import React, { Component } from 'react';

import { Link } from 'react-router-dom';

class Thankyou extends Component {
    componentDidMount()   {  

        window.scrollTo(0, 0)


    }
    render() {
        return (
            <div>
                <div className="bg-light py-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-0"><Link to="/">Home</Link> <span className="mx-2 mb-0">/</span> <strong className="text-black">Thankyou</strong></div>
                        </div>
                    </div>
                </div>

                <div className="site-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <span className="icon-check_circle display-3 text-success"></span>
                                <h2 className="text-black">Thanks for shopping with TAPiTDABiT! </h2>
                             <b> <p className="lead mb-5 text-black">we need 3 days for designing, production and packing of your card.<br/>
after that at least 2-3 days for delivery timing.<br/>
total delivery time can be 5-7 working days.<br/>
however we will try to deliver as soon as possible.</p>
                                </b>  
                                <p><Link to='/'><div className="btn btn-sm btn-primary" style={{background : "grey" , color : "white", border : "1px solid grey"}}>Back to shop</div></Link></p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default Thankyou;