import React ,{useEffect , useState} from 'react'

import whatapps from '../assets/images/what.png';

import "react-multi-carousel/lib/styles.css";
import "react-multi-carousel/lib/styles.css";
import "./../assets/css/main.css"
import "./../assets/css/util.css"

import "./../assets/vendor/css-hamburgers/hamburgers.min.css"

import "./../assets/fonts/iconic/css/material-design-iconic-font.min.css"
import "./../assets/vendor/bootstrap/css/bootstrap.min.css"
import "./../assets/vendor/select2/select2.min.css"
import "./../assets/vendor/slick/slick.css"
import "./../assets/vendor/perfect-scrollbar/perfect-scrollbar.css"

import p12 from "./../assets/images/p1.png"
import p22 from "./../assets/images/p2.png"


const Home = ( props ) => {
    const [myRef,setRef] = useState( React.createRef())
  

    useEffect(() =>{

        window.scrollTo(0, 0)

       
 },[])

 
    
    
    
    
    

        return (
     
      <div ref={myRef}
    >

<a href="https://wa.me/923302757197" target="_blank"><img src={whatapps} alt="" style={{position : "fixed" ,zIndex : "999999", top : "80%" , right : "20px",width:"70px",height : "70px"}}/></a>


{/* <br /> */}

        <center>
            <img src={p12} alt="" className="vb1" style={{maxWidth:"70%", objectFit : "cover"}} />
          </center>

<br /><br />

<center>
  <h2 style={{color  :"black", fontWeight : "bold"}}>
  Your Tapitdabit, upgraded
  </h2>
 <b style={{fontSize : "19px", fontWeight : "bold" , color : "black"}}> <p style={{padding  :"20px 20px" , }}>Purchase the tapitdabit pro feature to gain access to the world's most advanced digital business card.</p>
  </b>
</center>


<center>
  
<div className="sec-banner bg0 p-t-10 p-b-10">
          <div className="container-fuild" style={{width : "90%",margin : "auto"}}>
            <div className="row">
            <div className="col-12 text-center">
                                 
                                </div>

              <div className="col-md-12 col-xl-12" style={{padding : "20px 0px"}}>
                <div className="">
                  <img src={p22} alt="IMG-BANNER " className="buir" style={{height : "700px", objectFit: "cover" }}/>
                </div>
              </div>
            </div>
          </div>
        </div>



</center>








        {/* <center>

        <div className="container">
          <div className="row">
            <div className="arrival11 col-xl-12 col-lg-12 col-md-12 col-sm-12">
    <img src={p22} alt="" style={{minWidth : "60%",maxWidth:"100%", height : "600px"}} /> 
            </div>
           
          </div>
        </div>
        
       
      </center> */}



<br />

<center>
<span style={{padding :  "5px 20px",background: "grey" ,color :  "white", borderRadius : "20px"}} onClick={()=>props.history.push("/login")}> Go Pro</span>
{/* <span style={{padding :  "5px 10px",background: "grey" , border : "1px solid grey"}}> Login Now</span> */}
</center>
<br />








<header className='header1'>
      <h1>Our Pricing</h1>
      <div class="toggle">
        <label>Annually </label>
        <div class="toggle-btn">
          <input type="checkbox" class="checkbox1" id="checkbox" />
          <label class="sub" id="sub" for="checkbox">
            <div class="circle"></div>
          </label>
        </div>
        <label> Monthly</label>
      </div>
    </header>
    <div class="cards1">
      <div class="card1 shadow1">
        <ul class="ul1">
          <li class="pack">Basic</li>
          <li id="basic" class="price bottom-bar">$ 199.99</li>
          <li class="bottom-bar">500 GB Storage</li>
          <li class="bottom-bar">2 Users Allowed</li>
          <li class="bottom-bar">Send up to 3 GB</li>
          <li><button class="btn1">Learn More</button></li>
        </ul>
      </div>
      <div class="card1 active">
        <ul class="ul1">
          <li class="pack">Professional</li>
          <li id="professional" class="price bottom-bar">$ 249.99</li>
          <li class="bottom-bar">1 TB Storage</li>
          <li class="bottom-bar">5 Users Allowed</li>
          <li class="bottom-bar">Send up to 10 GB</li>
          <li><button class="btn1 active-btn">Learn More</button></li>
        </ul>
      </div>
      <div class="card1 shadow1">
        <ul class="ul1">
          <li class="pack">Master</li>
          <li id="master" class="price bottom-bar">$ 399.99</li>
          <li class="bottom-bar">2 TB Storage</li>
          <li class="bottom-bar">10 Users Allowed</li>
          <li class="bottom-bar">Send up to 20 GB</li>
          <li><button class="btn1">Learn More</button></li>
        </ul>
      </div>
    </div>






<br />
<br />
<br />











        {/* Footer */}
        
        {/* Back to top */}
        <div className="btn-back-to-top" id="myBtn">
          <span className="symbol-btn-back-to-top">
            <i className="zmdi zmdi-chevron-up" />
          </span>
        </div>
        {/* Modal1 */}
        <div className="wrap-modal1 js-modal1 p-t-60 p-b-20">
          <div className="overlay-modal1 js-hide-modal1" />
          <div className="container">
            <div className="bg0 p-t-60 p-b-30 p-lr-15-lg how-pos3-parent">
              <button className="how-pos3 hov3 trans-04 js-hide-modal1">
                <img src="images/icons/icon-close.png" alt="CLOSE" />
              </button>
              <div className="row">
                <div className="col-md-6 col-lg-7 p-b-30">
                  <div className="p-l-25 p-r-30 p-lr-0-lg">
                    <div className="wrap-slick3 flex-sb flex-w">
                      <div className="wrap-slick3-dots" />
                      <div className="wrap-slick3-arrows flex-sb-m flex-w" />
                      <div className="slick3 gallery-lb">
                        <div className="item-slick3" data-thumb="images/product-detail-01.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-01.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-01.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-02.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-02.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-02.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-03.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-03.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-03.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5 p-b-30">
                  <div className="p-r-50 p-t-5 p-lr-0-lg">
                    <h4 className="mtext-105 cl2 js-name-detail p-b-14">
                      Lightweight Jacket
                    </h4>
                    <span className="mtext-106 cl2">
                      $58.79
                    </span>
                    <p className="stext-102 cl3 p-t-23">
                      Nulla eget sem vitae eros pharetra viverra. Nam vitae luctus ligula. Mauris consequat ornare feugiat.
                    </p>
                    {/*  */}
                    <div className="p-t-33">
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Size
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Size S</option>
                              <option>Size M</option>
                              <option>Size L</option>
                              <option>Size XL</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Color
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Red</option>
                              <option>Blue</option>
                              <option>White</option>
                              <option>Grey</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-204 flex-w flex-m respon6-next">
                          <div className="wrap-num-product flex-w m-r-20 m-tb-10">
                            <div className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-minus" />
                            </div>
                            <input className="mtext-104 cl3 txt-center num-product" type="number" name="num-product" defaultValue={1} />
                            <div className="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-plus" />
                            </div>
                          </div>
                          <button className="flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04 js-addcart-detail">
                            Add to cart
                          </button>
                        </div>
                      </div>	
                    </div>
                    {/*  */}
                    <div className="flex-w flex-m p-l-100 p-t-40 respon7">
                      <div className="flex-m bor9 p-r-10 m-r-11">
                        <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 js-addwish-detail tooltip100" data-tooltip="Add to Wishlist">
                          <i className="zmdi zmdi-favorite" />
                        </a>
                      </div>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Facebook">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Twitter">
                        <i className="fa fa-twitter" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Google Plus">
                        <i className="fa fa-google-plus" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
 





        )
    
}

export default Home;