import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"
import swal from 'sweetalert';
import $ from 'jquery';
// import SmartSticky from 'react-smart-sticky';
import Slider from "react-slick";
// import Button from 'react-bootstrap/Button';

// or less ideally
import { Carousel } from 'react-bootstrap';
// import '~video-react/dist/video-react.css'; 
// @import '~video-react/styles/scss/video-react'; // or import scss 
import { Player } from 'video-react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ClearIcon from '@material-ui/icons/Clear';
import whatapps from '../assets/images/what.png';
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from 'react-responsive-carousel';
import "react-multi-carousel/lib/styles.css";
import "react-multi-carousel/lib/styles.css";
import "./../assets/css/main.css"
import "./../assets/css/util.css"

import "./../assets/vendor/css-hamburgers/hamburgers.min.css"

import "./../assets/fonts/iconic/css/material-design-iconic-font.min.css"
import "./../assets/vendor/bootstrap/css/bootstrap.min.css"
import "./../assets/vendor/select2/select2.min.css"
import "./../assets/vendor/slick/slick.css"
import "./../assets/vendor/perfect-scrollbar/perfect-scrollbar.css"



import videologo from "./../assets/images/videologo.gif"
import videoshort from "./../assets/images/shortheading.gif"


import tapni1 from "./../assets/images/tapni1.png"
import tapni2 from "./../assets/images/tapni2.png"
import tapni3 from "./../assets/images/tapin3.png"
import tapni4 from "./../assets/images/tapni4.png"
import tapni5 from "./../assets/images/tapni5.png"
import tapni6 from "./../assets/images/tapni6.png"





import img1 from "./../assets/images/img 1.png"
import img2 from "./../assets/images/img 2.png"
import img3 from "./../assets/images/img 3.svg"






import setting from "./../assets/images/setting.svg"
import share from "./../assets/images/share.svg"
import contacts from "./../assets/images/contacts.svg"








const settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1
};



const Home = ( props ) => {
    const [myRef,setRef] = useState( React.createRef())
    // const [scrollTop1,setscrollTop] = useState(0)
    // const [Categories , setCategories] = useState([])
    // const [Categories1 , setCategories1] = useState([])
    // const [Homo , setHomo] = useState([])
    const [product , setproduct] = useState([])
    const [dis , setdis] = useState(false)

    // const [product1 , setproduct1] = useState([])
    // const [UserDatat , setUserDatat] = useState({})
    // const [userHeader , setuserHeader] = useState(false)
    // const [SliderPhoto , setSliderPhoto] = useState([])
    // const [ArrivalPhoto , setArrivalPhoto] = useState([])
    // const [BannerPhoto , setBannerPhoto] = useState([])
    const [MainCatogories , setMainCatogories] = useState([])
    const [DataPart2 , setDataPart2] = useState([])
    const [NowAvailable , setNowAvailable] = useState([])
    const [bestSeller1 , setbestSeller1] = useState([])
    const [bestkeybaad , setbestkeybaad] = useState([])
    const [youtube123 , setyoutube123] = useState({})
    const [companieswith , setcompanieswith] = useState([])
    const [fg , setfg] = useState(true)
    const [fvalue , setfvalue] = useState([])
    const [data, setdata] = useState([]) 
    
    
    const [couponShow , setcouponShow] = useState(false)
    useEffect(() =>{

        window.scrollTo(0, 0)

var data1 =  JSON.parse(localStorage.getItem("Cart")) 
    if (data1) setDataPart2(data1)

        // fetch("https://tapitdabit-backend.vercel.app/slider-photo",{
        //     method: "GET",
        //      headers :  {
        //      "Content-Type" : "application/json" , 
        //  } ,
        // })
        // .then(res=>res.json())
        // .then(res1=>{
        //     console.log(res1[0]);
        //     setSliderPhoto(res1)
        
        // })
        let x =  window.location.href.split("?")[1]
        if(x){
          let v = x.split("%20").join(" ")
          localStorage.setItem("Code Promo" , JSON.stringify(v))
          fetch("https://tapitdabit-backend.vercel.app/CounttheAmbas",{
            method: "POST",
            headers :  {
                "Content-Type" : "application/json" , 
            } ,
            body : JSON.stringify({
              v
                // MainLine2 :mainline ,
            })
        })
        .then(res11=>res11.json())
        .then((res12)=>{
          console.log("erew")
        })
        }
        fetch("https://tapitdabit-backend.vercel.app/AllDisplayCoupon",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res3=>res3.json())
        .then(res4=>{
            if(res4.display) {
              setcouponShow(true)
              if(JSON.parse(localStorage.getItem("discount")) && JSON.parse(localStorage.getItem("discount")).name === "SAVE500") {
                setcouponShow(false)
            }
            }
            else  {
              setcouponShow(false)
            }
          })
        fetch("https://tapitdabit-backend.vercel.app/FAQ1",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res3=>res3.json())
        .then(res4=>{
          let dat1 = []
          res4.map((res55,c)=>{
              dat1.push(0)
          })
          setfvalue(dat1)
               setdata(res4)
               data.map((res,i)=>{
                data1[i] = res.text
               })

            console.log(res4)
          })
        fetch("https://tapitdabit-backend.vercel.app/NowAvailable1",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res9=>res9.json())
        .then(res11=>{
            console.log(res11);
            setNowAvailable(res11)
        
        })
        fetch("https://tapitdabit-backend.vercel.app/youtube123",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res99=>res99.json())
        .then(res191=>{
            console.log(res191);
            setyoutube123(res191[0])
        
        })
        fetch("https://tapitdabit-backend.vercel.app/bestSeller1",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res9=>res9.json())
        .then(res11=>{
            console.log(res11[0]);
            setbestSeller1(res11)
        
        })
        fetch("https://tapitdabit-backend.vercel.app/bestkeybaad",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res9=>res9.json())
        .then(res11=>{
            console.log(res11[0]);
            setbestkeybaad(res11)
        
        })
        fetch("https://tapitdabit-backend.vercel.app/companieswith",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res9=>res9.json())
        .then(res11=>{
            console.log(res11[0]);
            setcompanieswith(res11)
        
        })

        // fetch("https://tapitdabit-backend.vercel.app/BannerPhoto",{
        //     method: "GET",
        //      headers :  {
        //      "Content-Type" : "application/json" , 
        //  } ,
        // })
        // .then(res22=>res22.json())
        // .then(res222=>{
        //     console.log(res222[0]);
        //     setBannerPhoto(res222)
        
        // })

        // fetch("https://tapitdabit-backend.vercel.app/arrival-photo",{
        //     method: "GET",
        //      headers :  {
        //      "Content-Type" : "application/json" , 
        //  } ,
        // })
        // .then(res2=>res2.json())
        // .then(res3=>{
        //     console.log(res3);
        //     setArrivalPhoto(res3)
        
        // })
        fetch("https://tapitdabit-backend.vercel.app/MainCatogories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            console.log(res3);
            // setMainCatogories(res3)
            

            let dat = []
            res3.map((res55,c)=>{
                dat.push(res55)
            })
            // console.log(SubClassCategories,asse)
            console.log(dat)
            let data = dat.sort((a, b) => {
                return a.sort - b.sort;
            });
            setMainCatogories(data)
            
        })
        




        localStorage.removeItem("SearchData")
        localStorage.removeItem("Data")
        localStorage.removeItem("CartPrice")
        localStorage.removeItem("CateProduct")

        // if ( JSON.parse(localStorage.getItem("User"))  ){
        //     setuserHeader(true)
        //     setUserDatat(JSON.parse(localStorage.getItem("User")))
        //     const cartUser1 = JSON.parse(localStorage.getItem("Cart"))
        //     if (cartUser1 ){
        // fetch("/user-cart-add",{
        //                         method: "POST",
        //                         headers :  {
        //                             "Content-Type" : "application/json" , 
        //                         } ,
        //                         body : JSON.stringify({
        //                             cart : cartUser1 ,
        //                             user : JSON.parse(localStorage.getItem("User")) 
        //                         })
        //                     })
        //                     .then(res=>res.json())
        //                     .then((res1)=>{ 
        //                         console.log(res1);
        //                     })

        //     }
        // }

        fetch("https://tapitdabit-backend.vercel.app/AllProduct",{
       method: "GET",
        headers :  {
        "Content-Type" : "application/json" , 
    }
   })
   .then(res5=>res5.json())
   .then(res6=>{
       console.log(res6);
    const pro = []
    res6.map((res7,i)=>{
        //    console.log(res7.Product_Popular );
        if ( res7.Arrival){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro.push(res7)
        }
    })
    pro.sort((firstItem, secondItem) => firstItem.sort - secondItem.sort);

    // setproduct(pro.reverse())
    setproduct(pro.slice(0,5))
    setdis(true)
   })
    
//     fetch("/AllCategories",{
//        method: "GET",
//         headers :  {
//         "Content-Type" : "application/json" , 
//     } ,
//    })
//    .then(res=>res.json())
//    .then(res1=>{
//     setCategories(res1)
   
//    })

    
//     fetch("/AllHomomethtic",{
//        method: "GET",
//         headers :  {
//         "Content-Type" : "application/json" , 
//     } ,
//    })
//    .then(res3=>res3.json())
//    .then(res4=>{
//     setHomo(res4)
//     // console.log(res4);
//    })
return () => {
  document.getElementById("get1").classList.remove("is-active");
  document.getElementById("get2").style.display = "none";
  // console.log(document.getElementsByClassName("slick-dots"))
  // document.getElementsByClassName("slick-dots")[0].style.display = "none";
}
 },[])

 const savethedetailproduct = (data) =>{
    localStorage.setItem("Data" , JSON.stringify(data) )
    console.log(data);
 }
 
 
 const cate =(Categories) =>{
     var Cat1 = Categories.split(" ").join("-")
     
     localStorage.setItem("Cate" , JSON.stringify(Categories) )
     
     setTimeout(()=>{
            props.history.push(`/shop/categories/${Cat1}`)
        },1500)
    }
    


    const setChange = () =>{
        if(document.getElementById("get2").style.display === "none"){
            document.getElementById("get1").classList.add("is-active");
            document.getElementById("get2").style.display = "block";
        }
        else{
            document.getElementById("get1").classList.remove("is-active");
            document.getElementById("get2").style.display = "none";
        }
    }





    
    const addtocartproduct = (productDetail) =>{
      swal("Update!", "Your Item Add in Cart!", "success");
    
      // if(productDetail){
          // console.log(typeof(productDetail[0].ProductCreater), typeof("60a3c644e4520a12c80a6f52"));
          
    
    
        
                      if (productDetail[0].Product_Price_Discounted === null){
                      const data =  {...productDetail[0] ,
                          Percentage : 0,
                          DeliveryStatus : "Pending",
                          Pieces :1,
                          Total_Product_Price  : productDetail[0].Product_Price *1}
                      var data1 = JSON.parse(localStorage.getItem("Cart")) 
                      if (data1){
                      var data3 = data1.map((item) => {
                          if(item._id === data._id ){
                              console.log("double");
                      ;                   localStorage.setItem("double",JSON.stringify(true))
                      return {...item,
                          Pieces :1+ item.Pieces,
                          Total_Product_Price  : (productDetail[0].Product_Price *1)+ item.Total_Product_Price}
                      }
                      else{
                      console.log("double not match");
                      return item
                      }
    
                      })
                      var data5 =  JSON.parse(localStorage.getItem("double")) 
                      console.log(DataPart2.length, data3.length,data5);
                      var data10 =  JSON.parse(localStorage.getItem("Cart")) 
    
                      if(data10.length=== data3.length && data5){
                      console.log("double remove");
                      localStorage.removeItem("double")
                      localStorage.setItem("Cart" , JSON.stringify(data3) )
    
                      }
                      else{
                      console.log("Differet");
                      var data2 = [...data1 , data]
    
                      localStorage.setItem("Cart" , JSON.stringify(data2) )
                      }
                      }
                      else{
                      console.log("1");
                      localStorage.setItem("Cart" , JSON.stringify([data]) )
    
                      }
    
                      }
                      else if (productDetail[0].Product_Price_Discounted){
                      const data =  {...productDetail[0] ,
                          Percentage : 0,
                          DeliveryStatus : "Pending",
                          Pieces :1,
                          Total_Product_Price  : productDetail[0].Product_Price_Discounted *1}
                      var data1 = JSON.parse(localStorage.getItem("Cart")) 
                      if (data1){
                      var data3 = data1.map((item) => {
                          if(item._id === data._id ){
                              console.log("double");
                      ;                   localStorage.setItem("double",JSON.stringify(true))
                      return {...item,
                          Pieces :1+ item.Pieces,
                          Total_Product_Price  : (productDetail[0].Product_Price_Discounted *1)+ item.Total_Product_Price}
                      }
                      else{
                      console.log("double not match");
                      return item
                      }
    
                      })
                      var data5 =  JSON.parse(localStorage.getItem("double")) 
                      console.log(DataPart2.length, data3.length,data5);
                      var data10 =  JSON.parse(localStorage.getItem("Cart")) 
    
                      if(data10.length=== data3.length && data5){
                      console.log("double remove");
                      localStorage.removeItem("double")
                      localStorage.setItem("Cart" , JSON.stringify(data3) )
    
                      }
                      else{
                      console.log("Differet");
                      var data2 = [...data1 , data]
    
                      localStorage.setItem("Cart" , JSON.stringify(data2) )
                      }
                      }
                      else{
                      console.log("1");
                      localStorage.setItem("Cart" , JSON.stringify([data]) )
    
                      }
    
                      }
    
    
                          
    
    }
    
    
    const addtocartproduct12 = (productDetail) =>{
  
      if (productDetail[0].Product_Price_Discounted === null){
        const data =  {...productDetail[0] ,
            Percentage : 0,
            DeliveryStatus : "Pending",
            Pieces : 1 ,
            Total_Product_Price  : productDetail[0].Product_Price * 1 }
        var data1 = JSON.parse(localStorage.getItem("Cart")) 
        if (data1){
        var data3 = data1.map((item) => {
            if(item._id === data._id ){
                console.log("double");
        ;                   localStorage.setItem("double",JSON.stringify(true))
        return {...item,
            Pieces : 1 + item.Pieces,
            Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
        }
        else{
        console.log("double not match");
        return item
        }
    
        })
        var data5 =  JSON.parse(localStorage.getItem("double")) 
        console.log(DataPart2.length, data3.length,data5);
        var data10 =  JSON.parse(localStorage.getItem("Cart")) 
    
        if(data10.length=== data3.length && data5){
        console.log("double remove");
        localStorage.removeItem("double")
        localStorage.setItem("Cart" , JSON.stringify(data3) )
    
        }
        else{
        console.log("Differet");
        var data2 = [...data1 , data]
    
        localStorage.setItem("Cart" , JSON.stringify(data2) )
        }
        }
        else{
        console.log("1");
        localStorage.setItem("Cart" , JSON.stringify([data]) )
    
        }
    
        }
        else if (productDetail[0].Product_Price_Discounted){
        const data =  {...productDetail[0] ,
            Percentage : 0,
            DeliveryStatus : "Pending",
            Pieces : 1 ,
            Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
        var data1 = JSON.parse(localStorage.getItem("Cart")) 
        if (data1){
        var data3 = data1.map((item) => {
            if(item._id === data._id ){
                console.log("double");
        ;                   localStorage.setItem("double",JSON.stringify(true))
        return {...item,
            Pieces : 1 + item.Pieces,
            Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
        }
        else{
        console.log("double not match");
        return item
        }
    
        })
        var data5 =  JSON.parse(localStorage.getItem("double")) 
        console.log(DataPart2.length, data3.length,data5);
        var data10 =  JSON.parse(localStorage.getItem("Cart")) 
    
        if(data10.length=== data3.length && data5){
        console.log("double remove");
        localStorage.removeItem("double")
        localStorage.setItem("Cart" , JSON.stringify(data3) )
    
        }
        else{
        console.log("Differet");
        var data2 = [...data1 , data]
    
        localStorage.setItem("Cart" , JSON.stringify(data2) )
        }
        }
        else{
        console.log("1");
        localStorage.setItem("Cart" , JSON.stringify([data]) )
    
        }
    
        }
    
    
      props.history.push("/custom-product/photo-upload")
    }
    
    
    
const setChangerItemSize = (e)=>{

  if (fg) setfg(false)
  else setfg(true)

  for (let i = 0; i < 45; i++) {
    if(i === e){
      if(fvalue[e] === 0){
          fvalue[e] = 1
          // let f = document.getElementById("changeIcon"+i)
           document.getElementById("changeIcon1"+i).style.transform = "rotate(180deg)"
          
      }
      else {
          fvalue[e] = 0
          // let v = document.getElementById("changeIcon"+i)
           document.getElementById("changeIcon1"+i).style.transform = "rotate(0deg)"
          // v.innerHTML= ""
       }
    }
 
  }

setfvalue(fvalue)
  if (fg) setfg(false)
  else setfg(true)
  
}
  
const SaveCoupon = () => {
  localStorage.setItem("discount",JSON.stringify(
    {name  : "SAVE500" ,
    discount  : 500,
    discount1  : 0,
    id : 'srfkej34jhkskj'
    }
  ))
  swal("Update!","Successfully your Promo Applied ", "success");
  setcouponShow(!couponShow)
}


        return (
            






      <div ref={myRef}
        // onScroll={onScrolll()}
       >
                     <a href="https://wa.me/923302757197" target="_blank"><img src={whatapps} alt="" style={{position : "fixed" ,zIndex : "999999", top : "80%" , right : "10px",width:"70px",height : "70px"}}/></a>

        {/* Slider */}



        {/* <Player playsInline >
      <source src={h9}/>
    </Player> */}

        {/* <section>
                    <div className="container">
                        <div className="col-xl-12 col-lg-12 col-md-12" style={{width : "100%", margin  : "0px",padding: "0px"}} >
                            <video width="100%" height="600px" autoPlay loop >
                              <source src={h9}/>
</video>

                        </div>
                  </div>
        </section> */}
        










{couponShow ?

        <div className="site-section change-padding" style={{position: "fixed", width: "100%",
    zIndex:"9999",top  :"25%"}}>

                    <div className="container div-size-respon" style={{width : "35%", borderRadius : "20px",background: "white",padding : "0px"}}>
                        <div className="row">
                            <div className="col-md-12">
                                <div >

                                    <div className="p-3 p-lg-5" style={{ borderRadius : "20px",boxShadow: "1px 1px 10px grey"}}>
                                       <center>
                                        <h5 className='change-texts' style={{color : "black"}}>
                                     
                                     <span style={{fontWeight : "bold"}}>  WE HAVE A <br /> SPECIAL GIFT FOR YOU</span> 
                                        <br />
                                        <br />
                                      <b>

                                        <span  style={{color : "grey"}}>
                                       Get flat Rs 500 discount on
                                        <br /> 
                                          your first purchase
                                        </span>
                                          
                                      </b>
                                        </h5>
                                        <p  style={{fontSize : "13px"}}>Code expires after 01 hour</p>
                                
                                        <p  style={{fontSize : "17px",color : "grey",fontWeight : "800",margin : "0px"}}>Coupon Code</p>
                                       <div style={{border : "1px dashed black" , background : "rgb(234, 234, 234)",color : "black" , fontSize : "20px", fontWeight: "bold"
                                      ,padding: "3px 8px 3px 8px",
    width: "126px",
    fontFamily: "revert"
}}>
                                        SAVE500
                                       </div>
                                       </center>
                                       
                                       <ClearIcon  style={{position: "absolute",top: "1px",right: "4%", color : "black",fontSize : "27px",fontWeight : "400",
                                          border: "2px solid",
                                          borderRadius: "32px",
                                          padding: "3px 3px",
                                          width: "37px",
                                          height: "37px",
                                          background: "rgb(234, 234, 234)"}}
                                          onClick={()=>setcouponShow(!couponShow)}
                                          />
                                     

         
                              

                              <div className="form-row">
                                          <div className="form-group col-md-12">
                                            <center>
                                         
<br />
<button  style={{width : "170px", padding : "8px 0px", color : "black",  background : "rgb(234, 234, 234)" ,   borderRadius :  "30px" ,fontSize : "19px",fontWeight : "bold",border : "1px solid black"}} onClick={()=>SaveCoupon()}>GET MY COUPON</button>
                                            
                                            </center>
                                </div>
                                </div>
                                     
                                    </div>
                                </div>
                            </div>
                         
                        </div>
                    </div>
                </div>


:
""

}


































          <div className='show-desktop-main-video' style={{display: "flex",justifyContent: 'center',alignItems: "center",marginTop: "65px",maxWidth: "100%"}}>
<div style={{
  display: 'flex',
  flexDirection: 'column',
  alignItems: "inherit",
  padding: "80px 0px 0px 0px",
}}>
<h1 className="main-heading-line">THE LAST BUSINESS CARD YOU’LL EVER BUY</h1>
<img src={videoshort} className="short-video-responsive" alt=""style={{
    height: '368px',
    // width: "500px",
    marginTop: "-112px"
}}/>
</div>
      <img src={videologo} className="main-video-responsive" alt="" style={{ padding: "20px 0px 0px 99px",   height: "637px"  , objectFit: "cover"
        ,padding: '11px 0px 0px 34px',
        height: "605px",
        objectFit: "cover",
        marginTop: "20px"
    
    }}/>
        </div>





          <div className='show-mobile-main-video' style={{display: "flex",justifyContent: 'center',alignItems: "center",maxWidth: "97%"}}>
       <center>
         <h1 className="main-heading-line" style={{textAlign : "center"}}>THE LAST BUSINESS CARD YOU’LL EVER BUY</h1>

            <img src={videologo} alt="" style={{ 
              height: "436px",
              objectFit: "cover",
              width: "300px",
              marginTop: "-41px",
              position : "relative",
              zIndex : "1"
             }}/>
       </center>
<div style={{
  display: 'flex',
  flexDirection: 'column',
  alignItems: "inherit",
  // padding: "80px 0px 0px 0px",
}}>
<img src={videoshort} alt=""style={{
    height: '308px',
    // width: "500px",
    marginTop: "-77px",
    marginBottom: "25px"
}}/>
</div>
        </div>
        
        
     
                                                                {/* <video class="video-fluid" autoPlay loop muted class="w-100 carousel pj3" style={{paddingTop : '72px',height: "728px"}}>
                                                                  <source src={h9} type="video/mp4"/>
                                                                </video> */}
                                                <center>

                                                                <div className="p-b-4 trans-05 button-mobile-view-change" style={{ background: "black",width   :"200px",padding: "15px 10px 15px 10px",borderRadius: "20px"}}>
                      <div className="stext-101 cl0 trans-09">
                        <Link to={"/all-shop"} style={{color : "white"}}>Shop Now  <ArrowForwardIcon/> </Link>
                      </div>
                    </div>
                                                </center>






<br />

{/* <br />

      <center>
        <h2 style={{color : "black",fontWeight : "bold"}}>
      Other person don't need an app or a TAPITDABIT card to receive your info.
        </h2>
      </center>

      <br /> */}


{/* <center>
      <h4 style={{color : "grey",fontWeight : "bold"}}>
        Now available on
        </h4>

</center> */}

      {/* <section>
                    <div className="container-fuild">
                        <div className="col-xl-12 col-lg-12 col-md-12" style={{width : "100%", margin  : "0px",padding: "0px",height : "300px"}} >
                                        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" data-interval="2000">
                                        <ol class="carousel-indicators">
                                        {NowAvailable.map((res,i)=>{
                                                    return (  
                                                       <>
                                            <li data-target="#carouselExampleIndicators"  class="active" data-slide-to={i}></li>   
                                            {res.photo1 ?<li data-target="#carouselExampleIndicators" data-slide-to={i+1}></li>:""}
                                            {res.photo2 ?<li data-target="#carouselExampleIndicators" data-slide-to={i+2}></li>:""}
                                             </>
                                             )
                                          
                                          
                                        })
                                      }

                                        </ol>
                                            <div class="carousel-inner">
                                            {NowAvailable.map((res,i)=>{
                                              if(res.photo){
                                                      return (  <>
                                                        <div class="carousel-item active" >
                                                    <img class="d-block img-cov" src={res.photo} alt="First slide"  style={{height:"300px" ,width: "50%",margin: "auto"}}/>
                                                </div>
                                                {res.photo1 ?  <div class="carousel-item">
                                                          <img class="d-block img-cov" src={res.photo1} alt="Third slide"   style={{height:"300px",width: "50%",margin: "auto"}}/>
                                                      </div>
                                                      :""}
                                                      {res.photo2 ? 
                                                       <div class="carousel-item">
                                                          <img class="d-block img-cov" src={res.photo2} alt="Third slide"   style={{height:"300px",width: "50%",margin: "auto"}}/>
                                                      </div>
                                                      :""}
                                                      
                                                     </> )
                                                  }
                                             
                                                   
                                                    
                                                     
                                            })
                                            }
                                            </div>
                                            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                <span class="sr-only">Previous</span>
                                            </a>
                                            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                <span class="sr-only">Next</span>
                                            </a>
                                        </div>
                        </div>
                    </div>
                </section> */}


                {/* <Carousel>
                <div>
                    <img src="assets/1.jpeg" />
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img src="assets/2.jpeg" />
                    <p className="legend">Legend 2</p>
                </div>
                <div>
                    <img src="assets/3.jpeg" />
                    <p className="legend">Legend 3</p>
                </div>
            </Carousel> */}

      {/* <center>
        <h4 style={{color : "grey",fontWeight : "bold"}}>
        Now available on
        </h4>
        <div className="container">
          <div className="row">
             {
        NowAvailable.map((res,i)=>{
          return(
            <>
            <div className="arrival11 col-xl-4 col-lg-4 col-md-6 col-sm-6">
             <img src={res.photo} alt="" className="arrival-Photo111"  />
            </div>
            <div className="arrival11 col-xl-4 col-lg-4 col-md-6 col-sm-6">
             {res.photo1 ?<img src={res.photo1} alt="" className="arrival-Photo111"  /> : ""  }
            </div>
            <div className="arrival11 col-xl-4 col-lg-4 col-md-6 col-sm-6">
             {res.photo2 ?<img src={res.photo2} alt="" className="arrival-Photo111"  /> : ""  }
            </div>
          </>
          )
        })
        }
          </div>
        </div>
        
       
      </center> */}
    
        {/* <h2 style={{color : "black",fontWeight : "bold"}}>
        Best Sellers
        </h2> */}
        {/* <div className="container"><div className="row" style={{margin: "-4px auto",width: "84%",}}>

  
                <div className="block2">
                  <div className="block2-pic hov-img0">
                    <Link  to={"/categories/"+res.Product_Catagories+"/"+"product/"+res._id+"/"+res.Product_Name}><img src={res.Product_Image_Upload} alt="IMG-PRODUCT"  style={{minHeight : "250px"}}/></Link>
                    <Link to={"/categories/"+res.Product_Catagories+"/"+"product/"+res._id+"/"+res.Product_Name} className="block2-btn flex-c-m stext-103 cl2 size-102 bg0 bor2 hov-btn1 p-lr-15 trans-04 js-show-modal1">
                      Quick View
                    </Link>
                  </div>
                  <div className="block2-txt flex-w flex-t p-t-14" style={{padding :  "10px 13px"}}>
                    <div className="block2-txt-child1 flex-col-l">
                    <Link to={"/categories/"+res.Product_Catagories+"/"+"product/"+res._id+"/"+res.Product_Name} className="stext-104 cl4 hov-cl1 trans-04 js-name-b2 p-b-6">
                        <b>{
                          res.Product_Name
                        }
                          </b> 
                                                </Link>

                      <span className="stext-105 cl3">
                        <i>
                          {res.Product_Price_Discounted ? 
                                                        <div style={{display : "flex",justifyContent : 'center',fontSize : "21px"}}>
                                                            <p  style={{color  : "black"  }}>Rs : {res.Product_Price_Discounted}</p>
                                                            <div style={{width : "10px"}}></div>
                                                            <p style={{color  : "grey"  }}> <b><s>Rs : {res.Product_Price}</s></b>  </p>
                                                        </div>
                                                        : 
                                                        <p style={{color  : "black"  ,fontSize : "21px"}}>Rs : {res.Product_Price}</p>

                                                        }      
                        </i>
                                      </span>
                    </div>
                    <div className="block2-txt-child2 flex-r p-t-3">
                      <a className="btn-addwish-b2 dis-block pos-relative js-addwish-b2">
                      <i className="zmdi zmdi-shopping-cart dis-block trans-04" style={{fontSize : "24px",color:"rgb(190, 193, 201)"}} onClick={() =>res.Product_Catagories !==  "Custom Products" ? addtocartproduct([res]) : addtocartproduct12([res])  }/>

                      
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              )
            })
          
          }





          
          </div></div>
        
        */}



















           {/* <Slider {...settings}>
{NowAvailable.length > 0 ?
<>
          <div> 
          <img  src={NowAvailable[0].photo && NowAvailable[0].photo}  style={{height:"300px" ,width: "50%",margin: "auto"}}/>
      </div>
      <div>
      <img  src={NowAvailable[0].photo1 && NowAvailable[0].photo1}  style={{height:"300px" ,width: "50%",margin: "auto"}}/>
      </div>
      <div>
      <img  src={NowAvailable[0].photo2 &&NowAvailable[0].photo2}  style={{height:"300px" ,width: "50%",margin: "auto"}}/>
      </div>
      </>
      : " "
}

            </Slider> */}





















           {/* <Carousel showArrows={true} onChange={onChange} onClickItem={onClickItem} onClickThumb={onClickThumb}>
                                            {NowAvailable.map((res,i)=>{
                                              if(res.photo){
                                                      return (  <>
                                                       <div>
                                                            <img class="d-block img-cov" src={res.photo}  style={{height:"300px" ,width: "50%",margin: "auto"}}/>
                                                        </div>
                                                        {res.photo1 ?  <div>
                                                          <img class="d-block img-cov" src={res.photo1} alt="Third slide"   style={{height:"300px",width: "50%",margin: "auto"}}/>
                                                      </div>
                                                      :""}
                                                        {res.photo2 ? 
                                                       <div>
                                                          <img class="d-block img-cov" src={res.photo2} alt="Third slide"   style={{height:"300px",width: "50%",margin: "auto"}}/>
                                                      </div>
                                                      :""}
                                                     </> )
                                                  }
                                             
                                                   
                                                    
                                                     
                                            })
                                            }
                                            
  </Carousel> */}
           {/* <Carousel showArrows={true} onChange={onChange} onClickItem={onClickItem} onClickThumb={onClickThumb}>
                                            {NowAvailable.map((res,i)=>{
                                              if(res.photo){
                                                      return (  <>
                                                       <div>
                                                            <img class="d-block img-cov" src={res.photo}  style={{height:"300px" ,width: "50%",margin: "auto"}}/>
                                                        </div>
                                                        {res.photo1 ?  <div>
                                                          <img class="d-block img-cov" src={res.photo1} alt="Third slide"   style={{height:"300px",width: "50%",margin: "auto"}}/>
                                                      </div>
                                                      :""}
                                                        {res.photo2 ? 
                                                       <div>
                                                          <img class="d-block img-cov" src={res.photo2} alt="Third slide"   style={{height:"300px",width: "50%",margin: "auto"}}/>
                                                      </div>
                                                      :""}
                                                     </> )
                                                  }
                                             
                                                   
                                                    
                                                     
                                            })
                                            }
                                            
  </Carousel> */}



























{/* <Carousel variant="dark">
{NowAvailable.map((res,i)=>{
   if(res.photo){
    return (  <>
<Carousel.Item>
    <img
      className="d-block w-100"
      src={NowAvailable[0].photo && NowAvailable[0].photo}  style={{height:"300px",width: "50%",margin: "auto"}}
      alt="First slide"
    />

  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={NowAvailable[0].photo1 && NowAvailable[0].photo1}  style={{height:"300px",width: "50%",margin: "auto"}}
      alt="Second slide"
    />

  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={NowAvailable[0].photo2 && NowAvailable[0].photo2}  style={{height:"300px",width: "50%",margin: "auto"}}
      alt="Third slide"
    />

  </Carousel.Item>

    </>)
}
})
}
  
</Carousel> */}






































 {/* {
        bestSeller1.map((res,i)=>{
          return(
            <div className="arrival11 col-xl-3 col-lg-4 col-md-4 col-sm-6 r55">
             <img src={res.photo} alt="" className="arrival-Photo111"  />
            </div>
          
          )
        })
        } */}



        {/* <center>
 { 
        bestkeybaad.map((res,i)=>{
          return(
            <>
            <img src={res.photo} className="az1" alt="" style={{minWidth : "60%" ,maxWidth:"100%", height : "900px" , paddingBottom : "20px"}} /> 
             {res.photo1 ?<img src={res.photo1} className="az2" alt="" style={{minWidth : "50%",maxWidth:"100%", height : "900px" , paddingBottom : "20px"}} /> : ""  }<br/> 
             {res.photo2 ?<img src={res.photo2} className="az3" alt="" style={{minWidth : "50%",maxWidth:"100%", height : "1100px" , paddingBottom : "20px"}} /> : ""  }
              </>
          )
        })
        }
         
          </center> */}







{/* <center> */}

{/* <section className="bg0 p-t-23 p-b-60" > */}
<section className="bg0 p-t-40">
          <div className="container">
            <div className="p-b-10">
              {/* <h5 className="ltext-103 cl5" style={{color  :  'black',textAlign : "left", fontSize : "29px"}}> */}
             
             
              {/* <h1 style={{color  :  'black',textAlign : "left", fontSize : "29px"}}> */}
              <h2  style={{color : "black",fontWeight : "bold"}}>

              TAPITDABIT vs Paper Business Cards

              </h2>
             
            </div>
            
           
            <div className="row isotope-grid">
       
                <div className="col-sm-6 col-md-4 col-lg-4 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                <div className="block2">
                  <div className="block2-pic hov-img0">
                    <Link ><img src={tapni1} alt="IMG-PRODUCT"  style={{width:"67px",height : "64px"}}/></Link>
                  </div>
                  <br/>
                  <h4 style={{color : "black",fontWeight : 'bold'}}>Time-saving</h4>
                  <p style={{color : "black",width :"280px", fontWeight : "bold"}}>Instantly share your info with a tap, or scan. Exchange contact info, social media, websites, and more. </p>
                </div>
              </div>
                <div className="col-sm-6 col-md-4 col-lg-4 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                <div className="block2">
                  <div className="block2-pic hov-img0">
                    <Link ><img src={tapni2} alt="IMG-PRODUCT"  style={{width:"67px",height : "64px"}}/></Link>
                  </div>
                  <br/>
                  <h4 style={{color : "black",fontWeight : 'bold'}}>Update anytime</h4>
<p style={{color : "black",width :"280px", fontWeight : "bold"}}>Real-time editing. Update your information from any location, and it will sync automatically.
  </p>                </div>
              </div>
                <div className="col-sm-6 col-md-4 col-lg-4 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                <div className="block2">
                  <div className="block2-pic hov-img0">
                    <Link ><img src={tapni3} alt="IMG-PRODUCT"  style={{width:"67px",height : "64px"}}/></Link>
                  </div>
                  <br/>
                  <h4 style={{color : "black",fontWeight : 'bold'}}>Wow effect - included
            </h4>
                  <p style={{color : "black",width :"280px", fontWeight : "bold"}}>Amaze your future connections with the fastest and coolest way to share information.</p>
                </div>
              </div>

                <div className="col-sm-6 col-md-4 col-lg-4 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                <div className="block2">
                  <div className="block2-pic hov-img0">
                    <Link ><img src={tapni4} alt="IMG-PRODUCT"  style={{width:"67px",height : "64px"}}/></Link>
                  </div>
                  <br/>
                  <h4 style={{color : "black",fontWeight : 'bold'}}> Compatible by default</h4>
                  <p style={{color : "black",width :"280px", fontWeight : "bold"}}>Share with anyone. Compatible with all modern smartphones, Apple and Android.</p>
                </div>
              </div>
                <div className="col-sm-6 col-md-4 col-lg-4 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                <div className="block2">
                  <div className="block2-pic hov-img0">
                    <Link ><img src={tapni5} alt="IMG-PRODUCT"  style={{width:"67px",height : "64px",borderRadius : "20px"}}/></Link>
                  </div>
                  <br/>
                  <h4 style={{color : "black",fontWeight : 'bold'}}>Cost-efficient</h4>
<p style={{color : "black",width :"280px", fontWeight : "bold"}}>Replace hundreds of paper business cards with one that uses contemporary technology.
  </p>                </div>
              </div>
                <div className="col-sm-6 col-md-4 col-lg-4 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                <div className="block2">
                  <div className="block2-pic hov-img0">
                    <Link ><img src={tapni6} alt="IMG-PRODUCT"  style={{width:"67px",height : "64px"}}/></Link>
                  </div>
                  <br/>
                  <h4 style={{color : "black",fontWeight : 'bold'}}>Tree friendly
            </h4>
                  <p style={{color : "black",width :"280px", fontWeight : "bold"}}>By switching to digital solution, less 🌳 is being cut for our networking needs.
</p>
                </div>
              </div>
            
              {/* <div className="flex-c-m flex-w w-full p-t-5">
              <Link to={"/all-shop"} className="flex-c-m stext-101 cl5 size-103 bg2 bor1 hov-btn1 p-lr-15 trans-04" style={{background: "black",color : "white",width   :"200px"}}>
              See all products
              </Link>
            </div> */}

            </div>

          </div>
        </section>


{/* </center> */}









<br />
      <center>

<section className="bg0 p-t-0">
          <div className="container">
            <div className="p-b-10">
              {/* <h3 className="ltext-103 cl5"> */}
              <h2  style={{color : "black",fontWeight : "bold"}}>

              Best Sellers
              </h2>
              {/* </h3> */}
            </div>
            
           
            <div className="row isotope-grid">
               {product.map((res,i)=>{
                if(res.Price[0]){
                  return (
                    <div className="col-sm-6 col-md-4 col-lg-4 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                    <div className="block2">
                      <div className="block2-pic hov-img0">
                      <Link  to={"/main-categories/"+res.Product_Catagories+"/product/"+res._id+"/"+res.Product_Name} ><img src={res.Product_Image_Upload} alt="IMG-PRODUCT"  style={{height : "300px", objectFit  :"cover",height: "fitContent"}}/></Link>
                        <Link to={"/main-categories/"+res.Product_Catagories+"/product/"+res._id+"/"+res.Product_Name} className="block2-btn flex-c-m stext-103 cl2 size-102 bg0 bor2 hov-btn1 p-lr-15 trans-04 js-show-modal1">
                          Quick View
                        </Link>
                      </div>
                      <div className="block2-txt flex-w flex-t p-t-14" style={{padding :  "10px 13px"}}>
                        <div className="block2-txt-child1 flex-col-l ">
                          <Link to={"/main-categories/"+res.Product_Catagories+"/"+"product/"+res._id+"/"+res.Product_Name} className="stext-104 cl4 hov-cl1 trans-04 js-name-b2 p-b-6">
                          <b>
                            <h1 style={{color: "black", fontSize:" 16px",fontWeight: "bold"}}>
                             {
                              res.Product_Name
                            } 
                            </h1>
                            
                              </b>                       </Link>
    
    
    
                          <span className="stext-105 cl3">
                              <i>
                                 {res.Size_Discounted[0] ? 
                                                            <div style={{display : "flex",justifyContent : 'center',fontSize : "19px"}}>
                                                                <p  style={{color  : "black"  }}>Rs : {res.Size_Discounted[0]}</p>
                                                                <div style={{width : "8px"}}></div>
                                                                <p style={{color  : "grey"  }}> <b><s>Rs : {res.Price[0]}</s></b>  </p>
                                                                <div style={{width : "8px"}}></div>
                                                                <p style={{color  : "black"  }}> <b>Size : {res.Size[0]}</b>  </p>
                                                            </div>
                                                            : 
                                                            <div style={{display : "flex",justifyContent : 'center',fontSize : "19px"}}>
                                                            <p style={{color  : "black"  ,fontSize : "21px"}}>Rs : {res.Price[0]}</p>
                                                              <div style={{width : "8px"}}></div>
                                                              <p style={{color  : "black"  }}> <b>Size : {res.Size[0]}</b>  </p>
                                                          </div>
    
                                                            }    
                              </i>
                          
    
                                                                                  </span>
                        </div>
                       <div className="block2-txt-child2 flex-r p-t-3">
                          <a href="#" className="btn-addwish-b2 dis-block pos-relative js-addwish-b2">
                          <i className="zmdi zmdi-shopping-cart dis-block trans-04"  style={{fontSize : "24px",color:"black" ,background :"rgb(238, 238, 238)",padding: "10px 13px 10px 12px",borderRadius: "39px"}}  onClick={() => addtocartproduct([res])  }/>
    
                            {/* <img className="icon-heart1 dis-block trans-04" src={h1} alt="ICON" />
                            <img className="icon-heart2 dis-block trans-04 ab-t-l" src={h2} alt="ICON" /> */}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                    )
                  }else{
                    if(res.Product_Name === "Executive Black Metal Hybrid Card "                    ){
                      return (
                      <div className="col-sm-6 col-md-4 col-lg-4 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                      <div className="block2">
                        <div className="block2-pic hov-img0">
                        <Link  to={"/main-category/"+res.Product_Catagories+"/product/"+res._id+"/"+res.Product_Name} ><img src={res.Product_Image_Upload} alt="IMG-PRODUCT"  style={{height : "300px", objectFit  :"cover",height: "fitContent"}}/></Link>
                          <Link to={"/main-category/"+res.Product_Catagories+"/product/"+res._id+"/"+res.Product_Name} className="block2-btn flex-c-m stext-103 cl2 size-102 bg0 bor2 hov-btn1 p-lr-15 trans-04 js-show-modal1">
                            Quick View
                          </Link>
                        </div>
                        <div className="block2-txt flex-w flex-t p-t-14" style={{padding :  "10px 13px"}}>
                          <div className="block2-txt-child1 flex-col-l ">
                            <Link to={"/main-category/"+res.Product_Catagories+"/"+"product/"+res._id+"/"+res.Product_Name} className="stext-104 cl4 hov-cl1 trans-04 js-name-b2 p-b-6">
                            <b>
                            <h1 style={{color: "black", fontSize:" 16px",fontWeight: "bold"}}>
                             {
                              res.Product_Name
                            } 
                            </h1>
                            
                              </b>                        </Link>
      
      
      
                            <span className="stext-105 cl3">
                                <i>
                                   {res.Product_Price_Discounted ? 
                                                              <div style={{display : "flex",justifyContent : 'center',fontSize : "21px"}}>
                                                                  <p  style={{color  : "black"  }}>Rs : {res.Product_Price_Discounted}</p>
                                                                  <div style={{width : "10px"}}></div>
                                                                  <p style={{color  : "grey"  }}> <b><s>Rs : {res.Product_Price}</s></b>  </p>
                                                              </div>
                                                              : 
                                                              <p style={{color  : "black"  ,fontSize : "21px"}}>Rs : {res.Product_Price}</p>
      
                                                              }    
                                </i>
                            
      
                                                                                    </span>
                          </div>
                         <div className="block2-txt-child2 flex-r p-t-3">
                            <a href="#" className="btn-addwish-b2 dis-block pos-relative js-addwish-b2">
                            <i className="zmdi zmdi-shopping-cart dis-block trans-04"  style={{fontSize : "24px",color:"black" ,background :"rgb(238, 238, 238)",padding: "10px 13px 10px 12px",borderRadius: "39px"}}  onClick={() => addtocartproduct([res])  }/>
      
                              {/* <img className="icon-heart1 dis-block trans-04" src={h1} alt="ICON" />
                              <img className="icon-heart2 dis-block trans-04 ab-t-l" src={h2} alt="ICON" /> */}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div> 
                      )
                    }
                    else{
                      return (
                        <div className="col-sm-6 col-md-4 col-lg-4 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                        <div className="block2">
                          <div className="block2-pic hov-img0">
                          <Link  to={"/categories/"+res.Product_Catagories+"/product/"+res._id+"/"+res.Product_Name} ><img src={res.Product_Image_Upload} alt="IMG-PRODUCT"  style={{height : "300px", objectFit  :"cover",height: "fitContent"}}/></Link>
                            <Link to={"/categories/"+res.Product_Catagories+"/product/"+res._id+"/"+res.Product_Name} className="block2-btn flex-c-m stext-103 cl2 size-102 bg0 bor2 hov-btn1 p-lr-15 trans-04 js-show-modal1">
                              Quick View
                            </Link>
                          </div>
                          <div className="block2-txt flex-w flex-t p-t-14" style={{padding :  "10px 13px"}}>
                            <div className="block2-txt-child1 flex-col-l ">
                              <Link to={"/categories/"+res.Product_Catagories+"/"+"product/"+res._id+"/"+res.Product_Name} className="stext-104 cl4 hov-cl1 trans-04 js-name-b2 p-b-6">
                              <b>
                            <h1 style={{color: "black", fontSize:" 16px",fontWeight: "bold"}}>
                             {
                              res.Product_Name
                            } 
                            </h1>
                            
                              </b>                   </Link>
        
        
        
                              <span className="stext-105 cl3">
                                  <i>
                                     {res.Product_Price_Discounted ? 
                                                                <div style={{display : "flex",justifyContent : 'center',fontSize : "21px"}}>
                                                                    <p  style={{color  : "black"  }}>Rs : {res.Product_Price_Discounted}</p>
                                                                    <div style={{width : "10px"}}></div>
                                                                    <p style={{color  : "grey"  }}> <b><s>Rs : {res.Product_Price}</s></b>  </p>
                                                                </div>
                                                                : 
                                                                <p style={{color  : "black"  ,fontSize : "21px"}}>Rs : {res.Product_Price}</p>
        
                                                                }    
                                  </i>
                              
        
                                                                                      </span>
                            </div>
                           <div className="block2-txt-child2 flex-r p-t-3">
                              <a href="#" className="btn-addwish-b2 dis-block pos-relative js-addwish-b2">
                              <i className="zmdi zmdi-shopping-cart dis-block trans-04"  style={{fontSize : "24px",color:"black" ,background :"rgb(238, 238, 238)",padding: "10px 13px 10px 12px",borderRadius: "39px"}}  onClick={() => addtocartproduct([res])  }/>
                              {/* <i className="zmdi zmdi-shopping-cart dis-block trans-04"  style={{fontSize : "24px",color:"black" ,background :"rgb(238, 238, 238)",padding: "10px 13px 10px 12px",borderRadius: "39px"}}  onClick={() =>res.Product_Catagories !==  "Custom Products" ? addtocartproduct([res]) : addtocartproduct12([res])  }/> */}
        
                                {/* <img className="icon-heart1 dis-block trans-04" src={h1} alt="ICON" />
                                <img className="icon-heart2 dis-block trans-04 ab-t-l" src={h2} alt="ICON" /> */}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div> 
                        )
                    }
                    
                    
                }
                
            })
          
          }
             
            </div>

          </div>
        </section>
      </center>
      <center>

<div className="p-b-4 trans-05" style={{ background: "black",width   :"200px",padding: "15px 10px",borderRadius: "20px", marginBottom : "30px"}}>
<div className="stext-101 cl0 trans-09">
<Link to={"/all-shop"} style={{color : "white"}}>See All Products  </Link>
</div>
</div>
</center>









<center>

<section className="bg0 p-t-23 p-b-20" >
          <div className="container">
          {/* <div className="container-fuild" style={{width:  "90%" , margin : "auto"}}> */}
            <div className="row isotope-grid">
       
                <div className="col-sm-6 col-md-6 col-lg-6 p-b-35 isotope-item women" style={{padding :  "10px 0px"}}>
                <div className="block2">
               
           
                
                  <h1 className='way-share-main-heading' style={{color : "black",fontWeight : 'bold',fontSize: "45px",textAlign: "left",fontFamily: 'Poppins-Medium'}}>TWO WAYS TO SHARE</h1>
                 <br />
                  {/* <h5 className='way-share-short-heading' style={{color : "rgb(247, 188, 68)",fontWeight : 'bold',fontSize: "27px",  textAlign : 'left'}}>Compatible with all phones. <br /> */}
                  <h5 className='way-share-short-heading' style={{color : "#564c4c",fontWeight : 'bold',fontSize: "27px",  textAlign : 'left'}}>Compatible with all phones. <br />
No app required.</h5>
{/* <br /> */}
                  <p className='way-share-main-paragraph' style={{color : "black", textAlign : 'left'}}>Simply tap or scan your card using any smartphone, with no app or installation required. It’s really that simple!</p>
               
               <div className='way-share-main-point' style={{display : "flex",textAlign : "left",color : "black"}}>
               {/* <div className='way-share-main-point' style={{display : "flex",justifyContent : "center",padding : "20px 20px 0px 20px",textAlign : "left",color : "black"}}> */}


                  <div style={{padding : "8px 5px"}}>
              <b style={{fontSize: "15px",
    fontWeight: "bold"}}>TAPITDABIT TAP</b>
              <ul style={{padding : "5px 25px"}}>
                <li style={{fontWeight: "600"}}>1. Tap phone to TAPITDABIT card.</li>
                <li style={{fontWeight: "600"}}>2. Open the notification.</li>
              </ul>
                  </div>

                  <div style={{padding : "8px 5px"}}>
              <b style={{fontSize: "15px",
    fontWeight: "bold"}}>TAPITDABIT SCAN</b>
              <ul style={{padding : "5px 25px"}}>
                <li style={{fontWeight: "600"}}>1. Open the camera app.</li>
                <li style={{fontWeight: "600"}}>2. Point the camera at the QR code.</li>
                <li style={{fontWeight: "600"}}>3. Open the notification.</li>
              </ul>
           
                  </div>


                </div>
                







                  </div>



              </div>















                <div className="col-sm-6 col-md-4 col-lg-4 p-b-35 isotope-item women" style={{padding :  "10px 0px"}}>
                <div className="block2">
              
           
                  <div className="block2-pic hov-img0" style={{marginTop : "-56px"}}>
                    <Link ><img src={videologo} alt="IMG-PRODUCT"  style={{minHeight : "250px",height: "619px", objectFit : "contain"}}/></Link>
                  </div>
                  <br/>
                 
             </div>
              </div>
                <div className="col-sm-3 col-md-2 col-lg-2 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                <div className="block2">
               
           










                 <div className="p-b-35 isotope-item women" style={{padding :  "15px 6px"}}>
                <div className="block2">
                  <div className="block2-pic hov-img0">
                    <Link ><img src={contacts} alt="IMG-PRODUCT"  style={{width:"50px",height : "50px",marginBottom : "15px"}}/></Link>
                  </div>
                  {/* <br/> */}
                  <h6 className='paragraph-bold' style={{color : "black",fontWeight : '600',textAlign: "center",margin: "0px"}}>Add your contact details and social media profiles


            </h6>
                </div>
              </div>

                <div className="p-b-35 isotope-item women" style={{padding :  "15px 6px"}}>
                <div className="block2">
                  <div className="block2-pic hov-img0">
                    <Link ><img src={share} alt="IMG-PRODUCT"  style={{width:"50px",height : "50px",marginBottom : "15px"}}/></Link>
                  </div>
                  {/* <br/> */}
                  <h6 className='paragraph-bold' style={{color : "black",fontWeight : '600',textAlign: "center",margin: "0px"}}>Share any details in just 2 seconds - no app required!
</h6>
               </div>
              </div>
               <div className="p-b-0 isotope-item women" style={{padding :  "15px 6px"}}>
                <div className="block2">
                  <div className="block2-pic hov-img0">
                    <Link ><img src={setting} alt="IMG-PRODUCT"  style={{width:"50px",height : "50px",marginBottom : "15px"}}/></Link>
                  </div>
                  {/* <br/> */}
                  <h6 className='paragraph-bold' style={{color : "black",fontWeight : '600',textAlign: "center",margin: "0px"}}>Update your details whenever you need to
</h6>
                </div>
              </div>
              


                </div>
              </div>
               
            

            </div>

          </div>
        </section>


</center>






<br />













<center>
  {/* <h4  style={{color : "black",fontWeight : "bold"}}>Companies using TAPITDABIT</h4> */}
  {/* <h3 style={{color: "black", fontWeight: "bold"}}> */}
  <h2  style={{color : "black",fontWeight : "bold"}}>

    Companies Using TAPITDABIT
    
    </h2>
    {/* </h3> */}
</center>
<section>
                    <div className="container-fuild">
                        <div className="col-xl-12 col-lg-12 col-md-12" style={{width : "100%", margin  : "0px",padding: "0px",height : "120px"}} >
                                        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" data-interval="1000" style={{height : "120px"}}>
                                        <ol class="carousel-indicators">
                                        {companieswith.map((res,i)=>{
                                          if(i === 0){

                                                    return (  
                                                       <>
                                            <li data-target="#carouselExampleIndicators"  class="active" data-slide-to={i}></li>   
                                             </>
                                             )
                                          }
                                          else{

                                                    return (  
                                                       <>
                                            <li data-target="#carouselExampleIndicators" data-slide-to={i}></li>
                                             </>
                                             )
                                          }
                                          
                                          
                                        })
                                      }

                                            {/* // <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                                            // <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                            // <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                                            // <li data-target="#carouselExampleIndicators" data-slide-to="3"></li> */}
                                        </ol>
                                            <div class="carousel-inner">
                                            {companieswith.map((res,i)=>{
                                              if(i === 0){
                                                      return (  
                                                        <div class="carousel-item active" >
                                                    <img class="d-block img-cov" src={res.photo} alt="First slide"  style={{height:"120px",width: "20%",margin: "auto"}}/>
                                                </div>
                                                        )
                                                  }
                                              else{
                                                      return (  
                                                       <div class="carousel-item">
                                                          <img class="d-block img-cov" src={res.photo} alt="Third slide"   style={{height:"120px",width: "20%",margin: "auto"}}/>
                                                      </div>
                                                 )
                                                  }
                                             
                                                   
                                                    
                                                     
                                            })
                                            }
                                            </div>
                                            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                <span class="sr-only">Previous</span>
                                            </a>
                                            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                <span class="sr-only">Next</span>
                                            </a>
                                        </div>
                        </div>
                    </div>
                </section>

<br />







<div className="sec-banner bg0 p-t-0 p-b-50">
          {/* <div className="container-fuild" style={{width : "96%"}}> */}
          <div className="container">
            <div className="row">
            <div className="col-12 text-center">
                                    {/* <h2 style={{color : "black"}} > <b> */}
                                    <h2  style={{color : "black",fontWeight : "bold"}}>

                                      Discover Our Collections
                                      </h2>
                                      {/* </b> </h2> */}
                                    <center>
                                        <p style={{width : "280px" , border : "0.5px solid grey" }}></p>
                                    </center>
                                </div>
              {MainCatogories.map((res,i)=>{
                return(
                  <div className="col-md-6 col-xl-4" style={{padding : "10px 0px"}}>
                {/* Block1 */}
                <div className="block1 wrap-pic-w dfjkkj">
                  <Link to={"/categories/"+res.MainCategories+"/product"}>
                  <img src={res.photo} alt="IMG-BANNER" style={{height : "300px", objectFit  :"cover"}}/></Link>
                  <Link  to={"/categories/"+res.MainCategories+"/product"} style={{color : "black"}} className="block1-txt ab-t-l s-full flex-col-l-sb p-lr-38 p-tb-34 trans-03 respon3 dfjkkj">
                  

                 <div className="block1-txt-child1 flex-col-l dfjkkj" style={{ }}>
                 {/* <div className="block1-txt-child1 flex-col-l" style={{ background : "white",  padding: "10px 20px 0px 20px" , borderRadius  : "10px"}}> */}
                      <span className="block1-name ltext-102 trans-04 p-b-8 dfjkkj">
                      <Link to={"/categories/"+res.MainCategories+"/product"} style={{color : "black"}}> </Link>
                      {/* <Link to={"/categories/"+res.MainCategories+"/product"} style={{color : "black"}}> {res.MainCategories}</Link> */}
                      </span>
                      
                    </div> 
                    <div className="block1-txt-child2 p-b-4 trans-05 dfjkkj">
                      <div className="block1-link stext-101 cl0 trans-09 dfjkkj">
                        <Link to={"/categories/"+res.MainCategories+"/product"} style={{color : "black"}}>{res.MainCategories}  </Link>
                      </div>
                    </div>
                 
                  </Link  >
                </div>
              </div>
                )
              })

              }
              
            </div>
          </div>
        </div>





        <div className="sec-banner bg0 p-t-20 p-b-10">
          <div className="container-fuild njju" style={{width : "70%",margin : "auto"}}>
            <div className="row">
            <div className="col-12 text-center">
                                    {/* <h3 style={{color : "black"}} > <b> */}
                                    <h2  style={{color : "black",fontWeight : "bold"}}>

                                      What You Can Share
                                      </h2>
                                      {/* </b> </h3> */}
                                    <center>
                                        <p style={{width : "280px" , border : "0.5px solid grey" }}></p>
                                    </center>
                                </div>
              {bestkeybaad.map((res,i)=>{
                return(
                  <>
                  <div className="col-md-6 col-xl-6" style={{padding : "20px 0px"}}>
                <div className="block1 wrap-pic-w">
                  <img src={res.photo} alt="IMG-BANNER"  style={{maxHeight : "700px", objectFit : "scale-down"}}/>
              </div>
              </div>
                  {/* <div className="col-md-6 col-xl-6" style={{padding : "20px 0px"}}>
                <div className="block1 wrap-pic-w">
                  <img srcset={res.photo} alt="IMG-BANNER"  style={{maxHeight : "700px",width : "300px"}}  />
              </div>
              </div> */}
              {res.photo1 ? 
                  <div className="col-md-6 col-xl-6" style={{padding : "20px 0px"}}>
                <div className="block1 wrap-pic-w">
                  <img src={res.photo1} alt="IMG-BANNER"  style={{maxHeight : "700px", objectFit : "scale-down"}}/>
              </div>
              </div>

              :""

              }
              {/* {res.photo2 ? 
                  <div className="col-md-6 col-xl-6" style={{padding : "20px 0px"}}>
                <div className="block1 wrap-pic-w">
                  <img src={res.photo2} alt="IMG-BANNER"  style={{maxHeight : "1200px"}}/>
              </div>
              </div>

  : ""
              } */}
              </>
                )
              })

              }
              
            </div>
          </div>
        </div>




















<br />
































{/* 

<center>

<section className="bg0 p-t-23 p-b-60">
          <div className="container">
            <div className="p-b-10">
              <h5 className="ltext-103 cl5">
              A BUSINESS CARD THAT'S UNLIKE ANY OTHER
              </h5>
              <p style={{color  :  'black'}}> <b>

              TAPITDABIT is pulling the humble business card straight into the 21st century.
              </b>
              </p>
            </div>
            
           
            <div className="row isotope-grid">
       
                <div className="col-sm-6 col-md-4 col-lg-4 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                <div className="block2">
                  <div className="block2-pic hov-img0">
                    <Link ><img src={service6} alt="IMG-PRODUCT"  style={{minHeight : "250px"}}/></Link>
                  </div>
                  <br/>
                  <h4 style={{color : "black",fontWeight : 'bold'}}>TAPITDABIT Card</h4>
                  <p style={{color : "black"}}>Have confidence in knowing that in any business or social setting, your business card is as quick as you are, allowing you to switch between profiles in real time.</p>
                </div>
              </div>
                <div className="col-sm-6 col-md-4 col-lg-4 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                <div className="block2">
                  <div className="block2-pic hov-img0">
                    <Link ><img src={service4} alt="IMG-PRODUCT"  style={{minHeight : "250px"}}/></Link>
                  </div>
                  <br/>
                  <h4 style={{color : "black",fontWeight : 'bold'}}>Share more</h4>
<p style={{color : "black"}}>TAPITDABIT employs NFC, which allows you to share much more than just your contact information. With over 30 different actions, such as social media handles, websites, payment information, and addresses
  </p>                </div>  
              </div>
                <div className="col-sm-6 col-md-4 col-lg-4 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                <div className="block2">
                  <div className="block2-pic hov-img0">
                    <Link ><img src={service5} alt="IMG-PRODUCT"  style={{minHeight : "250px"}}/></Link>
                  </div>
                  <br/>
                  <h4 style={{color : "black",fontWeight : 'bold'}}>Update in Real Time</h4>
                  <p style={{color : "black"}}>Save time and money by not having to buy new business cards every time your contact information changes. Simply change your information in seconds.</p>
                </div>
              </div>
                <div className="col-sm-6 col-md-4 col-lg-4 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                <div className="block2">
                  <div className="block2-pic hov-img0">
                    <Link ><img src={service3} alt="IMG-PRODUCT"  style={{minHeight : "250px"}}/></Link>
                  </div>
                  <br/>
                  <h4 style={{color : "black",fontWeight : 'bold'}}>Paper Who?</h4>
                  <p style={{color : "black"}}>Have confidence in knowing that in any business or social setting, your business card is as quick as you are, allowing you to switch between profiles in real time.</p>
              
                </div>
              </div>
                <div className="col-sm-6 col-md-4 col-lg-4 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                <div className="block2">
                  <div className="block2-pic hov-img0">
                    <Link ><img src={service1} alt="IMG-PRODUCT"  style={{minHeight : "250px"}}/></Link>
                  </div>
                  <br/>
                  <h4 style={{color : "black",fontWeight : 'bold'}}>Your Data is Secure</h4>
                  <p style={{color : "black"}}>You can rest assured that this card does not store any sensitive information such as a password or payment information.</p>
              
                </div>
              </div>
                <div className="col-sm-6 col-md-4 col-lg-4 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                <div className="block2">
                  <div className="block2-pic hov-img0">
                    <Link ><img src={service2} alt="IMG-PRODUCT"  style={{minHeight : "250px"}}/></Link>
                  </div>
                  <br/>
                  <h4 style={{color : "black",fontWeight : 'bold'}}>Eco Friendly</h4>
<p style={{color : "black"}}>Reduce paper waste by repeatedly printing business cards. You only need one card, that's all.  </p>              
                </div>
              </div>
            

            </div>

          </div>
        </section>


</center> */}









        <center>
        <h2  style={{color : "black",fontWeight : "bold"}}>

        {/* <h2 style={{color : "black"}} > */}
           <b>How to Use TAPITDABIT </b> 
           </h2>
           {/* </h2> */}
                                    {/* <center>
                                        <p style={{width : "280px" , border : "0.5px solid grey" }}></p>
                                    </center> */}
<div className="container-fuild" style={{width :"91%", margin :  "auto"}}><div className="row" >
  {/* <div className="col-xl-1 col-lg-1 col-md-1 col-sm-0"></div> */}
  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
  {youtube123.you1 ? <iframe src={youtube123.you1} className="youtube-link-responsive" width="100%" height="595px" title="YouTube video player" frameborder="0" allow="fullscreen;"  ></iframe>  :  ""}
  </div>
  {/* <div className="col-xl-1 col-lg-1 col-md-1 col-sm-0"></div> */}
  {/* <div className="col-xl-1 col-lg-10 col-md-1 col-sm-0"></div> */}
  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
  {youtube123.you2 ? <iframe src={youtube123.you2} frameborder="0" className="youtube-link-responsive" width="100%" height="595px" allow="fullscreen;"></iframe>  :  ""}
  </div>
  {/* <div className="col-xl-1 col-lg-1 col-md-1 col-sm-0"></div> */}
  {/* <div className="col-xl-1 col-lg-1 col-md-1 col-sm-0"></div> */}
  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
  {youtube123.you3 ? <iframe src={youtube123.you3} frameborder="0" className="youtube-link-responsive" width="100%" height="595px" allow="fullscreen;"></iframe>  :  ""}
  </div>
  </div>
  </div>
</center>










<center>

<section className="bg0 p-t-63 p-b-60">
          <div className="container">
            <div className="p-b-10">
              <h2  style={{color : "black",fontWeight : "bold"}}>
              How it works
              </h2>
             {/* <br /> */}
             <p> <b>Go contactless in three simple steps !</b>  </p>
            </div>
            
           
            <div className="row isotope-grid">
       
                <div className="col-sm-6 col-md-4 col-lg-4 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                <div className="block2">
                <span style={{
                  // backgroundColor: "#f7bc44",
                  color: "#000",
                  borderRadius: "50%",
                  border : "1px solid black",
               
                  fontFamily: "Odudo Semi Bold",
                  fontWeight: "600",
                  fontSize: "12px",
                  height: "23px",
                  width: "23px",
                  marginRight: "5px",
                  padding: "12px 16px"
            }}>1</span>
           
                  <div className="block2-pic hov-img0" style={{marginTop : "30px"}}>
                    <Link ><img src={img1} alt="IMG-PRODUCT"  style={{height: "200px",objectFit: "cover",width: "200px"}}/></Link>
                  </div>
                  <br/>
                  <h4 style={{color : "black",fontWeight : 'bold'}}>Get a TAPITDABIT product
that works best for you
</h4>
                  <p style={{color : "black",cursor : "pointer"}} onClick={()=>props.history.push("/all-shop")}>Shop Now</p>
                </div>
              </div>
                <div className="col-sm-6 col-md-4 col-lg-4 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                <div className="block2">
                <span style={{
                  // backgroundColor: "#f7bc44",
                  color: "#000",
                  borderRadius: "50%",
                  border : "1px solid black",
               
                  fontFamily: "Odudo Semi Bold",
                  fontWeight: "600",
                  fontSize: "12px",
                  height: "23px",
                  width: "23px",
                  marginRight: "5px",
                  padding: "12px 16px"
            }}>2</span>
           
                  <div className="block2-pic hov-img0" style={{marginTop : "30px"}}>
                    <Link ><img src={img2} alt="IMG-PRODUCT"   style={{height: "200px",objectFit: "cover",width: "200px"}}/></Link>
                  </div>
                  <br/>
                  <h4 style={{color : "black",fontWeight : 'bold'}}>Activate your product
to your profile when it arrives
</h4>
{/* <p style={{color : "black"}}>Activate my Product</p> */}
             </div>
              </div>
                <div className="col-sm-6 col-md-4 col-lg-4 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                <div className="block2">
                <span style={{
                  // backgroundColor: "#f7bc44",
                  color: "#000",
                  borderRadius: "50%",
                  border : "1px solid black",
               
                  fontFamily: "Odudo Semi Bold",
                  fontWeight: "600",
                  fontSize: "12px",
                  height: "23px",
                  width: "23px",
                  marginRight: "5px",
                  padding: "12px 16px"
            }}>3</span>
           
                  <div className="block2-pic hov-img0" style={{marginTop : "30px"}}>
                    <Link ><img src={img3} alt="IMG-PRODUCT"  style={{height: "200px",objectFit: "cover",width: "200px"}}/></Link>
                  </div>
                  <br/>
                  <h4 style={{color : "black",fontWeight : 'bold'}}>Create Your Profile
You can add, edit & enable
what you would like to share with
your contactless card.
</h4>
{/* <p style={{color : "black"}}>Open my QR code</p> */}
                </div>
              </div>
               
            

            </div>

          </div>
        </section>


</center>











        {/* <section className="section-slide">
          <div className="wrap-slick1">
            <div className="slick1">
              <div className="item-slick1" className="s11">
                <div className="container h-full">
                  <div className="flex-col-l-m h-full p-t-100 p-b-30 respon5">
                    <div className="layer-slick1 animated visible-false" data-appear="fadeInDown" data-delay={0}>
                      <span className="ltext-101 cl2 respon2">
                        Women Collection 2018
                      </span>
                    </div>
                    <div className="layer-slick1 animated visible-false" data-appear="fadeInUp" data-delay={800}>
                      <h2 className="ltext-201 cl2 p-t-19 p-b-43 respon1">
                        NEW SEASON
                      </h2>
                    </div>
                    <div className="layer-slick1 animated visible-false" data-appear="zoomIn" data-delay={1600}>
                      <a href="product.html" className="flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04">
                        Shop Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-slick1" className="s22">
                <div className="container h-full">
                  <div className="flex-col-l-m h-full p-t-100 p-b-30 respon5">
                    <div className="layer-slick1 animated visible-false" data-appear="rollIn" data-delay={0}>
                      <span className="ltext-101 cl2 respon2">
                        Men New-Season
                      </span>
                    </div>
                    <div className="layer-slick1 animated visible-false" data-appear="lightSpeedIn" data-delay={800}>
                      <h2 className="ltext-201 cl2 p-t-19 p-b-43 respon1">
                        Jackets &amp; Coats
                      </h2>
                    </div>
                    <div className="layer-slick1 animated visible-false" data-appear="slideInUp" data-delay={1600}>
                      <a href="product.html" className="flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04">
                        Shop Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-slick1" className="s33">
                <div className="container h-full">
                  <div className="flex-col-l-m h-full p-t-100 p-b-30 respon5">
                    <div className="layer-slick1 animated visible-false" data-appear="rotateInDownLeft" data-delay={0}>
                      <span className="ltext-101 cl2 respon2">
                        Men Collection 2018
                      </span>
                    </div>
                    <div className="layer-slick1 animated visible-false" data-appear="rotateInUpRight" data-delay={800}>
                      <h2 className="ltext-201 cl2 p-t-19 p-b-43 respon1">
                        New arrivals
                      </h2>
                    </div>
                    <div className="layer-slick1 animated visible-false" data-appear="rotateIn" data-delay={1600}>
                      <a href="product.html" className="flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04">
                        Shop Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* Banner */}
        



{/* 
        <center>
        <h4 style={{color : "grey",fontWeight : "bold"}}>
        Now available on
        </h4>
        <div className="container">
          <div className="row">
             {
        NowAvailable.map((res,i)=>{
          return(
            <>
            <div className="arrival11 col-xl-4 col-lg-4 col-md-6 col-sm-6">
             <img src={res.photo} alt="" className="arrival-Photo111"  />
            </div>
            <div className="arrival11 col-xl-4 col-lg-4 col-md-6 col-sm-6">
             {res.photo1 ?<img src={res.photo1} alt="" className="arrival-Photo111"  /> : ""  }
            </div>
            <div className="arrival11 col-xl-4 col-lg-4 col-md-6 col-sm-6">
             {res.photo2 ?<img src={res.photo2} alt="" className="arrival-Photo111"  /> : ""  }
            </div>
          </>
          )
        })
        }
          </div>
        </div>
        
       
      </center> */}



                                       























{/* 

<center>

<section className="bg0 p-t-23 p-b-60" style={{background  : "black"}}>
          <div className="container">
            <div className="p-b-10">
              <h5 className="ltext-103 cl5" style={{color  :  'white'}}>
            HOW IT WORKS

              </h5>
              <p style={{color  :  'white'}}> <b>

              Go contactless in three simple steps!
              </b>
              </p>
            </div>
            
           
            <div className="row isotope-grid">
       
                <div className="col-sm-6 col-md-12 col-lg-4 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                <div className="block2">
                  <div className="block2-pic hov-img0">
                    <Link ><img src={how11} alt="IMG-PRODUCT"  style={{width:"120px",height : "60px"}}/></Link>
                  </div>
                  <br/>
                  <h4 style={{color : "white",fontWeight : 'bold'}}> <span style={{
                  backgroundColor: "#f7bc44",
                  color: "#000",
                  borderRadius: "50%",
               
                  fontFamily: "Odudo Semi Bold",
                  fontWeight: "600",
                  fontSize: "12px",
                  height: "23px",
                  width: "23px",
                  marginRight: "5px",
                  padding: "6px 10px"
            }}>1</span> Purchase Your Card</h4>
                  <p style={{color : "white",width :"350px"}}>After placing your order, you will receive instructions on how to provide us with your logo or design for on the card.</p>
                </div>
              </div>
                <div className="col-sm-6 col-md-12 col-lg-4 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                <div className="block2">
                  <div className="block2-pic hov-img0">
                    <Link ><img src={how22} alt="IMG-PRODUCT"  style={{width:"120px",height : "60px"}}/></Link>
                  </div>
                  <br/>
                  <h4 style={{color : "white",fontWeight : 'bold'}}><span style={{
                  backgroundColor: "#f7bc44",
                  color: "#000",
                  borderRadius: "50%",
               
                  fontFamily: "Odudo Semi Bold",
                  fontWeight: "600",
                  fontSize: "12px",
                  height: "23px",
                  width: "23px",
                  marginRight: "5px",
                  padding: "6px 10px"
            }}>2</span>Create Your Profile</h4>
<p style={{color : "white",width :"350px"}}>Here you can add, edit & enable what you would like to share with your contactless card.
  </p>                </div>
              </div>
                <div className="col-sm-6 col-md-12 col-lg-4 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                <div className="block2">
                  <div className="block2-pic hov-img0">
                    <Link ><img src={how33} alt="IMG-PRODUCT"  style={{width:"120px",height : "60px"}}/></Link>
                  </div>
                  <br/>
                  <h4 style={{color : "white",fontWeight : 'bold'}}><span style={{
                  backgroundColor: "#f7bc44",
                  color: "#000",
                  borderRadius: "50%",
               
                  fontFamily: "Odudo Semi Bold",
                  fontWeight: "600",
                  fontSize: "12px",
                  height: "23px",
                  width: "23px",
                  marginRight: "5px",
                  padding: "6px 10px"
            }}>3</span>Tap, Share & Go</h4>
                  <p style={{color : "white",width :"350px"}}>It's that simple! Share who you are and what you do with with one quick tap.</p>
                </div>
              </div>
            
            

            </div>

          </div>
        </section>


</center> */}



















































{/* <center style={{width : "100%"}}>
        <video class="video-fluid" autoPlay loop muted class="w-100 carousel pj3" style={{height : "650px" ,minWidth : "100%"}}>
                                                                  <source src={h10} type="video/mp4"/>
                                                                </video>
</center> */}

         




        {/* Product */}
        <section className="bg0 p-t-23 p-b-40">
          <div className="container">
            <div className="p-b-10">
              {/* <h3 className="ltext-103 cl5"> */}
              <h2  style={{color : "black",fontWeight : "bold"}}>
               <center>

                Top Product
               </center>
              </h2>
              {/* </h3> */}
            </div>
            <div className="flex-w flex-sb-m p-b-52">
              <div className="flex-w flex-l-m filter-tope-group m-tb-10">
                <button className="stext-106 cl6 hov1 bor3 trans-04 m-r-32 m-tb-5 how-active1" data-filter="*">
                All Products
                </button>
                {MainCatogories.map((res,i)=>{
                  return (
                        <button className="stext-106 cl6 hov1 bor3 trans-04 m-r-32 m-tb-5" data-filter=".women">
                          <Link to={"/categories/"+res.MainCategories+"/product"}>
                          {res.MainCategories
                        }
                          </Link>

                        </button>
                  )
                })

                }
               
              </div>
              
              {/* Search product */}
              
              {/* Filter */}
              
            </div>
           
            <div className="row isotope-grid">
            {product.map((res,i)=>{
                if(res.Price[0]){
                  return (
                    <div className="col-sm-6 col-md-4 col-lg-4 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                    <div className="block2">
                      <div className="block2-pic hov-img0">
                      <Link  to={"/main-categories/"+res.Product_Catagories+"/product/"+res._id+"/"+res.Product_Name} ><img src={res.Product_Image_Upload} alt="IMG-PRODUCT"  style={{height : "300px", objectFit  :"cover",height: "fitContent"}}/></Link>
                        <Link to={"/main-categories/"+res.Product_Catagories+"/product/"+res._id+"/"+res.Product_Name} className="block2-btn flex-c-m stext-103 cl2 size-102 bg0 bor2 hov-btn1 p-lr-15 trans-04 js-show-modal1">
                          Quick View
                        </Link>
                      </div>
                      <div className="block2-txt flex-w flex-t p-t-14" style={{padding :  "10px 13px"}}>
                        <div className="block2-txt-child1 flex-col-l ">
                          <Link to={"/main-categories/"+res.Product_Catagories+"/"+"product/"+res._id+"/"+res.Product_Name} className="stext-104 cl4 hov-cl1 trans-04 js-name-b2 p-b-6">
                          <b>
                            <h1 style={{color: "black", fontSize:" 16px",fontWeight: "bold"}}>
                             {
                              res.Product_Name
                            } 
                            </h1>
                            
                              </b>                       </Link>
    
    
    
                          <span className="stext-105 cl3">
                              <i>
                                 {res.Size_Discounted[0] ? 
                                                            <div style={{display : "flex",justifyContent : 'center',fontSize : "19px"}}>
                                                                <p  style={{color  : "black"  }}>Rs : {res.Size_Discounted[0]}</p>
                                                                <div style={{width : "8px"}}></div>
                                                                <p style={{color  : "grey"  }}> <b><s>Rs : {res.Price[0]}</s></b>  </p>
                                                                <div style={{width : "8px"}}></div>
                                                                <p style={{color  : "black"  }}> <b>Size : {res.Size[0]}</b>  </p>
                                                            </div>
                                                            : 
                                                            <div style={{display : "flex",justifyContent : 'center',fontSize : "19px"}}>
                                                            <p style={{color  : "black"  ,fontSize : "21px"}}>Rs : {res.Price[0]}</p>
                                                              <div style={{width : "8px"}}></div>
                                                              <p style={{color  : "black"  }}> <b>Size : {res.Size[0]}</b>  </p>
                                                          </div>
    
                                                            }    
                              </i>
                          
    
                                                                                  </span>
                        </div>
                       <div className="block2-txt-child2 flex-r p-t-3">
                          <a href="#" className="btn-addwish-b2 dis-block pos-relative js-addwish-b2">
                          <i className="zmdi zmdi-shopping-cart dis-block trans-04"  style={{fontSize : "24px",color:"black" ,background :"rgb(238, 238, 238)",padding: "10px 13px 10px 12px",borderRadius: "39px"}}  onClick={() => addtocartproduct([res])  }/>
    
                            {/* <img className="icon-heart1 dis-block trans-04" src={h1} alt="ICON" />
                            <img className="icon-heart2 dis-block trans-04 ab-t-l" src={h2} alt="ICON" /> */}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                    )
                  }else{
                    if(res.Product_Name === "Executive Black Metal Hybrid Card "                    ){
                      return (
                      <div className="col-sm-6 col-md-4 col-lg-4 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                      <div className="block2">
                        <div className="block2-pic hov-img0">
                        <Link  to={"/main-category/"+res.Product_Catagories+"/product/"+res._id+"/"+res.Product_Name} ><img src={res.Product_Image_Upload} alt="IMG-PRODUCT"  style={{height : "300px", objectFit  :"cover",height: "fitContent"}}/></Link>
                          <Link to={"/main-category/"+res.Product_Catagories+"/product/"+res._id+"/"+res.Product_Name} className="block2-btn flex-c-m stext-103 cl2 size-102 bg0 bor2 hov-btn1 p-lr-15 trans-04 js-show-modal1">
                            Quick View
                          </Link>
                        </div>
                        <div className="block2-txt flex-w flex-t p-t-14" style={{padding :  "10px 13px"}}>
                          <div className="block2-txt-child1 flex-col-l ">
                            <Link to={"/main-category/"+res.Product_Catagories+"/"+"product/"+res._id+"/"+res.Product_Name} className="stext-104 cl4 hov-cl1 trans-04 js-name-b2 p-b-6">
                            <b>
                            <h1 style={{color: "black", fontSize:" 16px",fontWeight: "bold"}}>
                             {
                              res.Product_Name
                            } 
                            </h1>
                            
                              </b>                        </Link>
      
      
      
                            <span className="stext-105 cl3">
                                <i>
                                   {res.Product_Price_Discounted ? 
                                                              <div style={{display : "flex",justifyContent : 'center',fontSize : "21px"}}>
                                                                  <p  style={{color  : "black"  }}>Rs : {res.Product_Price_Discounted}</p>
                                                                  <div style={{width : "10px"}}></div>
                                                                  <p style={{color  : "grey"  }}> <b><s>Rs : {res.Product_Price}</s></b>  </p>
                                                              </div>
                                                              : 
                                                              <p style={{color  : "black"  ,fontSize : "21px"}}>Rs : {res.Product_Price}</p>
      
                                                              }    
                                </i>
                            
      
                                                                                    </span>
                          </div>
                         <div className="block2-txt-child2 flex-r p-t-3">
                            <a href="#" className="btn-addwish-b2 dis-block pos-relative js-addwish-b2">
                            <i className="zmdi zmdi-shopping-cart dis-block trans-04"  style={{fontSize : "24px",color:"black" ,background :"rgb(238, 238, 238)",padding: "10px 13px 10px 12px",borderRadius: "39px"}}  onClick={() => addtocartproduct([res])  }/>
      
                              {/* <img className="icon-heart1 dis-block trans-04" src={h1} alt="ICON" />
                              <img className="icon-heart2 dis-block trans-04 ab-t-l" src={h2} alt="ICON" /> */}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div> 
                      )
                    }
                    else{
                      return (
                        <div className="col-sm-6 col-md-4 col-lg-4 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                        <div className="block2">
                          <div className="block2-pic hov-img0">
                          <Link  to={"/categories/"+res.Product_Catagories+"/product/"+res._id+"/"+res.Product_Name} ><img src={res.Product_Image_Upload} alt="IMG-PRODUCT"  style={{height : "300px", objectFit  :"cover",height: "fitContent"}}/></Link>
                            <Link to={"/categories/"+res.Product_Catagories+"/product/"+res._id+"/"+res.Product_Name} className="block2-btn flex-c-m stext-103 cl2 size-102 bg0 bor2 hov-btn1 p-lr-15 trans-04 js-show-modal1">
                              Quick View
                            </Link>
                          </div>
                          <div className="block2-txt flex-w flex-t p-t-14" style={{padding :  "10px 13px"}}>
                            <div className="block2-txt-child1 flex-col-l ">
                              <Link to={"/categories/"+res.Product_Catagories+"/"+"product/"+res._id+"/"+res.Product_Name} className="stext-104 cl4 hov-cl1 trans-04 js-name-b2 p-b-6">
                              <b>
                            <h1 style={{color: "black", fontSize:" 16px",fontWeight: "bold"}}>
                             {
                              res.Product_Name
                            } 
                            </h1>
                            
                              </b>                   </Link>
        
        
        
                              <span className="stext-105 cl3">
                                  <i>
                                     {res.Product_Price_Discounted ? 
                                                                <div style={{display : "flex",justifyContent : 'center',fontSize : "21px"}}>
                                                                    <p  style={{color  : "black"  }}>Rs : {res.Product_Price_Discounted}</p>
                                                                    <div style={{width : "10px"}}></div>
                                                                    <p style={{color  : "grey"  }}> <b><s>Rs : {res.Product_Price}</s></b>  </p>
                                                                </div>
                                                                : 
                                                                <p style={{color  : "black"  ,fontSize : "21px"}}>Rs : {res.Product_Price}</p>
        
                                                                }    
                                  </i>
                              
        
                                                                                      </span>
                            </div>
                           <div className="block2-txt-child2 flex-r p-t-3">
                              <a href="#" className="btn-addwish-b2 dis-block pos-relative js-addwish-b2">
                              <i className="zmdi zmdi-shopping-cart dis-block trans-04"  style={{fontSize : "24px",color:"black" ,background :"rgb(238, 238, 238)",padding: "10px 13px 10px 12px",borderRadius: "39px"}}  onClick={() => addtocartproduct([res])  }/>
                              {/* <i className="zmdi zmdi-shopping-cart dis-block trans-04"  style={{fontSize : "24px",color:"black" ,background :"rgb(238, 238, 238)",padding: "10px 13px 10px 12px",borderRadius: "39px"}}  onClick={() =>res.Product_Catagories !==  "Custom Products" ? addtocartproduct([res]) : addtocartproduct12([res])  }/> */}
        
                                {/* <img className="icon-heart1 dis-block trans-04" src={h1} alt="ICON" />
                                <img className="icon-heart2 dis-block trans-04 ab-t-l" src={h2} alt="ICON" /> */}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div> 
                        )
                    }
                    
                    
                }
                
            })
          
          }
             
            </div>
            {/* Load more */}
            <div className="flex-c-m flex-w w-full p-t-5">
              <Link to={"/all-shop"} className="flex-c-m stext-101 cl5 size-103 bg2 bor1 hov-btn1 p-lr-15 trans-04" style={{background: "black",color : "white",width   :"200px"}}>
              See all products
              </Link>
            </div>
          </div>
        </section>
        {/* Footer */}
        







        <div className="site-section" style={{padding : "20px 0px",margin : "10px 0px"}}>
                    <div className="container">
                          <div className="p-b-10">
                    {/* <h3 className="ltext-103 cl5"> */}
                    <h2  style={{color : "black",fontWeight : "bold"}}>

                      <center>
                      Frequently Asked Questions

                      </center>
                    </h2>
                    {/* </h3> */}
                  </div>
                        <div className="row">
                            
                                {data.map((res2,a)=>{
                                    return (
                                        <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3"  style={{margin : "0px 14px"}}>
                                            <div style={{display : "flex"}} onClick={()=>setChangerItemSize(a)}  href={"#collapsebank"+a} aria-controls={"collapsebank"+a}  data-toggle="collapse" >

<KeyboardArrowDownIcon style={{color : "black" ,marginRight: "20px",marginTop: "-4px"}} id={"changeIcon1"+a} href={"#collapsebank"+a} aria-controls={"collapsebank"+a}  data-toggle="collapse"   />   <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href={"#collapsebank"+a} role="button" aria-expanded="false" aria-controls={"collapsebank"+a} style={{fontSize: "16px",
    fontWeight: "bold",
    color: "black"}}> 
{res2.main}</a></h3>
        </div>
                                        <div className="collapse" id={"collapsebank"+a}>
                                            <div className="py-2">
                                            <h6 className="stext-102 cl6" style={{fontSize: "14px",
    fontWeight: "bold",
    color: "black"}}>
                                                 {   res2.text.split("\n").map((res4)=>{
                                                            return (
                                                                <>
                                                                {res4} <br/>
                                                                </>
                                                            )
                                                })
                                                    
                                                    
                                                }</h6>
                                            </div>
                                        
                                        </div>



                                    </div>      
       
             </div>

             )
            })
          }
          </div>
          </div>
          </div>
                                       



















        {/* Back to top */}
        <div className="btn-back-to-top" id="myBtn">
          <span className="symbol-btn-back-to-top">
            <i className="zmdi zmdi-chevron-up" />
          </span>
        </div>
        {/* Modal1 */}
        <div className="wrap-modal1 js-modal1 p-t-60 p-b-20">
          <div className="overlay-modal1 js-hide-modal1" />
          <div className="container">
            <div className="bg0 p-t-60 p-b-30 p-lr-15-lg how-pos3-parent">
              <button className="how-pos3 hov3 trans-04 js-hide-modal1">
                <img src="images/icons/icon-close.png" alt="CLOSE" />
              </button>
              <div className="row">
                <div className="col-md-6 col-lg-7 p-b-30">
                  <div className="p-l-25 p-r-30 p-lr-0-lg">
                    <div className="wrap-slick3 flex-sb flex-w">
                      <div className="wrap-slick3-dots" />
                      <div className="wrap-slick3-arrows flex-sb-m flex-w" />
                      <div className="slick3 gallery-lb">
                        <div className="item-slick3" data-thumb="images/product-detail-01.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-01.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-01.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-02.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-02.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-02.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-03.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-03.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-03.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5 p-b-30">
                  <div className="p-r-50 p-t-5 p-lr-0-lg">
                    <h4 className="mtext-105 cl2 js-name-detail p-b-14">
                      Lightweight Jacket
                    </h4>
                    <span className="mtext-106 cl2">
                      $58.79
                    </span>
                    <p className="stext-102 cl3 p-t-23">
                      Nulla eget sem vitae eros pharetra viverra. Nam vitae luctus ligula. Mauris consequat ornare feugiat.
                    </p>
                    {/*  */}
                    <div className="p-t-33">
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Size
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Size S</option>
                              <option>Size M</option>
                              <option>Size L</option>
                              <option>Size XL</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Color
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Red</option>
                              <option>Blue</option>
                              <option>White</option>
                              <option>Grey</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-204 flex-w flex-m respon6-next">
                          <div className="wrap-num-product flex-w m-r-20 m-tb-10">
                            <div className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-minus" />
                            </div>
                            <input className="mtext-104 cl3 txt-center num-product" type="number" name="num-product" defaultValue={1} />
                            <div className="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-plus" />
                            </div>
                          </div>
                          <button className="flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04 js-addcart-detail">
                            Add to cart
                          </button>
                        </div>
                      </div>	
                    </div>
                    {/*  */}
                    <div className="flex-w flex-m p-l-100 p-t-40 respon7">
                      <div className="flex-m bor9 p-r-10 m-r-11">
                        <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 js-addwish-detail tooltip100" data-tooltip="Add to Wishlist">
                          <i className="zmdi zmdi-favorite" />
                        </a>
                      </div>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Facebook">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Twitter">
                        <i className="fa fa-twitter" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Google Plus">
                        <i className="fa fa-google-plus" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
 







//             <div className="site-wrap">
// <center>
//             <div className="pop-up-1 dis-off" id="myDi">
//                 <h1>Product Updated in Cart</h1>
//             </div> 
//         </center>
               

//                 <section>
//                     <div className="container-fuild">
//                         <div className="col-xl-12 col-lg-12 col-md-12" style={{width : "100%", margin  : "0px",padding: "0px"}} >
//                                         <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" data-interval="2000" auto-play="true">
//                                         <ol class="carousel-indicators">
//                                             <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
//                                             <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
//                                             <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
//                                             <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
//                                         </ol>
//                                             <div class="carousel-inner">
//                                             {
//                                                 SliderPhoto.length > 0 && SliderPhoto.map((res,i)=>{
//                                                     console.log(res)
//                                                         return (
//                                                              <>
//                                                             <div class="carousel-item active" >
//                                                                 <img class="d-block w-100" src={res.photo1} alt="First slide"  style={{height:"300px"}}/>
//                                                             </div>
                                                       
//                                                                 <div class="carousel-item" >
//                                                                     <img class="d-block w-100" src={res.photo2} alt="Second slide"  style={{height:"300px"}}/>
//                                                                 </div>
//                                                                 <div class="carousel-item" >
//                                                                     <img class="d-block w-100" src={res.photo3} alt="Third slide"  style={{height:"300px"}}/>
//                                                                 </div>
//                                                                 <div class="carousel-item" >
//                                                                     <img class="d-block w-100" src={res.photo4} alt="Fourth slide"  style={{height:"300px"}}/>
//                                                                 </div>
//                                                             </>
//                                                         )
//                                                 })
//                                             }
                                              
//                                             </div>
//                                             <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
//                                                 <span class="carousel-control-prev-icon" aria-hidden="true"></span>
//                                                 <span class="sr-only">Previous</span>
//                                             </a>
//                                             <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
//                                                 <span class="carousel-control-next-icon" aria-hidden="true"></span>
//                                                 <span class="sr-only">Next</span>
//                                             </a>
//                                         </div>
//                         </div>
//                     </div>
//                 </section>


//                 <section>
//                     <div className="container-fuild" style={{margin : "70px auto 20px auto"  , width : "70%"}}>
//                             <div className="row justify-content-center">
//                                 <div className="text-center col-12">
//                                     <h2 style={{color : "black"}} > <b>New Arrivals</b> </h2>
//                                     <center>
//                                         <p style={{width : "220px" , border : "0.5px solid grey" }}></p>
//                                     </center>
//                                 </div> 
                               






//                                 <div className="row mb-5">
//                                    {dis ?
//                                     product.length > 0 ?
//                                     product.length === 3 ?
//                                     product.map((res,i)=>{
//                                         if(res.Product_Catagories === "books" || res.Product_Catagories === "Uniform"){
//                                           return (
//                                             <div className="col-xl-4 col-sm-12 col-lg-6 mb-12" data-aos="fade-up" style={{marginBottom : "30px"}}>
//                                                 <div className="block-4 text-center hover-product-card" style={{width: "300px"}}>
//                                                 <span style={{margin : "10px 4px" , color : "red", fontSize : "20px" , fontWeight : "bold",textAlign : "left"}}>
//                                                 {res.Size_Discounted[0] ?
//                                                     <span>{(100 - ( res.Size_Discounted[0]*100) /  res.Price[0]).toFixed(2)}  % off</span>
//                                                     : 
//                                                 res.Product_Price_Discounted ?
//                                                     <span>{(100 - (res.Product_Price_Discounted *100) /  res.Product_Price).toFixed(2)}  % off</span>
//                                                     : ""
//                                                 }
//                                                 </span>
//                                                     <figure className="block-4-image">
//                                                     <Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/"+res._id+"/"+res.Product_Name} ><img src={res.Product_Image_Upload}  style={{height : "210px" , width : "200px" }}  alt="Image placeholder" className="img-fluid" /></Link>
//                                                     </figure>
//                                                     <div className="block-4-text p-4">
//                                                         <h3><Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/"+res._id+"/"+res.Product_Name} > {res.Product_Name}</Link></h3>
//                                                         <p className="mb-0">{res.Product_Title}</p>
//                                                         {
//                                                             res.Size_Discounted[0] ?
//                                                             <div style={{display : "flex",justifyContent : 'center',fontSize : "21px"}}>
//                                                             <p  style={{color  : "black"  }}>Rs: {res.Size_Discounted[0]}</p>
//                                                             <div style={{width : "10px"}}></div>
//                                                             <p style={{color  : "grey"  }}> <b><s>Rs: {res.Price[0]}</s></b>  </p>
//                                                             <div style={{width : "10px"}}></div>
//                                                             <p  style={{color  : "black"  }}>Rs: {res.Size[0]}</p>
//                                                             </div>
//                                                             :
                                                            
//                                                             !res.Product_Price_Discounted ?
//                                                                 <>
//                                                             <p style={{color  : "grey"  }}> <b>Rs : {res.Product_Price}</b>  </p>
//                                                             <div style={{width : "10px"}}></div>
//                                                             </>
                                                                                                                  
//                                                         :
//                                                             res.Product_Price_Discounted ? 
//                                                         <div style={{display : "flex",justifyContent : 'center',fontSize : "21px"}}>
//                                                             <p  style={{color  : "black"  }}>Rs: {res.Product_Price_Discounted}</p>
//                                                             <div style={{width : "10px"}}></div>
//                                                             <p style={{color  : "grey"  }}> <b><s>Rs: {res.Product_Price}</s></b>  </p>
//                                                         </div>
//                                                         : 
//                                                         <p style={{color  : "black"  }}>{res.Product_Price}</p>

//                                                         }
//                                                         <div onClick={()=>settoBags(res)} className="btn btn-dark btn-group-lg view-btn-detail">Add To Bag</div>

//                                                     </div>
//                                                 </div>
                                               
//                                             </div> 
//                                         )  
//                                         }
//                                         else{
//                                             return (
//                                             <div className="col-xl-4 col-sm-12 col-lg-6 mb-12" data-aos="fade-up" style={{marginBottom : "30px"}}>
//                                                 <div className="block-4 text-center hover-product-card" style={{width: "300px"}}>
//                                                 <span style={{margin : "10px 4px" , color : "red", fontSize : "20px" , fontWeight : "bold",textAlign : "left"}}>
//                                                 {res.Size_Discounted[0] ?
//                                                     <span>{(100 - ( res.Size_Discounted[0]*100) /  res.Price[0]).toFixed(2)}  % off</span>
//                                                     : 
//                                                 res.Product_Price_Discounted ?
//                                                     <span>{(100 - (res.Product_Price_Discounted *100) /  res.Product_Price).toFixed(2)}  % off</span>
//                                                     : ""
//                                                 }
//                                                 </span>
//                                                     <figure className="block-4-image">
//                                                     <Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res._id+"/"+res.Product_Name} ><img src={res.Product_Image_Upload}  style={{height : "210px" , width : "200px" }}  alt="Image placeholder" className="img-fluid" /></Link>
//                                                     </figure>
//                                                     <div className="block-4-text p-4">
//                                                         <h3><Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res._id+"/"+res.Product_Name}> {res.Product_Name}</Link></h3>
//                                                         <p className="mb-0">{res.Product_Title}</p>
//                                                         {
//                                                             res.Size_Discounted[0] ?
//                                                             <div style={{display : "flex",justifyContent : 'center',fontSize : "21px"}}>
//                                                             <p  style={{color  : "black"  }}>Rs: {res.Size_Discounted[0]}</p>
//                                                             <div style={{width : "10px"}}></div>
//                                                             <p style={{color  : "grey"  }}> <b><s>Rs: {res.Price[0]}</s></b>  </p>
//                                                             <div style={{width : "10px"}}></div>
//                                                             <p  style={{color  : "black"  }}>Size: {res.Size[0]}</p>
//                                                             </div>
//                                                             :
                                                            
//                                                             !res.Product_Price_Discounted ?
//                                                                 <>
//                                                             <p style={{color  : "grey"  }}> <b>Rs : {res.Product_Price}</b>  </p>
//                                                             <div style={{width : "10px"}}></div>
//                                                             </>
                                                                                                                  
//                                                         :
//                                                             res.Product_Price_Discounted ? 
//                                                         <div style={{display : "flex",justifyContent : 'center',fontSize : "21px"}}>
//                                                             <p  style={{color  : "black"  }}>Rs: {res.Product_Price_Discounted}</p>
//                                                             <div style={{width : "10px"}}></div>
//                                                             <p style={{color  : "grey"  }}> <b><s>Rs: {res.Product_Price}</s></b>  </p>
//                                                         </div>
//                                                         : 
//                                                         <p style={{color  : "black"  }}>{res.Product_Price}</p>

//                                                         }
//                                                         <div onClick={()=>settoBags(res)} className="btn btn-dark btn-group-lg view-btn-detail">Add To Bag</div>

//                                                     </div>
//                                                 </div>
                                               
//                                             </div> 
//                                         ) 
//                                         }
                                        
//                                     }) 
//                                     :
//                                      <span style={{margin:  "auto"}}>
//                                         <h3></h3>
//                                     </span>
//                                     :
//                                      <span style={{margin:  "auto"}}>
//                                     </span>
//                                     :
//                                      <span style={{margin:  "auto"}}>
//                                     </span>
//                                    }
                                  
                                    
//                                 </div>
//                                 <div className="row mb-5">
//                                    {dis ?
//                                     product.length > 0 ?
//                                     product.length === 2 ?
//                                     product.map((res,i)=>{
//                                         if(res.Product_Catagories === "books" && res.Product_Catagories === "Uniform"){
//                                           return (
//                                             <div className="col-xl-6 col-sm-12 col-lg-6 mb-12" data-aos="fade-up" style={{marginBottom : "30px",paddingLeft:"50px"}}>
//                                                 <div className="block-4 text-center hover-product-card" style={{width: "300px"}}>
//                                                 <span style={{margin : "10px 4px" , color : "red", fontSize : "20px" , fontWeight : "bold",textAlign : "left"}}>
//                                                 {res.Size_Discounted[0] ?
//                                                     <span>{(100 - ( res.Size_Discounted[0]*100) /  res.Price[0]).toFixed(2)}  % off</span>
//                                                     : 
//                                                 res.Product_Price_Discounted ?
//                                                     <span>{(100 - (res.Product_Price_Discounted *100) /  res.Product_Price).toFixed(2)}  % off</span>
//                                                     : ""
//                                                 }
//                                                 </span>
//                                                     <figure className="block-4-image">
                                                   
//                                                     <Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/"+res._id+"/"+res.Product_Name} ><img src={res.Product_Image_Upload}  style={{height : "210px" , width : "200px" }}  alt="Image placeholder" className="img-fluid" /></Link>
                                         
//                                                     </figure>
//                                                     <div className="block-4-text p-4">
//                                                         <h3><Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/"+res._id+"/"+res.Product_Name} > {res.Product_Name}</Link></h3>
//                                                         <p className="mb-0">{res.Product_Title}</p>
//                                                         {
//                                                             res.Size_Discounted[0] ?
//                                                             <div style={{display : "flex",justifyContent : 'center',fontSize : "21px"}}>
//                                                             <p  style={{color  : "black"  }}>Rs: {res.Size_Discounted[0]}</p>
//                                                             <div style={{width : "10px"}}></div>
//                                                             <p style={{color  : "grey"  }}> <b><s>Rs: {res.Price[0]}</s></b>  </p>
//                                                             <div style={{width : "10px"}}></div>
//                                                             <p  style={{color  : "black"  }}>Rs: {res.Size[0]}</p>
//                                                             </div>
//                                                             :
                                                            
//                                                             !res.Product_Price_Discounted ?
//                                                                 <>
//                                                             <p style={{color  : "grey"  }}> <b>Rs : {res.Product_Price}</b>  </p>
//                                                             <div style={{width : "10px"}}></div>
//                                                             </>
                                                                                                                  
//                                                         :
//                                                             res.Product_Price_Discounted ? 
//                                                         <div style={{display : "flex",justifyContent : 'center',fontSize : "21px"}}>
//                                                             <p  style={{color  : "black"  }}>Rs: {res.Product_Price_Discounted}</p>
//                                                             <div style={{width : "10px"}}></div>
//                                                             <p style={{color  : "grey"  }}> <b><s>Rs: {res.Product_Price}</s></b>  </p>
//                                                         </div>
//                                                         : 
//                                                         <p style={{color  : "black"  }}>{res.Product_Price}</p>

//                                                         }
//                                                         <div onClick={()=>settoBags(res)} className="btn btn-dark btn-group-lg view-btn-detail">Add To Bag</div>

//                                                     </div>
//                                                 </div>
                                               
//                                             </div> 
//                                         )  
//                                         }
//                                         else{
//                                             return (
//                                             <div className="col-xl-6 col-sm-12 col-lg-6 mb-12" data-aos="fade-up" style={{marginBottom : "30px"}}>
//                                                 <div className="block-4 text-center hover-product-card" style={{width: "300px"}}>
//                                                 <span style={{margin : "10px 4px" , color : "red", fontSize : "20px" , fontWeight : "bold",textAlign : "left"}}>
//                                                 {res.Size_Discounted[0] ?
//                                                     <span>{(100 - ( res.Size_Discounted[0]*100) /  res.Price[0]).toFixed(2)}  % off</span>
//                                                     : 
//                                                 res.Product_Price_Discounted ?
//                                                     <span>{(100 - (res.Product_Price_Discounted *100) /  res.Product_Price).toFixed(2)}  % off</span>
//                                                     : ""
//                                                 }
//                                                 </span>
//                                                     <figure className="block-4-image">
//                                                     <Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res._id+"/"+res.Product_Name} ><img src={res.Product_Image_Upload}  style={{height : "210px" , width : "200px" }}  alt="Image placeholder" className="img-fluid" /></Link>
//                                                     </figure>
//                                                     <div className="block-4-text p-4">
//                                                         <h3><Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res._id+"/"+res.Product_Name}> {res.Product_Name}</Link></h3>
//                                                         <p className="mb-0">{res.Product_Title}</p>
//                                                         {
//                                                             res.Size_Discounted[0] ?
//                                                             <div style={{display : "flex",justifyContent : 'center',fontSize : "21px"}}>
//                                                             <p  style={{color  : "black"  }}>Rs: {res.Size_Discounted[0]}</p>
//                                                             <div style={{width : "10px"}}></div>
//                                                             <p style={{color  : "grey"  }}> <b><s>Rs: {res.Price[0]}</s></b>  </p>
//                                                             <div style={{width : "10px"}}></div>
//                                                             <p  style={{color  : "black"  }}>Size: {res.Size[0]}</p>
//                                                             </div>
//                                                             :
                                                            
//                                                             !res.Product_Price_Discounted ?
//                                                                 <>
//                                                             <p style={{color  : "grey"  }}> <b>Rs : {res.Product_Price}</b>  </p>
//                                                             <div style={{width : "10px"}}></div>
//                                                             </>
                                                                                                                  
//                                                         :
//                                                             res.Product_Price_Discounted ? 
//                                                         <div style={{display : "flex",justifyContent : 'center',fontSize : "21px"}}>
//                                                             <p  style={{color  : "black"  }}>Rs: {res.Product_Price_Discounted}</p>
//                                                             <div style={{width : "10px"}}></div>
//                                                             <p style={{color  : "grey"  }}> <b><s>Rs: {res.Product_Price}</s></b>  </p>
//                                                         </div>
//                                                         : 
//                                                         <p style={{color  : "black"  }}>{res.Product_Price}</p>

//                                                         }
//                                                         <div onClick={()=>settoBags(res)} className="btn btn-dark btn-group-lg view-btn-detail">Add To Bag</div>

//                                                     </div>
//                                                 </div>
                                               
//                                             </div> 
//                                         ) 
//                                         }
                                        
//                                     }) 
//                                     :
//                                      <span style={{margin:  "auto"}}>
//                                         <h3></h3>
//                                     </span>
//                                     :
//                                      <span style={{margin:  "auto"}}>
//                                     </span>
//                                     :
//                                      <span style={{margin:  "auto"}}>
//                                     </span>
//                                    }
                                     
                                    
//                                 </div>
//                                 <div className="row mb-5">
//                                    {dis ?
//                                     product.length > 0 ?
//                                     product.length === 1 ?
//                                     product.map((res,i)=>{
//                                         if(res.Product_Catagories === "books" || res.Product_Catagories === "Uniform"){
//                                           return (
//                                             <div className="col-xl-6 col-sm-12 col-lg-6 mb-12" data-aos="fade-up" style={{marginBottom : "30px",paddingLeft:"50px"}}>
//                                                 <div className="block-4 text-center hover-product-card" style={{width: "300px"}}>
//                                                 <span style={{margin : "10px 4px" , color : "red", fontSize : "20px" , fontWeight : "bold",textAlign : "left"}}>
//                                                 {res.Size_Discounted[0] ?
//                                                     <span>{(100 - ( res.Size_Discounted[0]*100) /  res.Price[0]).toFixed(2)}  % off</span>
//                                                     : 
//                                                 res.Product_Price_Discounted ?
//                                                     <span>{(100 - (res.Product_Price_Discounted *100) /  res.Product_Price).toFixed(2)}  % off</span>
//                                                     : ""
//                                                 }
//                                                 </span>
//                                                     <figure className="block-4-image">
//                                                     <Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/"+res._id+"/"+res.Product_Name} ><img src={res.Product_Image_Upload}  style={{height : "210px" , width : "200px" }}  alt="Image placeholder" className="img-fluid" /></Link>
//                                                     </figure>
//                                                     <div className="block-4-text p-4">
//                                                         <h3><Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/"+res._id+"/"+res.Product_Name} > {res.Product_Name}</Link></h3>
//                                                         <p className="mb-0">{res.Product_Title}</p>
//                                                         {
//                                                             res.Size_Discounted[0] ?
//                                                             <div style={{display : "flex",justifyContent : 'center',fontSize : "21px"}}>
//                                                             <p  style={{color  : "black"  }}>Rs: {res.Size_Discounted[0]}</p>
//                                                             <div style={{width : "10px"}}></div>
//                                                             <p style={{color  : "grey"  }}> <b><s>Rs: {res.Price[0]}</s></b>  </p>
//                                                             <div style={{width : "10px"}}></div>
//                                                             <p  style={{color  : "black"  }}>Rs: {res.Size[0]}</p>
//                                                             </div>
//                                                             :
                                                            
//                                                             !res.Product_Price_Discounted ?
//                                                                 <>
//                                                             <p style={{color  : "grey"  }}> <b>Rs : {res.Product_Price}</b>  </p>
//                                                             <div style={{width : "10px"}}></div>
//                                                             </>
                                                                                                                  
//                                                         :
//                                                             res.Product_Price_Discounted ? 
//                                                         <div style={{display : "flex",justifyContent : 'center',fontSize : "21px"}}>
//                                                             <p  style={{color  : "black"  }}>Rs: {res.Product_Price_Discounted}</p>
//                                                             <div style={{width : "10px"}}></div>
//                                                             <p style={{color  : "grey"  }}> <b><s>Rs: {res.Product_Price}</s></b>  </p>
//                                                         </div>
//                                                         : 
//                                                         <p style={{color  : "black"  }}>{res.Product_Price}</p>

//                                                         }
//                                                         <div onClick={()=>settoBags(res)} className="btn btn-dark btn-group-lg view-btn-detail">Add To Bag</div>

//                                                     </div>
//                                                 </div>
                                               
//                                             </div> 
//                                         )  
//                                         }
//                                         else{
//                                             return (
//                                             <div className="col-xl-6 col-sm-12 col-lg-6 mb-12" data-aos="fade-up" style={{marginBottom : "30px"}}>
//                                                 <div className="block-4 text-center hover-product-card" style={{width: "300px"}}>
//                                                 <span style={{margin : "10px 4px" , color : "red", fontSize : "20px" , fontWeight : "bold",textAlign : "left"}}>
//                                                 {res.Size_Discounted[0] ?
//                                                     <span>{(100 - ( res.Size_Discounted[0]*100) /  res.Price[0]).toFixed(2)}  % off</span>
//                                                     : 
//                                                 res.Product_Price_Discounted ?
//                                                     <span>{(100 - (res.Product_Price_Discounted *100) /  res.Product_Price).toFixed(2)}  % off</span>
//                                                     : ""
//                                                 }
//                                                 </span>
//                                                     <figure className="block-4-image">
//                                                     <Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res._id+"/"+res.Product_Name} ><img src={res.Product_Image_Upload}  style={{height : "210px" , width : "200px" }}  alt="Image placeholder" className="img-fluid" /></Link>
//                                                     </figure>
//                                                     <div className="block-4-text p-4">
//                                                         <h3><Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res._id+"/"+res.Product_Name}> {res.Product_Name}</Link></h3>
//                                                         <p className="mb-0">{res.Product_Title}</p>
//                                                         {
//                                                             res.Size_Discounted[0] ?
//                                                             <div style={{display : "flex",justifyContent : 'center',fontSize : "21px"}}>
//                                                             <p  style={{color  : "black"  }}>Rs: {res.Size_Discounted[0]}</p>
//                                                             <div style={{width : "10px"}}></div>
//                                                             <p style={{color  : "grey"  }}> <b><s>Rs: {res.Price[0]}</s></b>  </p>
//                                                             <div style={{width : "10px"}}></div>
//                                                             <p  style={{color  : "black"  }}>Size: {res.Size[0]}</p>
//                                                             </div>
//                                                             :
                                                            
//                                                             !res.Product_Price_Discounted ?
//                                                                 <>
//                                                             <p style={{color  : "grey"  }}> <b>Rs : {res.Product_Price}</b>  </p>
//                                                             <div style={{width : "10px"}}></div>
//                                                             </>
                                                                                                                  
//                                                         :
//                                                             res.Product_Price_Discounted ? 
//                                                         <div style={{display : "flex",justifyContent : 'center',fontSize : "21px"}}>
//                                                             <p  style={{color  : "black"  }}>Rs: {res.Product_Price_Discounted}</p>
//                                                             <div style={{width : "10px"}}></div>
//                                                             <p style={{color  : "grey"  }}> <b><s>Rs: {res.Product_Price}</s></b>  </p>
//                                                         </div>
//                                                         : 
//                                                         <p style={{color  : "black"  }}>{res.Product_Price}</p>

//                                                         }
//                                                         <div onClick={()=>settoBags(res)} className="btn btn-dark btn-group-lg view-btn-detail">Add To Bag</div>
//                                                         <Link to={"/categories/"+CategoriesName.categoriesName+"/"+CategoriesName.subcategories+"/"+res._id+"/"+res.Product_Name} onClick={()=>savethedetailproduct(res)} id="view-at-hover" ><div className="btn btn-dark btn-group-lg view-btn-detail">View</div></Link> */}

//                                                     </div>
//                                                 </div>
                                               
//                                             </div> 
//                                         ) 
//                                         }
                                        
//                                     }) 
//                                     :
//                                      <span style={{margin:  "auto"}}>
//                                         <h3></h3>
//                                     </span>
//                                     :
//                                      <span style={{margin:  "auto"}}>
//                                         <h3>No Any Product</h3>
//                                     </span>
//                                     :
//                                      <span style={{margin:  "auto"}}>
//                                         <h3>Loading ...</h3>
//                                     </span>
//                                    }
//                                      <br />
//                                      <br />
                                    
                                    
//                                 </div>

                               







                                      
                                       
//                             </div>

                                       
//                                 </div> 
//                                 <center>

//                                 <h4 style={{margin:  "auto"}}>
                                         
//                                     <Link to="/arrival/product">See More</Link>
//                                 </h4>
//                                 </center>
//                 </section>



//                 <section>
//                     <div className="container-fuild" style={{margin : "70px auto" , width : "95%"}}>
//                             <div className="row justify-content-center">
//                                 <div className="col-12 text-center">
//                                     <h2 style={{color : "black"}} > <b>Discover Our Collections</b> </h2>
//                                     <center>
//                                         <p style={{width : "280px" , border : "0.5px solid grey" }}></p>
//                                     </center>
//                                 </div>
//                                 {
//                                     MainCatogories.map((res,i)=>{
//                                         if(res.MainCategories === "books"|| res.MainCategories === "Uniform"){
//                                             return (
//                                                 <div className="arrival1">
//                                                     <Link to={"/categories/"+res.MainCategories+"/product"}><img src={res.photo} alt="" className="arrival-Photo11"/></Link>
//                                                 </div>
//                                             ) 
//                                         }
//                                         else{
//                                             return (
//                                                 <div className="arrival1">
//                                                     <Link to={"/categories/"+res.MainCategories+"/product"}><img src={res.photo} alt="" className="arrival-Photo11"/></Link>
//                                                 </div>
//                                             )    
//                                         }
                                        
//                                     })
//                                 } 
                                       
//                             </div>
                               
//                             </div>
//                 </section>










//                 <div className="site-section block-3 site-blocks-2"  style={{background : "#66605b"}}>
//                     <div className="container-fuild" style={{width : "98%"}}>
//                         <div className="row justify-content-center">
//                             <div className="col-md-7 site-section-heading text-center pt-4 ">
//                                 <h2 style={{color : "white",marginLeft : "-34px"}} >Popular Products</h2>
//                             </div>
//                         </div>
//                         <div className="row">
//                             <div className="col-md-12 p-5">
//                             <Carousel responsive={responsive}>
//                             {
//                                 product.map((res,i)=>{
//                                     if(res.Product_Popular && res.Product_Popular === "yes"){
//                                         var Cat = res.Product_Catagories.split(" ").join("-")
                                        
//                                         var Cat1 = res.Product_Name.split(" ").join("-")
//                                         return (<div className="item" style={{margin : "5px 5px" }} key={i}>
//                                         <div className="block-4 text-center" style={{margin : "10px 0px" , height : "430px"}} >
//                                             <figure className="block-3-image">
//                                                 <img src={res.Product_Image_Upload} alt="Image placeholder" style={{height : "240px" }}  className="img-fluid"/>
//                                             </figure>
//                                             <div className="block-4-text p-1">
//                                                 <h3><Link to='/shop'><div href="#">{res.Product_Name}</div></Link></h3>
//                                                 <p className="mb-0">{res.Product_Title}</p>
//                                                 <p className="text-primary font-weight-bold">Rs : {res.Product_Price}</p>
//                                                 <Link to={"/shop/categories/"+Cat+"/"+Cat1}  onClick={()=>savethedetailproduct(res)}><div className="btn btn-sm btn-primary">View</div></Link>

//                                             </div>
//                                         </div>
//                                     </div>
//                                     )
//                                     }
                                    
//                                 })
//                             }
                                   
                                   

//                 </Carousel>
//                             </div>
//                         </div>
//                     </div>
//                 </div> */}




//                 <div className="site-section site-section-sm site-blocks-1 backk" >
//                     <div className="container">
//                         <div className="row">
//                             <div className="col-md-6 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="">
//                                 <div className="icon mr-4 align-self-start">
//                                     <span><AirportShuttleIcon  className="icon-truck" style={{fontSize : "65px"}}/></span>
//                                 </div>
//                                 <div className="text">
//                                     <h2 className="text-uppercase" style={{color :  "white"}}>Free Shipping</h2>
//                                     <p>Shipping Fee Will be Calculated on Checkout.</p>
//                                 </div>
//                             </div>
//                             <div className="col-md-6 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="100">
//                                 <div className="icon mr-4 align-self-start">
//                                     <span><ReplayIcon    style={{fontSize : "65px"}}  /></span>
//                                 </div>
//                                 <div className="text">
//                                     <h2 className="text-uppercase" style={{color :  "white"}}>Free Returns</h2>
//                                     <p>Vendor will be take care of Return and Exchange.</p>
//                                 </div>
//                             </div>
//                             <div className="col-md-6 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="200">
//                                 <div className="icon mr-4 align-self-start">
//                                     <span><HelpOutlineIcon    style={{fontSize : "65px"}}  /></span>

//                                 </div>
//                                 <div className="text">
//                                     <h2 className="text-uppercase" style={{color :  "white"}}>Customer Support</h2>
//                                     <p>Student1Stop give 24/7 Customer Support.</p>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//   <div className="site-section site-section-sm site-blocks-1 m-3" >
//                     <div className="container-fuild">
//                         <div className="row">
//                             <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12" data-aos="fade-up" data-aos-delay="">
//                                 {BannerPhoto.length > 0
//                                      ? 
//                                     BannerPhoto.map((res,i)=>{
//                                         return (
//                                         <img src={res.photo1} alt="" style={{width : "100%" , height : "200px"}}/>
//                                         )
//                                     })
//                                     :
//                                         " "
//                                 }
//                             </div>
//                         </div>
//                     </div>
//                 </div>


//             </div>
        )
    
}

export default Home;