import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"
import swal from 'sweetalert';
// import $ from 'jquery';
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';


import "react-multi-carousel/lib/styles.css";
import "react-multi-carousel/lib/styles.css";
import "./../assets/css/main.css"
import "./../assets/css/util.css"
import "./../assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css"



// import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"

import "./../assets/vendor/css-hamburgers/hamburgers.min.css"

import "./../assets/fonts/iconic/css/material-design-iconic-font.min.css"
import "./../assets/vendor/bootstrap/css/bootstrap.min.css"
import "./../assets/vendor/select2/select2.min.css"
import "./../assets/vendor/slick/slick.css"
import "./../assets/vendor/perfect-scrollbar/perfect-scrollbar.css"

// import Logo from "./../assets/images/icons/logo-01.png"

// import b1 from "./../assets/images/banner-01.jpg"
// import b2 from "./../assets/images/banner-02.jpg"
// import b3 from "./../assets/images/banner-03.jpg"

import k1 from "./../assets/images/svg/Payments/SELECT JAZZ CASH LOGO/jazzcash.png"
// import k2 from "./../assets/images/k2.png"
// import k3 from "./../assets/images/k3.png"
import k4 from "./../assets/images/k4.png"
import k5 from "./../assets/images/k5.png"

// import a1 from "./../assets/images/a1.png"
// import a2 from "./../assets/images/a2.png"
// import a3 from "./../assets/images/a3.png"
// import a4 from "./../assets/images/a4.png"
// import a5 from "./../assets/images/a5.png"
// import a6 from "./../assets/images/a6.png"
// import a7 from "./../assets/images/a7.png"
// import a8 from "./../assets/images/a8.png"

// import a9 from "./../assets/images/a9.png"

// import a11 from "./../assets/images/a11.png"
// import a21 from "./../assets/images/a21.png"
// import a22 from "./../assets/images/a22.png"
// import a23 from "./../assets/images/a23.png"
// import a24 from "./../assets/images/a24.png"
// import a25 from "./../assets/images/a25.png"
// import a27 from "./../assets/images/a27.png"


// import z1 from "./../assets/images/z1.png"
// import z2 from "./../assets/images/z2.png"
// import z3 from "./../assets/images/z3.png"
// import z4 from "./../assets/images/z4.png"


// import c1 from "./../assets/images/c1.png"
import c2 from "./../assets/images/c2.png"
// import c3 from "./../assets/images/c3.png"
// import c4 from "./../assets/images/c4.png"






// import p2 from "./../assets/images/product-02.jpg"
// import p3 from "./../assets/images/product-03.jpg"
// import p4 from "./../assets/images/product-04.jpg"
// import p5 from "./../assets/images/product-05.jpg"
// import p6 from "./../assets/images/product-06.jpg"



// import h1 from "./../assets/images/icons/icon-heart-01.png"
// import h2 from "./../assets/images/icons/icon-heart-02.png"



// import s1 from "./../assets/images/slide-01.jpg"
// import s2 from "./../assets/images/slide-02.jpg"
// import s3 from "./../assets/images/slide-03.jpg"






const Home = ( props ) => {
    const [myRef,setRef] = useState( React.createRef())
    const [scrollTop1,setscrollTop] = useState(0)
    const [email , setemail] = useState(["","","","","","","","","","","",""])
    const [Name , setName] = useState(["","","","","","","","","","","",""])
    const [Nu , setNu] = useState(0)

    const [Image , setImage] = useState("")
    const [pass , setpass] = useState("")
    const [dispaly ,  setdispaly ] = useState(true)
    const [User1 ,  setUser1 ] = useState({})
    const [Num ,  setNum ] = useState(0)
    const [n , setn] = useState("")
    const [n1 , setn1] = useState("")
    const [n2 , setn2] = useState("")
    const [dispaly1 ,  setdispaly1 ] = useState(false)

    useEffect(() =>{
if(! JSON.parse(localStorage.getItem("User"))){

        props.match.history.push("/login")
      }
      else{
        window.scrollTo(0, 0)
        localStorage.setItem("VenderWorkingThose" ,JSON.stringify(123)) 
        setUser1(JSON.parse(localStorage.getItem("User")))
        setNum(JSON.parse(localStorage.getItem("the3")))
      }
      return () => {
        document.getElementById("get1").classList.remove("is-active");
        document.getElementById("get2").style.display = "none";
      }
  
    },[])
    useEffect( () => () => localStorage.removeItem("VenderWorkingThose"), [] );


    


const SubmitDataMessage = () =>{
  // console.log("wddde");
  let f
  
   if(Num === 53){
    f = "Linktree"
  }
  else{
    
  }
  let d = false
  let c = false
  for (let i = 0; i < Name.length; i++) {
    if (Name[i] !== "")
    d = true
    if (email[i] !== "")
    c = true
  }
  if(c && d){

  }
  else{
    setdispaly ( true)
    swal("Feild are Missing")
    setTimeout(()=>{
      swal.close()
             },1000)
    return
  }

    setdispaly ( false)
                              fetch("https://tapitdabit-backend.vercel.app/createlinktreeLinkBussiness",{
                                          method: "POST",
                                          headers :  {
                                              "Content-Type" : "application/json" , 
                                          } ,
                                          body : JSON.stringify({
                                            name  : f,
                                            Name1 : Name[0],
                                            Name2 : Name[1],
                                            Name3 : Name[2],
                                            Name4 : Name[3],
                                            Name5 : Name[4],

                                            email1 : email[0],
                                            email2 : email[1],
                                            email3 : email[2],
                                            email4 : email[3],
                                            email5 : email[4],
                                            id : User1._id
                                              // MainLine2 :mainline ,
                                          })
                                      })
                                      .then(res11=>res11.json())
                                      .then((res12)=>{ 
                                        if(!res12.Error){
                                            swal("Successfully Upload your Links!");      setTimeout(()=>{
                                              swal.close()
                                                     },1000)
                                            setTimeout(()=>{
    
                                              props.history.push("/user/bussiness/home1/"+User1._id)
                                            },1500)
                                            setdispaly ( true)
                                            this.setState({
                                                email: "",
                                        })

                                          }
                                          else{
                                        swal(res12.Error);
                                        setdispaly ( true)

                                      }
                                  })
                                  .catch(err=>{
                                    swal("Successfully Upload your Links!");      setTimeout(()=>{
                                      swal.close()
                                             },1000)
                                      setdispaly ( true)
            
                                      })
                                    // }
                                    // else{
                                    //   const c = User1.ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
                                    //   if(c.length > 0){
                                    //     props.history.push("/user/bussiness/editProfile/addotherlink/"+User1._id)
                                    //   }
                                    //   else{
    
                                    //     localStorage.setItem("request123",JSON.stringify(3) )
                                    //     setTimeout(()=>{
                                    //       props.history.push("/user/request-for-pro-item")
                                    //       swal("Sorry First You Buy")
                                    //     },1000)  
                                    //    }
                                    // }

}


const SubmitDataMessage2 = () =>{
  // console.log("wddde");
  let f
  
  if(Num === 110){
    f = "JazzCash"
  }
  else if(Num === 111){
    f = "EasyPaisa"
  }
  else{
    
  }
  let d = false
  let c = false
  if (n !== ""&&n1 !== ""){
    d = true
    c = true

  }
else{
  setdispaly ( true)
  swal("Feild are Missing")
  setTimeout(()=>{
    swal.close()
           },1000)
  return
}
// const b = User1.Social.filter((res,i)=>res.name === f)
// if(b.length === 0){
  setdispaly ( false)
                              fetch("https://tapitdabit-backend.vercel.app/createJqazEasyBussiness",{
                                          method: "POST",
                                          headers :  {
                                              "Content-Type" : "application/json" , 
                                          } ,
                                          body : JSON.stringify({
                                            name  : f,
                                            Name : n,
                                            Number : n1,
                                            id : User1._id
                                              // MainLine2 :mainline ,
                                          })
                                      })
                                      .then(res11=>res11.json())
                                      .then((res12)=>{ 
                                        if(!res12.Error){
                                            swal("Successfully Upload your Links!");      setTimeout(()=>{
                                              swal.close()
                                                     },1000)
                                            setTimeout(()=>{
    
                                              props.history.push("/user/bussiness/home/"+User1._id)
                                            },1500)
                                            setdispaly ( true)
                                            
                                                

                                          }
                                          else{
                                        swal(res12.Error);      setTimeout(()=>{
                                          swal.close()
                                                 },1000)
                                        setdispaly ( true)

                                      }
                                  })
                                  .catch(err=>{
                                    swal("Successfully Upload your Links!");      setTimeout(()=>{
                                      swal.close()
                                             },1000)
                                      setdispaly ( true)
            
                                      }) 
                                    // }
                                    // else{
                                    //   const c = User1.ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
                                    //   if(c.length > 0){
                                    //     props.history.push("/user/bussiness/editProfile/addotherlink/"+User1._id)
                                    //   }
                                    //   else{
    
                                    //     localStorage.setItem("request123",JSON.stringify(3) )
                                    //     setTimeout(()=>{
                                    //       props.history.push("/user/request-for-pro-item")
                                    //       swal("Sorry First You Buy")
                                    //     },1000)  
                                    //    }
                                    // }

}


const SubmitDataMessage3 = () =>{
  // console.log("wddde");
  let f
  
  if(Num === 112){
    f = "Bank"
  }
  else{
    
  }
  let d = false
    if (n !== ""&&n1 !== ""&&n2 !== ""){
      d = true
    }
  else{
    setdispaly ( true)
    swal("Feild are Missing")
    setTimeout(()=>{
      swal.close()
             },1000)
    return
  }
  // const b = User1.Social.filter((res,i)=>res.name === f)
  // if(b.length === 0){
    setdispaly ( false)
                              fetch("https://tapitdabit-backend.vercel.app/createBankBussiness",{
                                          method: "POST",
                                          headers :  {
                                              "Content-Type" : "application/json" , 
                                          } ,
                                          body : JSON.stringify({
                                            name  : f,
                                            Name : n,
                                            BankName : n1,
                                            AccountNumber : n2,
                                            id : User1._id
                                              // MainLine2 :mainline ,
                                          })
                                      })
                                      .then(res11=>res11.json())
                                      .then((res12)=>{ 
                                        if(!res12.Error){
                                            swal("Successfully Upload your Links!");      setTimeout(()=>{
                                              swal.close()
                                                     },1000)
                                            setTimeout(()=>{
    
                                              props.history.push("/user/bussiness/home/"+User1._id)
                                            },1500)
                                            setdispaly ( true)
                                            this.setState({
                                                email: "",
                                        })

                                          }
                                          else{
                                        swal(res12.Error);      setTimeout(()=>{
                                          swal.close()
                                                 },1000)
                                        setdispaly ( true)

                                      }
                                  })
                                  .catch(err=>{
                                    swal("Successfully Upload your Links!");      setTimeout(()=>{
                                      swal.close()
                                             },1000)
                                      setdispaly ( true)
            
                                      }) 
                                  //   }
                                  //   else{
                                  //     const c = User1.ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
                                  //     if(c.length > 0){
                                  //       fetch("https://tapitdabit-backend.vercel.app/createBankBussiness",{
                                  //         method: "POST",
                                  //         headers :  {
                                  //             "Content-Type" : "application/json" , 
                                  //         } ,
                                  //         body : JSON.stringify({
                                  //           name  : f,
                                  //           Name : n,
                                  //           BankName : n1,
                                  //           AccountNumber : n2,
                                  //           id : User1._id
                                  //             // MainLine2 :mainline ,
                                  //         })
                                  //     })
                                  //     .then(res11=>res11.json())
                                  //     .then((res12)=>{ 
                                  //       if(!res12.Error){
                                  //           swal("Successfully Upload your Links!");
                                  //           setTimeout(()=>{
    
                                  //             props.history.push("/user/bussiness/home/"+User1._id)
                                  //           },1500)
                                  //           setdispaly ( true)
                                  //           this.setState({
                                  //               email: "",
                                  //       })

                                  //         }
                                  //         else{
                                  //       swal(res12.Error);
                                  //       setdispaly ( true)

                                  //     }
                                  // })
                                  // .catch(err=>{
                                  //   swal("Successfully Upload your Links!");
                                  //     setdispaly ( true)
            
                                  //     })               
                                  //                           }
                                    //   else{
    
                                    //     localStorage.setItem("request123",JSON.stringify(3) )
                                    //     setTimeout(()=>{
                                    //       props.history.push("/user/request-for-pro-item")
                                    //       swal("Sorry First You Buy")
                                    //     },1000)  
                                    //    }
                                    // }

}




// const SubmitDataMessage1 = (e) =>{
//   e.preventDefault()
//   // console.log("wddde");
//   let f
//   if(Num === 33){
//     f = "Custom Link"
//   }
  
//   else{
    
//   }
//   // const d = User1.Social.filter((res,i)=>res.name === f)
//   // if(d.length === 0){
//     setdispaly ( false)

//                               const data = new FormData()
//                               data.append("file", Image)
//                               data.append("upload_preset", "commerce")
//                               data.append("cloud_name", "freedeveloperss123")
//                               fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
//                                   method : "POST",
//                                   body : data , 
//                               })
//                               .then(res=>res.json())
//                               .then((res2)=>{
//                                 console.log(res2)
//                               fetch("https://tapitdabit-backend.vercel.app/createhumareLink1Bussiness",{
//                                           method: "POST",
//                                           headers :  {
//                                               "Content-Type" : "application/json" , 
//                                           } ,
//                                           body : JSON.stringify({
//                                             name  : f,
//                                             photo : res2.url,
//                                             Name ,
//                                             link : email,
//                                             id : User1._id
//                                               // MainLine2 :mainline ,
//                                           })
//                                       })
//                                       .then(res11=>res11.json())
//                                       .then((res12)=>{ 
//                                           if(!res12.Error){
//                                             setdispaly ( true)
                                            
//                                             swal("Successfully Upload your Link!", {
//                                               buttons: false,
//                                             });      setTimeout(()=>{
//                                               swal.close()
//                                                      },1000)
//                                             setTimeout(()=>{
      
//                                               props.history.push("/user/bussiness/home/"+User1._id)
//                                             },1500)
      
//                                             this.setState({
//                                                 email: "",
//                                         })

//                                       }
//                                       else{
//                                         setTimeout(()=>{
//                                           swal.close()
//                                                  },1000)
//                                         swal(res12.Error);
//                                         setdispaly ( true)

//                                       }
//                                   })
//                                   .catch(err=>{
//                                     setTimeout(()=>{
//                                       swal.close()
//                                              },1000)
//                                     swal("There is An Error") 
//                                       setdispaly ( true)
                 
//                                       }) 
//                                     })
//                                   // }
//                                   // else{
//                                   //   const c = User1.ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
//                                   //   if(c.length > 0){
//                                   //        props.history.push("/user/bussiness/editProfile/addotherlink/"+User1._id)
//                                   //   }
//                                   //   else{
  
//                                   //     localStorage.setItem("request123",JSON.stringify(3) )
//                                   //     setTimeout(()=>{
//                                   //       props.history.push("/user/request-for-pro-item")
//                                   //       swal("Sorry First You Buy")
//                                   //     },1000)  
//                                   //    }
//                                   // }
// }

const  setChange55 = (e,i)=>{
    console.log(e , i ,Name)
    let a = Name
    a[i]  =  e
    setName(a)
    console.log(e , i ,Name)
    if(dispaly1){
        // setdispaly1(true)
        setdispaly1(false)

    }
  else{
  setdispaly1(true)
//   setdispaly1(false)
  }
}


const  setChange56 = (e,i)=>{
    console.log(e , i ,email)
    let a = email
    a[i]  =  e
    setemail(a)
    console.log(e , i ,email)
    if(dispaly1){
        // setdispaly1(true)
        setdispaly1(false)

    }
  else{
  setdispaly1(true)
//   setdispaly1(false)
  }
}


// const setRouting1 = (e)=>{
//   if(e === 1){
//     if(JSON.parse(localStorage.getItem("User")).DisPersonal)
//     props.history.push("/user/home1/"+User1._id)
//     else 
//     props.history.push("/user/bussiness/home1/"+User1._id)
//   }
//   else if(e === 4){
//     if(JSON.parse(localStorage.getItem("User")).DisPersonal)
//     props.history.push("/user/editProfile/sort-delete/"+User1.uid)
//     else
//     props.history.push("/user/bussiness/editProfile/sort-delete/"+User1.uid)
//   }
//   else if(e === 3){
//     // props.history.push("/user/analytics/"+User1._id)   

//     const d =  JSON.parse(localStorage.getItem("User")).ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
//     console.log(d)
    
      
//     if (d.length > 0){
//       let a = new Date()
//       let f= a.getDate() +" "+(a.getUTCMonth()+1)+ " "+a.getUTCFullYear()
//       console.log(d[0].End[0]+""+d[0].End[1] >= a.getDate(), d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ,d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear())
//       if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] > a.getUTCFullYear()     ){
//       // if(d[0].End && d[0].End >= f){
//     props.history.push("/user/analytics/"+User1._id)   

//       }
//       else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear()){
//         if( d[0].End[3]+""+d[0].End[4] >a.getUTCMonth()+1 ){
//       props.history.push("/user/analytics/"+User1._id)   

//         }
//         else{
//           if(d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1){
//             if( d[0].End[0]+""+d[0].End[1] >=a.getDate()){
//           props.history.push("/user/analytics/"+User1._id)   

//             }
//             else{
//               document.getElementById("myDivUmer12").style.display= "block"

//               // localStorage.setItem("request123",JSON.stringify(1) )
//               // props.history.push("/user/request-for-pro-item")
//               // swal("Sorry First You Buy")
  
//             }
//           }
//           else{
//             document.getElementById("myDivUmer12").style.display= "block"

//             // localStorage.setItem("request123",JSON.stringify(1) )
//             //   props.history.push("/user/request-for-pro-item")
//             //   swal("Sorry First You Buy")
  
//             }
//           }
//         }
//       else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear() && d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ){
//           if( d[0].End[0]+""+d[0].End[1] >=a.getDate()){
//         props.history.push("/user/analytics/"+User1._id)   

//         }
//         else{
//           document.getElementById("myDivUmer12").style.display= "block"

//           // localStorage.setItem("request123",JSON.stringify(1) )
//           // props.history.push("/user/request-for-pro-item")
//           // swal("Sorry First You Buy")
  
//         }
//       }
//       else{
//         document.getElementById("myDivUmer12").style.display= "block"

//         // localStorage.setItem("request123",JSON.stringify(1) )
//         //   props.history.push("/user/request-for-pro-item")
//         //   swal("Sorry First You Buy")
  
//       }
//     }
//     else{
//       document.getElementById("myDivUmer12").style.display= "block"

//       // localStorage.setItem("request123",JSON.stringify(1) )
//       //   props.history.push("/user/request-for-pro-item")
//       //   swal("Sorry First You Buy")
//   }
//   }
//   else if(e === 2){


//     const d =  JSON.parse(localStorage.getItem("User")).ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
//     console.log(d)
    
      
//     if (d.length > 0){
//       let a = new Date()
//       let f= a.getDate() +" "+(a.getUTCMonth()+1)+ " "+a.getUTCFullYear()
//       console.log(d[0].End[0]+""+d[0].End[1] >= a.getDate(), d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ,d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear())
//       if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] > a.getUTCFullYear()     ){
//       // if(d[0].End && d[0].End >= f){
       
//       }
//       else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear()){
//         if( d[0].End[3]+""+d[0].End[4] >a.getUTCMonth()+1 ){
//           props.history.push("/user/connect/myprofile/"+User1.uid)

//         }
//         else{
//           if(d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1){
//             if( d[0].End[0]+""+d[0].End[1]>=a.getDate()){
//               props.history.push("/user/connect/myprofile/"+User1.uid)

//             }
//             else{
//               document.getElementById("myDivUmer").style.display= "block"

//               // localStorage.setItem("request123",JSON.stringify(1) )
//               // props.history.push("/user/request-for-pro-item")
//               // swal("Sorry First You Buy")
  
//             }
//           }
//           else{
//             document.getElementById("myDivUmer").style.display= "block"

//             // localStorage.setItem("request123",JSON.stringify(1) )
//             //   props.history.push("/user/request-for-pro-item")
//             //   swal("Sorry First You Buy")
  
//             }
//           }
//         }
//       else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear() && d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ){
//           if( d[0].End[0]+""+d[0].End[1]>=a.getDate()){
//             props.history.push("/user/connect/myprofile/"+User1.uid)

//         }
//         else{
//           document.getElementById("myDivUmer").style.display= "block"

//           // localStorage.setItem("request123",JSON.stringify(1) )
//           // props.history.push("/user/request-for-pro-item")
//           // swal("Sorry First You Buy")
  
//         }
//       }
//       else{
//         document.getElementById("myDivUmer").style.display= "block"

//         // localStorage.setItem("request123",JSON.stringify(1) )
//         //   props.history.push("/user/request-for-pro-item")
//         //   swal("Sorry First You Buy")
  
//       }
//     }
//     else{
//       document.getElementById("myDivUmer").style.display= "block"

//       // localStorage.setItem("request123",JSON.stringify(1) )
//       //   props.history.push("/user/request-for-pro-item")
//       //   swal("Sorry First You Buy")
//   }
//   }
//   else{

//   }
// }
    
// const setRoter123 = ()=>{
//   localStorage.setItem("request123",JSON.stringify(1) )
//       props.history.push("/user/request-for-pro-item")

// }

// const setChanger1 = (e)=>{
//   document.getElementById("myDiv1234").style.display= "none"
// }
// const setChanger2 = (e)=>{
//   document.getElementById("myDivUmer").style.display= "none"
// }
// const setChanger8 = (e)=>{
//   document.getElementById("myDivUmer12").style.display= "block"
// }
        return (
            






      <div 
        // onScroll={onScrolll()}
        >
       
        {/* Slider */}




     




{Num === 53 ? 

<>
<br/>






              <section>
              <div className="container mi2" style={{margin : "70px auto 0px auto" , width : "96%"}}>
                            <div className="row justify-content-center">
                                {/* <div className="col-12 text-center">
                                    <h2 style={{color : "black"}} > <b>Link tree </b> </h2>
                                    
                                </div>  */}

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={c2} alt="" className="arrival-Photo111 arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div>
                            <center>

                            <p style={{fontWeight : "bold"  , color : "black", marginLeft : "0px"}} className="icon-name-change">Linktree</p>
</center>
                </section>

           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "40px 0px" }}>
                   {Nu === 0 || Nu > 0?
                       <>
                       <p> 1  </p>
                    <input className="input11" type="text"   value={Name[0]} onChange={(e)=>setChange55(e.target.value,0)} name="name" placeholder="Enter Link Name" /><br/>
                    <input className="input11" type="text"   value={email[0]} onChange={(e)=>setChange56(e.target.value,0)}  name="name" placeholder="Enter Link Here" /><br/>
                    </>
                     : ""}
                   {Nu === 1 || Nu > 1 ?
                       <>
                       <p> 2 </p>
                    <input className="input11" type="text"   value={Name[1]} onChange={(e)=>setChange55(e.target.value,1)} name="name" placeholder="Enter Link Name" /><br/>
                    <input className="input11" type="text"   value={email[1]} onChange={(e)=>setChange56(e.target.value,1)}  name="name" placeholder="Enter Link Here" /><br/>
                    </>
                     : ""}
                   {Nu === 2 || Nu > 2   ?
                       <>
                       <p>3 </p>
                    <input className="input11" type="text"   value={Name[2]} onChange={(e)=>setChange55(e.target.value,2)} name="name" placeholder="Enter Link Name" /><br/>
                    <input className="input11" type="text"   value={email[2]} onChange={(e)=>setChange56(e.target.value,2)}  name="name" placeholder="Enter Link Here" /><br/>
                    </>
                     : ""}
                   {Nu === 3 || Nu > 3  ?
                       <>
                       <p>4  </p>
                    <input className="input11" type="text"   value={Name[3]} onChange={(e)=>setChange55(e.target.value,3)} name="name" placeholder="Enter Link Name" /><br/>
                    <input className="input11" type="text"   value={email[3]} onChange={(e)=>setChange56(e.target.value,3)}  name="name" placeholder="Enter Link Here" /><br/>
                    </>
                     : ""}
                   {Nu === 4 || Nu > 4  ?
                       <>
                       <p>5  </p>
                    <input className="input11" type="text"   value={Name[4]} onChange={(e)=>setChange55(e.target.value,4)} name="name" placeholder="Enter Link Name" /><br/>
                    <input className="input11" type="text"   value={email[4]} onChange={(e)=>setChange56(e.target.value,4)}  name="name" placeholder="Enter Link Here" /><br/>
                    </>
                     : ""}
                    <div style={{display : "flex" , justifyContent : "space-between"}}>
                    <button onClick={()=>setNu(Nu+1)} className="button11" style={{borderRadius : "50px",width : "190px", border : "1px solid white",  background : "rgb(0, 0, 0)" ,  color  : "white", fontSize : "11px",height: "45px"}}  >Add More</button>
                        {dispaly ?
                            <button className="button11" onClick={()=> SubmitDataMessage()} style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}} >Save</button>
                            :
                            <button className="button11"  style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
                        }                    </div>
                    <br/>
                {/* </form> */}
            </div>
           </center>
           </>
           :""
}

{Num === 110 ? 

<>
<br/>






              <section>
              <div className="container mi2" style={{margin : "70px auto 0px auto" , width : "96%"}}>
                            <div className="row justify-content-center">
                                {/* <div className="col-12 text-center">
                                    <h2 style={{color : "black"}} > <b>Jazz Cash </b> </h2>
                                    
                                </div>  */}

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={k1} alt="" className="arrival-Photo111 arrival-P" style={{objectFit : "contain",padding: "0px 20px 0px 17px"}}/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div>
                            <center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "0px"}} className="icon-name-change">JazzCash</p>
</center>
                </section>

           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "40px 0px" }}>
                  
            <p style={{textAlign: "left"}}>Account Title</p>
                    <input className="input11" type="text"   value={n} onChange={(e)=>setn(e.target.value)} name="name" placeholder="Enter Account Title" /><br/>
                    <p style={{textAlign: "left"}}>Account Number</p>                    <input className="input11" type="text"   value={n1} onChange={(e)=>setn1(e.target.value)}  name="name" placeholder="Enter Account Number" /><br/>
                   
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        {dispaly ?
                            <button className="button11" onClick={()=> SubmitDataMessage2()} style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "16px"}}>Save</button>
                            :
                            <span className="button11" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "16px"}}>Save</span>
                        }                    </div>
                    <br/>
                {/* </form> */}
            </div>
           </center>
           </>
           :""
}


{Num === 111 ? 

<>
<br/>






              <section>
              <div className="container mi2" style={{margin : "70px auto 0px auto" , width : "96%"}}>
                            <div className="row justify-content-center">
                                {/* <div className="col-12 text-center">
                                    <h2 style={{color : "black"}} > <b>Easy Paisa </b> </h2>
                                    
                                </div>  */}

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={k4} alt="" className="arrival-Photo111 arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div>
                            <center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "0px"}} className="icon-name-change">EasyPaisa</p>
</center>
                </section>

           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "40px 0px" }}>
                  
            <p style={{textAlign: "left"}}>Account Title</p>

<input className="input11" type="text"   value={n} onChange={(e)=>setn(e.target.value)} name="name" placeholder="Enter Account Title" /><br/>
<p style={{textAlign: "left"}}>Account Number</p>                    <input className="input11" type="text"   value={n1} onChange={(e)=>setn1(e.target.value)}  name="name" placeholder="Enter Account Number" /><br/>
                   
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        {dispaly ?
                            <button className="button11" onClick={()=> SubmitDataMessage2()} style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "16px"}}>Save</button>
                            :
                            <span className="button11" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "16px"}}>Save</span>
                        }                    </div>
                    <br/>
                {/* </form> */}
            </div>
           </center>
           </>
           :""
}


{Num === 112 ? 

<>
<br/>






              <section>
              <div className="container mi2" style={{margin : "70px auto 0px auto" , width : "96%"}}>
                            <div className="row justify-content-center">
                                {/* <div className="col-12 text-center">
                                    <h2 style={{color : "black"}} > <b>Bank </b> </h2>
                                    
                                </div>  */}

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={k5} alt="" className="arrival-Photo111 arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div>
                            <center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "0px"}} className="icon-name-change">Bank</p>
</center>
                </section>

           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "40px 0px" }}>
                  
            <p style={{textAlign: "left"}}>Account Title</p>

<input className="input11" type="text"   value={n} onChange={(e)=>setn(e.target.value)} name="name" placeholder="Enter  Account Title" /><br/>
<p style={{textAlign: "left"}}>Bank Name</p>

<input className="input11" type="text"   value={n1} onChange={(e)=>setn1(e.target.value)}  name="name" placeholder=" Enter Bank Name" /><br/>
<p style={{textAlign: "left"}}>Account Number</p>
                    <input className="input11" type="text"   value={n2} onChange={(e)=>setn2(e.target.value)}  name="name" placeholder="Enter Account Number" /><br/>
                   
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        {dispaly ?
                            <button className="button11" onClick={()=> SubmitDataMessage3()} style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "16px"}}>Save</button>
                            :
                            <span className="button11" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "16px"}}>Save</span>
                        }                    </div>
                    <br/>
                {/* </form> */}
            </div>
           </center>
           </>
           :""
}





        
        


        {/* Back to top */}
        <div className="btn-back-to-top" id="myBtn">
          <span className="symbol-btn-back-to-top">
            <i className="zmdi zmdi-chevron-up" />
          </span>
        </div>
        {/* Modal1 */}
        <div className="wrap-modal1 js-modal1 p-t-60 p-b-20">
          <div className="overlay-modal1 js-hide-modal1" />
          <div className="container">
            <div className="bg0 p-t-60 p-b-30 p-lr-15-lg how-pos3-parent">
              <button className="how-pos3 hov3 trans-04 js-hide-modal1">
                <img src="images/icons/icon-close.png" alt="CLOSE" />
              </button>
              <div className="row">
                <div className="col-md-6 col-lg-7 p-b-30">
                  <div className="p-l-25 p-r-30 p-lr-0-lg">
                    <div className="wrap-slick3 flex-sb flex-w">
                      <div className="wrap-slick3-dots" />
                      <div className="wrap-slick3-arrows flex-sb-m flex-w" />
                      <div className="slick3 gallery-lb">
                        <div className="item-slick3" data-thumb="images/product-detail-01.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-01.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-01.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-02.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-02.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-02.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-03.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-03.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-03.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5 p-b-30">
                  <div className="p-r-50 p-t-5 p-lr-0-lg">
                    <h4 className="mtext-105 cl2 js-name-detail p-b-14">
                      Lightweight Jacket
                    </h4>
                    <span className="mtext-106 cl2">
                      $58.79
                    </span>
                    <p className="stext-102 cl3 p-t-23">
                      Nulla eget sem vitae eros pharetra viverra. Nam vitae luctus ligula. Mauris consequat ornare feugiat.
                    </p>
                    {/*  */}
                    <div className="p-t-33">
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Size
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Size S</option>
                              <option>Size M</option>
                              <option>Size L</option>
                              <option>Size XL</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Color
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Red</option>
                              <option>Blue</option>
                              <option>White</option>
                              <option>Grey</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-204 flex-w flex-m respon6-next">
                          <div className="wrap-num-product flex-w m-r-20 m-tb-10">
                            <div className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-minus" />
                            </div>
                            <input className="mtext-104 cl3 txt-center num-product" type="number" name="num-product" defaultValue={1} />
                            <div className="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-plus" />
                            </div>
                          </div>
                          <button className="flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04 js-addcart-detail">
                            Add to cart
                          </button>
                        </div>
                      </div>	
                    </div>
                    {/*  */}
                    <div className="flex-w flex-m p-l-100 p-t-40 respon7">
                      <div className="flex-m bor9 p-r-10 m-r-11">
                        <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 js-addwish-detail tooltip100" data-tooltip="Add to Wishlist">
                          <i className="zmdi zmdi-favorite" />
                        </a>
                      </div>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Facebook">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Twitter">
                        <i className="fa fa-twitter" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Google Plus">
                        <i className="fa fa-google-plus" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
 






        )
    
}

export default Home;