import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"
import swal from 'sweetalert';
// import $ from 'jquery';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';


import "react-multi-carousel/lib/styles.css";
import "react-multi-carousel/lib/styles.css";
import "./../assets/css/main.css"
import "./../assets/css/util.css"
import "./../assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css"



// import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"

import "./../assets/vendor/css-hamburgers/hamburgers.min.css"

import "./../assets/fonts/iconic/css/material-design-iconic-font.min.css"
import "./../assets/vendor/bootstrap/css/bootstrap.min.css"
import "./../assets/vendor/select2/select2.min.css"
import "./../assets/vendor/slick/slick.css"
import "./../assets/vendor/perfect-scrollbar/perfect-scrollbar.css"




const Home = ( props ) => {
    const [myRef,setRef] = useState( React.createRef())
    const [scrollTop1,setscrollTop] = useState(0)
    const [email , setemail] = useState("")
    const [pass , setpass] = useState("")
    const [pass1 , setpass1] = useState("")
    const [User1 , setUser1] = useState("")
    const [dispaly ,  setdispaly ] = useState(true)
    const [dispaly1 ,  setdispaly1 ] = useState(false)
    const [dispaly2 ,  setdispaly2 ] = useState(true)
    const [dispaly3 ,  setdispaly3 ] = useState(false)
    const [dispaly4 ,  setdispaly4 ] = useState(false)

    useEffect(() =>{
      if(! JSON.parse(localStorage.getItem("User"))){
        props.history.push("/login")
      }
      else{
        setUser1(JSON.parse(localStorage.getItem("User")))
        props.history.push("/user/new-password-recovery")
      }
    },[])

    const setChange = () =>{
        if(document.getElementById("get2").style.display === "none"){
            document.getElementById("get1").classList.add("is-active");
            document.getElementById("get2").style.display = "block";
        }
        else{
            document.getElementById("get1").classList.remove("is-active");
            document.getElementById("get2").style.display = "none";
        }
    }

    const setChange1 = (e) =>{
      if(e === 1 ){
        // document.getElementById("signUp").classList.add("right-panel-active");
        // document.getElementById("signIn").classList.remove("right-panel-active");
        document.getElementById("container1").classList.add("right-panel-active")
      }
      else{
        document.getElementById("container1").classList.remove("right-panel-active")
      }
    }




const postData = () =>{
  // e.preventDefault()
  if(email.length === 0){
      swal("Enter The Password !" )
         return
      }
      fetch("https://tapitdabit-backend.vercel.app/logIn-user-update-156756",{
        method: 'POST' , 
        headers :  {
          "Content-Type" : "application/json" , 
        } , 
        body : JSON.stringify({
            id :  User1._id,
          pass :email ,
        })
      })
      .then((res)=>res.json())
      .then((res2)  =>{
        console.log(res2)
      if(res2.Mess === "Yes"){
        setdispaly1(true)
        setdispaly2(false)
      }
      else{
        swal("Plz Enter correct Password")
      }
    })
        // console.log(res2)
        // if (res2 !== null && !res2.Error  ){

        //     localStorage.setItem("VenderWorkingThose" ,JSON.stringify(123)) 
        //     localStorage.setItem("User" , JSON.stringify(res2) )
        //    swal("SucessFully Login"  )
        //       props.history.push("/user/home1/"+res2.uid)

       
        // }

}

const postData1 = () =>{
  if(pass.length === 0){
      // if (email === "projectpharma874@gmail.com")
      swal("Enter The Password !" )
         return
      }
  if(pass !== pass1 ){
      // if (email === "projectpharma874@gmail.com")
      swal("Both Password is not Same !" )
         return
      }
//   if(pass.length === 0){
//       swal("Enter The Password !"  )
//          return
//        }
 
    fetch("https://tapitdabit-backend.vercel.app/logIn-user-update-1",{
      method: 'POST' , 
      headers :  {
        "Content-Type" : "application/json" , 
      } , 
      body : JSON.stringify({
          id :  User1._id,
        // email  ,
        pass  ,
      })
    })
    .then((res)=>res.json())
    .then((res2)  =>{
        console.log(res2)
        if (res2 !== null && !res2.Error  ){
            localStorage.setItem("VenderWorkingThose" ,JSON.stringify(123)) 
            const da = {...User1,pass}
            localStorage.setItem("User" , JSON.stringify(da) )
           swal("SucessFully Update your Password")
              props.history.push("/user/home1/"+res2.uid)

        }
        else{
       
          swal(res2.Error )
        }

    })
    .catch((err)=>console.log(err))
  

}


const  setPassShow = (e)=>{
  setdispaly3(e)
}
const  setPassShow1 = (e)=>{
  setdispaly4(e)
}



        return (
            






      <div
        // onScroll={onScrolll()}
        >
        
        {/* Slider */}
        <div style={{height : "2px"}}>

</div>


          {/* <div className="cc1"> */}
              <div className="container1 byt" id="container1" >
                
                <div className="form-container sign-in-container" style={{width : "100%"}}>
                  <div  className="form11">
                    <h1 className="h22">New Password</h1>
                    {
                        dispaly2 ? 
                        <>
                        <span style={{display  :"flex" , alignSelf : "flex-start"}}>Current Password</span>
                         <input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="email" placeholder="Enter a current password" />
                        </>

                        : ""
                    }
                    {/* <span className="span1">or use your account</span> */}
                    {/* <input className="input11" type="email"   value={email} onChange={(e)=>setemail(e.target.value)}  name="email" placeholder="Email" /> */}
                    {dispaly1 ?
                    <>
                    {!dispaly3 ? 
                    <>
                      <input className="input11" type="password"  value={pass} onChange={(e)=>setpass(e.target.value)}  name="password" placeholder="Password" />
                      <VisibilityOffIcon  onClick={()=>setPassShow(true)} style={{position : "absolute" , right : "63px", top : "202px"}}/>
                     </>
                      : 
                      <>
                      <input className="input11" type="text"  value={pass} onChange={(e)=>setpass(e.target.value)}  name="password" placeholder="Password" />
                      <VisibilityIcon  onClick={()=>setPassShow(false)} style={{position : "absolute" , right : "63px", top : "202px"}}/>
</>
                    }
                      {!dispaly4 ? 
                    <>
                    <input className="input11" type="password"  value={pass1} onChange={(e)=>setpass1(e.target.value)}  name="password" placeholder="Confirm Password" />
                    <VisibilityOffIcon onClick={()=>setPassShow1(true)} style={{position : "absolute" , right : "63px", top : "252px"}}/>
                    </>
                      : 
                      <>
                      <input className="input11" type="text"  value={pass1} onChange={(e)=>setpass1(e.target.value)}  name="password" placeholder="Confirm Password" />
                      <VisibilityIcon onClick={()=>setPassShow1(false)} style={{position : "absolute" , right : "63px", top : "252px"}}/>
                      </>
}
                     
                      </>
                        :""
                    }
                    {/* <a href="#" className="aa">Forgot Your Password</a> */}
                    {
                        dispaly2 ? 
                    <button className="button11" onClick={()=>postData()}>Check</button>
                    :""}
                    {
                        dispaly1 ? 
                    <button className="button11" onClick={()=>postData1()}>Update</button>
                    :""}
                  </div>
                </div>
                
              </div>
          {/* </div> */}
        



        {/* Back to top */}
        <div className="btn-back-to-top" id="myBtn">
          <span className="symbol-btn-back-to-top">
            <i className="zmdi zmdi-chevron-up" />
          </span>
        </div>
        {/* Modal1 */}
        <div className="wrap-modal1 js-modal1 p-t-60 p-b-20">
          <div className="overlay-modal1 js-hide-modal1" />
          <div className="container">
            <div className="bg0 p-t-60 p-b-30 p-lr-15-lg how-pos3-parent">
              <button className="how-pos3 hov3 trans-04 js-hide-modal1">
                <img src="images/icons/icon-close.png" alt="CLOSE" />
              </button>
              <div className="row">
                <div className="col-md-6 col-lg-7 p-b-30">
                  <div className="p-l-25 p-r-30 p-lr-0-lg">
                    <div className="wrap-slick3 flex-sb flex-w">
                      <div className="wrap-slick3-dots" />
                      <div className="wrap-slick3-arrows flex-sb-m flex-w" />
                      <div className="slick3 gallery-lb">
                        <div className="item-slick3" data-thumb="images/product-detail-01.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-01.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-01.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-02.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-02.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-02.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-03.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-03.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-03.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5 p-b-30">
                  <div className="p-r-50 p-t-5 p-lr-0-lg">
                    <h4 className="mtext-105 cl2 js-name-detail p-b-14">
                      Lightweight Jacket
                    </h4>
                    <span className="mtext-106 cl2">
                      $58.79
                    </span>
                    <p className="stext-102 cl3 p-t-23">
                      Nulla eget sem vitae eros pharetra viverra. Nam vitae luctus ligula. Mauris consequat ornare feugiat.
                    </p>
                    {/*  */}
                    <div className="p-t-33">
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Size
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Size S</option>
                              <option>Size M</option>
                              <option>Size L</option>
                              <option>Size XL</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Color
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Red</option>
                              <option>Blue</option>
                              <option>White</option>
                              <option>Grey</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-204 flex-w flex-m respon6-next">
                          <div className="wrap-num-product flex-w m-r-20 m-tb-10">
                            <div className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-minus" />
                            </div>
                            <input className="mtext-104 cl3 txt-center num-product" type="number" name="num-product" defaultValue={1} />
                            <div className="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-plus" />
                            </div>
                          </div>
                          <button className="flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04 js-addcart-detail">
                            Add to cart
                          </button>
                        </div>
                      </div>	
                    </div>
                    {/*  */}
                    <div className="flex-w flex-m p-l-100 p-t-40 respon7">
                      <div className="flex-m bor9 p-r-10 m-r-11">
                        <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 js-addwish-detail tooltip100" data-tooltip="Add to Wishlist">
                          <i className="zmdi zmdi-favorite" />
                        </a>
                      </div>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Facebook">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Twitter">
                        <i className="fa fa-twitter" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Google Plus">
                        <i className="fa fa-google-plus" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
 






        )
    
}

export default Home;