import React ,{useEffect , useState} from 'react'
// import {Link} from "react-router-dom"
import swal from 'sweetalert';
// import $ from 'jquery';
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
// import createVCard from 'create-vcard';
// import vcard from 'vcard-generator';
// import ppp11 from "./../assets/images/ba1.jpeg"

import "react-multi-carousel/lib/styles.css";
import "react-multi-carousel/lib/styles.css";
import "./../assets/css/main.css"
import "./../assets/css/util.css"
import "./../assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css"
import VCard from 'vcard-creator'


// import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"

import "./../assets/vendor/css-hamburgers/hamburgers.min.css"

import "./../assets/fonts/iconic/css/material-design-iconic-font.min.css"
import "./../assets/vendor/bootstrap/css/bootstrap.min.css"
import "./../assets/vendor/select2/select2.min.css"
import "./../assets/vendor/slick/slick.css"
import "./../assets/vendor/perfect-scrollbar/perfect-scrollbar.css"

import ham1 from "./../assets/images/ham1.jpeg"
import ham2 from "./../assets/images/ham2.jpeg"
import ham3 from "./../assets/images/ham3.jpeg"
import ham4 from "./../assets/images/ham4.jpeg"
import ham5 from "./../assets/images/ham5.jpeg"





const Home = ( props ) => {
    const [myRef,setRef] = useState( React.createRef())
    // const [scrollTop1,setscrollTop] = useState(0)
    // const [email , setemail] = useState("")
    // const [pass , setpass] = useState("")
    const [dispaly ,  setdispaly ] = useState(true)
    // const [dis1 ,  setdis1 ] = useState(true)
    // const [dis2 ,  setdis2 ] = useState(true)
    // const [dis3 ,  setdis3 ] = useState(true)
    const [User1 ,  setUser1 ] = useState({})
     const [Arr1 ,  setArr1 ] = useState([])


    useEffect(() =>{        window.scrollTo(0, 0)
      if(! JSON.parse(localStorage.getItem("User")) ){
        props.history.push("/login")

      }else
    //   localStorage.setItem("UserProp",JSON.stringify("1"))
    localStorage.setItem("VenderWorkingThose" ,JSON.stringify(123)) 
      localStorage.removeItem("UserProp")


  fetch("https://tapitdabit-backend.vercel.app/Userother/"+props.match.params.otheruserId,{
    method: "GET",
     headers :  {
     "Content-Type" : "application/json" , 
 } ,
})
.then(res5=>res5.json())
.then(res6=>{
  console.log(res6)
    setUser1(res6)
    // localStorage.setItem("User 56" , JSON.stringify(res6))
    localStorage.setItem("User" ,JSON.stringify(res6)) 





    if (res6.Direct1.length > 0){
      console.log(4545)
      if(res6.Direct1[0].link)
      window.location.assign(res6.Direct1[0].link )
      else if(res6.Direct1[0].name === "Contact"){
        console.log(4545)
        var vCardsJS = require('vcards-js');
        // const fs = require('fs')
        const FileSaver = require('file-saver');

    
            //create a new vCard



            const myVCard = new VCard()

            myVCard.addName(res6.Direct1[0].fullName)
            // Add work res6.Direct1[0].            myVCard.addCompany('Siesqo')
            myVCard.addJobtitle(res6.Direct1[0].Role)
            myVCard.addRole(res6.Direct1[0].Role)
            myVCard.addEmail(res6.Direct1[0].Email)
            myVCard.addPhoneNumber(res6.Direct1[0].Phone, 'Personal')
            myVCard.addPhoneNumber(res6.Direct1[0].Phone, 'WORK')
            myVCard.addAddress(res6.Direct1[0].Country)
            myVCard.addAddress(res6.Direct1[0].Address)
                  for (let i = 0; i < res6.Direct1[0].Contact.length; i++) {
               if(res6.Direct1[0].Contact[i].name !== undefined  && res6.Direct1[0].Contact[i].link !== undefined && res6.Direct1[0].Contact[i].link[0] === "h")
                    myVCard.addURL(res6.Direct1[0].Contact[i].link)
                  }
            
            
          
            console.log(myVCard.toString())
          
          
          
          console.log(myVCard.toString() );
          const blob = new Blob([ myVCard.toString() ], {type: "text/vcard;charset=utf-8"});
          console.log(myVCard)
          FileSaver.saveAs(blob, res6.Direct1[0].fullName+".vcf");
          











    //         var vCard = vCardsJS();
             
    //         //set properties
    //         vCard.firstName = res6.Direct1[0].fullName;
    //         // vCard.middleName =  res6.Direct1[0].fullName;
    //         // vCard.lastName = res6.Direct1[0].Name;
    //         if(res6.Direct1[0].photo){
    //              vCard.photo.attachFromUrl(res6.Direct1[0].photo);
    //         vCard.logo =(res6.Direct1[0].photo)
    //       }
    //       if(res6.Direct1[0].photo1){
    //           vCard.photo.attachFromUrl(res6.Direct1[0].photo1);
    //      vCard.logo =(res6.Direct1[0].photo1)
    
    //         }
         
    //         vCard.organization = res6.Direct1[0].Role;
    //         vCard.workPhone = res6.Direct1[0].Phone;
    //         // vCard.birthday = new Date(1985, 0, 1);
    //         vCard.title =res6.Direct1[0].Role;
    //         vCard.url = 'https://tapitdabit.com/user/'+User1.uid;
    //         vCard.note = res6.Direct1[0].Role;
    //         vCard.workPhone = res6.Direct1[0].Phone;
    //           // vCard.logo = ("https://res.cloudinary.com/freedeveloperss123/image/upload/v1635694890/doqfirps8iwyoutvcpkb",".jpg")
    //           // // vCard.photo.attachFromUrl = (res6.Direct1[0].photo);
    //           // vCard.logo.attachFromUrl('https://avatars2.githubusercontent.com/u/5659221?v=3&s=460', 'JPEG');
    
    //           // vCard.photo.attachFromUrl(res6.Direct1[0].photo);
    //           // const imagePath = "https://res.cloudinary.com/freedeveloperss123/image/upload/v1635694890/doqfirps8iwyoutvcpkb.jpg"
    //           // const image = fs.readFileSync(imagePath, { encoding: 'base64', flag: 'r' })
              
    //         // vCard.socialUrls
    //         vCard.note = res6.Direct1[0].Country
    //         console.log(User1.DisPersonal)
    //         if(User1.DisPersonal){
    //         for (let i = 0; i < res6.Direct1[0].Contact.length; i++) {
    //           vCard.socialUrls[res6.Direct1[0].Contact[i].name] =res6.Direct1[0].Contact[i].link;
            
                    
    //           // const element = array[i];
              
    //         }
    //       }
    //       else{
    //       for (let i = 0; i < res6.Direct1[0].ContactBuss.length; i++) {
    //         vCard.socialUrls[res6.Direct1[0].ContactBuss[i].name] =res6.Direct1[0].ContactBuss[i].link;
          
                  
    //         // const element = array[i];
            
    //       }
    
    //     }
    //         // vCard.socialUrls['facebook'] = 'https://...';
    //         // vCard.socialUrls['linkedIn'] = 'https://...';
    //         // vCard.socialUrls['twitter'] = 'https://...';
    //         // vCard.socialUrls['flickr'] = 'https://...';
    //         // vCard.socialUrls['custom'] = 'https://...';
    //                 //     vCard.birthday = new Date(1985, 0, 1);
    //         //     vCard.title = 'Software Developer';
    //         //     vCard.url = 'https://github.com/enesser';
    //         //     vCard.workUrl = 'https://acme-corporation/enesser';
    //         //     vCard.note = 'Notes on Eric';
    //             vCard.workAddress = res6.Direct1[0].Address
    //         //     //set other vitals
    //         //     vCard.nickname = 'Scarface';
    //         //     vCard.namePrefix = 'Mr.';
    //         //     vCard.nameSuffix = 'JR';
    //             vCard.gender = res6.Direct1[0].Gender;
    //         //     vCard.anniversary = new Date(2004, 0, 1);
    //             vCard.role =res6.Direct1[0].Role;
                
    //         //     //set other phone numbers
    //             vCard.homePhone = res6.Direct1[0].Phone;
    //         //     vCard.cellPhone = '312-555-1414';
    //         //     vCard.pagerPhone = '312-555-1515';
                
    //         //     //set fax/facsimile numbers
    //         //     vCard.homeFax = '312-555-1616';
    //         //     vCard.workFax = '312-555-1717';
                
    //         //     //set email addresses
    //             vCard.email = res6.Direct1[0].Email;
    //         //     vCard.workEmail = 'e.nesser@acme-corporation.tld';
    //         //save to file
    //         const FileSaver = require('file-saver');
    // const blob = new Blob([ vCard.getFormattedString() ], {type: "text/vcard;charset=utf-8"});
    // FileSaver.saveAs(blob,res6.Direct1[0].fullName+".vcf");
    

            // vCard.saveToFile('Content-Type', 'text/vcard; name="person.vcf"')
            //  .saveToFile('./eric-nesser.vcf');
             
            //get as formatted string
            // console.log(vCard.getFormattedString());
    
        // fetch("/aaa",{
        //     method: "GET",
        //      headers :  {
        //      "Content-Type" : "application/json" , 
        //  } , 
        
        // })
        // .then(res5=>res5.json())
        // .then(res6=>{
        //     swal("Success")
        // })
            
      }

      else if(res6.Direct1[0].name === "Embed video")
      window.location.assign(res6.Direct2[0].link)
      
      else if(res6.Direct1[0].name === "whatsapps")
      window.location.assign("https://wa.me/"+res6.Direct2[0].link)
      
      else if(res6.Direct1[0].name === "email")
      window.location.assign("mailto:"+res6.Direct2[0].link)
      
      else if(res6.Direct1[0].name === "phone")
      window.location.assign("tel:"+res6.Direct2[0].link)
      
      else if(res6.Direct1[0].name === "text")
      window.location.assign("sms:"+res6.Direct2[0].link)
      
      else if(res6.Direct1[0].name === "whatsapps")
      window.location.assign("https://wa.me/"+res6.Direct2[0].link)



       setArr1(res6.Direct1)}






    if (res6.Direct2.length > 0){


      if(res6.Direct2[0].link)
        window.location.assign(res6.Direct2[0].link)





      else if(res6.Direct2[0].name === "Contact"){


        const FileSaver = require('file-saver');


const myVCard = new VCard()

myVCard.addName(res6.Direct2[0].fullName)
// Add work res6.Direct2[0]
// myVCard.addCompany('Siesqo')
myVCard.addJobtitle(res6.Direct2[0].Role)
myVCard.addRole(res6.Direct2[0].Role)
myVCard.addEmail(res6.Direct2[0].Email)
myVCard.addPhoneNumber(res6.Direct2[0].Phone, 'Personal')
myVCard.addPhoneNumber(res6.Direct2[0].Phone, 'WORK')
myVCard.addAddress(res6.Direct2[0].Country)
myVCard.addAddress(res6.Direct2[0].Address)

    for (let i = 0; i < res6.Direct2[0].ContactBuss.length; i++) {
      if(res6.Direct2[0].ContactBuss[i].name !== undefined  && res6.Direct2[0].ContactBuss[i].link !== undefined && res6.Direct2[0].ContactBuss[i].link[0] === "h")
         myVCard.addURL(res6.Direct2[0].ContactBuss[i].link)

}



console.log(myVCard.toString())



console.log(myVCard.toString() );
const blob = new Blob([ myVCard.toString() ], {type: "text/vcard;charset=utf-8"});
console.log(myVCard)
FileSaver.saveAs(blob, res6.Direct2[0].fullName+".vcf");










    //     var vCardsJS = require('vcards-js');
    //     const fs = require('fs')
    
    //         //create a new vCard
    //         var vCard = vCardsJS();
             
    //         //set properties
    //         vCard.firstName = res6.Direct2[0].fullName;
    //         // vCard.middleName =  res6.Direct2[0].fullName;
    //         // vCard.lastName = res6.Direct2[0].Name;
    //         if(res6.Direct2[0].photo){
    //              vCard.photo.attachFromUrl(res6.Direct2[0].photo);
    //         vCard.logo =(res6.Direct2[0].photo)
    //       }
    //       if(res6.Direct2[0].photo1){
    //           vCard.photo.attachFromUrl(res6.Direct2[0].photo1);
    //      vCard.logo =(res6.Direct2[0].photo1)
    
    //         }
         
    //         vCard.organization = res6.Direct2[0].Role;
    //         vCard.workPhone = res6.Direct2[0].Phone;
    //         // vCard.birthday = new Date(1985, 0, 1);
    //         vCard.title =res6.Direct2[0].Role;
    //         vCard.url = 'https://tapitdabit.com/user/'+User1.uid;
    //         vCard.note = res6.Direct2[0].Role;
    //         vCard.workPhone = res6.Direct2[0].Phone;
    //           // vCard.logo = ("https://res.cloudinary.com/freedeveloperss123/image/upload/v1635694890/doqfirps8iwyoutvcpkb",".jpg")
    //           // // vCard.photo.attachFromUrl = (res6.Direct2[0].photo);
    //           // vCard.logo.attachFromUrl('https://avatars2.githubusercontent.com/u/5659221?v=3&s=460', 'JPEG');
    
    //           // vCard.photo.attachFromUrl(res6.Direct2[0].photo);
    //           // const imagePath = "https://res.cloudinary.com/freedeveloperss123/image/upload/v1635694890/doqfirps8iwyoutvcpkb.jpg"
    //           // const image = fs.readFileSync(imagePath, { encoding: 'base64', flag: 'r' })
              
    
    //         vCard.note = res6.Direct2[0].Country
    //         console.log(User1.DisPersonal)
    //         if(User1.DisPersonal){
    //         for (let i = 0; i < res6.Direct2[0].Contact.length; i++) {
    //           vCard.socialUrls[res6.Direct2[0].Contact[i].name] =res6.Direct2[0].Contact[i].link;
            
                    
    //           // const element = array[i];
              
    //         }
    //       }
    //       else{
    //       for (let i = 0; i < res6.Direct2[0].ContactBuss.length; i++) {
    //         vCard.socialUrls[res6.Direct2[0].ContactBuss[i].name] =res6.Direct2[0].ContactBuss[i].link;
          
                  
    //         // const element = array[i];
            
    //       }
    
    //     }
    //         // vCard.socialUrls['facebook'] = 'https://...';
    //         // vCard.socialUrls['linkedIn'] = 'https://...';
    //         // vCard.socialUrls['twitter'] = 'https://...';
    //         // vCard.socialUrls['flickr'] = 'https://...';
    //         // vCard.socialUrls['custom'] = 'https://...';
    //                 //     vCard.birthday = new Date(1985, 0, 1);
    //         //     vCard.title = 'Software Developer';
    //         //     vCard.url = 'https://github.com/enesser';
    //         //     vCard.workUrl = 'https://acme-corporation/enesser';
    //         //     vCard.note = 'Notes on Eric';
    //             vCard.workAddress = res6.Direct2[0].Address
    //         //     //set other vitals
    //         //     vCard.nickname = 'Scarface';
    //         //     vCard.namePrefix = 'Mr.';
    //         //     vCard.nameSuffix = 'JR';
    //             vCard.gender = res6.Direct2[0].Gender;
    //         //     vCard.anniversary = new Date(2004, 0, 1);
    //             vCard.role =res6.Direct2[0].Role;
                
    //         //     //set other phone numbers
    //             vCard.homePhone = res6.Direct2[0].Phone;
    //         //     vCard.cellPhone = '312-555-1414';
    //         //     vCard.pagerPhone = '312-555-1515';
                
    //         //     //set fax/facsimile numbers
    //         //     vCard.homeFax = '312-555-1616';
    //         //     vCard.workFax = '312-555-1717';
                
    //         //     //set email addresses
    //             vCard.email = res6.Direct2[0].Email;
    //         //     vCard.workEmail = 'e.nesser@acme-corporation.tld';
    //         //save to file
    //         const FileSaver = require('file-saver');
    // const blob = new Blob([ vCard.getFormattedString() ], {type: "text/vcard;charset=utf-8"});
    // FileSaver.saveAs(blob,res6.Direct2[0].fullName+".vcf");
            // vCard.saveToFile('Content-Type', 'text/vcard; name="person.vcf"')
            //  .saveToFile('./eric-nesser.vcf');
             
            //get as formatted string
            // console.log(vCard.getFormattedString());
    
        // fetch("/aaa",{
        //     method: "GET",
        //      headers :  {
        //      "Content-Type" : "application/json" , 
        //  } , 
        
        // })
        // .then(res5=>res5.json())
        // .then(res6=>{
        //     swal("Success")
        // })
            
      }


      else if(res6.Direct1[0].name === "Embed video")
      window.location.assign(res6.Direct2[0].link)
      
      else if(res6.Direct1[0].name === "whatsapps")
      window.location.assign("https://wa.me/"+res6.Direct2[0].link)
      
      else if(res6.Direct1[0].name === "email")
      window.location.assign("mailto:"+res6.Direct2[0].link)
      
      else if(res6.Direct1[0].name === "phone")
      window.location.assign("tel:"+res6.Direct2[0].link)
      
      else if(res6.Direct1[0].name === "text")
      window.location.assign("sms:"+res6.Direct2[0].link)
      
      else if(res6.Direct1[0].name === "whatsapps")
      window.location.assign("https://wa.me/"+res6.Direct2[0].link)




       setArr1(res6.Direct1)}
    
    if (res6.DisPersonal) setArr1(res6.DispalyShow)
    else setArr1(res6.DispalyShow1)
    setdispaly(false)
  //  console.log(res6)
  
fetch("https://tapitdabit-backend.vercel.app/USeruPDATE12",{
  method: "POST",
   headers :  {
   "Content-Type" : "application/json" , 
} ,
body : JSON.stringify({
id : res6.uid,
ids : res6.DisPersonal
})
})
.then(res15=>res15.json())
.then(res16=>{
console.log(res16)
})

})
    

//   fetch("https://tapitdabit-backend.vercel.app/others123",{
//     method: "POST",
//      headers :  {
//      "Content-Type" : "application/json" , 
//  } ,  body : JSON.stringify({
//           id : User1._id
// })
// })
// .then(res55=>res55.json())
// .then(res65=>{
//   console.log(res65)

//   //  console.log(res6)
// })
    

return () => {
  document.getElementById("get1").classList.remove("is-active");
  document.getElementById("get2").style.display = "none";
//   localStorage.removeItem("UserProp")
}
    },[])
    useEffect( () => () => localStorage.removeItem("VenderWorkingThose"), [] );


//     const setChange = () =>{
//         if(document.getElementById("get2").style.display === "none"){
//             document.getElementById("get1").classList.add("is-active");
//             document.getElementById("get2").style.display = "block";
//         }
//         else{
//             document.getElementById("get1").classList.remove("is-active");
//             document.getElementById("get2").style.display = "none";
//         }
//     }

//     const setChange1 = (e) =>{
//       if(e === 1 ){
//         // document.getElementById("signUp").classList.add("right-panel-active");
//         // document.getElementById("signIn").classList.remove("right-panel-active");
//         document.getElementById("container1").classList.add("right-panel-active")
//       }
//       else{
//         document.getElementById("container1").classList.remove("right-panel-active")
//       }
//     }




// const postData = (e) =>{
//   e.preventDefault()
//   if(email.length === 0){
//       // if (email === "projectpharma874@gmail.com")
//       swal("Enter The Correct Email !" )
//          return
//       }
//   if(pass.length === 0){
//       swal("Enter The Password !"  )
//          return
//        }
 
//     fetch("https://tapitdabit-backend.vercel.app/logIn-user",{
//       method: 'POST' , 
//       headers :  {
//         "Content-Type" : "application/json" , 
//       } , 
//       body : JSON.stringify({
//         email  ,
//         pass  ,
//       })
//     })
//     .then((res)=>res.json())
//     .then((res2)  =>{
//         // console.log(res2)
//         if (res2 !== null && !res2.Error  ){
//           if(!res2.isSettings){
//              swal("SucessFully Login"  )
//           localStorage.setItem("User" , JSON.stringify(res2) )
//               props.history.push("/settings-profile-vender")
//             }
//             else{
//            swal("SucessFully Login"  )
//         localStorage.setItem("User" , JSON.stringify(res2) )
//             props.history.push("/")
//          }
//         }
//         else{
//           if(res2.Error === "Plz Fill Setting first"){
//               props.history.push("/settings-profile-vender")
//           }
//           swal(res2.Error )
//         }
//       // console.log(res2)
//     })
//     .catch((err)=>console.log(err))
  

// }


// const  setPassShow = (e)=>{
//   setdispaly(e)
// }
// const  setLoc = (e)=>{
// window.location.assign(
//   e,
//   '_blank' // <- This is what makes it open in a new window.
// );
// }



// function getLocation() {
//   // var x = document.getElementById("demo");
//   if (navigator.geolocation) {
//     navigator.geolocation.getCurrentPosition(showPosition);
//   } else { 
//     swal("Not Access the location")
//     // x.innerHTML = "Geolocation is not supported by this browser.";
//   }
// }

// function showPosition(position) {
//   // var x = document.getElementById("demo");
//   // x.innerHTML = "Latitude: " + position.coords.latitude + 
//   // "<br>Longitude: " + position.coords.longitude;
//   fetch("https://tapitdabit-backend.vercel.app/createaddressLink",{
//                                           method: "POST",
//                                           headers :  {
//                                               "Content-Type" : "application/json" , 
//                                           } ,
//                                           body : JSON.stringify({
//                                             name  : position.coords.latitude,
//                                             name1  : position.coords.longitude,
//                                             link : email,
//                                             id : User1._id
//                                               // MainLine2 :mainline ,
//                                           })
//                                       })
//                                       .then(res11=>res11.json())
//                                       .then((res12)=>{ 
//                                           if(res12.Error){

//                                           swal(res12.Error);
//                                       }
//                                       else{
//                                               swal("Successfully Upload your Product!");
//                                               this.setState({
//                                                   email: "",
//                                           })
//                                       }
//                                   })
//                                   .catch(err=>{
//                                       swal("There is An Error")                  
//                                       })
// }


// const setChangeCard = () =>{
  
//   const data = User1.DisPersonal ? User1.Contact[0] : User1.ContactBuss[0]
//   if(data){
//     const myVCard = new VCard()
//     const FileSaver = require('file-saver');

//     myVCard.addName(data.fullName)
//     // Add work data
//     // myVCard.addCompany('Siesqo')
//     myVCard.addJobtitle(data.Role)
//     myVCard.addRole(data.Role)
//     myVCard.addEmail(data.Email)
//     myVCard.addPhoneNumber(data.Phone, 'Personal')
//     myVCard.addPhoneNumber(data.Phone, 'WORK')
//     myVCard.addAddress(data.Country)
//     myVCard.addAddress(data.Address)
//     if(User1.DisPersonal){
//           for (let i = 0; i < data.Contact.length; i++) {
//       if(data.Contact[i].name !== undefined  && data.Contact[i].link !== undefined && data.Contact[i].link[0] === "h")
//             myVCard.addURL( data.Contact[i].link)
//             // myVCard.addName("tytry")
//           }
//     }
//     else{
//         for (let i = 0; i < data.ContactBuss.length; i++) {
//           if(data.ContactBuss[i].name !== undefined  && data.ContactBuss[i].link !== undefined && data.ContactBuss[i].link[0] === "h")
//             myVCard.addURL(data.ContactBuss[i].link)

//     }
//     }


//     console.log(myVCard.toString())



//     console.log(myVCard.toString() );
//     const blob = new Blob([ myVCard.toString() ], {type: "text/vcard;charset=utf-8"});
//     console.log(myVCard)
//     FileSaver.saveAs(blob, data.fullName+".vcf");




//   }
    
// }


// const setTheProfile = (e,data)=>{
//   console.log("gtg")
//           fetch("https://tapitdabit-backend.vercel.app/dispalyShow",{
//             method: "POST",
//             headers :  {
//                 "Content-Type" : "application/json" , 
//             } ,
//             body : JSON.stringify({
//                 data,
//               id : User1._id
//                 // MainLine2 :mainline ,
//             })
//         })
//         .then(res11=>res11.json())
//         .then((res12)=>{ 
//             if(res12.Error){

//             swal(res12.Error);
//         }
//         else{
//                 swal("Successfully Update!");
//             //     this.setState({
//             //         email: "",
//             // })
//         }
//         })

// }
// const OpenVcard = ( data )=>{
  
//     console.log("hyjy",data)

//     setdispaly1(data)
//     var vCardsJS = require('vcards-js');
//     const fs = require('fs')
//         //create a new vCard
//         var vCard = vCardsJS();
         
//         //set properties
//         vCard.firstName = data.fullName;
//         // vCard.middleName =  data.fullName;
//         // vCard.lastName = data.Name;
//         if(data.photo){
//              vCard.photo.attachFromUrl(data.photo);
//         vCard.logo =(data.photo)
//       }
//       if(data.photo1){
//           vCard.photo.attachFromUrl(data.photo1);
//      vCard.logo =(data.photo1)

//         }
     
//         vCard.organization = data.Role;
//         vCard.workPhone = data.Phone;
//         // vCard.birthday = new Date(1985, 0, 1);
//         vCard.title =data.Role;
//         // vCard.socialUrls = ['https://tapitdabit.com/user/'+User1.uid ,'https://tapitdabit.com/user111/'+User1.uid]
//         // vCard.socialUrls[0] = 'https://tapitdabit.com/user/'+User1.uid 
//         // vCard.socialUrls[1] = 'https://tapitdabit.com/user111/'+User1.uid
//       // vCard.workUrl =['https://tapitdabit.com/user/'+User1.uid ,'https://tapitdabit.com/user111/'+User1.uid]
//       vCard.workUrl ='https://tapitdabit.com/user/'+User1.uid 
//       vCard.url ='https://tapitdabit.com/user/'+User1.uid 
    
//         vCard.note = data.Role;
//         vCard.workPhone = data.Phone;
      
//           // vCard.logo = ("https://res.cloudinary.com/freedeveloperss123/image/upload/v1635694890/doqfirps8iwyoutvcpkb",".jpg")
//           // // vCard.photo.attachFromUrl = (data.photo);
//           // vCard.logo.attachFromUrl('https://avatars2.githubusercontent.com/u/5659221?v=3&s=460', 'JPEG');

//           // vCard.photo.attachFromUrl(data.photo);
//           // const imagePath = "https://res.cloudinary.com/freedeveloperss123/image/upload/v1635694890/doqfirps8iwyoutvcpkb.jpg"
//           // const image = fs.readFileSync(imagePath, { encoding: 'base64', flag: 'r' })
          

//         vCard.note = data.Country
//     // vCard.
// //         vCard.source = [
// //   'https://www.facebook.com/webocreators',
// //   'https://www.instagram.com/webocreators',
// //   'https://www.whatapps.com/webocreators'
// // ];
// // vCard.socialUrls.facebook = "https://www.facebook.com"
// // vCard.socialUrls.
//         console.log(User1.DisPersonal)
//         if(User1.DisPersonal){
// // vCard.socialUrls['facebook'] = 'https://...';
// // vCard.socialUrls['linkedIn'] = 'https://...';
// // vCard.socialUrls['twitter'] = 'https://...';
// // vCard.socialUrls['flickr'] = 'https://...';
// // vCard.socialUrls['custom'] = 'https://...';
// // vCard.socialUrls = "efifedp;"

// // vCard.workUrl = "https://...1111"
//         // for (let i = 0; i < data.Contact.length; i++) {
//         //   console.log(data.Contact[i].name ,data.Contact[i].link)
//         //   if(data.Contact[i].name !== undefined  && data.Contact[i].link !== undefined)
//         //   vCard.socialUrls[data.Contact[i].name] =data.Contact[i].link;
        
                
          
//         // }
//       }
//       else{
// // vCard.socialUrls['facebook'] = 'https://...';
// // vCard.socialUrls['linkedIn'] = 'https://...';
// // vCard.socialUrls['twitter'] = 'https://...';
// // vCard.socialUrls['flickr'] = 'https://...';
// // vCard.socialUrls['custom'] = 'https://...';

//       // for (let i = 0; i < data.ContactBuss.length; i++) {
//       //   console.log(data.ContactBuss[i].name ,data.ContactBuss[i].link)
//         // if(data.ContactBuss[i].name !== undefined  && data.ContactBuss[i].link !== undefined)
//         // vCard.socialUrls[data.ContactBuss[i].name] =data.ContactBuss[i].link;
      
              
//         // const element = array[i];
        
//     //   }

//     }
//         // vCard.socialUrls['facebook'] = 'https://...';
//         // vCard.socialUrls['linkedIn'] = 'https://...';
//         // vCard.socialUrls['twitter'] = 'https://...';
//         // vCard.socialUrls['flickr'] = 'https://...';
//         // vCard.socialUrls['custom'] = 'https://...';
//         // vCard.socialUrls = {
//         //   facebook : "https://www.facebook.com/webocreators" ,
//         //   linkedIn : "https://www.linkedIn.com/webocreators" ,
//         //   twitter : "https://www.twitter.com/webocreators" ,
//         //   custom : "https://www.custom.com/webocreators" ,
//         // }
        
//                 //     vCard.birthday = new Date(1985, 0, 1);
//             vCard.title = data.Role;
//         //     vCard.url = 'https://github.com/enesser';
//         //     vCard.workUrl = 'https://acme-corporation/enesser';
//         //     vCard.note = 'Notes on Eric';
//             vCard.workAddress = data.Address
//             vCard.homeAddress = data.Address
//         //     //set other vitals
//         //     vCard.nickname = 'Scarface';
//         //     vCard.namePrefix = 'Mr.';
//         //     vCard.nameSuffix = 'JR';
//             vCard.gender = data.Gender;
//         //     vCard.anniversary = new Date(2004, 0, 1);
//             vCard.role =data.Role;
            
//         //     //set other phone numbers
//             vCard.homePhone = data.Phone;
//         //     vCard.cellPhone = '312-555-1414';
//         //     vCard.pagerPhone = '312-555-1515';
            
//         //     //set fax/facsimile numbers
//         //     vCard.homeFax = '312-555-1616';
//         //     vCard.workFax = '312-555-1717';
            
//         //     //set email addresses
//             vCard.email = data.Email;
//             // vCard.url =
//             //   ['https://www.facebook.com/webocreators']
//             // vCard.url =
//               // ["https://www.whatapps.com/webocreators"]
//               // vCard.socialUrls =  ["https://www.whatapps.com/webocreators",'https://www.facebook.com/webocreators']
//               // vCard.note="https://www.instagram.com/webocreators"
//               // vCard.url
//             // vCard.url.link = "https://www.instagram.com/webocreators"
//             // vCard.socialUrls = "https://www.instagram.com/webocreators"
//             // vCard.socialUrls = "https://www.facebook.com/webocreators"
//             // vCard.add('URL')
//             // vCard.url.valueOf = 'https://www.example.com'
//             // vCard.url.type_param = "Website:"
//             // vCard.add('URL')
//             // vCard.url.valueOf = 'https://www.facebook.com'
//             // vCard.url .type_param = "Facebook:"
//               // vCard.url =
//             //   ' https://www.instagram.com/webocreators'
//             // vCard.url =
//             //   'https://www.whatapps.com/webocreators'
//             // vCard.workUrl =
//             //   'https://www.facebook.com/webocreators /n https://www.instagram.com/webocreators /n https://www.whatapps.com/webocreators'
//             //   vCard.note = data.Role
//             //   +"https://www.facebook.com/webocreators /n https://www.instagram.com/webocreators"
//             //   +"https://www.facebook.com/webocreators "+<br />+" https://www.instagram.com/webocreators"


              
//               ;

//         //     vCard.workEmail = 'e.nesser@acme-corporation.tld';
//         //save to file
//         const FileSaver = require('file-saver');
//         vCard  =  {
//           ...vCard , 
          
//           // url : [
//           //   // { facebook :  'https://www.facebook.com/webocreators'},
//           //   // { instagram : 'https://www.instagram.com/webocreators'},
//           //   //  { pad :  'https://www.whatapps.com/webocreators'}
//           //     'https://www.facebook.com/webocreators \n https://www.instagram.com/webocreators \n https://www.whatapps.com/webocreators\n',
              
//           //     // 'https://www.facebook.com/webocreators\n',
//           //     // 'https://www.instagram.com/webocreators\n',
//           //     // 'https://www.whatapps.com/webocreators\n'
//           //   ] ,
            
//           // Website : [
//           //   // { facebook :  'https://www.facebook.com/webocreators'},
//           //   // { instagram : 'https://www.instagram.com/webocreators'},
//           //   //  { pad :  'https://www.whatapps.com/webocreators'}
//           //     'https://www.facebook.com/webocreators',
              
//           //     // 'https://www.facebook.com/webocreators\n',
//           //     // 'https://www.instagram.com/webocreators\n',
//           //     // 'https://www.whatapps.com/webocreators\n'
//           //   ] ,

//           // socialUrls : [
//           //   // { facebook :  'https://www.facebook.com/webocreators'},
//           //   // { instagram : 'https://www.instagram.com/webocreators'},
//           //   //  { pad :  'https://www.whatapps.com/webocreators'}
//           //     'https://www.facebook.com/webocreators \n https://www.instagram.com/webocreators \n https://www.whatapps.com/webocreators\n',
              
//           //     // 'https://www.facebook.com/webocreators\n',
//           //     // 'https://www.instagram.com/webocreators\n',
//           //     // 'https://www.whatapps.com/webocreators\n'
//           //   ] ,
//           // url : [
//           //     'https://www.facebook.com/webocreators',
//           //     // 'https://www.instagram.com/webocreators',
//           //     // 'https://www.whatapps.com/webocreators'
//           //   ],
//           // url : [
//           //     'https://www.facebook.com/1webocreators',
//           //     // 'https://www.instagram.com/webocreators',
//           //     // 'https://www.whatapps.com/webocreators'
//           //   ],
//           // url : [
//           //     'https://www.facebook.com/2webocreators',
//           //     'https://www.facebook.com/3webocreators',
//           //     // 'https://www.instagram.com/webocreators',
//           //     // 'https://www.whatapps.com/webocreators'
//           //   ],
//           // url :
//           //     'https://www.facebook.com/3webocreators'
//           //    ,
//           // url :
//           //     'https://www.facebook.com/1webocreators'
//           //    ,
//           // workUrls :
//           //   [  
//           //     'https://www.facebook.com/45webocreators',
//           //     'https://www.facebook.com/1webocreators'
//           // ]
            
//           // socialUrls :{
//           //  facebook :  'https://www.facebook.com/webocreators',
//           //    instagram : 'https://www.instagram.com/webocreators',
//           //  pad :  'https://www.whatapps.com/webocreators'
//           //   },
//               socialUrls : [
//             { facebook :  'https://www.facebook.com/webocreators'},
//             { instagram : 'https://www.instagram.com/webocreators'},
//              { pad :  'https://www.whatapps.com/webocreators'}
           
//             ] ,
//         }
//         vCard.isOrganization = true;






// const myVCard = new VCard()

//   myVCard.addName(data.fullName)
//   // Add work data
//   // myVCard.addCompany('Siesqo')
//   myVCard.addJobtitle(data.Role)
//   myVCard.addRole(data.Role)
//   myVCard.addEmail(data.Email)
//   myVCard.addPhoneNumber(data.Phone, 'Personal')
//   myVCard.addPhoneNumber(data.Phone, 'WORK')
//   myVCard.addAddress(data.Country)
//   myVCard.addAddress(data.Address)
//   if(User1.DisPersonal){
//         for (let i = 0; i < data.Contact.length; i++) {
//      if(data.Contact[i].name !== undefined  && data.Contact[i].link !== undefined && data.Contact[i].link[0] === "h")
//           myVCard.addURL( data.Contact[i].link)
//           // myVCard.addName("tytry")
//         }
//   }
//   else{
//       for (let i = 0; i < data.ContactBuss.length; i++) {
//         if(data.ContactBuss[i].name !== undefined  && data.ContactBuss[i].link !== undefined && data.ContactBuss[i].link[0] === "h")
//            myVCard.addURL(data.ContactBuss[i].link)

//   }
//   }
  

//   console.log(myVCard.toString())



// console.log(myVCard.toString() );
// const blob = new Blob([ myVCard.toString() ], {type: "text/vcard;charset=utf-8"});
// console.log(myVCard)
// FileSaver.saveAs(blob, data.fullName+".vcf");








// // const card = createVCard({
// //   firstName: 'Max',
// //   lastName: 'Stoiber',
// //   middleName: 'MRS',
// //   url : "https://www.facebook.com/ertertfere",
// //   url : "https://www.facebook.com/1ertertfere",
// //   url :     'https://www.facebook.com/webocreators \n https://www.instagram.com/webocreators \n https://www.whatapps.com/webocreators\n',

// //   website : "https://www.facebook.com/1ertertfere",
// //   website : "https://www.facebook.com/12ertertfere",
// //   organization :  "SOftwqare" ,
// //   photo: "",
// //   // birthday: "Date",
// //   title: "Umer",
// //   socialUrls : {
// //   facebook :  'https://www.facebook.com/webocreators',
// //  instagram : 'https://www.instagram.com/webocreators',
// //       pad :  'https://www.whatapps.com/webocreators'
// //     }
// // });
 
// // console.log(card.getFormattedString());
// // const blob = new Blob([ card.getFormattedString() ], {type: "text/card;charset=utf-8"});
// // // console.log(vCard,vCardsJS)
// // FileSaver.saveAs(blob, data.fullName+".vcf");

























// // console.log(vCard,vCardsJS,FileSaver.saveAs(blob, data.fullName+".vcf"))
//         // vCard.saveToFile('Content-Type', 'text/vcard; name="person.vcf"')
//         //  .saveToFile('./eric-nesser.vcf');
         
//         //get as formatted string
//         // console.log(vCard.getFormattedString());

//     // fetch("/aaa",{
//     //     method: "GET",
//     //      headers :  {
//     //      "Content-Type" : "application/json" , 
//     //  } , 
    
//     // })
//     // .then(res5=>res5.json())
//     // .then(res6=>{
//     //     swal("Success")
//     // })
        
          
// }

const setdispaly1 = (data) =>{
  if(User1.DisPersonal){
    fetch("https://tapitdabit-backend.vercel.app/others1234",{
    method: "POST",
     headers :  {
     "Content-Type" : "application/json" , 
 } ,  body : JSON.stringify({
          id : User1._id,
          data 
})
})
.then(res55=>res55.json())
.then(res65=>{
  console.log(res65)

  //  console.log(res6)
})
  }
  else{
    fetch("https://tapitdabit-backend.vercel.app/others12345/1",{
    method: "POST",
     headers :  {
     "Content-Type" : "application/json" , 
 } ,  body : JSON.stringify({
          id : User1._id,
          data 
})
})
.then(res33=>res33.json())
.then(res15=>{
  console.log(res15) 
})
  
   
}
}
        return (
     <>
             <div style={{height : "2px"}}>


</div>

             <div className='fdgrg' style={{position: "fixed",bottom: "90px",left: "45%"}}>
             <button className="button11" type="button" class="btn btn-dark" style={{width : "140px",height:"50px",borderRadius : "10px" , fontWeight :  "bold", padding : "14px 30px" ,fontSize : "11px" , margin : "0px 15px"}}  onClick={()=>props.history.push("/user/request-for-pro-item")}>Buy Now</button>

</div>
 <div className="mi2" style={{paddingTop :  "29px",margin : "50px auto" }}>
      <center>
          <h1>Upgrade to Pro</h1>
          <p>Scroll down to see some of the incredible feathers you get with tapitdabit Pro</p>
          <img src={ham1} alt="" style={{width : "300px" }}/>
          <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 mt-4 mb-4" style={{margin : "0px 0px" , display : "flex" , justifyContent :  "center"}}>
                  {/* <div class="px-1" style={{background : "rgb(245, 246, 246)", borderRadius : "10px"}}> */}
                   
                     {/* <button className="button11" type="button" class="btn btn-dark" style={{width : "140px",height:"50px",borderRadius : "10px" , fontWeight :  "bold", padding : "14px 30px" ,fontSize : "11px" , margin : "0px 15px"}}  onClick={()=>props.history.push("/user/request-for-pro-item")}>Yearly</button>
                     <button className="button11" type="button" class="btn btn-dark" style={{width : "140px",height:"50px",borderRadius : "10px" , fontWeight :  "bold", padding : "14px 20px",fontSize : "11px", margin : "0px 15px"}}  onClick={()=>props.history.push("/user/request-for-pro-item")}>Monthly</button> */}
                     {/* props.history.push("/pro-feather") */}

                  {/* </div> */}
                 
               
              </div>
      </center>
      <br />
      <br />
      <br />
      <center>
          <h1>Personal + Business Mode</h1>
          <p>Build both a personal and business profile and instantly switch between them whenever you'd like </p>
          <img src={ham2} alt="" style={{width : "300px" }}/>
          <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 mt-4 mb-4" style={{margin : "0px 0px" , display : "flex" , justifyContent :  "center"}}>
                  {/* <div class="px-1" style={{background : "rgb(245, 246, 246)", borderRadius : "10px"}}> */}
                   
                     {/* <button className="button11" type="button" class="btn btn-dark" style={{width : "140px",height:"50px",borderRadius : "10px" , fontWeight :  "bold", padding : "14px 30px" ,fontSize : "11px" , margin : "0px 15px"}}  onClick={()=>props.history.push("/user/request-for-pro-item")}>Yearly</button>
                     <button className="button11" type="button" class="btn btn-dark" style={{width : "140px",height:"50px",borderRadius : "10px" , fontWeight :  "bold", padding : "14px 20px",fontSize : "11px", margin : "0px 15px"}}  onClick={()=>props.history.push("/user/request-for-pro-item")}>Monthly</button> */}

                  {/* </div> */}
                 
               
              </div>
      </center>
      <br />
      <br />
      <br />
      <center>
          <h1>Capture Leads and Build your Network</h1>
          <p>Capture Leads using tapitdabit and favorite, group, sort and manage them all from here. Start building your connections now</p>
          <img src={ham3} alt="" style={{width : "300px" }}/>
          <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 mt-4 mb-4" style={{margin : "0px 0px" , display : "flex" , justifyContent :  "center"}}>
                  {/* <div class="px-1" style={{background : "rgb(245, 246, 246)", borderRadius : "10px"}}> */}
                   
                     {/* <button className="button11" type="button" class="btn btn-dark" style={{width : "140px",height:"50px",borderRadius : "10px" , fontWeight :  "bold", padding : "14px 30px" ,fontSize : "11px" , margin : "0px 15px"}}  onClick={()=>props.history.push("/user/request-for-pro-item")}>Yearly</button>
                     <button className="button11" type="button" class="btn btn-dark" style={{width : "140px",height:"50px",borderRadius : "10px" , fontWeight :  "bold", padding : "14px 20px",fontSize : "11px", margin : "0px 15px"}}  onClick={()=>props.history.push("/user/request-for-pro-item")}>Monthly</button> */}

                  {/* </div> */}
                 
               
              </div>
      </center>
      <br />
      <br />
      <br />
      <center>
          <h1>Unlimited Pro Links</h1>
          <p>Add Unlimited professional links that accelerate the growth of your business and networking</p>
          <img src={ham4} alt="" style={{width : "300px" }}/>
          <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 mt-4 mb-4" style={{margin : "0px 0px" , display : "flex" , justifyContent :  "center"}}>
                  {/* <div class="px-1" style={{background : "rgb(245, 246, 246)", borderRadius : "10px"}}> */}
                   
                     {/* <button className="button11" type="button" class="btn btn-dark" style={{width : "140px",height:"50px",borderRadius : "10px" , fontWeight :  "bold", padding : "14px 30px" ,fontSize : "11px" , margin : "0px 15px"}}  onClick={()=>props.history.push("/user/request-for-pro-item")}>Yearly</button>
                     <button className="button11" type="button" class="btn btn-dark" style={{width : "140px",height:"50px",borderRadius : "10px" , fontWeight :  "bold", padding : "14px 20px",fontSize : "11px", margin : "0px 15px"}}  onClick={()=>props.history.push("/user/request-for-pro-item")}>Monthly</button> */}

                  {/* </div> */}
                 
               
              </div>
      </center>
      <br />
      <br />
      <br />
      <center>
          <h1>Custom Icon + Title</h1>
          <p>Set custom icons and titles for each of your links! Setting better icons and titles can be great for engagement and tap through rate     </p>
          <img src={ham5} alt="" style={{width : "300px" }}/>
          <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 mt-4 mb-4" style={{margin : "0px 0px" , display : "flex" , justifyContent :  "center"}}>
                  {/* <div class="px-1" style={{background : "rgb(245, 246, 246)", borderRadius : "10px"}}> */}
                   
                     {/* <button className="button11" type="button" class="btn btn-dark" style={{width : "140px",height:"50px",borderRadius : "10px" , fontWeight :  "bold", padding : "14px 30px" ,fontSize : "11px" , margin : "0px 15px"}}  onClick={()=>props.history.push("/user/request-for-pro-item")}>Yearly</button>
                     <button className="button11" type="button" class="btn btn-dark" style={{width : "140px",height:"50px",borderRadius : "10px" , fontWeight :  "bold", padding : "14px 20px",fontSize : "11px", margin : "0px 15px"}}  onClick={()=>props.history.push("/user/request-for-pro-item")}>Monthly</button> */}

                  {/* </div> */}
                 
               
              </div>
      </center>
       
       </div>


     </>

     

        )
    
}

export default Home;