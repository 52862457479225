import React ,{useEffect , useState} from 'react'
// import {Link} from "react-router-dom"
import swal from 'sweetalert';

import ClearIcon from '@material-ui/icons/Clear';

import "react-multi-carousel/lib/styles.css";
import "react-multi-carousel/lib/styles.css";
import "./../assets/css/main.css"
import "./../assets/css/util.css"
import "./../assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css"


import "./../assets/vendor/css-hamburgers/hamburgers.min.css"

import "./../assets/fonts/iconic/css/material-design-iconic-font.min.css"
import "./../assets/vendor/bootstrap/css/bootstrap.min.css"
import "./../assets/vendor/select2/select2.min.css"
import "./../assets/vendor/slick/slick.css"
import "./../assets/vendor/perfect-scrollbar/perfect-scrollbar.css"

import ln1 from "./../assets/images/svg/social/f linkedin.svg"
import tw1 from "./../assets/images/svg/social/f twitter.svg"
import in1 from "./../assets/images/svg/social/f instagram.svg"
import pi1 from "./../assets/images/svg/social/f pinterest.svg"
import tt1 from "./../assets/images/svg/social/twitch (1).svg"
import tk1 from "./../assets/images/svg/social/tiktok.svg"
import you1 from "./../assets/images/svg/social/youtube (2).svg"
import sc1 from "./../assets/images/svg/social/snapchat.svg"
import fb1 from "./../assets/images/svg/social/f facebook.svg"


import bk1 from "./../assets/images/svg/Payments/bank (9).svg"
import ca1 from "./../assets/images/svg/Payments/cashapp.svg"
import ep1 from "./../assets/images/svg/Payments/easypaisa.png"
import jc1 from "./../assets/images/svg/Payments/SELECT JAZZ CASH LOGO/jazzcash.png"
import ve1 from "./../assets/images/svg/Payments/venmo.svg"
import pp1 from "./../assets/images/svg/Payments/paypal (1).svg"



import cu1 from "./../assets/images/svg/MORE/custom link.svg"
import lt1 from "./../assets/images/svg/MORE/linktree-2.svg"
import vi1 from "./../assets/images/svg/MORE/video.svg"
import we1 from "./../assets/images/svg/MORE/WEBSITE.svg"


import call1 from "./../assets/images/svg/cotact info/call.svg"
import cd1 from "./../assets/images/svg/cotact info/ccard.svg"
import lo1 from "./../assets/images/svg/cotact info/location.svg"
import mail1 from "./../assets/images/svg/cotact info/mail.svg"
import wa1 from "./../assets/images/svg/cotact info/whatapps.svg"
import mess1 from "./../assets/images/svg/cotact info/message (2).svg"







const Home = ( props ) => {
    const [myRef,setRef] = useState( React.createRef())
    const [scrollTop1,setscrollTop] = useState(0)
    const [email , setemail] = useState("")
    const [pass , setpass] = useState("")
    const [dispaly ,  setdispaly ] = useState(true)
    const [dispaly1 ,  setdispaly1 ] = useState(false)
    const [as ,  setas ] = useState(true)
    const [disDete ,  setdisDete ] = useState(true)

    const [User1 ,  setUser1 ] = useState({})
    const [Arr1 ,  setArr1 ] = useState([])
    const [Arr11 ,  setArr11 ] = useState([])
    const [Arr22 ,  setArr22 ] = useState([])
    // const [Num ,  setNum ] = useState(21)
    const [range1,setRange1] = useState([0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0])
    const [text,settext] = useState([0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0])
    
    useEffect(() =>{        window.scrollTo(0, 0)

if(! JSON.parse(localStorage.getItem("User"))){

        props.match.history.push("/login")
      }
      else{
        localStorage.removeItem("UserProp")

          localStorage.setItem("VenderWorkingThose" ,JSON.stringify(123)) 
        setUser1(JSON.parse(localStorage.getItem("User")))

  fetch("https://tapitdabit-backend.vercel.app/allUser/"+JSON.parse(localStorage.getItem("User"))._id,{
    method: "GET",
     headers :  {
     "Content-Type" : "application/json" , 
 } ,
})
.then(res5=>res5.json())
.then(res6=>{
  const cf = res6.All.sort((a, b) => a.sort > b.sort && 1 || -1)
  const cf1 = res6.Social.sort((a, b) => a.sort > b.sort && 1 || -1)
  const cf2 = res6.Contact.sort((a, b) => a.sort > b.sort && 1 || -1)
  setUser1({...res6 ,
            All : cf,
            Social : cf1,
            Contact : cf2
  })

    console.log({...res6 ,
      All : cf,
      Social : cf1,
      Contact : cf2
})
// console.log(res6.All.length)
    for (let i = 0; i < res6.All.length; i++) {
      console.log(i)
        // if(cf[i].sort){
            text[i] = i+1
            // text[i] = cf[i].sort
        // }
        
        // Ar[i]  = 0;
        // range1[i] = 0
    }
    console.log(text)
    setArr1(cf)
    setArr11(cf1)
    setArr22(cf2)
    
})
      }

      return () => {
        document.getElementById("get1").classList.remove("is-active");
        document.getElementById("get2").style.display = "none";
      }
    },[])
    useEffect( () => () => localStorage.removeItem("VenderWorkingThose"), [] );



const  setChange55 = (e,i)=>{
    console.log(e , i ,text)
    let a = text
    a[i]  =  parseInt(e)
    settext(a)
    console.log(e , i ,text)
    if(dispaly1){
        // setdispaly1(true)
        setdispaly1(false)

    }
  else{
  setdispaly1(true)
//   setdispaly1(false)
  }
}


// const setChanger8 = (e)=>{
//   props.history.push("/user/pro-feather")
// }


const  setDeleteEK = (data,i)=>{




    console.log(data,User1._id)
    if(disDete){
      setdisDete(false)

        let A8 = []
        User1.Counter.map((res343,i)=>{
        if(res343.name === "Contact"){
          if(data.fullName !== res343.fullName || data.Contact.length !== res343.Contact.length || data.Email !== res343.Email || data.Phone !== res343.Phone ){
            console.log("1")
            A8.push( {
              ...res343 
            })
          }
          }
        //   else{
        //     console.log("3")
        //     A8.push( {
        //       ...res343 
        //     })          }
        // }
        // else{
          if(data.name !== res343.name ||  data.link !== res343.link){
            console.log("2")
  
          A8.push( {
            ...res343 
          })
        }
        // }
      
      })
      console.log(A8)

      const newData1 = Arr1.filter((data1,j)=> i !== j)
    const Arr11 = []
    const Arr22 = []
    newData1.map((res,p)=>{
      if(res.name === "Contact"){
        Arr22.push(res)
      }
      else{
        Arr11.push(res)

      }
    }) 
    const A = []
    const A1 = []
    User1.DispalyShow.map((res23,g)=>{
      console.log(res23)
      if(res23.name === "Contact"){
        if(data.fullName === res23.fullName && data.Contact.lenght === res23.Contact.lenght && data.Email === res23.Email && data.Phone === res23.Phone ){
          console.log("1")
          A.push( {
            ...res23 
          })
        }
        else{
          console.log("3")
          A1.push(res23)
        }
      }
      else{
        if(data.name === res23.name &&  data.link === res23.link){
          console.log("2")

        A.push( {
          ...res23 
        })
      }
      else{
        console.log("3")
        A1.push(res23)
      }
      }
    })

    // let b= [...A,...User1.DispalyShow]

    //   let v = b.filter((a, i) => b.findIndex((s) =>{
    //   //  return a.name === s.name && a.link === s.link

    //    if(a.name === "Contact")
    //     return a.fullName === s.fullName && a.Contact.length === s.ContactBuss.length && a.Email === s.Email && a.Phone === s.Phone 
    //   else
    //     return a.name === s.name &&  a.link === s.link

    //   }
      
    //   ) === i)
      
    console.log(A1,"Contact",A)
    // const newData2 = Arr11.filter(data1=>  data1.link !== data.link )
    // const newData2 =[]
    // Arr11.map(data1=> {
    //   if(  data1.link !== data.link && data1.name !== data.name && data1.Name !== data.Name) {
    //     newData2.push(data1)
    //   }
    // })
    // let AA = []
    // Arr22.map(data1=> {
    //    if(  data1.fullName !== data.fullName && data1.Phone !== data.Phone && data1.Email !== data.Email) {
    //        AA.push(data1)
    //    }
    // })
    // console.log(newData1,"1111111",newData2,"2222222",AA)

    fetch("https://tapitdabit-backend.vercel.app/deleteSocial",{
        method: "POST" , 
        headers: {
            "Content-Type" : "application/json" ,
        } , 
        body : JSON.stringify({
            id : User1._id,
            All : newData1,
            Social : Arr11,
            Contact : Arr22,
            dis : A1,
            d : A8
        })
    })
    .then(res=>res.json())
    .then(res2=>{
    //     // const newData = Arr1.filter(data1=>  data1.name !== data.name && data1.link !== data.link && data1.photo !== data.photo : data1.name !== data.name && data1.fullName !== data.fullName && data1.Phone !== data.Phone && data1.Email !== data.Email)
        setArr1(newData1)
        setArr11(Arr11)
        setArr22(Arr22)
        swal("Successfully Updated")
        // props.history.push("/user/home1/"+User1._id)
        window.location.assign("/user/editProfile/sort-delete/"+User1.uid)

        setdisDete(true)

    //     // setAccountAdmin(newData)
    //     // props.history.push("/alluser")
    })
    }
    else{
      // swal("Plz Wait ")
    }
    
}


const  setUpdateEK = (data,i)=>{
    console.log(data,User1._id,i,text[i],text)
    // const newData1 = Arr1.filter((data1,j)=> i !== j)
    // const newData2 = Arr11.filter(data1=>  data1.link !== data.link )
    let A0 = []
    let A1 = []
    let A2 = []
    let vg = text[i] 
    console.log(vg,text,i,data)
    for (let e = i ; e < Arr1.length; e++) {
      console.log(vg,e)
      if(e === i){
        Arr1[e] = {
          ...Arr1[e] ,sort : text[i]
        }
      }
      else{
        Arr1[e] = {
          ...Arr1[e] ,sort : vg
        }
      }
      vg = vg + 1
    }
    console.log(Arr1)



    for (let e = text[i] -1; e < Arr1.length; e++) {
      console.log(vg,e)
      if(e === i){
        Arr1[e] = {
          ...Arr1[e] ,sort : text[i]
        }
      }
      else{
        Arr1[e] = {
          ...Arr1[e] ,sort : vg +1 
        }
      }
      vg = vg + 1
    }
    console.log(Arr1)





    let A091 = []
    Arr1.map((res66,u)=>{
      User1.DispalyShow.filter((res2,r)=>{
        if(res2.name === "Contact"){
            if(res2.fullName === res66.fullName && res2.Contact.length === res66.Contact.length && res2.Email === res66.Email && res2.Phone === res66.Phone ){
            //   return res2
            A091.push(res66) 
            }
            // else 
          }
          // else if(res2.photo){

          // }
          else{
            if(res2.photo){
               if(res2.name === res66.name &&  res2.link === res66.link  &&  res2.photo === res66.photo &&  res2.user === res66.user ){
                // return res2
                A091.push(res66) 
          }
        }
        else{
          if(res2.name === res66.name &&  res2.link === res66.link  &&  res2.user === res66.user ){
           // return res2
           A091.push(res66) 
     }
          
            }
           
          // else 
          
          }
    })
    })
    
    // console.log(A091,Arr1)


const A45 = []
const A56 = []
Arr1.map((res343,i)=>{
  if(res343.name === "Contact"){
     A45.push( {
              ...res343 
            }) 
          }

    else{
      A56.push( {
              ...res343 
            }) 
  }
  
  // }
  
  })
  fetch("https://tapitdabit-backend.vercel.app/updatetoallone",{
        method: "POST" , 
        headers: {
            "Content-Type" : "application/json" ,
        } , 
        body : JSON.stringify({
            id : User1._id,
            All : Arr1,
            Social : A56,
            Contact : A45,
  dis : A091
        })
    })
    .then(res=>res.json())
    .then(res2=>{
    //     // const newData = Arr1.filter(data1=>  data1.name !== data.name && data1.link !== data.link && data1.photo !== data.photo : data1.name !== data.name && data1.fullName !== data.fullName && data1.Phone !== data.Phone && data1.Email !== data.Email)
        setArr1(Arr1)
        setArr11(A56)
        setArr22(A45)
        window.location.assign("/user/editProfile/sort-delete/"+User1.uid)
        // window.location.assign("/user/bussiness/editProfile/sort-delete/"+User1.uid)

    //     // setAccountAdmin(newData)
    //     // props.history.push("/alluser")
    })
    Arr1.map((data1,j)=> {
       if(   i !== j) {
           A0.push(data1)
       }
       else{
           A0.push({...data1 , sort : text[i]})
       }
    })





    // Arr11.map(data1=> {
    //    if( data1.link !== data.link &&  data1.sort !== data.sort ) {
    //        A1.push(data1)
    //    }
    //    else{
    //     A1.push({...data1 , sort : text[i]})
    // }
    // })
    // Arr22.map(data1=> {
    //    if(  data1.fullName !== data.fullName && data1.Phone !== data.Phone && data1.Email !== data.Email && data1.Contact.length !== data.Contact.length) {
    //        A2.push(data1)
    //    }
    //    else{
    //     A2.push({...data1 , sort : text[i]})
    // }
    // })
    // console.log(A0,A1,A2)
    // fetch("https://tapitdabit-backend.vercel.app/updatetoallone",{
    //     method: "POST" , 
    //     headers: {
    //         "Content-Type" : "application/json" ,
    //     } , 
    //     body : JSON.stringify({
    //         id : User1._id,
    //         All : A0,
    //         Social : A1,
    //         Contact : A2,
    //     })
    // })
    // .then(res=>res.json())
    // .then(res2=>{
    // //     // const newData = Arr1.filter(data1=>  data1.name !== data.name && data1.link !== data.link && data1.photo !== data.photo : data1.name !== data.name && data1.fullName !== data.fullName && data1.Phone !== data.Phone && data1.Email !== data.Email)
    //     setArr1(A0)
    //     setArr11(A1)
    //     setArr22(A2)
    // //     // setAccountAdmin(newData)
    // //     // props.history.push("/alluser")
    // })
}

const  setEditEK = (data,i)=>{
    localStorage.setItem("EditData12" , JSON.stringify(data))
    localStorage.setItem("Edit12345" , JSON.stringify(i))
    props.history.push("/user/edit-link/"+User1._id)
}



// const SubmitDataMessage = (e) =>{
// //   e.preventDefault()
//   setdispaly ( false)
//   // console.log("wddde");
//   let Ak = []
//     for (let i = 0; i < Arr1.length; i++) {
//         if (range1[i] === 100)
//         Ak.push(Arr1[i])
//         // const element = array[i];
        
//     }
//   console.log(Ak)
//                               const data = new FormData()
//                               data.append("file", Image)
//                               data.append("upload_preset", "commerce")
//                               data.append("cloud_name", "freedeveloperss123")
//                               fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
//                                   method : "POST",
//                                   body : data , 
//                               })
//                               .then(res=>res.json())
//                               .then((res2)=>{

//                               fetch("https://tapitdabit-backend.vercel.app/createContactLink",{
//                                           method: "POST",
//                                           headers :  {
//                                               "Content-Type" : "application/json" , 
//                                           } ,
//                                           body : JSON.stringify({
//                                               fullName :Name ,
//                                               Email  ,
//                                               Phone  , 
//                                               Address ,
//                                               Gender  , 
//                                               City ,
//                                               Country  , 
//                                               Role  , 
//                                               Image : res2.url,
//                                             Contact : Ak,
//                                             id : User1._id
//                                               // MainLine2 :mainline ,
//                                           })
//                                       })
//                                       .then(res11=>res11.json())
//                                       .then((res12)=>{ 
//                                           if(res12.Error){

//                                           swal(res12.Error);
//                                       }
//                                       else{
//                                               swal("Successfully Upload your Contact Card!");
//                                           //     this.setState({
//                                           //         email: "",
//                                           // })
//                                       }
//                                   })
//                                   })
//                                   .catch(err=>{
//                                       swal("There is An Error")                  
//                                       }) 

// }



// const setRanger=(i)=>{
//   if(as) setas(false)
//   else setas(true)
//   console.log(i)
//   if(range1[i] === 0){
//     range1[i] = 100 
//   }
//   else{
//     range1[i] = 0 
//   }
//   console.log(range1)
// }
const setChanger2 = (e)=>{
  document.getElementById("myDivUmer").style.display= "none"
}
const setRoter123 = ()=>{
  localStorage.setItem("request123",JSON.stringify(1) )
      props.history.push("/user/request-for-pro-item")

}
        return (
            






      <div className="nm1"
        // onScroll={onScrolll()}
        >
       
        {/* Slider */}


        <div id="myDivUmer">
                {/* <img src={guides} alt=""  id="myDiv23456"/> */}
                <center>
                  <h1>
                     Build Your Self
                </h1>
                  </center>
                <center><p>
Capture leads using TapitDabit and favorite, group, sort and manage them all from here. Start building your connections now                  </p></center>
                  <center>
                  <button class="btn btn-dark F23 f45" onClick={()=>setRoter123()}>Buy Now </button>
                  </center>
                <ClearIcon id="myDiv34567"  style={{color : "black",fontSize : "30px",position : "absolute" , right: "10%",top : "10%" , zIndex: "2"}} onClick={()=>setChanger2()}/>
            </div> 










      <section>
                       
            {Arr1.map((res1,i)=>{
                    return(
                      <>
                          <center>
                                                {res1.photo === "" && res1.name === "facebook" ? 
                                                    <img src={fb1} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "Custom Link" ? 
                                                    <img src={cu1} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "location" ? 
                                                    <img src={lo1} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "instagram" ? 
                                                    <img src={in1} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "snapchat" ? 
                                                    <img src={sc1} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "tiktok" ? 
                                                    <img src={tt1} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "linkedin" ? 
                                                    <img src={ln1} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "twitter" ? 
                                                    <img src={tw1} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "youtube" ? 
                                                    <img src={you1} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "twitch" ? 
                                                    <img src={tw1} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "pinterest" ? 
                                                    <img src={pi1} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "cash app" ? 
                                                    <img src={ca1} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "paypal" ? 
                                                    <img src={pp1} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "Venmo" ? 
                                                    <img src={ve1} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "text" ? 
                                                    <img src={mess1} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "website" ? 
                                                    <img src={we1} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "whatsapps" ? 
                                                    <img src={wa1} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "email" ? 
                                                    <img src={mail1} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "phone" ? 
                                                    <img src={call1} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "JazzCash" ? 
                                                    <img src={jc1} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "Linktree" ? 
                                                    <img src={lt1} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "EasyPaisa" ? 
                                                    <img src={ep1} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "Bank" ? 
                                                    <img src={bk1} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "Address" ? 
                                                    <img src={lo1} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "Embed video" ? 
                                                    <img src={vi1} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {! res1.name1 &&  res1.name === "Contact" ? 
                                                    <img src={cd1} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo && res1.photo.length !== 0  &&  res1.name !== "Contact" ?   
                                                    <img src={res1.photo} alt="" style={{margin: "20px -2px 40px -25px" , borderRadius :  "70px",height : "105px" , width :  "102px"}}/>
                                                : ""
                                                }
                                                { res1.name1 &&  res1.name === "Contact" ?   
                                                    <img src={res1.photo1 ? res1.photo1 : cd1} alt=""  style={{margin: "20px -2px 40px -25px" , borderRadius :  "70px",height : "105px" , width :  "102px"}}/>
                                                : ""
                                                }
                                           </center>
                                            <div className="container" style={{margin : "0px auto" , width : "97%" , height: "59px"}}>
                                                    <div className="row justify-content-center">
                                
                                            {/* <div className="arrival11 col-xl-10 col-lg-10 col-md-4 col-sm-5">
                                                <div style={{minWidth: "95%",display: "flex",justifyContent: "center"}}  > */}
                                                {/* <img src={z1} alt="" className="arrival-Photo111"/> */}
                                                
                                                <div className="arrival11 col-xl-10 col-lg-10 col-md-4 col-sm-5">
                                                <div style={{minWidth: "95%",display: "flex",justifyContent: "center"}} className="km1" >
                                                <div>
                                                <h4>
                                                {/* {res1.name1 ?res1.name1 :""} */}
                                                {!res1.Name  && res1.Name !== res1.name &&  !(res1.name1)?res1.name :""}
                                                {res1.Name !== res1.name &&  !(res1.name1)?res1.Name :res1.name1}
                                                </h4>
                                                <input type="number"  value={text[i]} onChange={(e)=>setChange55(e.target.value,i)} style={{padding : "3px 8px ",border  : "2px solid black" ,borderRadius :  "3px "}}  />
                                                {/* <p style={{maxWidth : "250px",    overflow: "hidden"}}> 
                                                {res1.link} ...
                                                </p> */}
                                                </div>
                                                <div style={{display :"flex" , flexDirection : "column" , padding : "0px 3px",margin: "-21px 0px",}}>
                                                <button style={{background :  "rgb(35, 39, 43)",margin: "2px",padding : "0px 4px 25px 3px" ,borderRadius :  "3px ",height:"20px"}} type="button" class="btn btn-dark" onClick={()=>setEditEK(res1,i)}>Edit</button>
                                                <button style={{background :  "rgb(35, 39, 43)",margin: "2px",padding : "0px 4px 25px 3px" ,borderRadius :  "3px ",height:"20px"}} type="button" class="btn btn-dark" onClick={()=>setUpdateEK(res1,i)}>Update</button>
                                                <button style={{background :  "rgb(35, 39, 43)",margin: "2px",padding : "0px 4px 25px 3px" ,borderRadius :  "3px ",height:"20px"}} type="button" class="btn btn-dark" onClick={()=>setDeleteEK(res1,i)}>Delete</button>

                                                </div>
                                                {/* <input className="cx" type="range" min="0" max="100" value={range1[i]} onClick={()=>setRanger(i)} /> */}
                                                </div>
                                            </div>
                                            
                                           
                            </div>
                               
                            </div>
                            <hr />
                            </>
                    )
            })

            }
               
                </section> 








           {/* <center>
          
            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-10" style={{margin : "20px 0px" }}>
                   
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        <button className="button11" onClick={()=>SubmitDataMessage()} style={{width : "200px",   background : "rgb(0, 0, 0)" ,  color  : "white", borderRadius :  "3px" , border : "3px solid rgb(211, 211, 211)",fontSize : "18px"}}>Save</button>
                    </div>
                    <br/>
            </div>
           </center> */}
          
{/* 
           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "20px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage(e)}>
                   
                    <input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here" /><br/>
                    <div style={{display : "flex" , justifyContent : "space-between"}}>
                        <button className="button11" style={{width : "200px" , background : "rgb(211, 211, 211)" , color  : "black" , borderRadius :  "3px" , border : "3px solid black",fontSize : "18px"}}>Open</button>
                        <button className="button11" type="submit" style={{width : "200px",   background : "rgb(0, 0, 0)" ,  color  : "white", borderRadius :  "3px" , border : "3px solid rgb(211, 211, 211)",fontSize : "18px"}}>Save</button>
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           */}


<br /><br />


          
        


        {/* Back to top */}
        <div className="btn-back-to-top" id="myBtn">
          <span className="symbol-btn-back-to-top">
            <i className="zmdi zmdi-chevron-up" />
          </span>
        </div>
        {/* Modal1 */}
        <div className="wrap-modal1 js-modal1 p-t-60 p-b-20">
          <div className="overlay-modal1 js-hide-modal1" />
          <div className="container">
            <div className="bg0 p-t-60 p-b-30 p-lr-15-lg how-pos3-parent">
              <button className="how-pos3 hov3 trans-04 js-hide-modal1">
                <img src="images/icons/icon-close.png" alt="CLOSE" />
              </button>
              <div className="row">
                <div className="col-md-6 col-lg-7 p-b-30">
                  <div className="p-l-25 p-r-30 p-lr-0-lg">
                    <div className="wrap-slick3 flex-sb flex-w">
                      <div className="wrap-slick3-dots" />
                      <div className="wrap-slick3-arrows flex-sb-m flex-w" />
                      <div className="slick3 gallery-lb">
                        <div className="item-slick3" data-thumb="images/product-detail-01.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-01.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-01.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-02.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-02.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-02.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-03.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-03.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-03.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5 p-b-30">
                  <div className="p-r-50 p-t-5 p-lr-0-lg">
                    <h4 className="mtext-105 cl2 js-name-detail p-b-14">
                      Lightweight Jacket
                    </h4>
                    <span className="mtext-106 cl2">
                      $58.79
                    </span>
                    <p className="stext-102 cl3 p-t-23">
                      Nulla eget sem vitae eros pharetra viverra. Nam vitae luctus ligula. Mauris consequat ornare feugiat.
                    </p>
                    {/*  */}
                    <div className="p-t-33">
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Size
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Size S</option>
                              <option>Size M</option>
                              <option>Size L</option>
                              <option>Size XL</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Color
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Red</option>
                              <option>Blue</option>
                              <option>White</option>
                              <option>Grey</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-204 flex-w flex-m respon6-next">
                          <div className="wrap-num-product flex-w m-r-20 m-tb-10">
                            <div className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-minus" />
                            </div>
                            <input className="mtext-104 cl3 txt-center num-product" type="number" name="num-product" defaultValue={1} />
                            <div className="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-plus" />
                            </div>
                          </div>
                          <button className="flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04 js-addcart-detail">
                            Add to cart
                          </button>
                        </div>
                      </div>	
                    </div>
                    {/*  */}
                    <div className="flex-w flex-m p-l-100 p-t-40 respon7">
                      <div className="flex-m bor9 p-r-10 m-r-11">
                        <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 js-addwish-detail tooltip100" data-tooltip="Add to Wishlist">
                          <i className="zmdi zmdi-favorite" />
                        </a>
                      </div>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Facebook">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Twitter">
                        <i className="fa fa-twitter" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Google Plus">
                        <i className="fa fa-google-plus" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
 






        )
    
}

export default Home;