import React ,{useEffect , useState} from 'react'

import { Link } from 'react-router-dom';

const Thankyou = ()=> {
    const [User1 ,  setUser1 ] = useState({})

    useEffect(() =>{

        window.scrollTo(0, 0)

        fetch("https://tapitdabit-backend.vercel.app/TermA-all",{
            method: "GET",
            headers :  {
                "Content-Type" : "application/json" , 
            } 
          })
          .then((res3)=>res3.json())
          .then((res44)=>{
            setUser1(res44[0])
          })

        },[])

        return (
            <div>
                <div className="bg-light py-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-0"><Link to="/">Home</Link> <span className="mx-2 mb-0">/</span> <strong className="text-black">Term & Conditions</strong></div>
                        </div>
                    </div>
                </div>

                <div className="site-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <p className="lead mb-5">{User1 && User1.term}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )

}

export default Thankyou;