import React, { useEffect , useState } from 'react';
import swal from 'sweetalert';
// import cloth_1 from '../assets/images/cloth_1.jpg';
// import shoe_1 from '../assets/images/shoe_1.jpg';
// import cloth_2 from '../assets/images/cloth_2.jpg';
// import cloth_3 from '../assets/images/cloth_3.jpg';
// import Ban1 from "../assets/images/banner1.jpg"
// import Ban2 from "../assets/images/banner2.jpg"
// import Ban3 from "../assets/images/banner3.jpg"
import "react-responsive-carousel/lib/styles/carousel.min.css";

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import whatapps from '../assets/images/what.png';

import "react-multi-carousel/lib/styles.css";
import "react-multi-carousel/lib/styles.css";
import "./../assets/css/main.css"
import "./../assets/css/util.css"

import "./../assets/vendor/css-hamburgers/hamburgers.min.css"

import "./../assets/fonts/iconic/css/material-design-iconic-font.min.css"
import "./../assets/vendor/bootstrap/css/bootstrap.min.css"
import "./../assets/vendor/select2/select2.min.css"
import "./../assets/vendor/slick/slick.css"
import "./../assets/vendor/perfect-scrollbar/perfect-scrollbar.css"


import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
// import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
// import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { Link } from 'react-router-dom';
// import { useSelector, useDispatch } from 'react-redux';
// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import { Button } from '@material-ui/core';
import GradeIcon from '@material-ui/icons/Grade';
const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};


export function SubCategoriesProductDetails(props) {
    const [productDetail , setproductDetail] = useState([])
    const [Price , setPrice] = useState(1)
    const [DataPart2 , setDataPart2] = useState([])
    // const [allproduct , setallproduct] = useState([])
    const [allproductReview , setallproductReview] = useState([])
    // const [Average , setAverage] = useState(0)


    const [Area1 , setArea1] = useState(true)
    const [Area2 , setArea2] = useState(false)
    const [Area3 , setArea3] = useState(false)

    // const [dd1 , setdd1] = useState(true)
    // const [dd2 , setdd2] = useState(false)
    

    const [dis , setdis] = useState(false)



    const [NowAvailable , setNowAvailable] = useState([])
    const [bestSeller1 , setbestSeller1] = useState([])
    const [product , setproduct] = useState([])
    const [MainCatogories , setMainCatogories] = useState([])
    // const [DataPart21 , setDataPart21] = useState([])
    const [bestkeybaad , setbestkeybaad] = useState([])
    const [youtube123 , setyoutube123] = useState({})
    const [companieswith , setcompanieswith] = useState([])
    // const [fg1 , setfg1] = useState(true)
    // const [fvalue1 , setfvalue1] = useState([])
    // const [data1, setdata1] = useState([]) 



    const [Changer6711 , setChanger671] = useState(true)
    const [Changer6721 , setChanger672] = useState(false)
    const [Changer6731 , setChanger673] = useState(false)











    const [sliderStatus , setsliderStatus] = useState(0)




    const [Star1 , setStar1] = useState(0)
    const [Star2 , setStar2] = useState(0)
    const [Star3 , setStar3] = useState(0)
    const [Detail, setDetail] = useState("") 

    
    
    // const [Changer , setChanger] = useState(0)
    
    
    const [name, setname] = useState("") 
    // const [lname, setlname] = useState("") 
    // const [email, setemail] = useState("") 
    const [subject, setsubject] = useState("") 
    const [Message, setMessage] = useState("") 
    
    const [CategoriesName , setCategoriesName] = useState({})
    const [data, setdata] = useState([]) 
    
    const [fg , setfg] = useState(true)
    const [fvalue , setfvalue] = useState([])

    
    
    const [n1, setn1] = useState("") 
    const [c1, setc1] = useState("") 
    const [p1, setp1] = useState("") 



    useEffect(() =>{
        window.scrollTo(0, 0)
        carrouselInit();
        let Categoriesname = props.match.params ;
        setCategoriesName(Categoriesname)

        fetch("https://tapitdabit-backend.vercel.app/AllProduct1/"+Categoriesname.productid,{
        method: "GET",
         headers :  {
         "Content-Type" : "application/json" , 
     }
    })
    .then(res=>res.json())
    .then(res1=>{
        console.log(res1)
        setproductDetail(res1)
        // console.log(res1.Product_Long_Notes.replace("\n","<br>"));
        setDetail(res1[0].Product_Long_Notes)
        // console.log(res1[0].Product_Long_Notes,Detail,"jk");
    })
    fetch("https://tapitdabit-backend.vercel.app/AllProduct",{
      method: "GET",
       headers :  {
       "Content-Type" : "application/json" , 
   }
  })
  .then(res5=>res5.json())
  .then(res6=>{
      console.log(res6);
   const pro = []
   res6.map((res7,i)=>{
       //    console.log(res7.Product_Popular );
       if ( res7.Arrival){
           //    console.log(res7);
           //         // return setproduct1(...product1 , res3)
           pro.push(res7)
       }
   })
   // setproduct(pro.reverse())
   setproduct(pro.slice(0,4))
   setdis(true)
  })
    fetch("https://tapitdabit-backend.vercel.app/FAQ1",{
      method: "GET",
       headers :  {
       "Content-Type" : "application/json" , 
   } ,
  })
  .then(res3=>res3.json())
  .then(res4=>{
    let dat1 = []
    res4.map((res55,c)=>{
        dat1.push(0)
    })
    setfvalue(dat1)
         setdata(res4)
         data.map((res,i)=>{
          data1[i] = res.text
         })

      console.log(res4)
    })
  fetch("https://tapitdabit-backend.vercel.app/NowAvailable1",{
      method: "GET",
       headers :  {
       "Content-Type" : "application/json" , 
   } ,
  })
  .then(res9=>res9.json())
  .then(res11=>{
      console.log(res11);
      setNowAvailable(res11)
  
  })
  fetch("https://tapitdabit-backend.vercel.app/youtube123",{
      method: "GET",
       headers :  {
       "Content-Type" : "application/json" , 
   } ,
  })
  .then(res99=>res99.json())
  .then(res191=>{
      console.log(res191);
      setyoutube123(res191[0])
  
  })
  fetch("https://tapitdabit-backend.vercel.app/bestSeller1",{
      method: "GET",
       headers :  {
       "Content-Type" : "application/json" , 
   } ,
  })
  .then(res9=>res9.json())
  .then(res11=>{
      console.log(res11[0]);
      setbestSeller1(res11)
  
  })
  fetch("https://tapitdabit-backend.vercel.app/bestkeybaad",{
      method: "GET",
       headers :  {
       "Content-Type" : "application/json" , 
   } ,
  })
  .then(res9=>res9.json())
  .then(res11=>{
      console.log(res11[0]);
      setbestkeybaad(res11)
  
  })
  fetch("https://tapitdabit-backend.vercel.app/companieswith",{
      method: "GET",
       headers :  {
       "Content-Type" : "application/json" , 
   } ,
  })
  .then(res9=>res9.json())
  .then(res11=>{
      console.log(res11[0]);
      setcompanieswith(res11)
  
  })

  // fetch("https://tapitdabit-backend.vercel.app/BannerPhoto",{
  //     method: "GET",
  //      headers :  {
  //      "Content-Type" : "application/json" , 
  //  } ,
  // })
  // .then(res22=>res22.json())
  // .then(res222=>{
  //     console.log(res222[0]);
  //     setBannerPhoto(res222)
  
  // })

  // fetch("https://tapitdabit-backend.vercel.app/arrival-photo",{
  //     method: "GET",
  //      headers :  {
  //      "Content-Type" : "application/json" , 
  //  } ,
  // })
  // .then(res2=>res2.json())
  // .then(res3=>{
  //     console.log(res3);
  //     setArrivalPhoto(res3)
  
  // })
  fetch("https://tapitdabit-backend.vercel.app/MainCatogories",{
      method: "GET",
       headers :  {
       "Content-Type" : "application/json" , 
   } ,
  })
  .then(res2=>res2.json())
  .then(res3=>{
      console.log(res3);
      // setMainCatogories(res3)
      

      let dat = []
      res3.map((res55,c)=>{
          dat.push(res55)
      })
      // console.log(SubClassCategories,asse)
      console.log(dat)
      let data = dat.sort((a, b) => {
          return a.sort - b.sort;
      });
      setMainCatogories(data)
      
  })
  











    fetch("https://tapitdabit-backend.vercel.app/FAQ1",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res3=>res3.json())
        .then(res4=>{
          let dat1 = []
          res4.map((res55,c)=>{
              dat1.push(0)
          })
          setfvalue(dat1)
               setdata(res4)
               data.map((res,i)=>{
                data1[i] = res.text
               })

            console.log(res4)
          })
    fetch("https://tapitdabit-backend.vercel.app/allReview",{
        method: "GET",
         headers :  {
         "Content-Type" : "application/json" , 
     }
    })
    .then(res3=>res3.json())
    .then(res4=>{
        console.log(res4) 
            var q = 0
            var v = 0
            var p = 0
            var qan = 0
        res4.map((res5)=>{
            // console.log(res5)
           
            if(res5.productId === Categoriesname.productid &&res5.ReviewApproval){
                // console.log(res5.quality,+res5.value,res5.price);
               
                v = res5.value + v
                q =res5.quality + q
                p = res5.price + p
                qan = 15 + qan
            } 
        }) 
                // setq(q)
                // setv(v)
                // setp(p)
                // setqan(qan)
        // const data1 = res4.filter((res5)=>{
        //     return res5.productId === Categoriesname.productid
        // })
        // var q = 0
        // var v = 0
        // var p = 0
        // var qan = 0
        let data2 = res4.filter((res5)=>{
            // q =+ res5.quality
            // v =+ res5.value
            // p =+ res5.price
            // qan =+ 5
            return res5.productId === Categoriesname.productid && res5.ReviewApproval
        })
        
        let total = q+p+v
        console.log(total,q,v,p,qan,"total");
        if((total*100)/qan >= 80){
            document.getElementById("star1").style.color = "rgb(251, 122, 51)"
            document.getElementById("star2").style.color = "rgb(251, 122, 51)"
            document.getElementById("star3").style.color = "rgb(251, 122, 51)"
            document.getElementById("star4").style.color = "rgb(251, 122, 51)"
            document.getElementById("star5").style.color = "rgb(251, 122, 51)"
        }
        else if((total*100)/qan >= 60){
            document.getElementById("star1").style.color = "rgb(251, 122, 51)"
            document.getElementById("star2").style.color = "rgb(251, 122, 51)"
            document.getElementById("star3").style.color = "rgb(251, 122, 51)"
            document.getElementById("star4").style.color = "rgb(251, 122, 51)"
            
        }
        else if((total*100)/qan >= 40){
            document.getElementById("star1").style.color = "rgb(251, 122, 51)"
            document.getElementById("star2").style.color = "rgb(251, 122, 51)"
            document.getElementById("star3").style.color = "rgb(251, 122, 51)"

            
        }
        else if((total*100)/qan >= 20){
            document.getElementById("star1").style.color = "rgb(251, 122, 51)"
            document.getElementById("star2").style.color = "rgb(251, 122, 51)"

            
        }
        else{
            document.getElementById("star1").style.color = "rgb(251, 122, 51)"

        }
        
        
        
        // setAverage((total*100)/qan)
        setallproductReview(data2)
    })
      var data1 =  JSON.parse(localStorage.getItem("Cart")) 
       if (data1) setDataPart2(data1)

    //   const Inte = setInterval(()=>{
    //         fetch("/AllProduct",{
    //     method: "GET",
    //      headers :  {
    //      "Content-Type" : "application/json" , 
    //  }
    // })
    // .then(res5=>res5.json())
    // .then(res6=>{
    //     // console.log(res6);
    //     const vc = []
    //  res6.map((res7,i)=>{
    //         console.log( res7.Product_Catagories , productDetail.Product_Catagories , res7._id , productDetail._id);
    //      if ( res7.Product_Catagories === data.Product_Catagories && res7._id !== data._id) {
    //          //    console.log(res7);
    //          //         // return setproduct1(...product1 , res3)
    //          vc.push(res7)
    //         //  return  res7
    //         }
    //     })
    //     console.log(vc);
        
    //     if(vc.length >= 1 && vc.length < 5){
    //        console.log("yes");
    //     res6.map((res7,i)=>{
    //         // console.log( res7.Product_Catagories , data.Product_Catagories , res7._id , data._id);
    //      if ( res7._id !== data._id) {

    //          vc.push(res7)
    //         }
    //     })
    //     let db = vc.slice(0,7)
    //     setallproduct(db) 
    //     }

    //     else if (vc.length >= 1 ){
    //        console.log("yes");
    //        let db = vc.slice(0,7)
    //        console.log(db);
    //     setallproduct(db)
    //     }

    //     else{
    //         const vb = []
    //         res6.map((res7,i)=>{
    //             if ( i+1 % 4 === 0) {
                    
    //                 vb.push(res7)

    //                }
    //            })
    //            let db = vb.slice(0,7)
    //            console.log(db);
    //             setallproduct(db)
    //     }
 
    //     console.log(allproduct);
    // })
    //    },1000)
    //    return () => clearInterval(Inte);

    },[])

const minus=()=>{
        if(Price > 1)
        setPrice(Price - 1)
        console.log(Price);
    }
    const plus=()=>{
        if(Price < 100)
        setPrice(Price + 1)
        console.log(Price);

    }

    
const addtocartproduct12 = () =>{
  swal("Update!", "Your Item Add in Cart!", "success");

  if (productDetail[0].Product_Price_Discounted === null){
    const data =  {...productDetail[0] ,
        Percentage : 0,
        DeliveryStatus : "Pending",
        Pieces : Price ,
        Total_Product_Price  : productDetail[0].Product_Price * Price }
    var data1 = JSON.parse(localStorage.getItem("Cart")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id ){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
    return {...item,
        Pieces : Price + item.Pieces,
        Total_Product_Price  : (productDetail[0].Product_Price * Price )+ item.Total_Product_Price}
    }
    else{
    console.log("double not match");
    return item
    }

    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Cart")) 

    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Cart" , JSON.stringify(data3) )

    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]

    localStorage.setItem("Cart" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Cart" , JSON.stringify([data]) )

    }

    }
    else if (productDetail[0].Product_Price_Discounted){
    const data =  {...productDetail[0] ,
        Percentage : 0,
        DeliveryStatus : "Pending",
        Pieces : Price ,
        Total_Product_Price  : productDetail[0].Product_Price_Discounted * Price }
    var data1 = JSON.parse(localStorage.getItem("Cart")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id ){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
    return {...item,
        Pieces : Price + item.Pieces,
        Total_Product_Price  : (productDetail[0].Product_Price_Discounted * Price )+ item.Total_Product_Price}
    }
    else{
    console.log("double not match");
    return item
    }

    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Cart")) 

    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Cart" , JSON.stringify(data3) )

    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]

    localStorage.setItem("Cart" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Cart" , JSON.stringify([data]) )

    }

    }


  props.history.push("/custom-product/photo-upload")
}

const addtocartproduct = () =>{
    swal("Update!", "Your Item Add in Cart!", "success");

    // if(productDetail){
        console.log(typeof(productDetail[0].ProductCreater), typeof("60a3c644e4520a12c80a6f52"));
        

        const data = new FormData()
        data.append("file", p1)
        data.append("upload_preset", "commerce")
        data.append("cloud_name", "freedeveloperss123")
        fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
            method : "POST",
            body : data , 
        })
        .then(res1=>res1.json())
        .then((res8)=>{
      console.log(res8,n1,c1)
                    if (productDetail[0].Product_Price_Discounted === null){
                    const data =  {...productDetail[0] ,
                        Percentage : 0,
                        DeliveryStatus : "Pending",
                        Pieces : Price ,
                        des : {n1,c1,photo:res8.url ? res8.url :""},
                        Total_Product_Price  : productDetail[0].Product_Price * Price }
                    var data1 = JSON.parse(localStorage.getItem("Cart")) 
                    if (data1){
                    var data3 = data1.map((item) => {
                        if(item._id === data._id ){
                            console.log("double");
                    ;                   localStorage.setItem("double",JSON.stringify(true))
                    return {...item,
                        Pieces : Price + item.Pieces,
                        des1 : {n1,c1,photo:res8.url ? res8.url :""},

                        Total_Product_Price  : (productDetail[0].Product_Price * Price )+ item.Total_Product_Price}
                    }
                    else{
                    console.log("double not match");
                    return item
                    }

                    })
                    var data5 =  JSON.parse(localStorage.getItem("double")) 
                    console.log(DataPart2.length, data3.length,data5);
                    var data10 =  JSON.parse(localStorage.getItem("Cart")) 

                    if(data10.length=== data3.length && data5){
                    console.log("double remove");
                    localStorage.removeItem("double")
                    localStorage.setItem("Cart" , JSON.stringify(data3) )

                    }
                    else{
                    console.log("Differet");
                    var data2 = [...data1 , data]

                    localStorage.setItem("Cart" , JSON.stringify(data2) )
                    }
                    }
                    else{
                    console.log("1");
                    localStorage.setItem("Cart" , JSON.stringify([data]) )

                    }

                    }
                    else if (productDetail[0].Product_Price_Discounted){
                    const data =  {...productDetail[0] ,
                        Percentage : 0,
                        DeliveryStatus : "Pending",
                        Pieces : Price ,
                        // des : {n1,c1,photo:res8.url ? res8.url :""},
                        des1 : {n1,c1,photo:res8.url ? res8.url :""},
                        Total_Product_Price  : productDetail[0].Product_Price_Discounted * Price }
                    var data1 = JSON.parse(localStorage.getItem("Cart")) 
                    if (data1){
                    var data3 = data1.map((item) => {
                        if(item._id === data._id ){
                            console.log("double");
                    ;                   localStorage.setItem("double",JSON.stringify(true))
                    return {...item,
                        Pieces : Price + item.Pieces,
                        des1 : {n1,c1,photo:res8.url ? res8.url :""},
                        Total_Product_Price  : (productDetail[0].Product_Price_Discounted * Price )+ item.Total_Product_Price}
                    }
                    else{
                    console.log("double not match");
                    return item
                    }

                    })
                    var data5 =  JSON.parse(localStorage.getItem("double")) 
                    console.log(DataPart2.length, data3.length,data5);
                    var data10 =  JSON.parse(localStorage.getItem("Cart")) 

                    if(data10.length=== data3.length && data5){
                    console.log("double remove");
                    localStorage.removeItem("double")
                    localStorage.setItem("Cart" , JSON.stringify(data3) )

                    }
                    else{
                    console.log("Differet");
                    var data2 = [...data1 , data]

                    localStorage.setItem("Cart" , JSON.stringify(data2) )
                    }
                    }
                    else{
                    console.log("1");
                    localStorage.setItem("Cart" , JSON.stringify([data]) )

                    }

                    }
                })

                        

}

const addtocartproduct1 = () =>{
    swal("Update!", "Your Item Add in Cart!", "success");

    // if(productDetail){
        console.log(typeof(productDetail[0].ProductCreater), typeof("60a3c644e4520a12c80a6f52"));
        


        const data = new FormData()
        data.append("file", p1)
        data.append("upload_preset", "commerce")
        data.append("cloud_name", "freedeveloperss123")
        fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
            method : "POST",
            body : data , 
        })
        .then(res1=>res1.json())
        .then((res8)=>{
      
                    if (productDetail[0].Product_Price_Discounted === null){
                    const data =  {...productDetail[0] ,
                        Percentage : 0,
                        DeliveryStatus : "Pending",
                        Pieces : Price ,
                        des : {n1,c1,photo:res8.url ? res8.url :""},
                        Total_Product_Price  : productDetail[0].Product_Price * Price }
                    var data1 = JSON.parse(localStorage.getItem("Cart")) 
                    if (data1){
                    var data3 = data1.map((item) => {
                        if(item._id === data._id ){
                            console.log("double");
                    ;                   localStorage.setItem("double",JSON.stringify(true))
                    return {...item,
                        Pieces : Price + item.Pieces,
                        Total_Product_Price  : (productDetail[0].Product_Price * Price )+ item.Total_Product_Price}
                    }
                    else{
                    console.log("double not match");
                    return item
                    }

                    })
                    var data5 =  JSON.parse(localStorage.getItem("double")) 
                    console.log(DataPart2.length, data3.length,data5);
                    var data10 =  JSON.parse(localStorage.getItem("Cart")) 

                    if(data10.length=== data3.length && data5){
                    console.log("double remove");
                    localStorage.removeItem("double")
                    localStorage.setItem("Cart" , JSON.stringify(data3) )

                    }
                    else{
                    console.log("Differet");
                    var data2 = [...data1 , data]

                    localStorage.setItem("Cart" , JSON.stringify(data2) )
                    }
                    }
                    else{
                    console.log("1");
                    localStorage.setItem("Cart" , JSON.stringify([data]) )

                    }

                    }
                    else if (productDetail[0].Product_Price_Discounted){
                    const data =  {...productDetail[0] ,
                        Percentage : 0,
                        DeliveryStatus : "Pending",
                        Pieces : Price ,
                        des : {n1,c1,photo:res8.url ? res8.url :""},
                        Total_Product_Price  : productDetail[0].Product_Price_Discounted * Price }
                    var data1 = JSON.parse(localStorage.getItem("Cart")) 
                    if (data1){
                    var data3 = data1.map((item) => {
                        if(item._id === data._id ){
                            console.log("double");
                    ;                   localStorage.setItem("double",JSON.stringify(true))
                    return {...item,
                        Pieces : Price + item.Pieces,
                        Total_Product_Price  : (productDetail[0].Product_Price_Discounted * Price )+ item.Total_Product_Price}
                    }
                    else{
                    console.log("double not match");
                    return item
                    }

                    })
                    var data5 =  JSON.parse(localStorage.getItem("double")) 
                    console.log(DataPart2.length, data3.length,data5);
                    var data10 =  JSON.parse(localStorage.getItem("Cart")) 

                    if(data10.length=== data3.length && data5){
                    console.log("double remove");
                    localStorage.removeItem("double")
                    localStorage.setItem("Cart" , JSON.stringify(data3) )

                    }
                    else{
                    console.log("Differet");
                    var data2 = [...data1 , data]

                    localStorage.setItem("Cart" , JSON.stringify(data2) )
                    }
                    }
                    else{
                    console.log("1");
                    localStorage.setItem("Cart" , JSON.stringify([data]) )

                    }

                    }


                        props.history.push("/checkout")

                })



}
// const savethedetailproduct = (data) =>{
//     localStorage.setItem("Data" , JSON.stringify(data) )
//     console.log(data);
//  }


// const change_detail_area = (e)=>{
//     if (e === 1){
//         setArea1(true)
//         setArea2(false)
//         setArea3(false)
//     }
//     else if (e === 2){
//         setArea1(false)
//         setArea2(true)
//         setArea3(false)
//     }
//     else if (e === 3){
//         setArea1(false)
//         setArea2(false)
//         setArea3(true)
//     }
// }

const RatingProduct1 = (e)=>{
    setStar1(e)
    let a = e-1
    for (let i = 0; i < 5; i++) {
        document.getElementById("Rate").firstChild.children[i].style.color  = "rgba(192, 186, 186, 0.432)"
    }
    for (let i = 0; i < a+1; i++) {
        document.getElementById("Rate").firstChild.children[i].style.color  = "yellow"
    }
}
const RatingProduct2 = (e)=>{
    setStar2(e)
    let a = e-1
    for (let i = 0; i < 5; i++) {
        document.getElementById("Rate").children[1].children[i].style.color  = "rgba(192, 186, 186, 0.432)"
    }
    // document.getElementById("Rate").children[1].children[e-1].style.color  = "yellow"
    for (let i = 0; i < a+1; i++) {
        document.getElementById("Rate").children[1].children[i].style.color  = "yellow"
    }
}
const RatingProduct3 = (e)=>{
    setStar3(e)
    let a = e-1
    for (let i = 0; i < 5; i++) {
        document.getElementById("Rate").children[2].children[i].style.color  = "rgba(192, 186, 186, 0.432)"
    }
    for (let i = 0; i < a+1; i++) {
        document.getElementById("Rate").children[2].children[i].style.color  = "yellow"
    }
}
    // document.getElementById("Rate").children[2].children[e-1].style.color  = "yellow"

// }
// const changePrice = (e)=>{
//     setChanger(e)
//     // console.log("sdcefelwjfklj");
//     // if(document.getElementById("PriceChanger")){
//         if(productDetail[0].Size_Discounted[0]){
//               document.getElementById("PriceChanger2").innerHTML ="Rs : "+ productDetail[0].Price[e]
//         // document.getElementById("PriceChanger").innerHTML ="Rs : "+ productDetail[0].Size_Discounted[e]
//         document.getElementById("PriceChanger3").innerHTML =(100 -(productDetail[0].Size_Discounted[e] *100) /  productDetail[0].Price[e]).toFixed(0) +" % off"
//         document.getElementById("PriceChanger4").innerHTML ="Rs : "+ productDetail[0].Size_Discounted[e]
//     }
//     else{
//         document.getElementById("PriceChanger").innerHTML ="Rs : "+ productDetail[0].Size_Discounted[e]
//     }
      
//         // "Rs : "+ productDetail[0].Size_Discounted[e]
//         // let Price = productDetail[0].Price[e]
//         // de.innerHTML = Price
//     // }

// }

const setReviewChange = ()=>{
    // setChanger(e)
    setArea1(false)
    setArea2(false)
    setArea3(true)
        // document.getElementById("PriceChanger").innerHTML ="Rs : "+ productDetail[0].Price[e]
  

}


const submitUserReview = (e)=>{
    e.preventDefault()
    fetch("https://tapitdabit-backend.vercel.app/createReview",{
        method: "POST",
        headers :  {
            "Content-Type" : "application/json" , 
        } ,
        body : JSON.stringify({
            user:name,
            summary:subject ,
            review_message:Message ,
            quality:Star1 ,
            value:Star2 ,
            price:Star3 ,
            productId:CategoriesName.productid
        }),
    })  
    .then(res=>res.json())
    .then((res2)=>{        
    if(res2.Error){
        swal(res2.Error);
     }
     else{
        swal("Successfully Submit your Review!");
        setsubject("")
        setStar1("")
        setStar2("")
        setStar3("")
        setMessage("")
        setname("")
     }
    })
       
}
// const changePrice1 = (e)=>{
//     setChanger(e)
//     // console.log("sdcefelwjfklj");
//     // if(document.getElementById("PriceChanger")){
//         document.getElementById("PriceChanger2").innerHTML ="Rs : "+ productDetail[0].Price[e]
//         // document.getElementById("PriceChanger").innerHTML ="Rs : "+ productDetail[0].Size_Discounted[e]
//         document.getElementById("PriceChanger3").innerHTML =(100 -(productDetail[0].Size_Discounted[e] *100) /  productDetail[0].Price[e]).toFixed(0) +" % off"
//         document.getElementById("PriceChanger4").innerHTML ="Rs : "+ productDetail[0].Size_Discounted[e]
//         // "Rs : "+ productDetail[0].Size_Discounted[e]
//         // let Price = productDetail[0].Price[e]
//         // de.innerHTML = Price
//     // }

// }
// const setShoesSize = (e)=>{
//     document.getElementById("myDiv1234").style.display= "block"
//     let  m = document.getElementById("myDiv23456")
//     m.src = e
// }
// const setMainPhoto = (e)=>{
//     console.log(e);
//     document.getElementById("mainphoto").style.display= "block"
//     let  m = document.getElementById("mainphoto2")
//     m.src = e
// }

// const setChanger1 = (e)=>{
//     document.getElementById("myDiv1234").style.display= "none"
// }
// const setChanger2 = (e)=>{
//     document.getElementById("mainphoto").style.display= "none"
// }

const setChanger6711 = ()=>{
  setChanger671(true)
  setChanger672(false)
  setChanger673(false)
  
}
const setChanger6721 = ()=>{
  setChanger672(true)
  setChanger671(false)
  setChanger673(false)
  
}
const setChanger6731 = ()=>{
  setChanger673(true)
  setChanger672(false)
  setChanger671(false)
  
}

 
        
const setChangerItemSize = (e)=>{

  if (fg) setfg(false)
  else setfg(true)

  for (let i = 0; i < 45; i++) {
    if(i === e){
      if(fvalue[e] === 0){
          fvalue[e] = 1
          // let f = document.getElementById("changeIcon"+i)
           document.getElementById("changeIcon1"+i).style.transform = "rotate(180deg)"
          
      }
      else {
          fvalue[e] = 0
          // let v = document.getElementById("changeIcon"+i)
           document.getElementById("changeIcon1"+i).style.transform = "rotate(0deg)"
          // v.innerHTML= ""
       }
    }
 
  }

setfvalue(fvalue)
  if (fg) setfg(false)
  else setfg(true)
  
}

const carrouselInit =() => {

  var carrousel = document.getElementById("carrouselimg1");
  carrousel.style.opacity = "1";
  // var carrousel1 = document.getElementById("carrouselimg6");
  // carrousel1.style.opacity = "1";
  // setInterval(carrouselAutoChange, 5000);

}

const carrouselAutoChange=()  =>{
  carrouselRight();
}



const carrouselChange=(i) => {

  var carrousel = document.getElementById("carrouselimg" + i);
  carrousel.style.opacity = "1";

}

const  changePhoto = (e,a)=>{
  // document.getElementById("changes-Photo6").src = e
// for (let i = 0; i < a; i++) {
//   carrouselRight()    
// }

let nbCarrousel = 4;
  let num = 0;

  for (let i = 0; i < nbCarrousel; i++) {
      num = i + 1;
      var carrousel = document.getElementById("carrouselimg" + num);
          carrousel.style.opacity = "0";
  }
document.getElementById("carrouselimg"+(a+1)).style.opacity = "1"

}

const carrouselLeft=()  =>{

  let nbCarrousel = 4;
  let num = 0;

  for (let i = 0; i < nbCarrousel; i++) {
      num = i + 1;
      var carrousel = document.getElementById("carrouselimg" + num);
      if (carrousel.style.opacity == "1") {
          carrousel.style.opacity = "0";
          if (i == 0) {
              return carrouselChange(4);
          }
          return carrouselChange(num - 1);
      }
  }
  setsliderStatus(sliderStatus-1)
}

const carrouselRight=() =>{
  setsliderStatus(sliderStatus+1)
  let nbCarrousel = 4;
  let num = 0;

  for (let i = 0; i < nbCarrousel; i++) {
      num = i + 1;
      var carrousel = document.getElementById("carrouselimg" + num);
      if (carrousel.style.opacity == "1") {
          carrousel.style.opacity = "0";
          if (i == 3) {
              return carrouselChange(1);
          }
          return carrouselChange(num + 1);
      }
  }
}

    return (
        <>  
                     <a href="https://wa.me/923302757197" target="_blank"><img src={whatapps} alt=""style={{position : "fixed" ,zIndex : "999999", top : "80%" , right : "20px",width:"70px",height : "70px"}}/></a>

        {/* <br /><br /><br /> */}
        <div>
        {/* breadcrumb */}
        <div className="container mi2" style={{padding : "50px 0px 0px 0px"}}>
          <div className="bread-crumb flex-w p-l-25 p-r-15 p-t-30 p-lr-0-lg">
            <Link to="/" className="stext-109 cl8 hov-cl1 trans-04">
              Home
              <i className="fa fa-angle-right m-l-9 m-r-10" aria-hidden="true" />
            </Link>
            <a href="" className="stext-109 cl8 hov-cl1 trans-04">
              {CategoriesName.categoriesName}
              <i className="fa fa-angle-right m-l-9 m-r-10" aria-hidden="true" />
            </a>
            <span className="stext-109 cl4" style={{marginBottom: "16px"}}>
              {CategoriesName.productname}
            </span>
          </div>
        </div>


        {/* {
                    productDetail ? productDetail.map((res,i)=>{
                        return ( */}
                                <div className="site-section" style={{padding : "0px"}}>
                                    <div className="container">
                                            <div className="row">
                                                <div className="col-md-8 col-lg-6">
                                                    <div className="container-fuild">
                                                        {/* <div className="col-xl-12 col-lg-12 col-md-12" style={{width : "100%", margin  : "0px",padding: "0px"}} > */}
                                                        <div className="col-xl-10 col-lg-10 col-md-12" style={{width : "100%", margin  : "0px",padding: "0px"}} >
                                                            

                                                              <section>
                                                                          <div className="container-fuild container-photo-detail" style={{height :  "auto"}}>
                                                                              <div className="col-xl-10 col-lg-10 col-md-12 dfkjdf" style={{width : "92%", margin  : "0px",padding: "0px",height : "200px"}} >
                                                                                          
                                                                                          
                                                                                          
                                                                                                    <div class="center5">
                                                                                   
                                                                                                    <div onClick={()=>carrouselLeft()} style={{marginLeft: "-11px"}}>
                                                                                      <KeyboardArrowLeftIcon  style={{color : "grey", width :"30px",fontSize  :"30px"}}/>
                                                                                  
                                                                                </div>
                                                                                <div class="center11">
                                                                                    <div class="carrousel-item7" id="carrouselimg1">
                                                                                        <img class="d-block img-cov" src={productDetail[0] && productDetail[0].Product_Image_Upload1} alt="image 1"  style={{height:"185px",width: "85%",margin: "auto",borderRadius: "20px"}} id="changes-Photo6"/>
                                                                                        <span style={{position: "relative",
                                                                                            color: "#C0C0C0"  ,                                                                                         
                                                                                            zIndex: "99999",
                                                                                            top: "-74px",
                                                                                            left : "42px",
                                                                                            fontWeight: "bold",
                                                                                            fontSize: "15px",
                                                                                            fontFamily: 'Poppins-Regular'}}>{n1.length===0 ? "Your Name" : n1}</span>
                                                                                            <br />
                                                                                        <span style={{position: "relative",
                                                                                            color: "#C0C0C0",                                                                                           
                                                                                            zIndex: "99999",
                                                                                            top: "-72px",
                                                                                            left : "42px",
                                                                                            fontWeight: "bold",
                                                                                            fontSize: "15px",
                                                                                            fontFamily: 'Poppins-Regular'}}>{c1.length === 0 ? "Title" : c1}</span>
                                                                                    </div>
                                                                                    <div class="carrousel-item7" id="carrouselimg2">
                                                                                        {/* <img class="d-block img-cov" src={productDetail[0] && productDetail[0].Product_Image_Upload} alt="image 2" style={{height:"200px",width: "73%",margin: "auto",objectFit :"fill"}} id="changes-Photo6 changes-Photo"/> */}
                                                                                        <img class="d-block img-cov img-cov45" src={productDetail[0] && productDetail[0].Product_Image_Upload} alt="image 2" style={{height:"214px",paddingLeft: "10px",width: "100%",margin: "auto",borderRadius: "20px",objectFit : "contain"}} id="changes-Photo6 changes-Photo"/>
                                                                                        {/* <img class="d-block img-cov img-cov45" src={productDetail[0] && productDetail[0].Product_Image_Upload} alt="image 2" style={{height:"209px",paddingLeft: "20px",objectFit: "cover",width: "100%",margin: "auto",borderRadius: "20px"}} id="changes-Photo6 changes-Photo"/> */}
                                                                                    </div>
                                                                                    <div class="carrousel-item7" id="carrouselimg3">
                                                                                        <img class="d-block img-cov" src={productDetail[0] && productDetail[0].Product_Image_Upload2} alt="image 3" style={{height:"205px",objectFit: "cover",width: "96%",margin: "auto",borderRadius: "20px"}} id="changes-Photo6 changes-Photo"/>
                                                                                    </div>
                                                                                    <div class="carrousel-item7" id="carrouselimg4">
                                                                                        <img class="d-block img-cov img-cov45" src={productDetail[0] && productDetail[0].Product_Image_Upload3} alt="image 4" style={{height:"205px",width: "96%",margin: "auto",borderRadius: "20px",objectFit:"contain"}} id="changes-Photo6 changes-Photo"/>
                                                                                    </div>
                                                                                    <div class="carrousel-item7" id="carrouselimg5">
                                                                                        <img class="d-block img-cov" src={productDetail[0] && productDetail[0].Product_Image_Upload1} alt="image 5" style={{height:"185px",width: "96%",margin: "auto",borderRadius: "20px"}} id="changes-Photo6 changes-Photo"/>
                                                                                    </div>
                                                                                    
                                                                                 
                                                                                      
                                                                                  </div>
                                                                                  <div onClick={()=>carrouselRight()}>
                                                                                    <ArrowForwardIosIcon  style={{color : "grey", width :"19px",fontSize  :"30px"}}/>
                                                                                    </div>
                                                                            </div>
                                                                                          
                                                                                          
                                                                                          
                                                                                          
                                                                                          
                                                                              </div>
                                                                          </div>
                                                                      </section>

                                                                      <div style={{height : "193px" }} className="container-photo-detail">
                                                            {/* <div style={{paddingTop : "40px",height : "193px" }} className="container-photo-detail"> */}
                                                            {productDetail[0] && productDetail[0].Product_Image_Upload1
                                                            ?
                                                            <img src={productDetail[0] && productDetail[0].Product_Image_Upload} className="details-img-size1" alt="First slide" onClick={()=>changePhoto(productDetail[0] && productDetail[0].Product_Image_Upload,1)}/>
                                                            : ""
                                                            }
                                                            {productDetail[0] && productDetail[0].Product_Image_Upload2
                                                            ?
                                                            <img src={productDetail[0] && productDetail[0].Product_Image_Upload2} className="details-img-size1" alt="First slide"  onClick={()=>changePhoto(productDetail[0] && productDetail[0].Product_Image_Upload2,2)} />
                                                            : ""
                                                            }
                                                            {productDetail[0] && productDetail[0].Product_Image_Upload3
                                                            ?
                                                            <img src={productDetail[0] && productDetail[0].Product_Image_Upload3} className="details-img-size1" alt="First slide"  onClick={()=>changePhoto(productDetail[0] && productDetail[0].Product_Image_Upload3,3)} />
                                                            : ""
                                                            }
                                                               
                                                        </div>
                                                              {/* <section>
                                                                          <div className="container-fuild container-photo-detail" style={{height :  "auto"}}>
                                                                              <div className="col-xl-12 col-lg-12 col-md-12 dfkjdf" style={{width : "92%", margin  : "0px",padding: "0px",height : "300px"}} >
                                                                                          
                                                                                          
                                                                                          
                                                                                                    <div class="center">
                                                                                   

                                                                                <div class="center1">
                                                                                    
                                                                                    <div class="carrousel-item7" id="carrouselimg6">
                                                                                        <img class="d-block img-cov" src={productDetail[0] && productDetail[0].Product_Image_Upload1} alt="image 2" style={{height:"300px",width: "100%",margin: "auto"}} id="changes-Photo6"/>
                                                                                    </div>
                                                                                   
                                                                                      
                                                                                  </div>
                                                                                  
                                                                            </div>
                                                                                          
                                                                                          
                                                                                          
                                                                                          
                                                                                          
                                                                              </div>
                                                                          </div>
                                                                      </section> */}




                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="col-md-12 col-lg-6">
                                               
                                                <h4 className="mtext-105 cl2 js-name-detail">
                                                {/* <h4 className="mtext-105 cl2 js-name-detail p-b-14"> */}
                                                {productDetail[0] && productDetail[0].Product_Name}                  </h4>
                                               
                                                {productDetail[0] && productDetail[0].Price[0] ?
                                                    productDetail[0] && productDetail[0].Size_Discounted[0]?
                                                    <div style={{display : "flex"}}>
                                                    <p><strong  style={{color : "black"}}  className="h2 mtext-106 cl2" id="PriceChanger4">Rs : {productDetail[0] && productDetail[0].Size_Discounted[0]} </strong></p>
                                                    <div style={{width: "15px"}} className="mtext-106 cl2"></div>
                                                    <p  className="mtext-106 cl2" style={{color : "grey" , PaddingTop : "60px" , margin:  "0px" ,paddingTop: "8px"}}><strong  className=" h5" > <s  id="PriceChanger2"> Rs : {productDetail[0] && productDetail[0].Price[0]} </s></strong></p>
                                                    <div style={{width: "10px"}}></div>
                                                    <p style={{color : "red" ,paddingTop: "8px"}}> 
                                                        {productDetail[0] && productDetail[0].Size_Discounted[0] ?
                                                    <span  className="mtext-106 cl2" id="PriceChanger3">{(100 - ( productDetail[0] && productDetail[0].Size_Discounted[0]*100) /  productDetail[0] && productDetail[0].Price[0]).toFixed(2)}  % off</span>
                                                    :""
                                                   
                                                    }
                                                    
                                                     </p>
                                                </div>   :  <div style={{display : "flex"}}>
                                                    <p className="mtext-106 cl2"><strong className=" h2" style={{color : "black"}} id="PriceChanger">Rs : {productDetail[0] && productDetail[0].Price[0]} </strong></p>
                                                    </div>                                                 
                                                : productDetail[0] && productDetail[0].Product_Price_Discounted ? 
                                                
                                                <div style={{display : "flex"}}>
                                                    <p className="mtext-106 cl2"><strong className=" h2" style={{color : "black"}}>Rs : {productDetail[0] && productDetail[0].Product_Price_Discounted} </strong></p>
                                                    <div style={{width: "15px"}}></div>
                                                    <p className="mtext-106 cl2" style={{color : "grey" , PaddingTop : "60px" , margin:  "0px" ,paddingTop: "8px"}}><strong  className=" h5" > <s> Rs : {productDetail[0] && productDetail[0].Product_Price} </s></strong></p>
                                                    <div style={{width: "10px"}}></div>
                                                    <p style={{color : "red" ,paddingTop: "8px"}}><strong  className=" h5" > 
                                                        {productDetail[0] && productDetail[0].Product_Price_Discounted ?
                                                    <span>{(100 - (productDetail[0] && productDetail[0].Product_Price_Discounted *100) /  productDetail[0] && productDetail[0].Product_Price).toFixed(2)}  % off</span>
                                                    : ""
                                                    }
                                                    
                                                     </strong></p>
                                                
                                                </div>
                                                : 
                                                    <p style={{color : "black" , PaddingTop : "60px" , margin:  "0px" ,paddingTop: "8px"}}><strong  className=" h2" >  Rs : {productDetail[0] && productDetail[0].Product_Price} </strong></p>
                                                
                                                }
                                            
                                            

                            
                                                {/* {
                                                    productDetail[0] && productDetail[0].Product_Image_Upload4 ? 
                                                    <>
                                                 <p style={{cursor : "pointer"}}  onClick={()=>setShoesSize(productDetail[0] && productDetail[0].Product_Image_Upload4)}><img src={guides} width="20px" height="20px" alt="" />  Size Guide </p>
                                                </>
                                                :""
                                                }
                                                 */}
                                               

                                               
                                              
                                                
                                                <div className="mb-2" style={{padding :"10px 0px"}}>
                                                    <div className="input-group mb-3" style={{ maxWidth: "120px" }} >
                                                        <div className="input-group-prepend">
                                                            <button className="btn btn-outline-primary js-btn-minus" type="button"  onClick = {()=>minus()}>&#8722;</button>
                                                        </div>
                                                        <input type="text" className="form-control text-center"  value={Price} onChange={(e)=>setPrice(e.target.value)}  min="1" max="100" placeholder="" aria-label="Example text with button addon" aria-describedby="button-addon1" />
                                                        <div className="input-group-append">
                                                            <button className="btn btn-outline-primary js-btn-plus" type="button" onClick={()=>plus()}>&#43;</button>
                                                        </div>
                                                    </div>
                                                </div>




                                               
                                              
                                                
                                                <div className="mb-2" style={{padding :"10px 0px"}}>
                                                    <label htmlFor="c_subject" className="text-black" style={{fontWeight : "500"}}>Your Name</label><br />
                                                    <div className="input-group mb-3" style={{ maxWidth: "420px" }} >

                                                        <input type="text" className="form-control"  value={n1} onChange={(e)=>setn1(e.target.value)}  min="1" max="20" maxLength="25" placeholder="Your Name" aria-label="Example text with button addon" aria-describedby="button-addon1" />
                                                    </div>
                                                    <label htmlFor="c_subject" className="text-black" style={{fontWeight : "500"}}>Company Title On Card</label><br />
                                                    <div className="input-group mb-3" style={{ maxWidth: "420px" }} >

                                                        <input type="text" className="form-control"  value={c1} onChange={(e)=>setc1(e.target.value)}  min="1" max="20" maxLength="25" placeholder="Title" aria-label="Example text with button addon" aria-describedby="button-addon1" />
                                                    </div>
                                                    <label htmlFor="c_subject" className="text-black" style={{fontWeight : "500"}}>Upload Company Logo </label><br />
                                                    <div className="input-group mb-3" style={{ maxWidth: "420px" }} >

                                                        <input type="file" className="form-control"  onChange={(e)=>setp1(e.target.files[0])}  placeholder="" aria-label="Example text with button addon" aria-describedby="button-addon1" />
                                                    </div>
                                                </div>













                                             
                                                <p><div className="buy-now btn btn-sm btn-primary" onClick={() => addtocartproduct()} style={{borderRadius : '20px', margin : "10px" , background : "black",border  : "1px solid black",fontWeight : "bold"}}>Add To Bag</div><div className="buy-now btn btn-sm btn-primary" onClick={() =>addtocartproduct1()} style={{ borderRadius : '20px', margin : "10px" , background : "black",border  : "1px solid black",fontWeight : "bold"}}>Buy It Now</div></p>
                                                
                                                
                                                <h5>
                                                    {productDetail[0] && productDetail[0].Product_Short_Notes}
                                                  </h5>

                                                 <p>
                                                <GradeIcon id="star1"/>
                                                <GradeIcon id="star2"/>
                                                <GradeIcon id="star3"/>
                                                <GradeIcon id="star4"/>
                                                <GradeIcon id="star5"/> 
                                                <b><a onClick={()=>setReviewChange()} href="#review"  onClick={()=>setChanger6731(true)}> {allproductReview.length} Reviews</a> <br /><br /> <span style={{padding :  "5px 10px",margin : "10px 0px" , border : "1px solid grey"}} onClick={()=>setChanger6731(true)}> <a  href="#revi"  style={{color : "rgb(125, 125, 125)"}}> Create a Review</a></span></b></p>
                                                {/* <p><div className="buy-now btn btn-sm btn-primary" onClick={() => props.history.push("/checkout")}>Buy It Now</div></p> */}

                                                <div className="flex-w flex-m p-l-100 p-t-40 respon7">
                                                    <div className="flex-m bor9 p-r-10 m-r-11">
                                                    <a href="https://www.youtube.com/channel/UCUNCCx2Cvz0zajt3r-mat_w"className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 js-addwish-detail">
                                                        <i className="fa fa-youtube" />
                                                    </a>
                                                    </div>
                                                    <a href="https://www.facebook.com/Tapitdabit-108952084954293" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8">
                                                    <i className="fa fa-facebook" />
                                                    </a>
                                                    <a href="https://www.instagram.com/tapitdabit/" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8">
                                                    <i className="fa fa-instagram" />
                                                    </a>
                                                    <a href="https://twitter.com/tapitdabit" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8">
                                                    <i className="fa fa-twitter" />
                                                    </a>
                                                </div>
                                                

                                            </div>
                                        </div>
                                    </div>
                                </div>
                        {/* )
                    })

                    : <center><h2>Loading ...</h2></center>
                } */}



 









        {/* Product Detail */}
        <section className="sec-product-detail bg0">
          <div className="container">
            <div className="row">
              
              
            </div>
            <div className="bor10 m-t-50 p-t-23 p-b-20 m-b-30">
              {/* Tab01 */}
              <div className="tab01">
                {/* Nav tabs */}
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item p-b-10" onClick={()=>setChanger6711()}>
                    <a className="nav-link active" data-toggle="tab" href="#description" role="tab">Description</a>
                  </li>
                  <li className="nav-item p-b-10" onClick={()=>setChanger6721()}>
                    <a className="nav-link" data-toggle="tab" href="#description" role="tab">FAQs</a>
                  </li>
                  {/* <li className="nav-item p-b-10">
                    <a className="nav-link" data-toggle="tab" href="#information" role="tab">Additional information</a>
                  </li> */}
                  <li className="nav-item p-b-10" onClick={()=>setChanger6731()}>
                    <a className="nav-link" data-toggle="tab" href="#reviews" role="tab">Reviews </a>
                  </li>
                 
                </ul>
                {/* Tab panes */}
                <div className="tab-content p-t-43">
                  {/* - */}
                  {Changer6721 ? 
                    <div className="site-section" style={{padding : "0px"}}>
                    <div className="container">
                        <div className="row">
                            
                                {data.map((res2,a)=>{
                                    return (
                                        <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3" style={{margin : "0px 14px"}}>
                                            <div style={{display : "flex"}} onClick={()=>setChangerItemSize(a)}  href={"#collapsebank"+a} aria-controls={"collapsebank"+a}  data-toggle="collapse" >

<KeyboardArrowDownIcon style={{color : "black" ,marginRight: "20px",marginTop: "-4px"}} id={"changeIcon1"+a} />   <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href={"#collapsebank"+a} role="button" aria-expanded="false" aria-controls={"collapsebank"+a} style={{color : "black"}}> 
{res2.main}</a></h3>
        </div>
                                        <div className="collapse" id={"collapsebank"+a}>
                                            <div className="py-2">
                                                <p className="mb-0">{

                                                    res2.text.split("\n").map((res4)=>{
                                                            return (
                                                                <>
                                                                {res4} <br/>
                                                                </>
                                                            )
                                                })
                                                    
                                                    
                                                }</p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            
  )
})

}


                            </div> 

                    </div>
                </div>
                  :""
                  }
                  {Changer6711 ? 
                    <div className="show active" id="description" role="tabpanel">
                    <div className="how-pos2 p-lr-15-md">
                    <h6 className="stext-102 cl6" style={{fontSize: "16px",
    fontWeight: "bold",
    color: "black"}}>
                      {Detail.split("\n").map((res4)=>{
                                            return (
                                                <>
                                                {res4} <br/>
                                                </>
                                            )
                                        })}
                      </h6>
                    </div>
                  </div>
                  :""
                  }
                 

                 
{Changer6731 ? 
   <div id="reviews" role="tabpanel">
                  <div className="row" id="revi">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 product-detail-descr-rating3" id="review">
                               <div style={{padding  : "5px 40px"}}>
                                   <h2> Customer Reviews</h2>
                                   <br />

                                   {allproductReview.map((res3)=>{
                                       return (
                                           <>
                                           <hr />
                                           <hr />
                                        <div style={{padding  : "5px 40px"}}>
                                        <h4>{res3.summary}</h4>

                                        <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <p>Quality : 
                                        {res3.quality === 5 ?
                                        <>
                                        <GradeIcon style={{marginLeft:"10px" , color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "rgb(251, 122, 51)"}}/>
                                        </>
                                        : ""
                                        }

                                        {res3.quality === 4 ?
                                        <>
                                        <GradeIcon style={{marginLeft:"10px" , color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "grey"}}/>
                                        
                                        </>
                                        : ""
                                        }

                                        {res3.quality === 3 ?
                                        <>
                                        <GradeIcon style={{marginLeft:"10px" , color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "grey"}}/>
                                        <GradeIcon style={{ color : "grey"}}/>
                                        </>
                                        : ""
                                        }

                                        {res3.quality === 2 ?
                                        <>
                                        <GradeIcon style={{marginLeft:"10px" , color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "grey"}}/>
                                        <GradeIcon style={{ color : "grey"}}/>
                                        <GradeIcon style={{ color : "grey"}}/>
                                        </>
                                        : ""
                                        }

                                        {res3.quality === 1 ?
                                        <>
                                        <GradeIcon style={{marginLeft:"10px" , color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "grey"}}/>
                                        <GradeIcon style={{ color : "grey"}}/>
                                        <GradeIcon style={{ color : "grey"}}/>
                                        <GradeIcon style={{ color : "grey"}}/>
                                        </>
                                        : ""
                                        }
                                        </p>


                                        <p>Value : 
                                        {res3.value === 5 ?
                                        <>
                                        <GradeIcon style={{marginLeft:"10px" , color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "rgb(251, 122, 51)"}}/>
                                        </>
                                        : ""
                                        }

                                        {res3.value === 4 ?
                                        <>
                                        <GradeIcon style={{marginLeft:"10px" , color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "grey"}}/>
                                        
                                        </>
                                        : ""
                                        }

                                        {res3.value === 3 ?
                                        <>
                                        <GradeIcon style={{marginLeft:"10px" , color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "grey"}}/>
                                        <GradeIcon style={{ color : "grey"}}/>
                                        </>
                                        : ""
                                        }

                                        {res3.value === 2 ?
                                        <>
                                        <GradeIcon style={{marginLeft:"10px" , color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "grey"}}/>
                                        <GradeIcon style={{ color : "grey"}}/>
                                        <GradeIcon style={{ color : "grey"}}/>
                                        </>
                                        : ""
                                        }

                                        {res3.value === 1 ?
                                        <>
                                        <GradeIcon style={{marginLeft:"10px" , color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "grey"}}/>
                                        <GradeIcon style={{ color : "grey"}}/>
                                        <GradeIcon style={{ color : "grey"}}/>
                                        <GradeIcon style={{ color : "grey"}}/>
                                        </>
                                        : ""
                                        }
                                        </p>




                                        <p>Price : 
                                        {res3.price === 5 ?
                                        <>
                                        <GradeIcon style={{marginLeft:"10px" , color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "rgb(251, 122, 51)"}}/>
                                        </>
                                        : ""
                                        }

                                        {res3.price === 4 ?
                                        <>
                                        <GradeIcon style={{marginLeft:"10px" , color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "grey"}}/>
                                        
                                        </>
                                        : ""
                                        }

                                        {res3.price === 3 ?
                                        <>
                                        <GradeIcon style={{marginLeft:"10px" , color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "grey"}}/>
                                        <GradeIcon style={{ color : "grey"}}/>
                                        </>
                                        : ""
                                        }

                                        {res3.price === 2 ?
                                        <>
                                        <GradeIcon style={{marginLeft:"10px" , color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "grey"}}/>
                                        <GradeIcon style={{ color : "grey"}}/>
                                        <GradeIcon style={{ color : "grey"}}/>
                                        </>
                                        : ""
                                        }

                                        {res3.price === 1 ?
                                        <>
                                        <GradeIcon style={{marginLeft:"10px" , color : "rgb(251, 122, 51)"}}/>
                                        <GradeIcon style={{ color : "grey"}}/>
                                        <GradeIcon style={{ color : "grey"}}/>
                                        <GradeIcon style={{ color : "grey"}}/>
                                        <GradeIcon style={{ color : "grey"}}/>
                                        </>
                                        : ""
                                        }
                                        </p>

                                        {/* <p>Value :  <GradeIcon style={{marginLeft:"10px"}} /><GradeIcon   /><GradeIcon  /><GradeIcon   /><GradeIcon  /></p>
                                        <p>Price :  <GradeIcon style={{marginLeft:"10px"}} /><GradeIcon   /><GradeIcon  /><GradeIcon   /><GradeIcon  /></p> */}
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12" style={{padding:"10px 10%"}}>
                                            <h5>{res3.review_message}</h5>
                                            <p style={{color:"grey"}}>Publish by : {res3.user}</p>
                                        </div>
                                        </div>
                                        
                                        </div>
                                        <hr />
                                        <hr />
                                        </>
                                       )
                                   })

                                   }
                                    
                               </div>
                               <div style={{padding  : "20px 40px"}}>
                                   <p>You're reviewing:</p>
                                    <h3>
                                    {
                                            productDetail[0] ? 
                                            productDetail[0].Product_Title
                                            : " "
                                        }                                        </h3>
                                    <p>Your Rating <span className="text-danger">*</span></p>

                                    <div id="Rate">
                                        <p>Quality <GradeIcon onClick={()=>RatingProduct1(1)} className="Icon-start a1-start"/><GradeIcon onClick={()=>RatingProduct1(2)}  className="Icon-start a2-start"/><GradeIcon onClick={()=>RatingProduct1(3)}  className="Icon-start a3-start"/><GradeIcon onClick={()=>RatingProduct1(4)}  className="Icon-start a4-start"/><GradeIcon onClick={()=>RatingProduct1(5)}  className="Icon-start a5-start"/></p>
                                        <p>Value  <GradeIcon  onClick={()=>RatingProduct2(1)}  className="Icon-start a1-start"/><GradeIcon  onClick={()=>RatingProduct2(2)}   className="Icon-start a2-start"/><GradeIcon  onClick={()=>RatingProduct2(3)}   className="Icon-start a3-start"/><GradeIcon  onClick={()=>RatingProduct2(4)}   className="Icon-start a4-start"/><GradeIcon  onClick={()=>RatingProduct2(5)}   className="Icon-start a5-start"/></p>
                                        <p>Price  <GradeIcon  onClick={()=>RatingProduct3(1)}  className="Icon-start a1-start"/><GradeIcon  onClick={()=>RatingProduct3(2)}   className="Icon-start a2-start"/><GradeIcon  onClick={()=>RatingProduct3(3)}   className="Icon-start a3-start"/><GradeIcon  onClick={()=>RatingProduct3(4)}   className="Icon-start a4-start"/><GradeIcon  onClick={()=>RatingProduct3(5)}   className="Icon-start a5-start"/></p>
                                    </div>
                               </div>
                               
                               <div className="col-md-12">

                               <form onSubmit={(e)=>submitUserReview(e)}>

                                        <div className="p-3 p-lg-5 border">
                                            <div className="form-group row">
                                                <div className="col-md-12">
                                                    <label htmlFor="c_fname" className="text-black">Name <span className="text-danger">*</span></label>
                                                    <input type="text" value={name} onChange={(e)=>setname(e.target.value)}   className="form-control" />
                                                </div>
                                                {/* <div className="col-md-6">
                                                    <label htmlFor="c_lname" className="text-black">Last Name <span className="text-danger">*</span></label>
                                                    <input type="text" value={lname} onChange={(e)=>setlname(e.target.value)}   className="form-control"/>
                                                </div> */}
                                            </div>
                                           
                                            <div className="form-group row">
                                                <div className="col-md-12">
                                                    <label htmlFor="c_subject" className="text-black">Email <span className="text-danger">*</span></label>
                                                    <input type="text" value={subject} onChange={(e)=>setsubject(e.target.value)}   className="form-control" />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <div className="col-md-12">
                                                    <label htmlFor="c_message" className="text-black">Review <span className="text-danger">*</span></label>
                                                    <textarea value={Message} onChange={(e)=>setMessage(e.target.value)}   cols="30" rows="7" className="form-control"></textarea>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 ">
                                                    <input type="submit" className="btn btn-primary btn-lg btn-block" value="Send Reviews" />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    </div>
                             </div>
                        </div>
                    
                  </div>
                  : ""
}
                 
                </div>
              </div>
            </div>
          </div>
          {/* <div className="bg6 flex-c-m flex-w size-302 m-t-73 p-tb-15">
            <span className="stext-107 cl6 p-lr-25">
              SKU: JAK-01
            </span>
            <span className="stext-107 cl6 p-lr-25">
              Categories: Jacket, Men
            </span>
          </div> */}
        </section>
        {/* Related Products */}
        
       








        <div className="btn-back-to-top" id="myBtn">
          <span className="symbol-btn-back-to-top">
            <i className="zmdi zmdi-chevron-up" />
          </span>
        </div>
        {/* Modal1 */}
        <div className="wrap-modal1 js-modal1 p-t-60 p-b-20">
          <div className="overlay-modal1 js-hide-modal1" />
          <div className="container">
            <div className="bg0 p-t-60 p-b-30 p-lr-15-lg how-pos3-parent">
              <button className="how-pos3 hov3 trans-04 js-hide-modal1">
                <img src="images/icons/icon-close.png" alt="CLOSE" />
              </button>
              <div className="row">
                <div className="col-md-6 col-lg-7 p-b-30">
                  <div className="p-l-25 p-r-30 p-lr-0-lg">
                    <div className="wrap-slick3 flex-sb flex-w">
                      <div className="wrap-slick3-dots" />
                      <div className="wrap-slick3-arrows flex-sb-m flex-w" />
                      <div className="slick3 gallery-lb">
                        <div className="item-slick3" data-thumb="images/product-detail-01.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-01.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-01.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-02.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-02.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-02.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-03.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-03.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-03.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5 p-b-30">
                  <div className="p-r-50 p-t-5 p-lr-0-lg">
                    <h4 className="mtext-105 cl2 js-name-detail p-b-14">
                      Lightweight Jacket
                    </h4>
                    <span className="mtext-106 cl2">
                      $58.79
                    </span>
                    <p className="stext-102 cl3 p-t-23">
                      Nulla eget sem vitae eros pharetra viverra. Nam vitae luctus ligula. Mauris consequat ornare feugiat.
                    </p>
                    {/*  */}
                    <div className="p-t-33">
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Size
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Size S</option>
                              <option>Size M</option>
                              <option>Size L</option>
                              <option>Size XL</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Color
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Red</option>
                              <option>Blue</option>
                              <option>White</option>
                              <option>Grey</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-204 flex-w flex-m respon6-next">
                          <div className="wrap-num-product flex-w m-r-20 m-tb-10">
                            <div className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-minus" />
                            </div>
                            <input className="mtext-104 cl3 txt-center num-product" type="number" name="num-product" defaultValue={1} />
                            <div className="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-plus" />
                            </div>
                          </div>
                          <button className="flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04 js-addcart-detail">
                            Add to cart
                          </button>
                        </div>
                      </div>	
                    </div>
                    {/*  */}
                    <div className="flex-w flex-m p-l-100 p-t-40 respon7">
                      <div className="flex-m bor9 p-r-10 m-r-11">
                        <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 js-addwish-detail tooltip100" data-tooltip="Add to Wishlist">
                          <i className="zmdi zmdi-favorite" />
                        </a>
                      </div>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Facebook">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Twitter">
                        <i className="fa fa-twitter" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Google Plus">
                        <i className="fa fa-google-plus" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        </>
    )
}

export default SubCategoriesProductDetails;
