import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
// import swal from 'sweetalert';

const Contact = (props)=>  {

    useEffect(() =>{

        window.scrollTo(0, 0)
    },[])


        return (
            <div>
                <div className="bg-light py-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-0"><Link to="/">Home</Link> <span className="mx-2 mb-0">/</span> <strong className="text-black">Privacy Policy</strong></div>
                        </div>
                    </div>
                </div>
                <div className="container" style={{width : "99% " ,padding : "80px 30px",color  : "black",fontWeight:"500"}}>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div>
                                    <h1>Tapit Dabit.com Privacy Policy</h1>
                                    <p>When you visit the Site or use our Services, you trust us with your personal information. We take your privacy very seriously. In this Privacy Policy, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this Privacy Policy that you do not consent to, please discontinue use of our Site and Services. By accessing or using the Site or the Services, you acknowledge that you have read and understood this Privacy Policy.
</p>
                                    <h5>Please read this Privacy Policy carefully as it will help you make informed decisions about sharing your personal information with us.</h5>
                                    <h3>WHAT INFORMATION DO WE COLLECT?</h3>
                                    <p>Personal information you disclose to us
In Short: We collect personal information that you provide to us such as name, address, contact information, and other information you voluntarily provide us.

</p>
                                    <h3>Information collected through our Site
</h3>
                                    <p>In Short:  We may collect information regarding Your Data when you use our Site or Services.
</p>
<h3>HOW DO WE USE YOUR INFORMATION?</h3>
                                    <p>In Short:  We process Your Data for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.

</p>
<h3>WILL YOUR INFORMATION BE SHARED WITH ANYONE?

</h3>
                                    <p>In Short:  We only share Your Data with your consent, to comply with laws, to provide you with access to the Site and Services, to protect your rights, or to fulfill business obligations.</p>
                                  
<h3>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h3>
<p>In Short:  We may use cookies and other tracking technologies to collect and store Your Data.</p>


<h3>HOW LONG DO WE KEEP YOUR INFORMATION?</h3>
<p>In Short: We keep Your Data for as long as necessary to fulfill the purposes outlined in this Privacy Policy unless otherwise required by law.</p>
<h3>WHAT ARE YOUR PRIVACY RIGHTS?</h3>
<p>In Short:  You may review, change, or terminate your account at any time.</p>
<h3>Account Information</h3>
<p>If you would at any time like to review or change the information in your account or terminate your account, you can:</p>
<h4>* Log into your account settings and update your user account.</h4>
<h4>* Contact us using the contact information provided.</h4>
<h3>DO WE MAKE UPDATES TO THIS POLICY?</h3>
<p>In Short:  Yes, we will update this policy as necessary to stay compliant with relevant laws.</p>
<h3>HOW CAN YOU CONTACT US ABOUT THIS POLICY?</h3>
<p>If you have questions or comments about this policy, you may email us at tapitdabit@gmail.com </p>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        )
    
}


export default Contact;