import React , {useState , useEffect} from 'react';
import {Link} from "react-router-dom"

import bla from "./../assets/images/l3.png"

const Header = ( props ) => {
  const [MainCategories , setMainCategories] = useState([])
  const [User1 , setUser1] = useState({})
  const [User12 , setUser12] = useState({})

  useEffect(() =>{
    var User =  JSON.parse(localStorage.getItem("User")) 

     if(User1){
      setUser1(User)
    }
    
    console.log(props)
    if(window.location.href.split("/")[4]){
 fetch("https://tapitdabit-backend.vercel.app/Userother/"+window.location.href.split("/")[4],{
      method: "GET",
     headers :  {
       "Content-Type" : "application/json" , 
      } ,
    })
    .then(res5=>res5.json())
    .then(res6=>{
      
      setUser12(User)


})
    }
   
  fetch("https://tapitdabit-backend.vercel.app/MainCatogories",{
    method: "GET",
     headers :  {
     "Content-Type" : "application/json" , 
 } ,
})
.then(res3=>res3.json())
.then(res4=>{
    // console.log(res1[0]);

    let dat = []
    res4.map((res55,c)=>{
        dat.push(res55)
    })
    // console.log(SubClassCategories,asse)
    console.log(dat)
    let data = dat.sort((a, b) => {
        return a.sort - b.sort;
    });
    setMainCategories(data)
    





    const inter1 = setInterval(()=>{
      
    
    const da = JSON.parse(localStorage.getItem("User")) 
    setUser1(da)
    },1000) 

    return () => {
      

        clearInterval(inter1)
      }



    // setMainCategories(res4)
})

},[])




        return (
          <>
          {   window.location.href.split("/")[3] === "user"  ?
          <footer className="" style={{height : "60px"}}>
         <div className="container">
           
           <div className="p-t-20">
          
            
           </div>
         </div>
       </footer> 
         : 
         ""}
          {   window.location.href.split("/")[3] === "user" &&   window.location.href.split("/")[4] === User12 && User12.uid  ?
          <footer className="bg3" style={{height : "60px"}}>
         <div className="container">
           
           <div className="p-t-20">
          
             <p className="stext-107 cl6 txt-center" style={{color : "white"}}>
               {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
               Powered By TapitDabit
               {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
             </p>
           </div>
         </div>
       </footer> 
         : 
         ""}
          {   window.location.href.split("/")[3] !== "user"  ?
            <footer className="bg3 p-t-25 p-b-12 p-r-40">
          <div className="container">
            <div className="row">
              <div className="col-sm-6 col-lg-2 p-b-40">
                <img src={bla} alt=""  style={{width: "148px" ,height: "68px",objectFit: "contain"}} />
                
              </div>
              <div className="col-sm-6 col-lg-2 p-b-10 p-l-10">
                <h4 className="stext-301 cl0 p-b-30">
                 Shop All 
                </h4>
                <ul>
                  {MainCategories.map((res,i)=>{
                    return (
                      <li className="p-b-10">
                        <Link to={"/categories/"+res.MainCategories+"/product"} className="stext-107 cl7 hov-cl1 trans-04">
                           {res.MainCategories}
                        </Link>
                      </li>
                     
                    )
                  })

                  }
                 
                </ul>
              </div>
              <div className="col-sm-6 col-lg-2 p-b-10 p-l-10">
                <h4 className="stext-301 cl0 p-b-30">
                 User 
                </h4>
                <ul>
                <li className="p-b-5">

                        <Link to={"/login"} className="stext-107 cl7 hov-cl1 trans-04">
                           Login
                        </Link>
                        </li>
                <li className="p-b-5">

                        <Link to={"/account-activate"} className="stext-107 cl7 hov-cl1 trans-04">
                           Activate Card
                        </Link>
                        </li>

                </ul>
              </div>
              <div className="col-sm-6 col-lg-2 p-b-40 p-l-10">
                <h4 className="stext-301 cl0 p-b-30">
                  Company
                </h4>
                <ul>
                  <li className="p-b-10">
                    <Link to="/help" className="stext-107 cl7 hov-cl1 trans-04">
                      Help
                    </Link>
                  </li>
                  <li className="p-b-10">
                    <Link to="/enterprise" className="stext-107 cl7 hov-cl1 trans-04">
                      tabitdabit Enterprise  
                    </Link>
                  </li>
                  <li className="p-b-10">
                    <Link to="/term-policy"  className="stext-107 cl7 hov-cl1 trans-04">
                      Terms & Condition
                    </Link>
                  </li>
                  <li className="p-b-10">
                    <Link to="/privacy-policy"  className="stext-107 cl7 hov-cl1 trans-04">
                      Privacy Policy 
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-sm-6 col-lg-3 p-b-40 p-l-10">
                <h4 className="stext-301 cl0 p-b-30">
                  Connect with us
                </h4>
               
                <div className="p-t-0">
                  <a target="_blank" href="https://www.facebook.com/Tapitdabit-108952084954293" className="fs-18 cl7 hov-cl1 trans-04 m-r-16">
                    <i className="fa fa-facebook" />
                  </a>
                  <a target="_blank" href="https://www.instagram.com/tapitdabit/" className="fs-18 cl7 hov-cl1 trans-04 m-r-16">
                    <i className="fa fa-instagram" />
                  </a>
                  <a target="_blank" href="https://www.linkedin.com/in/tapit-dabit-549517224" className="fs-18 cl7 hov-cl1 trans-04 m-r-16">
                    <i className="fa fa-linkedin" />
                  </a>
                  <a target="_blank" href="https://www.youtube.com/channel/UCUNCCx2Cvz0zajt3r-mat_w" className="fs-18 cl7 hov-cl1 trans-04 m-r-16">
                    <i className="fa fa-youtube" />
                  </a>
                  <a target="_blank" href="https://twitter.com/tapitdabit" className="fs-18 cl7 hov-cl1 trans-04 m-r-16">
                    <i className="fa fa-twitter" />
                  </a>
                </div>
              </div>
              {/* <div className="col-sm-6 col-lg-3 p-b-50">
                <h4 className="stext-301 cl0 p-b-30">
                  Newsletter
                </h4>
                <form>
                  <div className="wrap-input1 w-full p-b-4">
                    <input className="input1 bg-none plh1 stext-107 cl7" type="text" name="email" placeholder="email@example.com" />
                    <div className="focus-input1 trans-04" />
                  </div>
                  <div className="p-t-18">
                    <button className="flex-c-m stext-101 cl0 size-103 bg1 bor1 hov-btn2 p-lr-15 trans-04">
                      Subscribe
                    </button>
                  </div>
                </form>
              </div> */}
            </div>
            <div className="p-t-5">
              
              <p className="stext-107 cl6 txt-center">
                Copyright © All rights reserved 
              </p>
            </div>
          </div>
        </footer>
         : 
         ""}

          </>
       
        //   <footer className="bg3 p-t-75 p-b-32">
        //   <div className="container">
        //     <div className="row">
        //       <div className="col-sm-6 col-lg-2 p-b-50">
        //         <img src={bla} alt=""  style={{width: "148px" ,height: "68px"}} />
                
        //       </div>
        //       <div className="col-sm-6 col-lg-2 p-b-20">
        //         <h4 className="stext-301 cl0 p-b-30">
        //          Shop All 
        //         </h4>
        //         <ul>
        //           {MainCategories.map((res,i)=>{
        //             return (
        //               <li className="p-b-10">
        //                 <Link to={"/categories/"+res.MainCategories+"/product"} className="stext-107 cl7 hov-cl1 trans-04">
        //                    {res.MainCategories}
        //                 </Link>
        //               </li>
                     
        //             )
        //           })

        //           }
                 
        //         </ul>
        //       </div>
        //       <div className="col-sm-6 col-lg-2 p-b-20">
        //         <h4 className="stext-301 cl0 p-b-30">
        //          User 
        //         </h4>
        //         <ul>
        //         <li className="p-b-5">

        //                 <Link to={"/login"} className="stext-107 cl7 hov-cl1 trans-04">
        //                    Login
        //                 </Link>
        //                 </li>
        //         <li className="p-b-5">

        //                 <Link to={"/account-activate"} className="stext-107 cl7 hov-cl1 trans-04">
        //                    Activate Card
        //                 </Link>
        //                 </li>

        //         </ul>
        //       </div>
        //       <div className="col-sm-6 col-lg-2 p-b-50">
        //         <h4 className="stext-301 cl0 p-b-30">
        //           Company
        //         </h4>
        //         <ul>
        //           <li className="p-b-10">
        //             <Link to="/help" className="stext-107 cl7 hov-cl1 trans-04">
        //               Help
        //             </Link>
        //           </li>
        //           <li className="p-b-10">
        //             <Link to="/enterprise" className="stext-107 cl7 hov-cl1 trans-04">
        //               tabitdabit Enterprise  
        //             </Link>
        //           </li>
        //           <li className="p-b-10">
        //             <Link to="/term-policy"  className="stext-107 cl7 hov-cl1 trans-04">
        //               Terms & Condition
        //             </Link>
        //           </li>
        //           <li className="p-b-10">
        //             <Link to="/privacy-policy"  className="stext-107 cl7 hov-cl1 trans-04">
        //               Privacy Policy 
        //             </Link>
        //           </li>
        //         </ul>
        //       </div>
        //       <div className="col-sm-6 col-lg-3 p-b-50">
        //         <h4 className="stext-301 cl0 p-b-30">
        //           Connect with us
        //         </h4>
               
        //         <div className="p-t-27">
        //           <a target="_blank" href="https://www.facebook.com/Tapitdabit-108952084954293" className="fs-18 cl7 hov-cl1 trans-04 m-r-16">
        //             <i className="fa fa-facebook" />
        //           </a>
        //           <a target="_blank" href="https://www.instagram.com/tapitdabit/" className="fs-18 cl7 hov-cl1 trans-04 m-r-16">
        //             <i className="fa fa-instagram" />
        //           </a>
        //           <a target="_blank" href="https://www.linkedin.com/in/tapit-dabit-549517224" className="fs-18 cl7 hov-cl1 trans-04 m-r-16">
        //             <i className="fa fa-linkedin" />
        //           </a>
        //           <a target="_blank" href="https://www.youtube.com/channel/UCUNCCx2Cvz0zajt3r-mat_w" className="fs-18 cl7 hov-cl1 trans-04 m-r-16">
        //             <i className="fa fa-youtube" />
        //           </a>
        //           <a target="_blank" href="https://twitter.com/tapitdabit" className="fs-18 cl7 hov-cl1 trans-04 m-r-16">
        //             <i className="fa fa-twitter" />
        //           </a>
        //         </div>
        //       </div>
        //       <div className="col-sm-6 col-lg-3 p-b-50">
        //         <h4 className="stext-301 cl0 p-b-30">
        //           Newsletter
        //         </h4>
        //         <form>
        //           <div className="wrap-input1 w-full p-b-4">
        //             <input className="input1 bg-none plh1 stext-107 cl7" type="text" name="email" placeholder="email@example.com" />
        //             <div className="focus-input1 trans-04" />
        //           </div>
        //           <div className="p-t-18">
        //             <button className="flex-c-m stext-101 cl0 size-103 bg1 bor1 hov-btn2 p-lr-15 trans-04">
        //               Subscribe
        //             </button>
        //           </div>
        //         </form>
        //       </div>
        //     </div>
        //     <div className="p-t-40">
              
        //       <p className="stext-107 cl6 txt-center">
        //         Copyright © All rights reserved 
        //       </p>
        //     </div>
        //   </div>
        // </footer>
         
        )
    
}

export default Header;