import React ,{useEffect , useState} from 'react'
// import {Link} from "react-router-dom"
import swal from 'sweetalert';

import Loader from "react-loader-spinner";



import ClearIcon from '@material-ui/icons/Clear';
import "react-multi-carousel/lib/styles.css";
import "react-multi-carousel/lib/styles.css";
import "./../assets/css/main.css"
import "./../assets/css/util.css"

import "./../assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css"

import "./../assets/vendor/css-hamburgers/hamburgers.min.css"

import "./../assets/fonts/iconic/css/material-design-iconic-font.min.css"
import "./../assets/vendor/bootstrap/css/bootstrap.min.css"
import "./../assets/vendor/select2/select2.min.css"
import "./../assets/vendor/slick/slick.css"
import "./../assets/vendor/perfect-scrollbar/perfect-scrollbar.css"

import copy1 from "./../assets/images/copy1.png"
import copy2 from "./../assets/images/copy2.png"



const Home = ( props ) => {
    // const [myRef,setRef] = useState( React.createRef())
    // const [scrollTop1,setscrollTop] = useState(0)
    // const [email , setemail] = useState("")
    // const [pass , setpass] = useState("")
    // const [dispaly ,  setdispaly ] = useState(true)
    const [User1 ,  setUser1 ] = useState({})
    // const [Arr1 ,  setArr1 ] = useState([])
    // const [order ,  setorder ] = useState([2,1,4,3,4,5,6,7,8,9,10])
    // const [Arr2 ,  setArr2 ] = useState([])
    // const [Arr5 ,  setArr5 ] = useState([])
    // const [Arr6 ,  setArr6 ] = useState([])
    // const [directOffDis ,  setdirectOffDis ] = useState(false)
    // const [directOff ,  setdirectOff ] = useState(false)
    const [Personal ,  setPersonal] = useState(true)
    const [Gene ,  setGene ] = useState("")
    const [All1, setAll1] = useState([])
    const [range1,setrange1] = useState(0)
    // const [range19,setrange19] = useState([])
    // const [dragId,setdragId] = useState("")
    // const [previewImages,setpreviewImages] = useState([])

    // const [selectDis ,  setselectDis] = useState(false)
    // const [disDete ,  setdisDete ] = useState(true)
    // const [disDete1 ,  setdisDete1 ] = useState(true)
    // const [range123,setrange123] = useState([])
    // const [Hy ,  setHy] = useState(false)



    var QRCode = require('qrcode.react');

    useEffect(() =>{
if(! JSON.parse(localStorage.getItem("User"))){
        props.history.push("/login")
      }
      else{
        // setUser1(JSON.parse(localStorage.getItem("User")))
  
    //     this.setState({
    //         email: "",
    // })
    // window.location.reload(true)
    window.scrollTo(0, 0)
    localStorage.setItem("VenderWorkingThose" ,JSON.stringify(123)) 
    localStorage.removeItem("UserProp")

    setUser1(JSON.parse(localStorage.getItem("User")))

// let A1 = JSON.parse(localStorage.getItem("User"))
  fetch("https://tapitdabit-backend.vercel.app/allUser/"+JSON.parse(localStorage.getItem("User"))._id,{
    method: "GET",
     headers :  {
     "Content-Type" : "application/json" , 
 } ,
})
.then(res5=>res5.json())
.then(res6=>{
  setGene(res6.generator)
  setPersonal(res6.DisPersonal)
  if(res6.Direct1.length > 0 ){
    setrange1(100)
  }
  // if(!res6.DisPersonal) return props.history.push("/user/bussiness/home1/"+res6._id)
  // console.log(localStorage.getItem("User").generator)
  // console.log(res6.generator)
  // localStorage.removeItem("User")
  if(res6.DispalyShow === null){

   
    fetch("https://tapitdabit-backend.vercel.app/DisNullIsNot",{
      method: "POST",
      headers :  {
          "Content-Type" : "application/json" , 
      } ,
      body : JSON.stringify({

        id : User1._id
          // MainLine2 :mainline ,
      })
  })
  .then(res11=>res11.json())
  .then((res12)=>{ 
    console.log(res12)
      if(!res12.Error){
        console.log("Successfully Send")
      }
      else{
        console.log("Not")

      }
    })

  }





  if(res6.Social||res6.Contact){
  const data = {
    ...res6 , All : [...res6.Social,...res6.Contact]
  }
  localStorage.setItem("User" ,JSON.stringify(data)) 
}
else{
  const data = {
    ...res6 
  }
  localStorage.setItem("User" ,JSON.stringify(data)) 
}
      setUser1(res6)

})
      }
    // console.log(scrollTop1)

    return () => {
      document.getElementById("get1").classList.remove("is-active");
      document.getElementById("get2").style.display = "none";
    }

    },[])
    useEffect( () => () => localStorage.removeItem("VenderWorkingThose"), [] );

  


    const setChange = () =>{
        if(document.getElementById("get2").style.display === "none"){
            document.getElementById("get1").classList.add("is-active");
            document.getElementById("get2").style.display = "block";
        }
        else{
            document.getElementById("get1").classList.remove("is-active");
            document.getElementById("get2").style.display = "none";
        }
    }

const setRouting1 = (e)=>{
  if(e === 1){
    if(JSON.parse(localStorage.getItem("User")).DisPersonal)
    props.history.push("/user/home1/"+User1._id)
    else 
    props.history.push("/user/bussiness/home1/"+User1._id)
  }
  else if(e === 4){
    if(JSON.parse(localStorage.getItem("User")).DisPersonal)
    props.history.push("/user/editProfile/sort-delete/"+User1.uid)
    else
    props.history.push("/user/bussiness/editProfile/sort-delete/"+User1.uid)
  }
  else if(e === 3){
    // props.history.push("/user/analytics/"+User1._id)   

    const d =  JSON.parse(localStorage.getItem("User")).ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
    console.log(d)
    
      
    if (d.length > 0){
      let a = new Date()
      let f= a.getDate() +" "+(a.getUTCMonth()+1)+ " "+a.getUTCFullYear()
      console.log(d[0].End[0]+""+d[0].End[1] >= a.getDate(), d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ,d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear())
      if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] > a.getUTCFullYear()     ){
      // if(d[0].End && d[0].End >= f){
    props.history.push("/user/analytics/"+User1._id)   

      }
      else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear()){
        if( d[0].End[3]+""+d[0].End[4] >a.getUTCMonth()+1 ){
      props.history.push("/user/analytics/"+User1._id)   

        }
        else{
          if(d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1){
            if( d[0].End[0]+""+d[0].End[1] >=a.getDate()){
          props.history.push("/user/analytics/"+User1._id)   

            }
            else{
              // document.getElementById("myDivUmer12").style.display= "block"
    props.history.push("/user/pro-feather")



              // localStorage.setItem("request123",JSON.stringify(1) )
              // props.history.push("/user/request-for-pro-item")
              // swal("Sorry First You Buy")
  
            }
          }
          else{
            // document.getElementById("myDivUmer12").style.display= "block"
    props.history.push("/user/pro-feather")



            // localStorage.setItem("request123",JSON.stringify(1) )
            //   props.history.push("/user/request-for-pro-item")
            //   swal("Sorry First You Buy")
  
            }
          }
        }
      else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear() && d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ){
          if( d[0].End[0]+""+d[0].End[1] >=a.getDate()){
        props.history.push("/user/analytics/"+User1._id)   

        }
        else{
          // document.getElementById("myDivUmer12").style.display= "block"
    props.history.push("/user/pro-feather")



          // localStorage.setItem("request123",JSON.stringify(1) )
          // props.history.push("/user/request-for-pro-item")
          // swal("Sorry First You Buy")
  
        }
      }
      else{
        // document.getElementById("myDivUmer12").style.display= "block"
    props.history.push("/user/pro-feather")



        // localStorage.setItem("request123",JSON.stringify(1) )
        //   props.history.push("/user/request-for-pro-item")
        //   swal("Sorry First You Buy")
  
      }
    }
    else{
      // document.getElementById("myDivUmer12").style.display= "block"
    props.history.push("/user/pro-feather")



      // localStorage.setItem("request123",JSON.stringify(1) )
      //   props.history.push("/user/request-for-pro-item")
      //   swal("Sorry First You Buy")
  }
  }
  else if(e === 2){


    const d =  JSON.parse(localStorage.getItem("User")).ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
    console.log(d)
    
      
    if (d.length > 0){
      let a = new Date()
      let f= a.getDate() +" "+(a.getUTCMonth()+1)+ " "+a.getUTCFullYear()
      console.log(d[0].End[0]+""+d[0].End[1] >= a.getDate(), d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ,d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear())
      if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] > a.getUTCFullYear()     ){
      // if(d[0].End && d[0].End >= f){
        props.history.push("/user/connect/myprofile/"+User1.uid)

      }
      else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear()){
        if( d[0].End[3]+""+d[0].End[4] >a.getUTCMonth()+1 ){
          props.history.push("/user/connect/myprofile/"+User1.uid)

        }
        else{
          if(d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1){
            if( d[0].End[0]+""+d[0].End[1] >=a.getDate()){
              props.history.push("/user/connect/myprofile/"+User1.uid)

            }
            else{
              // document.getElementById("myDivUmer").style.display= "block"

                  props.history.push("/user/pro-feather")


              // localStorage.setItem("request123",JSON.stringify(1) )
              // props.history.push("/user/request-for-pro-item")
              // swal("Sorry First You Buy")
  
            }
          }
          else{
            // document.getElementById("myDivUmer").style.display= "block"

                props.history.push("/user/pro-feather")


            // localStorage.setItem("request123",JSON.stringify(1) )
            //   props.history.push("/user/request-for-pro-item")
            //   swal("Sorry First You Buy")
  
            }
          }
        }
      else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear() && d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ){
          if( d[0].End[0]+""+d[0].End[1] >=a.getDate()){
            props.history.push("/user/connect/myprofile/"+User1.uid)

        }
        else{
          // document.getElementById("myDivUmer").style.display= "block"

              props.history.push("/user/pro-feather")


          // localStorage.setItem("request123",JSON.stringify(1) )
          // props.history.push("/user/request-for-pro-item")
          // swal("Sorry First You Buy")
  
        }
      }
      else{
        // document.getElementById("myDivUmer").style.display= "block"

            props.history.push("/user/pro-feather")


        // localStorage.setItem("request123",JSON.stringify(1) )
        //   props.history.push("/user/request-for-pro-item")
        //   swal("Sorry First You Buy")
  
      }
    }
    else{
      // document.getElementById("myDivUmer").style.display= "block"

          props.history.push("/user/pro-feather")


      // localStorage.setItem("request123",JSON.stringify(1) )
      //   props.history.push("/user/request-for-pro-item")
      //   swal("Sorry First You Buy")
  }
  }
  else{

  }
  
  
}
const setChanger8 = (e)=>{
  // document.getElementById("myDivUmer12").style.display= "block"
  props.history.push("/user/pro-feather")

}
const setRoter123 = ()=>{
  localStorage.setItem("request123",JSON.stringify(1) )
      props.history.push("/user/request-for-pro-item")

}

const setChanger1 = (e)=>{
  document.getElementById("myDiv1234").style.display= "none"
}
const setChanger2 = (e)=>{
  document.getElementById("myDivUmer").style.display= "none"
}
const setChanger3 = (e)=>{
  document.getElementById("myDivUmer7").style.display= "none"
}
const setChanger4 = (e)=>{
  document.getElementById("myDivUmer7").style.display= "block"
}
const setChange44 = (a,b)=>{
  if(navigator.share){
    navigator.share({
      title  : window.document.title,
      url  : a+""+b
    }).then(()=>console.log("Thnks"))
    .catch((err)=>console.log("Err"))
  }
}


 const eie1 = (a,c)=>{
    navigator.clipboard.writeText(c+""+a)
    swal("SucessFully Copied Your Link")
}

        return (
     

      <div  style={{background : "black", height : "100vh"}} >
        
        { !JSON.parse(localStorage.getItem("User")) ?
                <Loader
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={10000} //3 secs
        className="loader-1"
      /> 
      :""
}  


        <div id="myDiv1234">
                {/* <img src={guides} alt=""  id="myDiv23456"/> */}
                <center>
                  <h1>
                      Personal + Bussiness
                </h1>
                  </center>
                <center><p>
                  Build both a personal and bussiness profile and instantly switch between them whenever you'd like.
                  </p></center>
                  <center>
                  <button class="btn btn-dark F23 f45" onClick={()=>setRoter123()}>Buy Now </button>
                  </center>
                <ClearIcon id="myDiv34567"  style={{color : "black",fontSize : "30px",position : "absolute" , right: "10%",top : "10%" , zIndex: "2"}} onClick={()=>setChanger1()}/>
            </div> 
        <div id="myDivUmer">
                {/* <img src={guides} alt=""  id="myDiv23456"/> */}
                <center>
                  <h1>
                     Build Your Network
                </h1>
                  </center>
                <center><p>
Capture leads using TapitDabit and favorite, group, sort and manage them all from here. Start building your connections now                  </p></center>
                  <center>
                  <button class="btn btn-dark F23 f45" onClick={()=>setRoter123()}>Buy Now </button>
                  </center>
                <ClearIcon id="myDiv34567"  style={{color : "black",fontSize : "30px",position : "absolute" , right: "10%",top : "10%" , zIndex: "2"}} onClick={()=>setChanger2()}/>
            </div> 
        <div id="myDivUmer12">
                {/* <img src={guides} alt=""  id="myDiv23456"/> */}
                <center>
                  <h1>
                    Analytics
                </h1>
                  </center>
                <center><p>
                Unlock all links with Pro to see insights of your account. <br />
Tap Buy now  to upgrade.
          </p></center>
                  <center>
                  <button class="btn btn-dark F23 f45" onClick={()=>setRoter123()}>Buy Now </button>
                  </center>
                <ClearIcon id="myDiv3456767"  style={{color : "black",fontSize : "30px",position : "absolute" , right: "10%",top : "10%" , zIndex: "2"}} onClick={()=>setChanger8()}/>
            </div> 
        <div id="myDivUmer7">
                {/* <img src={guides} alt=""  id="myDiv23456"/> */}
                {/* <center>
                  <h1>
                     Build Your Network
                </h1>
                  </center> */}
                <center><p style={{padding :  "50px 30px"}}>
                TapitDabit Direct allows you to pop anyone directly to a link of your choice, without going to your full profile  .  </p></center>
                  {/* <center>
                  <button class="btn btn-dark F23 f45" onClick={()=>setRoter123()}>Buy Now </button>
                  </center> */}
                  <ClearIcon id="myDiv34567"  style={{color : "black",fontSize : "30px",position : "absolute" , right: "10%",top : "10%" , zIndex: "2"}} onClick={()=>setChanger3()}/>

            </div> 
          <div style={{height : "2px"}}>

          </div>
         


<div style={{margin :"41px 0px"}} className="eedklwk">
      <center>

         <div style={{width:  "170px", padding : "10px 20px 0px 20px",background : "white"}}>
        {User1?
<QRCode value={"https://tapitdabit.com/user/"+User1.uid} />
:""
        }
          </div>  
          <br />
<h5 style={{ color : "white"}}>Tap to save QR code to photos</h5>
          </center>
          
       <br />
        <center>
            <button class="btn btn-dark" style={{width : "100px" , borderRadius :"20px", background : "white" , color  : "black"}} onClick={()=>setChange44("https://www.tapitdabit.com/user/",User1.uid )}>Share</button>
        </center>   
<br />
        <center>
            <div className='qr-style' style={{padding  :  "12px 10px",width : "90%" ,borderRadius : "20px", height : "46px",background : "rgb(39, 39, 41)",display : "flex" ,  justifyContent : "space-between"}}>
            <p style={{ fontSize : "12px",fontWeight : "600",color  : "white" }}>tapitdabit.com/user/{User1.uid}  </p>
            {/* <p style={{ fontSize : "12px",fontWeight : "600",color  : "white" }}>https://tapitdabit.com/user/{User1.uid}  </p> */}
            <span onClick={()=>eie1(User1.uid,"https://www.tapitdabit.com/user/")} style={{color : "white",marginTop: "-5px"}}> <img src={copy1} alt="" style={{width : "30px",  height : "30px"}} /> </span>
            </div>
        </center>   

</div>
        




        



















              







       
        {/* Back to top */}
        <div className="btn-back-to-top" id="myBtn">
          <span className="symbol-btn-back-to-top">
            <i className="zmdi zmdi-chevron-up" />
          </span>
        </div>
        {/* Modal1 */}
        <div className="wrap-modal1 js-modal1 p-t-60 p-b-20">
          <div className="overlay-modal1 js-hide-modal1" />
          <div className="container">
            <div className="bg0 p-t-60 p-b-30 p-lr-15-lg how-pos3-parent">
              <button className="how-pos3 hov3 trans-04 js-hide-modal1">
                <img src="images/icons/icon-close.png" alt="CLOSE" />
              </button>
              <div className="row">
                <div className="col-md-6 col-lg-7 p-b-30">
                  <div className="p-l-25 p-r-30 p-lr-0-lg">
                    <div className="wrap-slick3 flex-sb flex-w">
                      <div className="wrap-slick3-dots" />
                      <div className="wrap-slick3-arrows flex-sb-m flex-w" />
                      <div className="slick3 gallery-lb">
                        <div className="item-slick3" data-thumb="images/product-detail-01.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-01.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-01.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-02.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-02.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-02.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-03.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-03.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-03.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5 p-b-30">
                  <div className="p-r-50 p-t-5 p-lr-0-lg">
                    <h4 className="mtext-105 cl2 js-name-detail p-b-14">
                      Lightweight Jacket
                    </h4>
                    <span className="mtext-106 cl2">
                      $58.79
                    </span>
                    <p className="stext-102 cl3 p-t-23">
                      Nulla eget sem vitae eros pharetra viverra. Nam vitae luctus ligula. Mauris consequat ornare feugiat.
                    </p>
                    {/*  */}
                    <div className="p-t-33">
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Size
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Size S</option>
                              <option>Size M</option>
                              <option>Size L</option>
                              <option>Size XL</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Color
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Red</option>
                              <option>Blue</option>
                              <option>White</option>
                              <option>Grey</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-204 flex-w flex-m respon6-next">
                          <div className="wrap-num-product flex-w m-r-20 m-tb-10">
                            <div className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-minus" />
                            </div>
                            <input className="mtext-104 cl3 txt-center num-product" type="number" name="num-product" defaultValue={1} />
                            <div className="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-plus" />
                            </div>
                          </div>
                          <button className="flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04 js-addcart-detail">
                            Add to cart
                          </button>
                        </div>
                      </div>	
                    </div>
                    {/*  */}
                    <div className="flex-w flex-m p-l-100 p-t-40 respon7">
                      <div className="flex-m bor9 p-r-10 m-r-11">
                        <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 js-addwish-detail tooltip100" data-tooltip="Add to Wishlist">
                          <i className="zmdi zmdi-favorite" />
                        </a>
                      </div>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Facebook">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Twitter">
                        <i className="fa fa-twitter" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Google Plus">
                        <i className="fa fa-google-plus" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
 






        )
    
}

export default Home;