import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"
import swal from 'sweetalert';
// import $ from 'jquery';
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import ClearIcon from '@material-ui/icons/Clear';

import "react-multi-carousel/lib/styles.css";
import "react-multi-carousel/lib/styles.css";
import "./../assets/css/main.css"
import "./../assets/css/util.css"
import "./../assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css"

// import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
// import AssessmentIcon from '@material-ui/icons/Assessment';
// import DashboardIcon from '@material-ui/icons/Dashboard';
// import SortIcon from '@material-ui/icons/Sort';



// import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"

import "./../assets/vendor/css-hamburgers/hamburgers.min.css"

import "./../assets/fonts/iconic/css/material-design-iconic-font.min.css"
import "./../assets/vendor/bootstrap/css/bootstrap.min.css"
import "./../assets/vendor/select2/select2.min.css"
import "./../assets/vendor/slick/slick.css"
import "./../assets/vendor/perfect-scrollbar/perfect-scrollbar.css"

import a11 from "./../assets/images/a11.png"


import vi1 from "./../assets/images/svg/MORE/video.svg"




const Home = ( props ) => {
    const [myRef,setRef] = useState( React.createRef())
    const [scrollTop1,setscrollTop] = useState(0)
    const [email , setemail] = useState("")
    const [pass , setpass] = useState("")
    const [dispaly ,  setdispaly ] = useState(true)
    const [User1 ,  setUser1 ] = useState({})
    const [Num ,  setNum ] = useState(0)


    useEffect(() =>{        window.scrollTo(0, 0)

if(! JSON.parse(localStorage.getItem("User"))){

        props.match.history.push("/login")
      }
      else{
        setUser1(JSON.parse(localStorage.getItem("User")))
        setNum(1)
      }
      return () => {
        document.getElementById("get1").classList.remove("is-active");
        document.getElementById("get2").style.display = "none";
      }
    },[])


//     const setChange = () =>{
//         if(document.getElementById("get2").style.display === "none"){
//             document.getElementById("get1").classList.add("is-active");
//             document.getElementById("get2").style.display = "block";
//         }
//         else{
//             document.getElementById("get1").classList.remove("is-active");
//             document.getElementById("get2").style.display = "none";
//         }
//     }

//     const setChange1 = (e) =>{
//       if(e === 1 ){
//         // document.getElementById("signUp").classList.add("right-panel-active");
//         // document.getElementById("signIn").classList.remove("right-panel-active");
//         document.getElementById("container1").classList.add("right-panel-active")
//       }
//       else{
//         document.getElementById("container1").classList.remove("right-panel-active")
//       }
//     }





// const  setPassShow = (e)=>{
//   setdispaly(e)
// }



const SubmitDataMessage = (e) =>{
  e.preventDefault()
  setdispaly ( false)
  // console.log("wddde");
  let f
  if(Num === 1){
    f = "Embed video"
  }
  
  else{
    
  }


  // const d = User1.SocialBuss.filter((res,i)=>res.name === f)
  // if(d.length === 0){
                              fetch("https://tapitdabit-backend.vercel.app/createhumareLinkBussiness",{
                                          method: "POST",
                                          headers :  {
                                              "Content-Type" : "application/json" , 
                                          } ,
                                          body : JSON.stringify({
                                            name  : f,
                                            link : email,
                                            id : User1._id
                                              // MainLine2 :mainline ,
                                          })
                                      })
                                      .then(res11=>res11.json())
                                      .then((res12)=>{ 
                                        if(!res12.Error){
                                          setTimeout(()=>{
  
                                            props.history.push("/user/bussiness/home/"+User1._id)
                                          },1500)
                                          setdispaly ( true)
                                                swal("Successfully Save!");
                                                setTimeout(()=>{
                                                  swal.close()
                                                         },1000)
                 
                                                this.setState({
                                                    email: "",
                                            })

                                        }
                                        else{setdispaly ( true)
                                      swal(res12.Error);
                                    }
                                })
                                .catch(err=>{setdispaly ( true)
                                  swal("Successfully Save !");
                                  setTimeout(()=>{
                                    swal.close()
                                           },1000)
   
                                })
                              // }
                              // else{
                              //   const c = User1.ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
                              //   if(c.length > 0){
                              //     swal("Sorry Only 1 Embed Video Allow")
                              //   }
                              //   else{

                              //     localStorage.setItem("request123",JSON.stringify(3) )
                              //     setTimeout(()=>{
                              //       props.history.push("/user/request-for-pro-item")
                              //       swal("Sorry First You Buy")
                              //     },1000)  
                              //    }
                              // } 

}


// const setRouting1 = (e)=>{
//   if(e === 1){
//     if(JSON.parse(localStorage.getItem("User")).DisPersonal)
//     props.history.push("/user/home1/"+User1._id)
//     else 
//     props.history.push("/user/bussiness/home1/"+User1._id)
//   }
//   else if(e === 4){
//     if(JSON.parse(localStorage.getItem("User")).DisPersonal)
//     props.history.push("/user/editProfile/sort-delete/"+User1.uid)
//     else
//     props.history.push("/user/bussiness/editProfile/sort-delete/"+User1.uid)
//   }
//   else if(e === 3){
//     // props.history.push("/user/analytics/"+User1._id)   

//     const d =  JSON.parse(localStorage.getItem("User")).ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
//     console.log(d)
    
      
//     if (d.length > 0){
//       let a = new Date()
//       let f= a.getDate() +" "+(a.getUTCMonth()+1)+ " "+a.getUTCFullYear()
//       console.log(d[0].End[0]+""+d[0].End[1] >= a.getDate(), d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ,d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear())
//       if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] > a.getUTCFullYear()     ){
//       // if(d[0].End && d[0].End >= f){
//     props.history.push("/user/analytics/"+User1._id)   

//       }
//       else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear()){
//         if( d[0].End[3]+""+d[0].End[4] >a.getUTCMonth()+1 ){
//       props.history.push("/user/analytics/"+User1._id)   

//         }
//         else{
//           if(d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1){
//             if( d[0].End[0]+""+d[0].End[1] >=a.getDate()){
//           props.history.push("/user/analytics/"+User1._id)   

//             }
//             else{
//               document.getElementById("myDivUmer12").style.display= "block"

//               // localStorage.setItem("request123",JSON.stringify(1) )
//               // props.history.push("/user/request-for-pro-item")
//               // swal("Sorry First You Buy")
  
//             }
//           }
//           else{
//             document.getElementById("myDivUmer12").style.display= "block"

//             // localStorage.setItem("request123",JSON.stringify(1) )
//             //   props.history.push("/user/request-for-pro-item")
//             //   swal("Sorry First You Buy")
  
//             }
//           }
//         }
//       else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear() && d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ){
//           if( d[0].End[0]+""+d[0].End[1] >=a.getDate()){
//         props.history.push("/user/analytics/"+User1._id)   

//         }
//         else{
//           document.getElementById("myDivUmer12").style.display= "block"

//           // localStorage.setItem("request123",JSON.stringify(1) )
//           // props.history.push("/user/request-for-pro-item")
//           // swal("Sorry First You Buy")
  
//         }
//       }
//       else{
//         document.getElementById("myDivUmer12").style.display= "block"

//         // localStorage.setItem("request123",JSON.stringify(1) )
//         //   props.history.push("/user/request-for-pro-item")
//         //   swal("Sorry First You Buy")
  
//       }
//     }
//     else{
//       document.getElementById("myDivUmer12").style.display= "block"

//       // localStorage.setItem("request123",JSON.stringify(1) )
//       //   props.history.push("/user/request-for-pro-item")
//       //   swal("Sorry First You Buy")
//   }
//   }
//   else if(e === 2){


//     const d =  JSON.parse(localStorage.getItem("User")).ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
//     console.log(d)
    
      
//     if (d.length > 0){
//       let a = new Date()
//       let f= a.getDate() +" "+(a.getUTCMonth()+1)+ " "+a.getUTCFullYear()
//       console.log(d[0].End[0]+""+d[0].End[1] >= a.getDate(), d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ,d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear())
//       if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] > a.getUTCFullYear()     ){
//       // if(d[0].End && d[0].End >= f){
       
//       }
//       else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear()){
//         if( d[0].End[3]+""+d[0].End[4] >a.getUTCMonth()+1 ){
//           props.history.push("/user/connect/myprofile/"+User1.uid)

//         }
//         else{
//           if(d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1){
//             if( d[0].End[0]+""+d[0].End[1]>=a.getDate()){
//               props.history.push("/user/connect/myprofile/"+User1.uid)

//             }
//             else{
//               document.getElementById("myDivUmer").style.display= "block"

//               // localStorage.setItem("request123",JSON.stringify(1) )
//               // props.history.push("/user/request-for-pro-item")
//               // swal("Sorry First You Buy")
  
//             }
//           }
//           else{
//             document.getElementById("myDivUmer").style.display= "block"

//             // localStorage.setItem("request123",JSON.stringify(1) )
//             //   props.history.push("/user/request-for-pro-item")
//             //   swal("Sorry First You Buy")
  
//             }
//           }
//         }
//       else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear() && d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ){
//           if( d[0].End[0]+""+d[0].End[1]>=a.getDate()){
//             props.history.push("/user/connect/myprofile/"+User1.uid)

//         }
//         else{
//           document.getElementById("myDivUmer").style.display= "block"

//           // localStorage.setItem("request123",JSON.stringify(1) )
//           // props.history.push("/user/request-for-pro-item")
//           // swal("Sorry First You Buy")
  
//         }
//       }
//       else{
//         document.getElementById("myDivUmer").style.display= "block"

//         // localStorage.setItem("request123",JSON.stringify(1) )
//         //   props.history.push("/user/request-for-pro-item")
//         //   swal("Sorry First You Buy")
  
//       }
//     }
//     else{
//       document.getElementById("myDivUmer").style.display= "block"

//       // localStorage.setItem("request123",JSON.stringify(1) )
//       //   props.history.push("/user/request-for-pro-item")
//       //   swal("Sorry First You Buy")
//   }
//   }
//   else{

//   }
// }
const setRoter123 = ()=>{
  localStorage.setItem("request123",JSON.stringify(1) )
      props.history.push("/user/request-for-pro-item")

}
const setChanger8 = (e)=>{
  document.getElementById("myDivUmer12").style.display= "block"
}
const setChanger1 = (e)=>{
  document.getElementById("myDiv1234").style.display= "none"
}
const setChanger2 = (e)=>{
  document.getElementById("myDivUmer").style.display= "none"
}


        return (
            






      <div
        // onScroll={onScrolll()}
        >
       
        {/* Slider */}

        <div id="myDivUmer12">
                {/* <img src={guides} alt=""  id="myDiv23456"/> */}
                <center>
                  <h1>
                    Analytics
                </h1>
                  </center>
                <center><p>
                Unlock all links with Pro to see insights of your account. <br />
Tap Buy now  to upgrade.
          </p></center>
                  <center>
                  <button class="btn btn-dark F23 f45" onClick={()=>setRoter123()}>Buy Now </button>
                  </center>
                <ClearIcon id="myDiv3456767"  style={{color : "black",fontSize : "30px",position : "absolute" , right: "10%",top : "10%" , zIndex: "2"}} onClick={()=>setChanger8()}/>
            </div> 
        <div id="myDivUmer">
                {/* <img src={guides} alt=""  id="myDiv23456"/> */}
                <center>
                  <h1>
                     Build Your Network
                </h1>
                  </center>
                <center><p>
Capture leads using Popl and favorite, group, sort and manage them all from here. Start building your connections now                  </p></center>
                  <center>
                  <button class="btn btn-dark F23 f45" onClick={()=>setRoter123()}>Buy Now </button>
                  </center>
                <ClearIcon id="myDiv34567"  style={{color : "black",fontSize : "30px",position : "absolute" , right: "10%",top : "10%" , zIndex: "2"}} onClick={()=>setChanger2()}/>
            </div> 




<>
<br/>
<br/>





<section>
                    <div className="container mi2 change-margin" style={{padding : "100px 0px 0px 0px", width : "96%"}}>
                            <div className="row justify-content-center">

                                <center>
  
  <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12">
  <div class="px-1" style={{borderRadius : "10px"}}>
   
    <div style={{display : "flex" , flexDirection : "row" ,padding : "14px 7px"}}>
      {/* <img src={s1} alt="" style={{borderRadius : "200px",width: "140px" , height : "140px"}}/> */}
        
        <div class="px-1"  style={{display : "flex" , justifyContent : "center"}}>
              {/* <textarea rows="8" cols="40" placeholder="Enter your Bio !" style={{padding : "10px 14px " , border :"2px solid white" , borderRadius : "10px", background  : " rgb(245, 246, 246)" }}></textarea> */}
              <img src={vi1} alt="" width="100%" height="80px"/>
              {/* <p style={{color : "black"}}><b>{AdminData.user}</b></p> */}
        </div>
    </div>
     
  </div>
 

</div>
</center>










                               
                            </div>
                            </div>
                </section>


              

           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" >
                <form  onSubmit={(e)=>SubmitDataMessage(e)}>
                   
                    <input className="input11" required type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Embed Video Link Here" /><br/>
                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        {/* <button className="button11" style={{width : "200px" , background : "rgb(211, 211, 211)" , color  : "black" , borderRadius :  "3px" , border : "3px solid black",fontSize : "18px"}}>Open</button> */}
                        {dispaly ? 
                        <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px", border : "1px solid white",  background : "rgb(0, 0, 0)" ,  color  : "white", fontSize : "12px"}} >Save</button>
                        :
                        <span className="button11" style={{borderRadius : "50px",width : "190px", border : "1px solid white",  background : "rgb(0, 0, 0)" ,  color  : "white", fontSize : "12px"}} >Save</span>

                    }                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
         

          
        



        
        {/* Footer */}
      
        {/* Back to top */}
        <div className="btn-back-to-top" id="myBtn">
          <span className="symbol-btn-back-to-top">
            <i className="zmdi zmdi-chevron-up" />
          </span>
        </div>
        {/* Modal1 */}
        <div className="wrap-modal1 js-modal1 p-t-60 p-b-20">
          <div className="overlay-modal1 js-hide-modal1" />
          <div className="container">
            <div className="bg0 p-t-60 p-b-30 p-lr-15-lg how-pos3-parent">
              <button className="how-pos3 hov3 trans-04 js-hide-modal1">
                <img src="images/icons/icon-close.png" alt="CLOSE" />
              </button>
              <div className="row">
                <div className="col-md-6 col-lg-7 p-b-30">
                  <div className="p-l-25 p-r-30 p-lr-0-lg">
                    <div className="wrap-slick3 flex-sb flex-w">
                      <div className="wrap-slick3-dots" />
                      <div className="wrap-slick3-arrows flex-sb-m flex-w" />
                      <div className="slick3 gallery-lb">
                        <div className="item-slick3" data-thumb="images/product-detail-01.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-01.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-01.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-02.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-02.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-02.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-03.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-03.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-03.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5 p-b-30">
                  <div className="p-r-50 p-t-5 p-lr-0-lg">
                    <h4 className="mtext-105 cl2 js-name-detail p-b-14">
                      Lightweight Jacket
                    </h4>
                    <span className="mtext-106 cl2">
                      $58.79
                    </span>
                    <p className="stext-102 cl3 p-t-23">
                      Nulla eget sem vitae eros pharetra viverra. Nam vitae luctus ligula. Mauris consequat ornare feugiat.
                    </p>
                    {/*  */}
                    <div className="p-t-33">
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Size
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Size S</option>
                              <option>Size M</option>
                              <option>Size L</option>
                              <option>Size XL</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Color
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Red</option>
                              <option>Blue</option>
                              <option>White</option>
                              <option>Grey</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-204 flex-w flex-m respon6-next">
                          <div className="wrap-num-product flex-w m-r-20 m-tb-10">
                            <div className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-minus" />
                            </div>
                            <input className="mtext-104 cl3 txt-center num-product" type="number" name="num-product" defaultValue={1} />
                            <div className="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-plus" />
                            </div>
                          </div>
                          <button className="flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04 js-addcart-detail">
                            Add to cart
                          </button>
                        </div>
                      </div>	
                    </div>
                    {/*  */}
                    <div className="flex-w flex-m p-l-100 p-t-40 respon7">
                      <div className="flex-m bor9 p-r-10 m-r-11">
                        <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 js-addwish-detail tooltip100" data-tooltip="Add to Wishlist">
                          <i className="zmdi zmdi-favorite" />
                        </a>
                      </div>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Facebook">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Twitter">
                        <i className="fa fa-twitter" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Google Plus">
                        <i className="fa fa-google-plus" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
 






        )
    
}

export default Home;