import React, { useState , useEffect } from 'react';

import swal from 'sweetalert';
import whatapps from '../assets/images/what.png';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Link } from 'react-router-dom';
import Loader from "react-loader-spinner";

const Card = (props)=> {
    const [price , setPrice] = useState([1,1,1,1,1,1,1,1,1,1,1,1,1])
    const [cart , setCart] = useState([])
    const [total , settotal] = useState(0)
    const [totalpro , settotalpro] = useState(0)
    const [dis , setdis] = useState(true)
    const [total1 , settotal1] = useState(0)
    const [total2 , settotal2] = useState(0)
    const [peice , setpeice] = useState([1,1,1,1,1,1,1,1,1,1,1,1,1])
    const [discount , setdiscount] = useState([])
    const [discount6 , setdiscount6] = useState({})
    const [promo , setpromo] = useState("")
    
    
    const [dis1 , setdis1] = useState(true)

    useEffect(() =>{
        window.scrollTo(0, 0)


        fetch("https://tapitdabit-backend.vercel.app/AllCreateDisCountPrice",{
          method: "GET",
          headers :  {
          "Content-Type" : "application/json" , 
      } ,
      })
      .then(res7=>res7.json())
      .then(res8=>{
       setdiscount(res8)
       let data5 =  JSON.parse(localStorage.getItem("CartPrice")) 
       settotal1(data5)
       settotal2(data5)
      })
      if(JSON.parse(localStorage.getItem("discount")) ){
        setdiscount6(JSON.parse(localStorage.getItem("discount")))
      }
      else{
        setdiscount6({fg : "3"})
      }



    fetch("https://tapitdabit-backend.vercel.app/all-AmbassadorLog",{
      method: "GET",
      headers :  {
      "Content-Type" : "application/json" , 
  } ,
  })
  .then(res7=>res7.json())
  .then(res8=>{
    let v = ""
    if(      JSON.parse(  localStorage.getItem("Code Promo"  ) ) ){
      v =  JSON.parse(  localStorage.getItem("Code Promo"  ) )
    }else{
      let x =  window.location.href.split("?")[1]
      if(x){
        v = x.split("%20").join(" ")
       console.log(v)
      }
    }
    if(v){
      res8.map((res12,t)=>{
      console.log(res12.name+""+res12.Num, v)
      if(res12.name+""+res12.Num === v){
        console.log(res12.name+""+res12.Num, v)
        var d = document.getElementById("discount")
        var d1 = document.getElementById("discount1")
   
            settotalpro((total1 -total1 * 20)/100)
            
            localStorage.setItem("discount",JSON.stringify(
              {name  : "Ambassador" ,
              discount2  : 20,
              name :  res12.name+""+res12.Num,
              AmbaPercen  : res12.percen
              }
            ))
            setdiscount6({})

            d.innerHTML = " " +JSON.parse(localStorage.getItem("discount")).discount2 +" % Off"
            d1.innerHTML = "Discount : "
            // d1.innerHTML = "Discount : "
            swal("Update!","Successfully your Promo Applied ", "success");
      
      }
    })

  }
  
  

  })  


  
  

  

  

  
  
  setpromo(JSON.parse(localStorage.getItem("discount")) ? JSON.parse(localStorage.getItem("discount")).name : "")







       const Inter = setInterval(()=>{
            let data =  JSON.parse(localStorage.getItem("Cart")) 
            if(data)
           setCart(data)
           else      setCart(0)

           let data5 =  JSON.parse(localStorage.getItem("CartPrice")) 
           settotal1(data5)
           settotal2(data5)
          


           if(JSON.parse(localStorage.getItem("discount")) && !JSON.parse(localStorage.getItem("discount")).discount2 ){
             setdiscount6(JSON.parse(localStorage.getItem("discount")))
            // setdiscount(JSON.parse(localStorage.getItem("discount")))
            var d = document.getElementById("discount")
            var d1 = document.getElementById("discount1")
            if(JSON.parse(localStorage.getItem("discount")).discount > 1){
                d.innerHTML = "Rs " +JSON.parse(localStorage.getItem("discount")).discount
                // document.getElementById("ci11").style.visibility = "visible"
                d1.innerHTML = "Discount : "
                // settotal1( total1 - JSON.parse(localStorage.getItem("discount")).discount)
            }
            else{
                d.innerHTML = " " +JSON.parse(localStorage.getItem("discount")).discount1 +" % Off"
                d1.innerHTML = "Discount : "
                // settotal1(total1*JSON.parse(localStorage.getItem("discount").discount1)/100)

            }
        }
        else{

        }
        },3000)

        let data =  JSON.parse(localStorage.getItem("Cart")) 
        if(data)
       { 
           setCart(data)
           data.map((res,i)=>{
            peice[i] = res.Pieces
               price[i] = res.Pieces
           })
           console.log(price,peice)
        }
        else      setCart(0)

 if (data) {
            var j = 0
            data.map((item,i)=>{
               j = item.Total_Product_Price + j
            })
            settotal1(j)
            localStorage.setItem("CartPrice" ,JSON.stringify(j))
        }
        else{
            settotal1(0)

        }
        // console.log(total,cart,total1)
        // var t = document.getElementById("subtotal")
        // t.innerHTML = "Rs "+j
        // var f = document.getElementById("total")
        // f.innerHTML = "Rs " +j
      

        return () => {
          document.getElementById("get1").classList.remove("is-active");
          document.getElementById("get2").style.display = "none";
          clearInterval(Inter);
          updateCard()
        }

  },[])
  
  const removeDatas = (item) =>{
 
    let data1;
    if(!item.Size){
         data1 = cart.filter((res,i)=>{
            return res._id !== item.id
        })
    }
    else{
         data1 = cart.filter((res,i)=>{
            return res.Size !== item.Size
        })
    }
    localStorage.setItem("Cart" ,JSON.stringify(data1))
        var j = 0
    data1.map((res,i)=>{
         j += res.Total_Product_Price
    })
    if ( j !== 0){
        localStorage.setItem("CartPrice",JSON.stringify( j))
        settotal1(j)
    }
    else{
        localStorage.setItem("CartPrice",JSON.stringify( 0))
        settotal1(0)
    }
    
    
  }

// useEffect(() => {
  
//     return () => {
//         clearInterval()
//     }
//   }, [])




  const minus=(e)=>{
    if(dis1){
      setdis1(false)
    if(peice[e] > 1)
    peice[e] = (peice[e] - 1)
    // console.log(peice[e],peice);
    if(dis)
    setdis(true)
    else setdis(false)
    updateCard()
    setdis1(true)
  
  }
  }
const plus=(e)=>{
  if(dis1){
    setdis1(false)
    if(peice[e] < 100)
    peice[e] = (peice[e] + 1)
    // console.log(peice[e],peice);
    if(dis)
    setdis(true)
    else setdis(false)
    
    updateCard()
    setdis1(true)

  }
}





const updateCard = (id) =>{
  localStorage.removeItem("CartPrice")
    let data3 =  JSON.parse(localStorage.getItem("Cart")) 
    let arr = []
    arr = peice

    const pro = data3.map((res,i)=>{
      if(res.Product_Price_Discounted)
        return {
            ...res , Pieces : arr[i] , Total_Product_Price : res.Product_Price_Discounted * arr[i] 
        }
        else 
        return {
            ...res , Pieces : arr[i] , Total_Product_Price : res.Product_Price * arr[i] 
        }
    })
    console.log(pro)
    setCart(pro)
   localStorage.removeItem("Cart")
    localStorage.setItem("Cart",JSON.stringify(pro))
    var j = 0
    pro.map((item,i)=>{
      console.log(item.Total_Product_Price)
       j = item.Total_Product_Price + j
    })
    settotal1(j)
    localStorage.setItem("CartPrice" ,JSON.stringify(j))
    // swal("Update!", "Your Cart is Updated!", "success");

    // props.history.push("/card")
        
    // }
    
  }


const setCouopon = () =>{
     













  var d = document.getElementById("discount")
  var d1 = document.getElementById("discount1")
  const the = discount.filter((res,i)=> res.name === promo)
  if(the.length === 0){
    swal("Please enter a correct promo code");
    return
  }
  if(the[0].discount === null &&the[0].discount1 === null){
    swal("Please enter a correct promo code");
    return
  }
  if(the.length > 0){

    setdiscount6(the[0])
    if( the[0].discount > 1){
      settotalpro((total1 -total1 * the[0].discount)/100)
      // settotal1((total1 * the[0].discount1)/100)
      localStorage.setItem("discount",JSON.stringify(the[0]))
      // d.innerHTML = "Rs " +the[0].discount
      d1.innerHTML = "Discount : "
      swal("Update!","Successfully your Promo Applied ", "success");
      
    }
    else{
      
      console.log(total1 -(total1 * the[0].discount1)/100)
      settotalpro((total1 -(total1 * the[0].discount1)/100))
      // settotal1((total1 * the[0].discount1)/100)
      // console.log(total1 , (total1/the[0].discount1))
      localStorage.setItem("discount",JSON.stringify(the[0]))
      // d.innerHTML = " " +the[0].discount1 +" % Off"
      d1.innerHTML = "Discount : "
      swal("Update!","Successfully your Promo Applied ", "success");

    }
  }
  else{




    fetch("https://tapitdabit-backend.vercel.app/all-AmbassadorLog",{
      method: "GET",
      headers :  {
      "Content-Type" : "application/json" , 
  } ,
  })
  .then(res7=>res7.json())
  .then(res8=>{
    let v = ""
    // if(      JSON.parse(  localStorage.getItem("Code Promo"  ) ) ){
    //   v =  JSON.parse(  localStorage.getItem("Code Promo"  ) )
    // }else{
      // let x =  window.location.href.split("?")[1]
      // if(x){
        v = promo
       console.log(v)
      // }
    // }
    if(v){
      res8.map((res12,t)=>{
      console.log(res12.name+""+res12.Num, v)
      if(res12.name+""+res12.Num === v){
        console.log(res12.name+""+res12.Num, v)
        var d = document.getElementById("discount")
        var d1 = document.getElementById("discount1")
   
            settotalpro((total1 -total1 * 20)/100)
            
            localStorage.setItem("discount",JSON.stringify(
              {name  : "Ambassador" ,
              discount2  : 20,
              name :  res12.name+""+res12.Num,
              AmbaPercen  : res12.percen
              }
            ))
            setdiscount6({})
            d.innerHTML = " " +JSON.parse(localStorage.getItem("discount")).discount2 +" % Off"
            d1.innerHTML = "Discount : "
            // d1.innerHTML = "Discount : "
            swal("Update!","Successfully your Promo Applied ", "success");
      
      }
      else{
        localStorage.removeItem("discount")
      }
    })
  
    }
    
  
  setTimeout(()=>{
    if(! JSON.parse( localStorage.getItem("discount"))){
  
      swal("Please enter a correct promo code");
      var d = document.getElementById("discount").innerHTML = ""
      var d1 = document.getElementById("discount1").innerHTML = ""
    }
  },1000)
  })  
  }
    
  }
   
        return (
            <div>

{! dis1 ?
                <Loader
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={10000} //3 secs
        className="loader-1"
      /> 
      :""
}  
        {/* breadcrumb */}
        <a href="https://wa.me/923302757197" target="_blank"><img src={whatapps} alt="" style={{position : "fixed" ,zIndex : "999999", top : "80%" , right : "20px",width:"70px",height : "70px"}}/></a>

        {/* <br /><br /><br /> */}
        <div className="container mi2" style={{padding : "80px 0px 0px 0px"}}>
          <div className="bread-crumb flex-w p-l-25 p-r-15 p-t-30 p-lr-0-lg">
            <a href="index.html" className="stext-109 cl8 hov-cl1 trans-04">
              Home
              <i className="fa fa-angle-right m-l-9 m-r-10" aria-hidden="true" />
            </a>
            <span className="stext-109 cl4">
              Shoping Cart
            </span>
          </div>
        </div>
        {/* Shoping Cart */}
        {/* <form className="bg0 p-t-75 p-b-85"> */}
          <div className="container">
            <div className="row">
              <div className="col-lg-10 col-xl-7 m-lr-auto m-b-0 m-b-20" style={{padding  : "0px"}}>
                <div className="m-lr-0-xl" style={{width:  "100%", margin : "auto"}}>
                  <div className="wrap-table-shopping-cart">
                    <table className="table-shopping-cart">
                      <tbody>
                        {/* <tr className="table_head">
                          <th className="column-1">Product</th>
                          <th className="column-2" />
                          <th className="column-3">Price</th>
                          <th className="column-4">Quantity</th>
                          <th className="column-5">Total</th>
                        </tr> */}
                        {
                        cart.length >= 1 ? cart.map((item,i)=>{
                                return(<>
                                  <tr className="table_row desktop-view-for-cart">
                                    <td className="column-1">
                                        <div className="how-itemcart1">
                                        <img src={item.Product_Image_Upload} alt="IMG" />
                                        </div>
                                    </td>
                                    <td className="column-2">{item.Product_Name}</td>
                                    <td className="column-3">Rs : {item.Product_Price_Discounted ? item.Product_Price_Discounted : item.Product_Price }
                                   {item.Stock_Quantity[0]  ? " Size : "+item.Size   : ""}
                                    </td>
                                    <td className="column-4">
                                        <div className="wrap-num-product flex-w m-l-auto m-r-0">
                                        <div className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m"  onClick={()=>minus(i)}>
                                            <i className="fs-16 zmdi zmdi-minus"/>
                                        </div>
                                        <input className="mtext-104 cl3 txt-center num-product" type="number" name="num-product1" value={peice[i]}/>
                                        <div className="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m"  onClick={()=>plus(i)} >
                                            <i className="fs-16 zmdi zmdi-plus"/>
                                        </div>
                                        </div>
                                    </td>
                                    <td className="column-5">Rs {(item.Product_Price_Discounted ? item.Product_Price_Discounted : item.Product_Price ) * peice[i]}</td>
                                    <td className="column-2"><DeleteIcon onClick={()=>removeDatas(item)} style={{color  : "red"}}/></td>
                                    </tr>
                                </>
                                      
                         )
                                            }) : <tr className="table_row desktop-view-for-cart"><td colSpan="6"><div className="col-sm-12 col-lg-12 mb-12"> <center><h3> No Product in Card</h3></center> </div></td></tr> 
                                       }
                        


                        {
                        cart.length >= 1 ? cart.map((item,i)=>{
                                return(<>
                                <tr className="table_row mobile-view-for-cart" style={{margin: "0px 20px"}}>
                                          <div>
                                           <td className="column-1" style={{width: "72px",padding: "10px 4px 0px 0px"}}>
                                        <div className="how-itemcart1">
                                        <img src={item.Product_Image_Upload} alt="IMG" style={{paddingTop: "30px"}}/>
                                        </div>
                                    </td>
                                    <td>
                                      <div style={{display: 'flex' ,flexDirection : "column",width: "128px" , overflow : "hidden",marginRight : "0px"}}>

                                    <td className="column-2" style={{fontSize : "12px",fontWeight :"bold"}}>{item.Product_Name}</td> 
<td className="column-3">Rs : {(item.Product_Price_Discounted ? item.Product_Price_Discounted : item.Product_Price ) * peice[i]}</td>
{/* <td className="column-3">Rs : {item.Product_Price_Discounted ? item.Product_Price_Discounted : item.Product_Price }</td> */}
                                   
                                   
                                    </div>
                                    </td>
                                    <td>
                                      <div style={{display: 'flex' ,flexDirection : "column",width: "76px" }}>
                                       <td className="column-1"  style={{ textAlign : "right",color  : "red",paddingRight: "20px",textDecoration : "underline"}}><b onClick={()=>removeDatas(item)} >Remove</b> </td>
                                       {/* <td className="column-3"  style={{width : "76px", textAlign : "right"}}><DeleteIcon onClick={()=>removeDatas(item)} style={{color  : "red"}}/>Remove</td> */}
                                       {/* <td style={{zindex: 1,position: "relative"}}><DeleteIcon onClick={()=>removeDatas(item)} style={{color  : "red"}}/></td> */}
                                    <td className="column-3">
                                    {/* <td className="column-3" style={{position: "relative",top: "20px"}}> */}
                                        <div className="wrap-num-product flex-w m-l-auto m-r-0"  style={{width : "76px"}}>
                                        <div className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m"  onClick={()=>minus(i)} style={{width : "27px"}}>
                                            <i className="fs-16 zmdi zmdi-minus"/>
                                        </div>
                                        <input className="mtext-104 cl3 txt-center num-product" type="number" name="num-product1" value={peice[i]}  style={{width : "20px"}}/>
                                        <div className="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m"  onClick={()=>plus(i)}  style={{width : "27px"}}>
                                            <i className="fs-16 zmdi zmdi-plus"/>
                                        </div>
                                        </div>
                                    </td>
                                    </div>
                                    </td>
                                    
                                   
                                    
                                          </div>


                                          {/* <div> */}
                                    {/* <th className="column-4" style={{padding   : "0px",paddingRight: "30px"}}>Total  </th>
                                    <td className="column-3">  Rs {(item.Product_Price_Discounted ? item.Product_Price_Discounted : item.Product_Price ) * peice[i]}</td> */}
                                    {/* <td className="column-2" style={{zindex: 1,position: "relative"}}><DeleteIcon onClick={()=>removeDatas(item)} style={{color  : "red"}}/></td> */}
                                          {/* </div> */}
                                    
                                    
                                    </tr>
                                </>
                                      
                         )
                                            }) :<tr className="table_row mobile-view-for-cart"><td colSpan="6"><div className="col-sm-12 col-lg-12 mb-12"> <h3 style={{padding  :"30px 20px"}}> No Product in Card</h3> </div></td></tr> 
                                       }
                        
                      </tbody></table>
                  </div>
                  <div className="flex-w flex-sb-m bor15 p-lr-40 p-lr-15-sm">
                    <div className="flex-m m-r-20 m-tb-5">
                      <input className="stext-104 cl2 plh4 size-117 bor13 p-lr-20 m-r-10 m-tb-5" type="text" name="coupon" placeholder="Coupon Code" value={promo} onChange={(e)=>setpromo(e.target.value)} style={{borderRadius : "10px"}}/>
                      <div className="flex-c-m stext-101 cl2 bg8 bor13 hov-btn3 p-lr-15 trans-04 pointer m-tb-5" onClick={()=>setCouopon()} style={{borderRadius : "10px" , width:  "51px", height :"40px",background : "rgb(125, 125, 125)"}}>
                        <KeyboardBackspaceIcon style={{color :  "white" , transform: "rotate(180deg)"}}/>
                        {/* Apply coupon */}
                      
                      </div>
                    </div>
                    {/* <div className="flex-c-m stext-101 cl2 size-119 bg8 bor13 hov-btn3 p-lr-15 trans-04 pointer m-tb-10">
                      Update Cart
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-sm-10 col-lg-7 col-xl-5 m-lr-auto m-b-0">
                <div className="p-lr-10 p-b-20 m-l-63 m-r-40 m-lr-0-xl p-lr-15-sm">
                  <h4 className="mtext-109 cl2">
                    Cart Totals
                  </h4>
               {/* <div className="size-209 p-r-0-sm w-full-ssm">
                     
                      <p className="stext-111" style={{fontWeight :  "bold" , fontSize  : "14px"}}>
                        Sub Total :
                        <b className="p-t-2 pl-4">
                            Rs  */}
                    
    {/* else{
      
      console.log(the,promo)
      settotalpro((total1 * the[0].discount1)/100)
      settotal1((total1 * the[0].discount1)/100)
      console.log(total1 , (total1/the[0].discount1))
      localStorage.setItem("discount",JSON.stringify(the[0]))
      swal("Applied Promo  ",the[0].discount," % Off");
      d.innerHTML = " " +the[0].discount1 +" % Off"
      d1.innerHTML = "Discount : "
    }
  } */}
               


                            {/* { " "+total1
                        } 
                        </b>
                      
                      </p>

</div>    */}
                  <div className="flex-w flex-t ">
                    

                    <div className="w-full-ssm" style={{marginBottom :  "30px"}}>
                      <span className="stext-110 cl2" id="discount1">
                       
                      </span> 
                      <span className="stext-110 cl2">
                         <b id="discount">

                         </b>
                      </span>
                    </div>
                    
                    <div className="w-full-ssm">
                     
                    </div>
                    <div className="size-209 p-r-0-sm w-full-ssm">
                     
                      {/* <p className="stext-111" style={{fontWeight :  "bold" , fontSize  : "18px"}}>
                        Sub Total :
                        <b className="p-t-2 pl-4">
     
               
                            { " "+total1
                        } 
                        </b>
                      
                      </p> */}




                      <p className="stext-111" style={{fontWeight :  "bold" , fontSize  : "16px", color : "black"}}>
                        Subtotal :
                        <b className="p-t-2 pl-4" style={{margin  :"0px 0px 0px 30px"}}>
                            Rs 
                            
                       {
   discount6.discount ?
   " "+ total2 - discount6.discount
    : ""
  }

   { discount6.discount1 ? 
   " "+(total2 -( total2 * discount6.discount1)/100) :
   ""
  }
    {discount6.fg === "3" ? 
    total2 : ""
    
  }
    {JSON.parse(localStorage.getItem("discount")) && JSON.parse(localStorage.getItem("discount")).discount2 ? 
    " "+(total2 -( total2 * JSON.parse(localStorage.getItem("discount")).discount2)/100) :
    ""
    }

                        </b>
                      
                      </p>

                      
                      <div className="p-t-15">
                     
                      </div>
                    </div>
                  </div>
                  
                           <Link to="/checkout"><button className="flex-c-m stext-101 cl0 size-116 bg3 bor14 hov-btn3 p-lr-15 trans-04 pointer">
          Checkout
                  </button></Link> 
                </div>
              </div>
            </div>
          </div>
        {/* </form> */}
        {/* Footer */}
       
        {/* Back to top */}
        <div className="btn-back-to-top" id="myBtn">
          <span className="symbol-btn-back-to-top">
            <i className="zmdi zmdi-chevron-up" />
          </span>
        </div>
      </div>
        )
    }


export default Card;





















// import React, { useState , useEffect } from 'react';
// import cloth_1 from '../assets/images/cloth_1.jpg';
// import cloth_2 from '../assets/images/cloth_2.jpg';
// import swal from 'sweetalert';
// import whatapps from '../assets/images/what.png';
// import DeleteIcon from '@material-ui/icons/Delete';
// import { Link } from 'react-router-dom';

// const Card = (props)=> {
//     const [price , setPrice] = useState([1,1,1,1,1,1,1,1,1,1,1,1,1])
//     const [cart , setCart] = useState([])
//     const [total , settotal] = useState(0)
//     const [totalpro , settotalpro] = useState(0)
//     const [dis , setdis] = useState(true)
//     const [total1 , settotal1] = useState(0)
//     const [total2 , settotal2] = useState(0)
//     const [peice , setpeice] = useState([1,1,1,1,1,1,1,1,1,1,1,1,1])
//     const [discount , setdiscount] = useState([])
//     const [discount6 , setdiscount6] = useState({})
//     const [promo , setpromo] = useState("")



//     useEffect(() =>{
//         window.scrollTo(0, 0)


//         fetch("https://tapitdabit-backend.vercel.app/AllCreateDisCountPrice",{
//           method: "GET",
//           headers :  {
//           "Content-Type" : "application/json" , 
//       } ,
//       })
//       .then(res7=>res7.json())
//       .then(res8=>{
//        setdiscount(res8)
      
//       })
//       if(JSON.parse(localStorage.getItem("discount")) ){
//         setdiscount6(JSON.parse(localStorage.getItem("discount")))
//       }
//       if(JSON.parse(localStorage.getItem("CartPrice")) ){
//         settotal2(JSON.parse(localStorage.getItem("CartPrice")))

//       }
//       else{
//         settotal2(0)
//       }

//       if(JSON.parse(localStorage.getItem("discount")) ){
//         setdiscount6(JSON.parse(localStorage.getItem("discount")))
//        // setdiscount(JSON.parse(localStorage.getItem("discount")))
//        var d = document.getElementById("discount")
//        var d1 = document.getElementById("discount1")
//        if(JSON.parse(localStorage.getItem("discount")).discount > 1){
//            d.innerHTML = "Rs : " +JSON.parse(localStorage.getItem("discount")).discount
//            // document.getElementById("ci11").style.visibility = "visible"
//            d1.innerHTML = "Discount : "
//            settotal1( JSON.parse(localStorage.getItem("CartPrice"))  - JSON.parse(localStorage.getItem("discount")).discount)
//        }
//        else{
//            d.innerHTML = " " +JSON.parse(localStorage.getItem("discount")).discount1 +" % Off"
//            d1.innerHTML = "Discount : "
//            settotal1(JSON.parse(localStorage.getItem("CartPrice")) *(JSON.parse(localStorage.getItem("discount").discount1)/100))

//        }
//    }
//        const Inter = setInterval(()=>{
//             let data =  JSON.parse(localStorage.getItem("Cart")) 
//             if(data)
//            setCart(data)
//            else      setCart(0)

//            let data5 =  JSON.parse(localStorage.getItem("CartPrice")) 
//              settotal1(data5)
//            settotal2(data5)
          


//            if(JSON.parse(localStorage.getItem("discount")) ){
//              setdiscount6(JSON.parse(localStorage.getItem("discount")))
//             // setdiscount(JSON.parse(localStorage.getItem("discount")))
//             var d = document.getElementById("discount")
//             var d1 = document.getElementById("discount1")
//             if(JSON.parse(localStorage.getItem("discount")).discount > 1){
//                 d.innerHTML = "Rs  : " +JSON.parse(localStorage.getItem("discount")).discount
//                 // document.getElementById("ci11").style.visibility = "visible"
//                 d1.innerHTML = "Discount : "
//                 settotal1( total2 - JSON.parse(localStorage.getItem("discount")).discount)
//             }
//             else{
//                 d.innerHTML = " " +JSON.parse(localStorage.getItem("discount")).discount1 +" % Off"
//                 d1.innerHTML = "Discount : "
//                 settotal1(total2*(JSON.parse(localStorage.getItem("discount").discount1)/100))

//             }
//         }
//         else{

//         }
//         },3000)

//         let data =  JSON.parse(localStorage.getItem("Cart")) 
//         if(data)
//        { 
//            setCart(data)
//            data.map((res,i)=>{
//             peice[i] = res.Pieces
//                price[i] = res.Pieces
//            })
//            console.log(price,peice)
//         }
//         else      setCart(0)

//  if (data) {
//             var j = 0
//             data.map((item,i)=>{
//                j = item.Total_Product_Price + j
//             })
//             settotal1(j)
//             localStorage.setItem("CartPrice" ,JSON.stringify(j))
//         }
//         else{
//             settotal1(0)

//         }
//         // console.log(total,cart,total1)
//         // var t = document.getElementById("subtotal")
//         // t.innerHTML = "Rs "+j
//         // var f = document.getElementById("total")
//         // f.innerHTML = "Rs " +j
      

//         return () => {
//           document.getElementById("get1").classList.remove("is-active");
//           document.getElementById("get2").style.display = "none";
//           clearInterval(Inter);
//           updateCard()
//         }

//   },[])
  
//   const removeDatas = (item) =>{
 
//     let data1;
//     if(!item.Size){
//          data1 = cart.filter((res,i)=>{
//             return res._id !== item.id
//         })
//     }
//     else{
//          data1 = cart.filter((res,i)=>{
//             return res.Size !== item.Size
//         })
//     }
//     localStorage.setItem("Cart" ,JSON.stringify(data1))
//         var j = 0
//     data1.map((res,i)=>{
//          j += res.Total_Product_Price
//     })
//     if ( j !== 0){
//         localStorage.setItem("CartPrice",JSON.stringify( j))
//         settotal1(j)
//     }
//     else{
//         localStorage.setItem("CartPrice",JSON.stringify( 0))
//         settotal1(0)
//     }
    
    
//   }

// // useEffect(() => {
  
// //     return () => {
// //         clearInterval()
// //     }
// //   }, [])




//   const minus=(e)=>{
//     if(peice[e] > 1)
//     peice[e] = (peice[e] - 1)
//     // console.log(peice[e],peice);
//     if(dis)
//     setdis(true)
//     else setdis(false)}
// const plus=(e)=>{
//     if(peice[e] < 100)
//     peice[e] = (peice[e] + 1)
//     // console.log(peice[e],peice);
//     if(dis)
//     setdis(true)
//     else setdis(false)


// }





// const updateCard = (id) =>{
//   localStorage.removeItem("CartPrice")
//     let data3 =  JSON.parse(localStorage.getItem("Cart")) 
//     let arr = []
//     arr = peice

//     const pro = data3.map((res,i)=>{
//       if(res.Product_Price_Discounted)
//         return {
//             ...res , Pieces : arr[i] , Total_Product_Price : res.Product_Price_Discounted * arr[i] 
//         }
//         else 
//         return {
//             ...res , Pieces : arr[i] , Total_Product_Price : res.Product_Price * arr[i] 
//         }
//     })
//     console.log(pro)
//     setCart(pro)
//    localStorage.removeItem("Cart")
//     localStorage.setItem("Cart",JSON.stringify(pro))
//     var j = 0
//     pro.map((item,i)=>{
//       console.log(item.Total_Product_Price)
//        j = item.Total_Product_Price + j
//     })
//     settotal1(j)
//     localStorage.setItem("CartPrice" ,JSON.stringify(j))
//     swal("Update!", "Your Cart is Updated!", "success");

//     // props.history.push("/card")
        
//     // }
    
//   }


// const setCouopon = () =>{
     
//   var d = document.getElementById("discount")
//   var d1 = document.getElementById("discount1")
//   const the = discount.filter((res,i)=> res.name === promo)
//   if(the.length > 0){

//     setdiscount6(the[0])
//     if( the[0].discount > 1){
//       settotalpro((total2 - the[0].discount))
//       settotal1((total2 - the[0].discount1)/100)
//       localStorage.setItem("discount",JSON.stringify(the[0]))
//       swal("Applied Promo  Rs : ",the[0].discount);
//       // d.innerHTML = "Rs " +the[0].discount
//       d1.innerHTML = "Discount : "
      
//     }
//     else{
      
//       console.log(the,promo)
//       settotalpro((total2 * the[0].discount1)/100)
//       settotal1((total2 * the[0].discount1)/100)
//       console.log(total2 , (total2/the[0].discount1))
//       localStorage.setItem("discount",JSON.stringify(the[0]))
//       swal("Applied Promo  ",the[0].discount," % Off");
//       // d.innerHTML = " " +the[0].discount1 +" % Off"
//       d1.innerHTML = "Discount : "

//     }
//   }
//   else{
//     swal("Plz Enter A Correct Promo Code");
//   }
    
//   }
   
//   // const removeDatas = (item) =>{
//   //   // var data =  JSON.parse(localStorage.getItem("Cart")) 
//   //   //  var j = 0
//   //   // data.map((res,i)=>{
//   //   //     if (res._id === id)
//   //   //      j += res.Product_Price 
//   //   // })
//   //   let data1;
//   //   if(!item.Size){
//   //        data1 = cart.filter((res,i)=>{
//   //           return res._id !== item.id
//   //       })
//   //   }
//   //   else{
//   //        data1 = cart.filter((res,i)=>{
//   //           return res.Size !== item.Size
//   //       })
//   //   }
//   //   localStorage.setItem("Cart" ,JSON.stringify(data1))
//   //   // fetch("https://database-student-1-stop.herokuapp.com/user-cart-add",{
//   //   //     method: "POST",
//   //   //     headers :  {
//   //   //         "Content-Type" : "application/json" , 
//   //   //     } ,
//   //   //     body : JSON.stringify({
//   //   //         cart : data1 ,
//   //   //         user : JSON.parse(localStorage.getItem("User")) 
//   //   //     })
//   //   // })
//   //   // .then(res=>res.json())
//   //   // .then((res1)=>{ 
//   //   //     console.log(res1);
//   //   // })
//   //       var j = 0
//   //   data1.map((res,i)=>{
//   //       // if (res._id === id)
//   //        j += res.Total_Product_Price
//   //   })
//   //   // let data5 =  JSON.parse(localStorage.getItem("CartPrice"))     
//   //   // total1 =  data5 - total1
//   //   if ( j !== 0){
//   //       localStorage.setItem("CartPrice",JSON.stringify( j))
//   //       settotal1(j)
//   //   }
//   //   else{
//   //       localStorage.setItem("CartPrice",JSON.stringify( 0))
//   //       settotal1(0)

//   //   }
    
    
//   // }

//         return (
//             <div>
//         {/* breadcrumb */}
//         <a href="https://wa.me/923302757197" target="_blank"><img src={whatapps} alt="" style={{position : "fixed" ,zIndex : "999999", top : "80%" , right : "20px",width:"70px",height : "70px"}}/></a>

//         <br /><br /><br />
//         <div className="container">
//           <div className="bread-crumb flex-w p-l-25 p-r-15 p-t-30 p-lr-0-lg">
//             <a href="index.html" className="stext-109 cl8 hov-cl1 trans-04">
//               Home
//               <i className="fa fa-angle-right m-l-9 m-r-10" aria-hidden="true" />
//             </a>
//             <span className="stext-109 cl4">
//               Shoping Cart
//             </span>
//           </div>
//         </div>
//         {/* Shoping Cart */}
//         {/* <form className="bg0 p-t-75 p-b-85"> */}
//           <div className="container">
//             <div className="row">
//               <div className="col-lg-10 col-xl-7 m-lr-auto m-b-50">
//                 <div className="m-l-25 m-r--38 m-lr-0-xl">
//                   <div className="wrap-table-shopping-cart">
//                     <table className="table-shopping-cart">
//                       <tbody><tr className="table_head">
//                           <th className="column-1">Product</th>
//                           <th className="column-2" />
//                           <th className="column-3">Price</th>
//                           <th className="column-4">Quantity</th>
//                           <th className="column-5">Total</th>
//                         </tr>
//                         {
//                         cart.length >= 1 ? cart.map((item,i)=>{
//                                 return(
//                                         <tr className="table_row">
//                                     <td className="column-1">
//                                         <div className="how-itemcart1">
//                                         <img src={item.Product_Image_Upload} alt="IMG" />
//                                         </div>
//                                     </td>
//                                     <td className="column-2">{item.Product_Name}</td>
//                                     <td className="column-3">Rs : {item.Product_Price_Discounted ? item.Product_Price_Discounted : item.Product_Price }</td>
//                                     <td className="column-4">
//                                         <div className="wrap-num-product flex-w m-l-auto m-r-0">
//                                         <div className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m"  onClick={()=>minus(i)}>
//                                             <i className="fs-16 zmdi zmdi-minus"/>
//                                         </div>
//                                         <input className="mtext-104 cl3 txt-center num-product" type="number" name="num-product1" value={peice[i]}/>
//                                         <div className="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m"  onClick={()=>plus(i)} >
//                                             <i className="fs-16 zmdi zmdi-plus"/>
//                                         </div>
//                                         </div>
//                                     </td>
//                                     <td className="column-5">Rs {(item.Product_Price_Discounted ? item.Product_Price_Discounted : item.Product_Price ) * peice[i]}</td>
//                                     <td className="column-2"><DeleteIcon onClick={()=>removeDatas(item)} style={{color  : "red"}}/></td>
//                                     </tr>
//                          )
//                                             }) : <tr><td colSpan="6"><div className="col-sm-12 col-lg-12 mb-12"><center> <h3> No Product in Card</h3></center> </div></td></tr> 
//                                        }
                        
//                       </tbody></table>
//                   </div>
//                   <div className="flex-w flex-sb-m bor15 p-t-18 p-b-15 p-lr-40 p-lr-15-sm">
//                     <div className="flex-w flex-m m-r-20 m-tb-5">
//                       <input className="stext-104 cl2 plh4 size-117 bor13 p-lr-20 m-r-10 m-tb-5" type="text" name="coupon" placeholder="Coupon Code" value={promo} onChange={(e)=>setpromo(e.target.value)} />
//                       <div className="flex-c-m stext-101 cl2 size-118 bg8 bor13 hov-btn3 p-lr-15 trans-04 pointer m-tb-5" onClick={()=>setCouopon()}>
//                         Apply coupon
//                       </div>
//                     </div>
//                     {/* <div className="flex-c-m stext-101 cl2 size-119 bg8 bor13 hov-btn3 p-lr-15 trans-04 pointer m-tb-10">
//                       Update Cart
//                     </div> */}
//                   </div>
//                 </div>
//               </div>
//               <div className="col-sm-10 col-lg-7 col-xl-5 m-lr-auto m-b-50">
//                 <div className="bor10 p-lr-40 p-t-30 p-b-40 m-l-63 m-r-40 m-lr-0-xl p-lr-15-sm">
//                   <h4 className="mtext-109 cl2 p-b-30">
//                     Cart Totals
//                   </h4>
//                <div className="size-209 p-r-18 p-r-0-sm w-full-ssm">
                     
//                       <p className="stext-111" style={{fontWeight :  "bold" , fontSize  : "14px"}}>
//                         Sub Total 
//                         <b className="p-t-2 pl-4">
//                             Rs :
                    
//     {/* else{
      
//       console.log(the,promo)
//       settotalpro((total1 * the[0].discount1)/100)
//       settotal1((total1 * the[0].discount1)/100)
//       console.log(total1 , (total1/the[0].discount1))
//       localStorage.setItem("discount",JSON.stringify(the[0]))
//       swal("Applied Promo  ",the[0].discount," % Off");
//       d.innerHTML = " " +the[0].discount1 +" % Off"
//       d1.innerHTML = "Discount : "

//     }



//   } */}
               
//                             { " "+total2

//                         } 
//                         </b>
                      
//                       </p>

// </div>   
// <div className="size-209 p-r-18 p-r-0-sm w-full-ssm">
                     
//                       <p className="stext-111" style={{fontWeight :  "bold" , fontSize  : "14px"}}>
//                       <span className="stext-110 cl2" id="discount1">

                     
//                         </span>
//                         <b id="discount">

//                          </b>
                      
//                       </p>

// </div>   
// <div className="size-209 p-r-18 p-r-0-sm w-full-ssm">
                     
//                       <p className="stext-111" style={{fontWeight :  "bold" , fontSize  : "14px"}}>
//                       <span className="stext-110 cl2" >
                     
//                         </span>
//                         <p className="stext-111" style={{fontWeight :  "bold" , fontSize  : "18px"}}>
//                         Total 
//                         <b className="p-t-2 pl-4">
//                             Rs : 
//                             {
//                             " " +  total1
//                             }
                 
   
//                         </b>
                      
//                       </p>

                      
//                       </p>

// </div>   
//                   <div className="flex-w flex-t bor12 p-t-15 p-b-30">
                    

//                     <div className="size-208 w-full-ssm" style={{marginBottom :  "30px"}}>
//                       <span className="stext-110 cl2" id="discount1">
                       
//                       </span>
//                     </div>
                    
//                     <div className="w-full-ssm">
//                       <span className="stext-110 cl2">
//                          <b id="discount">

//                          </b>
//                       </span>
//                     </div>
//                     <div className="size-209 p-r-18 p-r-0-sm w-full-ssm">
                     
//                       {/* <p className="stext-111" style={{fontWeight :  "bold" , fontSize  : "18px"}}>
//                         Sub Total :
//                         <b className="p-t-2 pl-4">
     
               
//                             { " "+total1

//                         } 
//                         </b>
                      
//                       </p> */}





                      
//                       <div className="p-t-15">
//                         {/* <span className="stext-112 cl8">
//                           Calculate Shipping
//                         </span>
//                         <div className="rs1-select2 rs2-select2 bor8 bg0 m-b-12 m-t-9">
//                           <select className="js-select2" name="time">
//                             <option>Select a country...</option>
//                             <option>USA</option>
//                             <option>UK</option>
//                           </select>
//                           <div className="dropDownSelect2" />
//                         </div> */}
//                         {/* <div className="bor8 bg0 m-b-12">
//                           <input className="stext-111 cl8 plh3 size-111 p-lr-15" type="text" name="state" placeholder="State /  country" />
//                         </div>
//                         <div className="bor8 bg0 m-b-22">
//                           <input className="stext-111 cl8 plh3 size-111 p-lr-15" type="text" name="postcode" placeholder="Postcode / Zip" />
//                         </div> */}
//                         {/* <div className="flex-w">
//                           <div className="flex-c-m stext-101 cl2 size-115 bg8 bor13 hov-btn3 p-lr-15 trans-04 pointer">
//                             Update Totals
//                           </div>
//                         </div> */}
//                       </div>
//                     </div>
//                   </div>
                  
//                            <Link to="/checkout"><button className="flex-c-m stext-101 cl0 size-116 bg3 bor14 hov-btn3 p-lr-15 trans-04 pointer">
//            Proceed to Checkout
//                   </button></Link> 
//                 </div>
//               </div>
//             </div>
//           </div>
//         {/* </form> */}
//         {/* Footer */}
       
//         {/* Back to top */}
//         <div className="btn-back-to-top" id="myBtn">
//           <span className="symbol-btn-back-to-top">
//             <i className="zmdi zmdi-chevron-up" />
//           </span>
//         </div>
//       </div>
//         )
//     }


// export default Card;