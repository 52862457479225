import React, { useState ,useEffect} from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const Contact = (props)=>  {
    const [name, setname] = useState("") 
    const [pass, setpass] = useState("") 
    const [Phone, setPhone] = useState("") 
    const [Photo, setPhoto] = useState("") 
    const [Photo1, setPhoto1] = useState("") 
    const [Email, setEmail] = useState("") 
    const [ZipPostal, setZipPostal] = useState(0) 
    const [City, setCity] = useState("") 
    const [State, setState] = useState("") 
    const [Country, setCountry] = useState("") 
    const [Twitter, setTwitter] = useState("") 
    const [instagram, setinstagram] = useState("") 
    const [Youtube, setYoutube] = useState("") 
    const [Comment, setComment] = useState("") 
    const [TikTok, setTikTok] = useState("") 
    const [Address, setAddress] = useState("") 

    // const [UserPhoneCode ,  setUserPhoneCode ] = useState("92")
    const [Dis12, setDis12] = useState(false) 


    const [User1, setUser1] = useState({}) 


    useEffect(() =>{
        if(! JSON.parse(localStorage.getItem("Ambassador User"))){
                props.history.push("/ambassador-login")
              }
              else{

                document.getElementById("Close-the-header").style.display = "none"

                setname(JSON.parse(localStorage.getItem("Ambassador User")).name)
                setAddress(JSON.parse(localStorage.getItem("Ambassador User")).Address)
                setTikTok(JSON.parse(localStorage.getItem("Ambassador User")).TikTok)
                setname(JSON.parse(localStorage.getItem("Ambassador User")).name)
                setpass(JSON.parse(localStorage.getItem("Ambassador User")).pass)
                setPhone(JSON.parse(localStorage.getItem("Ambassador User")).Phone)
                setPhoto(JSON.parse(localStorage.getItem("Ambassador User")).Photo)
                setEmail(JSON.parse(localStorage.getItem("Ambassador User")).email)
                setZipPostal(JSON.parse(localStorage.getItem("Ambassador User")).ZipPostal)
                setCity(JSON.parse(localStorage.getItem("Ambassador User")).Country)
                setState(JSON.parse(localStorage.getItem("Ambassador User")).City)
                setCountry(JSON.parse(localStorage.getItem("Ambassador User")).Country)
                setTwitter(JSON.parse(localStorage.getItem("Ambassador User")).Twitter)
                setinstagram(JSON.parse(localStorage.getItem("Ambassador User")).instagram)
                setYoutube(JSON.parse(localStorage.getItem("Ambassador User")).Youtube)
                setComment(JSON.parse(localStorage.getItem("Ambassador User")).Comment)
                // setUser1(JSON.parse(localStorage.getItem("User")))
          
            //     this.setState({
            //         email: "",
            // })
            // window.location.reload(true)
            window.scrollTo(0, 0)
            
            setUser1(JSON.parse(localStorage.getItem("Ambassador User")))
    }

    },[])

    const Submitdata = (e)=>{
        e.preventDefault()

        const data = new FormData()
        data.append("file", Photo1)
        data.append("upload_preset", "commerce")
        data.append("cloud_name", "freedeveloperss123")
        fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
            method : "POST",
            body : data , 
        })
        .then(res=>res.json())
        .then((res2)=>{
        fetch("https://tapitdabit-backend.vercel.app/create-Ambassador111",{
            method: 'POST' , 
            headers :  {
              "Content-Type" : "application/json" , 
            } , 
            body : JSON.stringify({
                id : JSON.parse(localStorage.getItem("Ambassador User"))._id ,
              name  ,
              pass  ,
              Photo : res2.url ? res2.url :  Photo,
              Phone : Phone ,
              Email  ,
              ZipPostal :  "" ,
              City  ,
              State :  "",
              Country  ,
              Twitter  ,
              TikTok,
              Address,
              instagram  ,
              Youtube  ,
              Comment  ,
            })
          })
          .then((res)=>res.json())
          .then((res2)  =>{
              console.log(res2)
              if (res2){
                swal("SucessFully Send "  )
             
                    props.history.push("/ambassador-dashboard")
              }
              else if (res2.Error){
                swal(res2.Error )
              }
              else{
                swal("Plz Try Again !"  )
              }
            // console.log(res2)
          })
          .catch((err)=>console.log(err))
        })
    }

    const setProfile1 = ()=>{
        localStorage.removeItem("Ambassador User")
        // localStorage.removeItem("User")

        window.location.href = "https://www.tapitdabit.com/ambassador-login"
    }


        return (
            <div>
                {/* <div className="bg-light py-3 theporper">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-0"><Link to="/ambassador-dashboard">Home</Link> <span className="mx-2 mb-0">/</span> <strong className="text-black">Contact</strong></div>
                        </div>
                    </div>
                </div> */}
                <div className="site-section">
                    <div className="container">
                        <div className="row">
                        <div className="col-md-12">
                                <h2 className="h3 mb-3 text-black">Settings</h2>
                            </div> 
                        <div className="col-md-12">

<form onSubmit={(e)=>Submitdata(e)}>

    <div className="border" style={{padding : "20px 20px 40px 20px"}}>
    {/* <div style={{margin : "0px 0px 40px 0px",borderBottom :  "1px solid black"}} className="col-md-12"><Link to="/ambassador-dashboard">Home</Link> <span className="mx-2 mb-0" style={{paddingLeft : "30px", paddingBottom : "20px"}}>|</span><Link to="/ambassador-dashboard-Analytics">Analytics</Link> <span className="mx-2 mb-0" style={{paddingLeft : "30px", paddingBottom : "20px"}}>|</span> <Link to="/ambassador-dashboard-Payments">Payments</Link> <span className="mx-2 mb-0" style={{paddingLeft : "30px", paddingBottom : "20px"}}>|</span>  <Link to="/ambassador-dashboard-Settings">Settings</Link><span className="mx-2 mb-0" style={{paddingLeft : "30px", paddingBottom : "20px"}}>|</span>  <Link onClick={()=>setProfile1()}>Logout</Link></div> */}

    <div  style={{border :  "1px solid black"}} className="col-md-12 uiuiiuuhi">
                            {/* <div className="col-md-12 mb-0"> <span className="mx-2 mb-0">/</span> <strong className="text-black">Contact</strong></div> */}
                    <li onClick={()=> setDis12(! Dis12)}>Settings  <span> <KeyboardArrowDownIcon style={{position : "absolute" , right : "10px"}} /></span> </li>
                    </div>
                     {Dis12 ?

                   <ul   style={{border :  "1px solid black", padding : "10px 0px 0px 0px"}}>
                    <li  style={{ padding : "5px 14px",borderBottom :"1px solid black",margin : "0px"}}><Link to="/ambassador-dashboard" style={{color : "black", fontWeight : "bold"}}>Home</Link></li>
                    <li  style={{ padding : "5px 14px",borderBottom :"1px solid black",margin : "0px"}}><Link to="/ambassador-dashboard-Analytics" style={{color : "black", fontWeight : "bold"}}>Analytics</Link></li>
                    <li  style={{ padding : "5px 14px",borderBottom :"1px solid black",margin : "0px"}}> <Link to="/ambassador-dashboard-Payments" style={{color : "black", fontWeight : "bold"}}>Payments</Link></li>
                    <li  style={{ padding : "5px 14px",borderBottom :"1px solid black",margin : "0px"}}> <Link to="/ambassador-dashboard-Settings" style={{color : "black", fontWeight : "bold"}}>Settings</Link></li>
                    <li  style={{ padding : "5px 14px",borderBottom :"1px solid black",margin : "0px"}}> <Link onClick={()=>setProfile1()} style={{color : "black", fontWeight : "bold"}}>Logout</Link></li>
                   </ul>
                     : ""
                     }  
                            <div style={{borderBottom :  "1px solid black"}} className="col-md-12 mb- theporper"><Link to="/ambassador-dashboard">Home</Link> <span className="mx-2 mb-0" style={{paddingLeft : "30px", paddingBottom : "10px"}}>|</span><Link to="/ambassador-dashboard-Analytics">Analytics</Link> <span className="mx-2 mb-0" style={{paddingLeft : "30px", paddingBottom : "10px"}}>|</span> <Link to="/ambassador-dashboard-Payments">Payments</Link> <span className="mx-2 mb-0" style={{paddingLeft : "30px", paddingBottom : "10px"}}>|</span>  <Link to="/ambassador-dashboard-Settings">Settings</Link><span className="mx-2 mb-0" style={{paddingLeft : "30px", paddingBottom : "10px"}}>|</span>  <Link onClick={()=>setProfile1()}>Logout</Link></div>
                         <br />


        <div className="form-group row">
            <div className="col-md-6">
                <label htmlFor="c_fname" className="text-black">Name <span className="text-danger">*</span></label>
                <input type="text" value={name}  className="form-control" />
            </div>
            <div className="col-md-6">
                <label htmlFor="c_fname" className="text-black">Email <span className="text-danger">*</span></label>
                <input type="text" value={Email}    className="form-control" />
            </div>
        </div>
        <div className="form-group row">
            <div className="col-md-6">
                <label htmlFor="c_lname" className="text-black">Phone<span className="text-danger">*</span></label>
                {/* <div style={{display : "flex" , justifyContent : "flex-start"}}>
                <select name="" id=""  value={UserPhoneCode} onChange={(e)=>setUserPhoneCode(e.target.value)}  style={{width : "107px" , fontSize :"12px" , height: "40px" , marginTop : "1px"}}>
                    {
                    PhoneCode.map((res,i)=>{
                        return(
                        <option value={res.phoneCode} >{res.value}  +{res.phoneCode}</option>
                        )
                    })
                    }
                </select> */}
                <input type="text" value={Phone} onChange={(e)=>setPhone(e.target.value)}   className="form-control"/>
            {/* </div> */}
                </div>
            <div className="col-md-6">
                <label htmlFor="c_lname" className="text-black">Password <span className="text-danger">*</span></label>
                <input type="password" value={pass} onChange={(e)=>setpass(e.target.value)}   className="form-control"/>
            </div>
        </div>
        <div className="form-group row">
            <div className="col-md-12">
                <label htmlFor="c_email" className="text-black">Photo <span className="text-danger">*</span></label>
                <input type="file"   onChange={(e)=>setPhoto1(e.target.files[0])}  className="form-control" placeholder="" />
            </div>
        </div>
        <div className="form-group row">
            <div className="col-md-12">
                <label htmlFor="c_subject" className="text-black">City </label>
                <input type="text"  value={City} onChange={(e)=>setCity(e.target.value)}   className="form-control" />
            </div>
            {/* <div className="col-sm-4 eoweoioi">
                <label htmlFor="c_subject" className="text-black">State </label>
                <input type="text"  value={State} onChange={(e)=>setState(e.target.value)}   className="form-control" />
            </div>
            <div className="col-sm-4 eoweoioi">
                <label htmlFor="c_subject" className="text-black">Zip/Postal </label>
                <input type="text"  value={ZipPostal} onChange={(e)=>setZipPostal(e.target.value)}   className="form-control" />
            </div> */}
        </div>
        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="c_email" className="text-black">Address <span className="text-danger">*</span></label>
                                                <input type="text" required value={Address} onChange={(e)=>setAddress(e.target.value)}   className="form-control" placeholder="" />
                                            </div>
                                        </div>
        <div className="form-group row">
            <div className="col-md-12">
                <label htmlFor="c_email" className="text-black">Country <span className="text-danger">*</span></label>
                <input type="text" value={Country} onChange={(e)=>setCountry(e.target.value)}   className="form-control" placeholder="" />
            </div>
        </div>
        <div className="form-group row">
            <div className="col-md-12">
                <label htmlFor="c_email" className="text-black">Twitter <span className="text-danger">*</span></label>
                <input type="text" value={Twitter} onChange={(e)=>setTwitter(e.target.value)}   className="form-control" placeholder="" />
            </div>
        </div>
        <div className="form-group row">
            <div className="col-md-12">
                <label htmlFor="c_email" className="text-black">instagram <span className="text-danger">*</span></label>
                <input type="text" value={instagram} onChange={(e)=>setinstagram(e.target.value)}   className="form-control" placeholder="" />
            </div>
        </div>
        <div className="form-group row">
            <div className="col-md-12">
                <label htmlFor="c_email" className="text-black">Youtube <span className="text-danger">*</span></label>
                <input type="text" value={Youtube} onChange={(e)=>setYoutube(e.target.value)}   className="form-control" placeholder="" />
            </div>
        </div>
        <div className="form-group row">
            <div className="col-md-12">
                <label htmlFor="c_email" className="text-black">TikTok <span className="text-danger"></span></label>
                <input type="text" value={TikTok} onChange={(e)=>setTikTok(e.target.value)}   className="form-control" placeholder="" />
            </div>
        </div>
        <div className="form-group row">
            <div className="col-md-12">
                <label htmlFor="c_message" className="text-black">Comment </label>
                <textarea value={Comment} onChange={(e)=>setComment(e.target.value)}   cols="30" rows="7" className="form-control"></textarea>
            </div>
        </div>
        <div className="form-group row">
            <div className="col-lg-4" style={{margin : "auto"}}>
                <input type="submit" className="btn btn-primary btn-lg btn-block" value="Update Your Proflie" style={{background : 'grey' , color : "white"}}/>
            </div>
        </div>
    </div>
</form>
</div>
                           
                        </div>
                    </div>
                </div>




          
                    
              
            </div>
        )
    
}


export default Contact;