import React , {useState , useEffect} from 'react';
import { Link  ,useHistory } from 'react-router-dom';

// import swal from 'sweetalert';
// import $ from 'jquery';
// import SmartSticky from 'react-smart-sticky';

// import '~video-react/dist/video-react.css'; 
// @import '~video-react/styles/scss/video-react'; // or import scss 
// import { Player } from 'video-react';
import ClearIcon from '@material-ui/icons/Clear';

// import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import "react-multi-carousel/lib/styles.css";
import "react-multi-carousel/lib/styles.css";
import "./../assets/css/main.css"
import "./../assets/css/util.css"

import "./../assets/vendor/css-hamburgers/hamburgers.min.css"

import "./../assets/fonts/iconic/css/material-design-iconic-font.min.css"
import "./../assets/vendor/bootstrap/css/bootstrap.min.css"
import "./../assets/vendor/select2/select2.min.css"
import "./../assets/vendor/slick/slick.css"
import "./../assets/vendor/perfect-scrollbar/perfect-scrollbar.css"

// import Logo from "./../assets/images/icons/logo-01.png"

// import b1 from "./../assets/images/banner-01.jpg"
// import b2 from "./../assets/images/banner-02.jpg"
// import b3 from "./../assets/images/banner-03.jpg"

import l2 from "./../assets/images/l2.png"






const Header = ( props ) => {
    const [myRef,setRef] = useState( React.createRef())
    const [myRef1,setRef1] = useState( React.createRef())
    const [scrollTop1,setscrollTop] = useState(0)
    
    // const [Categories , setCategories] = useState([])
    // const [Categories1 , setCategories1] = useState([])
    // const [Homo , setHomo] = useState([])
    const [product , setproduct] = useState([])
    const [dis , setdis] = useState(false)

    // const [product1 , setproduct1] = useState([])
    // const [UserDatat , setUserDatat] = useState({})
    // const [userHeader , setuserHeader] = useState(false)
    const [SliderPhoto , setSliderPhoto] = useState([])
    const [ArrivalPhoto , setArrivalPhoto] = useState([])
    const [BannerPhoto , setBannerPhoto] = useState([])
    const [MainCatogories , setMainCatogories] = useState([])
    // const [search , setSearch] = useState("")
    const [DataPart2 , setDataPart2] = useState([])
    // const [menu1 , setmenu1] = useState(false)
    // const [adminHeader , setadminHeader] = useState(false)
    const [displayMenu , setdisplayMenu] = useState(false)
    const [displayForVender , setdisplayForVender] = useState(false)
    const [MainLine , setMainLine] = useState("")
    // const [MainForum , setMainForum] = useState(false)
    const [MainCategories , setMainCategories] = useState([])
    const [User1 , setUser1] = useState({})
    let history = useHistory();


    useEffect(() =>{
      var data1 =  JSON.parse(localStorage.getItem("Cart")) 
      var data13 =  JSON.parse(localStorage.getItem("VenderWorkingThose")) 
      var User =  JSON.parse(localStorage.getItem("User")) 

      if (data1) setDataPart2(data1)
        

       if(User1){
        setUser1(User)
       console.log(window.location.href , window.location.href.split("/")[3]) 
       }
      

        if(data13) setdisplayForVender(true)
        else setdisplayForVender(false)
            fetch("https://tapitdabit-backend.vercel.app/main-line",{
                  method: "GET",
                   headers :  {
                   "Content-Type" : "application/json" , 
               } ,
              })
              .then(res=>res.json())
              .then(res1=>{
                if(res1[0].MainLine2)
                  // console.log(res1[0]);
                  setMainLine(res1[0].MainLine2)
                  else{
                    setMainLine("")

                  }
              
              })
              // fetch("https://tapitdabit-backend.vercel.app/Forum",{
              //     method: "GET",
              //      headers :  {
              //      "Content-Type" : "application/json" , 
              //  } ,
              // })
              // .then(res=>res.json())
              // .then(res1=>{
              //     console.log(res1[0]);
              //     setMainForum(res1[0].student)
              
              // })
      
      
              fetch("https://tapitdabit-backend.vercel.app/MainCatogories",{
                  method: "GET",
                   headers :  {
                   "Content-Type" : "application/json" , 
               } ,
              })
              .then(res3=>res3.json())
              .then(res4=>{
                  // console.log(res1[0]);
      
                  let dat = []
                  res4.map((res55,c)=>{
                      dat.push(res55)
                  })
                  // console.log(SubClassCategories,asse)
                  console.log(dat)
                  let data = dat.sort((a, b) => {
                      return a.sort - b.sort;
                  });
                  setMainCategories(data)
                  
      
      
      
      
      
      
      
      
      
                  // setMainCategories(res4)
              })
      
        
      
      
              window.scrollTo(0, 0)
      
      
              fetch("https://tapitdabit-backend.vercel.app/slider-photo",{
                  method: "GET",
                   headers :  {
                   "Content-Type" : "application/json" , 
               } ,
              })
              .then(res=>res.json())
              .then(res1=>{
                  console.log(res1[0]);
                  setSliderPhoto(res1)
              
              })
      
              fetch("https://tapitdabit-backend.vercel.app/BannerPhoto",{
                  method: "GET",
                   headers :  {
                   "Content-Type" : "application/json" , 
               } ,
              })
              .then(res22=>res22.json())
              .then(res222=>{
                  console.log(res222[0]);
                  setBannerPhoto(res222)
              
              })
      
              fetch("https://tapitdabit-backend.vercel.app/arrival-photo",{
                  method: "GET",
                   headers :  {
                   "Content-Type" : "application/json" , 
               } ,
              })
              .then(res2=>res2.json())
              .then(res3=>{
                  console.log(res3);
                  setArrivalPhoto(res3)
              
              })
              fetch("https://tapitdabit-backend.vercel.app/MainCatogories",{
                  method: "GET",
                   headers :  {
                   "Content-Type" : "application/json" , 
               } ,
              })
              .then(res2=>res2.json())
              .then(res3=>{
                  console.log(res3);
                  // setMainCatogories(res3)
                  
      
                  let dat = []
                  res3.map((res55,c)=>{
                      dat.push(res55)
                  })
                  // console.log(SubClassCategories,asse)
                  console.log(dat)
                  let data = dat.sort((a, b) => {
                      return a.sort - b.sort;
                  });
                  setMainCatogories(data)
                  
              })
              
      
      
      
      
              localStorage.removeItem("SearchData")
              localStorage.removeItem("Data")
              localStorage.removeItem("CartPrice")
              localStorage.removeItem("CateProduct")
      
              // if ( JSON.parse(localStorage.getItem("User"))  ){
              //     setuserHeader(true)
              //     setUserDatat(JSON.parse(localStorage.getItem("User")))
              //     const cartUser1 = JSON.parse(localStorage.getItem("Cart"))
              //     if (cartUser1 ){
              // fetch("/user-cart-add",{
              //                         method: "POST",
              //                         headers :  {
              //                             "Content-Type" : "application/json" , 
              //                         } ,
              //                         body : JSON.stringify({
              //                             cart : cartUser1 ,
              //                             user : JSON.parse(localStorage.getItem("User")) 
              //                         })
              //                     })
              //                     .then(res=>res.json())
              //                     .then((res1)=>{ 
              //                         console.log(res1);
              //                     })
      
              //     }
              // }
      
              fetch("https://tapitdabit-backend.vercel.app/AllProduct",{
             method: "GET",
              headers :  {
              "Content-Type" : "application/json" , 
          }
         })
         .then(res5=>res5.json())
         .then(res6=>{
          //    console.log(res6);
          const pro = []
          res6.map((res7,i)=>{
              //    console.log(res7.Product_Popular );
              if ( res7.Arrival){
                  //    console.log(res7);
                  //         // return setproduct1(...product1 , res3)
                  pro.push(res7)
              }
          })
          // setproduct(pro.reverse())
          setproduct(pro.slice(0,3))
          setdis(true)
         })
          
      //     fetch("/AllCategories",{
      //        method: "GET",
      //         headers :  {
      //         "Content-Type" : "application/json" , 
      //     } ,
      //    })
      //    .then(res=>res.json())
      //    .then(res1=>{
      //     setCategories(res1)
         
      //    })
      
          
      //     fetch("/AllHomomethtic",{
      //        method: "GET",
      //         headers :  {
      //         "Content-Type" : "application/json" , 
      //     } ,
      //    })
      //    .then(res3=>res3.json())
      //    .then(res4=>{
      //     setHomo(res4)
      //     // console.log(res4);
      //    })
      if(JSON.parse(localStorage.getItem("User 56")))
      setUser1(JSON.parse(localStorage.getItem("User 56")))

      const inter1 = setInterval(()=>{
        if(JSON.parse(localStorage.getItem("User 56")))
        setUser1(JSON.parse(localStorage.getItem("User 56")))

      
      if(window.location.href.split("/")[3] !== "user" || User1.uid === null  ){
  const scrollY = window.scrollY //Don't get confused by what's scrolling - It's not the window
      const scrollTop = myRef.current.scrollTop
      // console.log(`onScroll, window.scrollY: ${scrollY} myRef.scrollTop: ${scrollTop}`)
      if(scrollY <= 40){
        console.log(40-scrollY );
        document.getElementById("head1").style.top = (42-scrollY)+"px"
      }
      else{
        document.getElementById("head1").style.top = -3+"px"
      
      }
      setscrollTop(scrollTop)
      
      }else{
        
      
      }
        var data12 =  JSON.parse(localStorage.getItem("Cart")) 
      if (data12) {
          setDataPart2(data12)
         //  const data = DataPart2.length  
         //  setDataPart2(data)
         }
      else setDataPart2([])
    
      var data123 =  JSON.parse(localStorage.getItem("VenderWorkingThose")) 
      if (data123) {
        setdisplayForVender(true)
         //  const data = DataPart2.length  
         //  setDataPart2(data)
         }
      else setdisplayForVender(false)
    
      
      // console.log(scrollTop1)
      
      const da = JSON.parse(localStorage.getItem("User")) 
      setUser1(da)


      },1000) 

      return () => {
        
          document.getElementById("get1").classList.remove("is-active");
          document.getElementById("get2").style.display = "none";
          localStorage.removeItem("UserProp")

          clearInterval(inter1)
        }
          

          },[])
      










        const clickLogin = ()=>{
            // localStorage.removeItem("Admin")
            localStorage.removeItem("User")
            localStorage.removeItem("VenderWorkingThose")
            localStorage.removeItem("the")
            localStorage.removeItem("the1")
            // localStorage.removeItem("Cart")
            window.location.href = "https://www.tapitdabit.com/login"
            // history.push("/login")
        }
      
      
      const effectEnter = () =>{
        const c=  document.getElementById("nav-menu-hover-effect1")
        document.getElementById("desktop-nav1").style.display = "block"
        // console.log(c)
      
      }
      
      const setChanger7 = () =>{
        const c=  document.getElementById("88")
        if(c.style.display === "none"){
          c.style.display = "block"
          document.getElementById("66").classList.add("turn-arrow-main-menu-m")
          
        }
        else{
          c.style.display = "none"
          document.getElementById("66").classList.remove("turn-arrow-main-menu-m")
        }
      
      }
      
      
      const effectCancel = () =>{
        const c1=  document.getElementById("nav-menu-hover-effect1")
        const para = document.getElementById("desktop-nav1");
        document.getElementById("desktop-nav1").style.display = "none"
      
            para.addEventListener('pointerenter', (event) => {
            document.getElementById("desktop-nav1").style.display = "block"
      
            // console.log('Pointer entered element');
            });
            para.addEventListener('pointerleave', (event) => {
            document.getElementById("desktop-nav1").style.display = "none"
      
            // console.log('Pointer entered element');
            });
        // document.getElementById("desktop-nav1").onPointerEnter(()=>{
        //     document.getElementById("desktop-nav1").style.display = "block"
      
        // })
      
        // console.log(c1,para)
      }
      
      const setStateChangeMenu = ()=>{
        if(!displayMenu){
            setdisplayMenu(true)
            document.getElementById("mobile-site-hide-show11").style.display = "block"
        }
        else{
            setdisplayMenu(false)
            document.getElementById("mobile-site-hide-show11").style.display = "none"
        }
      
        
      
      
      }
      
      const setChangeInit = ()=>{
        console.log("edfefef");
        document.getElementById("mobile-site-hide-show11").style.display="none"
        
      }
      
      
          const setChange = () =>{
              if(document.getElementById("get2").style.display === "none"){
                  document.getElementById("get1").classList.add("is-active");
                  // document.getElementById("kn2").style.top = "53%";
                  document.getElementById("get2").style.display = "block";
              }
              else{
                  document.getElementById("get1").classList.remove("is-active");
                  document.getElementById("get2").style.display = "none";
                  // document.getElementById("kn2").style.top = "19%";

              }
          }
      
          const setChange1 = (e) =>{
            if(e === 1 ){
              // document.getElementById("signUp").classList.add("right-panel-active");
              // document.getElementById("signIn").classList.remove("right-panel-active");
              document.getElementById("container1").classList.add("right-panel-active")
            }
            else{
              document.getElementById("container1").classList.remove("right-panel-active")
            }
          }
      
             
      
const setForGenerator1 = (e) =>{
  // console.log(props ,history.pu)
  if(e === false){
    
    const d = JSON.parse(localStorage.getItem("User")).ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
    console.log(d)
    if (d.length > 0){
      let a = new Date()
      let f= a.getDate() +" "+(a.getUTCMonth()+1)+ " "+a.getUTCFullYear()
      console.log(d , d[0].End[0]+""+d[0].End[1] , a.getDate(), d[0].End[3]+""+d[0].End[4] ,a.getUTCMonth()+1 ,d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] , a.getUTCFullYear())
      if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] > a.getUTCFullYear()     ){
      // if(d[0].End && d[0].End >= f){
       
       history.push("/user/bussiness/home1/"+User1._id)
     
      }
      else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear()){
        if( d[0].End[3]+""+d[0].End[4] >a.getUTCMonth()+1 ){
         history.push("/user/bussiness/home1/"+User1._id)

        }
        else{
          if(d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1){
            if( d[0].End[0]+""+d[0].End[1]>=a.getDate()){
             history.push("/user/bussiness/home1/"+User1._id)

            }
            else{
              
    // document.getElementById("myDiv1234").style.display= "block"
        history.push("/user/pro-feather")

              localStorage.setItem("request123",JSON.stringify(1) )
              
              // setTimeout(()=>{
                //  history.push("/user/request-for-pro-item")
                //   swal("Sorry First You Buy")
                // },1000)
                
              }
            }
            else{
              // document.getElementById("myDiv1234").style.display= "block"
                  history.push("/user/pro-feather")

              localStorage.setItem("request123",JSON.stringify(1) )
              // localStorage.setItem("request123",JSON.stringify(1) )
              // setTimeout(()=>{
                //  history.push("/user/request-for-pro-item")
            //   swal("Sorry First You Buy")
            // },1000)
            
          }
          }
        }
      else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear() && d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ){
          if( d[0].End[0]+""+d[0].End[1]>=a.getDate()){
           history.push("/user/bussiness/home1/"+User1._id)
        }
        else{
          // document.getElementById("myDiv1234").style.display= "block"
              history.push("/user/pro-feather")

          localStorage.setItem("request123",JSON.stringify(1) )
          //   localStorage.setItem("request123",JSON.stringify(1) )
          // setTimeout(()=>{
            //  history.push("/user/request-for-pro-item")
            //   swal("Sorry First You Buy")
            // },1000)
            
          }
        }
        else{
          // document.getElementById("myDiv1234").style.display= "block"
              history.push("/user/pro-feather")

          localStorage.setItem("request123",JSON.stringify(1) )
        // localStorage.setItem("request123",JSON.stringify(1) )
        // setTimeout(()=>{
        //  history.push("/user/request-for-pro-item")
        //   swal("Sorry First You Buy")
        // },1000)
  
      }
    }
    else{
      // document.getElementById("myDiv1234").style.display= "block"
          history.push("/user/pro-feather")

      localStorage.setItem("request123",JSON.stringify(1) )
      // setTimeout(()=>{
      //  history.push("/user/request-for-pro-item")
      //   swal("Sorry First You Buy")
      // },1000)
  }
  
  
  }
  }
      
const setForAnalytic = () =>{
  // console.log(props ,history.pu)
 
    
    const d = JSON.parse(localStorage.getItem("User")).ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
    console.log(d)
    if (d.length > 0){
      let a = new Date()
      let f= a.getDate() +" "+(a.getUTCMonth()+1)+ " "+a.getUTCFullYear()
      console.log(d , d[0].End[0]+""+d[0].End[1] , a.getDate(), d[0].End[3]+""+d[0].End[4] ,a.getUTCMonth()+1 ,d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] , a.getUTCFullYear())
      if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] > a.getUTCFullYear()     ){
      // if(d[0].End && d[0].End >= f){
       history.push("/user/analytics/"+User1._id)
     
      }
      else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear()){
        if( d[0].End[3]+""+d[0].End[4] >a.getUTCMonth()+1 ){
         history.push("/user/analytics/"+User1._id)

        }
        else{
          if(d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1){
            if( d[0].End[0]+""+d[0].End[1]>=a.getDate()){
             history.push("/user/analytics/"+User1._id)

            }
            else{
              
    // document.getElementById("myDivUmer12").style.display= "block"
        history.push("/user/pro-feather")

              localStorage.setItem("request123",JSON.stringify(1) )
              
              // setTimeout(()=>{
                //  history.push("/user/request-for-pro-item")
                //   swal("Sorry First You Buy")
                // },1000)
                
              }
            }
            else{
              // document.getElementById("myDivUmer12").style.display= "block"
                  history.push("/user/pro-feather")

              localStorage.setItem("request123",JSON.stringify(1) )
              // localStorage.setItem("request123",JSON.stringify(1) )
              // setTimeout(()=>{
                //  history.push("/user/request-for-pro-item")
            //   swal("Sorry First You Buy")
            // },1000)
            
          }
          }
        }
      else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear() && d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ){
          if( d[0].End[0]+""+d[0].End[1]>=a.getDate()){
           history.push("/user/analytics/"+User1._id)
        }
        else{
          // document.getElementById("myDivUmer12").style.display= "block"
              history.push("/user/pro-feather")

          localStorage.setItem("request123",JSON.stringify(1) )
          //   localStorage.setItem("request123",JSON.stringify(1) )
          // setTimeout(()=>{
            //  history.push("/user/request-for-pro-item")
            //   swal("Sorry First You Buy")
            // },1000)
            
          }
        }
        else{
          // document.getElementById("myDivUmer12").style.display= "block"
              history.push("/user/pro-feather")

          localStorage.setItem("request123",JSON.stringify(1) )
        // localStorage.setItem("request123",JSON.stringify(1) )
        // setTimeout(()=>{
        //  history.push("/user/request-for-pro-item")
        //   swal("Sorry First You Buy")
        // },1000)
  
      }
   
  
  
  }
  else{
    // document.getElementById("myDivUmer12").style.display= "block"
        history.push("/user/pro-feather")

    localStorage.setItem("request123",JSON.stringify(1) )
  }
  }
      
const setForConnection = () =>{
  // console.log(props ,history.pu)
 
    
    const d = JSON.parse(localStorage.getItem("User")).ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
    console.log(d)
    if (d.length > 0){
      let a = new Date()
      let f= a.getDate() +" "+(a.getUTCMonth()+1)+ " "+a.getUTCFullYear()
      console.log(d , d[0].End[0]+""+d[0].End[1] , a.getDate(), d[0].End[3]+""+d[0].End[4] ,a.getUTCMonth()+1 ,d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] , a.getUTCFullYear())
      if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] > a.getUTCFullYear()     ){
      // if(d[0].End && d[0].End >= f){
       history.push("/user/connect/myprofile/"+User1._id)
     
      }
      else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear()){
        if( d[0].End[3]+""+d[0].End[4] >a.getUTCMonth()+1 ){
         history.push("/user/connect/myprofile/"+User1._id)

        }
        else{
          if(d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1){
            if( d[0].End[0]+""+d[0].End[1]>=a.getDate()){
             history.push("/user/connect/myprofile/"+User1._id)

            }
            else{
              
    // document.getElementById("myDivUmer").style.display= "block"
        history.push("/user/pro-feather")

              localStorage.setItem("request123",JSON.stringify(1) )
              
              // setTimeout(()=>{
                //  history.push("/user/request-for-pro-item")
                //   swal("Sorry First You Buy")
                // },1000)
                
              }
            }
            else{
              // document.getElementById("myDivUmer").style.display= "block"
                  history.push("/user/pro-feather")

              localStorage.setItem("request123",JSON.stringify(1) )
              // localStorage.setItem("request123",JSON.stringify(1) )
              // setTimeout(()=>{
                //  history.push("/user/request-for-pro-item")
            //   swal("Sorry First You Buy")
            // },1000)
            
          }
          }
        }
      else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear() && d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ){
          if( d[0].End[0]+""+d[0].End[1]>=a.getDate()){
           history.push("/user/connect/myprofile/"+User1._id)
        }
        else{
          // document.getElementById("myDivUmer").style.display= "block"
              history.push("/user/pro-feather")

          localStorage.setItem("request123",JSON.stringify(1) )
          //   localStorage.setItem("request123",JSON.stringify(1) )
          // setTimeout(()=>{
            //  history.push("/user/request-for-pro-item")
            //   swal("Sorry First You Buy")
            // },1000)
            
          }
        }
        else{
          // document.getElementById("myDivUmer").style.display= "block"
              history.push("/user/pro-feather")

          localStorage.setItem("request123",JSON.stringify(1) )
        // localStorage.setItem("request123",JSON.stringify(1) )
        // setTimeout(()=>{
        //  history.push("/user/request-for-pro-item")
        //   swal("Sorry First You Buy")
        // },1000)
  
      }
   
  
  
  }
  else{
    // document.getElementById("myDivUmer").style.display= "block"
        history.push("/user/pro-feather")

    localStorage.setItem("request123",JSON.stringify(1) )
  }
  }
  const setRoter123 = ()=>{
    localStorage.setItem("request123",JSON.stringify(1) )
    // document.getElementById("myDiv1234").style.display= "none"
    history.push("/user/pro-feather")

        // history.push("/user/request-for-pro-item")
  
  }
  
  const setChanger1 = (e)=>{
    document.getElementById("myDiv1234").style.display= "none"
  }
  const setChanger8 = (e)=>{
    document.getElementById("myDivUmer12").style.display= "none"
  }
  const setChanger2 = (e)=>{
    document.getElementById("myDivUmer").style.display= "none"
  }
        return (
            < >
                  <div id="myDivUmer">
                {/* <img src={guides} alt=""  id="myDiv23456"/> */}
                <center>
                  <h1>
                     Build Your Self
                </h1>
                  </center>
                <center><p>
Capture leads using TapitDabit and favorite, group, sort and manage them all from here. Start building your connections now                  </p></center>
                  <center>
                  <button class="btn btn-dark F23 f45" onClick={()=>setRoter123()}>Buy Now </button>
                  </center>
                <ClearIcon id="myDiv34567"  style={{color : "black",fontSize : "30px",position : "absolute" , right: "10%",top : "10%" , zIndex: "2"}} onClick={()=>setChanger2()}/>
            </div> 
            <div id="myDivUmer12">
                {/* <img src={guides} alt=""  id="myDiv23456"/> */}
                <center>
                  <h1>
                    Analytics
                </h1>
                  </center>
                <center><p>
                Unlock all links with Pro to see insights of your account. <br />
Tap Buy now  to upgrade.
          </p></center>
                  <center>
                  <button class="btn btn-dark F23 f45" onClick={()=>setRoter123()}>Buy Now </button>
                  </center>
                <ClearIcon id="myDiv3456767"  style={{color : "black",fontSize : "30px",position : "absolute" , right: "10%",top : "10%" , zIndex: "2"}} onClick={()=>setChanger8()}/>
            </div> 
              <div id="myDiv1234">
                {/* <img src={guides} alt=""  id="myDiv23456"/> */}
                <center>
                  <h1>
                      Personal + Bussiness
                </h1>
                  </center>
                <center><p>
                  Build both a personal and bussiness profile and instantly switch between them whenever you'd like.
                  </p></center>
                  <center>
                  <button class="btn btn-dark F23 f45" onClick={()=>setRoter123()}>Buy Now </button>
                  </center>
                <ClearIcon id="myDiv34567"  style={{color : "black",fontSize : "30px",position : "absolute" , right: "10%",top : "10%" , zIndex: "2"}} onClick={()=>setChanger1()}/>
            </div>
            {window.location.href.split("/")[3] !== "user" ||  (JSON.parse(localStorage.getItem("UserProp"))) ||   User1.uid === null ? 
            <>
  <header  ref={myRef}  id="Close-the-header">
  {/* Header desktop */}
  <div className="container-menu-desktop fix-menu-desktop">
    {/* Topbar */}
    <div className="top-bar">
      <div className="content-topbar flex-sb-m1 h-full container">
        <div className="left-top-bar" style={{textAlign: "center"}}>
       <b style={{fontSize : "18px"}}>
       <center>{MainLine} 
            </center> </b> 
        </div>
      
      </div>
    </div>

    <div className="wrap-menu-desktop" id="head1" style={{backgroundColor :"white"}}>
      <nav className="limiter-menu-desktop container">
        {/* Logo desktop */}
           {/* {User1 ? 
            <Link to={"/user/home1/"+User1.uid} className="logo">
              <img src={l2} alt="IMG-LOGO" style={{maxWidth: "148px" ,height: "68px"}} />
            </Link>
            :
""
              }     */}
{/* {window.location.href.split("/")[3] !== "user" || User1.uid === null ? */}
            <Link to="/" className="logo">
              <img src={l2} alt="IMG-LOGO" style={{maxWidth: "148px" ,height: "68px"}} />
            </Link> 
{/*  : <Link to={"/user/home1/"+User1.uid} className="logo">
 <img src={l2} alt="IMG-LOGO" style={{maxWidth: "148px" ,height: "68px"}} />
 </Link>
} */}
        	
        {/* Menu desktop */}
        <div className="menu-desktop">
          <ul className="main-menu">
            {/* <li className="active-menu">
              <Link to="/">Home</Link>
            </li> */}
            <li>
              <Link to="/login">Login</Link>
        </li>
            <li>
        <Link to="/">Home</Link>
        </li>
            <li>
              <Link to="/all-shop">Shop All</Link>
              <ul className="sub-menu">
                {MainCategories.map((res,i)=>{
                  return (
                    <li><Link to={"/categories/"+res.MainCategories+"/product"}>{res.MainCategories}</Link></li>

                  )
                })

                }
                    <li><Link to={"/all-shop"}>All Products</Link></li>

                {/* <li><Link to="home-02.html">Homepage 2</a></li>
                <li><Link to="home-03.html">Homepage 3</a></li> */}
              </ul>
            </li>
            {/* <li className="label1" data-label1="hot"> */}
            <li>
              <Link to="/tapkropro">tapitdabit Pro</Link>
            </li>
            {/* <li>
              <a href="blog.html">Blog</a>
            </li> */}
            <li>
              <Link to={"/categories/Custom Products/product"}>Custom Product</Link>
            </li>
            <li>
              <Link to="/ambassador-login">Ambassadors</Link>
            </li>
            <li>
              <Link to="/enterprise">Enterprise</Link>
            </li>
            {/* <li>
              <Link to="/tutorial">Tutorial</Link>
            </li> */}
            <li>
              <Link to="/help">help</Link>
            </li>
          </ul>
        </div>	
        {/* Icon header */}
        <div className="wrap-icon-header flex-w flex-r-m">
          {/* <div className="icon-header-item cl2 hov-cl1 trans-04 p-l-22 p-r-11 js-show-modal-search">
            <i className="zmdi zmdi-search" />
          </div> */}
         <Link to="/cart">
          <div className="icon-header-item cl2 hov-cl1 trans-04 p-l-22 p-r-11 icon-header-noti js-show-cart"  data-notify={DataPart2.length}> <i className="zmdi zmdi-shopping-cart" />
          </div>
         </Link>
        
         {/* <ShoppingCartIcon className="icon icon-shopping_cart change-header-color change-header-color1"/>
          <span className="count">{DataPart2.length ? DataPart2.length : 0}</span> */}
          {/* <a href="#" className="dis-block icon-header-item cl2 hov-cl1 trans-04 p-l-22 p-r-11 icon-header-noti" data-notify={0}>
            <i className="zmdi zmdi-favorite-outline" />
          </a> */}
        </div>
      </nav>
    </div>	
  </div>
  {/* Header Mobile */}
  <div className="wrap-header-mobile">
    {/* Logo moblie */}		
    <div className="logo-mobile">
      {/* <Link to="/">          <img src={l2} className="logo-mobile1" alt="IMG-LOGO" style={{maxWidth: "118px" ,height: "68px"}} />
</Link> */}


{/* {User1 ? 
            <Link to={"/user/home1/"+User1.uid} className="logo">
  <img src={l2} className="logo-mobile1" alt="IMG-LOGO" style={{maxWidth: "118px" ,height: "68px"}} />            </Link>
            : */}
            <Link to="/">          
              <img src={l2} className="logo-mobile1" alt="IMG-LOGO" style={{maxWidth: "82px" ,height: "67px"}} />
            </Link>

              {/* }  */}



    </div>
    {/* Icon header */}
    <div className="wrap-icon-header flex-w flex-r-m m-r-15">
      {/* <div className="icon-header-item cl2 hov-cl1 trans-04 p-r-11 js-show-modal-search">
        <i className="zmdi zmdi-search" />
      </div> */}
 
      <Link to="/cart">
      <div className="icon-header-item cl2 hov-cl1 trans-04 p-r-11 p-l-10 icon-header-noti js-show-cart" data-notify={DataPart2.length} >
        <i className="zmdi zmdi-shopping-cart" />
      </div>
      </Link>
      
      {/* {! DataPart2?
        <Link to="/cart">
      <div className="icon-header-item cl2 hov-cl1 trans-04 p-r-11 p-l-10 icon-header-noti js-show-cart" data-notify={0} >
        <i className="zmdi zmdi-shopping-cart" />
      </div>
      </Link>
      :""
    } */}
      {/* <a href="#" className="dis-block icon-header-item cl2 hov-cl1 trans-04 p-r-11 p-l-10 icon-header-noti" data-notify={0}>
        <i className="zmdi zmdi-favorite-outline" />
      </a> */}
    </div>
    {/* Button show menu */}
    <div className="btn-show-menu-mobile hamburger hamburger--squeeze" id="get1" onClick={()=>setChange()}>
      <span className="hamburger-box">
        <span className="hamburger-inner" />
      </span>
    </div>
  </div>
  {/* Menu Mobile */}
  <div className="menu-mobile" id="get2">
    <ul className="topbar-mobile">
      <li>
        <div className="left-top-bar" style={{textAlign: "center"}}>
          <center>{MainLine} 
            </center> 
        </div>
      </li>
      <li>
        {/* <div className="right-top-bar flex-w h-full">
          <a href="#" className="flex-c-m p-lr-10 trans-04">
            Help &amp; FAQs
          </a>
          <a href="#" className="flex-c-m p-lr-10 trans-04">
            My Account
          </a>
          <a href="#" className="flex-c-m p-lr-10 trans-04">
            EN
          </a>
          <a href="#" className="flex-c-m p-lr-10 trans-04">
            USD
          </a>
        </div> */}
      </li>
    </ul>
    <ul className="main-menu-m" style={{zIndex : "99999"}}>
    <li>
              <Link to="/login" onClick={()=>setChange()}>Login</Link>
        </li>
      <li>
        <Link to="/" onClick={()=>setChange()}>Home</Link>
        {/* <ul className="sub-menu-m">
          <li><a href="index.html">Homepage 1</a></li>
          <li><a href="home-02.html">Homepage 2</a></li>
          <li><a href="home-03.html">Homepage 3</a></li>
        </ul>
        <span className="arrow-main-menu-m">
          <i className="fa fa-angle-right" aria-hidden="true" />
        </span> */}
      </li>
      <li>
      <Link to="/all-shop" onClick={()=>setChange()}>Shop All</Link>
        <ul className="sub-menu-m" id="88">
                {MainCategories.map((res,i)=>{
                  return (
                    <li><Link to={"/categories/"+res.MainCategories+"/product"} onClick={()=>setChange()}>{res.MainCategories}</Link></li>
                  )
                })
                }
                 <li><Link to={"/all-shop"}>All Products</Link></li>

              </ul>
              <span className="arrow-main-menu-m" id="66" onClick={()=>setChanger7()}>
          <i className="fa fa-angle-right" aria-hidden="true" />
        </span>
      </li>
      <li>
              <Link to="/tapkropro" onClick={()=>setChange()}>tapitdabit Pro</Link>
            </li>
            {/* <li>
              <a href="blog.html">Blog</a>
            </li> */}
            <li>
              <Link to={"/categories/Custom Products/product"} onClick={()=>setChange()}>Custom Product</Link>
            </li>
            <li>
              <Link to="/ambassador-login" onClick={()=>setChange()}>Ambassadors</Link>
            </li>
            <li>
              <Link to="/enterprise" onClick={()=>setChange()}>Enterprise</Link>
            </li>
            {/* <li>
              <Link to="/tutorial" onClick={()=>setChange()}>Tutorial</Link>
            </li> */}
            <li>
              <Link to="/help" onClick={()=>setChange()}>help</Link>
            </li>
    </ul>
  </div>
  {/* Modal Search */}
  <div className="modal-search-header flex-c-m trans-04 js-hide-modal-search">
    <div className="container-search-header">
      <button className="flex-c-m btn-hide-modal-search trans-04 js-hide-modal-search">
        {/* <img src={l2} alt="CLOSE" className="logo-mobile1"/> */}
        {/* {window.location.href.split("/")[3] !== "user"|| User1.uid === null? */}
            <Link to="/" className="logo">
                     <img src={l2} alt="CLOSE" className="logo-mobile1"/>

            </Link> 
{/* : <Link to={"/user/home1/"+User1.uid} className="logo">
        <img src={l2} alt="CLOSE" className="logo-mobile1"/>
</Link>
} */}
      </button>
      <form className="wrap-search-header flex-w p-l-15">
        {/* <button className="flex-c-m trans-04">
          <i className="zmdi zmdi-search" />
        </button> */}
        <input className="plh3" type="text" name="search" placeholder="Search..." />
      </form>
    </div>
  </div>
</header>
{/* Cart */}
<div className="wrap-header-cart js-panel-cart">
  <div className="s-full js-hide-cart" />
  <div className="header-cart flex-col-l p-l-65 p-r-25">
    <div className="header-cart-title flex-w flex-sb-m p-b-8">
      <span className="mtext-103 cl2">
        Your Cart
      </span>
      <div className="fs-35 lh-10 cl2 p-lr-5 pointer hov-cl1 trans-04 js-hide-cart">
        <i className="zmdi zmdi-close" />
      </div>
    </div>
    <div className="header-cart-content flex-w js-pscroll">
      <ul className="header-cart-wrapitem w-full">
        <li className="header-cart-item flex-w flex-t m-b-12">
          <div className="header-cart-item-img">
            <img src="images/item-cart-01.jpg" alt="IMG" />
          </div>
          <div className="header-cart-item-txt p-t-8">
            <a href="#" className="header-cart-item-name m-b-18 hov-cl1 trans-04">
              White Shirt Pleat
            </a>
            <span className="header-cart-item-info">
              1 x $19.00
            </span>
          </div>
        </li>
        <li className="header-cart-item flex-w flex-t m-b-12">
          <div className="header-cart-item-img">
            <img src="images/item-cart-02.jpg" alt="IMG" />
          </div>
          <div className="header-cart-item-txt p-t-8">
            <a href="#" className="header-cart-item-name m-b-18 hov-cl1 trans-04">
              Converse All Star
            </a>
            <span className="header-cart-item-info">
              1 x $39.00
            </span>
          </div>
        </li>
        <li className="header-cart-item flex-w flex-t m-b-12">
          <div className="header-cart-item-img">
            <img src="images/item-cart-03.jpg" alt="IMG" />
          </div>
          <div className="header-cart-item-txt p-t-8">
            <a href="#" className="header-cart-item-name m-b-18 hov-cl1 trans-04">
              Nixon Porter Leather
            </a>
            <span className="header-cart-item-info">
              1 x $17.00
            </span>
          </div>
        </li>
      </ul>
      <div className="w-full">
        <div className="header-cart-total w-full p-tb-40">
          Total: $75.00
        </div>
        <div className="header-cart-buttons flex-w w-full">
          <a href="shoping-cart.html" className="flex-c-m stext-101 cl0 size-107 bg3 bor2 hov-btn3 p-lr-15 trans-04 m-r-8 m-b-10">
            View Cart
          </a>
          <a href="shoping-cart.html" className="flex-c-m stext-101 cl0 size-107 bg3 bor2 hov-btn3 p-lr-15 trans-04 m-b-10">
            Check Out
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
</>


            :
           































            <>
            <header  ref={myRef1}>
            {/* Header desktop */}
            <div className="container-menu-desktop fix-menu-desktop">
              {/* Topbar */}
           
          
              <div className="wrap-menu-desktop mi2" id="head12" style={{backgroundColor :"white"}}>
                <nav className="limiter-menu-desktop container">
                  {/* Logo desktop */}		
                  {/* {User1 ?
                  <Link to={User1.DisPersonal ?  "/user/home1/"+User1.uid:"/user/bussiness/home1/"+User1._id} className="logo">
                  <img src={l2} alt="IMG-LOGO" style={{width: "148px" ,height: "68px"}} />
                  </Link>
                  :""} */}
                  {window.location.href.split("/")[3] !== "user"|| User1.uid === null ?
            <Link to="/" className="logo">
              <img src={l2} alt="IMG-LOGO" style={{maxWidth: "148px" ,height: "68px"}} />
            </Link> 
: <Link to={"/user/home1/"+User1.uid} className="logo">
<img src={l2} alt="IMG-LOGO" style={{maxWidth: "148px" ,height: "68px"}} />
</Link>
}



                  {/* Menu desktop */}
                  {User1 ?

                  <div className="menu-desktop">
                    <ul className="main-menu">
                      <li className="active-menu">
                      {User1.DisPersonal ? 

<Link to={"/user/home1/"+User1._id}>Dashboard</Link>
:
<Link to={"/user/bussiness/home1/"+User1._id}>Dashboard</Link>

  }                      </li>
                      <li>
                      {User1.DisPersonal ? 

<Link to={"/user/home/"+User1._id}>Add Link</Link>
:
<Link to={"/user/bussiness/home/"+User1._id}>Add Link</Link>

  }    
                      </li>
                      <li>
                        <Link to={"/user/"+User1.uid}>profile Preview</Link>
                      </li>
                      {/* {
                        User1.DisPersonal ?
<li>
                        <Link to={"/user/editProfile/sort-delete/"+User1.uid}>Sorting Area</Link>
                      </li>
                        :
<li>
                        <Link to={"/user/bussiness/editProfile/sort-delete/"+User1.uid}>Sorting Area</Link>
                      </li>
                      } */}
                      
                       <li>
                        <Link onClick={()=>setForGenerator1(false)}>Bussiness Account</Link>
                        {/* <Link to={"/user/bussiness/home1/"+User1._id}>Bussiness Account</Link> */}
                      </li>
                      <li>
                        <Link onClick={()=>setForAnalytic()}>Analytics</Link>
                        {/* <Link to={"/user/analytics/"+User1._id}>Analytics</Link> */}
                      </li>
                      <li>
                        <Link onClick={()=>setForConnection()}>Connections</Link>
                        {/* <Link to={"/user/connect/myprofile/"+User1._id}>Connections</Link> */}
                      </li>
                      <li>
                        <Link onClick={()=>clickLogin()}>Logout</Link>
                      </li>
                    </ul>
                  </div>	
                  : ""

                  }




                  {/* Icon header */}
                  
                </nav>
              </div>	
            </div>
            {/* Header Mobile */}
            <div className="wrap-header-mobile">
              {/* Logo moblie */}		
              <div className="logo-mobile" style={{marginLeft: "33%"}}>
              {User1?
                <Link  to={User1.DisPersonal ?  "/user/home1/"+User1.uid:"/user/bussiness/home1/"+User1._id}>          <img src={l2} className="logo-mobile1" alt="IMG-LOGO" style={{width: "118px" ,height: "68px"}} />
</Link>
:""}
              </div>
              {/* Icon header */}
              
              {/* Button show menu */}
              <div className="btn-show-menu-mobile hamburger hamburger--squeeze" id="get1" onClick={()=>setChange()}>
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </div>
            </div>
            {/* Menu Mobile */}
            <div className="menu-mobile" id="get2">
              
              {User1 ?

              <ul className="main-menu-m">
              <li className="active-menu">
                {User1.DisPersonal ? 

              <Link to={"/user/home1/"+User1._id}>Dashboard</Link>
              :
              <Link to={"/user/bussiness/home1/"+User1._id}>Dashboard</Link>

                }
                      </li>
                      <li>
                      {User1.DisPersonal ? 

<Link to={"/user/home/"+User1._id}>Add Link</Link>
:
<Link to={"/user/bussiness/home/"+User1._id}>Add Link</Link>

  }                         </li>
                      <li>
                        <Link to={"/user/"+User1.uid}>profile Preview</Link>
                      </li>
                      {/* {
                        User1.DisPersonal ?
<li>
                        <Link to={"/user/editProfile/sort-delete/"+User1.uid}>Sorting Area</Link>
                      </li>
                        :
<li>
                        <Link to={"/user/bussiness/editProfile/sort-delete/"+User1.uid}>Sorting Area</Link>
                      </li>
                      } */}
                      {/* <li>
                        <a href="blog.html">Blog</a>
                      </li> */}
                      <li>
                        <Link  onClick={()=>setForGenerator1(false)}>Bussiness Account</Link>
                      </li>
                      <li>
                      <Link onClick={()=>setForAnalytic()}>Analytics</Link>
                        {/* <Link to={"/user/analytics/"+User1._id}>Analytics</Link> */}
                      </li>
                      <li>
                      <Link onClick={()=>setForConnection()}>Connections</Link>
                      </li>
                      <li>
                        <Link onClick={()=>clickLogin()}>Logout</Link>
                      </li>


               
              </ul>
              : ""

              }
            </div>
            {/* Modal Search */}
            <div className="modal-search-header flex-c-m trans-04 js-hide-modal-search">
              <div className="container-search-header">
                <button className="flex-c-m btn-hide-modal-search trans-04 js-hide-modal-search">
                  {/* <img src={l2} alt="CLOSE" className="logo-mobile1"/> */}
                  {window.location.href.split("/")[3] !== "user" || User1.uid === null?
            <Link to="/" className="logo">
                     <img src={l2} alt="CLOSE" className="logo-mobile1"/>

            </Link> 
: <Link to={"/user/home1/"+User1.uid} className="logo">
        <img src={l2} alt="CLOSE" className="logo-mobile1"/>
</Link>
}
                </button>
                <form className="wrap-search-header flex-w p-l-15">
                  {/* <button className="flex-c-m trans-04">
                    <i className="zmdi zmdi-search" />
                  </button> */}
                  {/* <input className="plh3" type="text" name="search" placeholder="Search..." /> */}
                </form>
              </div>
            </div>
          </header>
          {/* Cart */}
          <div className="wrap-header-cart js-panel-cart">
            <div className="s-full js-hide-cart" />
            <div className="header-cart flex-col-l p-l-65 p-r-25">
              <div className="header-cart-title flex-w flex-sb-m p-b-8">
                <span className="mtext-103 cl2">
                  Your Cart
                </span>
                <div className="fs-35 lh-10 cl2 p-lr-5 pointer hov-cl1 trans-04 js-hide-cart">
                  <i className="zmdi zmdi-close" />
                </div>
              </div>
              <div className="header-cart-content flex-w js-pscroll">
                <ul className="header-cart-wrapitem w-full">
                  <li className="header-cart-item flex-w flex-t m-b-12">
                    <div className="header-cart-item-img">
                      <img src="images/item-cart-01.jpg" alt="IMG" />
                    </div>
                    <div className="header-cart-item-txt p-t-8">
                      <a href="#" className="header-cart-item-name m-b-18 hov-cl1 trans-04">
                        White Shirt Pleat
                      </a>
                      <span className="header-cart-item-info">
                        1 x $19.00
                      </span>
                    </div>
                  </li>
                  <li className="header-cart-item flex-w flex-t m-b-12">
                    <div className="header-cart-item-img">
                      <img src="images/item-cart-02.jpg" alt="IMG" />
                    </div>
                    <div className="header-cart-item-txt p-t-8">
                      <a href="#" className="header-cart-item-name m-b-18 hov-cl1 trans-04">
                        Converse All Star
                      </a>
                      <span className="header-cart-item-info">
                        1 x $39.00
                      </span>
                    </div>
                  </li>
                  <li className="header-cart-item flex-w flex-t m-b-12">
                    <div className="header-cart-item-img">
                      <img src="images/item-cart-03.jpg" alt="IMG" />
                    </div>
                    <div className="header-cart-item-txt p-t-8">
                      <a href="#" className="header-cart-item-name m-b-18 hov-cl1 trans-04">
                        Nixon Porter Leather
                      </a>
                      <span className="header-cart-item-info">
                        1 x $17.00
                      </span>
                    </div>
                  </li>
                </ul>
                <div className="w-full">
                  <div className="header-cart-total w-full p-tb-40">
                    Total: $75.00
                  </div>
                  <div className="header-cart-buttons flex-w w-full">
                    <a href="shoping-cart.html" className="flex-c-m stext-101 cl0 size-107 bg3 bor2 hov-btn3 p-lr-15 trans-04 m-r-8 m-b-10">
                      View Cart
                    </a>
                    <a href="shoping-cart.html" className="flex-c-m stext-101 cl0 size-107 bg3 bor2 hov-btn3 p-lr-15 trans-04 m-b-10">
                      Check Out
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </>
          














            }
        
            </>
        )
}
export default Header