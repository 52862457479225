import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"
import swal from 'sweetalert';

import ClearIcon from '@material-ui/icons/Clear';


import "react-multi-carousel/lib/styles.css";
import "react-multi-carousel/lib/styles.css";
import "./../assets/css/main.css"
import "./../assets/css/util.css"
import "./../assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css"


import "./../assets/vendor/css-hamburgers/hamburgers.min.css"

import "./../assets/fonts/iconic/css/material-design-iconic-font.min.css"
import "./../assets/vendor/bootstrap/css/bootstrap.min.css"
import "./../assets/vendor/select2/select2.min.css"
import "./../assets/vendor/slick/slick.css"
import "./../assets/vendor/perfect-scrollbar/perfect-scrollbar.css"


import ln1 from "./../assets/images/svg/social/f linkedin.svg"
import tw1 from "./../assets/images/svg/social/f twitter.svg"
import in1 from "./../assets/images/svg/social/f instagram.svg"
import pi1 from "./../assets/images/svg/social/f pinterest.svg"
import tt1 from "./../assets/images/svg/social/twitch (1).svg"
import tk1 from "./../assets/images/svg/social/tiktok.svg"
import you1 from "./../assets/images/svg/social/youtube (2).svg"
import sc1 from "./../assets/images/svg/social/snapchat.svg"
import fb1 from "./../assets/images/svg/social/f facebook.svg"


import bk1 from "./../assets/images/svg/Payments/bank (9).svg"
import ca1 from "./../assets/images/svg/Payments/cashapp.svg"
import ep1 from "./../assets/images/svg/Payments/easypaisa.png"
import jc1 from "./../assets/images/svg/Payments/SELECT JAZZ CASH LOGO/jazzcash.png"
import ve1 from "./../assets/images/svg/Payments/venmo.svg"
import pp1 from "./../assets/images/svg/Payments/paypal (1).svg"



import cu1 from "./../assets/images/svg/MORE/custom link.svg"
import lt1 from "./../assets/images/svg/MORE/linktree-2.svg"
import vi1 from "./../assets/images/svg/MORE/video.svg"
import we1 from "./../assets/images/svg/MORE/WEBSITE.svg"


import call1 from "./../assets/images/svg/cotact info/call.svg"
import cd1 from "./../assets/images/svg/cotact info/ccard.svg"
import lo1 from "./../assets/images/svg/cotact info/location.svg"
import mail1 from "./../assets/images/svg/cotact info/mail.svg"
import wa1 from "./../assets/images/svg/cotact info/whatapps.svg"
import mess1 from "./../assets/images/svg/cotact info/message (2).svg"





const Home = ( props ) => {
    
    const [User1 ,  setUser1 ] = useState({})
    const [Name ,  setName ] = useState("")
    
    const [Image ,  setImage ] = useState("")


    useEffect(() =>{
      window.scrollTo(0, 0)

      if(! JSON.parse(localStorage.getItem("User"))){
        props.match.history.push("/login")
      }else{
        localStorage.removeItem("UserProp")

        fetch("https://tapitdabit-backend.vercel.app/allUser/"+JSON.parse(localStorage.getItem("User"))._id,{
          method: "GET",
           headers :  {
           "Content-Type" : "application/json" , 
       } ,
      })
      .then(res5=>res5.json())
      .then(res6=>{
        const data = {
          ...res6 , AllBuss : [...res6.SocialBuss,...res6.ContactBuss]
        }
        localStorage.setItem("User" ,JSON.stringify(data)) 
      })

        localStorage.setItem("VenderWorkingThose" ,JSON.stringify(123)) 
        setUser1(JSON.parse(localStorage.getItem("User")))
        setName(JSON.parse(localStorage.getItem("User")).NameBuss)
      }
  // localStorage.setItem("User" , JSON.stringify(res2) )
  return () => {
    document.getElementById("get1").classList.remove("is-active");
    document.getElementById("get2").style.display = "none";
  }
    },[])

    useEffect( () => () => localStorage.removeItem("VenderWorkingThose"), [] );

    // const setChange = () =>{
    //     if(document.getElementById("get2").style.display === "none"){
    //         document.getElementById("get1").classList.add("is-active");
    //         document.getElementById("get2").style.display = "block";
    //     }
    //     else{
    //         document.getElementById("get1").classList.remove("is-active");
    //         document.getElementById("get2").style.display = "none";
    //     }
    // }

    // const setChange1 = (e) =>{
    //   if(e === 1 ){
    //     // document.getElementById("signUp").classList.add("right-panel-active");
    //     // document.getElementById("signIn").classList.remove("right-panel-active");
    //     document.getElementById("container1").classList.add("right-panel-active")
    //   }
    //   else{
    //     document.getElementById("container1").classList.remove("right-panel-active")
    //   }
    // }




// const setChangeData = () =>{
  

//   console.log(Name,gender,area,date)
//   // const data = new FormData()
//   //                             data.append("file", Image)
//   //                             data.append("upload_preset", "commerce")
//   //                             data.append("cloud_name", "freedeveloperss123")
//   //                             fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
//   //                                 method : "POST",
//   //                                 body : data , 
//   //                             })
//   //                             .then(res=>res.json())
//   //                             .then((res2)=>{
//     fetch("https://tapitdabit-backend.vercel.app/ProfleChangeBussiness",{
//       method: 'POST' , 
//       headers :  {
//         "Content-Type" : "application/json" , 
//       } , 
//       body : JSON.stringify({
//         id : User1._id ,
//         Name  ,
//         image : Image  ,
//         area  ,
//       })
//     })
//     .then((res)=>res.json())
//     .then((res2)  =>{
//         console.log(res2)
//         // if (res2 !== null && !res2.Error  ){
//         //   if(!res2.isSettings){
//           swal("Successfully Save your Profile!");
//           // localStorage.setItem("User" , JSON.stringify(res2) )
//           //     props.history.push("/settings-profile-vender")
          
        
//       // console.log(res2)
//     })
//     .catch((err)=>console.log(err))
//                               // })

// }


// const  setPassShow = (e)=>{
//   setdispaly(e)
// }


const  settochange = (e)=>{
  console.log(e)
  localStorage.setItem("the",JSON.stringify(e))
  props.history.push("/user/bussiness/editProfile/"+User1.uid)

}

// const  settochange1 = (e)=>{
//   console.log(e)
//   localStorage.setItem("the1",JSON.stringify(e))
//   props.history.push("/user/bussiness/editProfile123/"+User1.uid)

// }

const  settochange3 = (e)=>{
  console.log(e)
  localStorage.setItem("the",JSON.stringify(e))
  props.history.push("/user/bussiness/editProfile/"+User1.uid)

}
const  setContactCard = (e)=>{
  console.log(e)
  localStorage.setItem("the1",JSON.stringify(e))
  props.history.push("/user/bussiness/editProfile/contactcard/"+User1.uid)

}
const  settochange44 = (e)=>{
  console.log(e)
  localStorage.setItem("the3",JSON.stringify(e))
  props.history.push("/user/bussiness/editProfile/linktree/"+User1.uid)

}







// function getLocation() {
//   // var x = document.getElementById("demo");
//   if (navigator.geolocation) {
//     navigator.geolocation.getCurrentPosition(showPosition);
//   } else { 
//     swal("Not Access the location")
//     // x.innerHTML = "Geolocation is not supported by this browser.";
//   }
// }

// function showPosition(position) {
//   // var x = document.getElementById("demo");
//   // x.innerHTML = "Latitude: " + position.coords.latitude + 
//   // "<br>Longitude: " + position.coords.longitude;
//   fetch("https://tapitdabit-backend.vercel.app/createaddressLinkBussiness",{
//                                           method: "POST",
//                                           headers :  {
//                                               "Content-Type" : "application/json" , 
//                                           } ,
//                                           body : JSON.stringify({
//                                             name  : "location",
//                                             // name1  : position.coords.longitude,
//                                             link : "https://www.google.com/maps/@"+position.coords.longitude+","+position.coords.latitude+",15z",
//                                             id : User1._id
//                                               // MainLine2 :mainline ,
//                                           })
//                                       })
//                                       .then(res11=>res11.json())
//                                       .then((res12)=>{ 
//                                           if(!res12.Error){
//                                             swal("Successfully Upload your Product!");
//                                             this.setState({
//                                                 email: "",
//                                         })

//                                       }
//                                       else{
//                                       swal(res12.Error);
//                                       }
//                                   })
//                                   .catch(err=>{
//                                     swal("Successfully Upload your Location!");
//                                       })
// }



const EmbedVideo= () =>{
  props.history.push("/user/bussiness/editProfile/emdedvideo/"+User1.uid)
}


// const setRouting1 = (e)=>{
//   if(e === 1){
//     if(JSON.parse(localStorage.getItem("User")).DisPersonal)
//     props.history.push("/user/home1/"+User1._id)
//     else 
//     props.history.push("/user/bussiness/home1/"+User1._id)
//   }
//   else if(e === 4){
//     if(JSON.parse(localStorage.getItem("User")).DisPersonal)
//     props.history.push("/user/editProfile/sort-delete/"+User1.uid)
//     else
//     props.history.push("/user/bussiness/editProfile/sort-delete/"+User1.uid)
//   }
//   else if(e === 3){
//     // props.history.push("/user/analytics/"+User1._id)   

//     const d =  JSON.parse(localStorage.getItem("User")).ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
//     console.log(d)
    
      
//     if (d.length > 0){
//       let a = new Date()
//       let f= a.getDate() +" "+(a.getUTCMonth()+1)+ " "+a.getUTCFullYear()
//       console.log(d[0].End[0]+""+d[0].End[1] >= a.getDate(), d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ,d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear())
//       if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] > a.getUTCFullYear()     ){
//       // if(d[0].End && d[0].End >= f){
//     props.history.push("/user/analytics/"+User1._id)   

//       }
//       else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear()){
//         if( d[0].End[3]+""+d[0].End[4] >a.getUTCMonth()+1 ){
//       props.history.push("/user/analytics/"+User1._id)   

//         }
//         else{
//           if(d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1){
//             if( d[0].End[0]+""+d[0].End[1] >=a.getDate()){
//           props.history.push("/user/analytics/"+User1._id)   

//             }
//             else{
//               document.getElementById("myDivUmer12").style.display= "block"

//               // localStorage.setItem("request123",JSON.stringify(1) )
//               // props.history.push("/user/request-for-pro-item")
//               // swal("Sorry First You Buy")
  
//             }
//           }
//           else{
//             document.getElementById("myDivUmer12").style.display= "block"

//             // localStorage.setItem("request123",JSON.stringify(1) )
//             //   props.history.push("/user/request-for-pro-item")
//             //   swal("Sorry First You Buy")
  
//             }
//           }
//         }
//       else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear() && d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ){
//           if( d[0].End[0]+""+d[0].End[1] >=a.getDate()){
//         props.history.push("/user/analytics/"+User1._id)   

//         }
//         else{
//           document.getElementById("myDivUmer12").style.display= "block"

//           // localStorage.setItem("request123",JSON.stringify(1) )
//           // props.history.push("/user/request-for-pro-item")
//           // swal("Sorry First You Buy")
  
//         }
//       }
//       else{
//         document.getElementById("myDivUmer12").style.display= "block"

//         // localStorage.setItem("request123",JSON.stringify(1) )
//         //   props.history.push("/user/request-for-pro-item")
//         //   swal("Sorry First You Buy")
  
//       }
//     }
//     else{
//       document.getElementById("myDivUmer12").style.display= "block"

//       // localStorage.setItem("request123",JSON.stringify(1) )
//       //   props.history.push("/user/request-for-pro-item")
//       //   swal("Sorry First You Buy")
//   }
//   }
//   else if(e === 2){


//     const d =  JSON.parse(localStorage.getItem("User")).ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
//     console.log(d)
    
      
//     if (d.length > 0){
//       let a = new Date()
//       let f= a.getDate() +" "+(a.getUTCMonth()+1)+ " "+a.getUTCFullYear()
//       console.log(d[0].End[0]+""+d[0].End[1] >= a.getDate(), d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ,d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear())
//       if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] > a.getUTCFullYear()     ){
//       // if(d[0].End && d[0].End >= f){
//         props.history.push("/user/connect/myprofile/"+User1.uid)

//       }
//       else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear()){
//         if( d[0].End[3]+""+d[0].End[4] >a.getUTCMonth()+1 ){
//           props.history.push("/user/connect/myprofile/"+User1.uid)

//         }
//         else{
//           if(d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1){
//             if( d[0].End[0]+""+d[0].End[1] >=a.getDate()){
//               props.history.push("/user/connect/myprofile/"+User1.uid)

//             }
//             else{
//               document.getElementById("myDivUmer").style.display= "block"

//               // localStorage.setItem("request123",JSON.stringify(1) )
//               // props.history.push("/user/request-for-pro-item")
//               // swal("Sorry First You Buy")
  
//             }
//           }
//           else{
//             document.getElementById("myDivUmer").style.display= "block"

//             // localStorage.setItem("request123",JSON.stringify(1) )
//             //   props.history.push("/user/request-for-pro-item")
//             //   swal("Sorry First You Buy")
  
//             }
//           }
//         }
//       else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear() && d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ){
//           if( d[0].End[0]+""+d[0].End[1] >=a.getDate()){
//             props.history.push("/user/connect/myprofile/"+User1.uid)

//         }
//         else{
//           document.getElementById("myDivUmer").style.display= "block"

//           // localStorage.setItem("request123",JSON.stringify(1) )
//           // props.history.push("/user/request-for-pro-item")
//           // swal("Sorry First You Buy")
  
//         }
//       }
//       else{
//         document.getElementById("myDivUmer").style.display= "block"

//         // localStorage.setItem("request123",JSON.stringify(1) )
//         //   props.history.push("/user/request-for-pro-item")
//         //   swal("Sorry First You Buy")
  
//       }
//     }
//     else{
//       document.getElementById("myDivUmer").style.display= "block"

//       // localStorage.setItem("request123",JSON.stringify(1) )
//       //   props.history.push("/user/request-for-pro-item")
//       //   swal("Sorry First You Buy")
//   }
//   }
//   else{

//   }
// }
const setChanger8 = (e)=>{
  document.getElementById("myDivUmer12").style.display= "block"
}
const setImage123 = (e)=>{
  console.log(e)
  const data = new FormData()
  data.append("file", e)
  data.append("upload_preset", "commerce")
  data.append("cloud_name", "freedeveloperss123")
  fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
      method : "POST",
      body : data , 
  })
  .then(res=>res.json())
  .then((res2)=>{
    setImage(res2.url)
    document.getElementById("photoImage2").src = res2.url
  })
}
const setRoter123 = ()=>{
  localStorage.setItem("request123",JSON.stringify(1) )
      props.history.push("/user/request-for-pro-item")

}

// const setChanger1 = (e)=>{
//   document.getElementById("myDiv1234").style.display= "none"
// }
const setChanger2 = (e)=>{
  document.getElementById("myDivUmer").style.display= "none"
}

        return (
            






      <div style={{background : "rgb(0,0,0)"}}
        // onScroll={onScrolll()}
        >
     
        {/* Slider */}




        <div style={{height : "2px"}}>

</div>




        <div id="myDivUmer">
                {/* <img src={guides} alt=""  id="myDiv23456"/> */}
                <center>
                  <h1>
                     Build Your Network
                </h1>
                  </center>
                <center><p>
Capture leads using TabitDabit and favorite, group, sort and manage them all from here. Start building your connections now                  </p></center>
                  <center>
                  <button class="btn btn-dark F23 f45" onClick={()=>setRoter123()}>Buy Now </button>
                  </center>
                <ClearIcon id="myDiv34567"  style={{color : "black",fontSize : "30px",position : "absolute" , right: "10%",top : "10%" , zIndex: "2"}} onClick={()=>setChanger2()}/>
            </div> 

            <div id="myDivUmer12">
                {/* <img src={guides} alt=""  id="myDiv23456"/> */}
                <center>
                  <h1>
                    Analytics
                </h1>
                  </center>
                <center><p>
                Unlock all links with Pro to see insights of your account. <br />
Tap Buy now  to upgrade.
          </p></center>
                  <center>
                  <button class="btn btn-dark F23 f45" onClick={()=>setRoter123()}>Buy Now </button>
                  </center>
                <ClearIcon id="myDiv3456767"  style={{color : "black",fontSize : "30px",position : "absolute" , right: "10%",top : "10%" , zIndex: "2"}} onClick={()=>setChanger8()}/>
            </div> 







    

  <div className="container" style={{margin : "30px auto" , width : "90%"}}>
        <div className="row justify-content-center">
          <div className="col-12">
            <h2 style={{color : "white" , padding:  "0px 10px 0px 10px"}} > <b>Link Store </b> </h2>
            <p style={{color : "grey" ,fontSize : "18px", padding:  "0px 10px"}} > <b>App links to your personal profile </b> </p>
            <h2 style={{color : "white",fontWeight : "200" , padding:  "10px 10px 0px 10px"}} > <b>Recommended </b> </h2>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(55 55 60)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf" onClick={()=>settochange(25)}  >
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={call1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "white" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Number</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(55 55 60)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf"  onClick={()=>settochange(24)}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={mail1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "white" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Email</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(55 55 60)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf"  onClick={()=>settochange(1)}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={in1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "white" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Instagram</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(55 55 60)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf"  onClick={()=>settochange(13)}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={we1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "white" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Website</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(55 55 60)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf"  onClick={()=>settochange(5)}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={ln1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "white" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>LinkedIn</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(55 55 60)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf"  onClick={()=>setContactCard()}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={cd1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "white" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Contact Card</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
         

        </div>
      </div>




      <div className="container" style={{margin : "30px auto" , width : "90%"}}>
        <div className="row justify-content-center">
          <div className="col-12">
            <h2 style={{color : "white",fontWeight : "200" , padding:  "10px 10px 0px 10px"}} > <b>Social Media </b> </h2>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(55 55 60)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf" onClick={()=>settochange(1)}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={in1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "white" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Instagram</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(55 55 60)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf" onClick={()=>settochange(2)} >
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={sc1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "white" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>SnapChat</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(55 55 60)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf" onClick={()=>settochange(3)} >
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={tk1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "white" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>TikTok</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(55 55 60)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf" onClick={()=>settochange(4)} >
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={fb1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "white" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Facebook</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(55 55 60)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf" onClick={()=>settochange(5)} >
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={ln1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "white" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>LinkedIn</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(55 55 60)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf" onClick={()=>settochange(6)} >
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={tw1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "white" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Twitter</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(55 55 60)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf" onClick={()=>settochange(7)} >
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={you1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "white" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Youtube</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(55 55 60)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf" onClick={()=>settochange(8)} >
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={tt1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "white" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Twitch</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(55 55 60)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf" onClick={()=>settochange(9)} >
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={pi1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "white" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Pinterest</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>

        </div>
      </div>





      <div className="container" style={{margin : "30px auto" , width : "90%"}}>
        <div className="row justify-content-center">
          <div className="col-12">
            <h2 style={{color : "white",fontWeight : "200" , padding:  "10px 10px 0px 10px"}} > <b>Content </b> </h2>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(55 55 60)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf"  onClick={()=>EmbedVideo()} >
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={vi1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "white" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Embed Video</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          

        </div>
      </div>
     
      <div className="container" style={{margin : "30px auto" , width : "90%"}}>
        <div className="row justify-content-center">
          <div className="col-12">
            <h2 style={{color : "white",fontWeight : "200" , padding:  "10px 10px 0px 10px"}} > <b>Contact Info </b> </h2>
          </div>
        
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(55 55 60)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf" onClick={()=>settochange(25)} >
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={call1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "white" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Number</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
         
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(55 55 60)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf"  onClick={()=>settochange(21)}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={mess1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "white" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Text</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(55 55 60)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf" onClick={()=>settochange(22)} >
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={wa1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "white" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>WhatsApp</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(55 55 60)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf"  onClick={()=>settochange(24)}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={mail1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "white" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Email</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(55 55 60)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf"   onClick={()=>settochange(99)}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={lo1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "white" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Location</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(55 55 60)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf"  onClick={()=>setContactCard()}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={cd1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "white" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Contact Card</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>

        </div>
      </div>








      <div className="container" style={{margin : "30px auto" , width : "90%"}}>
        <div className="row justify-content-center">
          <div className="col-12">
            <h2 style={{color : "white",fontWeight : "200" , padding:  "10px 10px 0px 10px"}} > <b>Payment </b> </h2>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(55 55 60)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf"  onClick={()=>settochange44(112)}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={bk1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "white" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Bank</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(55 55 60)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf" onClick={()=>settochange44(111)} >
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={ep1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "white" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>EasyPaisa</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(55 55 60)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf" onClick={()=>settochange44(110)} >
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    {/* <img src={jc1} style={{width : "45px" , height: "39px",objectFit: "contain"}}  /> */}
                    <img src={jc1} style={{width : "53px" , height: "39px",objectFit: "fill"}}  />

                    <p style={{color : "white" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>JazzCash</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(55 55 60)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf"  onClick={()=>settochange(11)}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={pp1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "white" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>PayPal</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(55 55 60)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf"  onClick={()=>settochange(10)}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={ca1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "white" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Cash App</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          
          
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(55 55 60)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf" onClick={()=>settochange(12)} >
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={ve1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "white" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Venmo</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          

        </div>
      </div>




      <div className="container" style={{margin : "30px auto" , width : "90%",padding:"0px 0px 103px 0px"}}>
        <div className="row justify-content-center">
          <div className="col-12">
            <h2 style={{color : "white",fontWeight : "200" , padding:  "10px 10px 0px 10px"}} > <b>For Business </b> </h2>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(55 55 60)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf"  onClick={()=>settochange(13)}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={we1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "white" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Website</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(55 55 60)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf"  onClick={()=>settochange3(33)}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={cu1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "white" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Custom Link</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(55 55 60)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf"  onClick={()=>settochange44(53)}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={lt1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "white" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Linktree</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          
         
          

        </div>
      </div>






{/* 

          <div className="cc1">
              <div className="container1" id="container1"  >
                <div className="form-container sign-up-container">
                  <form className="form11" >
                    <h1 className="h22">Create Account</h1>
                    <div className="social-container">
                     
                    </div>
                    <input className="input11" type="text" name="name" placeholder="Name" />
                    <input className="input11" type="email" name="email" placeholder="Email" />
                    <input className="input11" type="password" name="password" placeholder="Password" />
                    <button className="button11" type="submit">SignUp</button>
                  </form>
                </div>
                <div className="form-container sign-in-container">
                  <form  className="form11"   onSubmit={(e)=>postData(e)}>
                    <h1 className="h22">Sign In</h1>
                    <div className="social-container">
                     
                    </div>
                    <input className="input11" type="email"   value={email} onChange={(e)=>setemail(e.target.value)}  name="email" placeholder="Email" />
                    {dispaly ?
                    <>
                    <input className="input11" type="password"  value={pass} onChange={(e)=>setpass(e.target.value)}  name="password" placeholder="Password" />
                    <VisibilityOffIcon  onClick={()=>setPassShow(false)} style={{position : "absolute" , right : "63px", top : "252px"}}/>
                      </>
                      :
                    <>
                    <input className="input11" type="text"  value={pass} onChange={(e)=>setpass(e.target.value)}  name="password" placeholder="Password" />
                    <VisibilityIcon onClick={()=>setPassShow(true)} style={{position : "absolute" , right : "63px", top : "252px"}}/>
                      </>
                      
                    }
                    <a href="#" className="aa">Forgot Your Password</a>
                    <button className="button11" type="submit">Sign In</button>
                  </form>
                </div>
                <div className="overlay-container">
                  <div className="overlay">
                    <div className="overlay-panel overlay-left">
                      <h1 className="h22">Welcome Back!</h1>
                      <p className="ppp1">To keep connected with us please login with your personal info</p>
                      <button  className="ghost button11" id="signIn" onClick={()=>setChange1(11)}>Sign In</button>
                    </div>
                    <div className="overlay-panel overlay-right">
                      <h1 className="h22">Hello, Friend!</h1>
                      <p className="ppp1">Enter your details and start journey with us</p>
                      <button  className="ghost button11" id="signUp" onClick={()=>setChange1(1)}>Sign Up</button>
                    </div>
                  </div>
                </div>
              </div>
          </div>
         */}

        {/* Back to top */}
        <div className="btn-back-to-top" id="myBtn">
          <span className="symbol-btn-back-to-top">
            <i className="zmdi zmdi-chevron-up" />
          </span>
        </div>
        {/* Modal1 */}
        <div className="wrap-modal1 js-modal1 p-t-60 p-b-20">
          <div className="overlay-modal1 js-hide-modal1" />
          <div className="container">
            <div className="bg0 p-t-60 p-b-30 p-lr-15-lg how-pos3-parent">
              <button className="how-pos3 hov3 trans-04 js-hide-modal1">
                <img src="images/icons/icon-close.png" alt="CLOSE" />
              </button>
              <div className="row">
                <div className="col-md-6 col-lg-7 p-b-30">
                  <div className="p-l-25 p-r-30 p-lr-0-lg">
                    <div className="wrap-slick3 flex-sb flex-w">
                      <div className="wrap-slick3-dots" />
                      <div className="wrap-slick3-arrows flex-sb-m flex-w" />
                      <div className="slick3 gallery-lb">
                        <div className="item-slick3" data-thumb="images/product-detail-01.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-01.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-01.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-02.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-02.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-02.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-03.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-03.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-03.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5 p-b-30">
                  <div className="p-r-50 p-t-5 p-lr-0-lg">
                    <h4 className="mtext-105 cl2 js-name-detail p-b-14">
                      Lightweight Jacket
                    </h4>
                    <span className="mtext-106 cl2">
                      $58.79
                    </span>
                    <p className="stext-102 cl3 p-t-23">
                      Nulla eget sem vitae eros pharetra viverra. Nam vitae luctus ligula. Mauris consequat ornare feugiat.
                    </p>
                    {/*  */}
                    <div className="p-t-33">
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Size
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Size S</option>
                              <option>Size M</option>
                              <option>Size L</option>
                              <option>Size XL</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Color
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Red</option>
                              <option>Blue</option>
                              <option>White</option>
                              <option>Grey</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-204 flex-w flex-m respon6-next">
                          <div className="wrap-num-product flex-w m-r-20 m-tb-10">
                            <div className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-minus" />
                            </div>
                            <input className="mtext-104 cl3 txt-center num-product" type="number" name="num-product" defaultValue={1} />
                            <div className="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-plus" />
                            </div>
                          </div>
                          <button className="flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04 js-addcart-detail">
                            Add to cart
                          </button>
                        </div>
                      </div>	
                    </div>
                    {/*  */}
                    <div className="flex-w flex-m p-l-100 p-t-40 respon7">
                      <div className="flex-m bor9 p-r-10 m-r-11">
                        <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 js-addwish-detail tooltip100" data-tooltip="Add to Wishlist">
                          <i className="zmdi zmdi-favorite" />
                        </a>
                      </div>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Facebook">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Twitter">
                        <i className="fa fa-twitter" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Google Plus">
                        <i className="fa fa-google-plus" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
 






        )
    
}

export default Home;