
import React, { useState, useEffect } from "react";

// import ArrowBackIosNewIcon from '@material-ui/icons/ArrowBackIosNew';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
export default function Map() {

//   useEffect(() =>{

//   carrouselInit();

// },[])


  function carrouselInit() {

    var carrousel = document.getElementById("carrouselimg1");
    carrousel.style.opacity = "1";
    setInterval(carrouselAutoChange, 5000);

}

function carrouselAutoChange() {
    carrouselRight();
}



function carrouselChange(i) {

    var carrousel = document.getElementById("carrouselimg" + i);
    carrousel.style.opacity = "1";

}


function carrouselLeft() {

    let nbCarrousel = 5;
    let num = 0;

    for (let i = 0; i < nbCarrousel; i++) {
        num = i + 1;
        var carrousel = document.getElementById("carrouselimg" + num);
        if (carrousel.style.opacity == "1") {
            carrousel.style.opacity = "0";
            if (i == 0) {
                return carrouselChange(5);
            }
            return carrouselChange(num - 1);
        }
    }
}

function carrouselRight() {

    let nbCarrousel = 5;
    let num = 0;

    for (let i = 0; i < nbCarrousel; i++) {
        num = i + 1;
        var carrousel = document.getElementById("carrouselimg" + num);
        if (carrousel.style.opacity == "1") {
            carrousel.style.opacity = "0";
            if (i == 4) {
                return carrouselChange(1);
            }
            return carrouselChange(num + 1);
        }
    }
}

  return (
<div class="center">
        <div onclick="carrouselLeft()">
          <KeyboardArrowLeftIcon  style={{color : "grey", width :"30px"}}/>
            {/* <path
            d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" /> */}
        {/* </svg> */}
    </div>
    <div class="carrousel">
        <div class="carrousel-item" id="carrouselimg1">
            <img src="https://picsum.photos/600/300?random=1" alt="image 1"/>
        </div>
        <div class="carrousel-item" id="carrouselimg2">
            <img src="https://picsum.photos/600/300?random=2" alt="image 2"/>
        </div>
        <div class="carrousel-item" id="carrouselimg3">
            <img src="https://picsum.photos/600/300?random=3" alt="image 3"/>
        </div>
        <div class="carrousel-item" id="carrouselimg4">
            <img src="https://picsum.photos/600/300?random=4" alt="image 4"/>
            </div>
            {/* <div class="carrousel-item" id="carrouselimg5">
                <img src="https://picsum.photos/600/300?random=5" alt="image 5"/>
            </div> */}
        </div>
        <div onclick="carrouselRight()">
        <KeyboardArrowRightIcon  style={{color : "grey", width :"30px"}}/>

    </div>
</div>
  );
}
