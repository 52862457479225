import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"
import swal from 'sweetalert';

import VCard from 'vcard-creator'

import "react-multi-carousel/lib/styles.css";
import "react-multi-carousel/lib/styles.css";
import "./../assets/css/main.css"
import "./../assets/css/util.css"
import "./../assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css"
import Loader from "react-loader-spinner";



// import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"

import "./../assets/vendor/css-hamburgers/hamburgers.min.css"

import "./../assets/fonts/iconic/css/material-design-iconic-font.min.css"
import "./../assets/vendor/bootstrap/css/bootstrap.min.css"
import "./../assets/vendor/select2/select2.min.css"
import "./../assets/vendor/slick/slick.css"
import "./../assets/vendor/perfect-scrollbar/perfect-scrollbar.css"

import x1 from "./../assets/images/x1.jpg"



const Home = ( props ) => {
    const [myRef,setRef] = useState( React.createRef())
    // const [scrollTop1,setscrollTop] = useState(0)
    // const [email , setemail] = useState("")
    // const [pass , setpass] = useState("")
    // const [dispaly ,  setdispaly ] = useState(true)
    const [User1 ,  setUser1 ] = useState({})
     const [Arr1 ,  setArr1 ] = useState([])


    
     const [ConnectedUser, setConnectedUser] = useState([])

     const [Connect ,  setConnect ] = useState(false)
    
     const [Image , setImage] = useState("")
      const [Name , setname] = useState("")
      const [Email , setEmail] = useState("")
      const [Phone , setPhone] = useState("")
      // const [Gender , setGender] = useState("")
      const [City , setCity] = useState("")
      const [Job , setJob] = useState("")
      const [Date , setDate] = useState("")
      const [Role , setRole] = useState("")
      // const [url , seturl] = useState("")
      const [dis10 ,  setdis10 ] = useState(true)
      // const [dis20 ,  setdis20 ] = useState(false)
 
      const [UserPhoneCode ,  setUserPhoneCode ] = useState("92")
      const [PhoneCode ,  setPhoneCode ] = useState([
        {
          code: 'PK', map: '🇵🇰', phoneCode: 92, value: 'Pakistan',
        },
        {
          code: 'AF', map: '🇦🇫', phoneCode: 93, value: 'Afghanistan',
        },
        {
          code: 'AL', map: '🇦🇱', phoneCode: 355, value: 'Albania',
        },
        {
          code: 'DZ', map: '🇩🇿', phoneCode: 213, value: 'Algeria',
        },
        {
          code: 'AS', map: '🇦🇸', phoneCode: 1684, value: 'American Samoa',
        },
        {
          code: 'AD', map: '🇦🇩', phoneCode: 376, value: 'Andorra',
        },
        {
          code: 'AO', map: '🇦🇴', phoneCode: 244, value: 'Angola',
        },
        {
          code: 'AI', map: '🇦🇮', phoneCode: 1264, value: 'Anguilla',
        },
        {
          code: 'AQ', map: '🇦🇶', phoneCode: 0, value: 'Antarctica',
        },
        {
          code: 'AR', map: '🇦🇷', phoneCode: 54, value: 'Argentina',
        },
        {
          code: 'AM', map: '🇦🇲', phoneCode: 374, value: 'Armenia',
        },
        {
          code: 'AW', map: '🇦🇼', phoneCode: 297, value: 'Aruba',
        },
        {
          code: 'AU', map: '🇦🇺', phoneCode: 61, value: 'Australia',
        },
        {
          code: 'AT', map: '🇦🇹', phoneCode: 43, value: 'Austria',
        },
        {
          code: 'AZ', map: '🇦🇿', phoneCode: 994, value: 'Azerbaijan',
        },
        {
          code: 'BH', map: '🇧🇭', phoneCode: 973, value: 'Bahrain',
        },
        {
          code: 'BD', map: '🇧🇩', phoneCode: 880, value: 'Bangladesh',
        },
        {
          code: 'BB', map: '🇧🇧', phoneCode: 1246, value: 'Barbados',
        },
        {
          code: 'BY', map: '🇧🇾', phoneCode: 375, value: 'Belarus',
        },
        {
          code: 'BE', map: '🇧🇪', phoneCode: 32, value: 'Belgium',
        },
        {
          code: 'BZ', map: '🇧🇿', phoneCode: 501, value: 'Belize',
        },
        {
          code: 'BJ', map: '🇧🇯', phoneCode: 229, value: 'Benin',
        },
        {
          code: 'BM', map: '🇧🇲', phoneCode: 1441, value: 'Bermuda',
        },
        {
          code: 'BT', map: '🇧🇹', phoneCode: 975, value: 'Bhutan',
        },
        {
          code: 'BO', map: '🇧🇴', phoneCode: 591, value: 'Bolivia',
        },
        {
          code: 'BW', map: '🇧🇼', phoneCode: 267, value: 'Botswana',
        },
        {
          code: 'BV', map: '🇧🇻', phoneCode: 0, value: 'Bouvet Island',
        },
        {
          code: 'BR', map: '🇧🇷', phoneCode: 55, value: 'Brazil',
        },
        {
          code: 'IO', map: '🇮🇴', phoneCode: 246, value: 'British Indian Ocean Territory',
        },
        {
          code: 'BN', map: '🇧🇳', phoneCode: 673, value: 'Brunei',
        },
        {
          code: 'BG', map: '🇧🇬', phoneCode: 359, value: 'Bulgaria',
        },
        {
          code: 'BF', map: '🇧🇫', phoneCode: 226, value: 'Burkina Faso',
        },
        {
          code: 'BI', map: '🇧🇮', phoneCode: 257, value: 'Burundi',
        },
        {
          code: 'KH', map: '🇰🇭', phoneCode: 855, value: 'Cambodia',
        },
        {
          code: 'CM', map: '🇨🇲', phoneCode: 237, value: 'Cameroon',
        },
        {
          code: 'CA', map: '🇨🇦', phoneCode: 1, value: 'Canada',
        },
        {
          code: 'CV', map: '🇨🇻', phoneCode: 238, value: 'Cape Verde',
        },
        {
          code: 'KY', map: '🇰🇾', phoneCode: 1345, value: 'Cayman Islands',
        },
        {
          code: 'CF', map: '🇨🇫', phoneCode: 236, value: 'Central African Republic',
        },
        {
          code: 'TD', map: '🇹🇩', phoneCode: 235, value: 'Chad',
        },
        {
          code: 'CL', map: '🇨🇱', phoneCode: 56, value: 'Chile',
        },
        {
          code: 'CN', map: '🇨🇳', phoneCode: 86, value: 'China',
        },
        {
          code: 'CX', map: '🇨🇽', phoneCode: 61, value: 'Christmas Island',
        },
        {
          code: 'CC', map: '🇨🇨', phoneCode: 672, value: 'Cocos (Keeling) Islands',
        },
        {
          code: 'CO', map: '🇨🇴', phoneCode: 57, value: 'Colombia',
        },
        {
          code: 'KM', map: '🇰🇲', phoneCode: 269, value: 'Comoros',
        },
        {
          code: 'CK', map: '🇨🇰', phoneCode: 682, value: 'Cook Islands',
        },
        {
          code: 'CR', map: '🇨🇷', phoneCode: 506, value: 'Costa Rica',
        },
        {
          code: 'CU', map: '🇨🇺', phoneCode: 53, value: 'Cuba',
        },
        {
          code: 'CY', map: '🇨🇾', phoneCode: 357, value: 'Cyprus',
        },
        {
          code: 'DK', map: '🇩🇰', phoneCode: 45, value: 'Denmark',
        },
        {
          code: 'DJ', map: '🇩🇯', phoneCode: 253, value: 'Djibouti',
        },
        {
          code: 'DM', map: '🇩🇲', phoneCode: 1767, value: 'Dominica',
        },
        {
          code: 'DO', map: '🇩🇴', phoneCode: 1809, value: 'Dominican Republic',
        },
        {
          code: 'EC', map: '🇪🇨', phoneCode: 593, value: 'Ecuador',
        },
        {
          code: 'EG', map: '🇪🇬', phoneCode: 20, value: 'Egypt',
        },
        {
          code: 'SV', map: '🇸🇻', phoneCode: 503, value: 'El Salvador',
        },
        {
          code: 'GQ', map: '🇬🇶', phoneCode: 240, value: 'Equatorial Guinea',
        },
        {
          code: 'ER', map: '🇪🇷', phoneCode: 291, value: 'Eritrea',
        },
        {
          code: 'EE', map: '🇪🇪', phoneCode: 372, value: 'Estonia',
        },
        {
          code: 'ET', map: '🇪🇹', phoneCode: 251, value: 'Ethiopia',
        },
        {
          code: 'FK', map: '🇫🇰', phoneCode: 500, value: 'Falkland Islands',
        },
        {
          code: 'FO', map: '🇫🇴', phoneCode: 298, value: 'Faroe Islands',
        },
        {
          code: 'FI', map: '🇫🇮', phoneCode: 358, value: 'Finland',
        },
        {
          code: 'FR', map: '🇫🇷', phoneCode: 33, value: 'France',
        },
        {
          code: 'GF', map: '🇬🇫', phoneCode: 594, value: 'French Guiana',
        },
        {
          code: 'PF', map: '🇵🇫', phoneCode: 689, value: 'French Polynesia',
        },
        {
          code: 'TF', map: '🇹🇫', phoneCode: 0, value: 'French Southern Territories',
        },
        {
          code: 'GA', map: '🇬🇦', phoneCode: 241, value: 'Gabon',
        },
        {
          code: 'GE', map: '🇬🇪', phoneCode: 995, value: 'Georgia',
        },
        {
          code: 'DE', map: '🇩🇪', phoneCode: 49, value: 'Germany',
        },
        {
          code: 'GH', map: '🇬🇭', phoneCode: 233, value: 'Ghana',
        },
        {
          code: 'GI', map: '🇬🇮', phoneCode: 350, value: 'Gibraltar',
        },
        {
          code: 'GR', map: '🇬🇷', phoneCode: 30, value: 'Greece',
        },
        {
          code: 'GL', map: '🇬🇱', phoneCode: 299, value: 'Greenland',
        },
        {
          code: 'GD', map: '🇬🇩', phoneCode: 1473, value: 'Grenada',
        },
        {
          code: 'GP', map: '🇬🇵', phoneCode: 590, value: 'Guadeloupe',
        },
        {
          code: 'GU', map: '🇬🇺', phoneCode: 1671, value: 'Guam',
        },
        {
          code: 'GT', map: '🇬🇹', phoneCode: 502, value: 'Guatemala',
        },
        {
          code: 'GN', map: '🇬🇳', phoneCode: 224, value: 'Guinea',
        },
        {
          code: 'GW', map: '🇬🇼', phoneCode: 245, value: 'Guinea-Bissau',
        },
        {
          code: 'GY', map: '🇬🇾', phoneCode: 592, value: 'Guyana',
        },
        {
          code: 'HT', map: '🇭🇹', phoneCode: 509, value: 'Haiti',
        },
        {
          code: 'HN', map: '🇭🇳', phoneCode: 504, value: 'Honduras',
        },
        {
          code: 'HU', map: '🇭🇺', phoneCode: 36, value: 'Hungary',
        },
        {
          code: 'IS', map: '🇮🇸', phoneCode: 354, value: 'Iceland',
        },
        {
          code: 'IN', map: '🇮🇳', phoneCode: 91, value: 'India',
        },
        {
          code: 'ID', map: '🇮🇩', phoneCode: 62, value: 'Indonesia',
        },
        {
          code: 'IR', map: '🇮🇷', phoneCode: 98, value: 'Iran',
        },
        {
          code: 'IQ', map: '🇮🇶', phoneCode: 964, value: 'Iraq',
        },
        {
          code: 'IE', map: '🇮🇪', phoneCode: 353, value: 'Ireland',
        },
        {
          code: 'IL', map: '🇮🇱', phoneCode: 972, value: 'Israel',
        },
        {
          code: 'IT', map: '🇮🇹', phoneCode: 39, value: 'Italy',
        },
        {
          code: 'JM', map: '🇯🇲', phoneCode: 1876, value: 'Jamaica',
        },
        {
          code: 'JP', map: '🇯🇵', phoneCode: 81, value: 'Japan',
        },
        {
          code: 'JO', map: '🇯🇴', phoneCode: 962, value: 'Jordan',
        },
        {
          code: 'KZ', map: '🇰🇿', phoneCode: 7, value: 'Kazakhstan',
        },
        {
          code: 'KE', map: '🇰🇪', phoneCode: 254, value: 'Kenya',
        },
        {
          code: 'KI', map: '🇰🇮', phoneCode: 686, value: 'Kiribati',
        },
        {
          code: 'KW', map: '🇰🇼', phoneCode: 965, value: 'Kuwait',
        },
        {
          code: 'KG', map: '🇰🇬', phoneCode: 996, value: 'Kyrgyzstan',
        },
        {
          code: 'LA', map: '🇱🇦', phoneCode: 856, value: 'Laos',
        },
        {
          code: 'LV', map: '🇱🇻', phoneCode: 371, value: 'Latvia',
        },
        {
          code: 'LB', map: '🇱🇧', phoneCode: 961, value: 'Lebanon',
        },
        {
          code: 'LS', map: '🇱🇸', phoneCode: 266, value: 'Lesotho',
        },
        {
          code: 'LR', map: '🇱🇷', phoneCode: 231, value: 'Liberia',
        },
        {
          code: 'LY', map: '🇱🇾', phoneCode: 218, value: 'Libya',
        },
        {
          code: 'LI', map: '🇱🇮', phoneCode: 423, value: 'Liechtenstein',
        },
        {
          code: 'LT', map: '🇱🇹', phoneCode: 370, value: 'Lithuania',
        },
        {
          code: 'LU', map: '🇱🇺', phoneCode: 352, value: 'Luxembourg',
        },
        {
          code: 'MK', map: '🇲🇰', phoneCode: 389, value: 'Macedonia',
        },
        {
          code: 'MG', map: '🇲🇬', phoneCode: 261, value: 'Madagascar',
        },
        {
          code: 'MW', map: '🇲🇼', phoneCode: 265, value: 'Malawi',
        },
        {
          code: 'MY', map: '🇲🇾', phoneCode: 60, value: 'Malaysia',
        },
        {
          code: 'MV', map: '🇲🇻', phoneCode: 960, value: 'Maldives',
        },
        {
          code: 'ML', map: '🇲🇱', phoneCode: 223, value: 'Mali',
        },
        {
          code: 'MT', map: '🇲🇹', phoneCode: 356, value: 'Malta',
        },
        {
          code: 'MH', map: '🇲🇭', phoneCode: 692, value: 'Marshall Islands',
        },
        {
          code: 'MQ', map: '🇲🇶', phoneCode: 596, value: 'Martinique',
        },
        {
          code: 'MR', map: '🇲🇷', phoneCode: 222, value: 'Mauritania',
        },
        {
          code: 'MU', map: '🇲🇺', phoneCode: 230, value: 'Mauritius',
        },
        {
          code: 'YT', map: '🇾🇹', phoneCode: 269, value: 'Mayotte',
        },
        {
          code: 'MX', map: '🇲🇽', phoneCode: 52, value: 'Mexico',
        },
        {
          code: 'FM', map: '🇫🇲', phoneCode: 691, value: 'Micronesia',
        },
        {
          code: 'MD', map: '🇲🇩', phoneCode: 373, value: 'Moldova',
        },
        {
          code: 'MC', map: '🇲🇨', phoneCode: 377, value: 'Monaco',
        },
        {
          code: 'MN', map: '🇲🇳', phoneCode: 976, value: 'Mongolia',
        },
        {
          code: 'MS', map: '🇲🇸', phoneCode: 1664, value: 'Montserrat',
        },
        {
          code: 'MA', map: '🇲🇦', phoneCode: 212, value: 'Morocco',
        },
        {
          code: 'MZ', map: '🇲🇿', phoneCode: 258, value: 'Mozambique',
        },
        {
          code: 'NA', map: '🇳🇦', phoneCode: 264, value: 'Namibia',
        },
        {
          code: 'NR', map: '🇳🇷', phoneCode: 674, value: 'Nauru',
        },
        {
          code: 'NP', map: '🇳🇵', phoneCode: 977, value: 'Nepal',
        },
        {
          code: 'NC', map: '🇳🇨', phoneCode: 687, value: 'New Caledonia',
        },
        {
          code: 'NZ', map: '🇳🇿', phoneCode: 64, value: 'New Zealand',
        },
        {
          code: 'NI', map: '🇳🇮', phoneCode: 505, value: 'Nicaragua',
        },
        {
          code: 'NE', map: '🇳🇪', phoneCode: 227, value: 'Niger',
        },
        {
          code: 'NG', map: '🇳🇬', phoneCode: 234, value: 'Nigeria',
        },
        {
          code: 'NU', map: '🇳🇺', phoneCode: 683, value: 'Niue',
        },
        {
          code: 'NF', map: '🇳🇫', phoneCode: 672, value: 'Norfolk Island',
        },
        {
          code: 'MP', map: '🇲🇵', phoneCode: 1670, value: 'Northern Mariana Islands',
        },
        {
          code: 'NO', map: '🇳🇴', phoneCode: 47, value: 'Norway',
        },
        {
          code: 'OM', map: '🇴🇲', phoneCode: 968, value: 'Oman',
        },
       
        {
          code: 'PW', map: '🇵🇼', phoneCode: 680, value: 'Palau',
        },
        {
          code: 'PA', map: '🇵🇦', phoneCode: 507, value: 'Panama',
        },
        {
          code: 'PY', map: '🇵🇾', phoneCode: 595, value: 'Paraguay',
        },
        {
          code: 'PE', map: '🇵🇪', phoneCode: 51, value: 'Peru',
        },
        {
          code: 'PH', map: '🇵🇭', phoneCode: 63, value: 'Philippines',
        },
        {
          code: 'PL', map: '🇵🇱', phoneCode: 48, value: 'Poland',
        },
        {
          code: 'PT', map: '🇵🇹', phoneCode: 351, value: 'Portugal',
        },
        {
          code: 'PR', map: '🇵🇷', phoneCode: 1787, value: 'Puerto Rico',
        },
        {
          code: 'QA', map: '🇶🇦', phoneCode: 974, value: 'Qatar',
        },
        {
          code: 'RO', map: '🇷🇴', phoneCode: 40, value: 'Romania',
        },
        {
          code: 'RU', map: '🇷🇺', phoneCode: 70, value: 'Russia',
        },
        {
          code: 'RW', map: '🇷🇼', phoneCode: 250, value: 'Rwanda',
        },
        {
          code: 'WS', map: '🇼🇸', phoneCode: 684, value: 'Samoa',
        },
        {
          code: 'SM', map: '🇸🇲', phoneCode: 378, value: 'San Marino',
        },
        {
          code: 'SA', map: '🇸🇦', phoneCode: 966, value: 'Saudi Arabia',
        },
        {
          code: 'SN', map: '🇸🇳', phoneCode: 221, value: 'Senegal',
        },
        {
          code: 'RS', map: '🇷🇸', phoneCode: 381, value: 'Serbia',
        },
        {
          code: 'SC', map: '🇸🇨', phoneCode: 248, value: 'Seychelles',
        },
        {
          code: 'SL', map: '🇸🇱', phoneCode: 232, value: 'Sierra Leone',
        },
        {
          code: 'SG', map: '🇸🇬', phoneCode: 65, value: 'Singapore',
        },
        {
          code: 'SK', map: '🇸🇰', phoneCode: 421, value: 'Slovakia',
        },
        {
          code: 'SI', map: '🇸🇮', phoneCode: 386, value: 'Slovenia',
        },
        {
          code: 'SB', map: '🇸🇧', phoneCode: 677, value: 'Solomon Islands',
        },
        {
          code: 'SO', map: '🇸🇴', phoneCode: 252, value: 'Somalia',
        },
        {
          code: 'ZA', map: '🇿🇦', phoneCode: 27, value: 'South Africa',
        },
        {
          code: 'SS', map: '🇸🇸', phoneCode: 211, value: 'South Sudan',
        },
        {
          code: 'ES', map: '🇪🇸', phoneCode: 34, value: 'Spain',
        },
        {
          code: 'LK', map: '🇱🇰', phoneCode: 94, value: 'Sri Lanka',
        },
        {
          code: 'SD', map: '🇸🇩', phoneCode: 249, value: 'Sudan',
        },
        {
          code: 'SR', map: '🇸🇷', phoneCode: 597, value: 'Suriname',
        },
        {
          code: 'SZ', map: '🇸🇿', phoneCode: 268, value: 'Swaziland',
        },
        {
          code: 'SE', map: '🇸🇪', phoneCode: 46, value: 'Sweden',
        },
        {
          code: 'CH', map: '🇨🇭', phoneCode: 41, value: 'Switzerland',
        },
        {
          code: 'SY', map: '🇸🇾', phoneCode: 963, value: 'Syria',
        },
        {
          code: 'TW', map: '🇹🇼', phoneCode: 886, value: 'Taiwan',
        },
        {
          code: 'TJ', map: '🇹🇯', phoneCode: 992, value: 'Tajikistan',
        },
        {
          code: 'TZ', map: '🇹🇿', phoneCode: 255, value: 'Tanzania',
        },
        {
          code: 'TH', map: '🇹🇭', phoneCode: 66, value: 'Thailand',
        },
        {
          code: 'TG', map: '🇹🇬', phoneCode: 228, value: 'Togo',
        },
        {
          code: 'TK', map: '🇹🇰', phoneCode: 690, value: 'Tokelau',
        },
        {
          code: 'TO', map: '🇹🇴', phoneCode: 676, value: 'Tonga',
        },
        {
          code: 'TN', map: '🇹🇳', phoneCode: 216, value: 'Tunisia',
        },
        {
          code: 'TR', map: '🇹🇷', phoneCode: 90, value: 'Turkey',
        },
        {
          code: 'TM', map: '🇹🇲', phoneCode: 7370, value: 'Turkmenistan',
        },
        {
          code: 'TV', map: '🇹🇻', phoneCode: 688, value: 'Tuvalu',
        },
        {
          code: 'UG', map: '🇺🇬', phoneCode: 256, value: 'Uganda',
        },
        {
          code: 'UA', map: '🇺🇦', phoneCode: 380, value: 'Ukraine',
        },
        {
          code: 'AE', map: '🇦🇪', phoneCode: 971, value: 'United Arab Emirates',
        },
        {
          code: 'GB', map: '🇬🇧', phoneCode: 44, value: 'United Kingdom',
        },
        {
          code: 'US', map: '🇺🇸', phoneCode: 1, value: 'United States',
        },
        {
          code: 'UY', map: '🇺🇾', phoneCode: 598, value: 'Uruguay',
        },
        {
          code: 'UZ', map: '🇺🇿', phoneCode: 998, value: 'Uzbekistan',
        },
        {
          code: 'VU', map: '🇻🇺', phoneCode: 678, value: 'Vanuatu',
        },
        {
          code: 'VE', map: '🇻🇪', phoneCode: 58, value: 'Venezuela',
        },
        {
          code: 'VN', map: '🇻🇳', phoneCode: 84, value: 'Vietnam',
        },
        {
          code: 'EH', map: '🇪🇭', phoneCode: 212, value: 'Western Sahara',
        },
        {
          code: 'YE', map: '🇾🇪', phoneCode: 967, value: 'Yemen',
        },
        {
          code: 'ZM', map: '🇿🇲', phoneCode: 260, value: 'Zambia',
        },
        {
          code: 'ZW', map: '🇿🇼', phoneCode: 26, value: 'Zimbabwe',
        }])
      
 
 

    useEffect(() =>{
      window.scrollTo(0, 0)
  fetch("https://tapitdabit-backend.vercel.app/allUser/"+JSON.parse(localStorage.getItem("User"))._id,{
    method: "GET",
     headers :  {
     "Content-Type" : "application/json" , 
 } ,
})
.then(res5=>res5.json())
.then(res6=>{
    setUser1(res6)
    setArr1(res6.DispalyShow)
   console.log(res6,props.match.params.otheruserId)
})
    
fetch("https://tapitdabit-backend.vercel.app/ConnectedOther",{
  method: "GET",
  headers :  {
      "Content-Type" : "application/json" , 
  } 
})
.then((res13)=>res13.json())
.then((res444)=>{
    console.log(res444,JSON.parse(localStorage.getItem("User"))._id)
    const Fil = res444.filter((res)=>res._id === props.match.params.otheruserId)
    console.log(Fil)
    setConnectedUser(Fil)
    setname(Fil[0].fullName)
    setEmail(Fil[0].Email)
    setCity(Fil[0].City)
    setRole(Fil[0].Role)
    setDate(Fil[0].Date)
    console.log(Fil[0].Phone[0])
    if(Fil[0].Phone[0] === "+"){
      let s =  ""
      for (let i = 0; i < Fil[0].Phone.length; i++) {
        if(i > 2){
           s = s + Fil[0].Phone[i]
         }
         
        }
        
        console.log(s)
    setPhone(s)
    // setPhone(Fil[0].Phone)
    }
    setJob(Fil[0].Job)
})   
return () => {
  document.getElementById("get1").classList.remove("is-active");
  document.getElementById("get2").style.display = "none";
}
    },[])


   

    const setCon = () =>{
      
    // var vCardsJS = require('vcards-js');
     
    //create a new vCard
    // var vCard = vCardsJS();
     
const myVCard = new VCard()

myVCard.addName(ConnectedUser[0].fullName)

myVCard.addEmail(ConnectedUser[0].Email)
myVCard.addPhoneNumber(ConnectedUser[0].Phone, 'Personal')
myVCard.addPhoneNumber(ConnectedUser[0].Phone, 'WORK')
myVCard.addAddress(ConnectedUser[0].City)
myVCard.addRole(ConnectedUser[0].Role)

    //set properties
    // vCard.firstName = ConnectedUser[0].fullName;
    // vCard.middleName = ' ';
    // vCard.lastName = ConnectedUser[0].fullName;
    // // vCard.organization = 'ACME Corporation';
    // vCard.workPhone = ConnectedUser[0].Phone;
    // // vCard.birthday = new Date(1985, 0, 1);
    // vCard.title =ConnectedUser[0].fullName;
    // // vCard.url = 'https://github.com/enesser';
    // // vCard.note = 'Notes on Eric';
    // vCard.workPhone = ConnectedUser[0].Phone;
    // if(ConnectedUser[0].Image){
    //   vCard.logo = ConnectedUser[0].photo
    //   vCard.photo.attachFromUrl(ConnectedUser[0].photo);

    // }
    // vCard.note = ConnectedUser[0].City
    
    //     vCard.workAddress = ConnectedUser[0].City
    
    //     vCard.role =ConnectedUser[0].Role;
        
    //     vCard.homePhone = ConnectedUser[0].Phone;

    //     vCard.email = ConnectedUser[0].Email;
    const FileSaver = require('file-saver');
const blob = new Blob([ myVCard.toString() ], {type: "text/vcard;charset=utf-8"});
FileSaver.saveAs(blob, ConnectedUser[0].fullName+".vcf");
    }
    

    
const SubmitDataMessage = () =>{
  // e.preventDefault()
  setdis10 ( false)
  let Phone1
  if(UserPhoneCode !== null){
    Phone1 = "+"+UserPhoneCode+Phone
 }
 else {
   swal("select Country Code")
   setdis10 ( true)
     return
   }
   let s = ""
   if(Phone[0] == 0){
    for (let i = 0; i < Phone.length; i++) {
      if(i !== 0){
        s = s + Phone[i]
      }
      
    }
    s ="+"+UserPhoneCode+s
  }
  
                                console.log(ConnectedUser[0]._id,Name,Email,Phone,City,Role,Job)
                              fetch("https://tapitdabit-backend.vercel.app/createConnectedOtherAgain1",{
                                          method: "POST",
                                          headers :  {
                                              "Content-Type" : "application/json" , 
                                          } ,
                                          body : JSON.stringify({
                                              id : ConnectedUser[0]._id,
                                              fullName :Name ,
                                              Email  ,
                                              Phone  : s, 
                                              City ,
                                              Role :Role , 
                                              Job :Job , 
                                              // use : res6.DisPersonal ? "per" : "buss",
                                              // Image : "",
                                              // Image : res2.url ? res2.url : null,
                                              // id : User1._id,
                                              // Date : f ,
                                              // dis  : User1.DisPersonal
                                              // MainLine2 :mainline ,
                                          })
                                      })
                                      .then(res11=>res11.json())
                                      .then((res12)=>{ 
                                          if(res12.Error){
                                            setdis10 ( true)
                                            // setdispaly0( true)
                                            
                                            swal(res12.Error);
                                          }
                                          else{
                                        setdis10 ( true)
                                        // setdispaly0( true)
                                              swal("Successfully Send ");
                                              window.location.reload(true); 
                                              // props.history.push("/user/"+User1.uid)
                                              setConnect(!Connect)
                                              setEmail("")
                                              setRole("")
                                              setCity("")
                                              setJob("")
                                              setPhone("")
                                              setname("")
                                            
                                      }
                                  })
                                 

}

        return (
     

      <div 
        // onScroll={onScrolll()}
        // style={{background: "aliceblue"}}
        >
        
        {/* Slider */}
<br />

<br />
<br />

{!dis10?
                        <Loader
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={10000} //3 secs
        className="loader-1 loader-adjust"
        style={{position : "absolute",top : "30%",left : "47%",zIndex: "99999"}}
      /> 
      :""
}


{Connect ?

<div className="site-section change-padding" style={{position: "absolute", width: "100%",marginTop :  "-60px",
    zIndex:"9999",padding  :"0px"}}>
<center>
  {User1.DisPersonal ? 
    <img className='change-size-image' src={User1.ProfilePhoto ? User1.ProfilePhoto   : x1} alt=""  style={{borderRadius : "90px",border  :"2px solid grey",position: "relative",top: "33px" ,width: "100px",height: "100px",objectFit: "cover"}} />
:
<img className='change-size-image' src={User1.ProfilePhotoBuss ?  User1.ProfilePhotoBuss : x1} alt=""  style={{borderRadius : "90px",border  :"2px solid grey",position: "relative",top: "33px" ,width: "100px",height: "100px",objectFit: "cover"}} />
  }
  </center>
                    <div className="container div-size-respon" style={{width : "35%", borderRadius : "20px",background: "white",padding : "0px"}}>
                        <div className="row">
                            <div className="col-md-12">
                                <div>

                                {/* <form onSubmit={(e)=>Submitdata(e)}> */}
                                    <div className="p-3 p-lg-5" style={{ borderRadius : "20px",boxShadow: "1px 1px 10px grey"}}>
                                       {/* <center>
                                        <h4 className='change-texts' style={{color : "black"}}>
                                        Share your info back 
                                        <br />
                                        with Card owner
                                        <br /> <b>
                                          {User1.DisPersonal  ? User1.Name  : User1.NameBuss}
                                          
                                          </b> 
                                          </h4>
                                       </center> */}
                                       <span onClick={()=>setConnect(!Connect)} style={{position: "absolute",top: "10px",right: "10%", color : "black",fontSize : "27px",fontWeight : "400"}}>x</span>
                                       {/* <br /> */}
                                          <br />
                                       <hr />
                                       {/* <br /> */}
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <input type="text" value={Email} onChange={(e)=>setEmail(e.target.value) }  className="form-control" id="inputPassword4" placeholder="Email" />
                                            </div>
                                            </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <input type="text" value={Name} onChange={(e)=>setname(e.target.value) }  className="form-control" id="inputPassword4" placeholder="Name" />
                                            </div>
                                            </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <div style={{display : "flex" , justifyContent : "flex-start"}}>
                                            <select name="" id=""  value={UserPhoneCode} onChange={(e)=>setUserPhoneCode(e.target.value)}  style={{width : "107px" , fontSize :"12px" , height: "42px"}}>
                                              
                                              {
                                                PhoneCode.map((res,i)=>{
                                                  return(
                                                    <option value={res.phoneCode} >{res.value}  +{res.phoneCode}</option>
                                                  )
                                                })
                                              }
                                            </select>
                                        <input type="text" required value={Phone} onChange={(e)=>setPhone(e.target.value) }  className="form-control" id="inputPassword4" placeholder="Phone Number"/>
                                          </div>
                                                                                      </div>
                                       
                                        </div>





                                        {/* {
                                  dis20 ?

                              <> */}

                              {/* <div className="form-group row">
                                         
                                        <div className="col-md-12">
                                        <input type="file" onChange={(e)=>setImage(e.target.files[0])}  className="form-control" id="inputAddress2" placeholder="Upload Image"/>
                                    </div>
                                    </div> */}
                                    <div className="form-group row">
                                          <div className="col-md-12">
                                        <input type="text"  value={Job} onChange={(e)=>setJob(e.target.value) }  className="form-control" id="inputPassword4" placeholder="job Title"/>
                                      </div> 
                                     
                                </div>
                              <div className="form-group row">
                                          <div className="col-md-12">
                                        <input type="text"  value={City} onChange={(e)=>setCity(e.target.value) }  className="form-control" id="inputPassword4" placeholder="Company"/>
                                      </div> 
                                     
                                </div>
                                <div className="form-group row">
                                   <div className="col-md-12">
                                        <textarea type="text" cols="10" rows="2" value={Role} onChange={(e)=>setRole(e.target.value) }  className="form-control" id="inputPassword4" placeholder="Notes On this Interaction"/>
                                      </div>
                                       
                                </div> 

                              {/* </>
: 
<p onClick={() => setdis20(!dis20) } style={{color : "rgb(52, 137, 243)"  ,fontSize : "18px", fontWeight : "bold"}}>



<span>
  + Add More
</span>

</p>

                                } */}
                              

                              <div className="form-row">
                                          <div className="form-group col-md-12">
                                            <center>
                                            {dis10 ?

<button onClick={()=>SubmitDataMessage()} style={{width : "200px", padding : "10px 0px",   background : "rgb(52, 137, 243)" ,  color  : "white", borderRadius :  "30px" ,fontSize : "18px"}}>Connect</button>
                                            :

<div  style={{minWidth : "200px", maxWidth : "200px" , padding : "10px 0px",   background : "rgb(52, 137, 243)" ,  color  : "white", borderRadius :  "30px" ,fontSize : "18px"}}>Connect</div>
                                            }
                                            </center>

                                </div>
                                </div>
                                      <center>
                                        <p style={{color : "black"}}>
                                        <b>
                                            TapitDabit does not sell your data
                                        </b>
                                        </p>
                                        </center>
                                    </div>
                                </div>
                            </div>
                         
                        </div>
                    </div>
                </div>


: ""

}













{/* 
          <div className="cc1" style={{justifyContent : "flex-start",height:"auto"}}>
              <div className="container1" id="container1" style={{margin :"92px 0px" , minHeight: "376px",width : "100%"}} >
                <img src={User1.ProfilePhoto ? User1.ProfilePhoto : x1} width= "50%" height= "376px" alt="" />
                <div className="overlay-container">
                  <div className="overlay" style={{background : "skyblue"}}>
                    <div className="overlay-panel overlay-right">
                    <h1 className="h22">Hello, {User1.user}</h1>
                      <p className="ppp1">{User1.bio}</p>
                    </div>
                  </div>
                </div>
              </div>
          </div>
         */}


          <center>
                {ConnectedUser[0] &&ConnectedUser[0].photo !== ""  ? 
                    <img src={ConnectedUser[0].photo} alt="" style={{borderRadius : "90px",width: "100px",height: "100px",objectFit: "cover"}}/>
                    : 
                <img src={x1} alt="" style={{borderRadius : "90px",width: "100px",height: "100px",objectFit: "cover"}}/>
                }
            </center>


                <center>
                <h4>
                  {ConnectedUser[0] &&ConnectedUser[0].fullName}
                  </h4>
                </center>
                <center>

                <button onClick={()=>setConnect(!Connect)} style={{borderRadius : "15px", fontWeight : "bold",margin : "0px 20px",padding :"6px 20px"}} type="button" class="btn btn-outline-secondary"> Edit</button>
                <button onClick={()=>setCon()} style={{borderRadius : "15px", fontWeight : "bold"}} type="button" class="btn btn-outline-secondary"> Save Contact</button>
                </center>
<br />
                {/* <center> */}
                  <div style={{margin :  "auto"  ,width : "310px"}}>
                      <div>
                          <p style={{width : "310px" , background :  "rgb(244, 244, 244)" , border : "1px solid grey" , borderRadius : "10px" , color : "grey" ,fontSize : "17px", padding : "13px 2px 13px 10px"}}>Email : <span style={{color : "black" ,paddingLeft :"20px"}}>{ConnectedUser[0] &&ConnectedUser[0].Email}</span></p>
                      </div>
                      <div>
                          <p style={{width : "310px" , background :  "rgb(244, 244, 244)" , border : "1px solid grey" , borderRadius : "10px" , color : "grey" ,fontSize : "17px", padding : "13px 2px 13px 10px"}}>Name : <span style={{color : "black" ,paddingLeft :"20px"}}>{ConnectedUser[0] &&ConnectedUser[0].fullName}</span></p>
                      </div>
                      <div>
                          <p style={{width : "310px" , background :  "rgb(244, 244, 244)" , border : "1px solid grey" , borderRadius : "10px" , color : "grey" ,fontSize : "17px", padding : "13px 2px 13px 10px"}}>Phone : <span style={{color : "black" ,paddingLeft :"20px"}}>{ConnectedUser[0] &&ConnectedUser[0].Phone}</span></p>
                      </div>
                      <div>
                          <p style={{width : "310px" , background :  "rgb(244, 244, 244)" , border : "1px solid grey" , borderRadius : "10px" , color : "grey" ,fontSize : "17px", padding : "13px 2px 13px 10px"}}>Date : <span style={{color : "black" ,paddingLeft :"27px"}}>{ConnectedUser[0] &&ConnectedUser[0].Date}</span></p>
                      </div>
                      <div>
                          <p style={{width : "310px" , background :  "rgb(244, 244, 244)" , border : "1px solid grey" , borderRadius : "10px" , color : "grey" ,fontSize : "17px", padding : "13px 2px 13px 10px"}}>Note : <span style={{color : "black" ,paddingLeft :"20px"}}>{ConnectedUser[0] &&ConnectedUser[0].Role}</span></p>
                      </div>
                      <div>
                          {/* <p style={{width : "310px" , background :  "rgb(244, 244, 244)" , border : "1px solid grey" , borderRadius : "10px" , color : "grey" ,fontSize : "17px", padding : "13px 2px 13px 10px"}}>Address : <span style={{color : "black" ,paddingLeft :"20px"}}>{ConnectedUser[0] &&ConnectedUser[0].City}</span></p> */}
                          <p style={{width : "310px" , background :  "rgb(244, 244, 244)" , border : "1px solid grey" , borderRadius : "10px" , color : "grey" ,fontSize : "17px", padding : "13px 2px 13px 10px"}}>Job : <span style={{color : "black" ,paddingLeft :"20px"}}>{ConnectedUser[0] &&ConnectedUser[0].Job}</span></p>
                      </div>
                      <div>
                          {/* <p style={{width : "310px" , background :  "rgb(244, 244, 244)" , border : "1px solid grey" , borderRadius : "10px" , color : "grey" ,fontSize : "17px", padding : "13px 2px 13px 10px"}}>Address : <span style={{color : "black" ,paddingLeft :"20px"}}>{ConnectedUser[0] &&ConnectedUser[0].City}</span></p> */}
                          <p style={{width : "310px" , background :  "rgb(244, 244, 244)" , border : "1px solid grey" , borderRadius : "10px" , color : "grey" ,fontSize : "17px", padding : "13px 2px 13px 10px"}}>Company : <span style={{color : "black" ,paddingLeft :"20px"}}>{ConnectedUser[0] &&ConnectedUser[0].City}</span></p>
                      </div>
                  </div>
                {/* </center> */}


        {/* Back to top */}
        <div className="btn-back-to-top" id="myBtn">
          <span className="symbol-btn-back-to-top">
            <i className="zmdi zmdi-chevron-up" />
          </span>
        </div>
        {/* Modal1 */}
        <div className="wrap-modal1 js-modal1 p-t-60 p-b-20">
          <div className="overlay-modal1 js-hide-modal1" />
          <div className="container">
            <div className="bg0 p-t-60 p-b-30 p-lr-15-lg how-pos3-parent">
              <button className="how-pos3 hov3 trans-04 js-hide-modal1">
                <img src="images/icons/icon-close.png" alt="CLOSE" />
              </button>
              <div className="row">
                <div className="col-md-6 col-lg-7 p-b-30">
                  <div className="p-l-25 p-r-30 p-lr-0-lg">
                    <div className="wrap-slick3 flex-sb flex-w">
                      <div className="wrap-slick3-dots" />
                      <div className="wrap-slick3-arrows flex-sb-m flex-w" />
                      <div className="slick3 gallery-lb">
                        <div className="item-slick3" data-thumb="images/product-detail-01.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-01.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-01.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-02.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-02.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-02.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-03.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-03.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-03.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5 p-b-30">
                  <div className="p-r-50 p-t-5 p-lr-0-lg">
                    <h4 className="mtext-105 cl2 js-name-detail p-b-14">
                      Lightweight Jacket
                    </h4>
                    <span className="mtext-106 cl2">
                      $58.79
                    </span>
                    <p className="stext-102 cl3 p-t-23">
                      Nulla eget sem vitae eros pharetra viverra. Nam vitae luctus ligula. Mauris consequat ornare feugiat.
                    </p>
                    {/*  */}
                    <div className="p-t-33">
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Size
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Size S</option>
                              <option>Size M</option>
                              <option>Size L</option>
                              <option>Size XL</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Color
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Red</option>
                              <option>Blue</option>
                              <option>White</option>
                              <option>Grey</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-204 flex-w flex-m respon6-next">
                          <div className="wrap-num-product flex-w m-r-20 m-tb-10">
                            <div className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-minus" />
                            </div>
                            <input className="mtext-104 cl3 txt-center num-product" type="number" name="num-product" defaultValue={1} />
                            <div className="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-plus" />
                            </div>
                          </div>
                          <button className="flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04 js-addcart-detail">
                            Add to cart
                          </button>
                        </div>
                      </div>	
                    </div>
                    {/*  */}
                    <div className="flex-w flex-m p-l-100 p-t-40 respon7">
                      <div className="flex-m bor9 p-r-10 m-r-11">
                        <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 js-addwish-detail tooltip100" data-tooltip="Add to Wishlist">
                          <i className="zmdi zmdi-favorite" />
                        </a>
                      </div>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Facebook">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Twitter">
                        <i className="fa fa-twitter" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Google Plus">
                        <i className="fa fa-google-plus" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
 






        )
    
}

export default Home;