import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"
import swal from 'sweetalert';
// import $ from 'jquery';
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';


import "react-multi-carousel/lib/styles.css";
import "react-multi-carousel/lib/styles.css";
import "./../assets/css/main.css"
import "./../assets/css/util.css"
import "./../assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css"

// import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
// import AssessmentIcon from '@material-ui/icons/Assessment';
// import DashboardIcon from '@material-ui/icons/Dashboard';
// import SortIcon from '@material-ui/icons/Sort';



// import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"

import "./../assets/vendor/css-hamburgers/hamburgers.min.css"

import "./../assets/fonts/iconic/css/material-design-iconic-font.min.css"
import "./../assets/vendor/bootstrap/css/bootstrap.min.css"
import "./../assets/vendor/select2/select2.min.css"
import "./../assets/vendor/slick/slick.css"
import "./../assets/vendor/perfect-scrollbar/perfect-scrollbar.css"

// import Logo from "./../assets/images/icons/logo-01.png"

// import b1 from "./../assets/images/banner-01.jpg"
// import b2 from "./../assets/images/banner-02.jpg"
// import b3 from "./../assets/images/banner-03.jpg"


// import a1 from "./../assets/images/a1.png"
// import a2 from "./../assets/images/a2.png"
// import a3 from "./../assets/images/a3.png"
// import a4 from "./../assets/images/a4.png"
// import a5 from "./../assets/images/a5.png"
// import a6 from "./../assets/images/a6.png"
// import a7 from "./../assets/images/a7.png"
// import a8 from "./../assets/images/a8.png"

// import a9 from "./../assets/images/a9.png"

// import a11 from "./../assets/images/a11.png"
import a21 from "./../assets/images/a21.png"
import a22 from "./../assets/images/a22.png"
import a23 from "./../assets/images/a23.png"
import a24 from "./../assets/images/a24.png"
import a25 from "./../assets/images/a25.png"
// import a27 from "./../assets/images/a27.png"


// import z1 from "./../assets/images/z1.png"
// import z2 from "./../assets/images/z2.png"
// import z3 from "./../assets/images/z3.png"
// import z4 from "./../assets/images/z4.png"


// import c1 from "./../assets/images/c1.png"
// import c2 from "./../assets/images/c2.png"
// import c3 from "./../assets/images/c3.png"
// import c4 from "./../assets/images/c4.png"






// import p2 from "./../assets/images/product-02.jpg"
// import p3 from "./../assets/images/product-03.jpg"
// import p4 from "./../assets/images/product-04.jpg"
// import p5 from "./../assets/images/product-05.jpg"
// import p6 from "./../assets/images/product-06.jpg"



// import h1 from "./../assets/images/icons/icon-heart-01.png"
// import h2 from "./../assets/images/icons/icon-heart-02.png"



// import s1 from "./../assets/images/slide-01.jpg"
// import s2 from "./../assets/images/slide-02.jpg"
// import s3 from "./../assets/images/slide-03.jpg"






const Home = ( props ) => {
    const [myRef,setRef] = useState( React.createRef())
    // const [scrollTop1,setscrollTop] = useState(0)
    const [email , setemail] = useState("")
    const [pass , setpass] = useState("")
    const [dispaly ,  setdispaly ] = useState(true)
    const [User1 ,  setUser1 ] = useState({})
    const [Num ,  setNum ] = useState(0)


    useEffect(() =>{
      window.scrollTo(0, 0)
if(! JSON.parse(localStorage.getItem("User"))){

        props.match.history.push("/login")
      }
      else{

        localStorage.setItem("VenderWorkingThose" ,JSON.stringify(123)) 
        setUser1(JSON.parse(localStorage.getItem("User")))
        setNum(JSON.parse(localStorage.getItem("the1")))
        // getLocation()
        // var x = document.getElementById("demo");
        function showPosition(position) {
          // var x = document.getElementById("demo");
          console.log(position.coords.latitude , position.coords.longitude)
          // x.innerHTML = "Latitude: " + position.coords.latitude + 
          // "<br>Longitude: " + position.coords.longitude;
        }
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(showPosition);
        } 
        else { 
          // x.innerHTML = "Geolocation is not supported by this browser.";
        }
        
      }


    // console.log(scrollTop1)
    return () => {
      document.getElementById("get1").classList.remove("is-active");
      document.getElementById("get2").style.display = "none";
    }

    },[])

    useEffect( () => () => localStorage.removeItem("VenderWorkingThose"), [] );

    const setChange = () =>{
        if(document.getElementById("get2").style.display === "none"){
            document.getElementById("get1").classList.add("is-active");
            document.getElementById("get2").style.display = "block";
        }
        else{
            document.getElementById("get1").classList.remove("is-active");
            document.getElementById("get2").style.display = "none";
        }
    }

    const setChange1 = (e) =>{
      if(e === 1 ){
        // document.getElementById("signUp").classList.add("right-panel-active");
        // document.getElementById("signIn").classList.remove("right-panel-active");
        document.getElementById("container1").classList.add("right-panel-active")
      }
      else{
        document.getElementById("container1").classList.remove("right-panel-active")
      }
    }




const postData = (e) =>{
  e.preventDefault()
  if(email.length === 0){
      // if (email === "projectpharma874@gmail.com")
      swal("Enter The Correct Email !" ,{
        buttons: false,
        timer: 3000,
      })
         return
      }
  if(pass.length === 0){
      swal("Enter The Password !" ,{
        buttons: false,
        timer: 3000,
      } )
         return
       }
 
    fetch("https://tapitdabit-backend.vercel.app/logIn-user",{
      method: 'POST' , 
      headers :  {
        "Content-Type" : "application/json" , 
      } , 
      body : JSON.stringify({
        email  ,
        pass  ,
      })
    })
    .then((res)=>res.json())
    .then((res2)  =>{
        console.log(res2)
        if (res2 !== null && !res2.Error  ){
          if(!res2.isSettings){
             swal("SucessFully Login" ,{
              buttons: false,
              timer: 3000,
            } )
          localStorage.setItem("User" , JSON.stringify(res2) )
              props.history.push("/settings-profile-vender")
            }
            else{
           swal("SucessFully Login" ,{
            buttons: false,
            timer: 3000,
          } )
        localStorage.setItem("User" , JSON.stringify(res2) )
            props.history.push("/")
         }
        }
        else{
          if(res2.Error === "Plz Fill Setting first"){
              props.history.push("/settings-profile-vender")
          }
          swal(res2.Error ,{
            buttons: false,
            timer: 3000,
          })
        }
      // console.log(res2)
    })
    .catch((err)=>console.log(err))
  

}


const  setPassShow = (e)=>{
  setdispaly(e)
}



const SubmitDataMessage = (e) =>{
  e.preventDefault()


  
  
  // console.log("wddde");
  let f
  // if(Num === 21){
    //   f = "text"
    // }
    if(Num === 23){
      f = "address"
    }
    // else if(Num === 22){
      //   f = "whatsapps"
      // }
      // else if(Num === 24){
      //   f = "email"
      // }
      // else if(Num === 25){
        //   f = "phone"
        // }
        else {
          
        }
        if(email === ""){
          swal ("Feild are Missing",{
            buttons: false,
            timer: 3000,
          })
          return
        }
        const d = User1.Social.filter((res,i)=>res.name === "address")
      if(d.length === 0){
        setdispaly ( false)

                              fetch("https://tapitdabit-backend.vercel.app/createaddressLink",{
                                          method: "POST",
                                          headers :  {
                                              "Content-Type" : "application/json" , 
                                          } ,
                                          body : JSON.stringify({
                                            name  : f,
                                            link : email,
                                            id : User1._id
                                              // MainLine2 :mainline ,
                                          })
                                      })
                                      .then(res11=>res11.json())
                                      .then((res12)=>{ 
                                          if(!res12.Error){
                                            setdispaly ( true)
                                              swal("Successfully Upload your Product!",{
                                                buttons: false,
                                                timer: 3000,
                                              });
                                              setTimeout(()=>{

                                                props.history.push("/user/home/"+User1._id)
                                              },1500)
                                              this.setState({
                                                  email: "",
                                          })
                                        }
                                        else{ setdispaly ( true)
                                        swal(res12.Error,{
                                          buttons: false,
                                          timer: 3000,
                                        });
                                      }
                                  })
                                  .catch(err=>{ setdispaly ( true)
                                      swal("There is An Error",{
                                        buttons: false,
                                        timer: 3000,
                                      })                  
                                      }) 
      }
      else{
        const c = User1.ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
        props.history.push("/user/home/"+User1._id)

      }
        

}

const setRouting1 = (e)=>{
  if(e === 1){
    props.history.push("/user/home1/"+User1._id)
  }
  else if(e === 4){
    props.history.push("/user/editProfile/sort-delete/"+User1.uid)
  }
  else if(e === 3){
    // props.history.push("/user/analytics/"+User1._id)   

    const d =  JSON.parse(localStorage.getItem("User")).ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
    console.log(d)
    
      
    if (d.length > 0){
      let a = new Date()
      let f= a.getDate() +" "+(a.getUTCMonth()+1)+ " "+a.getUTCFullYear()
      console.log(d[0].End[0]+""+d[0].End[1] >= a.getDate(), d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ,d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear())
      if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] > a.getUTCFullYear()     ){
      // if(d[0].End && d[0].End >= f){
    props.history.push("/user/analytics/"+User1._id)   

      }
      else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear()){
        if( d[0].End[3]+""+d[0].End[4] >a.getUTCMonth()+1 ){
      props.history.push("/user/analytics/"+User1._id)   

        }
        else{
          if(d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1){
            if( d[0].End[0]+""+d[0].End[1] >=a.getDate()){
          props.history.push("/user/analytics/"+User1._id)   

            }
            else{
              // document.getElementById("myDivUmer12").style.display= "block"

                              props.history.push("/user/pro-feather")

              // localStorage.setItem("request123",JSON.stringify(1) )
              // props.history.push("/user/request-for-pro-item")
              // swal("Sorry First You Buy")
  
            }
          }
          else{
            // document.getElementById("myDivUmer12").style.display= "block"

                            props.history.push("/user/pro-feather")

            // localStorage.setItem("request123",JSON.stringify(1) )
            //   props.history.push("/user/request-for-pro-item")
            //   swal("Sorry First You Buy")
  
            }
          }
        }
      else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear() && d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ){
          if( d[0].End[0]+""+d[0].End[1] >=a.getDate()){
        props.history.push("/user/analytics/"+User1._id)   

        }
        else{
          // document.getElementById("myDivUmer12").style.display= "block"

                          props.history.push("/user/pro-feather")

          // localStorage.setItem("request123",JSON.stringify(1) )
          // props.history.push("/user/request-for-pro-item")
          // swal("Sorry First You Buy")
  
        }
      }
      else{
        // document.getElementById("myDivUmer12").style.display= "block"

                        props.history.push("/user/pro-feather")

        // localStorage.setItem("request123",JSON.stringify(1) )
        //   props.history.push("/user/request-for-pro-item")
        //   swal("Sorry First You Buy")
  
      }
    }
    else{
      // document.getElementById("myDivUmer12").style.display= "block"

                      props.history.push("/user/pro-feather")

      // localStorage.setItem("request123",JSON.stringify(1) )
      //   props.history.push("/user/request-for-pro-item")
      //   swal("Sorry First You Buy")
  }
  }
  else if(e === 2){
    props.history.push("/user/connect/myprofile/"+User1.uid)
  }
  else{

  }
}
    
const setChanger8 = (e)=>{
  // document.getElementById("myDivUmer12").style.display= "block"
}
                props.history.push("/user/pro-feather")


        return (
            






      <div
        // onScroll={onScrolll()}
        >
       
        {/* Slider */}







{Num === 21 ? 

<>
<br/>






              <section>
                    <div className="container mi2" style={{margin : "70px auto" , width : "97%"}}>
                            <div className="row justify-content-center">
                                <div className="col-12 text-center">
                                    <h2 style={{color : "black"}} > <b>Text </b> </h2>
                                    
                                </div> 

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5">
                                                <Link to={"/main-categories/"}><img src={a21} alt="" className="arrival-Photo111"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div>
                </section>

           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage(e)}>
                   
                    <input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here" /><br/>
                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                    <div style={{display : "flex" , justifyContent : "space-between"}}>
                        <button className="button11" style={{width : "200px" , background : "rgb(211, 211, 211)" , color  : "black" , borderRadius :  "3px" , border : "3px solid black",fontSize : "18px"}}>Open</button>
                        <button className="button11" type="submit" style={{width : "200px",   background : "rgb(0, 0, 0)" ,  color  : "white", borderRadius :  "3px" , border : "3px solid rgb(211, 211, 211)",fontSize : "18px"}}>Save</button>
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}

{Num === 22 ? 

<>
<br/>






              <section>
                    <div className="container mi2" style={{margin : "70px auto" , width : "97%"}}>
                            <div className="row justify-content-center">
                                <div className="col-12 text-center">
                                    <h2 style={{color : "black"}} > <b>Whatsapps </b> </h2>
                                    
                                </div> 

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5">
                                                <Link to={"/main-categories/"}><img src={a22} alt="" className="arrival-Photo111"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div>
                </section>

           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage(e)}>
                   
                    <input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here" /><br/>
                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                    <div style={{display : "flex" , justifyContent : "space-between"}}>
                        <button className="button11" style={{width : "200px" , background : "rgb(211, 211, 211)" , color  : "black" , borderRadius :  "3px" , border : "3px solid black",fontSize : "18px"}}>Open</button>
                        <button className="button11" style={{width : "200px",   background : "rgb(0, 0, 0)" ,  color  : "white", borderRadius :  "3px" , border : "3px solid rgb(211, 211, 211)",fontSize : "18px"}}>Save</button>
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}

{Num === 23 ? 

<>
<br/>






              <section>
                    <div className="container mi2" style={{margin : "70px auto" , width : "97%"}}>
                            <div className="row justify-content-center">
                                <div className="col-12 text-center">
                                    <h2 style={{color : "black"}} > <b>Address </b> </h2>
                                    
                                </div> 

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5">
                                                <Link to={"/main-categories/"}><img src={a23} alt="" className="arrival-Photo111"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div>
                </section>

           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage(e)}>
                   
                    <input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here" /><br/>
                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                    <div style={{display : "flex" , justifyContent : "space-between"}}>
                        <button className="button11" style={{width : "200px" , background : "rgb(211, 211, 211)" , color  : "black" , borderRadius :  "3px" , border : "3px solid black",fontSize : "18px"}}>Open</button>
                        <button className="button11" style={{width : "200px",   background : "rgb(0, 0, 0)" ,  color  : "white", borderRadius :  "3px" , border : "3px solid rgb(211, 211, 211)",fontSize : "18px"}}>Save</button>
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}

{Num === 24 ? 

<>
<br/>






              <section>
                    <div className="container mi2" style={{margin : "70px auto" , width : "97%"}}>
                            <div className="row justify-content-center">
                                <div className="col-12 text-center">
                                    <h2 style={{color : "black"}} > <b>Email
                                     </b> </h2>
                                    
                                </div> 

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5">
                                                <Link to={"/main-categories/"}><img src={a24} alt="" className="arrival-Photo111"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div>
                </section>

           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage(e)}>
                   
                    <input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here" /><br/>
                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                    <div style={{display : "flex" , justifyContent : "space-between"}}>
                        <button className="button11" style={{width : "200px" , background : "rgb(211, 211, 211)" , color  : "black" , borderRadius :  "3px" , border : "3px solid black",fontSize : "18px"}}>Open</button>
                        <button className="button11" style={{width : "200px",   background : "rgb(0, 0, 0)" ,  color  : "white", borderRadius :  "3px" , border : "3px solid rgb(211, 211, 211)",fontSize : "18px"}}>Save</button>
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}

{Num === 25 ? 

<>
<br/>






              <section>
                    <div className="container mi2" style={{margin : "70px auto" , width : "97%"}}>
                            <div className="row justify-content-center">
                                <div className="col-12 text-center">
                                    <h2 style={{color : "black"}} > <b>Phone
                                     </b> </h2>
                                    
                                </div> 

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5">
                                                <Link to={"/main-categories/"}><img src={a25} alt="" className="arrival-Photo111"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div>
                </section>

           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage(e)}>
                   
                    <input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here" /><br/>
                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                    <div style={{display : "flex" , justifyContent : "space-between"}}>
                        <button className="button11" style={{width : "200px" , background : "rgb(211, 211, 211)" , color  : "black" , borderRadius :  "3px" , border : "3px solid black",fontSize : "18px"}}>Open</button>
                        <button className="button11" style={{width : "200px",   background : "rgb(0, 0, 0)" ,  color  : "white", borderRadius :  "3px" , border : "3px solid rgb(211, 211, 211)",fontSize : "18px"}}>Save</button>
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}


         
              




          
        


        {/* Back to top */}
        <div className="btn-back-to-top" id="myBtn">
          <span className="symbol-btn-back-to-top">
            <i className="zmdi zmdi-chevron-up" />
          </span>
        </div>
        {/* Modal1 */}
        <div className="wrap-modal1 js-modal1 p-t-60 p-b-20">
          <div className="overlay-modal1 js-hide-modal1" />
          <div className="container">
            <div className="bg0 p-t-60 p-b-30 p-lr-15-lg how-pos3-parent">
              <button className="how-pos3 hov3 trans-04 js-hide-modal1">
                <img src="images/icons/icon-close.png" alt="CLOSE" />
              </button>
              <div className="row">
                <div className="col-md-6 col-lg-7 p-b-30">
                  <div className="p-l-25 p-r-30 p-lr-0-lg">
                    <div className="wrap-slick3 flex-sb flex-w">
                      <div className="wrap-slick3-dots" />
                      <div className="wrap-slick3-arrows flex-sb-m flex-w" />
                      <div className="slick3 gallery-lb">
                        <div className="item-slick3" data-thumb="images/product-detail-01.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-01.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-01.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-02.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-02.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-02.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-03.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-03.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-03.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5 p-b-30">
                  <div className="p-r-50 p-t-5 p-lr-0-lg">
                    <h4 className="mtext-105 cl2 js-name-detail p-b-14">
                      Lightweight Jacket
                    </h4>
                    <span className="mtext-106 cl2">
                      $58.79
                    </span>
                    <p className="stext-102 cl3 p-t-23">
                      Nulla eget sem vitae eros pharetra viverra. Nam vitae luctus ligula. Mauris consequat ornare feugiat.
                    </p>
                    {/*  */}
                    <div className="p-t-33">
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Size
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Size S</option>
                              <option>Size M</option>
                              <option>Size L</option>
                              <option>Size XL</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Color
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Red</option>
                              <option>Blue</option>
                              <option>White</option>
                              <option>Grey</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-204 flex-w flex-m respon6-next">
                          <div className="wrap-num-product flex-w m-r-20 m-tb-10">
                            <div className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-minus" />
                            </div>
                            <input className="mtext-104 cl3 txt-center num-product" type="number" name="num-product" defaultValue={1} />
                            <div className="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-plus" />
                            </div>
                          </div>
                          <button className="flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04 js-addcart-detail">
                            Add to cart
                          </button>
                        </div>
                      </div>	
                    </div>
                    {/*  */}
                    <div className="flex-w flex-m p-l-100 p-t-40 respon7">
                      <div className="flex-m bor9 p-r-10 m-r-11">
                        <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 js-addwish-detail tooltip100" data-tooltip="Add to Wishlist">
                          <i className="zmdi zmdi-favorite" />
                        </a>
                      </div>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Facebook">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Twitter">
                        <i className="fa fa-twitter" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Google Plus">
                        <i className="fa fa-google-plus" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
 






        )
    
}

export default Home;