import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"
import swal from 'sweetalert';

import ppp11 from "./../assets/images/ba1.jpeg"
import contact1 from "./../assets/images/CONTACT 1.png"
import Loader from "react-loader-spinner";

import "react-multi-carousel/lib/styles.css";
import "react-multi-carousel/lib/styles.css";
import "./../assets/css/main.css"
import "./../assets/css/util.css"
import "./../assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css"
import VCard from 'vcard-creator'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";



import "./../assets/vendor/css-hamburgers/hamburgers.min.css"

import "./../assets/fonts/iconic/css/material-design-iconic-font.min.css"
import "./../assets/vendor/bootstrap/css/bootstrap.min.css"
import "./../assets/vendor/select2/select2.min.css"
import "./../assets/vendor/slick/slick.css"
import "./../assets/vendor/perfect-scrollbar/perfect-scrollbar.css"

import x1 from "./../assets/images/x1.jpg"

import a111 from "./../assets/images/1.png"





import ln1 from "./../assets/images/svg/social/f linkedin.svg"
import tw1 from "./../assets/images/svg/social/f twitter.svg"
import in1 from "./../assets/images/svg/social/f instagram.svg"
import pi1 from "./../assets/images/svg/social/f pinterest.svg"
import tt1 from "./../assets/images/svg/social/twitch (1).svg"
import tk1 from "./../assets/images/svg/social/tiktok.svg"
import you1 from "./../assets/images/svg/social/youtube (2).svg"
import sc1 from "./../assets/images/svg/social/snapchat.svg"
import fb1 from "./../assets/images/svg/social/f facebook.svg"


import bk1 from "./../assets/images/svg/Payments/bank (9).svg"
import ca1 from "./../assets/images/svg/Payments/cashapp.svg"
import ep1 from "./../assets/images/svg/Payments/easypaisa.png"
import jc1 from "./../assets/images/svg/Payments/SELECT JAZZ CASH LOGO/jazzcash.png"
import ve1 from "./../assets/images/svg/Payments/venmo.svg"
import pp1 from "./../assets/images/svg/Payments/paypal (1).svg"

import we1 from "./../assets/images/svg/MORE/WEBSITE.svg"
import call1 from "./../assets/images/svg/cotact info/call.svg"
import cd1 from "./../assets/images/svg/cotact info/ccard.svg"
import lo1 from "./../assets/images/svg/cotact info/location.svg"
import mail1 from "./../assets/images/svg/cotact info/mail.svg"
import wa1 from "./../assets/images/svg/cotact info/whatapps.svg"
import mess1 from "./../assets/images/svg/cotact info/message (2).svg"







const Home = ( props ) => {
    // const [myRef,setRef] = useState( React.createRef())
    // const [scrollTop1,setscrollTop] = useState(0)
    // const [email , setemail] = useState("")
    // const [pass , setpass] = useState("")
    const [dispaly ,  setdispaly ] = useState(true)
    const [dis1 ,  setdis1 ] = useState(true)
    const [dis2 ,  setdis2 ] = useState(true)
    const [dis3 ,  setdis3 ] = useState(true)
    const [dis4 ,  setdis4 ] = useState(false)
    // const [dis5 ,  setdis5 ] = useState(false)
    const [dis6 ,  setdis6 ] = useState(true)
    const [User1 ,  setUser1 ] = useState({})
    const [Arr1 ,  setArr1 ] = useState([])
    
    const [Connect ,  setConnect ] = useState(false)
    
    const [Image , setImage] = useState("")
     const [Name , setname] = useState("")
     const [Email , setEmail] = useState("")
     const [Phone , setPhone] = useState("")
     const [Job , setJob] = useState("")
     const [Gender , setGender] = useState("")
     const [City , setCity] = useState("")
     const [Role , setRole] = useState("")
     const [url , seturl] = useState("")
     const [dis10 ,  setdis10 ] = useState(true)
     const [dis20 ,  setdis20 ] = useState(false)

     const [UserPhoneCode ,  setUserPhoneCode ] = useState("92")
     const [PhoneCode ,  setPhoneCode ] = useState([
       {
         code: 'PK', map: '🇵🇰', phoneCode: 92, value: 'Pakistan',
       },
       {
         code: 'AF', map: '🇦🇫', phoneCode: 93, value: 'Afghanistan',
       },
       {
         code: 'AL', map: '🇦🇱', phoneCode: 355, value: 'Albania',
       },
       {
         code: 'DZ', map: '🇩🇿', phoneCode: 213, value: 'Algeria',
       },
       {
         code: 'AS', map: '🇦🇸', phoneCode: 1684, value: 'American Samoa',
       },
       {
         code: 'AD', map: '🇦🇩', phoneCode: 376, value: 'Andorra',
       },
       {
         code: 'AO', map: '🇦🇴', phoneCode: 244, value: 'Angola',
       },
       {
         code: 'AI', map: '🇦🇮', phoneCode: 1264, value: 'Anguilla',
       },
       {
         code: 'AQ', map: '🇦🇶', phoneCode: 0, value: 'Antarctica',
       },
       {
         code: 'AR', map: '🇦🇷', phoneCode: 54, value: 'Argentina',
       },
       {
         code: 'AM', map: '🇦🇲', phoneCode: 374, value: 'Armenia',
       },
       {
         code: 'AW', map: '🇦🇼', phoneCode: 297, value: 'Aruba',
       },
       {
         code: 'AU', map: '🇦🇺', phoneCode: 61, value: 'Australia',
       },
       {
         code: 'AT', map: '🇦🇹', phoneCode: 43, value: 'Austria',
       },
       {
         code: 'AZ', map: '🇦🇿', phoneCode: 994, value: 'Azerbaijan',
       },
       {
         code: 'BH', map: '🇧🇭', phoneCode: 973, value: 'Bahrain',
       },
       {
         code: 'BD', map: '🇧🇩', phoneCode: 880, value: 'Bangladesh',
       },
       {
         code: 'BB', map: '🇧🇧', phoneCode: 1246, value: 'Barbados',
       },
       {
         code: 'BY', map: '🇧🇾', phoneCode: 375, value: 'Belarus',
       },
       {
         code: 'BE', map: '🇧🇪', phoneCode: 32, value: 'Belgium',
       },
       {
         code: 'BZ', map: '🇧🇿', phoneCode: 501, value: 'Belize',
       },
       {
         code: 'BJ', map: '🇧🇯', phoneCode: 229, value: 'Benin',
       },
       {
         code: 'BM', map: '🇧🇲', phoneCode: 1441, value: 'Bermuda',
       },
       {
         code: 'BT', map: '🇧🇹', phoneCode: 975, value: 'Bhutan',
       },
       {
         code: 'BO', map: '🇧🇴', phoneCode: 591, value: 'Bolivia',
       },
       {
         code: 'BW', map: '🇧🇼', phoneCode: 267, value: 'Botswana',
       },
       {
         code: 'BV', map: '🇧🇻', phoneCode: 0, value: 'Bouvet Island',
       },
       {
         code: 'BR', map: '🇧🇷', phoneCode: 55, value: 'Brazil',
       },
       {
         code: 'IO', map: '🇮🇴', phoneCode: 246, value: 'British Indian Ocean Territory',
       },
       {
         code: 'BN', map: '🇧🇳', phoneCode: 673, value: 'Brunei',
       },
       {
         code: 'BG', map: '🇧🇬', phoneCode: 359, value: 'Bulgaria',
       },
       {
         code: 'BF', map: '🇧🇫', phoneCode: 226, value: 'Burkina Faso',
       },
       {
         code: 'BI', map: '🇧🇮', phoneCode: 257, value: 'Burundi',
       },
       {
         code: 'KH', map: '🇰🇭', phoneCode: 855, value: 'Cambodia',
       },
       {
         code: 'CM', map: '🇨🇲', phoneCode: 237, value: 'Cameroon',
       },
       {
         code: 'CA', map: '🇨🇦', phoneCode: 1, value: 'Canada',
       },
       {
         code: 'CV', map: '🇨🇻', phoneCode: 238, value: 'Cape Verde',
       },
       {
         code: 'KY', map: '🇰🇾', phoneCode: 1345, value: 'Cayman Islands',
       },
       {
         code: 'CF', map: '🇨🇫', phoneCode: 236, value: 'Central African Republic',
       },
       {
         code: 'TD', map: '🇹🇩', phoneCode: 235, value: 'Chad',
       },
       {
         code: 'CL', map: '🇨🇱', phoneCode: 56, value: 'Chile',
       },
       {
         code: 'CN', map: '🇨🇳', phoneCode: 86, value: 'China',
       },
       {
         code: 'CX', map: '🇨🇽', phoneCode: 61, value: 'Christmas Island',
       },
       {
         code: 'CC', map: '🇨🇨', phoneCode: 672, value: 'Cocos (Keeling) Islands',
       },
       {
         code: 'CO', map: '🇨🇴', phoneCode: 57, value: 'Colombia',
       },
       {
         code: 'KM', map: '🇰🇲', phoneCode: 269, value: 'Comoros',
       },
       {
         code: 'CK', map: '🇨🇰', phoneCode: 682, value: 'Cook Islands',
       },
       {
         code: 'CR', map: '🇨🇷', phoneCode: 506, value: 'Costa Rica',
       },
       {
         code: 'CU', map: '🇨🇺', phoneCode: 53, value: 'Cuba',
       },
       {
         code: 'CY', map: '🇨🇾', phoneCode: 357, value: 'Cyprus',
       },
       {
         code: 'DK', map: '🇩🇰', phoneCode: 45, value: 'Denmark',
       },
       {
         code: 'DJ', map: '🇩🇯', phoneCode: 253, value: 'Djibouti',
       },
       {
         code: 'DM', map: '🇩🇲', phoneCode: 1767, value: 'Dominica',
       },
       {
         code: 'DO', map: '🇩🇴', phoneCode: 1809, value: 'Dominican Republic',
       },
       {
         code: 'EC', map: '🇪🇨', phoneCode: 593, value: 'Ecuador',
       },
       {
         code: 'EG', map: '🇪🇬', phoneCode: 20, value: 'Egypt',
       },
       {
         code: 'SV', map: '🇸🇻', phoneCode: 503, value: 'El Salvador',
       },
       {
         code: 'GQ', map: '🇬🇶', phoneCode: 240, value: 'Equatorial Guinea',
       },
       {
         code: 'ER', map: '🇪🇷', phoneCode: 291, value: 'Eritrea',
       },
       {
         code: 'EE', map: '🇪🇪', phoneCode: 372, value: 'Estonia',
       },
       {
         code: 'ET', map: '🇪🇹', phoneCode: 251, value: 'Ethiopia',
       },
       {
         code: 'FK', map: '🇫🇰', phoneCode: 500, value: 'Falkland Islands',
       },
       {
         code: 'FO', map: '🇫🇴', phoneCode: 298, value: 'Faroe Islands',
       },
       {
         code: 'FI', map: '🇫🇮', phoneCode: 358, value: 'Finland',
       },
       {
         code: 'FR', map: '🇫🇷', phoneCode: 33, value: 'France',
       },
       {
         code: 'GF', map: '🇬🇫', phoneCode: 594, value: 'French Guiana',
       },
       {
         code: 'PF', map: '🇵🇫', phoneCode: 689, value: 'French Polynesia',
       },
       {
         code: 'TF', map: '🇹🇫', phoneCode: 0, value: 'French Southern Territories',
       },
       {
         code: 'GA', map: '🇬🇦', phoneCode: 241, value: 'Gabon',
       },
       {
         code: 'GE', map: '🇬🇪', phoneCode: 995, value: 'Georgia',
       },
       {
         code: 'DE', map: '🇩🇪', phoneCode: 49, value: 'Germany',
       },
       {
         code: 'GH', map: '🇬🇭', phoneCode: 233, value: 'Ghana',
       },
       {
         code: 'GI', map: '🇬🇮', phoneCode: 350, value: 'Gibraltar',
       },
       {
         code: 'GR', map: '🇬🇷', phoneCode: 30, value: 'Greece',
       },
       {
         code: 'GL', map: '🇬🇱', phoneCode: 299, value: 'Greenland',
       },
       {
         code: 'GD', map: '🇬🇩', phoneCode: 1473, value: 'Grenada',
       },
       {
         code: 'GP', map: '🇬🇵', phoneCode: 590, value: 'Guadeloupe',
       },
       {
         code: 'GU', map: '🇬🇺', phoneCode: 1671, value: 'Guam',
       },
       {
         code: 'GT', map: '🇬🇹', phoneCode: 502, value: 'Guatemala',
       },
       {
         code: 'GN', map: '🇬🇳', phoneCode: 224, value: 'Guinea',
       },
       {
         code: 'GW', map: '🇬🇼', phoneCode: 245, value: 'Guinea-Bissau',
       },
       {
         code: 'GY', map: '🇬🇾', phoneCode: 592, value: 'Guyana',
       },
       {
         code: 'HT', map: '🇭🇹', phoneCode: 509, value: 'Haiti',
       },
       {
         code: 'HN', map: '🇭🇳', phoneCode: 504, value: 'Honduras',
       },
       {
         code: 'HU', map: '🇭🇺', phoneCode: 36, value: 'Hungary',
       },
       {
         code: 'IS', map: '🇮🇸', phoneCode: 354, value: 'Iceland',
       },
       {
         code: 'IN', map: '🇮🇳', phoneCode: 91, value: 'India',
       },
       {
         code: 'ID', map: '🇮🇩', phoneCode: 62, value: 'Indonesia',
       },
       {
         code: 'IR', map: '🇮🇷', phoneCode: 98, value: 'Iran',
       },
       {
         code: 'IQ', map: '🇮🇶', phoneCode: 964, value: 'Iraq',
       },
       {
         code: 'IE', map: '🇮🇪', phoneCode: 353, value: 'Ireland',
       },
       {
         code: 'IL', map: '🇮🇱', phoneCode: 972, value: 'Israel',
       },
       {
         code: 'IT', map: '🇮🇹', phoneCode: 39, value: 'Italy',
       },
       {
         code: 'JM', map: '🇯🇲', phoneCode: 1876, value: 'Jamaica',
       },
       {
         code: 'JP', map: '🇯🇵', phoneCode: 81, value: 'Japan',
       },
       {
         code: 'JO', map: '🇯🇴', phoneCode: 962, value: 'Jordan',
       },
       {
         code: 'KZ', map: '🇰🇿', phoneCode: 7, value: 'Kazakhstan',
       },
       {
         code: 'KE', map: '🇰🇪', phoneCode: 254, value: 'Kenya',
       },
       {
         code: 'KI', map: '🇰🇮', phoneCode: 686, value: 'Kiribati',
       },
       {
         code: 'KW', map: '🇰🇼', phoneCode: 965, value: 'Kuwait',
       },
       {
         code: 'KG', map: '🇰🇬', phoneCode: 996, value: 'Kyrgyzstan',
       },
       {
         code: 'LA', map: '🇱🇦', phoneCode: 856, value: 'Laos',
       },
       {
         code: 'LV', map: '🇱🇻', phoneCode: 371, value: 'Latvia',
       },
       {
         code: 'LB', map: '🇱🇧', phoneCode: 961, value: 'Lebanon',
       },
       {
         code: 'LS', map: '🇱🇸', phoneCode: 266, value: 'Lesotho',
       },
       {
         code: 'LR', map: '🇱🇷', phoneCode: 231, value: 'Liberia',
       },
       {
         code: 'LY', map: '🇱🇾', phoneCode: 218, value: 'Libya',
       },
       {
         code: 'LI', map: '🇱🇮', phoneCode: 423, value: 'Liechtenstein',
       },
       {
         code: 'LT', map: '🇱🇹', phoneCode: 370, value: 'Lithuania',
       },
       {
         code: 'LU', map: '🇱🇺', phoneCode: 352, value: 'Luxembourg',
       },
       {
         code: 'MK', map: '🇲🇰', phoneCode: 389, value: 'Macedonia',
       },
       {
         code: 'MG', map: '🇲🇬', phoneCode: 261, value: 'Madagascar',
       },
       {
         code: 'MW', map: '🇲🇼', phoneCode: 265, value: 'Malawi',
       },
       {
         code: 'MY', map: '🇲🇾', phoneCode: 60, value: 'Malaysia',
       },
       {
         code: 'MV', map: '🇲🇻', phoneCode: 960, value: 'Maldives',
       },
       {
         code: 'ML', map: '🇲🇱', phoneCode: 223, value: 'Mali',
       },
       {
         code: 'MT', map: '🇲🇹', phoneCode: 356, value: 'Malta',
       },
       {
         code: 'MH', map: '🇲🇭', phoneCode: 692, value: 'Marshall Islands',
       },
       {
         code: 'MQ', map: '🇲🇶', phoneCode: 596, value: 'Martinique',
       },
       {
         code: 'MR', map: '🇲🇷', phoneCode: 222, value: 'Mauritania',
       },
       {
         code: 'MU', map: '🇲🇺', phoneCode: 230, value: 'Mauritius',
       },
       {
         code: 'YT', map: '🇾🇹', phoneCode: 269, value: 'Mayotte',
       },
       {
         code: 'MX', map: '🇲🇽', phoneCode: 52, value: 'Mexico',
       },
       {
         code: 'FM', map: '🇫🇲', phoneCode: 691, value: 'Micronesia',
       },
       {
         code: 'MD', map: '🇲🇩', phoneCode: 373, value: 'Moldova',
       },
       {
         code: 'MC', map: '🇲🇨', phoneCode: 377, value: 'Monaco',
       },
       {
         code: 'MN', map: '🇲🇳', phoneCode: 976, value: 'Mongolia',
       },
       {
         code: 'MS', map: '🇲🇸', phoneCode: 1664, value: 'Montserrat',
       },
       {
         code: 'MA', map: '🇲🇦', phoneCode: 212, value: 'Morocco',
       },
       {
         code: 'MZ', map: '🇲🇿', phoneCode: 258, value: 'Mozambique',
       },
       {
         code: 'NA', map: '🇳🇦', phoneCode: 264, value: 'Namibia',
       },
       {
         code: 'NR', map: '🇳🇷', phoneCode: 674, value: 'Nauru',
       },
       {
         code: 'NP', map: '🇳🇵', phoneCode: 977, value: 'Nepal',
       },
       {
         code: 'NC', map: '🇳🇨', phoneCode: 687, value: 'New Caledonia',
       },
       {
         code: 'NZ', map: '🇳🇿', phoneCode: 64, value: 'New Zealand',
       },
       {
         code: 'NI', map: '🇳🇮', phoneCode: 505, value: 'Nicaragua',
       },
       {
         code: 'NE', map: '🇳🇪', phoneCode: 227, value: 'Niger',
       },
       {
         code: 'NG', map: '🇳🇬', phoneCode: 234, value: 'Nigeria',
       },
       {
         code: 'NU', map: '🇳🇺', phoneCode: 683, value: 'Niue',
       },
       {
         code: 'NF', map: '🇳🇫', phoneCode: 672, value: 'Norfolk Island',
       },
       {
         code: 'MP', map: '🇲🇵', phoneCode: 1670, value: 'Northern Mariana Islands',
       },
       {
         code: 'NO', map: '🇳🇴', phoneCode: 47, value: 'Norway',
       },
       {
         code: 'OM', map: '🇴🇲', phoneCode: 968, value: 'Oman',
       },
      
       {
         code: 'PW', map: '🇵🇼', phoneCode: 680, value: 'Palau',
       },
       {
         code: 'PA', map: '🇵🇦', phoneCode: 507, value: 'Panama',
       },
       {
         code: 'PY', map: '🇵🇾', phoneCode: 595, value: 'Paraguay',
       },
       {
         code: 'PE', map: '🇵🇪', phoneCode: 51, value: 'Peru',
       },
       {
         code: 'PH', map: '🇵🇭', phoneCode: 63, value: 'Philippines',
       },
       {
         code: 'PL', map: '🇵🇱', phoneCode: 48, value: 'Poland',
       },
       {
         code: 'PT', map: '🇵🇹', phoneCode: 351, value: 'Portugal',
       },
       {
         code: 'PR', map: '🇵🇷', phoneCode: 1787, value: 'Puerto Rico',
       },
       {
         code: 'QA', map: '🇶🇦', phoneCode: 974, value: 'Qatar',
       },
       {
         code: 'RO', map: '🇷🇴', phoneCode: 40, value: 'Romania',
       },
       {
         code: 'RU', map: '🇷🇺', phoneCode: 70, value: 'Russia',
       },
       {
         code: 'RW', map: '🇷🇼', phoneCode: 250, value: 'Rwanda',
       },
       {
         code: 'WS', map: '🇼🇸', phoneCode: 684, value: 'Samoa',
       },
       {
         code: 'SM', map: '🇸🇲', phoneCode: 378, value: 'San Marino',
       },
       {
         code: 'SA', map: '🇸🇦', phoneCode: 966, value: 'Saudi Arabia',
       },
       {
         code: 'SN', map: '🇸🇳', phoneCode: 221, value: 'Senegal',
       },
       {
         code: 'RS', map: '🇷🇸', phoneCode: 381, value: 'Serbia',
       },
       {
         code: 'SC', map: '🇸🇨', phoneCode: 248, value: 'Seychelles',
       },
       {
         code: 'SL', map: '🇸🇱', phoneCode: 232, value: 'Sierra Leone',
       },
       {
         code: 'SG', map: '🇸🇬', phoneCode: 65, value: 'Singapore',
       },
       {
         code: 'SK', map: '🇸🇰', phoneCode: 421, value: 'Slovakia',
       },
       {
         code: 'SI', map: '🇸🇮', phoneCode: 386, value: 'Slovenia',
       },
       {
         code: 'SB', map: '🇸🇧', phoneCode: 677, value: 'Solomon Islands',
       },
       {
         code: 'SO', map: '🇸🇴', phoneCode: 252, value: 'Somalia',
       },
       {
         code: 'ZA', map: '🇿🇦', phoneCode: 27, value: 'South Africa',
       },
       {
         code: 'SS', map: '🇸🇸', phoneCode: 211, value: 'South Sudan',
       },
       {
         code: 'ES', map: '🇪🇸', phoneCode: 34, value: 'Spain',
       },
       {
         code: 'LK', map: '🇱🇰', phoneCode: 94, value: 'Sri Lanka',
       },
       {
         code: 'SD', map: '🇸🇩', phoneCode: 249, value: 'Sudan',
       },
       {
         code: 'SR', map: '🇸🇷', phoneCode: 597, value: 'Suriname',
       },
       {
         code: 'SZ', map: '🇸🇿', phoneCode: 268, value: 'Swaziland',
       },
       {
         code: 'SE', map: '🇸🇪', phoneCode: 46, value: 'Sweden',
       },
       {
         code: 'CH', map: '🇨🇭', phoneCode: 41, value: 'Switzerland',
       },
       {
         code: 'SY', map: '🇸🇾', phoneCode: 963, value: 'Syria',
       },
       {
         code: 'TW', map: '🇹🇼', phoneCode: 886, value: 'Taiwan',
       },
       {
         code: 'TJ', map: '🇹🇯', phoneCode: 992, value: 'Tajikistan',
       },
       {
         code: 'TZ', map: '🇹🇿', phoneCode: 255, value: 'Tanzania',
       },
       {
         code: 'TH', map: '🇹🇭', phoneCode: 66, value: 'Thailand',
       },
       {
         code: 'TG', map: '🇹🇬', phoneCode: 228, value: 'Togo',
       },
       {
         code: 'TK', map: '🇹🇰', phoneCode: 690, value: 'Tokelau',
       },
       {
         code: 'TO', map: '🇹🇴', phoneCode: 676, value: 'Tonga',
       },
       {
         code: 'TN', map: '🇹🇳', phoneCode: 216, value: 'Tunisia',
       },
       {
         code: 'TR', map: '🇹🇷', phoneCode: 90, value: 'Turkey',
       },
       {
         code: 'TM', map: '🇹🇲', phoneCode: 7370, value: 'Turkmenistan',
       },
       {
         code: 'TV', map: '🇹🇻', phoneCode: 688, value: 'Tuvalu',
       },
       {
         code: 'UG', map: '🇺🇬', phoneCode: 256, value: 'Uganda',
       },
       {
         code: 'UA', map: '🇺🇦', phoneCode: 380, value: 'Ukraine',
       },
       {
         code: 'AE', map: '🇦🇪', phoneCode: 971, value: 'United Arab Emirates',
       },
       {
         code: 'GB', map: '🇬🇧', phoneCode: 44, value: 'United Kingdom',
       },
       {
         code: 'US', map: '🇺🇸', phoneCode: 1, value: 'United States',
       },
       {
         code: 'UY', map: '🇺🇾', phoneCode: 598, value: 'Uruguay',
       },
       {
         code: 'UZ', map: '🇺🇿', phoneCode: 998, value: 'Uzbekistan',
       },
       {
         code: 'VU', map: '🇻🇺', phoneCode: 678, value: 'Vanuatu',
       },
       {
         code: 'VE', map: '🇻🇪', phoneCode: 58, value: 'Venezuela',
       },
       {
         code: 'VN', map: '🇻🇳', phoneCode: 84, value: 'Vietnam',
       },
       {
         code: 'EH', map: '🇪🇭', phoneCode: 212, value: 'Western Sahara',
       },
       {
         code: 'YE', map: '🇾🇪', phoneCode: 967, value: 'Yemen',
       },
       {
         code: 'ZM', map: '🇿🇲', phoneCode: 260, value: 'Zambia',
       },
       {
         code: 'ZW', map: '🇿🇼', phoneCode: 26, value: 'Zimbabwe',
       }])
     





    useEffect(() =>{        window.scrollTo(0, 0)
      if( JSON.parse(localStorage.getItem("User")) ){
        

      }else
      localStorage.setItem("UserProp",JSON.stringify("1"))
      localStorage.removeItem("VenderWorkingThose")


    console.log("Start")
  fetch("https://tapitdabit-backend.vercel.app/Userother/"+props.match.params.otheruserId,{
    method: "GET",
     headers :  {
     "Content-Type" : "application/json" , 
 } ,
})
.then(res5=>res5.json())
.then(res6=>{

  setUser1(res6)
  
  
  if(res6.email === res6.Email){
    localStorage.setItem("ActivateUrl" , JSON.stringify(res6.uid))
    setTimeout(()=>{
      props.history.push("/account-activate")
    },1000)
  }
  
  if (res6.DisPersonal) {
    let A2312 =res6.DispalyShow.sort((a, b) => a.sort > b.sort && 1 || -1)
    setArr1(A2312)
    setdis6(false)
  }
  else {
    let A231245 =res6.DispalyShow1.sort((a, b) => a.sort > b.sort && 1 || -1)
    setArr1(A231245)
    setdis6(false)
  }
  setdispaly(false)
  
  let data = res6.DisPersonal ? res6.Contact : res6.ContactBuss
  if(data[0] !== undefined){
    setdis4(true)
  }
  else{
    setdis4(false)
  }

  localStorage.setItem("User 56" , JSON.stringify(res6))
  
  console.log(res6)

    if (res6.Direct1.length > 0){
      console.log(4545)

      if(res6.Direct1[0].name === "Embed video")
      window.location.assign(res6.Direct1[0].link)
      
      else if(res6.Direct1[0].name === "JazzCash")
      setArr1(res6.Direct1)
      else if(res6.Direct1[0].name === "EasyPaisa")
      setArr1(res6.Direct1)
      else if(res6.Direct1[0].name === "Bank")
      setArr1(res6.Direct1)
      else if(res6.Direct1[0].name === "Linktree")
      setArr1(res6.Direct1)
      
      
      else if(res6.Direct1[0].name === "whatsapps")
      window.location.assign("https://wa.me/"+res6.Direct1[0].link)
      
      else if(res6.Direct1[0].name === "email")
      window.location.assign("mailto:"+res6.Direct1[0].link)
      
      else if(res6.Direct1[0].name === "phone")
      window.location.assign("tel:"+res6.Direct1[0].link)
      
      else if(res6.Direct1[0].name === "text")
      window.location.assign("sms:"+res6.Direct1[0].link)
      
      else if(res6.Direct1[0].name === "whatsapps")
      window.location.assign("https://wa.me/"+res6.Direct1[0].link)


      else if(res6.Direct1[0].name === "Contact"){
        console.log(4545)
        var vCardsJS = require('vcards-js');
        const FileSaver = require('file-saver');

  


            const myVCard = new VCard()

            myVCard.addName(res6.Direct1[0].fullName)
   
            myVCard.addRole(res6.Direct1[0].Gender)
            myVCard.addEmail(res6.Direct1[0].Email)
            myVCard.addPhoneNumber(res6.Direct1[0].Phone, 'Personal')
            myVCard.addPhoneNumber(res6.Direct1[0].Phone, 'WORK')
            myVCard.addAddress(res6.Direct1[0].Country,res6.Direct1[0].City)
                  for (let i = 0; i < res6.Direct1[0].Contact.length; i++) {
               if(res6.Direct1[0].Contact[i].name !== undefined  && res6.Direct1[0].Contact[i].link !== undefined && res6.Direct1[0].Contact[i].link[0] === "h")
                    myVCard.addURL(res6.Direct1[0].Contact[i].link)
                  }
            
            
  
          const blob = new Blob([ myVCard.toString() ], {type: "text/vcard;charset=utf-8"});
          FileSaver.saveAs(blob, res6.Direct1[0].fullName+".vcf");
          








      }

      else{
        window.location.assign(res6.Direct1[0].link )

      }

       setArr1(res6.Direct1)
      }






    if (res6.Direct2.length > 0){



        if(res6.Direct2[0].name === "Embed video")
        window.location.assign(res6.Direct2[0].link)
       
        


  
        else if(res6.Direct2[0].name === "JazzCash")
        setArr1(res6.Direct2)
        else if(res6.Direct2[0].name === "EasyPaisa")
        setArr1(res6.Direct2)
        else if(res6.Direct2[0].name === "Bank")
        setArr1(res6.Direct2)
        else if(res6.Direct2[0].name === "Linktree")
        setArr1(res6.Direct2)
        






        else if(res6.Direct2[0].name === "whatsapps")
        window.location.assign("https://wa.me/"+res6.Direct2[0].link)
        
        else if(res6.Direct2[0].name === "email")
        window.location.assign("mailto:"+res6.Direct2[0].link)
        
        else if(res6.Direct2[0].name === "phone")
        window.location.assign("tel:"+res6.Direct2[0].link)
        
        else if(res6.Direct2[0].name === "text")
        window.location.assign("sms:"+res6.Direct2[0].link)
        
        else if(res6.Direct2[0].name === "whatsapps")
        window.location.assign("https://wa.me/"+res6.Direct2[0].link)
  


      else if(res6.Direct2[0].name === "Contact"){


        const FileSaver = require('file-saver');


const myVCard = new VCard()

myVCard.addName(res6.Direct2[0].fullName)

myVCard.addRole(res6.Direct2[0].Gender)
myVCard.addEmail(res6.Direct2[0].Email)
myVCard.addPhoneNumber(res6.Direct2[0].Phone, 'Personal')
myVCard.addPhoneNumber(res6.Direct2[0].Phone, 'WORK')
myVCard.addAddress(res6.Direct2[0].Country,res6.Direct2[0].City)

    for (let i = 0; i < res6.Direct2[0].ContactBuss.length; i++) {
      if(res6.Direct2[0].ContactBuss[i].name !== undefined  && res6.Direct2[0].ContactBuss[i].link !== undefined && res6.Direct2[0].ContactBuss[i].link[0] === "h")
         myVCard.addURL(res6.Direct2[0].ContactBuss[i].link)

}



const blob = new Blob([ myVCard.toString() ], {type: "text/vcard;charset=utf-8"});
console.log(myVCard)
FileSaver.saveAs(blob, res6.Direct2[0].fullName+".vcf");




  
      }

else{
  window.location.assign(res6.Direct2[0].link)
}


       setArr1(res6.Direct2)
      
      }
    
  
  fetch("https://tapitdabit-backend.vercel.app/USeruPDATE12",{
    method: "POST",
     headers :  {
     "Content-Type" : "application/json" , 
  } ,
  body : JSON.stringify({
  id : res6.uid,
  ids : res6.DisPersonal
  })
  })
  .then(res15=>res15.json())
  .then(res16=>{
  console.log(res16)
  })
  
  })
return () => {
  document.getElementById("get1").classList.remove("is-active");
  document.getElementById("get2").style.display = "none";
  localStorage.removeItem("UserProp")
}
    },[])
const  setLoc = (e)=>{
window.location.origin(e)
}
const setChangeCard = () =>{
  let data = User1.DisPersonal ? User1.Contact : User1.ContactBuss
  if(data[0] !== undefined){
     data = data[0]
    if(data){
    const myVCard = new VCard()
    const FileSaver = require('file-saver');
    myVCard.addName(data.fullName)
    myVCard.addPhoto(data.photo)
    myVCard.addRole(data.Gender)
    myVCard.addEmail(data.Email)
    myVCard.addPhoneNumber(data.Phone, 'Personal')
    myVCard.addPhoneNumber(data.Phone, 'WORK')
    myVCard.addAddress(data.Country,data.City)
    if(User1.DisPersonal){
          for (let i = 0; i < data.Contact.length; i++) {
      if(data.Contact[i].name !== undefined  && data.Contact[i].link !== undefined && data.Contact[i].link[0] === "h")
            myVCard.addURL( data.Contact[i].link)
          }
    }
    else{
        for (let i = 0; i < data.ContactBuss.length; i++) {
          if(data.ContactBuss[i].name !== undefined  && data.ContactBuss[i].link !== undefined && data.ContactBuss[i].link[0] === "h")
            myVCard.addURL(data.ContactBuss[i].link)
    }
    }
    const blob = new Blob([ myVCard.toString() ], {type: "text/vcard;charset=utf-8"});
    console.log(myVCard)
    FileSaver.saveAs(blob, data.fullName+".vcf");
  }
  }
  else{
    swal ("No Any Contact Card ")
  }
}
const OpenVcard = ( data )=>{
    setdispaly1(data)
    var vCardsJS = require('vcards-js');
        var vCard = vCardsJS();
        vCard.firstName = data.fullName;
        if(data.photo){
             vCard.photo.attachFromUrl(data.photo);
        vCard.logo =(data.photo)
      }
      if(data.photo1){
          vCard.photo.attachFromUrl(data.photo1);
     vCard.logo =(data.photo1)
        }
        vCard.organization = data.Role;
        vCard.workPhone = data.Phone;
        vCard.title =data.Role;
      vCard.workUrl ='https://tapitdabit.com/user/'+User1.uid 
      vCard.url ='https://tapitdabit.com/user/'+User1.uid 
        vCard.note = data.Role;
        vCard.workPhone = data.Phone;
        vCard.note = data.Country
        console.log(User1.DisPersonal)
        if(User1.DisPersonal){

      }
      else{
    }
            vCard.title = data.Role;
            vCard.workAddress = data.Address
            vCard.homeAddress = data.Address
            vCard.gender = data.Gender;
            vCard.role =data.Role;
            vCard.homePhone = data.Phone;
            vCard.email = data.Email;
            
        const FileSaver = require('file-saver');
        vCard  =  {
          ...vCard , 
          
        
              socialUrls : [
            { facebook :  'https://www.facebook.com/webocreators'},
            { instagram : 'https://www.instagram.com/webocreators'},
             { pad :  'https://www.whatapps.com/webocreators'}
           
            ] ,
        }
        vCard.isOrganization = true;
const myVCard = new VCard()
  myVCard.addName(data.fullName)
  myVCard.addRole(data.Gender)
  myVCard.addEmail(data.Email)
  myVCard.addPhoneNumber(data.Phone, 'Personal')
  myVCard.addPhoneNumber(data.Phone, 'WORK')
  myVCard.addAddress(data.Country,data.City)
  if(User1.DisPersonal){
        for (let i = 0; i < data.Contact.length; i++) {
     if(data.Contact[i].name !== undefined  && data.Contact[i].link !== undefined && data.Contact[i].link[0] === "h")
          myVCard.addURL( data.Contact[i].link)
        }
  }
  else{
      for (let i = 0; i < data.ContactBuss.length; i++) {
        if(data.ContactBuss[i].name !== undefined  && data.ContactBuss[i].link !== undefined && data.ContactBuss[i].link[0] === "h")
           myVCard.addURL(data.ContactBuss[i].link)
  }
  }
const blob = new Blob([ myVCard.toString() ], {type: "text/vcard;charset=utf-8"});
FileSaver.saveAs(blob, data.fullName+".vcf");
}
const setdispaly1 = (data) =>{
  if(User1.DisPersonal){
    fetch("https://tapitdabit-backend.vercel.app/others1234",{
    method: "POST",
     headers :  {
     "Content-Type" : "application/json" , 
 } ,  body : JSON.stringify({
          id : User1._id,
          data 
})
})
.then(res55=>res55.json())
.then(res65=>{
  // console.log(res65)

  // //  console.log(res6)
})
  }
  else{
    fetch("https://tapitdabit-backend.vercel.app/others12345/1",{
    method: "POST",
     headers :  {
     "Content-Type" : "application/json" , 
 } ,  body : JSON.stringify({
          id : User1._id,
          data 
})
})
.then(res33=>res33.json())
.then(res15=>{
  console.log(res15) 
})
}
}





const SubmitDataMessage = (e) =>{
  e.preventDefault()
  setdis10 ( false)
  let Phone1
  if(UserPhoneCode !== null){
    Phone1 = "+"+UserPhoneCode+Phone
 }
 else {
   swal("select Country Code")
   setdis10 ( true)
     return
   }
   let s = ""
   if(Phone[0] == 0){
    for (let i = 0; i < Phone.length; i++) {
      if(i !== 0){
        s = s + Phone[i]
      }
      
    }
    s ="+"+UserPhoneCode+s
  }
  console.log(Phone[0])
  //  if(Email.length === 0){
  //   swal("Enter The Email !" )
  //   setdis10 ( true)
  //      return
  //   }
  //  if(Name.length === 0){
  //   swal("Enter The Name !" )
  //   setdis10 ( true)
  //      return
  //   }
  // setdispaly ( false)

  fetch("https://tapitdabit-backend.vercel.app/allUser345/"+props.match.params.otheruserId,{
  method: "GET",
  headers :  {
    "Content-Type" : "application/json" , 
  } ,
})
.then(res5=>res5.json())
.then(res6=>{ 

  let d = new Date()
  let v
  if(d.getDay() === 0){
    v = "Sunday"
  }
  else if(d.getDay() === 1){
    v = "Monday"
  }
  else if(d.getDay() === 2){
    v = "Tuesday"
  }
  else if(d.getDay() === 3){
    v = "Wednesday"
  }
  else if(d.getDay() === 4){
    v = "Thursday"
  }
  else if(d.getDay() === 5){
    v = "Friday"
  }
  else if(d.getDay() === 6){
    v = "Saturday"
  }
  let f=v +" "+ d.getDate() + " "+d.getUTCFullYear()
                              const data = new FormData()
                              data.append("file", Image)
                              data.append("upload_preset", "commerce")
                              data.append("cloud_name", "freedeveloperss123")
                              fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
                                  method : "POST",
                                  body : data , 
                              })
                              .then(res=>res.json())
                              .then((res2)=>{
                                console.log(res2,User1.DisPersonal)
                              fetch("https://tapitdabit-backend.vercel.app/createConnectedOther",{
                                          method: "POST",
                                          headers :  {
                                              "Content-Type" : "application/json" , 
                                          } ,
                                          body : JSON.stringify({
                                              fullName :Name ,
                                              Email  ,
                                              Phone :s , 
                                              City ,
                                              Job ,
                                              Role :Role , 
                                              use : res6.DisPersonal ? "per" : "buss",
                                              Image : res2.url ? res2.url : null,
                                              id : User1._id,
                                              Date : f ,
                                              dis  : User1.DisPersonal
                                              // MainLine2 :mainline ,
                                          })
                                      })
                                      .then(res11=>res11.json())
                                      .then((res12)=>{ 
                                          if(res12.Error){
                                            setdis10 ( true)
                                            // setdispaly0( true)
                                            
                                            swal(res12.Error);
                                          }
                                          else{
                                        setdis10 ( true)
                                        // setdispaly0( true)
                                              swal("Successfully Send ");
                                              // props.history.push("/user/"+User1.uid)
                                              setConnect(!Connect)
                                              setEmail("")
                                              setRole("")
                                              setJob("")
                                              setCity("")
                                              setPhone("")
                                              setname("")
                                            
                                      }
                                  })
                                  })
                                  .catch(err=>{
                                      swal("There is An Error") 
                                      setdis10 ( true)
             
                                      }) 
})

}
        return (
      <div 
        style={{background: "white"}}
        >
{! User1.Name ?
                <Loader
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={10000} //3 secs
        className="loader-1"
      /> 
      :""
}
{!dis10?
                        <Loader
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={10000} //3 secs
        className="loader-1 loader-adjust"
        style={{position : "absolute",top : "30%",left : "47%",zIndex: "99999"}}
      /> 
      :""
}



{Connect ?

<div className="site-section change-padding" style={{position: "absolute", width: "100%",
    zIndex:"9999"}}>
<center>
  {User1.DisPersonal ? 
    <img className='change-size-image' src={User1.ProfilePhoto ? User1.ProfilePhoto   : x1} alt=""  style={{borderRadius : "90px",border  :"2px solid grey",position: "relative",top: "33px" ,width: "100px",height: "100px",objectFit: "cover"}} />
:
<img className='change-size-image' src={User1.ProfilePhotoBuss ?  User1.ProfilePhotoBuss : x1} alt=""  style={{borderRadius : "90px",border  :"2px solid grey",position: "relative",top: "33px" ,width: "100px",height: "100px",objectFit: "cover"}} />
  }
  </center>
                    <div className="container div-size-respon" style={{width : "35%", borderRadius : "20px",background: "white",padding : "0px"}}>
                        <div className="row">
                            <div className="col-md-12">
                                <form  onSubmit={e=>SubmitDataMessage(e)}>

                                {/* <form onSubmit={(e)=>Submitdata(e)}> */}
                                    <div className="p-3 p-lg-5" style={{ borderRadius : "20px",boxShadow: "1px 1px 10px grey"}}>
                                       <center>
                                        <h5 className='change-texts' style={{color : "black"}}>
                                        Share your info back 
                                        <br />
                                        with Card owner
                                        <br /> <b>
                                          {User1.DisPersonal  ? User1.Name  : User1.NameBuss}
                                          
                                          </b> 
                                        </h5>
                                       </center>
                                       <span onClick={()=>setConnect(!Connect)} style={{position: "absolute",top: "10px",right: "10%", color : "black",fontSize : "27px",fontWeight : "400"}}>x</span>
                                       {/* <br /> */}
                                       <hr />
                                       {/* <br /> */}
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <input type="text" value={Email} onChange={(e)=>setEmail(e.target.value) }  className="form-control" id="inputPassword4" placeholder="Email" />
                                            </div>
                                            </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <input type="text" value={Name} onChange={(e)=>setname(e.target.value) }  className="form-control" id="inputPassword4" placeholder="Name" />
                                            </div>
                                            </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <div style={{display : "flex" , justifyContent : "flex-start"}}>
                                            <select name="" id=""  value={UserPhoneCode} onChange={(e)=>setUserPhoneCode(e.target.value)}  style={{width : "107px" , fontSize :"12px" , height: "42px"}}>
                                              
                                              {
                                                PhoneCode.map((res,i)=>{
                                                  return(
                                                    <option value={res.phoneCode} >{res.value}  +{res.phoneCode}</option>
                                                  )
                                                })
                                              }
                                            </select>
                                        <input type="text" required value={Phone} onChange={(e)=>setPhone(e.target.value) }  className="form-control" id="inputPassword4" placeholder="Phone Number"/>
                                          </div>
                                                                                      </div>
                                       
                                        </div>





                                        {
                                  dis20 ?

                              <>

                              <div className="form-group row">
                                         
                                        <div className="col-md-12">
                                        {/* <label for="fileupload"> Select a Photo to upload</label> */}
                                        <input type="file" id="fileupload" onChange={(e)=>setImage(e.target.files[0])}  className="form-control"  placeholder="Upload Image" title="Upload Image"/>

                                    </div>

                                    </div>
                              <div className="form-group row">
                                          <div className="col-md-12">
                                        <input type="text"  value={Job} onChange={(e)=>setJob(e.target.value) }  className="form-control" id="inputPassword4" placeholder="job Title"/>
                                      </div> 
                                     
                                </div>
                              <div className="form-group row">
                                          <div className="col-md-12">
                                        <input type="text"  value={City} onChange={(e)=>setCity(e.target.value) }  className="form-control" id="inputPassword4" placeholder="Company"/>
                                      </div> 
                                     
                                </div>
                                <div className="form-group row">
                                   <div className="col-md-12">
                                        <textarea type="text" cols="10" rows="2" value={Role} onChange={(e)=>setRole(e.target.value) }  className="form-control" id="inputPassword4" placeholder="Notes On this Interaction"/>
                                      </div>
                                       
                                </div> 

                              </>
: 
<p onClick={() => setdis20(!dis20) } style={{color : "rgb(52, 137, 243)"  ,fontSize : "18px", fontWeight : "bold"}}>



<span>
  + Add More
</span>

</p>

                                }
                              

                              <div className="form-row">
                                          <div className="form-group col-md-12">
                                            <center>
                                            {dis10 ?

<button type="submit" style={{width : "200px", padding : "10px 0px",   background : "rgb(52, 137, 243)" ,  color  : "white", borderRadius :  "30px" ,fontSize : "18px"}}>Connect</button>
                                            :

<div  style={{minWidth : "200px", maxWidth : "200px" , padding : "10px 0px",   background : "rgb(52, 137, 243)" ,  color  : "white", borderRadius :  "30px" ,fontSize : "18px"}}>Connect</div>
                                            }
                                            </center>

                                </div>
                                </div>
                                      <center>
                                        <p style={{color : "black"}}>
                                        <b>
                                            TapitDabit does not sell your data
                                        </b>
                                        </p>
                                        </center>
                                    </div>
                                </form>
                            </div>
                         
                        </div>
                    </div>
                </div>


: ""

}



<div style={{height : "2px"}}>

</div>




 <button className='change-margin'  style={{margin : "70px 10px 10px 10px" ,width : "75px",background :"rgb(35, 39, 43)"  ,color:  "white" ,border : "1px solid rgb(35, 39, 43)",  borderRadius : "10px", }} onClick={()=>props.history.push("/login")}>Login</button>
{User1.generator ? 
<>
{/* <div className="cc1 hello12345 hello12345088080 um1" style={{justifyContent : "flex-start",height:"439px"}}>
              <div className="container1 hello123 um2" id="container1" style={{margin :"92px 0px" , minHeight: "376px",width : "90%"}} >
              <div style={{background : `url(${ppp11})` , display : "flex" , justifyContent : "center",alignItems :  "center", width : "50%",height : "376px"}} className="link-user-photo um3">
                {User1.DisPersonal ? 
<img src={User1.ProfilePhoto ? User1.ProfilePhoto   : x1}width= "45%" height= "300px" alt="" className="uit1" style={{borderRadius :  "50%",height: "292px",width: "256px"}}/>
:
<img src={  User1.ProfilePhotoBuss ?  User1.ProfilePhotoBuss : x1}width= "45%" height= "300px" alt="" className="uit1" style={{borderRadius :  "50%",height: "292px",width: "256px"}}/>
                }
</div>                <div className="overlay-container um3">
                  <div className="overlay" style={{background : "rgb(2, 3, 27)"}}>
                    <div className="overlay-panel overlay-right">
                      <h3 className="f67" style={{fontWeight :  "bold"}}>{User1.DisPersonal  ? User1.Name  : User1.NameBuss}</h3>
                      <p className="ppp1">{User1.DisPersonal  ? User1.bio  : User1.bioBuss}</p>
                    </div>
                  </div>
                </div>
              </div>
          </div> */}















<div className="cc1 hello12345 um1 um4" style={{justifyContent : "flex-start",height:"299px"}}>
              <div className="container1 hello123 um1 um4" id="container1" style={{margin :"21px 0px" , minHeight: "296px",width : "90%"}}  >
<div style={{background : `url(${ppp11})` , display : "flex" , justifyContent : "center",alignItems :  "center", width : "51%",height : "296px", flexDirection: "column"}} className="link-user-photo um1 um4">
{User1.DisPersonal ? 

                <img src={User1.ProfilePhoto ? User1.ProfilePhoto : x1} width= "45%" height= "300px" alt="" className="uit1" style={{borderRadius :  "50%",height: "216px",width: "214px",margin: "22px 0px"}}/>
                :
                <img src={  User1.ProfilePhotoBuss ?  User1.ProfilePhotoBuss : x1}width= "45%" height= "300px" alt="" className="uit1" style={{borderRadius :  "50%",height: "216px",width: "214px",margin: "22px 0px"}}/>
       }  
               
               
               
             
</div>

                <div className="overlay-container um1 um4">
                  <div className="overlay" style={{background : "rgb(2, 3, 27)"}}>
                    <div className="overlay-panel overlay-right">
                  
            <br />
            
            <h3 className="f67"><b>{User1.DisPersonal  ? User1.Name  : User1.NameBuss}</b> </h3>
                      <p className="ppp1">{User1.DisPersonal  ? User1.bio  : User1.bioBuss}</p>
          
                    </div>
                  </div>
                </div>
              </div>
          </div>



















          <center>
              <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 mt-4 mb-4" style={{margin : "0px 0px" , display : "flex" , justifyContent :  "center"}}>
                     <button className="button11 rfgrgdfrgrrtg" type="button" class="btn btn-dark" style={{width : "200px",height:"50px",borderRadius : "10px" , fontWeight :  "bold", padding : "14px 20px",fontSize : "15px", margin : "0px 15px",background :"rgb(35, 39, 43)"}}  onClick={()=>setConnect(! Connect)}>Connect with me</button>
                     {/* <button className="button11 rfgrgdfrgrrtg" type="button" class="btn btn-dark" style={{width : "200px",height:"50px",borderRadius : "10px" , fontWeight :  "bold", padding : "14px 20px",fontSize : "15px", margin : "20px 15px",background :"rgb(35, 39, 43)"}}  onClick={()=>props.history.push("/user/connected/"+User1.uid)}>Connect with me</button> */}
                    {dis4
                    ?
                      <img src={contact1} onClick={()=>setChangeCard()} alt="" srcset="" width="40px" height="40px" style={{objectFit: "cover"
  //  , margin: "26px"
  , margin: "6px"
  }} /> 
   : ""
                    }
              </div>
          </center>
          <section>
                    <div className="container" style={{margin : "15px auto" , width : "97%"}}>
                            <div className="row justify-content-center">
                                {Arr1.map((res1,i)=>{
                                  return(  
                                    <>
                                    { res1.name !== "Linktree" &&  res1.name !== "JazzCash" && res1.name !== "EasyPaisa" && res1.name !== "Bank" &&  res1.name !== "Embed video"? 
                                    <div className="arrival11 eereuorowwe col-xl-3 col-lg-3 col-md-4 col-sm-4">
                                                {! res1.name1 &&  res1.name === "Contact" ? 
                                                       <>
                                                    <a > <img src={cd1} onClick={()=>OpenVcard(res1)}  alt="" className="arrival-Photo111 gorriwotwitwpi"/></a>
                                                    <center><p className="cb cb09 cb23">{res1.fullName}</p></center>
                                                  </>
                                                    : ""
                                                }
                                                { res1.name1 && res1.name === "Contact" ? 
                                                      <>
                                                       <a> <img src={res1.photo1 ? res1.photo1 : a111}  onClick={()=>OpenVcard(res1)}  alt="" className="arrival-Photo111 gorriwotwitwpi"  /></a>
                                                        <center><p className="cb cb09 cb23">{res1.name1}</p></center>
                                                      </>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "facebook" ?  
                                                <>
                                                <a href={res1.link} target="_blank" onClick={()=>setdispaly1(res1)}> <img src={fb1} alt="" className="arrival-Photo111 gorriwotwitwpi"/></a>
                                                <center><p className="cb cb09 cb23">Facebook</p></center>
                                                </>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "location" ?  <>
                                                    <a href={res1.link} target="_blank" onClick={()=>setdispaly1(res1)}><img src={lo1} alt="" className="arrival-Photo111 gorriwotwitwpi"/></a>
                                                    <center><p className="cb cb09 cb23">Location</p></center>
                                                    </>
                                                    : ""
                                                }
                                                {res1.photo === "" && res1.name === "instagram" ?  
                                                <>
                                                    <a href={res1.link} target="_blank" onClick={()=>setdispaly1(res1)}><img src={in1} alt="" className="arrival-Photo111 gorriwotwitwpi"/></a>
                                                    <center><p className="cb cb09 cb23">Instagram</p></center>
                                                    </>
    : ""
  }
                                                {res1.photo === "" && res1.name === "snapchat" ?  <>
                                                    <a href={res1.link} target="_blank" onClick={()=>setdispaly1(res1)}><img src={sc1} alt="" className="arrival-Photo111 gorriwotwitwpi"/></a>
                                                    <center><p className="cb cb09 cb23">SnapChat</p></center>
                                                    </>
                                                    : ""
                                                }
                                                {res1.photo === "" && res1.name === "tiktok" ?  <>
                                                    <a href={res1.link} target="_blank" onClick={()=>setdispaly1(res1)}><img src={tk1} alt="" className="arrival-Photo111 gorriwotwitwpi"/></a>
                                                    <center><p className="cb cb09 cb23">TikTok</p></center>
                                                    </>
                                                    : ""
                                                }
                                                {res1.photo === "" && res1.name === "linkedin" ?  <>
                                                    <a href={res1.link} target="_blank" onClick={()=>setdispaly1(res1)}><img src={ln1} alt="" className="arrival-Photo111 gorriwotwitwpi"/></a>
                                                    <center><p className="cb cb09 cb23">Linkedin</p></center>
                                                    </>
                                                    : ""
                                                }
                                                {res1.photo === "" && res1.name === "twitter" ?  <>
                                                    <a href={res1.link} target="_blank" onClick={()=>setdispaly1(res1)}><img src={tw1} alt="" className="arrival-Photo111 gorriwotwitwpi"/></a>
                                                    <center><p className="cb cb09 cb23">Twitter</p></center>
                                                    </>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "youtube" ?  <>
                                                    <a href={res1.link} target="_blank" onClick={()=>setdispaly1(res1)}><img src={you1} alt="" className="arrival-Photo111 gorriwotwitwpi"/></a>
                                                    <center><p className="cb cb09 cb23">Youtube</p></center>
                                                    </>
                                                    : ""
                                                }
                                                {res1.photo === "" && res1.name === "twitch" ?  <>
                                                    <a href={res1.link} target="_blank" onClick={()=>setdispaly1(res1)}><img src={tt1} alt="" className="arrival-Photo111 gorriwotwitwpi"/></a>
                                                    <center><p className="cb cb09 cb23">Twitch</p></center>
                                                    </>
                                                    : ""
                                                }
                                                {res1.photo === "" && res1.name === "pinterest" ?  <>
                                                    <a href={res1.link} target="_blank" onClick={()=>setdispaly1(res1)}><img src={pi1} alt="" className="arrival-Photo111 gorriwotwitwpi"/></a>
                                                    <center><p className="cb cb09 cb23">Pinterest</p></center>
                                                    </>
                                                    : ""
                                                }
                                                {res1.photo === "" && res1.name === "cash app" ?  <>
                                                    <a href={res1.link} target="_blank" onClick={()=>setdispaly1(res1)}><img src={ca1} alt="" className="arrival-Photo111 gorriwotwitwpi"/></a>
                                                    <center><p className="cb cb09 cb23">Cash App</p></center>
                                                    </>
                                                    : ""
                                                }
                                                {res1.photo === "" && res1.name === "paypal" ?  <>
                                                    <a href={res1.link} target="_blank" onClick={()=>setdispaly1(res1)}><img src={pp1} alt="" className="arrival-Photo111 gorriwotwitwpi"/></a>
                                                    <center><p className="cb cb09 cb23">PayPal</p></center>
                                                    </>
                                                    : ""
                                                }
                                                {res1.photo === "" && res1.name === "Venmo" ?  <>
                                                    <a href={res1.link} target="_blank" onClick={()=>setdispaly1(res1)}><img src={ve1} alt="" className="arrival-Photo111 gorriwotwitwpi"/></a>
                                                    <center><p className="cb cb09 cb23">Venmo</p></center>
                                                    </>
                                                    : ""
                                                }
                                                {res1.photo === "" && res1.name === "text" ?  <>
                                                    <a href={"sms:"+res1.link} target="_blank" onClick={()=>setdispaly1(res1)}><img src={mess1} alt="" className="arrival-Photo111 gorriwotwitwpi"/></a>
                                                    <center><p className="cb cb09 cb23">Text</p></center>
                                                    </>
                                                    : ""
                                                }
                                                {res1.photo === "" && res1.name === "website" ?  <>
                                                    <a href={res1.link} target="_blank" onClick={()=>setdispaly1(res1)}><img src={we1} alt="" className="arrival-Photo111 gorriwotwitwpi"/></a>
                                                    <center><p className="cb cb09 cb23">Website</p></center>
                                                    </>
                                                    : ""
                                                }
                                                {res1.photo === "" && res1.name === "whatsapps" ?  <>
                                                    <a href={"https://wa.me/"+res1.link} target="_blank" onClick={()=>setdispaly1(res1)}><img src={wa1} alt="" className="arrival-Photo111 gorriwotwitwpi"/></a>
                                                    <center><p className="cb cb09 cb23">Whatsapp</p></center>
                                                    </>
                                                    : ""
                                                }
                                                {res1.photo === "" && res1.name === "email" ?  <>
                                                    <a href={"mailto:"+res1.link} target="_blank" onClick={()=>setdispaly1(res1)}><img src={mail1} alt="" className="arrival-Photo111 gorriwotwitwpi"/></a>
                                                    <center><p className="cb cb09 cb23">Email</p></center>
                                                    </>
                                                    : ""
                                                }
                                                {res1.photo === "" && res1.name === "phone" ? <>
                                                    <a href={"tel:"+res1.link} target="_blank" onClick={()=>setdispaly1(res1)}><img src={call1} alt="" className="arrival-Photo111 gorriwotwitwpi"/></a>
                                                    <center><p className="cb cb09 cb23">Phone</p></center>
                                                    </>
                                                    : ""
                                                }
                                                {res1.photo === "" && res1.name === "Address" ? <>
                                                    <a href={res1.link} target="_blank" onClick={()=>setdispaly1(res1)}><img src={lo1} alt="" className="arrival-Photo111 gorriwotwitwpi"/></a>
                                                    <center><p className="cb cb09 cb23">Address</p></center>
                                                    </>
                                                    : ""
                                                  }             
                                                {res1.photo && res1.photo.length !== 0  && res1.name !== "Contact"?
                                                   <div>
                                                    <a href={res1.link} target="_blank" onClick={()=>setdispaly1(res1)}><img src={res1.photo} alt="" className="arrival-Photo111 gorriwotwitwpi" /></a>
                                                    <center><p className="cb cb09 cb23" >{res1.Name}</p></center>
                                                    </div>
                                                : ""
                                                }    
                                                                     </div>
                                                                     :""}
                                                                     </>
                                  )
                                })
                                }
                            {Arr1.map((res1,i)=>{
                                  return(  
                                    <>
                                                 { res1.name === "Linktree" ? 
                                    <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12" style={{marginTop: "68px"}}>
                                    <center>
                                                  <div className="container"  onClick={()=>setdispaly1(res1)}>
                                                <div className="row">
                                          <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12">
                                                <button className="button11" style={{background : "skyblue", width : "250px",margin : "2px 0px"}}  onClick={()=>setLoc(res1.email1)}>{res1.Name1}</button>
                                          </div>  
                                          {res1.Name2  ?   
                                           <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12">
                                                <button className="button11" style={{background : "skyblue", width : "250px",margin : "2px 0px"}} onClick={()=>setLoc(res1.email2)}>{res1.Name2}</button>
                                           </div>
                                            :""}
                                           {res1.Name3  ?
                                           <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12">
                                                <button className="button11" style={{background : "skyblue", width : "250px",margin : "2px 0px"}} onClick={()=>setLoc(res1.email3)}>{res1.Name3}</button>
                                           </div>
                                           :""}
                                           {res1.Name4  ?
                                           <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12">
                                                <button className="button11" style={{background : "skyblue", width : "250px",margin : "2px 0px" }} onClick={()=>setLoc(res1.email4)}>{res1.Name4}</button>
                                           </div>
                                           :""}
                                           {res1.Name5  ?
                                           <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12">
                                                <button className="button11" style={{background : "skyblue", width : "250px"}} onClick={()=>setLoc(res1.email5)}>{res1.Name5}</button>
                                           </div>
                                           : ""

                                           }
                                              </div>
                                                </div>
                                               </center>
                                    </div>
                                    : ""
                                                } 
                                                    { res1.name === "JazzCash" ? 
                                                    dis1 ? 
                                                    <div className="arrival11 eereuorowwe col-xl-3 col-lg-3 col-md-4 col-sm-4">
                                                                <a href={res1.link} target="_blank" onClick={()=>setdis1(false)}> <img src={jc1} alt="" className="arrival-Photo111 gorriwotwitwpi" style={{objectFit :"scale-down",PADDING : "0PX 0PX 0PX 6PX"}}/></a>
                                                                <center><p className="cb cb09 cb23">JazzCash</p></center>
                                                                   </div>
:
                                    <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12" style={{marginTop: "68px"}}>
<center>
                                            <div className="container"  onClick={()=>setdispaly1(res1)}>
                                              <div className="row">
                                                  <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12">
                                                        <button className="button11" style={{background : "skyblue", width : "250px",margin : "10px 0px"}} >{res1.name}</button>
                                                 </div>  
                                                <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12">
                                                      <button className="button11" style={{background : "skyblue", width : "250px",margin : "10px 0px"}} >Name : {res1.Name}</button>
                                                </div>  
        
                                                <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12">
                                                      <button className="button11" style={{background : "skyblue", width : "250px"}} >Account Number : {res1.Number}</button>
                                                </div>
                                              </div>
                                            </div>
                                               </center>
                                    </div>
                                                : ""
                                                } 
                                                    { res1.name === "EasyPaisa" ? 
                                                    dis2 ? 
                                                    <div className="arrival11 eereuorowwe col-xl-3 col-lg-3 col-md-4 col-sm-4">
                                                                <a href={res1.link} target="_blank" onClick={()=>setdis2(false)}> <img src={ep1} alt="" className="arrival-Photo111 gorriwotwitwpi changes-easypaisa"/></a>
                                                                <center><p className="cb cb09 cb23">EasyPaisa</p></center>
                                                                   </div>
:
                                         <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12" style={{marginTop: "68px"}}>
                                           <center>
                                                  <div className="container"  onClick={()=>setdispaly1(res1)}>
                                                <div className="row">
                                                <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12">
                                                <button className="button11" style={{background : "skyblue", width : "250px",margin : "10px 0px"}} >{res1.name}</button>
                                          </div>  
                                          <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12">
                                                <button className="button11" style={{background : "skyblue", width : "250px",margin : "10px 0px"}} >Name :  {res1.Name}</button>
                                          </div>  
                                           <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12">
                                                <button className="button11" style={{background : "skyblue", width : "250px"}} >Account Number : {res1.Number}</button>
                                           </div>
                                              </div>
                                                </div>
                                               </center>
                                                </div>
                                                : ""
                                                } 
                                               {/* <center> */}
                                                    { res1.name === "Bank" ? 

dis3 ? 
<div className="arrival11 eereuorowwe col-xl-3 col-lg-3 col-md-4 col-sm-4">
            <a href={res1.link} target="_blank" onClick={()=>setdis3(false)}> <img src={bk1} alt="" className="arrival-Photo111 gorriwotwitwpi"/></a>
            <center><p className="cb cb09 cb23">Bank</p></center>
               </div>
:
                                               <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12" style={{marginTop: "68px"}}>
                                                  <div className="container" style={{textAlign : "center"}}  onClick={()=>setdispaly1(res1)}>
                                                <div className="row">
                                                <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12">
                                                <button className="button11" style={{background : "skyblue", width : "250px",margin : "10px 0px"}} >{res1.name}</button>
                                          </div>  
                                          <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12">
                                                <button className="button11" style={{background : "skyblue", width : "250px",margin : "10px 0px"}} >Name : {res1.Name}</button>
                                          </div>  
  
                                           <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12">
                                                <button className="button11" style={{background : "skyblue", width : "250px",margin : "10px 0px"}} >Bank Name : {res1.BankName}</button>
                                           </div>
                                           <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12">
                                                <button className="button11" style={{background : "skyblue", width : "250px"}} >Bank Account No : {res1.AccountNumber}</button>
                                           </div>
                                              </div>
                                                </div>
                                              </div>
                                                : ""
                                              } 
                                                { res1.name === "Embed video" ? 
                                    <div className="col-xl-12 col-lg-12 col-md-5 col-sm-12" style={{marginTop: "68px"}}>
<center>

                                            <div className="container"  onClick={()=>setdispaly1(res1)}>
                                              <div className="row">
                                                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                  <iframe height="315" src={res1.link}  onClick={()=>setdispaly1(res1)} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                 </div>  
                                              </div>
                                            </div>
                                               </center>
                                    </div>

                                                : ""
                                                } 
                                                                     </>
                                  )
                                })
                                }
                            </div>
                            </div>
                </section>
</>
:
 <center>
   <br /><br /><br />
   {!dispaly ?<h1>This User Profile Not Exists.</h1>:""}
   <br /><br /><br />
 </center>
}

      </div>
        )
}
export default Home