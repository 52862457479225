import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"
import swal from 'sweetalert';
// import $ from 'jquery';
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ClearIcon from '@material-ui/icons/Clear';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import "react-multi-carousel/lib/styles.css";
import "react-multi-carousel/lib/styles.css";
import "./../assets/css/main.css"
import "./../assets/css/util.css"
import "./../assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css"

import ppp11 from "./../assets/images/ba1.jpeg"
import Loader from "react-loader-spinner";

import prow from "./../assets/images/prow.png"

import "./../assets/vendor/css-hamburgers/hamburgers.min.css"

import "./../assets/fonts/iconic/css/material-design-iconic-font.min.css"
import "./../assets/vendor/bootstrap/css/bootstrap.min.css"
import "./../assets/vendor/select2/select2.min.css"
import "./../assets/vendor/slick/slick.css"
import "./../assets/vendor/perfect-scrollbar/perfect-scrollbar.css"


import x1 from "./../assets/images/x1.jpg"

import ln1 from "./../assets/images/svg/social/f linkedin.svg"
import tw1 from "./../assets/images/svg/social/f twitter.svg"
import in1 from "./../assets/images/svg/social/f instagram.svg"
import pi1 from "./../assets/images/svg/social/f pinterest.svg"
import tt1 from "./../assets/images/svg/social/twitch (1).svg"
import tk1 from "./../assets/images/svg/social/tiktok.svg"
import you1 from "./../assets/images/svg/social/youtube (2).svg"
import sc1 from "./../assets/images/svg/social/snapchat.svg"
import fb1 from "./../assets/images/svg/social/f facebook.svg"


import bk1 from "./../assets/images/svg/Payments/bank (9).svg"
import ca1 from "./../assets/images/svg/Payments/cashapp.svg"
import ep1 from "./../assets/images/svg/Payments/easypaisa.png"
import jc1 from "./../assets/images/svg/Payments/SELECT JAZZ CASH LOGO/jazzcash.png"
import ve1 from "./../assets/images/svg/Payments/venmo.svg"
import pp1 from "./../assets/images/svg/Payments/paypal (1).svg"



import cu1 from "./../assets/images/svg/MORE/custom link.svg"
import lt1 from "./../assets/images/svg/MORE/linktree-2.svg"
import vi1 from "./../assets/images/svg/MORE/video.svg"
import we1 from "./../assets/images/svg/MORE/WEBSITE.svg"

import call1 from "./../assets/images/svg/cotact info/call.svg"
import cd1 from "./../assets/images/svg/cotact info/ccard.svg"
import lo1 from "./../assets/images/svg/cotact info/location.svg"
import mail1 from "./../assets/images/svg/cotact info/mail.svg"
import wa1 from "./../assets/images/svg/cotact info/whatapps.svg"
import mess1 from "./../assets/images/svg/cotact info/message (2).svg"







const Home = ( props ) => {
    const [myRef,setRef] = useState( React.createRef())
    const [scrollTop1,setscrollTop] = useState(0)
    const [email , setemail] = useState("")
    const [pass , setpass] = useState("")
    const [dispaly ,  setdispaly ] = useState(true)
    const [User1 ,  setUser1 ] = useState({})
    const [Arr1 ,  setArr1 ] = useState([])
    const [Arr2 ,  setArr2 ] = useState([])
    const [Arr5 ,  setArr5 ] = useState([])
    const [Arr6 ,  setArr6 ] = useState([])
    const [directOffDis ,  setdirectOffDis ] = useState(false)
    const [directOff ,  setdirectOff ] = useState(false)
    const [All1, setAll1] = useState([])    
    const [Personal ,  setPersonal] = useState(true)
    const [Hy ,  setHy] = useState(false)
    const [dragId,setdragId] = useState("")
    const [previewImages,setpreviewImages] = useState([])
    const [Gene ,  setGene ] = useState(true)    
    const [selectDis ,  setselectDis] = useState(false)
    const [range1,setrange1] = useState(0)
    const [disDete ,  setdisDete ] = useState(true)
    const [range123,setrange123] = useState([0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0])
    const [disDete1 ,  setdisDete1 ] = useState(true)
    const [range19,setrange19] = useState([])
    const [characters, updateCharacters] = useState([]);
    const [dispaly1 ,  setdispaly1 ] = useState(true)

    var QRCode = require('qrcode.react');


    useEffect(() =>{
if(! JSON.parse(localStorage.getItem("User"))){
        props.history.push("/login")
      }
      else{
        // setUser1(JSON.parse(localStorage.getItem("User")))
  
    //     this.setState({
    //         email: "",
    // })
    window.scrollTo(0, 0)
    localStorage.removeItem("UserProp")

      




    localStorage.setItem("VenderWorkingThose" ,JSON.stringify(123)) 

    setUser1(JSON.parse(localStorage.getItem("User")))
    // let A1 = JSON.parse(localStorage.getItem("User"))
    fetch("https://tapitdabit-backend.vercel.app/allUser/"+JSON.parse(localStorage.getItem("User"))._id,{
      method: "GET",
      headers :  {
        "Content-Type" : "application/json" , 
      } ,
    })
    .then(res5=>res5.json())
    .then(res6=>{ 


      if(res6.DispalyShow === null){

   
        fetch("https://tapitdabit-backend.vercel.app/DisNullIsNotBuss",{
          method: "POST",
          headers :  {
              "Content-Type" : "application/json" , 
          } ,
          body : JSON.stringify({
          
            id : User1._id
              // MainLine2 :mainline ,
          })
      })
      .then(res11=>res11.json())
      .then((res12)=>{ 
        console.log(res12)
          if(!res12.Error){
            console.log("Successfully Send")
          }
          else{
            console.log("Not")
    
          }
        })
    
      }




      setHy(true)




  setPersonal(res6.DisPersonal)
   setGene(res6.generator)
   if(res6.Direct2.length > 0 ){
    setrange1(100)
  }

  console.log(res6)
  // localStorage.removeItem("User")
  if(res6.SocialBuss||res6.ContactBuss){
    const data = {
      ...res6 , AllBuss : [...res6.SocialBuss,...res6.ContactBuss]
  }
  localStorage.setItem("User" ,JSON.stringify(data)) 
setUser1(res6)
// setUser1(res6)


      const aa = [...res6.SocialBuss,...res6.ContactBuss]
      aa.sort((a, b) => a.sort > b.sort && 1 || -1)
      setArr5(aa)
      setAll1(aa)
      setArr1(res6.SocialBuss)
      setArr2(res6.ContactBuss)
      
      let a =  [...res6.SocialBuss,...res6.ContactBuss]
      console.log(a)





      let A = []
      let we = []
  let we1 = []
      res6.DispalyShow1.map((res45,u)=>{
      //  console.log(res45)
       a.map((res23,r)=>{
          // console.log(res23.name  , res45.name)
          if(res23.name === "Contact"){console.log(u ,r,res45.fullName , res23.fullName , res45.ContactBuss , res23.ContactBuss , res45.Email , res23.Email , res45.Phone , res23.Phone)
            if(res45.fullName === res23.fullName    && res45.Email === res23.Email && res45.Phone === res23.Phone ){
              A.push( {
                ...res23 , yes : true
              })
            }
          }
          else if(res23.name === "Linktree"){
            if(res45.Name1 === res23.Name1 && res45.Name2 === res23.Name2 && res45.Name3 === res23.Name3 ){
              A.push( {
                ...res23 , yes : true
              })
            }
          }
          else{
            if(res45.name === res23.name &&  res45.link === res23.link){
            
            A.push( {
              ...res23 , yes : true
            })
          }
          else{
            return res23
          }
          }
          
          
        })
      })
      
      const se3=  a.map((res1,i)=>{
        return ({
          ...res1 , yes : true
        })
    })


      // let xs =  [...A,...res6.SocialBuss,...res6.ContactBuss ]
      let xs =  se3

      for (let li = 0; li < xs.length; li++) {
        if(xs[li].yes){
          we.push({
            ...xs[li] , yes : false
          })
        }
        
      }

     let b= [...A,...res6.SocialBuss,...res6.ContactBuss ]
    //  let b= [...A,...res6.Social,...res6.Contact ]
     let baa1= [...we,...A ]
     // let ff= []
     console.log(A,baa1,we1)

     const ids = baa1.map(o => o.sort)
const filtered = baa1.filter(({sort}, index) => !ids.includes( sort, index + 1))

console.log(filtered)
filtered.sort((a, b) => a.sort > b.sort && 1 || -1)
let gy = []
for (let l = 0; l < filtered.length; l++) {
  if(filtered[l].yes){
    gy[l] = 1
  }
  else{
    gy[l] = 0
  }
}

setrange19(gy)
setArr5(filtered)
setArr6(filtered)
updateCharacters(filtered)

 console.log(Hy  )
      // let v = b.filter((a, i) => b.findIndex((s) =>{
      // //  return a.name === s.name && a.link === s.link

      //  if(a.name === "Contact")
      //   return a.fullName === s.fullName && a.ContactBuss.length === s.ContactBuss.length && a.Email === s.Email && a.Phone === s.Phone 
      //   else if(a.name === "Linktree")
      //     return a.Name1 === s.Name1 && a.Name2 === s.Name2 && a.Name3 === s.Name3
       
      //   else
      //   return a.name === s.name &&  a.link === s.link

      // }
      
      // ) === i)
      

      // console.log(v,b)
      // v.sort((a, b) => a.sort > b.sort && 1 || -1)

      // setArr5(v)
     


  fetch("https://tapitdabit-backend.vercel.app/ab12",{
    method: "POST",
    headers :  {
        "Content-Type" : "application/json" , 
    } ,
    body : JSON.stringify({
        data :aa,
      id : res6._id
        // MainLine2 :mainline ,
    })
  })
  .then((res3)=>res3.json())
  .then((res44)=>{
    if(res44.Error){

      // swal(res12.Error);
  }
  else{
    
    console.log(res6)
      
  
  
  
  
  }
  }) } 
})




      }
      

    // console.log(scrollTop1)
    return () => {
      document.getElementById("get1").classList.remove("is-active");
      document.getElementById("get2").style.display = "none";
    }


    },[])
    useEffect( () => () => localStorage.removeItem("VenderWorkingThose"), [] );

  


    // const setChange = () =>{
    //     if(document.getElementById("get2").style.display === "none"){
    //         document.getElementById("get1").classList.add("is-active");
    //         document.getElementById("get2").style.display = "block";
    //     }
    //     else{
    //         document.getElementById("get1").classList.remove("is-active");
    //         document.getElementById("get2").style.display = "none";
    //     }
    // }

    // const setChange1 = (e) =>{
    //   if(e === 1 ){
    //     // document.getElementById("signUp").classList.add("right-panel-active");
    //     // document.getElementById("signIn").classList.remove("right-panel-active");
    //     document.getElementById("container1").classList.add("right-panel-active")
    //   }
    //   else{
    //     document.getElementById("container1").classList.remove("right-panel-active")
    //   }
    // }



    
    const setrange191 = (data,e)=>{
      if(User1.Direct2.length === 0){
      let tr = range19

      if(tr[e] === 1)
        tr[e] = 0
      else
        tr[e] = 1
    console.log(tr)
    setrange19(tr)
    
      // for (let i = 0; i < 25; i++) {
      //   if(i === e){
      //     if(range19[e] === 1)
      //     range19[e] = 0
      //     else {
      //       for (let i = 0; i < 25; i++) {
      //         range19[i] = 0
              
      //       }
      //       range19[e] = 1}
      //   }
      //   else{
    
      //     if(range19[e] === 0){
      //       for (let i = 0; i < 25; i++) {
      //         range19[i] = 0
              
      //       }
      //       range19[e] = 1 
      //     }
      //     else{
      //       range19[e] = 0 
      //     }
      //     }
        
      // }
      // setrange19(range19)

      if (disDete1) setdisDete1(false)
        else setdisDete1(true)
        if(document.getElementById(data.photo+""+data.name+""+e).style.opacity === "0.9"){
          console.log("gtg1")
              fetch("https://tapitdabit-backend.vercel.app/dispalyShow13",{
                method: "POST",
                headers :  {
                    "Content-Type" : "application/json" , 
                } ,
                body : JSON.stringify({
                    data,
                  id : User1._id
                    // MainLine2 :mainline ,
                })
            })
            .then(res11=>res11.json())
            .then((res12)=>{ 
                if(res12.Error){
                  setselectDis(false) 
    
                swal(res12.Error);
            }
            else{
              setselectDis(false) 
    
              document.getElementById(data.photo+""+data.name+""+e).style.opacity = "1"
              setUser1({...User1,DispalyShow1 : [...User1.DispalyShow1 , data]})

              // swal("Successfully Update!");
              // window.location.reload(true); 
    
                //     this.setState({
                //         email: "",
                // })
            }
            })
        }
        else{
          console.log("gtg2")
    
          // fetch("https://tapitdabit-backend.vercel.app/dispalyShow11",{
          fetch("https://tapitdabit-backend.vercel.app/dispalyShow1AgainBuss",{
                method: "POST",
                headers :  {
                    "Content-Type" : "application/json" , 
                } ,
                body : JSON.stringify({
                    data,
                  id : User1._id
                    // MainLine2 :mainline ,
                })
            })
            .then(res11=>res11.json())
            .then((res12)=>{ 
                if(res12.Error){
                  setselectDis(false) 
    
                swal(res12.Error);
            }
            else{
              let A1 = []
              User1.DispalyShow1.filter((res2,r)=>{
    
                      if(res2.sort !== data.sort ){
                          A1.push(res2)
                      }
              })
              setUser1({...User1,DispalyShow1 : A1})
    
              setselectDis(false) 
    
                    document.getElementById(data.photo+""+data.name+""+e).style.opacity = "0.9"
                    // swal("Successfully Update!");
                    // window.location.reload(true); 
    
                //     this.setState({
                //         email: "",
                // })
            }
            })
        }
      // }
      
      }
    }


const setTheProfile = (a,data,e)=>{
  if(User1.Direct2.length === 0){

  // if(selectDis){
    if (disDete1) setdisDete1(false)
    else setdisDete1(true)

    // if(range123[e] === 0){
    //   range123[e] = 1 
    // }
    // else{
    //   console.log(546546)
    // }
  //   for (let i = 0; i < 25; i++) {
  //     if(i === e){
  //       if(range123[e] === 1)
  //       range123[e] = 0
  //       else {
  //         for (let i = 0; i < 25; i++) {
  //           range123[i] = 0
            
  //         }
  //         range123[e] = 1}
  //     }
  //     else{

  //       if(range123[e] === 0){
  //         for (let i = 0; i < 25; i++) {
  //           range123[i] = 0
            
  //         }
  //         range123[e] = 1 
  //       }
  //       else{
  //         range123[e] = 0 
  //       }
  //       }
      
  //   }
  // if (disDete1) setdisDete1(false)
  // else setdisDete1(true)
  // setrange123(range123)
  // }
  let tr = range19

  if(tr[e] === 1)
    tr[e] = 0
  else
    tr[e] = 1
console.log(tr)
setrange19(tr)
  console.log(document.getElementById(data.photo+""+data.name+""+e))
  if(range1 === 0){

  if(!selectDis){
    setselectDis(true) 

  // if(directOffDis){
    if(document.getElementById(data.photo+""+data.name+""+e).style.opacity === "0.9"){
      console.log("gtg1")
          fetch("https://tapitdabit-backend.vercel.app/dispalyShow13",{
            method: "POST",
            headers :  {
                "Content-Type" : "application/json" , 
            } ,
            body : JSON.stringify({
                data,
              id : User1._id
                // MainLine2 :mainline ,
            })
        })
        .then(res11=>res11.json())
        .then((res12)=>{ 
            if(res12.Error){
              setselectDis(false) 

            swal(res12.Error);
        }
        else{
          setselectDis(false) 

          document.getElementById(data.photo+""+data.name+""+e).style.opacity = "1"
          // swal("Successfully Update!");
          // window.location.reload(true); 

            //     this.setState({
            //         email: "",
            // })
        }
        })
    }
    else{
      console.log("gtg2")

      // fetch("https://tapitdabit-backend.vercel.app/dispalyShow11",{
      fetch("https://tapitdabit-backend.vercel.app/dispalyShow1AgainBuss",{
            method: "POST",
            headers :  {
                "Content-Type" : "application/json" , 
            } ,
            body : JSON.stringify({
                data,
              id : User1._id
                // MainLine2 :mainline ,
            })
        })
        .then(res11=>res11.json())
        .then((res12)=>{ 
            if(res12.Error){
              setselectDis(false) 

            swal(res12.Error);
        }
        else{
          setselectDis(false) 

                document.getElementById(data.photo+""+data.name+""+e).style.opacity = "0.9"
                // swal("Successfully Update!");
                // window.location.reload(true); 

            //     this.setState({
            //         email: "",
            // })
        }
        })
    }
  // }
  
  }
}
  }
}

// const setdirectOffDis12 = () =>{
//   if(range1 === 0){

//   setdirectOff(true)

//   fetch("https://tapitdabit-backend.vercel.app/dispalyShow22",{
//             method: "POST",
//             headers :  {
//                 "Content-Type" : "application/json" , 
//             } ,
//             body : JSON.stringify({
//                 data :{...Arr5[0] , yes : ""},
//               id : User1._id
//                 // MainLine2 :mainline ,
//             })
//         })
//         .then(res11=>res11.json())
//         .then((res12)=>{ 
//             if(res12.Error){

//             swal(res12.Error);
//         }
//         else{
//                 swal("Successfully Update!");
//                 window.location.reload(true); 
//                            //     this.setState({
//             //         email: "",
//             // })
//         }
//         })
//       }
// }
// const setForGenerator = (e) =>{

//   fetch("https://tapitdabit-backend.vercel.app/generatorShowProfile",{
//             method: "POST",
//             headers :  {
//                 "Content-Type" : "application/json" , 
//             } ,
//             body : JSON.stringify({
//               id : User1._id,
//               generator : e
//             })
//         })
//         .then(res11=>res11.json())
//         .then((res12)=>{ 
//             if(res12.Error){

//             swal(res12.Error);
//         }
//         else{
//           setGene(e)

//                 swal("Successfully Update Your Profile!");
//             //     this.setState({
//             //         email: "",
//             // })
//         }
//         })
// }

// const setRouting1 = (e)=>{
//   if(e === 1){
//     if(JSON.parse(localStorage.getItem("User")).DisPersonal)
//     props.history.push("/user/home1/"+User1._id)
//     else 
//     props.history.push("/user/bussiness/home1/"+User1._id)
//   }
//   else if(e === 4){
//     if(JSON.parse(localStorage.getItem("User")).DisPersonal)
//     props.history.push("/user/editProfile/sort-delete/"+User1.uid)
//     else
//     props.history.push("/user/bussiness/editProfile/sort-delete/"+User1.uid)
//   }
//   else if(e === 3){
//     // props.history.push("/user/analytics/"+User1._id)   

//     const d =  JSON.parse(localStorage.getItem("User")).ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
//     console.log(d)
    
      
//     if (d.length > 0){
//       let a = new Date()
//       let f= a.getDate() +" "+(a.getUTCMonth()+1)+ " "+a.getUTCFullYear()
//       console.log(d[0].End[0]+""+d[0].End[1] >= a.getDate(), d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ,d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear())
//       if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] > a.getUTCFullYear()     ){
//       // if(d[0].End && d[0].End >= f){
//     props.history.push("/user/analytics/"+User1._id)   

//       }
//       else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear()){
//         if( d[0].End[3]+""+d[0].End[4] >a.getUTCMonth()+1 ){
//       props.history.push("/user/analytics/"+User1._id)   

//         }
//         else{
//           if(d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1){
//             if( d[0].End[0]+""+d[0].End[1] >=a.getDate()){
//           props.history.push("/user/analytics/"+User1._id)   

//             }
//             else{
//               document.getElementById("myDivUmer12").style.display= "block"

//               // localStorage.setItem("request123",JSON.stringify(1) )
//               // props.history.push("/user/request-for-pro-item")
//               // swal("Sorry First You Buy")
  
//             }
//           }
//           else{
//             document.getElementById("myDivUmer12").style.display= "block"

//             // localStorage.setItem("request123",JSON.stringify(1) )
//             //   props.history.push("/user/request-for-pro-item")
//             //   swal("Sorry First You Buy")
  
//             }
//           }
//         }
//       else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear() && d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ){
//           if( d[0].End[0]+""+d[0].End[1] >=a.getDate()){
//         props.history.push("/user/analytics/"+User1._id)   

//         }
//         else{
//           document.getElementById("myDivUmer12").style.display= "block"

//           // localStorage.setItem("request123",JSON.stringify(1) )
//           // props.history.push("/user/request-for-pro-item")
//           // swal("Sorry First You Buy")
  
//         }
//       }
//       else{
//         document.getElementById("myDivUmer12").style.display= "block"

//         // localStorage.setItem("request123",JSON.stringify(1) )
//         //   props.history.push("/user/request-for-pro-item")
//         //   swal("Sorry First You Buy")
  
//       }
//     }
//     else{
//       document.getElementById("myDivUmer12").style.display= "block"

//       // localStorage.setItem("request123",JSON.stringify(1) )
//       //   props.history.push("/user/request-for-pro-item")
//       //   swal("Sorry First You Buy")
//   }
//   }
//   else if(e === 2){


//     const d =  JSON.parse(localStorage.getItem("User")).ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
//     console.log(d)
    
      
//     if (d.length > 0){
//       let a = new Date()
//       let f= a.getDate() +" "+(a.getUTCMonth()+1)+ " "+a.getUTCFullYear()
//       console.log(d[0].End[0]+""+d[0].End[1] >= a.getDate(), d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ,d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear())
//       if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] > a.getUTCFullYear()     ){
//       // if(d[0].End && d[0].End >= f){
//         props.history.push("/user/connect/myprofile/"+User1.uid)

//       }
//       else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear()){
//         if( d[0].End[3]+""+d[0].End[4] >a.getUTCMonth()+1 ){
//           props.history.push("/user/connect/myprofile/"+User1.uid)

//         }
//         else{
//           if(d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1){
//             if( d[0].End[0]+""+d[0].End[1] >=a.getDate()){
//               props.history.push("/user/connect/myprofile/"+User1.uid)

//             }
//             else{
//               document.getElementById("myDivUmer").style.display= "block"

//               // localStorage.setItem("request123",JSON.stringify(1) )
//               // props.history.push("/user/request-for-pro-item")
//               // swal("Sorry First You Buy")
  
//             }
//           }
//           else{
//             document.getElementById("myDivUmer").style.display= "block"

//             // localStorage.setItem("request123",JSON.stringify(1) )
//             //   props.history.push("/user/request-for-pro-item")
//             //   swal("Sorry First You Buy")
  
//             }
//           }
//         }
//       else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear() && d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ){
//           if( d[0].End[0]+""+d[0].End[1] >=a.getDate()){
//             props.history.push("/user/connect/myprofile/"+User1.uid)

//         }
//         else{
//           document.getElementById("myDivUmer").style.display= "block"

//           // localStorage.setItem("request123",JSON.stringify(1) )
//           // props.history.push("/user/request-for-pro-item")
//           // swal("Sorry First You Buy")
  
//         }
//       }
//       else{
//         document.getElementById("myDivUmer").style.display= "block"

//         // localStorage.setItem("request123",JSON.stringify(1) )
//         //   props.history.push("/user/request-for-pro-item")
//         //   swal("Sorry First You Buy")
  
//       }
//     }
//     else{
//       document.getElementById("myDivUmer").style.display= "block"

//       // localStorage.setItem("request123",JSON.stringify(1) )
//       //   props.history.push("/user/request-for-pro-item")
//       //   swal("Sorry First You Buy")
//   }
//   }
//   else{

//   }
// }

const setChanger8 = (e)=>{
  document.getElementById("myDivUmer12").style.display= "block"
}

const setForGenerator1 = (e) =>{

  fetch("https://tapitdabit-backend.vercel.app/generatorShowProfile1",{
            method: "POST",
            headers :  {
                "Content-Type" : "application/json" , 
            } ,
            body : JSON.stringify({
              id : User1._id,
              generator : e
            })
        })
        .then(res11=>res11.json())
        .then((res12)=>{ 
            if(res12.Error){

            swal(res12.Error);
        }
        else{
                swal("Successfully Update Your Account!");
                setPersonal(e)
                const f = {...User1 , DisPersonal : e}
                localStorage.removeItem("User")
                localStorage.setItem("User" , JSON.stringify(f))
                if(e) props.history.push("/user/home1/"+User1._id)
                else props.history.push("/user/bussiness/home1/"+User1._id)
            //     this.setState({
            //         email: "",
            // })
        }
        })
}
const setRoter123 = ()=>{
  localStorage.setItem("request123",JSON.stringify(1) )
      props.history.push("/user/request-for-pro-item")

}

const setChanger1 = (e)=>{
  document.getElementById("myDiv1234").style.display= "none"
}
const setChanger2 = (e)=>{
  document.getElementById("myDivUmer").style.display= "none"
}
const setRanger=()=>{
  if(User1.SocialBuss.length > 0 || User1.ContactBuss > 0){

  if(range1 === 0){
    setrange1( 100 )

    fetch("https://tapitdabit-backend.vercel.app/dispalyShow256545",{
      method: "POST",
      headers :  {
          "Content-Type" : "application/json" , 
      } ,
      body : JSON.stringify({
          data :{...characters[0] , yes : ""},
        id : User1._id,
        BackUp : User1.DispalyShow1

          // MainLine2 :mainline ,
      })
  })
  .then(res11=>res11.json())
  .then((res12)=>{ 
      if(res12.Error){

      swal(res12.Error);
  }
  else{
          swal("Successfully Update!");
          window.location.reload(true); 
                     //     this.setState({
      //         email: "",
      // })
  }
  })










  }
  else{
    setrange1( 0 )

    fetch("https://tapitdabit-backend.vercel.app/dispalyShow256NoDirectBuss",{
      method: "POST",
      headers :  {
          "Content-Type" : "application/json" , 
      } ,
      body : JSON.stringify({
          
        id : User1._id
          // MainLine2 :mainline ,
      })
  })
  .then(res11=>res11.json())
  .then((res12)=>{ 
      if(res12.Error){

      swal(res12.Error);
  }
  else{
          swal("Successfully Update!");
          window.location.reload(true); 
                     //     this.setState({
      //         email: "",
      // })
  }
  })













  }
  }
}



const setChanger3 = (e)=>{
  document.getElementById("myDivUmer7").style.display= "none"
}
const setChanger4 = (e)=>{
  document.getElementById("myDivUmer7").style.display= "block"
}














const handleOver = (ev) => {
  ev.preventDefault();
};

const handleDrag = (ev) => {
  setdragId(ev.currentTarget.id)
  // this.setState({
  //   dragId: ev.currentTarget.id
  // });
};

// const handleDrop = (ev) => {
//   ev.preventDefault();
//   // const { previewImages } = this.state;
//   // const { dragId } = this.state;
//   // console.log(ev,dragId)
//   let dragImage = Arr5.find((image) => image.sort == dragId);
//   const ljkhjkl =  dragImage.sort
//   // console.log(ljkhjkl)
//   // const dragImage =  {
//     //   ...dragImage , sort : 
//     // }
//     // console.log(dragImage)
//     let dropImage = Arr5.find(
//       (image) => image.sort == ev.currentTarget.id
//       );
//       console.log()
//       const ljkhjklhj =  dropImage.sort
//       // dragImage = {
//       //   ...dragImage , sort : ljkhjklhj
//       // }
//       // dropImage = {
//       //   ...dropImage , sort : ljkhjkl
//       // }
//       if(dispaly) setdispaly(false)
//       else setdispaly(true) 
//       // console.log(ljkhjklhj)
//   // console.log(dropImage,dragImage , ljkhjkl ,ljkhjklhj)
//   // let arr = moveItem(dragImage.sort - 1, dropImage.sort - 1);
//   // console.log(arr)
//   // setArr5(arr)
//   if(dispaly) setdispaly(false)
//   else setdispaly(true)
//   let arr = [
//     ...Arr5 , dropImage , dragImage
//   ]
//   console.log(dropImage , dragImage)






//   const cf = Arr5.map((res343,i)=>{
//   if(res343.name === "Contact" && dragImage.name === "Contact"){
//     if( dragImage.fullName === res343.fullName && dragImage.ContactBuss.lenght === res343.ContactBuss.lenght && dragImage.Email === res343.Email && dragImage.Phone === res343.Phone ){
//     // if(dragImage.fullName === undefined && dragImage.fullName === res343.fullName && dragImage.Contact.lenght === res343.Contact.lenght && dragImage.Email === res343.Email && dragImage.Phone === res343.Phone ){
//       // console.log(ljkhjkl,ljkhjklhj,"1",dragImage.fullName , res343.fullName , dragImage.Contact.lenght ,res343.Contact.lenght , dragImage.Email , res343.Email , dragImage.Phone , res343.Phone)
//       return {
//         ...res343 , sort : ljkhjklhj
//       }
//     }
//     else{
//       return res343
//     }
//     }
//   //   else{
//   //     console.log("3")
//   //     A8.push( {
//   //       ...res343 
//   //     })          }
//   // }
//   // else{
//     if(dragImage.name === res343.name &&  dragImage.link === res343.link){
//       console.log(ljkhjkl,ljkhjklhj,"2")
  
//       return {
//       ...res343  , sort : ljkhjklhj
//     }
//   }
//   else{
//     return res343
//   }
//   // }
  
//   })


//   const cf1 = cf.map((res343,i)=>{
//   if(res343.name === "Contact" && dropImage.name === "Contact"){
//     if( dropImage.fullName === res343.fullName && dropImage.ContactBuss.lenght === res343.ContactBuss.lenght && dropImage.Email === res343.Email && dropImage.Phone === res343.Phone ){
//     // if(dropImage.fullName === undefined && dropImage.fullName === res343.fullName && dropImage.Contact.lenght === res343.Contact.lenght && dropImage.Email === res343.Email && dropImage.Phone === res343.Phone ){
//       // console.log(ljkhjkl,ljkhjklhj,"1",dropImage.fullName , res343.fullName , dropImage.Contact.lenght ,res343.Contact.lenght , dropImage.Email , res343.Email , dropImage.Phone , res343.Phone)
//       return {
//         ...res343 , sort : ljkhjkl
//       }
//     }
//     else{
//       return res343
//     }
//     }
//   //   else{
//   //     console.log("3")
//   //     A8.push( {
//   //       ...res343 
//   //     })          }
//   // }
//   // else{
//     if(dropImage.name === res343.name &&  dropImage.link === res343.link){
//       console.log(ljkhjkl,ljkhjklhj,"2")
  
//       return {
//       ...res343  , sort : ljkhjkl
//     }
//   }
//   else{
//     return res343
//   }
//   // }
  
//   })


// console.log(cf,cf1)
// const A45 = []
// const A56 = []
// cf1.map((res343,i)=>{
//   if(res343.name === "Contact"){
//      A45.push( {
//               ...res343 
//             }) 
//           }

//     else{
//       A56.push( {
//               ...res343 
//             }) 
//   }
  
//   // }
  
//   })

// const s1 = A56
// const c1 = A45
// const a_1 = cf1
// console.log(s1 ,c1 ,a_1 )
// fetch("https://tapitdabit-backend.vercel.app/updatetoallonebusinsess",{
//         method: "POST" , 
//         headers: {
//             "Content-Type" : "application/json" ,
//         } , 
//         body : JSON.stringify({
//             id : User1._id,
//             AllBuss : cf1,
//             SocialBuss : A56,
//             ContactBuss : A45,
//         })
//     })
//     .then(res=>res.json())
//     .then(res2=>{
//     //     // const newData = Arr1.filter(data1=>  data1.name !== data.name && data1.link !== data.link && data1.photo !== data.photo : data1.name !== data.name && data1.fullName !== data.fullName && data1.Phone !== data.Phone && data1.Email !== data.Email)
//     const ad = cf1
//     ad.sort((a, b) => a.sort > b.sort && 1 || -1)
//         setArr5(ad)
//         setArr1(s1)
//         setArr2(c1)

//         swal("Successfully Updated")

//     })

// };




//  const moveItem = (from, to)=> {
//     // const { previewImages } = this.state;
//     const f = Arr5.splice(from, 1)[0];
//     Arr5.splice(to, 0, f);
//     return Arr5;
//   }







//   const  setDeleteEK = (data,i)=>{
//     console.log(data,User1._id)
//     if(disDete){
//       setdisDete(false)


//       let A8 = []
//       User1.Counter1 && User1.Counter1.map((res343,i)=>{
//       if(res343.name === "Contact"){
//         if(data.fullName === res343.fullName && data.ContactBuss.lenght === res343.ContactBuss.lenght && data.Email === res343.Email && data.Phone === res343.Phone ){
//           console.log("1")
//           A8.push( {
//             ...res343 
//           })
//         }
//         }
//       //   else{
//       //     console.log("3")
//       //     A8.push( {
//       //       ...res343 
//       //     })          }
//       // }
//       // else{
//         if(data.name === res343.name &&  data.link === res343.link  &&  data.sort === res343.sort){
//           console.log("2")

//         A8.push( {
//           ...res343 
//         })
//       }
//       // }
    
//     })
//     console.log(A8)


//     const newData1 = Arr5.filter((data1,j)=> i !== j)
//     const Arr11 = []
//     const Arr22 = []
//     newData1.map((res,p)=>{
//       if(res.name === "Contact"){
//         Arr22.push(res)
//       }
//       else{
//         Arr11.push(res)

//       }
//     }) 
//     const A = []
//     const A1 = []
//     User1.DispalyShow1.map((res23,g)=>{
//       console.log(res23)
//       if(res23.name === "Contact"){
//         if(data.fullName === res23.fullName && data.ContactBuss.lenght === res23.ContactBuss.lenght && data.Email === res23.Email && data.Phone === res23.Phone ){
//           console.log("1")
//           A.push( {
//             ...res23 
//           })
//         }
//         else{
//           console.log("3")
//           A1.push(res23)
//         }
//       }
//       else{
       
//         if(data.name === res23.name &&  data.link === res23.link && res23.sort === data.sort){
 
//              console.log("2")

//         A.push( {
//           ...res23 
//         })
//           }
         

//       else{
//         console.log("3")
//         A1.push(res23)
//       }
//       }
//     })
//     console.log(A,A1) 
//     fetch("https://tapitdabit-backend.vercel.app/deleteSocialBussiness",{
//         method: "POST" , 
//         headers: {
//             "Content-Type" : "application/json" ,
//         } , 
//         body : JSON.stringify({
//             id : User1._id,
//             AllBuss : newData1,
//             SocialBuss : Arr11,
//             ContactBuss : Arr22,
//             dis : A1,
//             d : A8

//         })
//     })
//     .then(res=>res.json())
//     .then(res2=>{
//     //     // const newData = Arr1.filter(data1=>  data1.name !== data.name && data1.link !== data.link && data1.photo !== data.photo : data1.name !== data.name && data1.fullName !== data.fullName && data1.Phone !== data.Phone && data1.Email !== data.Email)
//         setArr5(newData1)
//         setArr1(Arr11)
//         setArr2(Arr22)
//         // setdisDete(true)
//         // props.history.push("/user/bussiness/home1/"+User1._id)
//         window.location.assign("/user/bussiness/home1/"+User1.uid)


//     //     // setAccountAdmin(newData)
//     //     // props.history.push("/alluser")
//     })
//   }
//   else{
//     // swal("Plz Wait ")
//   }
// }




const  setEditEK = (data,i)=>{
  localStorage.setItem("EditDataForAllData1" , JSON.stringify(Arr5))
  localStorage.setItem("EditData123" , JSON.stringify(data))
  localStorage.setItem("EditData12" , JSON.stringify(data))
  localStorage.setItem("Edit123456" , JSON.stringify(i))
  props.history.push("/user/bussiness/edit-link/"+User1._id)
}













const handleOnDragEnd = (result)=> {
  if (!result.destination) return;
  if(User1.Direct2.length === 0){

  const items = Array.from(characters);
  console.log(result)
  const [reorderedItem] = items.splice(result.source.index, 1);
  items.splice(result.destination.index, 0, reorderedItem);

  updateCharacters(items);
  console.log(items)
  

let A = []
  for (let i = 0; i < characters.length; i++) {
    console.log(characters[i], i)
    if(i === result.source.index){
      A.push({
        ...characters[i] , sort : result.destination.index + 1
      })
    }
    else if(i === result.destination.index){
      A.push({
        ...characters[i] , sort : result.source.index + 1
      })
    }
    else{
      A.push({
        ...characters[i]
      })
    }
    
  }
  console.log(A,range19)
  A.sort((a, b) => a.sort > b.sort && 1 || -1)

  let gy = []
for (let i = 0; i < A.length; i++) {
  if(A[i].yes){
    gy[i] = 1
  }
  else{
    gy[i] = 0
  }
}
setrange19(gy)
setdispaly1(!dispaly1)
console.log(gy)
setTimeout(()=>{
  
  setrange19(gy)
},1000)



updateCharacters(A)
setdispaly1(!dispaly1)







    let A091 = []
    A.map((res66,u)=>{
      User1.DispalyShow1.filter((res2,r)=>{
        if(res2.name === "Contact"){
            if(res2.fullName === res66.fullName && res2.ContactBuss.length === res66.ContactBuss.length && res2.Email === res66.Email && res2.Phone === res66.Phone ){
            A091.push(res66) 
            }
          }
        else if(res2.name === "Linktree"){
            if(res2.name === res66.name &&  res2.Name1 === res66.Name1 &&  res2.email1 === res66.email1){
            A091.push(res66) 
            }
          }
         
          else{
            if(res2.photo){
               if(res2.name === res66.name &&  res2.link === res66.link  &&  res2.photo === res66.photo &&  res2.user === res66.user ){
                A091.push(res66) 
          }
        }
        else{
          if(res2.name === res66.name &&  res2.link === res66.link  &&  res2.user === res66.user ){
           A091.push(res66) 
     }
          
            }
           
          
          }
    })
    })


















    const A45 = []
    const A56 = []
    A.map((res343,i)=>{
      if(res343.name === "Contact"){
         A45.push( {
                  ...res343 
                }) 
              }
    
        else{
          A56.push( {
                  ...res343 
                }) 
      }
      
      // }
      
      })

      fetch("https://tapitdabit-backend.vercel.app/updatetoallonebusinsess",{
        method: "POST" , 
        headers: {
            "Content-Type" : "application/json" ,
        } , 
        body : JSON.stringify({
            id : User1._id,
            AllBuss : A,
            SocialBuss : A56,
            ContactBuss : A45,
            dis : A091
        })
    })
    .then(res=>res.json())
    .then(res2=>{
      // swal("Successfully Updated")
    })


    setUser1({...User1,DispalyShow1 : A091 , SocialBuss : A56 ,ContactBuss : A45,AllBuss :A})


console.log(A091)













  }



}







        return (
     

      <div style={{background : "rgb(51, 51, 51)" }}>
        
        
        { JSON.parse(localStorage.getItem("User"))&& JSON.parse(localStorage.getItem("User")).SocialBuss.length !== 0 &&  Arr6.length ===0 ?
                <Loader
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={10000} //3 secs
        className="loader-1"
      /> 
      :""
}  

        <div id="myDivUmer12">
                {/* <img src={guides} alt=""  id="myDiv23456"/> */}
                <center>
                  <h1>
                    Analytics
                </h1>
                  </center>
                <center><p>
                Unlock all links with Pro to see insights of your account. <br />
Tap Buy now  to upgrade.
          </p></center>
                  <center>
                  <button class="btn btn-dark F23 f45" onClick={()=>setRoter123()}>Buy Now </button>
                  </center>
                <ClearIcon id="myDiv3456767"  style={{color : "black",fontSize : "30px",position : "absolute" , right: "10%",top : "10%" , zIndex: "2"}} onClick={()=>setChanger8()}/>
            </div> 
        <div id="myDivUmer">
                {/* <img src={guides} alt=""  id="myDiv23456"/> */}
                <center>
                  <h1>
                     Build Your Network
                </h1>
                  </center>
                <center><p>
Capture leads using TapitDabit and favorite, group, sort and manage them all from here. Start building your connections now                  </p></center>
                  <center>
                  <button class="btn btn-dark F23 f45" onClick={()=>setRoter123()}>Buy Now </button>
                  </center>
                <ClearIcon id="myDiv34567"  style={{color : "black",fontSize : "30px",position : "absolute" , right: "10%",top : "10%" , zIndex: "2"}} onClick={()=>setChanger2()}/>
            </div> 
            <div style={{height : "2px"}}>

          </div>
          <div id="myDivUmer7">
                {/* <img src={guides} alt=""  id="myDiv23456"/> */}
                {/* <center>
                  <h1>
                     Build Your Network
                </h1>
                  </center> */}
                <center><p style={{padding :  "50px 30px"}}>
                TapitDabit Direct allows you to pop anyone directly to a link of your choice, without going to your full profile  .  </p></center>
                 
                  <ClearIcon id="myDiv34567"  style={{color : "black",fontSize : "30px",position : "absolute" , right: "10%",top : "10%" , zIndex: "2"}} onClick={()=>setChanger3()}/>

            </div> 
            <center>
            <div className="container mi2"style={{  width: "91%",paddingTop: "29px",marginTop : "70px", paddingBottom  :"28px"}}>
              <div className="row" style={{padding  : "20px 0px"}}>

              
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" >
               <span className="button11"   style={{width : "165px",background :Personal ? "rgb(35, 39, 43)" : "white" ,color: !Personal ? "rgb(35, 39, 43)": "white",border : "2px solid rgb(35, 39, 43)" , borderBottomRightRadius : "0px",borderTopRightRadius : "0px"}}  onClick={()=>setForGenerator1(true)}>Personal</span>
               <span className="button11"   style={{width : "165px", background :!Personal ? "rgb(35, 39, 43)" :  "white" ,color:Personal ?   "rgb(35, 39, 43)" : "white",border : "2px solid rgb(35, 39, 43)", borderBottomLeftRadius : "0px",borderTopLeftRadius : "0px",padding: "12px 11px"}}  onClick={()=>setForGenerator1(false)}> Bussiness <img src={prow} style={{width : "30px" , height : "30px" , borderRadius : "30px"}} alt="" /></span>

          
           </div>
                     
</div>
            </div>
             
          </center>

        <div className="cc1 hello12345 um1 um4" style={{justifyContent : "flex-start",height:"299px",background: "rgb(51,51,51)"}}>
              <div className="container1 hello123 um1 um4" id="container1" style={{margin :"21px 0px" , minHeight: "299px",width : "90%"}}>
              <div style={{background : `url(${ppp11})` , display : "flex" , justifyContent : "center",alignItems :  "center", width : "50%",height : "299px" , flexDirection : "column"}}  className="link-user-photo um1 um4">
                <img src={User1.ProfilePhotoBuss ? User1.ProfilePhotoBuss : x1} width= "45%" height= "300px" alt="" className="uit1" style={{borderRadius :  "50%",height: "216px",width: "214px",margin: "22px 0px"}}/>
                {/* <button className="button11 F23 btn btn-dark f45"   style={{width : "205px",background :"rgb(35, 39, 43)"  ,color:  "white" ,border : "1px solid white", margin : "3px 0px"}} onClick={()=>props.history.push("/user/bussiness/profile-edit/"+User1._id)}>Edit Profile</button> */}

</div>                <div className="overlay-container um1 um4">
                  <div className="overlay" style={{background : "rgb(2, 3, 27)"}}>
                    <div className="overlay-panel overlay-right">
                     {/* {Gene ? 
              <button className=""  type="button"  style={{minWidth : "156px"}}  class="button11 F23 btn btn-dark f45" onClick={()=>setForGenerator(false)}>Profile  On</button>
              :
              <button  type="button"  style={{minWidth : "156px"}}  class="btn btn-dark F23 f45" onClick={()=>setForGenerator(true)}>Profile  Off</button>
            } */}
            {/* {Gene ? 
<div style={{display : "flex" , justifyContent  :"center",marginTop: "-52px"}}>
           
                    <span className="bh1" style={{marginTop: "13px",marginRight: "10px"}}> <b> Profile </b></span>   <button  type="button" style={{width : "120px",background :Gene ? "rgb(35, 39, 43)" : "white" ,color: !Gene ? "rgb(35, 39, 43)": "white",border : "2px solid rgb(35, 39, 43)" ,borderRadius : "20px", borderBottomRightRadius : "0px",borderTopRightRadius : "0px"}}  class="F23 btn btn-dark f45 nm12">On</button>
              
              <button  type="button"  class="btn btn-dark F23 f45 nm12" style={{width : "130px", background :!Gene ? "rgb(35, 39, 43)" :  "white" ,color:Gene ?   "rgb(35, 39, 43)" : "white",border : "2px solid rgb(35, 39, 43)" ,borderRadius : "20px", borderBottomLeftRadius : "0px",borderTopLeftRadius : "0px"}} onClick={()=>setForGenerator(false)}>Off</button>
            
              
                          </div>
                          :
<div style={{display : "flex" , justifyContent  :"center",marginTop: "-52px"}}>
           
                    <span className="bh1" style={{marginTop: "13px",marginRight: "10px"}}> <b> Profile</b> </span>   <button  type="button"  class="F23 btn btn-dark f45 nm12"  style={{width : "120px",background :Gene ? "rgb(35, 39, 43)" : "white" ,color: !Gene ? "rgb(35, 39, 43)": "white",border : "2px solid rgb(35, 39, 43)" ,borderRadius : "20px", borderBottomRightRadius : "0px",borderTopRightRadius : "0px"}} onClick={()=>setForGenerator(true)}>On</button>
              
              <button  type="button" class="btn btn-dark F23 f45 nm12"  style={{width : "130px", background :!Gene ? "rgb(35, 39, 43)" :  "white" ,color:Gene ?   "rgb(35, 39, 43)" : "white",border : "2px solid rgb(35, 39, 43)",borderRadius : "20px", borderBottomLeftRadius : "0px",borderTopLeftRadius : "0px"}}  >Off</button>
            
              
                          </div>
                    } */}
            <br />
             <h3 className="f67"> <b> {User1.NameBuss}</b></h3>
                      <p className="ppp1">{User1.bioBuss ? User1.bioBuss : ""}</p>
            {/* <button className="button11 f45 F23" style={{minWidth : "156px"}} onClick={()=>props.history.push("/user/bussiness/home/"+User1.uid)}  type="button"  class="F23 btn btn-dark F45">Add Links</button> */}
            {/* <button className="button11 F23 btn btn-dark f45"   style={{width : "205px",background :"rgb(35, 39, 43)"  ,color:  "white" ,border : "1px solid white"}} onClick={()=>props.history.push("/user/bussiness/profile-edit/"+User1._id)}>Edit Profile</button> */}

            

                    </div>
                  </div>
                </div>
              </div>
          </div>

          <center>
            <div className="container">
              <div className="row">

              
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style={{marginTop : "10px" }}>
               
               {/* <button className="button11 bt-145"   style={{width : "265px",background :"rgb(35, 39, 43)"  ,color:  "white",border : "2px solid rgb(35, 39, 43)",borderRight : "2px solid white", borderBottomRightRadius : "0px",borderTopRightRadius : "0px" }} onClick={()=>props.history.push("/user/bussiness/profile-edit/"+User1._id)}>Profile</button> */}
               <button className="button11 bt-14580"   style={{width : "153px",background :"rgb(244, 244, 244)" ,fontWeight :"bold" ,color:  "black",border : "1px solid white",borderBottomLeftRadius  : "0px" ,borderBottomRightRadius  : "0px" ,borderTopRightRadius  : "0px",padding  :"15px 0px",textTransform : "capitalize",fontSize : "13px",fontFamily: 'Poppins-Regular' }}  onClick={()=>props.history.push("/user/bussiness/profile-edit/"+User1._id)}>Edit Card</button>
                    <button className="button11 bt-14580"   style={{width : "153px",background :"rgb(244, 244, 244)"  ,fontWeight :"bold" ,color:  "black",border : "1px solid white",borderBottomLeftRadius  : "0px" ,borderBottomRightRadius  : "0px" ,borderTopLeftRadius  : "0px",padding  :"15px 0px",textTransform : "capitalize",fontSize : "13px",fontFamily: 'Poppins-Regular' }} onClick={()=>props.history.push("/user/"+User1.uid)}>Preview Card</button>               {/* <button className="button11"   style={{width : "265px",background :"rgb(35, 39, 43)"  ,color:  "white",border : "2px solid rgb(35, 39, 43)" }} onClick={()=>props.history.push("/user/profile-edit/"+User1._id)}>Edit Profile</button> */}

          
           </div>
           <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style={{marginBottom : "0px" }}>
               
               {/* <button className="button11 bt-145"   style={{width : "265px",background :"rgb(35, 39, 43)"  ,color:  "white",border : "2px solid rgb(35, 39, 43)",borderRight : "2px solid white", borderBottomRightRadius : "0px",borderTopRightRadius : "0px" }} onClick={()=>props.history.push("/user/bussiness/profile-edit/"+User1._id)}>Profile</button> */}
               <button className="button11 bt-145"  style={{width : "306px",background :"rgb(244, 244, 244)"  ,fontWeight :"bold" ,color:  "black",border : "1px solid white" ,borderTopLeftRadius  : "0px",borderTopRightRadius  : "0px",textTransform : "capitalize",padding  :"15px 0px",fontSize : "13px",fontFamily: 'Poppins-Regular' }} onClick={()=>props.history.push("/user/bussiness/home/"+User1._id)}>Add Links & Contact Info</button>
               {/* <button className="button11"   style={{width : "265px",background :"rgb(35, 39, 43)"  ,color:  "white",border : "2px solid rgb(35, 39, 43)" }} onClick={()=>props.history.push("/user/profile-edit/"+User1._id)}>Edit Profile</button> */}

          
           </div>
          
              
             
</div>
            </div>
             
          </center>
          {/* <center>
            <h3 style={{color :  "white"}}>Scan for other</h3>
            <div style={{padding : "10px" , background : "white" ,height : "150px", width : "160px"}}>

        {User1?
<QRCode value={"https://tapitdabit.com/user/"+User1.uid} />
:""
        }
            </div>
          </center> */}

          

        

          <center>
            <div className="container">
              <div className="row">

              
              {/* <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style={{margin : "10px 0px" }}>
               
              <span style={{color : "white" , fontSize : "18px", padding : "0px 10px" , fontWeight : "600"}}> Edit Mode  </span>  <button className="button11 rkjjk"   style={{width : "110px",background :directOffDis ? "rgb(35, 39, 43)" : "white" ,color: !directOffDis ? "rgb(35, 39, 43)": "white",border : "2px solid rgb(35, 39, 43)" , borderBottomRightRadius : "0px",borderTopRightRadius : "0px"}} onClick={()=>setdirectOffDis(true)}> On</button>
                    <button className="button11 rkjjk"   style={{width : "110px", background :!directOffDis ? "rgb(35, 39, 43)" :  "white" ,color:directOffDis ?   "rgb(35, 39, 43)" : "white",border : "2px solid rgb(35, 39, 43)", borderBottomLeftRadius : "0px",borderTopLeftRadius : "0px"}}  onClick={()=>setdirectOffDis(false)}> Off</button>

               
                </div> */}
              {/* <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12" style={{margin : "10px 0px" }}>
               
                    <button className="button11"   style={{width : "165px",background :directOff ? "rgb(35, 39, 43)" : "white" ,color: !directOff ? "rgb(35, 39, 43)": "white",border : "2px solid rgb(35, 39, 43)" , borderBottomRightRadius : "0px",borderTopRightRadius : "0px"}}  onClick={()=>setdirectOffDis12()}>Direct On</button>
                    <button className="button11"   style={{width : "165px", background :!directOff ? "rgb(35, 39, 43)" :  "white" ,color:directOff ?   "rgb(35, 39, 43)" : "white",border : "2px solid rgb(35, 39, 43)", borderBottomLeftRadius : "0px",borderTopLeftRadius : "0px"}}    onClick={()=>setdirectOff(false)}>Direct Off</button>

               
                </div> */}
              
             
</div>
            </div>
             
          </center>
          <center>
            <div className="container">
              <div className="row">

              
              {/* <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style={{margin : "10px 0px" }}>
               
                    <button className="button11"   style={{width : "165px",background :Personal ? "rgb(35, 39, 43)" : "white" ,color: !Personal ? "rgb(35, 39, 43)": "white",border : "2px solid rgb(35, 39, 43)" , borderBottomRightRadius : "0px",borderTopRightRadius : "0px"}}  onClick={()=>setForGenerator1(true)}>Personal</button>
                    <button className="button11"   style={{width : "165px", background :!Personal ? "rgb(35, 39, 43)" :  "white" ,color:Personal ?   "rgb(35, 39, 43)" : "white",border : "2px solid rgb(35, 39, 43)", borderBottomLeftRadius : "0px",borderTopLeftRadius : "0px"}}  onClick={()=>setForGenerator1(false)}> Bussiness </button>

               
                </div> */}
                   <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style={{margin : "10px 0px" }}>
               
               <span style={{width : "165px",padding  :  "10px 15px 10px 0px",background : "rgb(51, 51, 51)" ,color:  "rgb(35, 39, 43)",color : "white"}}><b style={{padding : "12px 30px 10px 0px"}}>Sharing Personal  <span style={{fontSize : "20px" , position : "relative" , left : "10px" , top : "3px"}} onClick={()=>setChanger4()}>  ⓘ<span style={{marginLeft:"20px"}}>  Direct  </span> </span> </b> <div  onClick={()=>setRanger()} style={{position: "absolute",right: "-60px" ,minWidth: "29px",height: "20px",background: "transparent"}} className="ki1 kii1"></div><input className="cx nj1"  style={{width : "30px", marginTop : "2%",height:"5px",dispaly : "inline"}} type="range" min="0" max="100" value={range1} onClick={()=>setRanger()} /> </span>
               {/* <button className="button11"   style={{width : "165px", background :!directOff ? "rgb(35, 39, 43)" :  "white" ,color:directOff ?   "rgb(35, 39, 43)" : "white",border : "2px solid rgb(35, 39, 43)", borderBottomLeftRadius : "0px",borderTopLeftRadius : "0px"}}    onClick={()=>setdirectOff(false)}>Direct Off</button> */}
             

          
           </div>
         
         
              
             
</div>
            </div>
             
          </center>






          



          <DragDropContext onDragEnd={(e)=>handleOnDragEnd(e)}>
          <Droppable droppableId="characters">
            {(provided) => (
              <ul className="characters" {...provided.droppableProps} ref={provided.innerRef} style={{width:  "90%",margin : "auto"}}>
        <div className="container" style={{margin : "30px auto" , width : "50%",padding:"0px 0px 103px 0px"}}>
      <div className="row justify-content-center">
                {characters.map((res1, i) => {
                  return (
                    <Draggable key={i+"klkl"} draggableId={i+"klkl"} index={i}>
                      {(provided) => (
                        <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                       {! res1.name1 && res1.name === "Contact" ? 
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div style={{background :"#f4f4f4", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px",opacity : res1.yes ? 1 : 0.9}} className="dfdfdsf"  id={res1.photo+""+res1.name+""+i}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    {/* <MoreVertIcon style={{color : "rgb*(18, 18, 18)" , fontSize : "33px" , marginTop : "-5px"}}/> */}
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}  onClick={()=>setEditEK(res1,i)}>
                    <img src={cd1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "7px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>{res1.fullName}</p>
                    </div>
                    <div  onClick={()=>setTheProfile("Con",res1,i)} style={{position: "absolute",right: "-60px" ,minWidth: "29px",height: "20px",background: "transparent"}} className="ki1 kii1 kuuuiuiui"></div><input style={{width : "30px", marginTop : "3%",height:"8px"}} className="cx nji" type="range" min="0" max="100" value={range19[i] === 1  ? 100 :  0} onClick={()=>setrange191(res1,i)}  />
                </div>
            </div>
          </div>
        :
        ""
        }
        { res1.name1 && res1.name === "Contact" ? 
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div style={{background :"#f4f4f4", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px",opacity : res1.yes ? 1 : 0.9}} className="dfdfdsf"  id={res1.photo+""+res1.name+""+i}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    {/* <MoreVertIcon style={{color : "rgb*(18, 18, 18)" , fontSize : "33px" , marginTop : "-5px"}}/> */}
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}  onClick={()=>setEditEK(res1,i)}>
                    <img src={res1.photo1 ? res1.photo1  : cd1} style={{width : "45px" , height: "39px"}} />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "7px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>{res1.name1}</p>
                    </div>
                    <div  onClick={()=>setTheProfile("Con",res1,i)} style={{position: "absolute",right: "-60px" ,minWidth: "29px",height: "20px",background: "transparent"}} className="ki1 kii1 kuuuiuiui"></div><input style={{width : "30px", marginTop : "3%",height:"8px"}} className="cx nji" type="range" min="0" max="100" value={range19[i] === 1  ? 100 :  0} onClick={()=>setrange191(res1,i)}  />
                </div>
            </div>
          </div>
        :
        ""
        }
        {res1.photo === "" && res1.name === "Embed video" ? 
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div style={{background :"#f4f4f4", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px",opacity : res1.yes ? 1 : 0.9}} className="dfdfdsf"  id={res1.photo+""+res1.name+""+i}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    {/* <MoreVertIcon style={{color : "rgb*(18, 18, 18)" , fontSize : "33px" , marginTop : "-5px"}}/> */}
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}  onClick={()=>setEditEK(res1,i)}>
                    <img src={vi1} style={{width : "45px" , height: "39px"}} />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "7px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Embed Video</p>
                    </div>
                    {/* <div  onClick={()=>setTheProfile("Con",res1,i)} style={{position: "absolute",right: "-60px" ,minWidth: "29px",height: "20px",background: "transparent"}} className="ki1 kii1 kuuuiuiui"></div><input style={{width : "30px", marginTop : "3%",height:"8px"}} className="cx nji" type="range" min="0" max="100" value={range19[i] === 1  ? 100 :  0} onClick={()=>setrange191(res1,i)}  /> */}
                    <div  onClick={()=>setrange191(res1,i)} style={{position: "absolute",right: "-60px" ,minWidth: "29px",height: "20px",background: "transparent"}} className="ki1 kii1 kuuuiuiui"></div><input style={{width : "30px", marginTop : "3%",height:"8px"}} className="cx nji" type="range" min="0" max="100" value={range19[i] === 1  ? 100 :  0} onClick={()=>setrange191(res1,i)}  />
                </div>
            </div>
          </div>
        :
        ""
        }
        {res1.photo === "" && res1.name === "JazzCash" ? 
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div style={{background :"#f4f4f4", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px",opacity : res1.yes ? 1 : 0.9}} className="dfdfdsf"  id={res1.photo+""+res1.name+""+i}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    {/* <MoreVertIcon style={{color : "rgb*(18, 18, 18)" , fontSize : "33px" , marginTop : "-5px"}}/> */}
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}  onClick={()=>setEditEK(res1,i)}>
                    {/* <img src={jc1} style={{width : "45px" , height: "39px",objectFit: "contain"}} /> */}
                    <img src={jc1} style={{width : "53px" , height: "39px",objectFit: "fill"}}  />

                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "7px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>JazzCash</p>
                    </div>
                    <div  onClick={()=>setrange191(res1,i)} style={{position: "absolute",right: "-60px" ,minWidth: "29px",height: "20px",background: "transparent"}} className="ki1 kii1 kuuuiuiui"></div><input style={{width : "30px", marginTop : "3%",height:"8px"}} className="cx nji" type="range" min="0" max="100" value={range19[i] === 1  ? 100 :  0} onClick={()=>setrange191(res1,i)}  />
                </div>
            </div>
          </div>
        :
        ""
        }
        {res1.photo === "" && res1.name === "EasyPaisa" ? 
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div style={{background :"#f4f4f4", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px",opacity : res1.yes ? 1 : 0.9}} className="dfdfdsf"  id={res1.photo+""+res1.name+""+i}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    {/* <MoreVertIcon style={{color : "rgb*(18, 18, 18)" , fontSize : "33px" , marginTop : "-5px"}}/> */}
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}  onClick={()=>setEditEK(res1,i)}>
                    <img src={ep1} style={{width : "45px" , height: "39px"}} />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "7px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>EasyPaisa</p>
                    </div>
                    <div  onClick={()=>setrange191(res1,i)} style={{position: "absolute",right: "-60px" ,minWidth: "29px",height: "20px",background: "transparent"}} className="ki1 kii1 kuuuiuiui"></div><input style={{width : "30px", marginTop : "3%",height:"8px"}} className="cx nji" type="range" min="0" max="100" value={range19[i] === 1  ? 100 :  0} onClick={()=>setrange191(res1,i)}  />
                </div>
            </div>
          </div>
        :
        ""
        }
        {res1.photo === "" && res1.name === "Bank" ? 
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div style={{background :"#f4f4f4", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px",opacity : res1.yes ? 1 : 0.9}} className="dfdfdsf"  id={res1.photo+""+res1.name+""+i}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    {/* <MoreVertIcon style={{color : "rgb*(18, 18, 18)" , fontSize : "33px" , marginTop : "-5px"}}/> */}
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}  onClick={()=>setEditEK(res1,i)}>
                    <img src={bk1} style={{width : "45px" , height: "39px"}} />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "7px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Bank</p>
                    </div>
                    <div  onClick={()=>setrange191(res1,i)} style={{position: "absolute",right: "-60px" ,minWidth: "29px",height: "20px",background: "transparent"}} className="ki1 kii1 kuuuiuiui"></div><input style={{width : "30px", marginTop : "3%",height:"8px"}} className="cx nji" type="range" min="0" max="100" value={range19[i] === 1  ? 100 :  0} onClick={()=>setrange191(res1,i)}  />
                </div>
            </div>
          </div>
        :
        ""
        }
        {res1.photo === "" && res1.name === "Linktree" ? 
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div style={{background :"#f4f4f4", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px",opacity : res1.yes ? 1 : 0.9}} className="dfdfdsf"  id={res1.photo+""+res1.name+""+i}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    {/* <MoreVertIcon style={{color : "rgb*(18, 18, 18)" , fontSize : "33px" , marginTop : "-5px"}}/> */}
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}  onClick={()=>setEditEK(res1,i)}>
                    <img src={lt1} style={{width : "45px" , height: "39px"}} />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "7px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Linktree</p>
                    </div>
                    <div  onClick={()=>setrange191(res1,i)} style={{position: "absolute",right: "-60px" ,minWidth: "29px",height: "20px",background: "transparent"}} className="ki1 kii1 kuuuiuiui"></div><input style={{width : "30px", marginTop : "3%",height:"8px"}} className="cx nji" type="range" min="0" max="100" value={range19[i] === 1  ? 100 :  0} onClick={()=>setrange191(res1,i)}  />
                </div>
            </div>
          </div>
        :
        ""
        }
        {res1.photo === "" && res1.name === "facebook" ? 
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div style={{background :"#f4f4f4", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px",opacity : res1.yes ? 1 : 0.9}} className="dfdfdsf"  id={res1.photo+""+res1.name+""+i}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    {/* <MoreVertIcon style={{color : "rgb*(18, 18, 18)" , fontSize : "33px" , marginTop : "-5px"}}/> */}
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}  onClick={()=>setEditEK(res1,i)}>
                    <img src={fb1} style={{width : "45px" , height: "39px"}} />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "7px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Facebook</p>
                    </div>
                    <div  onClick={()=>setrange191(res1,i)} style={{position: "absolute",right: "-60px" ,minWidth: "29px",height: "20px",background: "transparent"}} className="ki1 kii1 kuuuiuiui"></div><input style={{width : "30px", marginTop : "3%",height:"8px"}} className="cx nji" type="range" min="0" max="100" value={range19[i] === 1  ? 100 :  0} onClick={()=>setrange191(res1,i)}  />
                </div>
            </div>
          </div>
        :
        ""
        }
        {res1.photo === "" && res1.name === "location" ? 
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div style={{background :"#f4f4f4", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px",opacity : res1.yes ? 1 : 0.9}} className="dfdfdsf"  id={res1.photo+""+res1.name+""+i}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    {/* <MoreVertIcon style={{color : "rgb*(18, 18, 18)" , fontSize : "33px" , marginTop : "-5px"}}/> */}
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}  onClick={()=>setEditEK(res1,i)}>
                    <img src={lo1} style={{width : "45px" , height: "39px"}} />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "7px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Location</p>
                    </div>
                    <div  onClick={()=>setrange191(res1,i)} style={{position: "absolute",right: "-60px" ,minWidth: "29px",height: "20px",background: "transparent"}} className="ki1 kii1 kuuuiuiui"></div><input style={{width : "30px", marginTop : "3%",height:"8px"}} className="cx nji" type="range" min="0" max="100" value={range19[i] === 1  ? 100 :  0} onClick={()=>setrange191(res1,i)}  />
                </div>
            </div>
          </div>
        :
        ""
        }
        {res1.photo === "" && res1.name === "instagram" ? 
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div style={{background :"#f4f4f4", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px",opacity : res1.yes ? 1 : 0.9}} className="dfdfdsf"  id={res1.photo+""+res1.name+""+i}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    {/* <MoreVertIcon style={{color : "rgb*(18, 18, 18)" , fontSize : "33px" , marginTop : "-5px"}}/> */}
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}  onClick={()=>setEditEK(res1,i)}>
                    <img src={in1} style={{width : "45px" , height: "39px"}} />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "7px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Instagram</p>
                    </div>
                    <div  onClick={()=>setrange191(res1,i)} style={{position: "absolute",right: "-60px" ,minWidth: "29px",height: "20px",background: "transparent"}} className="ki1 kii1 kuuuiuiui"></div><input style={{width : "30px", marginTop : "3%",height:"8px"}} className="cx nji" type="range" min="0" max="100" value={range19[i] === 1  ? 100 :  0} onClick={()=>setrange191(res1,i)}  />
                </div>
            </div>
          </div>
        :
        ""
        }
        {res1.photo === "" && res1.name === "snapchat" ? 
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div style={{background :"#f4f4f4", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px",opacity : res1.yes ? 1 : 0.9}} className="dfdfdsf"  id={res1.photo+""+res1.name+""+i}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    {/* <MoreVertIcon style={{color : "rgb*(18, 18, 18)" , fontSize : "33px" , marginTop : "-5px"}}/> */}
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}  onClick={()=>setEditEK(res1,i)}>
                    <img src={sc1} style={{width : "45px" , height: "39px"}} />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "7px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>SnapChat</p>
                    </div>
                    <div  onClick={()=>setrange191(res1,i)} style={{position: "absolute",right: "-60px" ,minWidth: "29px",height: "20px",background: "transparent"}} className="ki1 kii1 kuuuiuiui"></div><input style={{width : "30px", marginTop : "3%",height:"8px"}} className="cx nji" type="range" min="0" max="100" value={range19[i] === 1  ? 100 :  0} onClick={()=>setrange191(res1,i)}  />
                </div>
            </div>
          </div>
        :
        ""
        }
        {res1.photo === "" && res1.name === "tiktok" ? 
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div style={{background :"#f4f4f4", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px",opacity : res1.yes ? 1 : 0.9}} className="dfdfdsf"  id={res1.photo+""+res1.name+""+i}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    {/* <MoreVertIcon style={{color : "rgb*(18, 18, 18)" , fontSize : "33px" , marginTop : "-5px"}}/> */}
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}  onClick={()=>setEditEK(res1,i)}>
                    <img src={tk1} style={{ width : "45px" , height: "39px"}} />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "7px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>TikTok</p>
                    </div>
                    <div  onClick={()=>setrange191(res1,i)} style={{position: "absolute",right: "-60px" ,minWidth: "29px",height: "20px",background: "transparent"}} className="ki1 kii1 kuuuiuiui"></div><input style={{width : "30px", marginTop : "3%",height:"8px"}} className="cx nji" type="range" min="0" max="100" value={range19[i] === 1  ? 100 :  0} onClick={()=>setrange191(res1,i)}  />
                </div>
            </div>
          </div>
        :
        ""
        }
        {res1.photo === "" && res1.name === "linkedin" ? 
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div style={{background :"#f4f4f4", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px",opacity : res1.yes ? 1 : 0.9}} className="dfdfdsf"  id={res1.photo+""+res1.name+""+i}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    {/* <MoreVertIcon style={{color : "rgb*(18, 18, 18)" , fontSize : "33px" , marginTop : "-5px"}}/> */}
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}  onClick={()=>setEditEK(res1,i)}>
                    <img src={ln1} style={{width : "45px" , height: "39px"}} />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "7px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Linkedin</p>
                    </div>
                    <div  onClick={()=>setrange191(res1,i)} style={{position: "absolute",right: "-60px" ,minWidth: "29px",height: "20px",background: "transparent"}} className="ki1 kii1 kuuuiuiui"></div><input style={{width : "30px", marginTop : "3%",height:"8px"}} className="cx nji" type="range" min="0" max="100" value={range19[i] === 1  ? 100 :  0} onClick={()=>setrange191(res1,i)}  />
                </div>
            </div>
          </div>
        :
        ""
        }
        {res1.photo === "" && res1.name === "twitter" ? 
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div style={{background :"#f4f4f4", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px",opacity : res1.yes ? 1 : 0.9}} className="dfdfdsf"  id={res1.photo+""+res1.name+""+i}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    {/* <MoreVertIcon style={{color : "rgb*(18, 18, 18)" , fontSize : "33px" , marginTop : "-5px"}}/> */}
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}  onClick={()=>setEditEK(res1,i)}>
                    <img src={tw1} style={{width : "45px" , height: "39px"}} />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "7px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Twitter</p>
                    </div>
                    <div  onClick={()=>setrange191(res1,i)} style={{position: "absolute",right: "-60px" ,minWidth: "29px",height: "20px",background: "transparent"}} className="ki1 kii1 kuuuiuiui"></div><input style={{width : "30px", marginTop : "3%",height:"8px"}} className="cx nji" type="range" min="0" max="100" value={range19[i] === 1  ? 100 :  0} onClick={()=>setrange191(res1,i)}  />
                </div>
            </div>
          </div>
        :
        ""
        }
        {res1.photo === "" && res1.name === "youtube" ? 
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div style={{background :"#f4f4f4", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px",opacity : res1.yes ? 1 : 0.9}} className="dfdfdsf"  id={res1.photo+""+res1.name+""+i}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    {/* <MoreVertIcon style={{color : "rgb*(18, 18, 18)" , fontSize : "33px" , marginTop : "-5px"}}/> */}
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}  onClick={()=>setEditEK(res1,i)}>
                    <img src={you1} style={{width : "45px" , height: "39px"}} />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "7px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Youtube</p>
                    </div>
                    <div  onClick={()=>setrange191(res1,i)} style={{position: "absolute",right: "-60px" ,minWidth: "29px",height: "20px",background: "transparent"}} className="ki1 kii1 kuuuiuiui"></div><input style={{width : "30px", marginTop : "3%",height:"8px"}} className="cx nji" type="range" min="0" max="100" value={range19[i] === 1  ? 100 :  0} onClick={()=>setrange191(res1,i)}  />
                </div>
            </div>
          </div>
        :
        ""
        }
        {res1.photo === "" && res1.name === "twitch" ? 
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div style={{background :"#f4f4f4", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px",opacity : res1.yes ? 1 : 0.9}} className="dfdfdsf"  id={res1.photo+""+res1.name+""+i}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    {/* <MoreVertIcon style={{color : "rgb*(18, 18, 18)" , fontSize : "33px" , marginTop : "-5px"}}/> */}
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}  onClick={()=>setEditEK(res1,i)}>
                    <img src={tt1} style={{width : "45px" , height: "39px"}} />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "7px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Twitch</p>
                    </div>
                    <div  onClick={()=>setrange191(res1,i)} style={{position: "absolute",right: "-60px" ,minWidth: "29px",height: "20px",background: "transparent"}} className="ki1 kii1 kuuuiuiui"></div><input style={{width : "30px", marginTop : "3%",height:"8px"}} className="cx nji" type="range" min="0" max="100" value={range19[i] === 1  ? 100 :  0} onClick={()=>setrange191(res1,i)}  />
                </div>
            </div>
          </div>
        :
        ""
        }
        {res1.photo === "" && res1.name === "pinterest" ? 
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div style={{background :"#f4f4f4", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px",opacity : res1.yes ? 1 : 0.9}} className="dfdfdsf"  id={res1.photo+""+res1.name+""+i}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    {/* <MoreVertIcon style={{color : "rgb*(18, 18, 18)" , fontSize : "33px" , marginTop : "-5px"}}/> */}
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}  onClick={()=>setEditEK(res1,i)}>
                    <img src={pi1} style={{width : "45px" , height: "39px"}} />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "7px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Pinterest</p>
                    </div>
                    <div  onClick={()=>setrange191(res1,i)} style={{position: "absolute",right: "-60px" ,minWidth: "29px",height: "20px",background: "transparent"}} className="ki1 kii1 kuuuiuiui"></div><input style={{width : "30px", marginTop : "3%",height:"8px"}} className="cx nji" type="range" min="0" max="100" value={range19[i] === 1  ? 100 :  0} onClick={()=>setrange191(res1,i)}  />
                </div>
            </div>
          </div>
        :
        ""
        }
        {res1.photo === "" && res1.name === "cash app" ? 
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div style={{background :"#f4f4f4", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px",opacity : res1.yes ? 1 : 0.9}} className="dfdfdsf"  id={res1.photo+""+res1.name+""+i}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    {/* <MoreVertIcon style={{color : "rgb*(18, 18, 18)" , fontSize : "33px" , marginTop : "-5px"}}/> */}
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}  onClick={()=>setEditEK(res1,i)}>
                    <img src={ca1} style={{width : "45px" , height: "39px"}} />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "7px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Cash App</p>
                    </div>
                    <div  onClick={()=>setrange191(res1,i)} style={{position: "absolute",right: "-60px" ,minWidth: "29px",height: "20px",background: "transparent"}} className="ki1 kii1 kuuuiuiui"></div><input style={{width : "30px", marginTop : "3%",height:"8px"}} className="cx nji" type="range" min="0" max="100" value={range19[i] === 1  ? 100 :  0} onClick={()=>setrange191(res1,i)}  />
                </div>
            </div>
          </div>
        :
        ""
        }
        {res1.photo === "" && res1.name === "paypal" ? 
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div style={{background :"#f4f4f4", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px",opacity : res1.yes ? 1 : 0.9}} className="dfdfdsf"  id={res1.photo+""+res1.name+""+i}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    {/* <MoreVertIcon style={{color : "rgb*(18, 18, 18)" , fontSize : "33px" , marginTop : "-5px"}}/> */}
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}  onClick={()=>setEditEK(res1,i)}>
                    <img src={pp1} style={{width : "45px" , height: "39px"}} />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "7px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Paypal</p>
                    </div>
                    <div  onClick={()=>setrange191(res1,i)} style={{position: "absolute",right: "-60px" ,minWidth: "29px",height: "20px",background: "transparent"}} className="ki1 kii1 kuuuiuiui"></div><input style={{width : "30px", marginTop : "3%",height:"8px"}} className="cx nji" type="range" min="0" max="100" value={range19[i] === 1  ? 100 :  0} onClick={()=>setrange191(res1,i)}  />
                </div>
            </div>
          </div>
        :
        ""
        }
        {res1.photo === "" && res1.name === "Venmo" ? 
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div style={{background :"#f4f4f4", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px",opacity : res1.yes ? 1 : 0.9}} className="dfdfdsf"  id={res1.photo+""+res1.name+""+i}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    {/* <MoreVertIcon style={{color : "rgb*(18, 18, 18)" , fontSize : "33px" , marginTop : "-5px"}}/> */}
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}  onClick={()=>setEditEK(res1,i)}>
                    <img src={ve1} style={{width : "45px" , height: "39px"}} />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "7px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Venmo</p>
                    </div>
                    <div  onClick={()=>setrange191(res1,i)} style={{position: "absolute",right: "-60px" ,minWidth: "29px",height: "20px",background: "transparent"}} className="ki1 kii1 kuuuiuiui"></div><input style={{width : "30px", marginTop : "3%",height:"8px"}} className="cx nji" type="range" min="0" max="100" value={range19[i] === 1  ? 100 :  0} onClick={()=>setrange191(res1,i)}  />
                </div>
            </div>
          </div>
        :
        ""
        }
        {res1.photo === "" && res1.name === "text" ? 
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div style={{background :"#f4f4f4", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px",opacity : res1.yes ? 1 : 0.9}} className="dfdfdsf"  id={res1.photo+""+res1.name+""+i}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    {/* <MoreVertIcon style={{color : "rgb*(18, 18, 18)" , fontSize : "33px" , marginTop : "-5px"}}/> */}
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}  onClick={()=>setEditEK(res1,i)}>
                    <img src={mess1} style={{width : "45px" , height: "39px"}} />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "7px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Text</p>
                    </div>
                    <div  onClick={()=>setrange191(res1,i)} style={{position: "absolute",right: "-60px" ,minWidth: "29px",height: "20px",background: "transparent"}} className="ki1 kii1 kuuuiuiui"></div><input style={{width : "30px", marginTop : "3%",height:"8px"}} className="cx nji" type="range" min="0" max="100" value={range19[i] === 1  ? 100 :  0} onClick={()=>setrange191(res1,i)}  />
                </div>
            </div>
          </div>
        :
        ""
        }
        {res1.photo === "" && res1.name === "website" ? 
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div style={{background :"#f4f4f4", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px",opacity : res1.yes ? 1 : 0.9}} className="dfdfdsf"  id={res1.photo+""+res1.name+""+i}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    {/* <MoreVertIcon style={{color : "rgb*(18, 18, 18)" , fontSize : "33px" , marginTop : "-5px"}}/> */}
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}  onClick={()=>setEditEK(res1,i)}>
                    <img src={we1} style={{width : "45px" , height: "39px"}} />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "7px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Website</p>
                    </div>
                    <div  onClick={()=>setrange191(res1,i)} style={{position: "absolute",right: "-60px" ,minWidth: "29px",height: "20px",background: "transparent"}} className="ki1 kii1 kuuuiuiui"></div><input style={{width : "30px", marginTop : "3%",height:"8px"}} className="cx nji" type="range" min="0" max="100" value={range19[i] === 1  ? 100 :  0} onClick={()=>setrange191(res1,i)}  />
                </div>
            </div>
          </div>
        :
        ""
        }
        {res1.photo === "" && res1.name === "whatsapps" ? 
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div style={{background :"#f4f4f4", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px",opacity : res1.yes ? 1 : 0.9}} className="dfdfdsf"  id={res1.photo+""+res1.name+""+i}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    {/* <MoreVertIcon style={{color : "rgb*(18, 18, 18)" , fontSize : "33px" , marginTop : "-5px"}}/> */}
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}  onClick={()=>setEditEK(res1,i)}>
                    <img src={wa1} style={{width : "45px" , height: "39px"}} />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "7px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>WhatsApp</p>
                    </div>
                    <div  onClick={()=>setrange191(res1,i)} style={{position: "absolute",right: "-60px" ,minWidth: "29px",height: "20px",background: "transparent"}} className="ki1 kii1 kuuuiuiui"></div><input style={{width : "30px", marginTop : "3%",height:"8px"}} className="cx nji" type="range" min="0" max="100" value={range19[i] === 1  ? 100 :  0} onClick={()=>setrange191(res1,i)}  />
                </div>
            </div>
          </div>
        :
        ""
        }
        {res1.photo === "" && res1.name === "email" ? 
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div style={{background :"#f4f4f4", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px",opacity : res1.yes ? 1 : 0.9}} className="dfdfdsf"  id={res1.photo+""+res1.name+""+i}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    {/* <MoreVertIcon style={{color : "rgb*(18, 18, 18)" , fontSize : "33px" , marginTop : "-5px"}}/> */}
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}  onClick={()=>setEditEK(res1,i)}>
                    <img src={mail1} style={{width : "45px" , height: "39px"}} />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "7px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Email</p>
                    </div>
                    <div  onClick={()=>setrange191(res1,i)} style={{position: "absolute",right: "-60px" ,minWidth: "29px",height: "20px",background: "transparent"}} className="ki1 kii1 kuuuiuiui"></div><input style={{width : "30px", marginTop : "3%",height:"8px"}} className="cx nji" type="range" min="0" max="100" value={range19[i] === 1  ? 100 :  0} onClick={()=>setrange191(res1,i)}  />
                </div>
            </div>
          </div>
        :
        ""
        }
        {res1.photo === "" && res1.name === "phone" ? 
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div style={{background :"#f4f4f4", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px",opacity : res1.yes ? 1 : 0.9}} className="dfdfdsf"  id={res1.photo+""+res1.name+""+i}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    {/* <MoreVertIcon style={{color : "rgb*(18, 18, 18)" , fontSize : "33px" , marginTop : "-5px"}}/> */}
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}  onClick={()=>setEditEK(res1,i)}>
                    <img src={call1} style={{width : "45px" , height: "39px"}} />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "7px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Phone</p>
                    </div>
                    <div  onClick={()=>setrange191(res1,i)} style={{position: "absolute",right: "-60px" ,minWidth: "29px",height: "20px",background: "transparent"}} className="ki1 kii1 kuuuiuiui"></div><input style={{width : "30px", marginTop : "3%",height:"8px"}} className="cx nji" type="range" min="0" max="100" value={range19[i] === 1  ? 100 :  0} onClick={()=>setrange191(res1,i)}  />
                </div>
            </div>
          </div>
        :
        ""
        }
        {res1.photo === "" && res1.name === "Address" ? 
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div style={{background :"#f4f4f4", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px",opacity : res1.yes ? 1 : 0.9}} className="dfdfdsf"  id={res1.photo+""+res1.name+""+i}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    {/* <MoreVertIcon style={{color : "rgb*(18, 18, 18)" , fontSize : "33px" , marginTop : "-5px"}}/> */}
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}  onClick={()=>setEditEK(res1,i)}>
                    <img src={lo1} style={{width : "45px" , height: "39px"}} />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "7px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Address</p>
                    </div>
                    <div  onClick={()=>setrange191(res1,i)} style={{position: "absolute",right: "-60px" ,minWidth: "29px",height: "20px",background: "transparent"}} className="ki1 kii1 kuuuiuiui"></div><input style={{width : "30px", marginTop : "3%",height:"8px"}} className="cx nji" type="range" min="0" max="100" value={range19[i] === 1  ? 100 :  0} onClick={()=>setrange191(res1,i)}  />
                </div>
            </div>
          </div>
        :
        ""
        }
        {res1.photo && res1.photo.length !== 0 && res1.name !== "Contact" ? 
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div style={{background :"#f4f4f4", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px",opacity : res1.yes ? 1 : 0.9}} className="dfdfdsf"  id={res1.photo+""+res1.name+""+i}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    {/* <MoreVertIcon style={{color : "rgb*(18, 18, 18)" , fontSize : "33px" , marginTop : "-5px"}}/> */}
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}  onClick={()=>setEditEK(res1,i)}>
                    <img src={res1.photo} style={{width : "45px" , height: "39px"}} />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "7px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>{res1.Name}</p>
                    </div>
                    <div  onClick={()=>setrange191(res1,i)} style={{position: "absolute",right: "-60px" ,minWidth: "29px",height: "20px",background: "transparent"}} className="ki1 kii1 kuuuiuiui"></div><input style={{width : "30px", marginTop : "3%",height:"8px"}} className="cx nji" type="range" min="0" max="100" value={range19[i] === 1  ? 100 :  0} onClick={()=>setrange191(res1,i)}  />
                </div>
            </div>
          </div>
        :
        ""
      }
                        </li>
                      )}
                    </Draggable>
                  );
                })}
                </div>
              </div>
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
































       
        {/* Back to top */}
        <div className="btn-back-to-top" id="myBtn">
          <span className="symbol-btn-back-to-top">
            <i className="zmdi zmdi-chevron-up" />
          </span>
        </div>
        {/* Modal1 */}
        <div className="wrap-modal1 js-modal1 p-t-60 p-b-20">
          <div className="overlay-modal1 js-hide-modal1" />
          <div className="container">
            <div className="bg0 p-t-60 p-b-30 p-lr-15-lg how-pos3-parent">
              <button className="how-pos3 hov3 trans-04 js-hide-modal1">
                <img src="images/icons/icon-close.png" alt="CLOSE" />
              </button>
              <div className="row">
                <div className="col-md-6 col-lg-7 p-b-30">
                  <div className="p-l-25 p-r-30 p-lr-0-lg">
                    <div className="wrap-slick3 flex-sb flex-w">
                      <div className="wrap-slick3-dots" />
                      <div className="wrap-slick3-arrows flex-sb-m flex-w" />
                      <div className="slick3 gallery-lb">
                        <div className="item-slick3" data-thumb="images/product-detail-01.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-01.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-01.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-02.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-02.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-02.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-03.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-03.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-03.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5 p-b-30">
                  <div className="p-r-50 p-t-5 p-lr-0-lg">
                    <h4 className="mtext-105 cl2 js-name-detail p-b-14">
                      Lightweight Jacket
                    </h4>
                    <span className="mtext-106 cl2">
                      $58.79
                    </span>
                    <p className="stext-102 cl3 p-t-23">
                      Nulla eget sem vitae eros pharetra viverra. Nam vitae luctus ligula. Mauris consequat ornare feugiat.
                    </p>
                    {/*  */}
                    <div className="p-t-33">
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Size
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Size S</option>
                              <option>Size M</option>
                              <option>Size L</option>
                              <option>Size XL</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Color
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Red</option>
                              <option>Blue</option>
                              <option>White</option>
                              <option>Grey</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-204 flex-w flex-m respon6-next">
                          <div className="wrap-num-product flex-w m-r-20 m-tb-10">
                            <div className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-minus" />
                            </div>
                            <input className="mtext-104 cl3 txt-center num-product" type="number" name="num-product" defaultValue={1} />
                            <div className="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-plus" />
                            </div>
                          </div>
                          <button className="flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04 js-addcart-detail">
                            Add to cart
                          </button>
                        </div>
                      </div>	
                    </div>
                    {/*  */}
                    <div className="flex-w flex-m p-l-100 p-t-40 respon7">
                      <div className="flex-m bor9 p-r-10 m-r-11">
                        <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 js-addwish-detail tooltip100" data-tooltip="Add to Wishlist">
                          <i className="zmdi zmdi-favorite" />
                        </a>
                      </div>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Facebook">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Twitter">
                        <i className="fa fa-twitter" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Google Plus">
                        <i className="fa fa-google-plus" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
 






        )
    
}

export default Home;