import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"
import swal from 'sweetalert';
// import $ from 'jquery';
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import ClearIcon from '@material-ui/icons/Clear';
import c2 from "./../assets/images/c2.png"

import "react-multi-carousel/lib/styles.css";
import "react-multi-carousel/lib/styles.css";
import "./../assets/css/main.css"
import "./../assets/css/util.css"
import "./../assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css"

import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import AssessmentIcon from '@material-ui/icons/Assessment';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SortIcon from '@material-ui/icons/Sort';

import k1 from "./../assets/images/k1.png"
// import k2 from "./../assets/images/k2.png"
// import k3 from "./../assets/images/k3.png"
import k4 from "./../assets/images/k4.png"
import k5 from "./../assets/images/k5.png"

import cd1 from "./../assets/images/svg/cotact info/ccard.svg"


// import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"

import "./../assets/vendor/css-hamburgers/hamburgers.min.css"

import "./../assets/fonts/iconic/css/material-design-iconic-font.min.css"
import "./../assets/vendor/bootstrap/css/bootstrap.min.css"
import "./../assets/vendor/select2/select2.min.css"
import "./../assets/vendor/slick/slick.css"
import "./../assets/vendor/perfect-scrollbar/perfect-scrollbar.css"

// import Logo from "./../assets/images/icons/logo-01.png"

// import b1 from "./../assets/images/banner-01.jpg"
// import b2 from "./../assets/images/banner-02.jpg"
// import b3 from "./../assets/images/banner-03.jpg"


import a1 from "./../assets/images/a1.png"
import a2 from "./../assets/images/a2.png"
import a3 from "./../assets/images/a3.png"
import a4 from "./../assets/images/a4.png"
import a5 from "./../assets/images/a5.png"
import a6 from "./../assets/images/a6.png"
import a7 from "./../assets/images/a7.png"
import a8 from "./../assets/images/a8.png"

import a9 from "./../assets/images/a9.png"

import a11 from "./../assets/images/a11.png"
import a21 from "./../assets/images/a21.png"
import a22 from "./../assets/images/a22.png"
import a23 from "./../assets/images/a23.png"
import a24 from "./../assets/images/a24.png"
// import a25 from "./../assets/images/a25.png"
// import a27 from "./../assets/images/a27.png"


import z1 from "./../assets/images/z1.png"
import z2 from "./../assets/images/z2.png"
import z3 from "./../assets/images/z3.png"
// import z4 from "./../assets/images/z4.png"


import c1 from "./../assets/images/c1.png"
// import c2 from "./../assets/images/c2.png"
// import c3 from "./../assets/images/c3.png"
// import c4 from "./../assets/images/c4.png"






// import p2 from "./../assets/images/product-02.jpg"
// import p3 from "./../assets/images/product-03.jpg"
// import p4 from "./../assets/images/product-04.jpg"
// import p5 from "./../assets/images/product-05.jpg"
// import p6 from "./../assets/images/product-06.jpg"



// import h1 from "./../assets/images/icons/icon-heart-01.png"
// import h2 from "./../assets/images/icons/icon-heart-02.png"



// import s1 from "./../assets/images/slide-01.jpg"
// import s2 from "./../assets/images/slide-02.jpg"
// import s3 from "./../assets/images/slide-03.jpg"
// import a111 from "./../assets/images/1.jpeg"
import nu1 from "./../assets/images/nu1.jpeg"




// import vi1 from "./../assets/images/svg/MORE/video.svg"



const Home = ( props ) => {
    const [myRef,setRef] = useState( React.createRef())
    // const [scrollTop1,setscrollTop] = useState(0)
    const [email , setemail] = useState("")
    const [pass , setpass] = useState("")
    const [dispaly ,  setdispaly ] = useState(true)
    const [as ,  setas ] = useState(true)
    const [User1 ,  setUser1 ] = useState({})
    const [Arr1 ,  setArr1 ] = useState([])
    // const [Num ,  setNum ] = useState(21)
    const [range1,setRange1] = useState([0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0])
    const [Ar,setAr] = useState([])
    const [NewImg , setNewImg] = useState("")

    const [Name , setname] = useState("")
    const [Email , setEmail] = useState("")
    const [Phone , setPhone] = useState("")
    const [Address , setAddress] = useState("")
    const [Gender , setGender] = useState("")
    const [City , setCity] = useState("")
    const [Country , setCountry] = useState("Pakistan")
    const [Role , setRole] = useState("")
    const [Image , setImage] = useState("")
    // const [url , seturl] = useState("")
    const [name1 , setname1] = useState("")
    const [photo1 , setphoto1] = useState("")

    const [dispaly1 ,  setdispaly1 ] = useState(true)
    const [dispaly2 ,  setdispaly2 ] = useState(false)

    const [UserPhoneCode ,  setUserPhoneCode ] = useState("92")
    const [PhoneCode ,  setPhoneCode ] = useState([
      {
        code: 'PK', map: '🇵🇰', phoneCode: 92, value: 'Pakistan',
      },
      {
        code: 'AF', map: '🇦🇫', phoneCode: 93, value: 'Afghanistan',
      },
      {
        code: 'AL', map: '🇦🇱', phoneCode: 355, value: 'Albania',
      },
      {
        code: 'DZ', map: '🇩🇿', phoneCode: 213, value: 'Algeria',
      },
      {
        code: 'AS', map: '🇦🇸', phoneCode: 1684, value: 'American Samoa',
      },
      {
        code: 'AD', map: '🇦🇩', phoneCode: 376, value: 'Andorra',
      },
      {
        code: 'AO', map: '🇦🇴', phoneCode: 244, value: 'Angola',
      },
      {
        code: 'AI', map: '🇦🇮', phoneCode: 1264, value: 'Anguilla',
      },
      {
        code: 'AQ', map: '🇦🇶', phoneCode: 0, value: 'Antarctica',
      },
      {
        code: 'AR', map: '🇦🇷', phoneCode: 54, value: 'Argentina',
      },
      {
        code: 'AM', map: '🇦🇲', phoneCode: 374, value: 'Armenia',
      },
      {
        code: 'AW', map: '🇦🇼', phoneCode: 297, value: 'Aruba',
      },
      {
        code: 'AU', map: '🇦🇺', phoneCode: 61, value: 'Australia',
      },
      {
        code: 'AT', map: '🇦🇹', phoneCode: 43, value: 'Austria',
      },
      {
        code: 'AZ', map: '🇦🇿', phoneCode: 994, value: 'Azerbaijan',
      },
      {
        code: 'BH', map: '🇧🇭', phoneCode: 973, value: 'Bahrain',
      },
      {
        code: 'BD', map: '🇧🇩', phoneCode: 880, value: 'Bangladesh',
      },
      {
        code: 'BB', map: '🇧🇧', phoneCode: 1246, value: 'Barbados',
      },
      {
        code: 'BY', map: '🇧🇾', phoneCode: 375, value: 'Belarus',
      },
      {
        code: 'BE', map: '🇧🇪', phoneCode: 32, value: 'Belgium',
      },
      {
        code: 'BZ', map: '🇧🇿', phoneCode: 501, value: 'Belize',
      },
      {
        code: 'BJ', map: '🇧🇯', phoneCode: 229, value: 'Benin',
      },
      {
        code: 'BM', map: '🇧🇲', phoneCode: 1441, value: 'Bermuda',
      },
      {
        code: 'BT', map: '🇧🇹', phoneCode: 975, value: 'Bhutan',
      },
      {
        code: 'BO', map: '🇧🇴', phoneCode: 591, value: 'Bolivia',
      },
      {
        code: 'BW', map: '🇧🇼', phoneCode: 267, value: 'Botswana',
      },
      {
        code: 'BV', map: '🇧🇻', phoneCode: 0, value: 'Bouvet Island',
      },
      {
        code: 'BR', map: '🇧🇷', phoneCode: 55, value: 'Brazil',
      },
      {
        code: 'IO', map: '🇮🇴', phoneCode: 246, value: 'British Indian Ocean Territory',
      },
      {
        code: 'BN', map: '🇧🇳', phoneCode: 673, value: 'Brunei',
      },
      {
        code: 'BG', map: '🇧🇬', phoneCode: 359, value: 'Bulgaria',
      },
      {
        code: 'BF', map: '🇧🇫', phoneCode: 226, value: 'Burkina Faso',
      },
      {
        code: 'BI', map: '🇧🇮', phoneCode: 257, value: 'Burundi',
      },
      {
        code: 'KH', map: '🇰🇭', phoneCode: 855, value: 'Cambodia',
      },
      {
        code: 'CM', map: '🇨🇲', phoneCode: 237, value: 'Cameroon',
      },
      {
        code: 'CA', map: '🇨🇦', phoneCode: 1, value: 'Canada',
      },
      {
        code: 'CV', map: '🇨🇻', phoneCode: 238, value: 'Cape Verde',
      },
      {
        code: 'KY', map: '🇰🇾', phoneCode: 1345, value: 'Cayman Islands',
      },
      {
        code: 'CF', map: '🇨🇫', phoneCode: 236, value: 'Central African Republic',
      },
      {
        code: 'TD', map: '🇹🇩', phoneCode: 235, value: 'Chad',
      },
      {
        code: 'CL', map: '🇨🇱', phoneCode: 56, value: 'Chile',
      },
      {
        code: 'CN', map: '🇨🇳', phoneCode: 86, value: 'China',
      },
      {
        code: 'CX', map: '🇨🇽', phoneCode: 61, value: 'Christmas Island',
      },
      {
        code: 'CC', map: '🇨🇨', phoneCode: 672, value: 'Cocos (Keeling) Islands',
      },
      {
        code: 'CO', map: '🇨🇴', phoneCode: 57, value: 'Colombia',
      },
      {
        code: 'KM', map: '🇰🇲', phoneCode: 269, value: 'Comoros',
      },
      {
        code: 'CK', map: '🇨🇰', phoneCode: 682, value: 'Cook Islands',
      },
      {
        code: 'CR', map: '🇨🇷', phoneCode: 506, value: 'Costa Rica',
      },
      {
        code: 'CU', map: '🇨🇺', phoneCode: 53, value: 'Cuba',
      },
      {
        code: 'CY', map: '🇨🇾', phoneCode: 357, value: 'Cyprus',
      },
      {
        code: 'DK', map: '🇩🇰', phoneCode: 45, value: 'Denmark',
      },
      {
        code: 'DJ', map: '🇩🇯', phoneCode: 253, value: 'Djibouti',
      },
      {
        code: 'DM', map: '🇩🇲', phoneCode: 1767, value: 'Dominica',
      },
      {
        code: 'DO', map: '🇩🇴', phoneCode: 1809, value: 'Dominican Republic',
      },
      {
        code: 'EC', map: '🇪🇨', phoneCode: 593, value: 'Ecuador',
      },
      {
        code: 'EG', map: '🇪🇬', phoneCode: 20, value: 'Egypt',
      },
      {
        code: 'SV', map: '🇸🇻', phoneCode: 503, value: 'El Salvador',
      },
      {
        code: 'GQ', map: '🇬🇶', phoneCode: 240, value: 'Equatorial Guinea',
      },
      {
        code: 'ER', map: '🇪🇷', phoneCode: 291, value: 'Eritrea',
      },
      {
        code: 'EE', map: '🇪🇪', phoneCode: 372, value: 'Estonia',
      },
      {
        code: 'ET', map: '🇪🇹', phoneCode: 251, value: 'Ethiopia',
      },
      {
        code: 'FK', map: '🇫🇰', phoneCode: 500, value: 'Falkland Islands',
      },
      {
        code: 'FO', map: '🇫🇴', phoneCode: 298, value: 'Faroe Islands',
      },
      {
        code: 'FI', map: '🇫🇮', phoneCode: 358, value: 'Finland',
      },
      {
        code: 'FR', map: '🇫🇷', phoneCode: 33, value: 'France',
      },
      {
        code: 'GF', map: '🇬🇫', phoneCode: 594, value: 'French Guiana',
      },
      {
        code: 'PF', map: '🇵🇫', phoneCode: 689, value: 'French Polynesia',
      },
      {
        code: 'TF', map: '🇹🇫', phoneCode: 0, value: 'French Southern Territories',
      },
      {
        code: 'GA', map: '🇬🇦', phoneCode: 241, value: 'Gabon',
      },
      {
        code: 'GE', map: '🇬🇪', phoneCode: 995, value: 'Georgia',
      },
      {
        code: 'DE', map: '🇩🇪', phoneCode: 49, value: 'Germany',
      },
      {
        code: 'GH', map: '🇬🇭', phoneCode: 233, value: 'Ghana',
      },
      {
        code: 'GI', map: '🇬🇮', phoneCode: 350, value: 'Gibraltar',
      },
      {
        code: 'GR', map: '🇬🇷', phoneCode: 30, value: 'Greece',
      },
      {
        code: 'GL', map: '🇬🇱', phoneCode: 299, value: 'Greenland',
      },
      {
        code: 'GD', map: '🇬🇩', phoneCode: 1473, value: 'Grenada',
      },
      {
        code: 'GP', map: '🇬🇵', phoneCode: 590, value: 'Guadeloupe',
      },
      {
        code: 'GU', map: '🇬🇺', phoneCode: 1671, value: 'Guam',
      },
      {
        code: 'GT', map: '🇬🇹', phoneCode: 502, value: 'Guatemala',
      },
      {
        code: 'GN', map: '🇬🇳', phoneCode: 224, value: 'Guinea',
      },
      {
        code: 'GW', map: '🇬🇼', phoneCode: 245, value: 'Guinea-Bissau',
      },
      {
        code: 'GY', map: '🇬🇾', phoneCode: 592, value: 'Guyana',
      },
      {
        code: 'HT', map: '🇭🇹', phoneCode: 509, value: 'Haiti',
      },
      {
        code: 'HN', map: '🇭🇳', phoneCode: 504, value: 'Honduras',
      },
      {
        code: 'HU', map: '🇭🇺', phoneCode: 36, value: 'Hungary',
      },
      {
        code: 'IS', map: '🇮🇸', phoneCode: 354, value: 'Iceland',
      },
      {
        code: 'IN', map: '🇮🇳', phoneCode: 91, value: 'India',
      },
      {
        code: 'ID', map: '🇮🇩', phoneCode: 62, value: 'Indonesia',
      },
      {
        code: 'IR', map: '🇮🇷', phoneCode: 98, value: 'Iran',
      },
      {
        code: 'IQ', map: '🇮🇶', phoneCode: 964, value: 'Iraq',
      },
      {
        code: 'IE', map: '🇮🇪', phoneCode: 353, value: 'Ireland',
      },
      {
        code: 'IL', map: '🇮🇱', phoneCode: 972, value: 'Israel',
      },
      {
        code: 'IT', map: '🇮🇹', phoneCode: 39, value: 'Italy',
      },
      {
        code: 'JM', map: '🇯🇲', phoneCode: 1876, value: 'Jamaica',
      },
      {
        code: 'JP', map: '🇯🇵', phoneCode: 81, value: 'Japan',
      },
      {
        code: 'JO', map: '🇯🇴', phoneCode: 962, value: 'Jordan',
      },
      {
        code: 'KZ', map: '🇰🇿', phoneCode: 7, value: 'Kazakhstan',
      },
      {
        code: 'KE', map: '🇰🇪', phoneCode: 254, value: 'Kenya',
      },
      {
        code: 'KI', map: '🇰🇮', phoneCode: 686, value: 'Kiribati',
      },
      {
        code: 'KW', map: '🇰🇼', phoneCode: 965, value: 'Kuwait',
      },
      {
        code: 'KG', map: '🇰🇬', phoneCode: 996, value: 'Kyrgyzstan',
      },
      {
        code: 'LA', map: '🇱🇦', phoneCode: 856, value: 'Laos',
      },
      {
        code: 'LV', map: '🇱🇻', phoneCode: 371, value: 'Latvia',
      },
      {
        code: 'LB', map: '🇱🇧', phoneCode: 961, value: 'Lebanon',
      },
      {
        code: 'LS', map: '🇱🇸', phoneCode: 266, value: 'Lesotho',
      },
      {
        code: 'LR', map: '🇱🇷', phoneCode: 231, value: 'Liberia',
      },
      {
        code: 'LY', map: '🇱🇾', phoneCode: 218, value: 'Libya',
      },
      {
        code: 'LI', map: '🇱🇮', phoneCode: 423, value: 'Liechtenstein',
      },
      {
        code: 'LT', map: '🇱🇹', phoneCode: 370, value: 'Lithuania',
      },
      {
        code: 'LU', map: '🇱🇺', phoneCode: 352, value: 'Luxembourg',
      },
      {
        code: 'MK', map: '🇲🇰', phoneCode: 389, value: 'Macedonia',
      },
      {
        code: 'MG', map: '🇲🇬', phoneCode: 261, value: 'Madagascar',
      },
      {
        code: 'MW', map: '🇲🇼', phoneCode: 265, value: 'Malawi',
      },
      {
        code: 'MY', map: '🇲🇾', phoneCode: 60, value: 'Malaysia',
      },
      {
        code: 'MV', map: '🇲🇻', phoneCode: 960, value: 'Maldives',
      },
      {
        code: 'ML', map: '🇲🇱', phoneCode: 223, value: 'Mali',
      },
      {
        code: 'MT', map: '🇲🇹', phoneCode: 356, value: 'Malta',
      },
      {
        code: 'MH', map: '🇲🇭', phoneCode: 692, value: 'Marshall Islands',
      },
      {
        code: 'MQ', map: '🇲🇶', phoneCode: 596, value: 'Martinique',
      },
      {
        code: 'MR', map: '🇲🇷', phoneCode: 222, value: 'Mauritania',
      },
      {
        code: 'MU', map: '🇲🇺', phoneCode: 230, value: 'Mauritius',
      },
      {
        code: 'YT', map: '🇾🇹', phoneCode: 269, value: 'Mayotte',
      },
      {
        code: 'MX', map: '🇲🇽', phoneCode: 52, value: 'Mexico',
      },
      {
        code: 'FM', map: '🇫🇲', phoneCode: 691, value: 'Micronesia',
      },
      {
        code: 'MD', map: '🇲🇩', phoneCode: 373, value: 'Moldova',
      },
      {
        code: 'MC', map: '🇲🇨', phoneCode: 377, value: 'Monaco',
      },
      {
        code: 'MN', map: '🇲🇳', phoneCode: 976, value: 'Mongolia',
      },
      {
        code: 'MS', map: '🇲🇸', phoneCode: 1664, value: 'Montserrat',
      },
      {
        code: 'MA', map: '🇲🇦', phoneCode: 212, value: 'Morocco',
      },
      {
        code: 'MZ', map: '🇲🇿', phoneCode: 258, value: 'Mozambique',
      },
      {
        code: 'NA', map: '🇳🇦', phoneCode: 264, value: 'Namibia',
      },
      {
        code: 'NR', map: '🇳🇷', phoneCode: 674, value: 'Nauru',
      },
      {
        code: 'NP', map: '🇳🇵', phoneCode: 977, value: 'Nepal',
      },
      {
        code: 'NC', map: '🇳🇨', phoneCode: 687, value: 'New Caledonia',
      },
      {
        code: 'NZ', map: '🇳🇿', phoneCode: 64, value: 'New Zealand',
      },
      {
        code: 'NI', map: '🇳🇮', phoneCode: 505, value: 'Nicaragua',
      },
      {
        code: 'NE', map: '🇳🇪', phoneCode: 227, value: 'Niger',
      },
      {
        code: 'NG', map: '🇳🇬', phoneCode: 234, value: 'Nigeria',
      },
      {
        code: 'NU', map: '🇳🇺', phoneCode: 683, value: 'Niue',
      },
      {
        code: 'NF', map: '🇳🇫', phoneCode: 672, value: 'Norfolk Island',
      },
      {
        code: 'MP', map: '🇲🇵', phoneCode: 1670, value: 'Northern Mariana Islands',
      },
      {
        code: 'NO', map: '🇳🇴', phoneCode: 47, value: 'Norway',
      },
      {
        code: 'OM', map: '🇴🇲', phoneCode: 968, value: 'Oman',
      },
      
      {
        code: 'PW', map: '🇵🇼', phoneCode: 680, value: 'Palau',
      },
      {
        code: 'PA', map: '🇵🇦', phoneCode: 507, value: 'Panama',
      },
      {
        code: 'PY', map: '🇵🇾', phoneCode: 595, value: 'Paraguay',
      },
      {
        code: 'PE', map: '🇵🇪', phoneCode: 51, value: 'Peru',
      },
      {
        code: 'PH', map: '🇵🇭', phoneCode: 63, value: 'Philippines',
      },
      {
        code: 'PL', map: '🇵🇱', phoneCode: 48, value: 'Poland',
      },
      {
        code: 'PT', map: '🇵🇹', phoneCode: 351, value: 'Portugal',
      },
      {
        code: 'PR', map: '🇵🇷', phoneCode: 1787, value: 'Puerto Rico',
      },
      {
        code: 'QA', map: '🇶🇦', phoneCode: 974, value: 'Qatar',
      },
      {
        code: 'RO', map: '🇷🇴', phoneCode: 40, value: 'Romania',
      },
      {
        code: 'RU', map: '🇷🇺', phoneCode: 70, value: 'Russia',
      },
      {
        code: 'RW', map: '🇷🇼', phoneCode: 250, value: 'Rwanda',
      },
      {
        code: 'WS', map: '🇼🇸', phoneCode: 684, value: 'Samoa',
      },
      {
        code: 'SM', map: '🇸🇲', phoneCode: 378, value: 'San Marino',
      },
      {
        code: 'SA', map: '🇸🇦', phoneCode: 966, value: 'Saudi Arabia',
      },
      {
        code: 'SN', map: '🇸🇳', phoneCode: 221, value: 'Senegal',
      },
      {
        code: 'RS', map: '🇷🇸', phoneCode: 381, value: 'Serbia',
      },
      {
        code: 'SC', map: '🇸🇨', phoneCode: 248, value: 'Seychelles',
      },
      {
        code: 'SL', map: '🇸🇱', phoneCode: 232, value: 'Sierra Leone',
      },
      {
        code: 'SG', map: '🇸🇬', phoneCode: 65, value: 'Singapore',
      },
      {
        code: 'SK', map: '🇸🇰', phoneCode: 421, value: 'Slovakia',
      },
      {
        code: 'SI', map: '🇸🇮', phoneCode: 386, value: 'Slovenia',
      },
      {
        code: 'SB', map: '🇸🇧', phoneCode: 677, value: 'Solomon Islands',
      },
      {
        code: 'SO', map: '🇸🇴', phoneCode: 252, value: 'Somalia',
      },
      {
        code: 'ZA', map: '🇿🇦', phoneCode: 27, value: 'South Africa',
      },
      {
        code: 'SS', map: '🇸🇸', phoneCode: 211, value: 'South Sudan',
      },
      {
        code: 'ES', map: '🇪🇸', phoneCode: 34, value: 'Spain',
      },
      {
        code: 'LK', map: '🇱🇰', phoneCode: 94, value: 'Sri Lanka',
      },
      {
        code: 'SD', map: '🇸🇩', phoneCode: 249, value: 'Sudan',
      },
      {
        code: 'SR', map: '🇸🇷', phoneCode: 597, value: 'Suriname',
      },
      {
        code: 'SZ', map: '🇸🇿', phoneCode: 268, value: 'Swaziland',
      },
      {
        code: 'SE', map: '🇸🇪', phoneCode: 46, value: 'Sweden',
      },
      {
        code: 'CH', map: '🇨🇭', phoneCode: 41, value: 'Switzerland',
      },
      {
        code: 'SY', map: '🇸🇾', phoneCode: 963, value: 'Syria',
      },
      {
        code: 'TW', map: '🇹🇼', phoneCode: 886, value: 'Taiwan',
      },
      {
        code: 'TJ', map: '🇹🇯', phoneCode: 992, value: 'Tajikistan',
      },
      {
        code: 'TZ', map: '🇹🇿', phoneCode: 255, value: 'Tanzania',
      },
      {
        code: 'TH', map: '🇹🇭', phoneCode: 66, value: 'Thailand',
      },
      {
        code: 'TG', map: '🇹🇬', phoneCode: 228, value: 'Togo',
      },
      {
        code: 'TK', map: '🇹🇰', phoneCode: 690, value: 'Tokelau',
      },
      {
        code: 'TO', map: '🇹🇴', phoneCode: 676, value: 'Tonga',
      },
      {
        code: 'TN', map: '🇹🇳', phoneCode: 216, value: 'Tunisia',
      },
      {
        code: 'TR', map: '🇹🇷', phoneCode: 90, value: 'Turkey',
      },
      {
        code: 'TM', map: '🇹🇲', phoneCode: 7370, value: 'Turkmenistan',
      },
      {
        code: 'TV', map: '🇹🇻', phoneCode: 688, value: 'Tuvalu',
      },
      {
        code: 'UG', map: '🇺🇬', phoneCode: 256, value: 'Uganda',
      },
      {
        code: 'UA', map: '🇺🇦', phoneCode: 380, value: 'Ukraine',
      },
      {
        code: 'AE', map: '🇦🇪', phoneCode: 971, value: 'United Arab Emirates',
      },
      {
        code: 'GB', map: '🇬🇧', phoneCode: 44, value: 'United Kingdom',
      },
      {
        code: 'US', map: '🇺🇸', phoneCode: 1, value: 'United States',
      },
      {
        code: 'UY', map: '🇺🇾', phoneCode: 598, value: 'Uruguay',
      },
      {
        code: 'UZ', map: '🇺🇿', phoneCode: 998, value: 'Uzbekistan',
      },
      {
        code: 'VU', map: '🇻🇺', phoneCode: 678, value: 'Vanuatu',
      },
      {
        code: 'VE', map: '🇻🇪', phoneCode: 58, value: 'Venezuela',
      },
      {
        code: 'VN', map: '🇻🇳', phoneCode: 84, value: 'Vietnam',
      },
      {
        code: 'EH', map: '🇪🇭', phoneCode: 212, value: 'Western Sahara',
      },
      {
        code: 'YE', map: '🇾🇪', phoneCode: 967, value: 'Yemen',
      },
      {
        code: 'ZM', map: '🇿🇲', phoneCode: 260, value: 'Zambia',
      },
      {
        code: 'ZW', map: '🇿🇼', phoneCode: 26, value: 'Zimbabwe',
      }])
    
    useEffect(() =>{
if(! JSON.parse(localStorage.getItem("User"))){

        props.match.history.push("/login")
      }
      else{        window.scrollTo(0, 0)

        localStorage.setItem("VenderWorkingThose" ,JSON.stringify(123)) 

        setUser1(JSON.parse(localStorage.getItem("User")))
        if(JSON.parse(localStorage.getItem("User")).Name)
        setname(JSON.parse(localStorage.getItem("User")).NameBuss)
        if(JSON.parse(localStorage.getItem("User")).bioBuss)
        setRole(JSON.parse(localStorage.getItem("User")).bioBuss)
        if(JSON.parse(localStorage.getItem("User")).email)
        setEmail(JSON.parse(localStorage.getItem("User")).email)
        if(JSON.parse(localStorage.getItem("User")).Phone)
        setPhone(JSON.parse(localStorage.getItem("User")).Phone)
        if(JSON.parse(localStorage.getItem("User")).ProfilePhotoBuss){
          setImage(JSON.parse(localStorage.getItem("User")).ProfilePhotoBuss)
          setNewImg(JSON.parse(localStorage.getItem("User")).ProfilePhotoBuss)
        }

  fetch("https://tapitdabit-backend.vercel.app/allUser/"+JSON.parse(localStorage.getItem("User"))._id,{
    method: "GET",
     headers :  {
     "Content-Type" : "application/json" , 
 } ,
})
.then(res5=>res5.json())
.then(res6=>{
    setArr1(res6.SocialBuss)
    for (let i = 0; i < res6.SocialBuss.length; i++) {
        Ar[i]  = 0;
        // range1[i] = 0
    }
})
      }

   
    },[])
    useEffect( () => () => localStorage.removeItem("VenderWorkingThose"), [] );


    const setChange = () =>{
        if(document.getElementById("get2").style.display === "none"){
            document.getElementById("get1").classList.add("is-active");
            document.getElementById("get2").style.display = "block";
        }
        else{
            document.getElementById("get1").classList.remove("is-active");
            document.getElementById("get2").style.display = "none";
        }
    }

    const setChange1 = (e) =>{
      if(e === 1 ){
        // document.getElementById("signUp").classList.add("right-panel-active");
        // document.getElementById("signIn").classList.remove("right-panel-active");
        document.getElementById("container1").classList.add("right-panel-active")
      }
      else{
        document.getElementById("container1").classList.remove("right-panel-active")
      }
    }




const postData = (e) =>{
  e.preventDefault()
  if(email.length === 0){
      // if (email === "projectpharma874@gmail.com")
      swal("Enter The Correct Email !" )
      setTimeout(()=>{
        swal.close()
               },1000)
         return
      }
  if(pass.length === 0){
      swal("Enter The Password !"  )
         return
       }
 
    fetch("https://tapitdabit-backend.vercel.app/logIn-user",{
      method: 'POST' , 
      headers :  {
        "Content-Type" : "application/json" , 
      } , 
      body : JSON.stringify({
        email  ,
        pass  ,
      })
    })
    .then((res)=>res.json())
    .then((res2)  =>{
        console.log(res2)
        if (res2 !== null && !res2.Error  ){
          if(!res2.isSettings){
             swal("SucessFully Login"  )
             setTimeout(()=>{
              swal.close()
                     },1000)
          localStorage.setItem("User" , JSON.stringify(res2) )
              props.history.push("/settings-profile-vender")
            }
            else{
           swal("SucessFully Login"  )
        localStorage.setItem("User" , JSON.stringify(res2) )
            props.history.push("/")
         }
        }
        else{
          if(res2.Error === "Plz Fill Setting first"){
              props.history.push("/settings-profile-vender")
          }
          swal(res2.Error )
        }
      // console.log(res2)
    })
    .catch((err)=>console.log(err))
  

}


const  setPassShow = (e)=>{
  setdispaly(e)
}



const SubmitDataMessage = (e) =>{
  e.preventDefault()
  // console.log("wddde");
  let Ak = []
    for (let i = 0; i < Arr1.length; i++) {
        if (range1[i] === 100)
        Ak.push(Arr1[i])
        // const element = array[i];
        
    }
  console.log(Ak)
  let Phone1

  if(UserPhoneCode !== null){
    
     Phone1 = "+"+UserPhoneCode+Phone
  }
    else {
      // setdispaly(true)
      swal("select Country Code")
      setTimeout(()=>{
        swal.close()
               },1000)
      return
    }
  // const d = User1.Social.filter((res,i)=>res.name === "Contact")
      // if(d.length === 0){
        setdispaly ( false)
                              const data = new FormData()
                              data.append("file", Image)
                              data.append("upload_preset", "commerce")
                              data.append("cloud_name", "freedeveloperss123")
                              fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
                                  method : "POST",
                                  body : data , 
                              })
                              .then(res=>res.json())
                              .then((res2)=>{

                              fetch("https://tapitdabit-backend.vercel.app/createContactLinkBussinessNameChange",{
                                          method: "POST",
                                          headers :  {
                                              "Content-Type" : "application/json" , 
                                          } ,
                                          body : JSON.stringify({
                                            name1 ,
                                            photo1 ,
                                              fullName :Name ,
                                              Email  ,
                                              Phone : Phone1 , 
                                              Address ,
                                              Gender  , 
                                              City ,
                                              Country  , 
                                              Role  , 
                                              Image : res2.url,
                                            ContactBuss : Ak,
                                            id : User1._id
                                              // MainLine2 :mainline ,
                                          })
                                      })
                                      .then(res11=>res11.json())
                                      .then((res12)=>{ 
                                          if(!res12.Error){
setdispaly ( true)

                                              swal("Successfully Upload your Contact Card!", {
                                                buttons: false,
                                              });      setTimeout(()=>{
                                                swal.close()
                                                       },1000)
                                              setTimeout(()=>{

                                                props.history.push("/user/bussiness/home/"+User1._id)
                                              },1500)
                                              //     this.se
                                            }
                                            else{
                                                //         email: "",
                                                // })\
                                                setdispaly ( true)

                                                swal(res12.Error);      setTimeout(()=>{
                                                  swal.close()
                                                         },1000)
                                              }
                                  })
                                  })
                                  .catch(err=>{
                                    setdispaly ( true)

                                      swal("There is An Error") 
                                      setTimeout(()=>{
                                        swal.close()
                                               },1000)                 
                                      })
                                    // }
                                    // else{
                                    //   const c = User1.ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
                                    //   if(c.length > 0){
                                    //        props.history.push("/user/bussiness/editProfile/addotherlink/"+User1._id)
                                    //   }
                                    //   else{

                                    //     localStorage.setItem("request123",JSON.stringify(3) )
                                    //     setTimeout(()=>{
                                    //       props.history.push("/user/request-for-pro-item")
                                    //       swal("Sorry First You Buy")
                                    //     },1000)  
                                    //    }
                                    // }

}
const setImage123 = (e)=>{
    setdispaly1(false)
    console.log(e)
    const data = new FormData()
    data.append("file", e)
    data.append("upload_preset", "commerce")
    data.append("cloud_name", "freedeveloperss123")
    fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
        method : "POST",
        body : data , 
    })
    .then(res=>res.json())
    .then((res2)=>{
        setphoto1(res2.url)
      document.getElementById("photoImage1").src = res2.url
      setdispaly1(true)
      setdispaly2(true)
        
    })
  }
const setRouting1 = (e)=>{
  if(e === 1){
    if(JSON.parse(localStorage.getItem("User")).DisPersonal)
    props.history.push("/user/home1/"+User1._id)
    else 
    props.history.push("/user/bussiness/home1/"+User1._id)
  }
  else if(e === 4){
    if(JSON.parse(localStorage.getItem("User")).DisPersonal)
    props.history.push("/user/editProfile/sort-delete/"+User1.uid)
    else
    props.history.push("/user/bussiness/editProfile/sort-delete/"+User1.uid)
  }
  else if(e === 3){
    // props.history.push("/user/analytics/"+User1._id)   

    const d =  JSON.parse(localStorage.getItem("User")).ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
    console.log(d)
    
      
    if (d.length > 0){
      let a = new Date()
      let f= a.getDate() +" "+(a.getUTCMonth()+1)+ " "+a.getUTCFullYear()
      console.log(d[0].End[0]+""+d[0].End[1] >= a.getDate(), d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ,d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear())
      if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] > a.getUTCFullYear()     ){
      // if(d[0].End && d[0].End >= f){
    props.history.push("/user/analytics/"+User1._id)   

      }
      else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear()){
        if( d[0].End[3]+""+d[0].End[4] >a.getUTCMonth()+1 ){
      props.history.push("/user/analytics/"+User1._id)   

        }
        else{
          if(d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1){
            if( d[0].End[0]+""+d[0].End[1] >=a.getDate()){
          props.history.push("/user/analytics/"+User1._id)   

            }
            else{
              document.getElementById("myDivUmer12").style.display= "block"

              // localStorage.setItem("request123",JSON.stringify(1) )
              // props.history.push("/user/request-for-pro-item")
              // swal("Sorry First You Buy")
  
            }
          }
          else{
            document.getElementById("myDivUmer12").style.display= "block"

            // localStorage.setItem("request123",JSON.stringify(1) )
            //   props.history.push("/user/request-for-pro-item")
            //   swal("Sorry First You Buy")
  
            }
          }
        }
      else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear() && d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ){
          if( d[0].End[0]+""+d[0].End[1] >=a.getDate()){
        props.history.push("/user/analytics/"+User1._id)   

        }
        else{
          document.getElementById("myDivUmer12").style.display= "block"

          // localStorage.setItem("request123",JSON.stringify(1) )
          // props.history.push("/user/request-for-pro-item")
          // swal("Sorry First You Buy")
  
        }
      }
      else{
        document.getElementById("myDivUmer12").style.display= "block"

        // localStorage.setItem("request123",JSON.stringify(1) )
        //   props.history.push("/user/request-for-pro-item")
        //   swal("Sorry First You Buy")
  
      }
    }
    else{
      document.getElementById("myDivUmer12").style.display= "block"

      // localStorage.setItem("request123",JSON.stringify(1) )
      //   props.history.push("/user/request-for-pro-item")
      //   swal("Sorry First You Buy")
  }
  }
  else if(e === 2){


    const d =  JSON.parse(localStorage.getItem("User")).ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
    console.log(d)
    
      
    if (d.length > 0){
      let a = new Date()
      let f= a.getDate() +" "+(a.getUTCMonth()+1)+ " "+a.getUTCFullYear()
      console.log(d[0].End[0]+""+d[0].End[1] >= a.getDate(), d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ,d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear())
      if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] > a.getUTCFullYear()     ){
      // if(d[0].End && d[0].End >= f){
       
      }
      else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear()){
        if( d[0].End[3]+""+d[0].End[4] >a.getUTCMonth()+1 ){
          props.history.push("/user/connect/myprofile/"+User1.uid)

        }
        else{
          if(d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1){
            if( d[0].End[0]+""+d[0].End[1]>=a.getDate()){
              props.history.push("/user/connect/myprofile/"+User1.uid)

            }
            else{
              document.getElementById("myDivUmer").style.display= "block"

              // localStorage.setItem("request123",JSON.stringify(1) )
              // props.history.push("/user/request-for-pro-item")
              // swal("Sorry First You Buy")
  
            }
          }
          else{
            document.getElementById("myDivUmer").style.display= "block"

            // localStorage.setItem("request123",JSON.stringify(1) )
            //   props.history.push("/user/request-for-pro-item")
            //   swal("Sorry First You Buy")
  
            }
          }
        }
      else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear() && d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ){
          if( d[0].End[0]+""+d[0].End[1]>=a.getDate()){
            props.history.push("/user/connect/myprofile/"+User1.uid)

        }
        else{
          document.getElementById("myDivUmer").style.display= "block"

          // localStorage.setItem("request123",JSON.stringify(1) )
          // props.history.push("/user/request-for-pro-item")
          // swal("Sorry First You Buy")
  
        }
      }
      else{
        document.getElementById("myDivUmer").style.display= "block"

        // localStorage.setItem("request123",JSON.stringify(1) )
        //   props.history.push("/user/request-for-pro-item")
        //   swal("Sorry First You Buy")
  
      }
    }
    else{
      document.getElementById("myDivUmer").style.display= "block"

      // localStorage.setItem("request123",JSON.stringify(1) )
      //   props.history.push("/user/request-for-pro-item")
      //   swal("Sorry First You Buy")
  }
  }
  else{

  }
}
    
const setChanger8 = (e)=>{
  document.getElementById("myDivUmer12").style.display= "block"
}
const setRanger=(i)=>{
  if(as) setas(false)
  else setas(true)
  console.log(i)
  if(range1[i] === 0){
    range1[i] = 100 
  }
  else{
    range1[i] = 0 
  }
  console.log(range1)
}
const setRoter123 = ()=>{
  localStorage.setItem("request123",JSON.stringify(1) )
      props.history.push("/user/request-for-pro-item")

}

const setChanger1 = (e)=>{
  document.getElementById("myDiv1234").style.display= "none"
}
const setChanger2 = (e)=>{
  document.getElementById("myDivUmer").style.display= "none"
}

        return (
            






      <div ref={myRef}
        // onScroll={onScrolll()}
        >
          <div style={{height : "2px"}}>

</div>
<div id="myDivUmer12">
                {/* <img src={guides} alt=""  id="myDiv23456"/> */}
                <center>
                  <h1>
                    Analytics
                </h1>
                  </center>
                <center><p>
                Unlock all links with Pro to see insights of your account. <br />
Tap Buy now  to upgrade.
          </p></center>
                  <center>
                  <button class="btn btn-dark F23 f45" onClick={()=>setRoter123()}>Buy Now </button>
                  </center>
                <ClearIcon id="myDiv3456767"  style={{color : "black",fontSize : "30px",position : "absolute" , right: "10%",top : "10%" , zIndex: "2"}} onClick={()=>setChanger8()}/>
            </div> 
            <div id="myDivUmer">
                {/* <img src={guides} alt=""  id="myDiv23456"/> */}
                <center>
                  <h1>
                     Build Your Network
                </h1>
                  </center>
                <center><p>
Capture leads using Popl and favorite, group, sort and manage them all from here. Start building your connections now                  </p></center>
                  <center>
                  <button class="btn btn-dark F23 f45" onClick={()=>setRoter123()}>Buy Now </button>
                  </center>
                <ClearIcon id="myDiv34567"  style={{color : "black",fontSize : "30px",position : "absolute" , right: "10%",top : "10%" , zIndex: "2"}} onClick={()=>setChanger2()}/>
            </div> 

        

        {/* Slider */}
        <br />
<center>
                            <div class="image-upload mi2" style={{margin : "70px auto 0px auto"}}>
                                                                              <label for="file-input">
                                                                                  <img src={nu1} style={{ width:"133px", fontSize : "30px",borderRadius : "15px"}}/>
                                                                              </label>
                                              
                                                                              <input id="file-input" type="file" style={{width :  "0px"}}  onChange={(e)=>setImage123(e.target.files[0])}/>
                                                                              </div>  

</center>

        <section>
                    <div className="container" style={{margin : "0px auto" , width : "97%"}}>
                            <div className="row justify-content-center">
                               

                                              
                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5">
                                                <Link to={"/main-categories/"}><img src={cd1} id="photoImage1" alt="" className="arrival-Photo111" style={{width : "100px"}}/></Link>
                                            </div>
                                            
                                {/* <button style={{position:"absolute",heught : "100px",right:"43%"}} className="yh2">
                                    <input type="file"  visbility="hidden" onChange={(e)=>setImage123(e.target.files[0])} style={{ width:"125px", fontSize : "30px"}} accept={nu1} /> 
                                    <img src={nu1} alt="Mountain"/>
                                     <span>Customize Icon</span> 
                                </button> */}
                                {/* <button style={{position:"absolute",heught : "100px",right:"43%"}} className="yh2">
                                    <input type="file"  visbility="hidden" onChange={(e)=>setImage123(e.target.files[0])} style={{ width:"125px", fontSize : "30px"}} accept={nu1} /> 

                                </button> */}
                               
                            </div>
                            </div>
                </section>







<form  onSubmit={(e)=>SubmitDataMessage(e)}>

                <section>
                    <div className="container" style={{margin : "50px auto" , width : "97%"}}>
                            <div className="row justify-content-center">

                <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 ">
                <div className="form-row">
                                          <div className="form-group col-md-12">
                                        <label for="inputPassword4">Contact Name</label>
                                        <input type="text" required value={name1} onChange={(e)=>setname1(e.target.value) }  className="form-control" id="inputPassword4" placeholder="Enter Contact Name"/>
                                          </div>
                                         
                                </div>
                                <div className="form-row">
                                          <div className="form-group col-md-6">
                                        <label for="inputPassword4">Name</label>
                                        <input type="text" required value={Name} onChange={(e)=>setname(e.target.value) }  className="form-control" id="inputPassword4" placeholder="Enter Full Name"/>
                                          </div>
                                          <div className="form-group col-md-6">
                                        <label for="inputPassword4">Email</label>
                                        <input type="text" required value={Email} onChange={(e)=>setEmail(e.target.value) }  className="form-control" id="inputPassword4" placeholder="Enter Email"/>
                                      </div>
                                </div>
                                <div className="form-row">
                                          <div className="form-group col-md-12">
                                        <label for="inputPassword4">Phone</label>
                                        <div style={{display : "flex" , justifyContent : "flex-start"}}>
                                            <select name="" id=""  value={UserPhoneCode} onChange={(e)=>setUserPhoneCode(e.target.value)}  style={{width : "117px" , fontSize :"12px" , height: "42px"}}>
                                              
                                              {
                                                PhoneCode.map((res,i)=>{
                                                  return(
                                                    <option value={res.phoneCode} >{res.value}  +{res.phoneCode}</option>
                                                    // <option value={"+" + res.phoneCode} onChange={()=>setUserPhoneCode(res)}>{res.value}  +{res.phoneCode}</option>
                                                  )
                                                })
                                              }
                                            </select>
                                        <input type="text" required value={Phone} onChange={(e)=>setPhone(e.target.value) }  className="form-control" id="inputPassword4" placeholder="Enter Phone"/>
                                          </div>
                                          </div>
                                          {/* <div className="form-group col-md-6">
                                        <label for="inputPassword4">Address</label>
                                        <input type="text" required value={Address} onChange={(e)=>setAddress(e.target.value) }  className="form-control" id="inputPassword4" placeholder="Enter Address"/>
                                      </div> */}
                                </div>
                                <div className="form-row">
                                          {/* <div className="form-group col-md-6">
                                        <label for="inputPassword4">Gender</label>
                                        <input type="text" required value={Gender} onChange={(e)=>setGender(e.target.value) }  className="form-control" id="inputPassword4" placeholder="Enter Gender"/>
                                          </div> */}
                                          <div className="form-group col-md-12">
                                        <label for="inputPassword4">Address</label>
                                        <input type="text" required value={City} onChange={(e)=>setCity(e.target.value) }  className="form-control" id="inputPassword4" placeholder="Enter Address"/>
                                      </div>
                                </div>
                                <div className="form-row">
                                          <div className="form-group col-md-6">
                                        <label for="inputPassword4">Country</label>
                                        <input type="text" required value={Country} onChange={(e)=>setCountry(e.target.value) }  className="form-control" id="inputPassword4" placeholder="Enter Country"/>
                                          </div>
                                          <div className="form-group col-md-6">
                                        <label for="inputPassword4">Role</label>
                                        <input type="text" value={Role} onChange={(e)=>setRole(e.target.role) }  className="form-control" id="inputPassword4" placeholder="Enter Role"/>
                                      </div>
                                </div>
                                
                                <div className="form-row">
                                {NewImg ? 
<>
<div style={{
  display: "flex",
  justifyontent: "flex-start",
  alignItems: "center",
  width: "94%"
}}>
<img src={NewImg} alt="" style={{width : "60px", height : "60px" , borderRadius : "60px"}}/>

                                {/* <img src={NewImg} alt="" style={{width : "60px", height : "60px" , borderRadius : "60px"}}/> */}

                                <div className="form-group col-md-12">
                                        <label for="inputAddress2">Upload Profile</label>
                                        <input type="file" onChange={(e)=>setImage(e.target.files[0])}  className="form-control" id="inputAddress2" placeholder="Upload Image"/>
                                    </div>
</div>
                                    </>
:
                                <div className="form-group col-md-12">
                                        <label for="inputAddress2">Upload Profile</label>
                                        <input type="file" onChange={(e)=>setImage(e.target.files[0])}  className="form-control" id="inputAddress2" placeholder="Upload Image"/>
                                    </div>
}

                                </div>
                                
                        </div>

          </div>
          </div>
          </section>



















            {Arr1.map((res1,i)=>{
                    return(
                        <section>
                               <center>{res1.photo === "" && res1.name === "facebook" ? 
                                                    <img src={a4} alt="" className="arrival-Photo111 arrival-P"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "location" ? 
                                                    <img src={a23} alt="" className="arrival-Photo111 arrival-P"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "instagram" ? 
                                                    <img src={a1} alt="" className="arrival-Photo111 arrival-P"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "snapchat" ? 
                                                    <img src={a2} alt="" className="arrival-Photo111 arrival-P"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "tiktok" ? 
                                                    <img src={a3} alt="" className="arrival-Photo111 arrival-P"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "linkedin" ? 
                                                    <img src={a5} alt="" className="arrival-Photo111 arrival-P"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "twitter" ? 
                                                    <img src={a6} alt="" className="arrival-Photo111 arrival-P"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "youtube" ? 
                                                    <img src={a7} alt="" className="arrival-Photo111 arrival-P"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "twitch" ? 
                                                    <img src={a8} alt="" className="arrival-Photo111 arrival-P"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "pinterest" ? 
                                                    <img src={a9} alt="" className="arrival-Photo111 arrival-P"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "cash app" ? 
                                                    <img src={z1} alt="" className="arrival-Photo111 arrival-P"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "paypal" ? 
                                                    <img src={z2} alt="" className="arrival-Photo111 arrival-P"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "Venmo" ? 
                                                    <img src={z3} alt="" className="arrival-Photo111 arrival-P"/>
                                                : ""
                                                }
                                                 {res1.photo === "" && res1.name === "JazzCash" ? 
                                                    <img src={k1} alt="" className="arrival-Photo111 arrival-P"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "EasyPaisa" ? 
                                                    <img src={k4} alt="" className="arrival-Photo111 arrival-P"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "Bank" ? 
                                                    <img src={k5} alt="" className="arrival-Photo111 arrival-P"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "text" ? 
                                                    <img src={a21} alt="" className="arrival-Photo111 arrival-P"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "website" ? 
                                                    <img src={c1} alt="" className="arrival-Photo111 arrival-P"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "whatsapps" ? 
                                                    <img src={a22} alt="" className="arrival-Photo111 arrival-P"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "email" ? 
                                                    <img src={a24} alt="" className="arrival-Photo111 arrival-P"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "phone" ? 
                                                    <img src={z1} alt="" className="arrival-Photo111 arrival-P"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "Linktree" ? 
                                                    <img src={c2} alt="" className="arrival-Photo111 arrival-P"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "Address" ? 
                                                    <img src={a23} alt="" className="arrival-Photo111 arrival-P"/>
                                                : ""
                                                }
                                                {res1.photo !== "" && res1.name !== "Contact" ? 
                                                <img src={res1.photo} alt="" className="arrival-Photo111 arrival-P"/>
                                                : ""
                                                }
                                           </center>
                                            <div className="container" style={{margin : "0px auto" , width : "97%",height : "117px"}}>
                                                    <div className="row justify-content-center">
                                
                                            {/* <div className="arrival11 col-xl-10 col-lg-10 col-md-4 col-sm-5">
                                                <div style={{minWidth: "95%",display: "flex",justifyContent: "center"}}  > */}
                                                {/* <img src={z1} alt="" className="arrival-Photo111"/> */}
                                                
                                                <div className="arrival11 col-xl-10 col-lg-10 col-md-4 col-sm-5">
                                                <div style={{minWidth: "95%",display: "flex",justifyContent: "center"}}  >
                                                <div>
                                                <h3>
                                                {res1.name}
                                                </h3>
                                                {res1.link ? <p style={{maxWidth : "250px",minWidth : "250px",    overflow: "hidden"}}> 
                                                {res1.link} 
                                                </p>
                                                :
                                                <p style={{maxWidth : "250px",minWidth : "250px",    overflow: "hidden"}}> 
                                                {res1.Number} 
                                                </p>
                                                }
                                                </div>
                                                <div  className="ki1 uhuuihuih8"  onClick={()=>setRanger(i)} style={{position: "absolute",right: "-60px" ,minWidth: "29px",height: "28px",background: "transparent"}}></div><input style={{width : "40px", marginTop : "2%",height:"45px"}} className="cx" type="range" min="0" max="100" value={range1[i]} onClick={()=>setRanger(i)} />
                                                </div>
                                            </div>
                                            
                                           
                            </div>
                               
                            </div>
                </section> 
                    )
            })

            }
               








           <center>
          
            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-10" style={{margin : "20px 0px" }}>
                   
                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        {/* <button className="button11" style={{width : "200px" , background : "rgb(211, 211, 211)" , color  : "black" , borderRadius :  "3px" , border : "3px solid black",fontSize : "18px"}}>Open</button> */}
                        {dispaly ? 
                            <button className="button11" type="submit"  style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
                            
                            :
                            <span className="button11"  style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</span>

                        }                    </div>
                        
                    <br/>
                        
            </div>
           </center>
          </form>
{/* 
           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "20px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage(e)}>
                   
                    <input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here" /><br/>
                    <div style={{display : "flex" , justifyContent : "space-between"}}>
                        <button className="button11" style={{width : "200px" , background : "rgb(211, 211, 211)" , color  : "black" , borderRadius :  "3px" , border : "3px solid black",fontSize : "18px"}}>Open</button>
                        <button className="button11" type="submit" style={{width : "200px",   background : "rgb(0, 0, 0)" ,  color  : "white", borderRadius :  "3px" , border : "3px solid rgb(211, 211, 211)",fontSize : "18px"}}>Save</button>
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           */}




          
        


        {/* Back to top */}
        <div className="btn-back-to-top" id="myBtn">
          <span className="symbol-btn-back-to-top">
            <i className="zmdi zmdi-chevron-up" />
          </span>
        </div>
        {/* Modal1 */}
        <div className="wrap-modal1 js-modal1 p-t-60 p-b-20">
          <div className="overlay-modal1 js-hide-modal1" />
          <div className="container">
            <div className="bg0 p-t-60 p-b-30 p-lr-15-lg how-pos3-parent">
              <button className="how-pos3 hov3 trans-04 js-hide-modal1">
                <img src="images/icons/icon-close.png" alt="CLOSE" />
              </button>
              <div className="row">
                <div className="col-md-6 col-lg-7 p-b-30">
                  <div className="p-l-25 p-r-30 p-lr-0-lg">
                    <div className="wrap-slick3 flex-sb flex-w">
                      <div className="wrap-slick3-dots" />
                      <div className="wrap-slick3-arrows flex-sb-m flex-w" />
                      <div className="slick3 gallery-lb">
                        <div className="item-slick3" data-thumb="images/product-detail-01.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-01.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-01.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-02.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-02.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-02.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-03.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-03.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-03.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5 p-b-30">
                  <div className="p-r-50 p-t-5 p-lr-0-lg">
                    <h4 className="mtext-105 cl2 js-name-detail p-b-14">
                      Lightweight Jacket
                    </h4>
                    <span className="mtext-106 cl2">
                      $58.79
                    </span>
                    <p className="stext-102 cl3 p-t-23">
                      Nulla eget sem vitae eros pharetra viverra. Nam vitae luctus ligula. Mauris consequat ornare feugiat.
                    </p>
                    {/*  */}
                    <div className="p-t-33">
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Size
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Size S</option>
                              <option>Size M</option>
                              <option>Size L</option>
                              <option>Size XL</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Color
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Red</option>
                              <option>Blue</option>
                              <option>White</option>
                              <option>Grey</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-204 flex-w flex-m respon6-next">
                          <div className="wrap-num-product flex-w m-r-20 m-tb-10">
                            <div className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-minus" />
                            </div>
                            <input className="mtext-104 cl3 txt-center num-product" type="number" name="num-product" defaultValue={1} />
                            <div className="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-plus" />
                            </div>
                          </div>
                          <button className="flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04 js-addcart-detail">
                            Add to cart
                          </button>
                        </div>
                      </div>	
                    </div>
                    {/*  */}
                    <div className="flex-w flex-m p-l-100 p-t-40 respon7">
                      <div className="flex-m bor9 p-r-10 m-r-11">
                        <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 js-addwish-detail tooltip100" data-tooltip="Add to Wishlist">
                          <i className="zmdi zmdi-favorite" />
                        </a>
                      </div>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Facebook">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Twitter">
                        <i className="fa fa-twitter" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Google Plus">
                        <i className="fa fa-google-plus" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
 






        )
    
}

export default Home;