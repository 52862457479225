import React ,{useEffect , useState} from 'react'
// import {Link} from "react-router-dom"
import swal from 'sweetalert';

import ClearIcon from '@material-ui/icons/Clear';

import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import AssessmentIcon from '@material-ui/icons/Assessment';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SortIcon from '@material-ui/icons/Sort';
import "react-multi-carousel/lib/styles.css";
import "react-multi-carousel/lib/styles.css";
import "./../assets/css/main.css"
import "./../assets/css/util.css"
import "./../assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css"



import "./../assets/vendor/css-hamburgers/hamburgers.min.css"

import "./../assets/fonts/iconic/css/material-design-iconic-font.min.css"
import "./../assets/vendor/bootstrap/css/bootstrap.min.css"
import "./../assets/vendor/select2/select2.min.css"
import "./../assets/vendor/slick/slick.css"
import "./../assets/vendor/perfect-scrollbar/perfect-scrollbar.css"



import a1 from "./../assets/images/a1.png"
import a2 from "./../assets/images/a2.png"
import a3 from "./../assets/images/a3.png"
import a4 from "./../assets/images/a4.png"
import a5 from "./../assets/images/a5.png"
import a6 from "./../assets/images/a6.png"
import a7 from "./../assets/images/a7.png"
import a8 from "./../assets/images/a8.png"

import k1 from "./../assets/images/k1.png"

import k4 from "./../assets/images/k4.png"
import k5 from "./../assets/images/k5.png"

import a9 from "./../assets/images/a9.png"

import a11 from "./../assets/images/a11.png"
import a21 from "./../assets/images/a21.png"
import a22 from "./../assets/images/a22.png"
import a23 from "./../assets/images/a23.png"
import a24 from "./../assets/images/a24.png"
import a25 from "./../assets/images/a25.png"
import a27 from "./../assets/images/a27.png"


import z1 from "./../assets/images/z1.png"
import z2 from "./../assets/images/z2.png"
import z3 from "./../assets/images/z3.png"
// import z4 from "./../assets/images/z4.png"


import c1 from "./../assets/images/c1.png"
import c2 from "./../assets/images/c2.png"
import c3 from "./../assets/images/c3.png"


import ln1 from "./../assets/images/svg/social/f linkedin.svg"
import tw1 from "./../assets/images/svg/social/f twitter.svg"
import in1 from "./../assets/images/svg/social/f instagram.svg"
import pi1 from "./../assets/images/svg/social/f pinterest.svg"
import tt1 from "./../assets/images/svg/social/twitch (1).svg"
import tk1 from "./../assets/images/svg/social/tiktok.svg"
import you1 from "./../assets/images/svg/social/youtube (2).svg"
import sc1 from "./../assets/images/svg/social/snapchat.svg"
import fb1 from "./../assets/images/svg/social/f facebook.svg"


import bk1 from "./../assets/images/svg/Payments/bank (9).svg"
import ca1 from "./../assets/images/svg/Payments/cashapp.svg"
import ep1 from "./../assets/images/svg/Payments/easypaisa.png"
// import jc1 from "./../assets/images/k34.svg"
import jc1 from "./../assets/images/svg/Payments/SELECT JAZZ CASH LOGO/jazzcash.png"
import ve1 from "./../assets/images/svg/Payments/venmo.svg"
import pp1 from "./../assets/images/svg/Payments/paypal (1).svg"



import cu1 from "./../assets/images/svg/MORE/custom link.svg"
import lt1 from "./../assets/images/svg/MORE/linktree-2.svg"
import vi1 from "./../assets/images/svg/MORE/video.svg"
import we1 from "./../assets/images/svg/MORE/WEBSITE.svg"
// import ve1 from "./../assets/images/svg/MORE/custom link.svg"
// import ve1 from "./../assets/images/svg/MORE/custom link.svg"


import call1 from "./../assets/images/svg/cotact info/call.svg"
import cd1 from "./../assets/images/svg/cotact info/ccard.svg"
import lo1 from "./../assets/images/svg/cotact info/location.svg"
import mail1 from "./../assets/images/svg/cotact info/mail.svg"
import wa1 from "./../assets/images/svg/cotact info/whatapps.svg"
import mess1 from "./../assets/images/svg/cotact info/message (2).svg"




const Home = ( props ) => {
    const [myRef,setRef] = useState( React.createRef())
    const [scrollTop1,setscrollTop] = useState(0)
    const [email , setemail] = useState("")
    const [pass , setpass] = useState("")
    const [dispaly ,  setdispaly ] = useState(true)
    const [User1 ,  setUser1 ] = useState({})
    const [Name ,  setName ] = useState("")
    const [gender ,  setgender ] = useState("")
    const [date ,  setdate ] = useState("")
    const [area ,  setarea ] = useState("")
    const [Image ,  setImage ] = useState("")


    useEffect(() =>{        
      window.scrollTo(0, 0)

      if(! JSON.parse(localStorage.getItem("User"))){
        props.match.history.push("/login")
      }else{
        fetch("https://tapitdabit-backend.vercel.app/allUser/"+JSON.parse(localStorage.getItem("User"))._id,{
          method: "GET",
           headers :  {
           "Content-Type" : "application/json" , 
       } ,
      })
      .then(res5=>res5.json())
      .then(res6=>{
        const data = {
          ...res6 , All : [...res6.Social,...res6.Contact]
        }
        localStorage.setItem("User" ,JSON.stringify(data)) 
      })
        localStorage.removeItem("UserProp")

        localStorage.setItem("VenderWorkingThose" ,JSON.stringify(123)) 
        setUser1(JSON.parse(localStorage.getItem("User")))
        setName(JSON.parse(localStorage.getItem("User")).Name)
      }
  // localStorage.setItem("User" , JSON.stringify(res2) )
  return () => {
    document.getElementById("get1").classList.remove("is-active");
    document.getElementById("get2").style.display = "none";
  }
    },[])

    useEffect( () => () => localStorage.removeItem("VenderWorkingThose"), [] );

    const setChange = () =>{
        if(document.getElementById("get2").style.display === "none"){
            document.getElementById("get1").classList.add("is-active");
            document.getElementById("get2").style.display = "block";
        }
        else{
            document.getElementById("get1").classList.remove("is-active");
            document.getElementById("get2").style.display = "none";
        }
    }

    const setChange1 = (e) =>{
      if(e === 1 ){
        // document.getElementById("signUp").classList.add("right-panel-active");
        // document.getElementById("signIn").classList.remove("right-panel-active");
        document.getElementById("container1").classList.add("right-panel-active")
      }
      else{
        document.getElementById("container1").classList.remove("right-panel-active")
      }
    }




const postData = (e) =>{
  e.preventDefault()
  if(email.length === 0){
      // if (email === "projectpharma874@gmail.com")
      swal("Enter The Correct Email !" )
         return
      }
  if(pass.length === 0){
      swal("Enter The Password !"  )
         return
       }
 
    fetch("https://tapitdabit-backend.vercel.app/logIn-user",{
      method: 'POST' , 
      headers :  {
        "Content-Type" : "application/json" , 
      } , 
      body : JSON.stringify({
        email  ,
        pass  ,
      })
    })
    .then((res)=>res.json())
    .then((res2)  =>{
        console.log(res2)
        if (res2 !== null && !res2.Error  ){
          if(!res2.isSettings){
             swal("SucessFully Login"  )
          localStorage.setItem("User" , JSON.stringify(res2) )
              props.history.push("/settings-profile-vender")
            }
            else{
           swal("SucessFully Login"  )
        localStorage.setItem("User" , JSON.stringify(res2) )
            props.history.push("/")
         }
        }
        else{
          if(res2.Error === "Plz Fill Setting first"){
              props.history.push("/settings-profile-vender")
          }
          swal(res2.Error )
        }
      // console.log(res2)
    })
    .catch((err)=>console.log(err))
  

}












const setChangeData = () =>{
  

  console.log(Name,gender,area,date)
  // const data = new FormData()
  //                             data.append("file", Image)
  //                             data.append("upload_preset", "commerce")
  //                             data.append("cloud_name", "freedeveloperss123")
  //                             fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
  //                                 method : "POST",
  //                                 body : data , 
  //                             })
  //                             .then(res=>res.json())
  //                             .then((res2)=>{
    fetch("https://tapitdabit-backend.vercel.app/ProfleChangePersonal",{
      method: 'POST' , 
      headers :  {
        "Content-Type" : "application/json" , 
      } , 
      body : JSON.stringify({
        id : User1._id ,
        Name  ,
        image : Image  ,
        area  ,
      })
    })
    .then((res)=>res.json())
    .then((res2)  =>{
        console.log(res2)
        // if (res2 !== null && !res2.Error  ){
        //   if(!res2.isSettings){
             swal("Update Successfully"  )
          // localStorage.setItem("User" , JSON.stringify(res2) )
          //     props.history.push("/settings-profile-vender")
          
        
      // console.log(res2)
    })
    .catch((err)=>console.log(err))
                              // })

}


const  setPassShow = (e)=>{
  setdispaly(e)
}


const  settochange = (e)=>{
  console.log(e)
  localStorage.setItem("the",JSON.stringify(e))
  props.history.push("/user/editProfile/"+User1.uid)

}

const  settochange1 = (e)=>{
  console.log(e)
  localStorage.setItem("the1",JSON.stringify(e))
  props.history.push("/user/editProfile123/"+User1.uid)

}

const EmbedVideo= () =>{
  const d = User1.ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
  console.log(d)
  if (d.length > 0){
    let a = new Date()
    let f= a.getDate() +" "+(a.getUTCMonth()+1)+ " "+a.getUTCFullYear()
    console.log(d[0].End[0]+""+d[0].End[1] >= a.getDate(), d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ,d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear())
    if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] > a.getUTCFullYear()     ){
    // if(d[0].End && d[0].End >= f){
      props.history.push("/user/editProfile/emdedvideo/"+User1.uid)

    }
    else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear()){
      if( d[0].End[3]+""+d[0].End[4] >a.getUTCMonth()+1 ){
        props.history.push("/user/editProfile/emdedvideo/"+User1.uid)

      }
      else{
        if(d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1){
          if( d[0].End[0]+""+d[0].End[1]>=a.getDate()){
            props.history.push("/user/editProfile/emdedvideo/"+User1.uid)

          }
          else{
            localStorage.setItem("request123",JSON.stringify(1) )
            // document.getElementById("myDivUmer2").style.display= "block"
            props.history.push("/user/pro-feather")

          // setTimeout(()=>{
          //   props.history.push("/user/request-for-pro-item")
          //   swal("Sorry First You Buy")
          // },1000)

          }
        }
        else{
          localStorage.setItem("request123",JSON.stringify(1) )
          // document.getElementById("myDivUmer2").style.display= "block"
          props.history.push("/user/pro-feather")

          // setTimeout(()=>{
          //   props.history.push("/user/request-for-pro-item")
          //   swal("Sorry First You Buy")
          // },1000)

          }
        }
      }
    else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear() && d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ){
        if( d[0].End[0]+""+d[0].End[1]>=a.getDate()){
          props.history.push("/user/editProfile/emdedvideo/"+User1.uid)

      }
      else{
        localStorage.setItem("request123",JSON.stringify(1) )
          //  document.getElementById("myDivUmer2").style.display= "block"
        props.history.push("/user/pro-feather")

        // setTimeout(()=>{
      //   props.history.push("/user/request-for-pro-item")
      //   swal("Sorry First You Buy")
      // },1000)

      }
    }
    else{
      localStorage.setItem("request123",JSON.stringify(1) )
          //  document.getElementById("myDivUmer2").style.display= "block"
      props.history.push("/user/pro-feather")

      // setTimeout(()=>{
      //   props.history.push("/user/request-for-pro-item")
      //   swal("Sorry First You Buy")
      // },1000)

    }
  }
  else{
    localStorage.setItem("request123",JSON.stringify(1) )
    // document.getElementById("myDivUmer2").style.display= "block"
    props.history.push("/user/pro-feather")

    // setTimeout(()=>{
    //   props.history.push("/user/request-for-pro-item")
    //   swal("Sorry First You Buy")
    // },1000)
}

  
}
const  settochange3 = (e)=>{
  if(e === 33){
    const d = User1.ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
    console.log(d)
    if (d.length > 0){
      let a = new Date()
      let f= a.getDate() +" "+(a.getUTCMonth()+1)+ " "+a.getUTCFullYear()
      console.log(d[0].End[0]+""+d[0].End[1] >= a.getDate(), d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ,d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear())
      if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] > a.getUTCFullYear()     ){
      // if(d[0].End && d[0].End >= f){
        localStorage.setItem("the",JSON.stringify(e))

        props.history.push("/user/editProfile/"+User1.uid)

      }
      else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear()){
        if( d[0].End[3]+""+d[0].End[4] >a.getUTCMonth()+1 ){
          localStorage.setItem("the",JSON.stringify(e))

          props.history.push("/user/editProfile/"+User1.uid)

        }
        else{
          if(d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1){
            if( d[0].End[0]+""+d[0].End[1]>=a.getDate()){
              localStorage.setItem("the",JSON.stringify(e))

              props.history.push("/user/editProfile/"+User1.uid)

            }
            else{
              localStorage.setItem("request123",JSON.stringify(1) )
              // document.getElementById("myDivUmer3").style.display= "block"

    props.history.push("/user/pro-feather")


            // setTimeout(()=>{
            //   props.history.push("/user/request-for-pro-item")
            //   swal("Sorry First You Buy")
            // },1000)
  
            }
          }
          else{
            localStorage.setItem("request123",JSON.stringify(1) )
            // document.getElementById("myDivUmer3").style.display= "block"

    props.history.push("/user/pro-feather")


            // setTimeout(()=>{
            //   props.history.push("/user/request-for-pro-item")
            //   swal("Sorry First You Buy")
            // },1000)
  
            }
          }
        }
      else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear() && d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ){
          if( d[0].End[0]+""+d[0].End[1]>=a.getDate()){
            props.history.push("/user/editProfile/"+User1.uid)

        }
        else{
          localStorage.setItem("request123",JSON.stringify(1) )
          // document.getElementById("myDivUmer3").style.display= "block"

    props.history.push("/user/pro-feather")


        // setTimeout(()=>{
        //   props.history.push("/user/request-for-pro-item")
        //   swal("Sorry First You Buy")
        // },1000)

        }
      }
      else{
        localStorage.setItem("request123",JSON.stringify(1) )
        // document.getElementById("myDivUmer3").style.display= "block"

    props.history.push("/user/pro-feather")


        // setTimeout(()=>{
        //   props.history.push("/user/request-for-pro-item")
        //   swal("Sorry First You Buy")
        // },1000)

      }
    }
    else{
      localStorage.setItem("request123",JSON.stringify(1) )
          //  document.getElementById("myDivUmer3").style.display= "block"

    props.history.push("/user/pro-feather")


      // setTimeout(()=>{
      //   props.history.push("/user/request-for-pro-item")
      //   swal("Sorry First You Buy")
      // },1000)
  }
  }




  else{
    console.log(e)
    localStorage.setItem("the",JSON.stringify(e))
    props.history.push("/user/editProfile/"+User1.uid)

  }

}

const  setContactCard = (e)=>{
  console.log(e)
  localStorage.setItem("the1",JSON.stringify(e))
  props.history.push("/user/editProfile/contactcard/"+User1.uid)

}
const  settochange44 = (e)=>{
  console.log(e)
  localStorage.setItem("the3",JSON.stringify(e))
  props.history.push("/user/editProfile/linktree/"+User1.uid)

}
const  settochange443 = (e)=>{





  if(e === 53){
    const d = User1.ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
    console.log(d)
    if (d.length > 0){
      let a = new Date()
      let f= a.getDate() +" "+(a.getUTCMonth()+1)+ " "+a.getUTCFullYear()
      console.log(d[0].End[0]+""+d[0].End[1] >= a.getDate(), d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ,d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear())
      if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] > a.getUTCFullYear()     ){
      // if(d[0].End && d[0].End >= f){
        localStorage.setItem("the",JSON.stringify(e))
        localStorage.setItem("the1",JSON.stringify(e))
        localStorage.setItem("the3",JSON.stringify(e))

        props.history.push("/user/editProfile/linktree/"+User1.uid)

      }
      else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear()){
        if( d[0].End[3]+""+d[0].End[4] >a.getUTCMonth()+1 ){
          localStorage.setItem("the",JSON.stringify(e))
          localStorage.setItem("the1",JSON.stringify(e))

          props.history.push("/user/editProfile/linktree/"+User1.uid)
          localStorage.setItem("the3",JSON.stringify(e))

        }
        else{
          if(d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1){
            if( d[0].End[0]+""+d[0].End[1]>=a.getDate()){
              localStorage.setItem("the",JSON.stringify(e))
              localStorage.setItem("the1",JSON.stringify(e))
              localStorage.setItem("the3",JSON.stringify(e))

              props.history.push("/user/editProfile/linktree/"+User1.uid)

            }
            else{
              localStorage.setItem("request123",JSON.stringify(1) )
              // document.getElementById("myDivUmer6").style.display= "block"
    props.history.push("/user/pro-feather")



            // setTimeout(()=>{
            //   props.history.push("/user/request-for-pro-item")
            //   swal("Sorry First You Buy")
            // },1000)
  
            }
          }
          else{
            localStorage.setItem("request123",JSON.stringify(1) )
            // document.getElementById("myDivUmer6").style.display= "block"
    props.history.push("/user/pro-feather")



            // setTimeout(()=>{
            //   props.history.push("/user/request-for-pro-item")
            //   swal("Sorry First You Buy")
            // },1000)
  
            }
          }
        }
      else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear() && d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ){
          if( d[0].End[0]+""+d[0].End[1]>=a.getDate()){
            localStorage.setItem("the",JSON.stringify(e))
            props.history.push("/user/editProfile/linktree/"+User1.uid)
            localStorage.setItem("the1",JSON.stringify(e))
            localStorage.setItem("the3",JSON.stringify(e))

        }
        else{
          localStorage.setItem("request123",JSON.stringify(1) )
          localStorage.setItem("the",JSON.stringify(e))
          // document.getElementById("myDivUmer6").style.display= "block"
              props.history.push("/user/pro-feather")
localStorage.setItem("the1",JSON.stringify(e))


          localStorage.setItem("the3",JSON.stringify(e))

        // setTimeout(()=>{
        //   props.history.push("/user/request-for-pro-item")
        //   swal("Sorry First You Buy")
        // },1000)

        }
      }
      else{
        localStorage.setItem("request123",JSON.stringify(1) )
        // document.getElementById("myDivUmer6").style.display= "block"
    props.history.push("/user/pro-feather")



        // setTimeout(()=>{
        //   props.history.push("/user/request-for-pro-item")
        //   swal("Sorry First You Buy")
        // },1000)

      }
    }
    else{
      localStorage.setItem("request123",JSON.stringify(1) )
          //  document.getElementById("myDivUmer6").style.display= "block"
    props.history.push("/user/pro-feather")



      // setTimeout(()=>{
      //   props.history.push("/user/request-for-pro-item")
      //   swal("Sorry First You Buy")
      // },1000)
  }
  }




  // else{
  //   console.log(e)
  // localStorage.setItem("the3",JSON.stringify(e))
  // props.history.push("/user/editProfile/linktree/"+User1.uid)

  // }





  // console.log(e)
  // localStorage.setItem("the3",JSON.stringify(e))
  // props.history.push("/user/editProfile/linktree/"+User1.uid)

}







function getLocation() {
  // var x = document.getElementById("demo");
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(showPosition);
  } else { 
    swal("Not Access the location")
    // x.innerHTML = "Geolocation is not supported by this browser.";
  }
}

function showPosition(position) {
  // var x = document.getElementById("demo");
  // x.innerHTML = "Latitude: " + position.coords.latitude + 
  // "<br>Longitude: " + position.coords.longitude;
  fetch("https://tapitdabit-backend.vercel.app/createaddressLink",{
                                          method: "POST",
                                          headers :  {
                                              "Content-Type" : "application/json" , 
                                          } ,
                                          body : JSON.stringify({
                                            name  : "location",
                                            // name1  : position.coords.longitude,
                                            link : "https://www.google.com/maps?q="+position.coords.longitude+","+position.coords.latitude+"&hl=es;z=14&output=embed",
                                            id : User1._id
                                              // MainLine2 :mainline ,
                                          })
                                      })
                                      .then(res11=>res11.json())
                                      .then((res12)=>{ 
                                          if(!res12.Error){
                                            swal("Successfully Upload your Product!");
                                            this.setState({
                                                email: "",
                                        })

                                      }
                                      else{
                                      swal(res12.Error);
                                      }
                                  })
                                  .catch(err=>{
                                    swal("Successfully Upload your Location!");
                                      })
}





const setRouting1 = (e)=>{
  if(e === 1){
    if(JSON.parse(localStorage.getItem("User")).DisPersonal)
    props.history.push("/user/home1/"+User1._id)
    else 
    props.history.push("/user/bussiness/home1/"+User1._id)
  }
  else if(e === 4){
    if(JSON.parse(localStorage.getItem("User")).DisPersonal)
    props.history.push("/user/editProfile/sort-delete/"+User1.uid)
    else
    props.history.push("/user/bussiness/editProfile/sort-delete/"+User1.uid)
  }
  else if(e === 3){
    // props.history.push("/user/analytics/"+User1._id)   

    const d =  JSON.parse(localStorage.getItem("User")).ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
    console.log(d)
    
      
    if (d.length > 0){
      let a = new Date()
      let f= a.getDate() +" "+(a.getUTCMonth()+1)+ " "+a.getUTCFullYear()
      console.log(d[0].End[0]+""+d[0].End[1] >= a.getDate(), d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ,d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear())
      if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] > a.getUTCFullYear()     ){
      // if(d[0].End && d[0].End >= f){
    props.history.push("/user/analytics/"+User1._id)   

      }
      else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear()){
        if( d[0].End[3]+""+d[0].End[4] >a.getUTCMonth()+1 ){
      props.history.push("/user/analytics/"+User1._id)   

        }
        else{
          if(d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1){
            if( d[0].End[0]+""+d[0].End[1] >=a.getDate()){
          props.history.push("/user/analytics/"+User1._id)   

            }
            else{
              // document.getElementById("myDivUmer12").style.display= "block"\
    props.history.push("/user/pro-feather")



              // localStorage.setItem("request123",JSON.stringify(1) )
              // props.history.push("/user/request-for-pro-item")
              // swal("Sorry First You Buy")
  
            }
          }
          else{
            // document.getElementById("myDivUmer12").style.display= "block"\
    props.history.push("/user/pro-feather")



            // localStorage.setItem("request123",JSON.stringify(1) )
            //   props.history.push("/user/request-for-pro-item")
            //   swal("Sorry First You Buy")
  
            }
          }
        }
      else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear() && d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ){
          if( d[0].End[0]+""+d[0].End[1] >=a.getDate()){
        props.history.push("/user/analytics/"+User1._id)   

        }
        else{
          // document.getElementById("myDivUmer12").style.display= "block"\
    props.history.push("/user/pro-feather")



          // localStorage.setItem("request123",JSON.stringify(1) )
          // props.history.push("/user/request-for-pro-item")
          // swal("Sorry First You Buy")
  
        }
      }
      else{
        // document.getElementById("myDivUmer12").style.display= "block"\
    props.history.push("/user/pro-feather")



        // localStorage.setItem("request123",JSON.stringify(1) )
        //   props.history.push("/user/request-for-pro-item")
        //   swal("Sorry First You Buy")
  
      }
    }
    else{
      // document.getElementById("myDivUmer12").style.display= "block"\
    props.history.push("/user/pro-feather")



      // localStorage.setItem("request123",JSON.stringify(1) )
      //   props.history.push("/user/request-for-pro-item")
      //   swal("Sorry First You Buy")
  }
  }
  else if(e === 2){


    const d =  JSON.parse(localStorage.getItem("User")).ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
    console.log(d)
    
      
    if (d.length > 0){
      let a = new Date()
      let f= a.getDate() +" "+(a.getUTCMonth()+1)+ " "+a.getUTCFullYear()
      console.log(d[0].End[0]+""+d[0].End[1] >= a.getDate(), d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ,d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear())
      if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] > a.getUTCFullYear()     ){
      // if(d[0].End && d[0].End >= f){
        props.history.push("/user/connect/myprofile/"+User1.uid)

      }
      else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear()){
        if( d[0].End[3]+""+d[0].End[4] >a.getUTCMonth()+1 ){
          props.history.push("/user/connect/myprofile/"+User1.uid)

        }
        else{
          if(d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1){
            if( d[0].End[0]+""+d[0].End[1]>=a.getDate()){
              props.history.push("/user/connect/myprofile/"+User1.uid)

            }
            else{
              // document.getElementById("myDivUmer").style.display= "block"
    props.history.push("/user/pro-feather")



              // localStorage.setItem("request123",JSON.stringify(1) )
              // props.history.push("/user/request-for-pro-item")
              // swal("Sorry First You Buy")
  
            }
          }
          else{
            // document.getElementById("myDivUmer").style.display= "block"
    props.history.push("/user/pro-feather")



            // localStorage.setItem("request123",JSON.stringify(1) )
            //   props.history.push("/user/request-for-pro-item")
            //   swal("Sorry First You Buy")
  
            }
          }
        }
      else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear() && d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ){
          if( d[0].End[0]+""+d[0].End[1]>=a.getDate()){
            props.history.push("/user/connect/myprofile/"+User1.uid)

        }
        else{
          // document.getElementById("myDivUmer").style.display= "block"
    props.history.push("/user/pro-feather")



          // localStorage.setItem("request123",JSON.stringify(1) )
          // props.history.push("/user/request-for-pro-item")
          // swal("Sorry First You Buy")
  
        }
      }
      else{
        // document.getElementById("myDivUmer").style.display= "block"
    props.history.push("/user/pro-feather")



        // localStorage.setItem("request123",JSON.stringify(1) )
        //   props.history.push("/user/request-for-pro-item")
        //   swal("Sorry First You Buy")
  
      }
    }
    else{
      // document.getElementById("myDivUmer").style.display= "block"
    props.history.push("/user/pro-feather")



      // localStorage.setItem("request123",JSON.stringify(1) )
      //   props.history.push("/user/request-for-pro-item")
      //   swal("Sorry First You Buy")
  }
  }
  else{

  }
}
const setChanger8 = (e)=>{
  // document.getElementById("myDivUmer12").style.display= "block"
  props.history.push("/user/pro-feather")

}
const setImage123 = (e)=>{
  console.log(e)
  const data = new FormData()
  data.append("file", e)
  data.append("upload_preset", "commerce")
  data.append("cloud_name", "freedeveloperss123")
  fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
      method : "POST",
      body : data , 
  })
  .then(res=>res.json())
  .then((res2)=>{
    setImage(res2.url)
    document.getElementById("photoImage1").src = res2.url
  })
}
const setRoter123 = ()=>{
  localStorage.setItem("request123",JSON.stringify(1) )
      props.history.push("/user/request-for-pro-item")

}

const setChanger1 = (e)=>{
  document.getElementById("myDivUmer2").style.display= "none"
}
const setChanger2 = (e)=>{
  document.getElementById("myDivUmer").style.display= "none"
}
const setChanger3 = (e)=>{
  document.getElementById("myDivUmer3").style.display= "none"
}
const setChanger6 = (e)=>{
  document.getElementById("myDivUmer6").style.display= "none"
}

        return (
            






      <div 
        // onScroll={onScrolll()}
        >
     
        {/* Slider */}





        <div style={{height : "2px"}}>

</div>
<div id="myDivUmer12">
                {/* <img src={guides} alt=""  id="myDiv23456"/> */}
                <center>
                  <h1>
                    Analytics
                </h1>
                  </center>
                <center><p>
                Unlock all links with Pro to see insights of your account. <br />
Tap Buy now  to upgrade.
          </p></center>
                  <center>
                  <button class="btn btn-dark F23 f45" onClick={()=>setRoter123()}>Buy Now </button>
                  </center>
                <ClearIcon id="myDiv3456767"  style={{color : "black",fontSize : "30px",position : "absolute" , right: "10%",top : "10%" , zIndex: "2"}} onClick={()=>setChanger8()}/>
            </div> 
        <div id="myDivUmer">
                {/* <img src={guides} alt=""  id="myDiv23456"/> */}
                <center>
                  <h1>
                     Build Your Self
                </h1>
                  </center>
                <center><p>
Capture leads using TapitDabit and favorite, group, sort and manage them all from here. Start building your connections now                  </p></center>
                  <center>
                  <button class="btn btn-dark F23 f45" onClick={()=>setRoter123()}>Buy Now </button>
                  </center>
                <ClearIcon id="myDiv34567"  style={{color : "black",fontSize : "30px",position : "absolute" , right: "10%",top : "10%" , zIndex: "2"}} onClick={()=>setChanger2()}/>
            </div> 
            <div id="myDivUmer3">
                {/* <img src={guides} alt=""  id="myDiv23456"/> */}
                <center>
                  <h1>
                     Custom Icon 
                      Title
                </h1>
                  </center>
                <center><p>
Set custom icons and titles for each of your links! Setting better icons and titles can be great for engagement and tap through rate       </p></center>
                  <center>
                  <button class="btn btn-dark F23 f45" onClick={()=>setRoter123()}>Buy Now </button>
                  </center>
                <ClearIcon id="myDiv34567"  style={{color : "black",fontSize : "30px",position : "absolute" , right: "10%",top : "10%" , zIndex: "2"}} onClick={()=>setChanger3()}/>
            </div> 
            <div id="myDivUmer6">
                {/* <img src={guides} alt=""  id="myDiv23456"/> */}
                <center>
                  <h1>
                     Links Your 
                      Tree
                </h1>
                  </center>
                <center><p>
Set Link tree each of your links have link Name! Setting better understanding and titles can be great for engagement and tap through rate       </p></center>
                  <center>
                  <button class="btn btn-dark F23 f45" onClick={()=>setRoter123()}>Buy Now </button>
                  </center>
                <ClearIcon id="myDiv34567"  style={{color : "black",fontSize : "30px",position : "absolute" , right: "10%",top : "10%" , zIndex: "2"}} onClick={()=>setChanger6()}/>
            </div> 
        <div id="myDivUmer2">
                {/* <img src={guides} alt=""  id="myDiv23456"/> */}
                <center>
                  <h1>
                     Build Your Self
                </h1>
                  </center>
                <center><p>
Capture leads using TapitDabit and favorite, group, sort and manage them all from here. Start building your Video now                  </p></center>
                  <center>
                  <button class="btn btn-dark F23 f45" onClick={()=>setRoter123()}>Buy Now </button>
                  </center>
                <ClearIcon id="myDiv34567"  style={{color : "black",fontSize : "30px",position : "absolute" , right: "10%",top : "10%" , zIndex: "2"}} onClick={()=>setChanger1()}/>
            </div> 
       











{/* <br />
<br /> */}
      
                                          

                    {/* <center>
                      <h1 style={{color : "black"}}>Add Social Media</h1>
                    </center>
              <div className="container">
                <div className="row">
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-5" >
                              <img src={s1} alt="rt" className="social-icon-img"/>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-5" >
                              <img src={s1} alt="rt" className="social-icon-img"/>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-5" >
                              <img src={s1} alt="rt" className="social-icon-img"/>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-5" >
                              <img src={s1} alt="rt" className="social-icon-img"/>
                    </div>

                </div>
              </div> */}

      <div className="container" style={{margin : "30px auto" , width : "90%"}}>
        <div className="row justify-content-center">
          <div className="col-12">
            <h2 style={{color : "black" , padding:  "0px 10px 0px 10px"}} > <b>Link Store </b> </h2>
            <p style={{color : "grey" ,fontSize : "18px", padding:  "0px 10px"}} > <b>App links to your personal profile </b> </p>
            <h2 style={{color : "black",fontWeight : "200" , padding:  "10px 10px 0px 10px"}} > <b>Recommended </b> </h2>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(226 226 226)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf" onClick={()=>settochange(25)}  >
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={call1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Number</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(226 226 226)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf"  onClick={()=>settochange(24)}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={mail1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Email</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(226 226 226)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf"  onClick={()=>settochange(1)}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={in1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Instagram</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(226 226 226)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf"  onClick={()=>settochange(13)}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={we1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Website</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(226 226 226)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf"  onClick={()=>settochange(5)}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={ln1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>LinkedIn</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(226 226 226)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf"  onClick={()=>setContactCard()}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={cd1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Contact Card</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
         

        </div>
      </div>




      <div className="container" style={{margin : "30px auto" , width : "90%"}}>
        <div className="row justify-content-center">
          <div className="col-12">
            <h2 style={{color : "black",fontWeight : "200" , padding:  "10px 10px 0px 10px"}} > <b>Social Media </b> </h2>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(226 226 226)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf" onClick={()=>settochange(1)}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={in1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Instagram</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(226 226 226)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf" onClick={()=>settochange(2)} >
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={sc1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>SnapChat</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(226 226 226)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf" onClick={()=>settochange(3)} >
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={tk1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>TikTok</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(226 226 226)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf" onClick={()=>settochange(4)} >
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={fb1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Facebook</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(226 226 226)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf" onClick={()=>settochange(5)} >
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={ln1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>LinkedIn</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(226 226 226)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf" onClick={()=>settochange(6)} >
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={tw1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Twitter</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(226 226 226)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf" onClick={()=>settochange(7)} >
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={you1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Youtube</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(226 226 226)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf" onClick={()=>settochange(8)} >
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={tt1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Twitch</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(226 226 226)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf" onClick={()=>settochange(9)} >
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={pi1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Pinterest</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>

        </div>
      </div>





      <div className="container" style={{margin : "30px auto" , width : "90%"}}>
        <div className="row justify-content-center">
          <div className="col-12">
            <h2 style={{color : "black",fontWeight : "200" , padding:  "10px 10px 0px 10px"}} > <b>Content </b> </h2>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(226 226 226)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf"  onClick={()=>EmbedVideo()} >
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={vi1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Embed Video</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          

        </div>
      </div>
     
      <div className="container" style={{margin : "30px auto" , width : "90%"}}>
        <div className="row justify-content-center">
          <div className="col-12">
            <h2 style={{color : "black",fontWeight : "200" , padding:  "10px 10px 0px 10px"}} > <b>Contact Info </b> </h2>
          </div>
         
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(226 226 226)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf" onClick={()=>settochange(25)} >
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={call1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Number</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>

          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(226 226 226)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf"  onClick={()=>settochange(21)}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={mess1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Text</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(226 226 226)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf" onClick={()=>settochange(22)} >
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={wa1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>WhatsApp</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(226 226 226)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf"  onClick={()=>settochange(24)}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={mail1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Email</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(226 226 226)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf"   onClick={()=>settochange(99)}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={lo1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Location</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(226 226 226)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf"  onClick={()=>setContactCard()}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={cd1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Contact Card</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>

        </div>
      </div>








      <div className="container" style={{margin : "30px auto" , width : "90%"}}>
        <div className="row justify-content-center">
          <div className="col-12">
            <h2 style={{color : "black",fontWeight : "200" , padding:  "10px 10px 0px 10px"}} > <b>Payment </b> </h2>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(226 226 226)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf"  onClick={()=>settochange44(112)}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={bk1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Bank</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div> 
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(226 226 226)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf" onClick={()=>settochange44(111)} >
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={ep1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>EasyPaisa</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(226 226 226)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf" onClick={()=>settochange44(110)} >
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    {/* <img src={jc1} style={{width : "45px" , height: "39px",objectFit: "contain"}}  /> */}
                    <img src={jc1} style={{width : "53px" , height: "39px",objectFit: "fill"}}  />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>JazzCash</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(226 226 226)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf"  onClick={()=>settochange(11)}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={pp1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>PayPal</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(226 226 226)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf"  onClick={()=>settochange(10)}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={ca1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Cash App</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
        
          
          
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(226 226 226)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf" onClick={()=>settochange(12)} >
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={ve1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Venmo</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          

        </div>
      </div>




      <div className="container" style={{margin : "30px auto" , width : "90%",padding:"0px 0px 103px 0px"}}>
        <div className="row justify-content-center">
          <div className="col-12">
            <h2 style={{color : "black",fontWeight : "200" , padding:  "10px 10px 0px 10px"}} > <b>For Business </b> </h2>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(226 226 226)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf"  onClick={()=>settochange(13)}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={we1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Website</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(226 226 226)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf"  onClick={()=>settochange3(33)}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={cu1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Custom Link</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div style={{background :"rgb(226 226 226)", padding : "20px 20px 10px 20px", margin  :"10px 0px", borderRadius : "10px", height :"60px"}} className="dfdfdsf"  onClick={()=>settochange443(53)}>
                <div style={{display : "flex" , justifyContent : "space-between"}}>
                    
                    <div style={{display : 'flex' , flexDirection : "row",    marginTop: "-9px",width : "285px"}}>
                    <img src={lt1} style={{width : "45px" , height: "39px"}}  />
                    <p style={{color : "rgb(92, 92, 92)" , fontWeight  :"bold",    paddingTop: "6px",    paddingLeft: "13px" , maxWidth: "210px",overflow: "hidden"}}>Linktree</p>
                    </div>
                    <button style={{width : "50px",height:"40px" ,  marginTop: "-8px", borderRadius : "10px" , background : "rgb(198 198 203)" , color : "black"}} >Add</button>
                </div>
            </div>
          </div>
          
         
          

        </div>
      </div>









{/* 
              <section>
                    <div className="container mi2" style={{paddingTop :  "29px",margin : "50px auto" , width : "96%"}}>
                            <div className="row justify-content-center">
                                <div className="col-12 text-center">
                                <h2 style={{color : "black" , paddingTop :  "20px"}} > <b>Add Social Media </b> </h2>
                                  
                                    <center>
                                        <p style={{width : "320px" , border : "0.5px solid grey" }}></p>
                                    </center>
                                </div> 
                         
                            
                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5">
                                                <span onClick={()=>settochange(1)}><img src={a1} alt="" className="arrival-Photo111 arrival-P"/></span>
                                            </div>
                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5">
                                                <span onClick={()=>settochange(2)}><img src={a2} alt="" className="arrival-Photo111 arrival-P"/></span>
                                            </div>
                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5">
                                                <span onClick={()=>settochange(3)}><img src={a3} alt="" className="arrival-Photo111 arrival-P"/></span>
                                            </div>
                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5">
                                                <span onClick={()=>settochange(4)}><img src={a4} alt="" className="arrival-Photo111 arrival-P"/></span>
                                            </div>
                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5">
                                                <span onClick={()=>settochange(5)}><img src={a5} alt="" className="arrival-Photo111 arrival-P"/></span>
                                            </div>
                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5">
                                                <span onClick={()=>settochange(6)}><img src={a6} alt="" className="arrival-Photo111 arrival-P"/></span>
                                            </div>
                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5">
                                                <span onClick={()=>settochange(7)}><img src={a7} alt="" className="arrival-Photo111 arrival-P"/></span>
                                            </div>
                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5">
                                                <span onClick={()=>settochange(8)}><img src={a8} alt="" className="arrival-Photo111 arrival-P"/></span>
                                            </div>
                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5">
                                                <span onClick={()=>settochange(9)}><img src={a9} alt="" className="arrival-Photo111 arrival-P"/></span>
                                            </div>
                                     
                            </div>
                               
                            </div>
                </section>

                <center>
              <div class="col-xl-8 col-lg-8 col-md-8 col-sm-10
              " style={{margin : "40px 0px" }}>
                 
                 
               
              </div>
              
          </center>





          <center>
  
  <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12" style={{margin : "40px 0px"}}>
  <div class="px-1" style={{background : "rgb(245, 246, 246)", borderRadius : "10px"}}>
   
    <div style={{display : "flex" , flexDirection : "row" ,padding : "14px 7px"}}>
        
        <div class="px-1"  style={{display : "flex" , justifyContent : "center"}}>
              <img src={a11} alt="" className="yh4"  onClick={()=>EmbedVideo()}/>
        </div>
    </div>
     
  </div>
 

</div>
</center>











<section>
                    <div className="container" style={{margin : "70px auto" , width : "90%"}}>
                            <div className="row justify-content-center">
                                <div className="col-lg-12 text-center">
                                    <h2 style={{color : "black"}} > <b>Add Contact</b> </h2>
                                    <center>
                                        <p style={{width : "320px" , border : "0.5px solid grey" }}></p>
                                    </center>
                                    
                                </div> 
  
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style={{margin : "0px 0px"}}>
  <div class="px-1" style={{ borderRadius : "10px"}}>
   
    <div style={{display : "flex" ,justifyContent : "center",padding : "14px 17px" }}>
        
        <div class="px-1"  style={{display : "flex" , justifyContent : "center",background : "rgb(245, 246, 246)",padding : "14px 7px",}}>
              <img src={a27} alt="" width="100%" height="137px" onClick={()=>setContactCard()}/>
        </div>
    </div>
     
  </div>
 

</div>
                                
                                
          <center>
</center>
                             
                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5">
                                                <span onClick={()=>settochange(21)}><img src={a21} alt="" className="arrival-Photo111 arrival-P"/></span>
                                            </div>
                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5">
                                                <span onClick={()=>settochange(22)}><img src={a22} alt="" className="arrival-Photo111 arrival-P"/></span>
                                            </div>
                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5">
                                                <span  onClick={()=>settochange(99)}><img src={a23} alt="" className="arrival-Photo111 arrival-P"/></span>
                                            </div>
                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5">
                                                <span onClick={()=>settochange(24)}><img src={a24} alt="" className="arrival-Photo111 arrival-P"/></span>
                                            </div>
                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5">
                                                <span onClick={()=>settochange(25)}><img src={a25} alt="" className="arrival-Photo111 arrival-P"/></span>
                                            </div>
                                            
                                          
                                         
                            </div>
                               
                            </div>
                </section>




<section>
                    <div className="container" style={{margin : "70px auto" , width : "90%"}}>
                            <div className="row justify-content-center">
                                <div className="col-12 text-center">
                                    <h2 style={{color : "black"}} > <b>Add Payment</b> </h2>
                                  
                                    <center>
                                        <p style={{width : "320px" , border : "0.5px solid grey" }}></p>
                                    </center>
                                </div> 
                                
                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5">
                                                <span onClick={()=>settochange(10)}><img src={z1} alt="" className="arrival-Photo111 arrival-P"/></span>
                                            </div>
                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5">
                                                <span onClick={()=>settochange(11)}><img src={z2} alt="" className="arrival-Photo111 arrival-P"/></span>
                                            </div>
                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5">
                                                <span onClick={()=>settochange(12)}><img src={z3} alt="" className="arrival-Photo111 arrival-P"/></span>
                                            </div>
                                            
                                            
                                            
                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5">
                                                <span onClick={()=>settochange44(110)}><img src={k1} alt="" className="arrival-Photo111 arrival-P"/></span>
                                            </div>
                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5">
                                                <span onClick={()=>settochange44(111)}><img src={k4} alt="" className="arrival-Photo111 arrival-P"/></span>
                                            </div>
                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5">
                                                <span onClick={()=>settochange44(112)}><img src={k5} alt="" className="arrival-Photo111 arrival-P"/></span>
                                            </div>
                                           
                                           
                            </div>
                               
                            </div>
                </section>

              

<section>
                    <div className="container" style={{margin : "70px auto" , width : "90%"}}>
                            <div className="row justify-content-center">
                                <div className="col-12 text-center">
                                    <h2 style={{color : "black"}} > <b>Add More</b> </h2>
                                  
                                    <center>
                                        <p style={{width : "320px" , border : "0.5px solid grey" }}></p>
                                    </center>
                                </div> 
                                
                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5">
                                                <span onClick={()=>settochange(13)}><img src={c1} alt="" className="arrival-Photo111 arrival-P"/></span>
                                            </div>
                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5">
                                                <span  onClick={()=>settochange443(53)}><img src={c2} alt="" className="arrival-Photo111 arrival-P"/></span>
                                            </div>
                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5">
                                                <span onClick={()=>settochange3(33)}><img src={c3} alt="" className="arrival-Photo111 arrival-P"/></span>
                                            </div>
                                           
                                           
                            </div>
                               
                            </div>
                </section>

                <center>
              <div class="col-xl-6 col-lg-8 col-md-8 col-sm-10" style={{margin : "40px 0px" , display : "flex" , justifyContent :  "center "}}>
                  <div class="px-1" style={{background : "rgb(245, 246, 246)", borderRadius : "10px"}}>
                    
                    <button type="button" class="btn btn-dark" style={{padding : "10px 15px" ,border : "2px solid black",borderRadius : "10px" ,width : "250px",background : "BEC1V9"}} onClick={()=>props.history.push("/user/home1/"+User1._id)}>Save Profile  </button>
                  </div>
                 
               
              </div>
              
          </center> */}



















        {/* Back to top */}
        <div className="btn-back-to-top" id="myBtn">
          <span className="symbol-btn-back-to-top">
            <i className="zmdi zmdi-chevron-up" />
          </span>
        </div>
        {/* Modal1 */}
        <div className="wrap-modal1 js-modal1 p-t-60 p-b-20">
          <div className="overlay-modal1 js-hide-modal1" />
          <div className="container">
            <div className="bg0 p-t-60 p-b-30 p-lr-15-lg how-pos3-parent">
              <button className="how-pos3 hov3 trans-04 js-hide-modal1">
                <img src="images/icons/icon-close.png" alt="CLOSE" />
              </button>
              <div className="row">
                <div className="col-md-6 col-lg-7 p-b-30">
                  <div className="p-l-25 p-r-30 p-lr-0-lg">
                    <div className="wrap-slick3 flex-sb flex-w">
                      <div className="wrap-slick3-dots" />
                      <div className="wrap-slick3-arrows flex-sb-m flex-w" />
                      <div className="slick3 gallery-lb">
                        <div className="item-slick3" data-thumb="images/product-detail-01.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-01.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-01.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-02.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-02.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-02.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-03.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-03.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-03.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5 p-b-30">
                  <div className="p-r-50 p-t-5 p-lr-0-lg">
                    <h4 className="mtext-105 cl2 js-name-detail p-b-14">
                      Lightweight Jacket
                    </h4>
                    <span className="mtext-106 cl2">
                      $58.79
                    </span>
                    <p className="stext-102 cl3 p-t-23">
                      Nulla eget sem vitae eros pharetra viverra. Nam vitae luctus ligula. Mauris consequat ornare feugiat.
                    </p>
                    {/*  */}
                    <div className="p-t-33">
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Size
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Size S</option>
                              <option>Size M</option>
                              <option>Size L</option>
                              <option>Size XL</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Color
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Red</option>
                              <option>Blue</option>
                              <option>White</option>
                              <option>Grey</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-204 flex-w flex-m respon6-next">
                          <div className="wrap-num-product flex-w m-r-20 m-tb-10">
                            <div className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-minus" />
                            </div>
                            <input className="mtext-104 cl3 txt-center num-product" type="number" name="num-product" defaultValue={1} />
                            <div className="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-plus" />
                            </div>
                          </div>
                          <button className="flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04 js-addcart-detail">
                            Add to cart
                          </button>
                        </div>
                      </div>	
                    </div>
                    {/*  */}
                    <div className="flex-w flex-m p-l-100 p-t-40 respon7">
                      <div className="flex-m bor9 p-r-10 m-r-11">
                        <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 js-addwish-detail tooltip100" data-tooltip="Add to Wishlist">
                          <i className="zmdi zmdi-favorite" />
                        </a>
                      </div>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Facebook">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Twitter">
                        <i className="fa fa-twitter" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Google Plus">
                        <i className="fa fa-google-plus" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
 






        )
    
}

export default Home;