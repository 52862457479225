import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';

const Contact = (props)=>  {

    useEffect(() =>{

        window.scrollTo(0, 0)
    },[])


        return (
            <div>
                <div className="bg-light py-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-0"><Link to="/">Home</Link> <span className="mx-2 mb-0">/</span> <strong className="text-black">Shipping Policy</strong></div>
                        </div>
                    </div>
                </div>
                <div className="container" style={{width : "99% " ,padding : "80px 30px",fontWeight:"500",color:"black"}}>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div>
                                    <h2>TERMS OF USE – tapitdabit.com</h2>
                                    <h3>AGREEMENT TO TERMS</h3>
                                    <p>
 You agree, on behalf of yourself and any person or entity for whom you use the Site or purchase Products (as defined below), that by accessing the Site or creating an account on the Site, you (i) represent that you are eighteen (18) years of age or older and have the capacity to read and understand these Terms, (ii) represent and warrant that you have read and understood these Terms and our Privacy Policy and (iii) agree to be bound by all of these Terms. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.
 </p>
                                    <h3>USER REPRESENTATIONS</h3>
                                    <p>By using the Site, you represent and warrant that: (a) all registration information you submit will be true, accurate, current, and complete; (b) you will maintain the accuracy of such information and promptly update such registration information as necessary;  (c) you have the requisite authority and legal capacity to enter into these Terms of Use and you agree to comply with these Terms of Use;  (d) you are not a minor in the jurisdiction in which you reside; (e) you will not access the Site through automated or non-human means, whether through a bot, script or otherwise; (f) you will not use the Site for any illegal or unauthorized purpose; and (g) your use of the Site will not violate any applicable law or regulation.
</p>
<p>If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Site (or any portion thereof).
</p>
                                    <h3>PRODUCTS</h3>
                                    <p>We make every effort to display as accurately as possible the colors, features, specifications, and details of the products available for sale on the Site from time to time (the “Products”). However, we do not guarantee that the colors, features, specifications, and details of the Products will be accurate, complete, reliable, current, or free of other errors, and your electronic display may not accurately reflect the actual colors and details of the Products. All Products are subject to availability, and we cannot guarantee that items will be in stock. We reserve the right to discontinue any Products at any time for any reason. Prices for all Products are subject to change.
</p>
                                    {/* <p>In the event of COD orders, it must be done before receiving the package, at the time of delivery. The package cannot be opened and checked during delivery. */}
{/* </p> */}
                                    <h2>RETURN POLICY </h2>
                                    <p>All sales are final and no refund will be issued except where prohibited by law.</p>
                                    <h3>MODIFICATIONS AND INTERRUPTIONS </h3>
                                    <p>We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Site. We also reserve the right to modify or discontinue all or part of the Site or the Products without notice at any time. We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Site or the Products.</p>
                                    <h3>CORRECTIONS</h3>
                                    <p>There may be information on the Site that contains typographical errors, inaccuracies, or omissions that may relate to the Products, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Site at any time, without prior notice.</p>
                                    <h3>USER DATA</h3>
                                    <p>We will maintain certain data that you transmit to the Site for the purpose of managing the performance of the Site, as well as data relating to your use of the Site. Although we perform regular routine backups of data, you are solely responsible for all data that you transmit or that relates to any activity you have undertaken using the Site. You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby waive any right of action against us arising from any such loss or corruption of such data.
</p>
                                    <h3>CONTACT US</h3>
                                    <p>In order to resolve a complaint regarding the Site or to receive further information regarding use of the Site, please contact us at: tapitdabit@gmail.com</p>
                                    
                                    
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        )
    
}


export default Contact;