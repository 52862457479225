import React, { useState ,useEffect} from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
// import p22 from "./../assets/images/p2.png"
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LanguageIcon from '@material-ui/icons/Language';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const Contact = (props)=>  {
    const [name, setname] = useState("") 
    const [lname, setlname] = useState("") 
    const [email, setemail] = useState("") 
    const [subject, setsubject] = useState("") 
    const [Message, setMessage] = useState("") 
    const [User1, setUser1] = useState({}) 
    const [User2, setUser2] = useState({}) 
    const [Sale, setSale] = useState(0) 
    const [Earn, setEarn] = useState(0) 
    const [Dis12, setDis12] = useState(false) 


    useEffect(() =>{
        if(! JSON.parse(localStorage.getItem("Ambassador User"))){
                props.history.push("/ambassador-login")
              }
              else{
                // setUser1(JSON.parse(localStorage.getItem("User")))
          
            //     this.setState({
            //         email: "",
            // })
            // window.location.reload(true)
            window.scrollTo(0, 0)
            document.getElementById("Close-the-header").style.display = "none"

            setUser1(JSON.parse(localStorage.getItem("Ambassador User")))
            fetch("https://tapitdabit-backend.vercel.app/allUser111/"+JSON.parse(localStorage.getItem("Ambassador User"))._id,{
                method: "GET",
                headers :  {
                    "Content-Type" : "application/json" , 
 } ,
})
.then(res5=>res5.json())
.then(res6=>{
    localStorage.setItem("Ambassador User" ,JSON.stringify(res6)) 
    setUser1(res6)
})
            fetch("https://tapitdabit-backend.vercel.app/AllUserCheckoutData",{
                method: "GET",
                headers :  {
                "Content-Type" : "application/json" , 
            } ,
            })
            .then(res7=>res7.json())
            .then(res8=>{
                console.log(res8)
                let h = []
                for (let i = 0; i < res8.length; i++) {
                    console.log(res8[i],res8[i].AmbaCoup , JSON.parse(localStorage.getItem("Ambassador User")).name+""+JSON.parse(localStorage.getItem("Ambassador User")).Num)
                    if(res8[i].AmbaCoup === JSON.parse(localStorage.getItem("Ambassador User")).name+""+JSON.parse(localStorage.getItem("Ambassador User")).Num){
                        h.push(res8[i])
                    }
                    
                }
                setUser2(h)
                let to = 0
                let to1 = 0
                for (let i = 0; i < h.length; i++) {
                    if(h[i].AfterDiscount){
                        to = to + h[i].AfterDiscount
                        if( h[i].discount.AmbaPercen){
                            to1 =  to1 + ( ((h[i].AfterDiscount * h[i].discount.AmbaPercen) / 100))
                        }
                    }

                }
                
                setSale(to)
                setEarn(to1)
            })
    }

    },[])

    const Submitdata = (e)=>{
        e.preventDefault()

        fetch("/touchIn",{
            method: 'POST' , 
            headers :  {
              "Content-Type" : "application/json" , 
            } , 
            body : JSON.stringify({
              name  ,
              lname  ,
              email  ,
              subject  ,
              Message  ,
            })
          })
          .then((res)=>res.json())
          .then((res2)  =>{
              console.log(res2)
              if (res2){
                swal("SucessFully Send "  )
                setname ("") 
                setlname("") 
                    setemail("") 
                    setsubject("") 
                    setMessage("") 
                // localStorage.setItem("User" , JSON.stringify("Yes") )
                    // props.history.push("/login")
              }
              else if (res2.Error){
                swal(res2.Error )
              }
              else{
                swal("Plz Try Again !"  )
              }
            // console.log(res2)
          })
          .catch((err)=>console.log(err))
        
    }

    const setProfile1 = ()=>{
        localStorage.removeItem("Ambassador User")
        // localStorage.removeItem("User")

        window.location.href = "https://www.tapitdabit.com/ambassador-login"
    }


        return (
            <div>
                {/* <div className="bg-light py-3 theporper">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-0"><Link to="/ambassador-dashboard">Home</Link> <span className="mx-2 mb-0">/</span> <strong className="text-black">Contact</strong></div>
                        </div>
                    </div>
                </div> */}
                <div className="site-section">
                    <div className="container-fuild" style={{margin :  "auto" , width  : '90%'}}>
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="h3 mb-3 text-black">Analysis</h2>
                            </div>

                            <div className="col-md-12">

                                <form onSubmit={(e)=>Submitdata(e)}>

                                    <div className="border" style={{padding : "10px 10px"}}>
                            {/* <div style={{borderBottom :  "1px solid black"}} className="col-md-12 mb-2"><Link to="/ambassador-dashboard">Home</Link> <span className="mx-2 mb-0" style={{paddingLeft : "30px", paddingBottom : "10px"}}>|</span><Link to="/ambassador-dashboard-Analytics">Analytics</Link> <span className="mx-2 mb-0" style={{paddingLeft : "30px", paddingBottom : "10px"}}>|</span> <Link to="/ambassador-dashboard-Payments">Payments</Link> <span className="mx-2 mb-0" style={{paddingLeft : "30px", paddingBottom : "10px"}}>|</span>  <Link to="/ambassador-dashboard-Settings">Settings</Link><span className="mx-2 mb-0" style={{paddingLeft : "30px", paddingBottom : "10px"}}>|</span>  <Link onClick={()=>setProfile1()}>Logout</Link></div> */}

                            <div  style={{border :  "1px solid black"}} className="col-md-12 uiuiiuuhi">
                            {/* <div className="col-md-12 mb-0"> <span className="mx-2 mb-0">/</span> <strong className="text-black">Contact</strong></div> */}
                    <li onClick={()=> setDis12(! Dis12)}>Analytics  <span> <KeyboardArrowDownIcon style={{position : "absolute" , right : "10px"}} /></span> </li>
                    </div>
                     {Dis12 ?

                   <ul   style={{border :  "1px solid black", padding : "10px 0px 0px 0px"}}>
                    <li  style={{ padding : "5px 14px",borderBottom :"1px solid black",margin : "0px"}}><Link to="/ambassador-dashboard" style={{color : "black", fontWeight : "bold"}}>Home</Link></li>
                    <li  style={{ padding : "5px 14px",borderBottom :"1px solid black",margin : "0px"}}><Link to="/ambassador-dashboard-Analytics" style={{color : "black", fontWeight : "bold"}}>Analytics</Link></li>
                    <li  style={{ padding : "5px 14px",borderBottom :"1px solid black",margin : "0px"}}> <Link to="/ambassador-dashboard-Payments" style={{color : "black", fontWeight : "bold"}}>Payments</Link></li>
                    <li  style={{ padding : "5px 14px",borderBottom :"1px solid black",margin : "0px"}}> <Link to="/ambassador-dashboard-Settings" style={{color : "black", fontWeight : "bold"}}>Settings</Link></li>
                    <li  style={{ padding : "5px 14px",borderBottom :"1px solid black",margin : "0px"}}> <Link onClick={()=>setProfile1()} style={{color : "black", fontWeight : "bold"}}>Logout</Link></li>
                   </ul>
                     : ""
                     }  
                            <div style={{borderBottom :  "1px solid black"}} className="col-md-12 mb- theporper"><Link to="/ambassador-dashboard">Home</Link> <span className="mx-2 mb-0" style={{paddingLeft : "30px", paddingBottom : "10px"}}>|</span><Link to="/ambassador-dashboard-Analytics">Analytics</Link> <span className="mx-2 mb-0" style={{paddingLeft : "30px", paddingBottom : "10px"}}>|</span> <Link to="/ambassador-dashboard-Payments">Payments</Link> <span className="mx-2 mb-0" style={{paddingLeft : "30px", paddingBottom : "10px"}}>|</span>  <Link to="/ambassador-dashboard-Settings">Settings</Link><span className="mx-2 mb-0" style={{paddingLeft : "30px", paddingBottom : "10px"}}>|</span>  <Link onClick={()=>setProfile1()}>Logout</Link></div>
                          
                          
<br />
                                       
           
            <div style={{margin : "20px 0px"}}>
                    {/* <div style={{display : 'flex' , flexDirection  :"row"}}> */}
                    <div className="form-group row">
                        <div className="col-md-12">
                            <h2 style={{color : "black" , fontWeight : "300"}}>Traffic Analysis
</h2>

                        </div>
                    </div>
                    <div className="form-group row">
                        
                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <div class="card twoCards">
                      <div class="card-body">
                        <div  style={{display :  "flex" , justifyContent : "space-between" , alignItems : "center"}}>
                        <p class="card-title changeTitle" style={{fontSize : "20px"  , color   : "black"}}>{User1.count} </p>
                        </div>
                          <div style={{display :  "flex" , justifyContent : "flex-start" , alignItems : "center"}}>
                              <p>Visits </p>
                          </div>
                      </div>
                    </div>
                  </div>
                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <div class="card twoCards">
                      <div class="card-body">
                        <div  style={{display :  "flex" , justifyContent : "space-between" , alignItems : "center"}}>
                        <p class="card-title changeTitle" style={{fontSize : "20px"  , color   : "black"}}>{User2.length} </p>
                        </div>
                          <div style={{display :  "flex" , justifyContent : "flex-start" , alignItems : "center"}}>
                              <p>Orders </p>
                          </div>
                      </div>
                    </div>
                  </div>
                        {/* <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <div class="card twoCards">
                      <div class="card-body">
                        <div  style={{display :  "flex" , justifyContent : "space-between" , alignItems : "center"}}>
                        <p class="card-title changeTitle" style={{fontSize : "20px"  , color   : "black"}}>0 </p>
                        </div>
                          <div style={{display :  "flex" , justifyContent : "flex-start" , alignItems : "center"}}>
                              <p>Conversions </p>
                          </div>
                      </div>
                    </div>
                  </div> */}
                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <div class="card twoCards">
                      <div class="card-body">
                        <div  style={{display :  "flex" , justifyContent : "space-between" , alignItems : "center"}}>
                        <p class="card-title changeTitle" style={{fontSize : "20px"  , color   : "black"}}>Rs : {Sale} </p>
                        </div>
                          <div style={{display :  "flex" , justifyContent : "flex-start" , alignItems : "center"}}>
                              <p>Sales </p>
                          </div>
                      </div>
                    </div>
                  </div>
                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <div class="card twoCards">
                      <div class="card-body">
                        <div  style={{display :  "flex" , justifyContent : "space-between" , alignItems : "center"}}>
                        <p class="card-title changeTitle" style={{fontSize : "20px"  , color   : "black"}}>Rs : {Earn} </p>
                        </div>
                          <div style={{display :  "flex" , justifyContent : "flex-start" , alignItems : "center"}}>
                              <p>Commisions </p>
                          </div>
                      </div>
                    </div>
                  </div>
                        



                    </div>
            </div>







                                    </div>
                                </form>
                            </div>
                            {/* <div className="col-md-5 ml-auto">
                                <div className="p-4 border mb-3">
                                    <span className="d-block text-primary h6 text-uppercase">New York</span>
                                    <p className="mb-0">203 Fake St. Mountain View, San Francisco, California, USA</p>
                                </div>
                                <div className="p-4 border mb-3">
                                    <span className="d-block text-primary h6 text-uppercase">London</span>
                                    <p className="mb-0">203 Fake St. Mountain View, San Francisco, California, USA</p>
                                </div>
                                <div className="p-4 border mb-3">
                                    <span className="d-block text-primary h6 text-uppercase">Canada</span>
                                    <p className="mb-0">203 Fake St. Mountain View, San Francisco, California, USA</p>
                                </div>

                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    
}


export default Contact;