import React, { useState ,useEffect} from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
// import FacebookIcon from '@material-ui/icons/Facebook';
// import InstagramIcon from '@material-ui/icons/Instagram';
// import LanguageIcon from '@material-ui/icons/Language';
// import YouTubeIcon from '@material-ui/icons/YouTube';
// import TwitterIcon from '@material-ui/icons/Twitter';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const Contact = (props)=>  {
    const [name, setname] = useState("") 
    const [lname, setlname] = useState("") 
    const [email, setemail] = useState("") 
    const [subject, setsubject] = useState("") 
    const [Message, setMessage] = useState("") 
    const [User1, setUser1] = useState({}) 
    const [User2, setUser2] = useState({}) 
    const [Paid, setPaid] = useState(0) 
    const [Pending, setPending] = useState(0) 
    const [Last, setLast] = useState([]) 
    // const [Pending, setPending] = useState(0) 
    const [Dis12, setDis12] = useState(false) 


    useEffect(() =>{
        if(! JSON.parse(localStorage.getItem("Ambassador User"))){
                props.history.push("/ambassador-login")
              }
              else{
                // setUser1(JSON.parse(localStorage.getItem("User")))
          
            //     this.setState({
            //         email: "",
            // })
            // window.location.reload(true)
            window.scrollTo(0, 0)
            document.getElementById("Close-the-header").style.display = "none"

            setUser1(JSON.parse(localStorage.getItem("Ambassador User")))
            fetch("https://tapitdabit-backend.vercel.app/allUser111/"+JSON.parse(localStorage.getItem("Ambassador User"))._id,{
                method: "GET",
                headers :  {
                    "Content-Type" : "application/json" , 
 } ,
})
.then(res5=>res5.json())
.then(res6=>{
    localStorage.setItem("Ambassador User" ,JSON.stringify(res6)) 
    setUser1(res6)

    fetch("https://tapitdabit-backend.vercel.app/all-Bank",{
        method: "GET",
        headers :  {
        "Content-Type" : "application/json" , 
    } ,
    })
    .then(res7=>res7.json())
    .then(res8=>{
        
        
        fetch("https://tapitdabit-backend.vercel.app/all-Mobile1",{
        method: "GET",
        headers :  {
        "Content-Type" : "application/json" , 
    } ,
    })
    .then(res17=>res17.json())
    .then(res81=>{
        console.log(res8,res81)
        let A = []
        res8.map((res10)=>{
            if(res10.id === JSON.parse(localStorage.getItem("Ambassador User"))._id){
                A.push(res10)
            }
        })

        let A1= []
        res81.map((res101)=>{
            if(res101.id === JSON.parse(localStorage.getItem("Ambassador User"))._id){
                A1.push(res101)
            }
        })
        let A2 = [...A,...A1]
        console.log(A2)
        let A3 = 0
        let A4 = 0
        let A5 = []
        A2.map((res1)=>{
            if(res1.Status){
                A5.push(res1)
                A3 = A3 + res1.Amount
            }
            else{
                A4 = A4 + res1.Amount
            }
        })
        // setLast(Math.max(
        //     A5.map(element => {
        //       return element.Date
        //     }),
        //   ))
        console.log(A5)
        // let A7 = []
        // A5.map((res0)=>{
        //     A7.push(res0.Date)
        // })
        // let A8 = Math.max(...A7) 

        //   const maxDate = 
        //     Math.max(
        //       ...A5.map(element => {
        //         return new Date(element.Date);
        //       }),
        //     )
          
        //   let A6 = []
        //   A5.map((res5)=>{
        //     if(res5.Date === A8){
        //         A6.push(res5)
        //     }
        //   })
          // 👇️ Thu Jul 17 2022
        //   console.log(A8,A6,A7,Math.max(A7)  );
        //  let d = new Date(
        //     Math.max(
        //       ...[
        //         new Date('2022-11-14T04:55:31.820Z'),
        //         new Date('2022-09-24T07:25:31.820Z'),
        //       ],
        //     ),
        //   )
        //   console.log(new Date(d)  , new Date("2022-11-14T04:55:31.820Z"))
        //   console.log(new Date(Math.max(...A5.map(element => {
        //     return element.Date
        //   }))))
        if(A5.length !== 0){
            setLast([A5[A5.length -1]])
        }
        else{
            setLast([])
        }
        setPaid(A3)
        setPending(A4)
    })
   
    })
})
           
    }

    },[])

    const Submitdata = (e)=>{
        e.preventDefault()

        fetch("/touchIn",{
            method: 'POST' , 
            headers :  {
              "Content-Type" : "application/json" , 
            } , 
            body : JSON.stringify({
              name  ,
              lname  ,
              email  ,
              subject  ,
              Message  ,
            })
          })
          .then((res)=>res.json())
          .then((res2)  =>{
              console.log(res2)
              if (res2){
                swal("SucessFully Send "  )
                setname ("") 
                setlname("") 
                    setemail("") 
                    setsubject("") 
                    setMessage("") 
                // localStorage.setItem("User" , JSON.stringify("Yes") )
                    // props.history.push("/login")
              }
              else if (res2.Error){
                swal(res2.Error )
              }
              else{
                swal("Plz Try Again !"  )
              }
            // console.log(res2)
          })
          .catch((err)=>console.log(err))
        
    }

    const setProfile1 = ()=>{
        localStorage.removeItem("Ambassador User")
        // localStorage.removeItem("User")
        window.location.href = "https://www.tapitdabit.com/ambassador-login"
    }


        return (
            <div>
                {/* <div className="bg-light py-3 theporper">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-0"><Link to="/ambassador-dashboard">Home</Link> <span className="mx-2 mb-0">/</span> <strong className="text-black">Contact</strong></div>
                        </div>
                    </div>
                </div> */}
                <div className="site-section">
                    <div className="container-fuild" style={{margin :  "auto" , width  : '90%'}}>
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="h3 mb-3 text-black">Payment</h2>
                            </div>

                            <div className="col-md-12">

                                <form onSubmit={(e)=>Submitdata(e)}>

                                    <div className="border" style={{padding : "10px 10px"}}>
                            {/* <div style={{borderBottom :  "1px solid black"}} className="col-md-12 mb-2"><Link to="/ambassador-dashboard">Home</Link> <span className="mx-2 mb-0" style={{paddingLeft : "30px", paddingBottom : "10px"}}>|</span><Link to="/ambassador-dashboard-Analytics">Analytics</Link> <span className="mx-2 mb-0" style={{paddingLeft : "30px", paddingBottom : "10px"}}>|</span> <Link to="/ambassador-dashboard-Payments">Payments</Link> <span className="mx-2 mb-0" style={{paddingLeft : "30px", paddingBottom : "10px"}}>|</span>  <Link to="/ambassador-dashboard-Settings">Settings</Link><span className="mx-2 mb-0" style={{paddingLeft : "30px", paddingBottom : "10px"}}>|</span>  <Link onClick={()=>setProfile1()}>Logout</Link></div> */}

                            <div  style={{border :  "1px solid black"}} className="col-md-12 uiuiiuuhi">
                            {/* <div className="col-md-12 mb-0"> <span className="mx-2 mb-0">/</span> <strong className="text-black">Contact</strong></div> */}
                    <li onClick={()=> setDis12(! Dis12)}>Payments  <span> <KeyboardArrowDownIcon style={{position : "absolute" , right : "10px"}} /></span> </li>
                    </div>
                     {Dis12 ?

                   <ul   style={{border :  "1px solid black", padding : "10px 0px 0px 0px"}}>
                    <li  style={{ padding : "5px 14px",borderBottom :"1px solid black",margin : "0px"}}><Link to="/ambassador-dashboard"  style={{color : "black", fontWeight : "bold"}}>Home</Link></li>
                    <li  style={{ padding : "5px 14px",borderBottom :"1px solid black",margin : "0px"}}><Link to="/ambassador-dashboard-Analytics"  style={{color : "black", fontWeight : "bold"}}>Analytics</Link></li>
                    <li  style={{ padding : "5px 14px",borderBottom :"1px solid black",margin : "0px"}}> <Link to="/ambassador-dashboard-Payments"  style={{color : "black", fontWeight : "bold"}}>Payments</Link></li>
                    <li  style={{ padding : "5px 14px",borderBottom :"1px solid black",margin : "0px"}}> <Link to="/ambassador-dashboard-Settings"  style={{color : "black", fontWeight : "bold"}}>Settings</Link></li>
                    <li  style={{ padding : "5px 14px",borderBottom :"1px solid black",margin : "0px"}}> <Link onClick={()=>setProfile1()} style={{color : "black", fontWeight : "bold"}}>Logout</Link></li>
                   </ul>
                     : ""
                     }  
                            <div style={{borderBottom :  "1px solid black"}} className="col-md-12 mb- theporper"><Link to="/ambassador-dashboard">Home</Link> <span className="mx-2 mb-0" style={{paddingLeft : "30px", paddingBottom : "10px"}}>|</span><Link to="/ambassador-dashboard-Analytics">Analytics</Link> <span className="mx-2 mb-0" style={{paddingLeft : "30px", paddingBottom : "10px"}}>|</span> <Link to="/ambassador-dashboard-Payments">Payments</Link> <span className="mx-2 mb-0" style={{paddingLeft : "30px", paddingBottom : "10px"}}>|</span>  <Link to="/ambassador-dashboard-Settings">Settings</Link><span className="mx-2 mb-0" style={{paddingLeft : "30px", paddingBottom : "10px"}}>|</span>  <Link onClick={()=>setProfile1()}>Logout</Link></div>
                         <br />                                       
          


            <div style={{margin : "20px 0px"}}>
                    {/* <div style={{display : 'flex' , flexDirection  :"row"}}> */}
                    {/* <div className="form-group row">
                        <div className="col-md-12">
                            <h2 style={{color : "black" , fontWeight : "300"}}>Summary</h2>

                        </div>
                    </div> */}
                    <div className="form-group row">
                        
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                    <div class="card twoCards">
                      <div class="card-body">
                        <div  style={{display :  "flex" , justifyContent : "space-between" , alignItems : "center"}}>
                        <p class="card-title changeTitle" style={{fontSize : "20px"  , color   : "black"}}>Rs  : {Paid} </p>
                        </div>
                          <div style={{display :  "flex" , justifyContent : "flex-start" , alignItems : "center"}}>
                              <p>Amount Paid
 </p>
                          </div>
                      </div>
                    </div>
                  </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                    <div class="card twoCards">
                      <div class="card-body">
                        <div  style={{display :  "flex" , justifyContent : "space-between" , alignItems : "center"}}>
                        <p class="card-title changeTitle" style={{fontSize : "20px"  , color   : "black"}}>Rs  : {Pending} </p>
                        </div>
                          <div style={{display :  "flex" , justifyContent : "flex-start" , alignItems : "center"}}>
                              <p>Amount Pending
 </p>
                          </div>
                      </div>
                    </div>
                  </div>
                     
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                    <div class="card twoCards">
                      <div class="card-body">
                        <div  style={{display :  "flex" , justifyContent : "space-between" , alignItems : "center"}}>
                        <p class="card-title changeTitle" style={{fontSize : "20px"  , color   : "black"}}>Rs  : {Last !== undefined && Last.length > 0  && Last[0].Amount  ? Last[0].Amount : 0} </p>
                        </div>
                          <div style={{display :  "flex" , justifyContent : "flex-start" , alignItems : "center"}}>
                              <p>Last Payment
 </p>
                          </div>
                      </div>
                    </div>
                  </div>
                   



                    </div>
            </div>

<center>

<button className="button11" style={{background  : "grey"}} onClick={()=>props.history.push("/ambassador-dashboard-withdraw")}>
Withdraw
</button>
</center>



                                    </div>
                                </form>
                            </div>
                            {/* <div className="col-md-5 ml-auto">
                                <div className="p-4 border mb-3">
                                    <span className="d-block text-primary h6 text-uppercase">New York</span>
                                    <p className="mb-0">203 Fake St. Mountain View, San Francisco, California, USA</p>
                                </div>
                                <div className="p-4 border mb-3">
                                    <span className="d-block text-primary h6 text-uppercase">London</span>
                                    <p className="mb-0">203 Fake St. Mountain View, San Francisco, California, USA</p>
                                </div>
                                <div className="p-4 border mb-3">
                                    <span className="d-block text-primary h6 text-uppercase">Canada</span>
                                    <p className="mb-0">203 Fake St. Mountain View, San Francisco, California, USA</p>
                                </div>

                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    
}


export default Contact;