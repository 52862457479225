import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"
import swal from 'sweetalert';
// import $ from 'jquery';
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ppp11 from "./../assets/images/ba1.jpeg"


import "react-multi-carousel/lib/styles.css";
import "react-multi-carousel/lib/styles.css";
import "./../assets/css/main.css"
import "./../assets/css/util.css"
import "./../assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css"

// import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
// import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
// import LocalAtmIcon from '@material-ui/icons/LocalAtm';
// import DashboardIcon from '@material-ui/icons/Dashboard';
// // import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';

// import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
// import AssessmentIcon from '@material-ui/icons/Assessment';
// import SortIcon from '@material-ui/icons/Sort';


// import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"

import "./../assets/vendor/css-hamburgers/hamburgers.min.css"

import "./../assets/fonts/iconic/css/material-design-iconic-font.min.css"
import "./../assets/vendor/bootstrap/css/bootstrap.min.css"
import "./../assets/vendor/select2/select2.min.css"
import "./../assets/vendor/slick/slick.css"
import "./../assets/vendor/perfect-scrollbar/perfect-scrollbar.css"

import z1 from "./../assets/images/im/z1.png"


import ln1 from "./../assets/images/svg/social/f linkedin.svg"
import tw1 from "./../assets/images/svg/social/f twitter.svg"
import in1 from "./../assets/images/svg/social/f instagram.svg"
import pi1 from "./../assets/images/svg/social/f pinterest.svg"
import tt1 from "./../assets/images/svg/social/twitch (1).svg"
import tk1 from "./../assets/images/svg/social/tiktok.svg"
import you1 from "./../assets/images/svg/social/youtube (2).svg"
import sc1 from "./../assets/images/svg/social/snapchat.svg"
import fb1 from "./../assets/images/svg/social/f facebook.svg"



import ca1 from "./../assets/images/svg/Payments/cashapp.svg"

import ve1 from "./../assets/images/svg/Payments/venmo.svg"
import pp1 from "./../assets/images/svg/Payments/paypal (1).svg"



import lt1 from "./../assets/images/svg/MORE/linktree-2.svg"
import vi1 from "./../assets/images/svg/MORE/video.svg"
import we1 from "./../assets/images/svg/MORE/WEBSITE.svg"
// import ve1 from "./../assets/images/svg/MORE/custom link.svg"
// import ve1 from "./../assets/images/svg/MORE/custom link.svg"


import call1 from "./../assets/images/svg/cotact info/call.svg"
import cd1 from "./../assets/images/svg/cotact info/ccard.svg"
import lo1 from "./../assets/images/svg/cotact info/location.svg"
import mail1 from "./../assets/images/svg/cotact info/mail.svg"
import wa1 from "./../assets/images/svg/cotact info/whatapps.svg"
import mess1 from "./../assets/images/svg/cotact info/message (2).svg"




const Home = ( props ) => {
    const [myRef,setRef] = useState( React.createRef())
    const [scrollTop1,setscrollTop] = useState(0)
    const [counterforlink,setcounterforlink] = useState([])
    const [namelink,setnamelink] = useState([])
    const [linklink,setlinklink] = useState([])
    const [counterforlinklink,setcounterforlinklink] = useState([])
    const [namelinklink,setnamelinklink] = useState([])
    const [linklinklink,setlinklinklink] = useState([])

    const [User1 ,  setUser1 ] = useState({})
 
    const [directOffDis ,  setdirectOffDis ] = useState(false)
    const [directOff ,  setdirectOff ] = useState(false)

    const [ConnectedUser, setConnectedUser] = useState([])

    useEffect(() =>{
if(! JSON.parse(localStorage.getItem("User"))){
        props.match.history.push("/login")
      }
      else{
        // setUser1(JSON.parse(localStorage.getItem("User")))
  
    //     this.setState({
    //         email: "",
    // })
    window.scrollTo(0, 0)
    localStorage.setItem("VenderWorkingThose" ,JSON.stringify(123)) 


// let A1 = JSON.parse(localStorage.getItem("User"))
  fetch("https://tapitdabit-backend.vercel.app/allUser/"+JSON.parse(localStorage.getItem("User"))._id,{
    method: "GET",
     headers :  {
     "Content-Type" : "application/json" , 
 } ,
})
.then(res5=>res5.json())
.then(res6=>{
    console.log(res6)

  localStorage.removeItem("User")
  const data = {
    ...res6 , All : [...res6.Social,...res6.Contact]
  }
  localStorage.setItem("User" ,JSON.stringify(data)) 

      setUser1(data)
     
      let arr = []
      let arr10 = []
      if(res6.DisPersonal){

  res6.Counter.map((res,t)=>{
      arr.push(res.name)
      // arr.push({name : res.name , link : res.link ,photo :  res.photo})
  })
  console.log(arr10)
  let arr1  =new Set(arr)
  // let arr11  =new Set(arr10)
  let arr2 = [...arr1]
  const Filter = res6.Counter.filter((res1,u)=>{
      return res1.name === arr2[u]
      // arr.push({name : res.name , link : res.link ,photo :  res.photo})
  })
  let aa1  =new Set(res6.Counter)

  console.log(Filter,aa1)
  // let arr22 = [...arr11]
  console.log( [...arr1])
  let arr56 = []
  for (let i = 0; i < arr2.length; i++) {
      let a = 0
      for (let j = 0; j < arr.length; j++) {
          if (arr[j] === arr2[i]) {
              a += 1

          }
          
        }
        
        arr56.push(
            a
            )
            a= 0
      
  }
  

  const key = 'name';



  const arrayUniqueByKey = [...new Map(res6.Counter.map(item =>
    [item[key], item])).values()];





    console.log(arrayUniqueByKey)





    setnamelink([...arr1])
    setlinklink(arrayUniqueByKey)
    console.log(arr56,arr1)
    
    setcounterforlink(arr56)

  }
  

else{
  


  let arr12 = []
  let arr101 = []
  res6.Counter1.map((res,t)=>{
      arr12.push(res.name)
      // arr.push({name : res.name , link : res.link ,photo :  res.photo})
  })
  // console.log(arr101)
  let arr16  =new Set(arr12)
  // let arr11  =new Set(arr10)
  let arr21 = [...arr16]
  const Filter1 = res6.Counter1.filter((res1,u)=>{
      return res1.name === arr21[u]
      // arr.push({name : res.name , link : res.link ,photo :  res.photo})
  })
  let aa12  =new Set(res6.Counter1)

  // console.log(Filter1,aa12)
  // let arr22 = [...arr11]
  // console.log( [...arr16])
  console.log( arr21,arr)
  let arr565 = []
  for (let i = 0; i < arr21.length; i++) {
      let a = 0
      for (let j = 0; j < arr12.length; j++) {
        console.log(arr12[j] === arr21[i])
          if (arr12[j] === arr21[i]) {
              a += 1

          }
          
        }
        
        arr565.push(
            a
            )
            a= 0
      
  }
  

  const key1 = 'name';



  const arrayUniqueByKey1 = [...new Map(res6.Counter1.map(item =>
    [item[key1], item])).values()];





    console.log([...arr16],arrayUniqueByKey1,arr565)
    // console.log(arrayUniqueByKey1,arrayUniqueByKey)



  setnamelinklink([...arr16])
  setlinklinklink(arrayUniqueByKey1)
  // console.log(arr56,arr1)
  
  setcounterforlinklink(arr565)



}









})




}
fetch("https://tapitdabit-backend.vercel.app/ConnectedOther",{
  method: "GET",
  headers :  {
      "Content-Type" : "application/json" , 
  } 
})
.then((res13)=>res13.json())
.then((res444)=>{
    console.log(res444,JSON.parse(localStorage.getItem("User"))._id)
    const Fil = res444.filter((res)=>res.id === JSON.parse(localStorage.getItem("User"))._id)
    setConnectedUser(Fil)
})
    // console.log(scrollTop1)



    return () => {
      document.getElementById("get1").classList.remove("is-active");
      document.getElementById("get2").style.display = "none";
    }




    },[])


    // const setChange = () =>{
    //     if(document.getElementById("get2").style.display === "none"){
    //         document.getElementById("get1").classList.add("is-active");
    //         document.getElementById("get2").style.display = "block";
    //     }
    //     else{
    //         document.getElementById("get1").classList.remove("is-active");
    //         document.getElementById("get2").style.display = "none";
    //     }
    // }

    // const setChange1 = (e) =>{
    //   if(e === 1 ){
    //     // document.getElementById("signUp").classList.add("right-panel-active");
    //     // document.getElementById("signIn").classList.remove("right-panel-active");
    //     document.getElementById("container1").classList.add("right-panel-active")
    //   }
    //   else{
    //     document.getElementById("container1").classList.remove("right-panel-active")
    //   }
    // }


    // const setRouting1 = (e)=>{
    //   if(e === 1){
    //     if(JSON.parse(localStorage.getItem("User")).DisPersonal)
    //     props.history.push("/user/home1/"+User1._id)
    //     else 
    //     props.history.push("/user/bussiness/home1/"+User1._id)
    //   }
    //   else if(e === 4){
    //     props.history.push("/user/editProfile/sort-delete/"+User1.uid)
    //   }
    //   else if(e === 3){
    //     props.history.push("/user/analytics/"+User1._id)
    //   }
    //   else if(e === 2){
    //     props.history.push("/user/connect/myprofile/"+User1.uid)
    //   }
    //   else{
    
    //   }
    // }
        

// const postData = (e) =>{
//   e.preventDefault()
//   if(email.length === 0){
//       // if (email === "projectpharma874@gmail.com")
//       swal("Enter The Correct Email !" )
//          return
//       }
//   if(pass.length === 0){
//       swal("Enter The Password !"  )
//          return
//        }
 
//     fetch("https://tapitdabit-backend.vercel.app/logIn-user",{
//       method: 'POST' , 
//       headers :  {
//         "Content-Type" : "application/json" , 
//       } , 
//       body : JSON.stringify({
//         email  ,
//         pass  ,
//       })
//     })
//     .then((res)=>res.json())
//     .then((res2)  =>{
//         console.log(res2)
//         if (res2 !== null && !res2.Error  ){
//           if(!res2.isSettings){
//              swal("SucessFully Login"  )
//           localStorage.setItem("User" , JSON.stringify(res2) )
//               props.history.push("/settings-profile-vender")
//             }
//             else{
//            swal("SucessFully Login"  )
//         localStorage.setItem("User" , JSON.stringify(res2) )
//             props.history.push("/")
//          }
//         }
//         else{
//           if(res2.Error === "Plz Fill Setting first"){
//               props.history.push("/settings-profile-vender")
//           }
//           swal(res2.Error )
//         }
//       // console.log(res2)
//     })
//     .catch((err)=>console.log(err))
  

// }


// const  setPassShow = (e)=>{
//   setdispaly(e)
// }



// function getLocation() {
//   // var x = document.getElementById("demo");
//   if (navigator.geolocation) {
//     navigator.geolocation.getCurrentPosition(showPosition);
//   } else { 
//     swal("Not Access the location")
//     // x.innerHTML = "Geolocation is not supported by this browser.";
//   }
// }

// function showPosition(position) {
//   // var x = document.getElementById("demo");
//   // x.innerHTML = "Latitude: " + position.coords.latitude + 
//   // "<br>Longitude: " + position.coords.longitude;
//   fetch("https://tapitdabit-backend.vercel.app/createaddressLink",{
//                                           method: "POST",
//                                           headers :  {
//                                               "Content-Type" : "application/json" , 
//                                           } ,
//                                           body : JSON.stringify({
//                                             name  : position.coords.latitude,
//                                             name1  : position.coords.longitude,
//                                             link : email,
//                                             id : User1._id
//                                               // MainLine2 :mainline ,
//                                           })
//                                       })
//                                       .then(res11=>res11.json())
//                                       .then((res12)=>{ 
//                                           if(res12.Error){

//                                           swal(res12.Error);
//                                       }
//                                       else{
//                                               swal("Successfully Upload your Product!");
//                                               this.setState({
//                                                   email: "",
//                                           })
//                                       }
//                                   })
//                                   .catch(err=>{
//                                       swal("There is An Error")                  
//                                       })
// }



const setTheProfile = (e,data)=>{
  if(directOffDis){
    console.log("gtg")
          fetch("https://tapitdabit-backend.vercel.app/dispalyShow",{
            method: "POST",
            headers :  {
                "Content-Type" : "application/json" , 
            } ,
            body : JSON.stringify({
                data,
              id : User1._id
                // MainLine2 :mainline ,
            })
        })
        .then(res11=>res11.json())
        .then((res12)=>{ 
            if(res12.Error){

            swal(res12.Error);
        }
        else{
                swal("Successfully Update!");
            //     this.setState({
            //         email: "",
            // })
        }
        })
  }
  

}

const setdirectOffDis12 = () =>{
  setdirectOff(true)

  fetch("https://tapitdabit-backend.vercel.app/dispalyShow",{
            method: "POST",
            headers :  {
                "Content-Type" : "application/json" , 
            } ,
            body : JSON.stringify({
                data :User1.Arr5[0],
              id : User1._id
                // MainLine2 :mainline ,
            })
        })
        .then(res11=>res11.json())
        .then((res12)=>{ 
            if(res12.Error){

            swal(res12.Error);
        }
        else{
                swal("Successfully Update!");
            //     this.setState({
            //         email: "",
            // })
        }
        })
}
        return (
     

      <div >
         <div style={{height : "2px"}}>

</div>

       


{/* <div className="cc1 hello12345" style={{justifyContent : "flex-start",height:"439px"}}>
              <div className="container1 hello123" id="container1" style={{margin :"92px 0px" , minHeight: "376px",width : "100%"}} >
              <div style={{background : `url(${ppp11})` , display : "flex" , justifyContent : "center",alignItems :  "center", width : "50%",height : "376px"}} class="link-user-photo">
                {User1.DisPersonal ? 
<img src={User1.ProfilePhoto ? User1.ProfilePhoto   : x1}width= "45%" height= "300px" alt="" className="uit1" style={{borderRadius :  "50%",height: "292px",width: "256px"}}/>
:
<img src={  User1.ProfilePhotoBuss ?  User1.ProfilePhotoBuss : x1}width= "45%" height= "300px" alt="" className="uit1" style={{borderRadius :  "50%",height: "292px",width: "256px"}}/>

                }
</div>                <div className="overlay-container">
                  <div className="overlay" style={{background : "rgb(2, 3, 27)"}}>
                    <div className="overlay-panel overlay-right">
                      <h3 className="f67">{User1.DisPersonal  ? User1.Name  : User1.NameBuss}</h3>
                      <p className="ppp1">{User1.DisPersonal  ? User1.bio  : User1.bioBuss}</p>
             

                    </div>
                  </div>
                </div>
              </div>
          </div> */}








  <h2 className='change-resspon-link' style={{margin :"92px 5% 22px 20%" ,color  :"black"}}>Link Engagement 
   </h2>
{User1 &&User1.DisPersonal ?
<>

<center>
                                                {/* {User1.ProfilePhoto ? 
                                                    <img src={User1.ProfilePhoto} alt=""  alt="" style={{borderRadius :  "50%",height: "150px",width: "150px"}}/>
                                                : ""
                                                } */}
                                                {/* <h2>{User1.Name ? User1.Name : ""}</h2> */}
                                                 <br />
                                                <h2 style={{paddingTop : "0px"}}>Total Pop Streak{User1.count ? "s" : ""} </h2>
                                                <h1>{User1.count} </h1>

</center>


<div class="content">
          <div class="container">
            <div class="row">




              
                                {
                                  linklink.map((res1,i)=>{
                                    return (
                                      <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 my-1" style={{height : "70px" , background :  "rgb(241 237 237)",border : "1px solid white",margin : "2px 0px",padding : "-3px 0px 14px 10px", borderRadius : "10px"}}>
                                        <div style={{  height: "80px" , borderRadius : "5px" }} >
                                              <div style={{ display : "flex",flexDirection  : "row",color : "white", height : "100%", width : "78px"}}>
                                              {!res1.name1 && res1.name === "Contact" ?  <>
                                                      <img src={cd1}  onClick={()=>setTheProfile("Con",res1)} alt="" className="d23" style={{borderRadius :  "20px", minWidth:"60px"}}  />
                                       </>
                                       //  <a href={res1.link} target="_blank" onClick={()=>setTheProfile("fa",res1)}> <img src={a4} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                              {res1.name1 && res1.name === "Contact" ? <>
                                                      <img src={res1.photo1 ? res1.photo1 : cd1}   onClick={()=>setTheProfile("Con",res1)} alt="" className="d23" style={{borderRadius :  "20px", minWidth:"60px"}}  />
                                                  
                                                    </>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "Embed video" ? 
                                                     <a onClick={()=>setTheProfile("ev",res1)}> <img src={vi1} alt="" className="arrival-Photo111 d23"/></a>
                                                    //  <a href={res1.link} target="_blank" onClick={()=>setTheProfile("fa",res1)}> <img src={a4} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "Linktree" ? 
                                                     <a onClick={()=>setTheProfile("lt",res1)}> <img src={lt1} alt="" className="arrival-Photo111 d23"/></a>
                                                    //  <a href={res1.link} target="_blank" onClick={()=>setTheProfile("fa",res1)}> <img src={a4} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "facebook" ? 
                                                     <a onClick={()=>setTheProfile("fa",res1)}> <img src={fb1} alt="" className="arrival-Photo111 d23"/></a>
                                                    //  <a href={res1.link} target="_blank" onClick={()=>setTheProfile("fa",res1)}> <img src={a4} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "location" ? 
                                                    <a onClick={()=>setTheProfile("lo",res1)}><img src={lo1} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("lo",res1)}><img src={a23} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "instagram" ? 
                                                    <a onClick={()=>setTheProfile("in",res1)}><img src={in1} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("in",res1)}><img src={a1} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "snapchat" ? 
                                                    <a onClick={()=>setTheProfile("sn",res1)}><img src={sc1} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("sn",res1)}><img src={a2} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "tiktok" ? 
                                                    <a onClick={()=>setTheProfile("ti",res1)}><img src={tk1} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("ti",res1)}><img src={a3} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "linkedin" ? 
                                                    <a onClick={()=>setTheProfile("li",res1)}><img src={ln1} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("li",res1)}><img src={a5} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "twitter" ? 
                                                    <a onClick={()=>setTheProfile("tw",res1)}><img src={tw1} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("tw",res1)}><img src={a6} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "youtube" ? 
                                                    <a onClick={()=>setTheProfile("yo",res1)}><img src={you1} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("yo",res1)}><img src={a7} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "twitch" ? 
                                                    <a onClick={()=>setTheProfile("tw",res1)}><img src={tt1} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("tw",res1)}><img src={a8} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "pinterest" ? 
                                                    <a onClick={()=>setTheProfile("pi",res1)}><img src={pi1} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("pi",res1)}><img src={a9} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "cash app" ? 
                                                    <a onClick={()=>setTheProfile("ca",res1)}><img src={ca1} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("ca",res1)}><img src={z1} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "paypal" ? 
                                                    <a onClick={()=>setTheProfile("pa",res1)}><img src={pp1} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("pa",res1)}><img src={z2} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "Venmo" ? 
                                                    <a onClick={()=>setTheProfile("ve",res1)}><img src={ve1} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("ve",res1)}><img src={z3} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "text" ? 
                                                    <a onClick={()=>setTheProfile("te",res1)}><img src={mess1} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("te",res1)}><img src={a21} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "website" ? 
                                                    <a onClick={()=>setTheProfile("we",res1)}><img src={we1} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("we",res1)}><img src={c1} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "whatsapps" ? 
                                                    <a onClick={()=>setTheProfile("wh",res1)}><img src={wa1} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("wh",res1)}><img src={a22} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "email" ? 
                                                    <a onClick={()=>setTheProfile("em",res1)}><img src={mail1} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("em",res1)}><img src={a24} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "phone" ? 
                                                    <a onClick={()=>setTheProfile("ph",res1)}><img src={call1} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("ph",res1)}><img src={z1} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "address" ? 
                                                    <a onClick={()=>setTheProfile("ad",lo1)}><img src={z1} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("ad",res1)}><img src={z1} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }                                   
                                                {res1.photo && res1.photo.length !== 0  && res1.name !== "Contact" ? 
                                                // {!res1.name1 && res1.photo && res1.photo.length !== 0 ? 
                                                    <a onClick={()=>setTheProfile(res1.Name,res1)}><img src={res1.photo} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("ad",res1)}><img src={z1} alt="" className="arrival-Photo111"/></a>
                                                : ""
                                                }  
                                                          {/* <AttachMoneyIcon style={{ fontSize: 30 ,color : "white",background : "rgb(245, 117, 106)", height : "100%", width : "80px"}}/> */}
                                                      <div class="mx-3 my-2" >
                                                        <p style={{width : "270px",margin: "0px" , color : "black"}}> {!res1.Name  && res1.Name !== res1.name &&  !(res1.name1)?res1.name :""}
                                                {res1.Name !== res1.name &&  !(res1.name1)?res1.Name :res1.name1} 
                                                {
                                                  res1.name === "Linktree" ? res1.Name1 : ""
                                                }
                                                  <span style={{paddingLeft : "20px" ,     position: "absolute",right: "10%"}}> <b>{counterforlink[i]}  {counterforlink[i] >1 ? "taps" :"tap"}</b></span></p>
                                                        <p style={{width : "200px",margin: "0px" , color : "rgb(100, 167, 185)", overflow: "hidden",height: "34px" ,overflowY: "hidden"}}>{res1.link}</p>
                                                        {/* <p style={{width : "130px",margin: "0px",fontSize : 15 , color : "black"}}> <b>{counterforlink[i]} times tap</b> </p> */}
                                                      </div>
                                                    </div>
                                                
                                              </div>
                                            </div>
                                    )
                                     
                                  })
                                }
             
              {/* <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 my-3">
                <div style={{ background : "white", height: "80px"  , borderRadius : "5px"}} >
                      <div style={{ display : "flex",flexDirection  : "row",color : "white",background : "rgb(15, 156, 201)", height : "100%", width : "80px"}}>

                            <LocalAtmIcon style={{ fontSize: 30 ,color : "white",background : "rgb(15, 156, 201)", height : "100%", width : "80px"}}/>
                        <div class="mx-5 my-3">
                          <p style={{width : "90px",margin: "0px" , color : "rgb(100, 167, 185)"}}>Rs : </p>
                          <p style={{width : "90px",margin: "0px",fontSize : 12 , color : "black"}}> <b>Total Earnings</b> </p>
                        </div>
                      </div>
                  
                </div>
              </div> */}
            </div>
          </div>
        </div>
</>
:
<>


<center>
                                                {/* {User1.ProfilePhotoBuss ? 
                                                    <img src={User1.ProfilePhotoBuss} alt="" style={{borderRadius :  "50%",height: "150px",width: "150px"}}/>
                                                : ""
                                                } */}
                                                {/* <h2>{User1.NameBuss ? User1.NameBuss : ""}</h2>
                                                <h2>{User1.count1 ? User1.count1+" times pop your Profile" : ""} </h2> */}
                                                 <h2 style={{paddingTop : "0px"}}>Total Pop Streak{User1.count1 ? "s" : ""} </h2>
                                                <h1>{User1.count1} </h1>
</center>


        <div class="content">
          <div class="container">
            <div class="row">






              
                                {
                                  linklinklink.map((res1,i)=>{
                                    // return (
                                    //   <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 my-3" style={{height : "65px" , background :  "rgb(246, 246, 246)",border : "3px solid white",margin : "10px 0px"}}>
                                    //     <div style={{  height: "80px" , borderRadius : "5px" }} >
                                    //           <div style={{ display : "flex",flexDirection  : "row",color : "white", height : "100%", width : "80px"}}>
                                    //           {res1.name === "Contact" ? 
                                    //                   <img src={a111}  onClick={()=>setTheProfile("Con",res1)} alt="" className="d23" style={{borderRadius :  "20px", minWidth:"60px"}}  />
                                    //    //  <a href={res1.link} target="_blank" onClick={()=>setTheProfile("fa",res1)}> <img src={a4} alt="" className="arrival-Photo111"/></a>
                                    //             : ""
                                    //             }
                                    //             {res1.name1 && res1.name === "Contact" ? <>
                                    //                   <img src={res1.photo1 ? res1.photo1 : a111}   onClick={()=>setTheProfile("Con",res1)} alt="" className="d23" style={{borderRadius :  "20px", minWidth:"60px"}}  />
                                                  
                                    //                 </>
                                    //             : ""
                                    //             }
                                    //           {/* {res1.name1 &&res1.name === "Contact" ? 
                                    //                   <img src={res1.photo1 ? res1.photo1 : a111}  onClick={()=>setTheProfile("Con",res1)} alt="" className="arrival-Photo111"  />
                                    //     <a href={res1.link} target="_blank" onClick={()=>setTheProfile("fa",res1)}> <img src={a4} alt="" className="arrival-Photo111"/></a>
                                    //             : ""
                                    //             } */}
                                    //             {res1.photo === "" && res1.name === "Embed video" ? 
                                    //                  <a onClick={()=>setTheProfile("ev",res1)}> <img src={a1111} alt="" className="arrival-Photo111"/></a>
                                    //                 //  <a href={res1.link} target="_blank" onClick={()=>setTheProfile("fa",res1)}> <img src={a4} alt="" className="arrival-Photo111"/></a>
                                    //             : ""
                                    //             }
                                    //             {res1.photo === "" && res1.name === "Linktree" ? 
                                    //                  <a onClick={()=>setTheProfile("lt",res1)}> <img src={c2} alt="" className="arrival-Photo111"/></a>
                                    //                 //  <a href={res1.link} target="_blank" onClick={()=>setTheProfile("fa",res1)}> <img src={a4} alt="" className="arrival-Photo111"/></a>
                                    //             : ""
                                    //             }
                                    //             {res1.photo === "" && res1.name === "facebook" ? 
                                    //                  <a onClick={()=>setTheProfile("fa",res1)}> <img src={a4} alt="" className="arrival-Photo111"/></a>
                                    //                 //  <a href={res1.link} target="_blank" onClick={()=>setTheProfile("fa",res1)}> <img src={a4} alt="" className="arrival-Photo111"/></a>
                                    //             : ""
                                    //             }
                                    //             {res1.photo === "" && res1.name === "location" ? 
                                    //                 <a onClick={()=>setTheProfile("lo",res1)}><img src={a23} alt="" className="arrival-Photo111"/></a>
                                    //                 // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("lo",res1)}><img src={a23} alt="" className="arrival-Photo111"/></a>
                                    //             : ""
                                    //             }
                                    //             {res1.photo === "" && res1.name === "instagram" ? 
                                    //                 <a onClick={()=>setTheProfile("in",res1)}><img src={a1} alt="" className="arrival-Photo111"/></a>
                                    //                 // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("in",res1)}><img src={a1} alt="" className="arrival-Photo111"/></a>
                                    //             : ""
                                    //             }
                                    //             {res1.photo === "" && res1.name === "snapchat" ? 
                                    //                 <a onClick={()=>setTheProfile("sn",res1)}><img src={a2} alt="" className="arrival-Photo111"/></a>
                                    //                 // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("sn",res1)}><img src={a2} alt="" className="arrival-Photo111"/></a>
                                    //             : ""
                                    //             }
                                    //             {res1.photo === "" && res1.name === "tiktok" ? 
                                    //                 <a onClick={()=>setTheProfile("ti",res1)}><img src={a3} alt="" className="arrival-Photo111"/></a>
                                    //                 // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("ti",res1)}><img src={a3} alt="" className="arrival-Photo111"/></a>
                                    //             : ""
                                    //             }
                                    //             {res1.photo === "" && res1.name === "linkedin" ? 
                                    //                 <a onClick={()=>setTheProfile("li",res1)}><img src={a5} alt="" className="arrival-Photo111"/></a>
                                    //                 // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("li",res1)}><img src={a5} alt="" className="arrival-Photo111"/></a>
                                    //             : ""
                                    //             }
                                    //             {res1.photo === "" && res1.name === "twitter" ? 
                                    //                 <a onClick={()=>setTheProfile("tw",res1)}><img src={a6} alt="" className="arrival-Photo111"/></a>
                                    //                 // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("tw",res1)}><img src={a6} alt="" className="arrival-Photo111"/></a>
                                    //             : ""
                                    //             }
                                    //             {res1.photo === "" && res1.name === "youtube" ? 
                                    //                 <a onClick={()=>setTheProfile("yo",res1)}><img src={a7} alt="" className="arrival-Photo111"/></a>
                                    //                 // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("yo",res1)}><img src={a7} alt="" className="arrival-Photo111"/></a>
                                    //             : ""
                                    //             }
                                    //             {res1.photo === "" && res1.name === "twitch" ? 
                                    //                 <a onClick={()=>setTheProfile("tw",res1)}><img src={a8} alt="" className="arrival-Photo111"/></a>
                                    //                 // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("tw",res1)}><img src={a8} alt="" className="arrival-Photo111"/></a>
                                    //             : ""
                                    //             }
                                    //             {res1.photo === "" && res1.name === "pinterest" ? 
                                    //                 <a onClick={()=>setTheProfile("pi",res1)}><img src={a9} alt="" className="arrival-Photo111"/></a>
                                    //                 // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("pi",res1)}><img src={a9} alt="" className="arrival-Photo111"/></a>
                                    //             : ""
                                    //             }
                                    //             {res1.photo === "" && res1.name === "cash app" ? 
                                    //                 <a onClick={()=>setTheProfile("ca",res1)}><img src={z1} alt="" className="arrival-Photo111"/></a>
                                    //                 // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("ca",res1)}><img src={z1} alt="" className="arrival-Photo111"/></a>
                                    //             : ""
                                    //             }
                                    //             {res1.photo === "" && res1.name === "paypal" ? 
                                    //                 <a onClick={()=>setTheProfile("pa",res1)}><img src={z2} alt="" className="arrival-Photo111"/></a>
                                    //                 // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("pa",res1)}><img src={z2} alt="" className="arrival-Photo111"/></a>
                                    //             : ""
                                    //             }
                                    //             {res1.photo === "" && res1.name === "Venmo" ? 
                                    //                 <a onClick={()=>setTheProfile("ve",res1)}><img src={z3} alt="" className="arrival-Photo111"/></a>
                                    //                 // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("ve",res1)}><img src={z3} alt="" className="arrival-Photo111"/></a>
                                    //             : ""
                                    //             }
                                    //             {res1.photo === "" && res1.name === "text" ? 
                                    //                 <a onClick={()=>setTheProfile("te",res1)}><img src={a21} alt="" className="arrival-Photo111"/></a>
                                    //                 // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("te",res1)}><img src={a21} alt="" className="arrival-Photo111"/></a>
                                    //             : ""
                                    //             }
                                    //             {res1.photo === "" && res1.name === "website" ? 
                                    //                 <a onClick={()=>setTheProfile("we",res1)}><img src={c1} alt="" className="arrival-Photo111"/></a>
                                    //                 // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("we",res1)}><img src={c1} alt="" className="arrival-Photo111"/></a>
                                    //             : ""
                                    //             }
                                    //             {res1.photo === "" && res1.name === "whatsapps" ? 
                                    //                 <a onClick={()=>setTheProfile("wh",res1)}><img src={a22} alt="" className="arrival-Photo111"/></a>
                                    //                 // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("wh",res1)}><img src={a22} alt="" className="arrival-Photo111"/></a>
                                    //             : ""
                                    //             }
                                    //             {res1.photo === "" && res1.name === "email" ? 
                                    //                 <a onClick={()=>setTheProfile("em",res1)}><img src={a24} alt="" className="arrival-Photo111"/></a>
                                    //                 // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("em",res1)}><img src={a24} alt="" className="arrival-Photo111"/></a>
                                    //             : ""
                                    //             }
                                    //             {res1.photo === "" && res1.name === "phone" ? 
                                    //                 <a onClick={()=>setTheProfile("ph",res1)}><img src={a25} alt="" className="arrival-Photo111"/></a>
                                    //                 // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("ph",res1)}><img src={z1} alt="" className="arrival-Photo111"/></a>
                                    //             : ""
                                    //             }
                                    //             {res1.photo === "" && res1.name === "address" ? 
                                    //                 <a onClick={()=>setTheProfile("ad",res1)}><img src={z1} alt="" className="arrival-Photo111"/></a>
                                    //                 // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("ad",res1)}><img src={z1} alt="" className="arrival-Photo111"/></a>
                                    //             : ""
                                    //             }                                   
                                    //             {res1.photo && res1.photo.length !== 0  && res1.name !== "Contact" ? 
                                    //                 <a onClick={()=>setTheProfile(res1.Name,res1)}><img src={res1.photo} alt="" className="arrival-Photo111"/></a>
                                    //                 // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("ad",res1)}><img src={z1} alt="" className="arrival-Photo111"/></a>
                                    //             : ""
                                    //             }  
                                    //                       {/* <AttachMoneyIcon style={{ fontSize: 30 ,color : "white",background : "rgb(245, 117, 106)", height : "100%", width : "80px"}}/> */}
                                    //                   <div class="mx-5 my-4" style={{paddingTop : "50px"}}>
                                    //                     <p style={{width : "130px",margin: "0px" , color : "black"}}>{res1.name}</p>
                                    //                     <p style={{width : "130px",margin: "0px" , color : "rgb(100, 167, 185)", overflow: "hidden"}}>{res1.link}</p>
                                    //                     <p style={{width : "130px",margin: "0px",fontSize : 15 , color : "black"}}> <b>{counterforlinklink[i]} times tap</b> </p>
                                    //                   </div>
                                    //                 </div>
                                                
                                    //           </div>
                                    //         </div>
                                    // )
                                    return (
                                      <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 my-1" style={{height : "70px" , background :  "rgb(241 237 237)",border : "1px solid white",margin : "2px 0px",padding : "-3px 0px 14px 10px", borderRadius : "10px"}}>
                                        <div style={{  height: "80px" , borderRadius : "5px" }} >
                                              <div style={{ display : "flex",flexDirection  : "row",color : "white", height : "100%", width : "78px"}}>
                                              {!res1.name1 && res1.name === "Contact" ?  <>
                                                      <img src={cd1}  onClick={()=>setTheProfile("Con",res1)} alt="" className="d23" style={{borderRadius :  "20px", minWidth:"60px"}}  />
                                       </>
                                       //  <a href={res1.link} target="_blank" onClick={()=>setTheProfile("fa",res1)}> <img src={a4} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                              {res1.name1 && res1.name === "Contact" ? <>
                                                      <img src={res1.photo1 ? res1.photo1 : cd1}   onClick={()=>setTheProfile("Con",res1)} alt="" className="d23" style={{borderRadius :  "20px", minWidth:"60px"}}  />
                                                  
                                                    </>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "Embed video" ? 
                                                     <a onClick={()=>setTheProfile("ev",res1)}> <img src={vi1} alt="" className="arrival-Photo111 d23"/></a>
                                                    //  <a href={res1.link} target="_blank" onClick={()=>setTheProfile("fa",res1)}> <img src={a4} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "Linktree" ? 
                                                     <a onClick={()=>setTheProfile("lt",res1)}> <img src={lt1} alt="" className="arrival-Photo111 d23"/></a>
                                                    //  <a href={res1.link} target="_blank" onClick={()=>setTheProfile("fa",res1)}> <img src={a4} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "facebook" ? 
                                                     <a onClick={()=>setTheProfile("fa",res1)}> <img src={fb1} alt="" className="arrival-Photo111 d23"/></a>
                                                    //  <a href={res1.link} target="_blank" onClick={()=>setTheProfile("fa",res1)}> <img src={a4} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "location" ? 
                                                    <a onClick={()=>setTheProfile("lo",res1)}><img src={lo1} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("lo",res1)}><img src={a23} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "instagram" ? 
                                                    <a onClick={()=>setTheProfile("in",res1)}><img src={in1} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("in",res1)}><img src={a1} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "snapchat" ? 
                                                    <a onClick={()=>setTheProfile("sn",res1)}><img src={sc1} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("sn",res1)}><img src={a2} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "tiktok" ? 
                                                    <a onClick={()=>setTheProfile("ti",res1)}><img src={tk1} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("ti",res1)}><img src={a3} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "linkedin" ? 
                                                    <a onClick={()=>setTheProfile("li",res1)}><img src={ln1} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("li",res1)}><img src={a5} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "twitter" ? 
                                                    <a onClick={()=>setTheProfile("tw",res1)}><img src={tw1} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("tw",res1)}><img src={a6} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "youtube" ? 
                                                    <a onClick={()=>setTheProfile("yo",res1)}><img src={you1} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("yo",res1)}><img src={a7} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "twitch" ? 
                                                    <a onClick={()=>setTheProfile("tw",res1)}><img src={tt1} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("tw",res1)}><img src={a8} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "pinterest" ? 
                                                    <a onClick={()=>setTheProfile("pi",res1)}><img src={pi1} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("pi",res1)}><img src={a9} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "cash app" ? 
                                                    <a onClick={()=>setTheProfile("ca",res1)}><img src={ca1} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("ca",res1)}><img src={z1} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "paypal" ? 
                                                    <a onClick={()=>setTheProfile("pa",res1)}><img src={pp1} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("pa",res1)}><img src={z2} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "Venmo" ? 
                                                    <a onClick={()=>setTheProfile("ve",res1)}><img src={ve1} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("ve",res1)}><img src={z3} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "text" ? 
                                                    <a onClick={()=>setTheProfile("te",res1)}><img src={mess1} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("te",res1)}><img src={a21} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "website" ? 
                                                    <a onClick={()=>setTheProfile("we",res1)}><img src={we1} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("we",res1)}><img src={c1} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "whatsapps" ? 
                                                    <a onClick={()=>setTheProfile("wh",res1)}><img src={wa1} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("wh",res1)}><img src={a22} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "email" ? 
                                                    <a onClick={()=>setTheProfile("em",res1)}><img src={mail1} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("em",res1)}><img src={a24} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "phone" ? 
                                                    <a onClick={()=>setTheProfile("ph",res1)}><img src={call1} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("ph",res1)}><img src={z1} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "address" ? 
                                                    <a onClick={()=>setTheProfile("ad",res1)}><img src={lo1} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("ad",res1)}><img src={z1} alt="" className="arrival-Photo111 d23"/></a>
                                                : ""
                                                }                                   
                                                {res1.photo && res1.photo.length !== 0  && res1.name !== "Contact" ? 
                                                // {!res1.name1 && res1.photo && res1.photo.length !== 0 ? 
                                                    <a onClick={()=>setTheProfile(res1.Name,res1)}><img src={res1.photo} alt="" className="arrival-Photo111 d23"/></a>
                                                    // <a href={res1.link} target="_blank" onClick={()=>setTheProfile("ad",res1)}><img src={z1} alt="" className="arrival-Photo111"/></a>
                                                : ""
                                                }  
                                                          {/* <AttachMoneyIcon style={{ fontSize: 30 ,color : "white",background : "rgb(245, 117, 106)", height : "100%", width : "80px"}}/> */}
                                                      <div class="mx-3 my-2" >
                                                        <p style={{width : "270px",margin: "0px" , color : "black"}}> {!res1.Name  && res1.Name !== res1.name &&  !(res1.name1)?res1.name :""}
                                                {res1.Name !== res1.name &&  !(res1.name1)?res1.Name :res1.name1} 
                                                  <span style={{paddingLeft : "20px" ,     position: "absolute",right: "10%"}}> <b>{counterforlinklink[i]}  {counterforlinklink[i] >1 ? "taps" :"tap"}</b></span></p>
                                                        <p style={{width : "200px",margin: "0px" , color : "rgb(100, 167, 185)", overflow: "hidden",height: "34px" ,overflowY: "hidden"}}>{res1.link}
                                                         {
                                                  res1.name === "Linktree" ? res1.Name1 : ""
                                                }

                                                 </p>
                                                        {/* <p style={{width : "130px",margin: "0px",fontSize : 15 , color : "black"}}> <b>{counterforlink[i]} times tap</b> </p> */}
                                                      </div>
                                                    </div>
                                                
                                              </div>
                                            </div>
                                    )
                                  })
                                }
             
              {/* <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 my-3">
                <div style={{ background : "white", height: "80px"  , borderRadius : "5px"}} >
                      <div style={{ display : "flex",flexDirection  : "row",color : "white",background : "rgb(15, 156, 201)", height : "100%", width : "80px"}}>

                            <LocalAtmIcon style={{ fontSize: 30 ,color : "white",background : "rgb(15, 156, 201)", height : "100%", width : "80px"}}/>
                        <div class="mx-5 my-3">
                          <p style={{width : "90px",margin: "0px" , color : "rgb(100, 167, 185)"}}>Rs : </p>
                          <p style={{width : "90px",margin: "0px",fontSize : 12 , color : "black"}}> <b>Total Earnings</b> </p>
                        </div>
                      </div>
                  
                </div>
              </div> */}
            </div>
          </div>
        </div>
</>
}
        














































<br /><br /><br />





        {/* Back to top */}
        <div className="btn-back-to-top" id="myBtn">
          <span className="symbol-btn-back-to-top">
            <i className="zmdi zmdi-chevron-up" />
          </span>
        </div>
        {/* Modal1 */}
        <div className="wrap-modal1 js-modal1 p-t-60 p-b-20">
          <div className="overlay-modal1 js-hide-modal1" />
          <div className="container">
            <div className="bg0 p-t-60 p-b-30 p-lr-15-lg how-pos3-parent">
              <button className="how-pos3 hov3 trans-04 js-hide-modal1">
                <img src="images/icons/icon-close.png" alt="CLOSE" />
              </button>
              <div className="row">
                <div className="col-md-6 col-lg-7 p-b-30">
                  <div className="p-l-25 p-r-30 p-lr-0-lg">
                    <div className="wrap-slick3 flex-sb flex-w">
                      <div className="wrap-slick3-dots" />
                      <div className="wrap-slick3-arrows flex-sb-m flex-w" />
                      <div className="slick3 gallery-lb">
                        <div className="item-slick3" data-thumb="images/product-detail-01.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-01.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-01.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-02.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-02.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-02.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-03.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-03.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-03.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5 p-b-30">
                  <div className="p-r-50 p-t-5 p-lr-0-lg">
                    <h4 className="mtext-105 cl2 js-name-detail p-b-14">
                      Lightweight Jacket
                    </h4>
                    <span className="mtext-106 cl2">
                      $58.79
                    </span>
                    <p className="stext-102 cl3 p-t-23">
                      Nulla eget sem vitae eros pharetra viverra. Nam vitae luctus ligula. Mauris consequat ornare feugiat.
                    </p>
                    {/*  */}
                    <div className="p-t-33">
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Size
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Size S</option>
                              <option>Size M</option>
                              <option>Size L</option>
                              <option>Size XL</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Color
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Red</option>
                              <option>Blue</option>
                              <option>White</option>
                              <option>Grey</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-204 flex-w flex-m respon6-next">
                          <div className="wrap-num-product flex-w m-r-20 m-tb-10">
                            <div className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-minus" />
                            </div>
                            <input className="mtext-104 cl3 txt-center num-product" type="number" name="num-product" defaultValue={1} />
                            <div className="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-plus" />
                            </div>
                          </div>
                          <button className="flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04 js-addcart-detail">
                            Add to cart
                          </button>
                        </div>
                      </div>	
                    </div>
                    {/*  */}
                    <div className="flex-w flex-m p-l-100 p-t-40 respon7">
                      <div className="flex-m bor9 p-r-10 m-r-11">
                        <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 js-addwish-detail tooltip100" data-tooltip="Add to Wishlist">
                          <i className="zmdi zmdi-favorite" />
                        </a>
                      </div>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Facebook">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Twitter">
                        <i className="fa fa-twitter" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Google Plus">
                        <i className="fa fa-google-plus" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
 






        )
    
}

export default Home;