import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"
import swal from 'sweetalert';
// import $ from 'jquery';
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import Loader from "react-loader-spinner";

import "react-multi-carousel/lib/styles.css";
import "react-multi-carousel/lib/styles.css";
import "./../assets/css/main.css"
import "./../assets/css/util.css"
import "./../assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css"

import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import AssessmentIcon from '@material-ui/icons/Assessment';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SortIcon from '@material-ui/icons/Sort';



// import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"

import "./../assets/vendor/css-hamburgers/hamburgers.min.css"

import "./../assets/fonts/iconic/css/material-design-iconic-font.min.css"
import "./../assets/vendor/bootstrap/css/bootstrap.min.css"
import "./../assets/vendor/select2/select2.min.css"
import "./../assets/vendor/slick/slick.css"
import "./../assets/vendor/perfect-scrollbar/perfect-scrollbar.css"


const Home = ( props ) => {
    const [myRef,setRef] = useState( React.createRef())
    const [scrollTop1,setscrollTop] = useState(0)
    // const [email , setemail] = useState(["","","","","","","","","","","",""])
    const [data , setdata] = useState([])

    const [list1 , setlist1] = useState("")
    const [Name , setname] = useState("")
    const [Name1 , setname1] = useState("")
    const [Email , setEmail] = useState("")
    const [Phone , setPhone] = useState("")
    const [Address , setAddress] = useState("")
 
    const [dispaly ,  setdispaly ] = useState(true)
    const [User1 ,  setUser1 ] = useState({})
    const [Num ,  setNum ] = useState(0)

    const [dispaly1 ,  setdispaly1 ] = useState(false)

    useEffect(() =>{
if(! JSON.parse(localStorage.getItem("User"))){

        props.match.history.push("/login")
      }
      else{

        window.scrollTo(0, 0)







        localStorage.setItem("VenderWorkingThose" ,JSON.stringify(123)) 
        setUser1(JSON.parse(localStorage.getItem("User")))
        setNum(JSON.parse(localStorage.getItem("request123")))


        fetch("https://tapitdabit-backend.vercel.app/pricelist",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res5=>res5.json())
        .then(res6=>{
            setdata(res6)
            console.log(res6)
            // res6.map
            const Fil = res6.filter((re,i)=>re.listName === "Pro Feathers")
            console.log(Fil)
            
            if(Fil){
              // if(Fil[0].listMonth > 0){
                setname1(Fil[0].Name)
                setlist1(Fil[0].listMonth +" "+Fil[0].listPrice+" "+Fil[0].Name)
              // }
              // else{
              //   setlist1(Fil[0].listDay +" "+Fil[0].listPrice)
              // }
            }
        })
      }
      return () => {
        document.getElementById("get1").classList.remove("is-active");
        document.getElementById("get2").style.display = "none";
      }
  
    },[])
    useEffect( () => () => localStorage.removeItem("VenderWorkingThose"), [] );


    


const SubmitDataMessage = (e) =>{
    e.preventDefault()
    console.log(list1)
  setdispaly ( false)
  console.log("wddde");
  let f
  
   if(Num === 1){
    f = "Pro Feathers"
  }
   else if(Num === 2){
    f = "Pro Feathers"
  }
   else if(Num === 3){
    f = "Pro Feathers"
  }
   else if(Num === 4){
    f = "Pro Feathers"
  }
  else{
    return
  }
                              fetch("https://tapitdabit-backend.vercel.app/createProRequest",{
                                          method: "POST",
                                          headers :  {
                                              "Content-Type" : "application/json" , 
                                          } ,
                                          body : JSON.stringify({
                                            RequestName  : f,
                                            fullName :Name ,
                                            // name : f,
                                            Email  ,
                                            Phone  , 
                                            Address ,
                                            time : list1,
                                            id : User1._id
                                              // MainLine2 :mainline ,
                                          })
                                      })
                                      .then(res11=>res11.json())
                                      .then((res12)=>{ 
                                          if(!res12.Error){
                                              swal("Successfully Send your Request!");
                                              setTimeout(()=>{

                                                props.history.push("/user/home1/"+User1._id)
                                              },1500)
                                              setdispaly ( true)

                                              this.setState({
                                                  email: "",
                                          })
                                        }
                                        else{
                                        swal(res12.Error);
                                        setdispaly ( true)
 
                                      }
                                  })
                                  .catch(err=>{
                                    swal("Successfully Send your Request!");
                                    setdispaly ( true)
                                    props.history.push("/user/home1/"+User1._id)

                                      }) 

}




const setRouting1 = (e)=>{
  if(e === 1){
    if(JSON.parse(localStorage.getItem("User")).DisPersonal)
    props.history.push("/user/home1/"+User1._id)
    else 
    props.history.push("/user/bussiness/home1/"+User1._id)
  }
  else if(e === 4){
    if(JSON.parse(localStorage.getItem("User")).DisPersonal)
    props.history.push("/user/editProfile/sort-delete/"+User1.uid)
    else
    props.history.push("/user/bussiness/editProfile/sort-delete/"+User1.uid)
  }
  else if(e === 3){
    // props.history.push("/user/analytics/"+User1._id)   

    const d =  JSON.parse(localStorage.getItem("User")).ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
    console.log(d)
    
      
    if (d.length > 0){
      let a = new Date()
      let f= a.getDate() +" "+(a.getUTCMonth()+1)+ " "+a.getUTCFullYear()
      console.log(d[0].End[0]+""+d[0].End[1] >= a.getDate(), d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ,d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear())
      if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] > a.getUTCFullYear()     ){
      // if(d[0].End && d[0].End >= f){
    props.history.push("/user/analytics/"+User1._id)   

      }
      else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear()){
        if( d[0].End[3]+""+d[0].End[4] >a.getUTCMonth()+1 ){
      props.history.push("/user/analytics/"+User1._id)   

        }
        else{
          if(d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1){
            if( d[0].End[0]+""+d[0].End[1] >=a.getDate()){
          props.history.push("/user/analytics/"+User1._id)   

            }
            else{
              document.getElementById("myDivUmer12").style.display= "block"

              // localStorage.setItem("request123",JSON.stringify(1) )
              // props.history.push("/user/request-for-pro-item")
              // swal("Sorry First You Buy")
  
            }
          }
          else{
            document.getElementById("myDivUmer12").style.display= "block"

            // localStorage.setItem("request123",JSON.stringify(1) )
            //   props.history.push("/user/request-for-pro-item")
            //   swal("Sorry First You Buy")
  
            }
          }
        }
      else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear() && d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ){
          if( d[0].End[0]+""+d[0].End[1] >=a.getDate()){
        props.history.push("/user/analytics/"+User1._id)   

        }
        else{
          document.getElementById("myDivUmer12").style.display= "block"

          // localStorage.setItem("request123",JSON.stringify(1) )
          // props.history.push("/user/request-for-pro-item")
          // swal("Sorry First You Buy")
  
        }
      }
      else{
        document.getElementById("myDivUmer12").style.display= "block"

        // localStorage.setItem("request123",JSON.stringify(1) )
        //   props.history.push("/user/request-for-pro-item")
        //   swal("Sorry First You Buy")
  
      }
    }
    else{
      document.getElementById("myDivUmer12").style.display= "block"

      // localStorage.setItem("request123",JSON.stringify(1) )
      //   props.history.push("/user/request-for-pro-item")
      //   swal("Sorry First You Buy")
  }
  }
  else if(e === 2){


    const d =  JSON.parse(localStorage.getItem("User")).ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
    console.log(d)
    
      
    if (d.length > 0){
      let a = new Date()
      let f= a.getDate() +" "+(a.getUTCMonth()+1)+ " "+a.getUTCFullYear()
      console.log(d[0].End[0]+""+d[0].End[1] >= a.getDate(), d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ,d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear())
      if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] > a.getUTCFullYear()     ){
      // if(d[0].End && d[0].End >= f){
        props.history.push("/user/connect/myprofile/"+User1.uid)

      }
      else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear()){
        if( d[0].End[3]+""+d[0].End[4] >a.getUTCMonth()+1 ){
          props.history.push("/user/connect/myprofile/"+User1.uid)

        }
        else{
          if(d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1){
            if( d[0].End[0]+""+d[0].End[1] >=a.getDate()){
              props.history.push("/user/connect/myprofile/"+User1.uid)

            }
            else{
              document.getElementById("myDivUmer").style.display= "block"

              // localStorage.setItem("request123",JSON.stringify(1) )
              // props.history.push("/user/request-for-pro-item")
              // swal("Sorry First You Buy")
  
            }
          }
          else{
            document.getElementById("myDivUmer").style.display= "block"

            // localStorage.setItem("request123",JSON.stringify(1) )
            //   props.history.push("/user/request-for-pro-item")
            //   swal("Sorry First You Buy")
  
            }
          }
        }
      else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear() && d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ){
          if( d[0].End[0]+""+d[0].End[1] >=a.getDate()){
            props.history.push("/user/connect/myprofile/"+User1.uid)

        }
        else{
          document.getElementById("myDivUmer").style.display= "block"

          // localStorage.setItem("request123",JSON.stringify(1) )
          // props.history.push("/user/request-for-pro-item")
          // swal("Sorry First You Buy")
  
        }
      }
      else{
        document.getElementById("myDivUmer").style.display= "block"

        // localStorage.setItem("request123",JSON.stringify(1) )
        //   props.history.push("/user/request-for-pro-item")
        //   swal("Sorry First You Buy")
  
      }
    }
    else{
      document.getElementById("myDivUmer").style.display= "block"

      // localStorage.setItem("request123",JSON.stringify(1) )
      //   props.history.push("/user/request-for-pro-item")
      //   swal("Sorry First You Buy")
  }
  }
  else{

  }
}
 

const setChanger8 = (e)=>{
  document.getElementById("myDivUmer12").style.display= "block"
}



        return (
            






      <div className="nm1"
        // onScroll={onScrolll()}
        >
                       {!dispaly?
                        <Loader
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={10000} //3 secs
        className="loader-1"
        // style={{position : "fixed",    left: "35%",top:"20%",zIndex: "9999"}}
      /> 
      :""
}

        {/* Slider */}



        <center>
          <div className="container-fuild cvb1" style={{background : "white",width: "100%" , bottom : "0px",right : "0px",height : "62px" , position : "fixed" ,zIndex : "99999",padding : "6px 14px"}}>
                <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3" style={{width : "25%" , padding : "5px 0px"}} onClick={()=>setRouting1(1)}>
                <DashboardIcon/>  <br /> Dashboard        

                {/* <i className="zmdi zmdi-nature-people" /> */}
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3" style={{width : "25%" , padding : "5px 0px"}} onClick={()=>setRouting1(2)}>
                  <PeopleAltIcon/>  <br /> Connection   
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3" style={{width : "25%" , padding : "5px 0px"}} onClick={()=>setRouting1(3)}>
                <AssessmentIcon/>  <br /> Analytics        
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3" style={{width : "25%" , padding : "5px 0px"}} onClick={()=>setRouting1(4)}>
                <SortIcon/>  <br />Sort        
                </div>
                </div>
              </div>
        </center>







        <form  onSubmit={(e)=>SubmitDataMessage(e)}>

<section>
    <div className="container" style={{margin : "20px auto" , width : "97%"}}>
            <div className="row justify-content-center">

<div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 ">
                <div className="form-row">
                          <div className="form-group col-md-6">
                        <label for="inputPassword4">Name</label>
                        <input type="text" required value={Name} onChange={(e)=>setname(e.target.value) }  className="form-control" id="inputPassword4" placeholder="Enter Full Name"/>
                          </div>
                          <div className="form-group col-md-6">
                        <label for="inputPassword4">Email</label>
                        <input type="email" required value={Email} onChange={(e)=>setEmail(e.target.value) }  className="form-control" id="inputPassword4" placeholder="Enter Email"/>
                      </div>
                </div>
                <div className="form-row">
                          <div className="form-group col-md-6">
                        <label for="inputPassword4">Phone</label>
                        <input type="number" required value={Phone} onChange={(e)=>setPhone(e.target.value) }  className="form-control" id="inputPassword4" placeholder="Enter Phone"/>
                          </div>
                          <div className="form-group col-md-6">
                        <label for="inputPassword4">Address</label>
                        <input type="text" required value={Address} onChange={(e)=>setAddress(e.target.value) }  className="form-control" id="inputPassword4" placeholder="Enter Address"/>
                      </div>
                </div>
                <div className="form-row">
                          <div className="form-group col-md-6">
                        <label for="inputPassword4">You Selected this Category</label>
                        {Num === 1 ? 
                        <input type="text" value="Pro Feathers"  className="form-control" id="inputPassword4"/>

                        : ""

                        }
                        {Num === 2 ? 
                        <input type="text" value="Pro Feathers"  className="form-control" id="inputPassword4"/>

                        : ""

                        }
                        {Num === 3 ? 
                        <input type="text" value="Pro Feathers"  className="form-control" id="inputPassword4"/>

                        : ""

                        }
                        {Num === 4 ? 
                        <input type="text" value="Pro Feathers"  className="form-control" id="inputPassword4"/>

                        : ""

                        }
                          </div>
                          <div className="form-group col-md-6">
                        <label for="inputPassword4">Select the Time period</label>
                        <select id="c_country" className="form-control" value={list1} onChange={(e)=>setlist1(e.target.value)} >
                                {data.map((res,i)=>{
                                    
                                    if(Num === 1 && res.listName === "Pro Feathers") return     <option value={res.listMonth +" "+res.listPrice +" "+res.Name}>{res.listMonth} {res.listMonth !== 1 ? res.Name +"s " : res.Name } for Rs : {res.listPrice}</option>
                                    else if(Num === 2 && res.listName === "Pro Feathers") return  <option value={res.listMonth +" "+res.listPrice +" "+res.Name}>{res.listMonth} {res.listMonth !== 1 ? res.Name +"s " : res.Name } for Rs : {res.listPrice}</option>
                                    else if(Num === 3 && res.listName === "Pro Feathers") return  <option value={res.listMonth +" "+res.listPrice +" "+res.Name}>{res.listMonth} {res.listMonth !== 1 ? res.Name +"s " : res.Name } for Rs : {res.listPrice}</option> 
                                    else if(Num === 4 && res.listName === "Pro Feathers") return <option value={res.listMonth +" "+res.listPrice +" "+res.Name}>{res.listMonth} {res.listMonth !== 1 ? res.Name +"s " : res.Name } for Rs : {res.listPrice}</option>
                                    else return
                                })
                                }
                    </select>
                          </div>
                </div>
                <center>
          
            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-10" style={{margin : "20px 0px" }}>
                   
                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        {/* <button className="button11" style={{width : "200px" , background : "rgb(211, 211, 211)" , color  : "black" , borderRadius :  "3px" , border : "3px solid black",fontSize : "18px"}}>Open</button> */}
                        {dispaly ? 
                            <button className="button11" type="submit" style={{width : "200px",   background : "rgb(0, 0, 0)" ,  color  : "white", borderRadius :  "3px" , border : "3px solid rgb(211, 211, 211)",fontSize : "18px"}}>Buy</button>
                            
                            :
                            <span className="button11" style={{width : "200px",   background : "rgb(0, 0, 0)" ,  color  : "white", borderRadius :  "3px" , border : "3px solid rgb(211, 211, 211)",fontSize : "18px"}}>Buy</span>

                        }                    </div>
                    <br/>
            </div>
           </center>
         
                
        </div>

</div>
</div>
</section>
</form>



          
        


        {/* Back to top */}
        <div className="btn-back-to-top" id="myBtn">
          <span className="symbol-btn-back-to-top">
            <i className="zmdi zmdi-chevron-up" />
          </span>
        </div>
        {/* Modal1 */}
        <div className="wrap-modal1 js-modal1 p-t-60 p-b-20">
          <div className="overlay-modal1 js-hide-modal1" />
          <div className="container">
            <div className="bg0 p-t-60 p-b-30 p-lr-15-lg how-pos3-parent">
              <button className="how-pos3 hov3 trans-04 js-hide-modal1">
                <img src="images/icons/icon-close.png" alt="CLOSE" />
              </button>
              <div className="row">
                <div className="col-md-6 col-lg-7 p-b-30">
                  <div className="p-l-25 p-r-30 p-lr-0-lg">
                    <div className="wrap-slick3 flex-sb flex-w">
                      <div className="wrap-slick3-dots" />
                      <div className="wrap-slick3-arrows flex-sb-m flex-w" />
                      <div className="slick3 gallery-lb">
                        <div className="item-slick3" data-thumb="images/product-detail-01.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-01.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-01.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-02.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-02.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-02.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-03.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-03.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-03.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5 p-b-30">
                  <div className="p-r-50 p-t-5 p-lr-0-lg">
                    <h4 className="mtext-105 cl2 js-name-detail p-b-14">
                      Lightweight Jacket
                    </h4>
                    <span className="mtext-106 cl2">
                      $58.79
                    </span>
                    <p className="stext-102 cl3 p-t-23">
                      Nulla eget sem vitae eros pharetra viverra. Nam vitae luctus ligula. Mauris consequat ornare feugiat.
                    </p>
                    {/*  */}
                    <div className="p-t-33">
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Size
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Size S</option>
                              <option>Size M</option>
                              <option>Size L</option>
                              <option>Size XL</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Color
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Red</option>
                              <option>Blue</option>
                              <option>White</option>
                              <option>Grey</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-204 flex-w flex-m respon6-next">
                          <div className="wrap-num-product flex-w m-r-20 m-tb-10">
                            <div className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-minus" />
                            </div>
                            <input className="mtext-104 cl3 txt-center num-product" type="number" name="num-product" defaultValue={1} />
                            <div className="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-plus" />
                            </div>
                          </div>
                          <button className="flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04 js-addcart-detail">
                            Add to cart
                          </button>
                        </div>
                      </div>	
                    </div>
                    {/*  */}
                    <div className="flex-w flex-m p-l-100 p-t-40 respon7">
                      <div className="flex-m bor9 p-r-10 m-r-11">
                        <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 js-addwish-detail tooltip100" data-tooltip="Add to Wishlist">
                          <i className="zmdi zmdi-favorite" />
                        </a>
                      </div>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Facebook">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Twitter">
                        <i className="fa fa-twitter" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Google Plus">
                        <i className="fa fa-google-plus" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
 






        )
    
}

export default Home;