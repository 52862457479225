import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"
import swal from 'sweetalert';
// import $ from 'jquery';
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
// import a111 from "./../assets/images/1.jpeg"

import ClearIcon from '@material-ui/icons/Clear';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import nu1 from "./../assets/images/nu1.jpeg"

import "react-multi-carousel/lib/styles.css";
import "react-multi-carousel/lib/styles.css";
import "./../assets/css/main.css"
import "./../assets/css/util.css"
import "./../assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css"



import k1 from "./../assets/images/svg/Payments/SELECT JAZZ CASH LOGO/jazzcash.png"

import k4 from "./../assets/images/k4.png"
import k5 from "./../assets/images/k5.png"



// import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"

import "./../assets/vendor/css-hamburgers/hamburgers.min.css"

import "./../assets/fonts/iconic/css/material-design-iconic-font.min.css"
import "./../assets/vendor/bootstrap/css/bootstrap.min.css"
import "./../assets/vendor/select2/select2.min.css"
import "./../assets/vendor/slick/slick.css"
import "./../assets/vendor/perfect-scrollbar/perfect-scrollbar.css"

// import Logo from "./../assets/images/icons/logo-01.png"

// import b1 from "./../assets/images/banner-01.jpg"
// import b2 from "./../assets/images/banner-02.jpg"
// import b3 from "./../assets/images/banner-03.jpg"


import a1 from "./../assets/images/a1.png"
import a2 from "./../assets/images/a2.png"
import a3 from "./../assets/images/a3.png"
import a4 from "./../assets/images/a4.png"
import a5 from "./../assets/images/a5.png"
import a6 from "./../assets/images/a6.png"
import a7 from "./../assets/images/a7.png"
import a8 from "./../assets/images/a8.png"

import a9 from "./../assets/images/a9.png"

// import a11 from "./../assets/images/a11.png"
import a21 from "./../assets/images/a21.png"
import a22 from "./../assets/images/a22.png"
import a23 from "./../assets/images/a23.png"
import a24 from "./../assets/images/a24.png"
import a25 from "./../assets/images/a25.png"
// import a27 from "./../assets/images/a27.png"


import z1 from "./../assets/images/z1.png"
import z2 from "./../assets/images/z2.png"
import z3 from "./../assets/images/z3.png"
// import z4 from "./../assets/images/z4.png"


import c1 from "./../assets/images/c1.png"
import c2 from "./../assets/images/c2.png"
// import c3 from "./../assets/images/c3.png"


import ln1 from "./../assets/images/svg/social/f linkedin.svg"
import tw1 from "./../assets/images/svg/social/f twitter.svg"
import in1 from "./../assets/images/svg/social/f instagram.svg"
import pi1 from "./../assets/images/svg/social/f pinterest.svg"
import tt1 from "./../assets/images/svg/social/twitch (1).svg"
import tk1 from "./../assets/images/svg/social/tiktok.svg"
import you1 from "./../assets/images/svg/social/youtube (2).svg"
import sc1 from "./../assets/images/svg/social/snapchat.svg"
import fb1 from "./../assets/images/svg/social/f facebook.svg"


import bk1 from "./../assets/images/svg/Payments/bank (9).svg"
import ca1 from "./../assets/images/svg/Payments/cashapp.svg"
import ep1 from "./../assets/images/svg/Payments/easypaisa.png"
import jc1 from "./../assets/images/svg/Payments/SELECT JAZZ CASH LOGO/jazzcash.png"
import ve1 from "./../assets/images/svg/Payments/venmo.svg"
import pp1 from "./../assets/images/svg/Payments/paypal (1).svg"



// import cu1 from "./../assets/images/svg/MORE/custom link.svg"
import lt1 from "./../assets/images/svg/MORE/linktree-2.svg"
import vi1 from "./../assets/images/svg/MORE/video.svg"
import we1 from "./../assets/images/svg/MORE/WEBSITE.svg"
// import ve1 from "./../assets/images/svg/MORE/custom link.svg"
// import ve1 from "./../assets/images/svg/MORE/custom link.svg"


import call1 from "./../assets/images/svg/cotact info/call.svg"
import cd1 from "./../assets/images/svg/cotact info/ccard.svg"
import lo1 from "./../assets/images/svg/cotact info/location.svg"
import mail1 from "./../assets/images/svg/cotact info/mail.svg"
import wa1 from "./../assets/images/svg/cotact info/whatapps.svg"
import mess1 from "./../assets/images/svg/cotact info/message (2).svg"





const Home = ( props ) => {
    const [myRef,setRef] = useState( React.createRef())
    const [scrollTop1,setscrollTop] = useState(0)
    const [email , setemail] = useState("")
    const [Name , setName] = useState("")
    const [Image , setImage] = useState("")
    const [pass , setpass] = useState("")
    const [dispaly ,  setdispaly ] = useState(true)
    const [User1 ,  setUser1 ] = useState({})
    const [data ,  setdata ] = useState({})
    const [Num ,  setNum ] = useState(0)
    const [n , setn] = useState("")
    const [n1 , setn1] = useState("")
    const [n2 , setn2] = useState("")
    const [Conname1 , setConname1] = useState("")
    
    const [Arr1111 ,  setArr1111 ] = useState([])
    const [AllDataArr , setAllDataArr] = useState([])



    const [disUser ,  setdisUser ] = useState(true)

    const [Arr1 ,  setArr1 ] = useState([])
    const [Arr11 ,  setArr11 ] = useState([])
    const [Arr22 ,  setArr22 ] = useState([])
    
    const [email11 , setemail11] = useState("")
    const [Name11 , setName11] = useState("")
    const [Image11 , setImage11] = useState("")

    const [Ar,setAr] = useState([])

    const [dispaly1 ,  setdispaly1 ] = useState(false)

    const [text,settext] = useState([0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0])

    const [email1 , setemail1] = useState(["","","","","","","","","","","",""])
    const [Name1 , setName1] = useState(["","","","","","","","","","","",""])
    const [Nu , setNu] = useState(0)
    
    const [Nu123 ,  setNu123 ] = useState(0)
    // const [Name123 , setName123] = useState("")
    // const [Number123 , setNumber123] = useState("")


    // const [Name1234 , setName1234] = useState("")
    // const [AccountNumber123 , setAccountNumber123] = useState("")
    // const [BankName123 , setBankName123] = useState("")
    


    const [ConName , setConname] = useState("")
    const [ConEmail , setConEmail] = useState("")
    const [Phone , setPhone] = useState("")
    const [Address , setAddress] = useState("")
    const [Gender , setGender] = useState("")
    const [City , setCity] = useState("")
    const [Country , setCountry] = useState("Pakistan")
    const [Role , setRole] = useState("")
    const [ConImage , setConImage] = useState("")
    const [ConImage1 , setConImage1] = useState("")
    const [ConImage12 , setConImage12] = useState("")
    // const [email11 , setemail11] = useState("")
    const [Namea , setNamea] = useState("")

    const [ConArr1 ,  setConArr1 ] = useState([])
    // const [Num ,  setNum ] = useState(21)
    const [Conrange1,setConRange1] = useState([0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0])
    const [ArCon,setArCon] = useState([])
    
    const [as ,  setas ] = useState(true)
    const [disUser1 ,  setdisUser1 ] = useState(false)

    const [email12 , setemail12] = useState("")



    const [dispaly2 ,  setdispaly2 ] = useState(false)
    const [UserPhoneCode ,  setUserPhoneCode ] = useState("92")
    const [PhoneCode ,  setPhoneCode ] = useState([
      {
        code: 'PK', map: '🇵🇰', phoneCode: 92, value: 'Pakistan',
      },
      {
        code: 'AF', map: '🇦🇫', phoneCode: 93, value: 'Afghanistan',
      },
      {
        code: 'AL', map: '🇦🇱', phoneCode: 355, value: 'Albania',
      },
      {
        code: 'DZ', map: '🇩🇿', phoneCode: 213, value: 'Algeria',
      },
      {
        code: 'AS', map: '🇦🇸', phoneCode: 1684, value: 'American Samoa',
      },
      {
        code: 'AD', map: '🇦🇩', phoneCode: 376, value: 'Andorra',
      },
      {
        code: 'AO', map: '🇦🇴', phoneCode: 244, value: 'Angola',
      },
      {
        code: 'AI', map: '🇦🇮', phoneCode: 1264, value: 'Anguilla',
      },
      {
        code: 'AQ', map: '🇦🇶', phoneCode: 0, value: 'Antarctica',
      },
      {
        code: 'AR', map: '🇦🇷', phoneCode: 54, value: 'Argentina',
      },
      {
        code: 'AM', map: '🇦🇲', phoneCode: 374, value: 'Armenia',
      },
      {
        code: 'AW', map: '🇦🇼', phoneCode: 297, value: 'Aruba',
      },
      {
        code: 'AU', map: '🇦🇺', phoneCode: 61, value: 'Australia',
      },
      {
        code: 'AT', map: '🇦🇹', phoneCode: 43, value: 'Austria',
      },
      {
        code: 'AZ', map: '🇦🇿', phoneCode: 994, value: 'Azerbaijan',
      },
      {
        code: 'BH', map: '🇧🇭', phoneCode: 973, value: 'Bahrain',
      },
      {
        code: 'BD', map: '🇧🇩', phoneCode: 880, value: 'Bangladesh',
      },
      {
        code: 'BB', map: '🇧🇧', phoneCode: 1246, value: 'Barbados',
      },
      {
        code: 'BY', map: '🇧🇾', phoneCode: 375, value: 'Belarus',
      },
      {
        code: 'BE', map: '🇧🇪', phoneCode: 32, value: 'Belgium',
      },
      {
        code: 'BZ', map: '🇧🇿', phoneCode: 501, value: 'Belize',
      },
      {
        code: 'BJ', map: '🇧🇯', phoneCode: 229, value: 'Benin',
      },
      {
        code: 'BM', map: '🇧🇲', phoneCode: 1441, value: 'Bermuda',
      },
      {
        code: 'BT', map: '🇧🇹', phoneCode: 975, value: 'Bhutan',
      },
      {
        code: 'BO', map: '🇧🇴', phoneCode: 591, value: 'Bolivia',
      },
      {
        code: 'BW', map: '🇧🇼', phoneCode: 267, value: 'Botswana',
      },
      {
        code: 'BV', map: '🇧🇻', phoneCode: 0, value: 'Bouvet Island',
      },
      {
        code: 'BR', map: '🇧🇷', phoneCode: 55, value: 'Brazil',
      },
      {
        code: 'IO', map: '🇮🇴', phoneCode: 246, value: 'British Indian Ocean Territory',
      },
      {
        code: 'BN', map: '🇧🇳', phoneCode: 673, value: 'Brunei',
      },
      {
        code: 'BG', map: '🇧🇬', phoneCode: 359, value: 'Bulgaria',
      },
      {
        code: 'BF', map: '🇧🇫', phoneCode: 226, value: 'Burkina Faso',
      },
      {
        code: 'BI', map: '🇧🇮', phoneCode: 257, value: 'Burundi',
      },
      {
        code: 'KH', map: '🇰🇭', phoneCode: 855, value: 'Cambodia',
      },
      {
        code: 'CM', map: '🇨🇲', phoneCode: 237, value: 'Cameroon',
      },
      {
        code: 'CA', map: '🇨🇦', phoneCode: 1, value: 'Canada',
      },
      {
        code: 'CV', map: '🇨🇻', phoneCode: 238, value: 'Cape Verde',
      },
      {
        code: 'KY', map: '🇰🇾', phoneCode: 1345, value: 'Cayman Islands',
      },
      {
        code: 'CF', map: '🇨🇫', phoneCode: 236, value: 'Central African Republic',
      },
      {
        code: 'TD', map: '🇹🇩', phoneCode: 235, value: 'Chad',
      },
      {
        code: 'CL', map: '🇨🇱', phoneCode: 56, value: 'Chile',
      },
      {
        code: 'CN', map: '🇨🇳', phoneCode: 86, value: 'China',
      },
      {
        code: 'CX', map: '🇨🇽', phoneCode: 61, value: 'Christmas Island',
      },
      {
        code: 'CC', map: '🇨🇨', phoneCode: 672, value: 'Cocos (Keeling) Islands',
      },
      {
        code: 'CO', map: '🇨🇴', phoneCode: 57, value: 'Colombia',
      },
      {
        code: 'KM', map: '🇰🇲', phoneCode: 269, value: 'Comoros',
      },
      {
        code: 'CK', map: '🇨🇰', phoneCode: 682, value: 'Cook Islands',
      },
      {
        code: 'CR', map: '🇨🇷', phoneCode: 506, value: 'Costa Rica',
      },
      {
        code: 'CU', map: '🇨🇺', phoneCode: 53, value: 'Cuba',
      },
      {
        code: 'CY', map: '🇨🇾', phoneCode: 357, value: 'Cyprus',
      },
      {
        code: 'DK', map: '🇩🇰', phoneCode: 45, value: 'Denmark',
      },
      {
        code: 'DJ', map: '🇩🇯', phoneCode: 253, value: 'Djibouti',
      },
      {
        code: 'DM', map: '🇩🇲', phoneCode: 1767, value: 'Dominica',
      },
      {
        code: 'DO', map: '🇩🇴', phoneCode: 1809, value: 'Dominican Republic',
      },
      {
        code: 'EC', map: '🇪🇨', phoneCode: 593, value: 'Ecuador',
      },
      {
        code: 'EG', map: '🇪🇬', phoneCode: 20, value: 'Egypt',
      },
      {
        code: 'SV', map: '🇸🇻', phoneCode: 503, value: 'El Salvador',
      },
      {
        code: 'GQ', map: '🇬🇶', phoneCode: 240, value: 'Equatorial Guinea',
      },
      {
        code: 'ER', map: '🇪🇷', phoneCode: 291, value: 'Eritrea',
      },
      {
        code: 'EE', map: '🇪🇪', phoneCode: 372, value: 'Estonia',
      },
      {
        code: 'ET', map: '🇪🇹', phoneCode: 251, value: 'Ethiopia',
      },
      {
        code: 'FK', map: '🇫🇰', phoneCode: 500, value: 'Falkland Islands',
      },
      {
        code: 'FO', map: '🇫🇴', phoneCode: 298, value: 'Faroe Islands',
      },
      {
        code: 'FI', map: '🇫🇮', phoneCode: 358, value: 'Finland',
      },
      {
        code: 'FR', map: '🇫🇷', phoneCode: 33, value: 'France',
      },
      {
        code: 'GF', map: '🇬🇫', phoneCode: 594, value: 'French Guiana',
      },
      {
        code: 'PF', map: '🇵🇫', phoneCode: 689, value: 'French Polynesia',
      },
      {
        code: 'TF', map: '🇹🇫', phoneCode: 0, value: 'French Southern Territories',
      },
      {
        code: 'GA', map: '🇬🇦', phoneCode: 241, value: 'Gabon',
      },
      {
        code: 'GE', map: '🇬🇪', phoneCode: 995, value: 'Georgia',
      },
      {
        code: 'DE', map: '🇩🇪', phoneCode: 49, value: 'Germany',
      },
      {
        code: 'GH', map: '🇬🇭', phoneCode: 233, value: 'Ghana',
      },
      {
        code: 'GI', map: '🇬🇮', phoneCode: 350, value: 'Gibraltar',
      },
      {
        code: 'GR', map: '🇬🇷', phoneCode: 30, value: 'Greece',
      },
      {
        code: 'GL', map: '🇬🇱', phoneCode: 299, value: 'Greenland',
      },
      {
        code: 'GD', map: '🇬🇩', phoneCode: 1473, value: 'Grenada',
      },
      {
        code: 'GP', map: '🇬🇵', phoneCode: 590, value: 'Guadeloupe',
      },
      {
        code: 'GU', map: '🇬🇺', phoneCode: 1671, value: 'Guam',
      },
      {
        code: 'GT', map: '🇬🇹', phoneCode: 502, value: 'Guatemala',
      },
      {
        code: 'GN', map: '🇬🇳', phoneCode: 224, value: 'Guinea',
      },
      {
        code: 'GW', map: '🇬🇼', phoneCode: 245, value: 'Guinea-Bissau',
      },
      {
        code: 'GY', map: '🇬🇾', phoneCode: 592, value: 'Guyana',
      },
      {
        code: 'HT', map: '🇭🇹', phoneCode: 509, value: 'Haiti',
      },
      {
        code: 'HN', map: '🇭🇳', phoneCode: 504, value: 'Honduras',
      },
      {
        code: 'HU', map: '🇭🇺', phoneCode: 36, value: 'Hungary',
      },
      {
        code: 'IS', map: '🇮🇸', phoneCode: 354, value: 'Iceland',
      },
      {
        code: 'IN', map: '🇮🇳', phoneCode: 91, value: 'India',
      },
      {
        code: 'ID', map: '🇮🇩', phoneCode: 62, value: 'Indonesia',
      },
      {
        code: 'IR', map: '🇮🇷', phoneCode: 98, value: 'Iran',
      },
      {
        code: 'IQ', map: '🇮🇶', phoneCode: 964, value: 'Iraq',
      },
      {
        code: 'IE', map: '🇮🇪', phoneCode: 353, value: 'Ireland',
      },
      {
        code: 'IL', map: '🇮🇱', phoneCode: 972, value: 'Israel',
      },
      {
        code: 'IT', map: '🇮🇹', phoneCode: 39, value: 'Italy',
      },
      {
        code: 'JM', map: '🇯🇲', phoneCode: 1876, value: 'Jamaica',
      },
      {
        code: 'JP', map: '🇯🇵', phoneCode: 81, value: 'Japan',
      },
      {
        code: 'JO', map: '🇯🇴', phoneCode: 962, value: 'Jordan',
      },
      {
        code: 'KZ', map: '🇰🇿', phoneCode: 7, value: 'Kazakhstan',
      },
      {
        code: 'KE', map: '🇰🇪', phoneCode: 254, value: 'Kenya',
      },
      {
        code: 'KI', map: '🇰🇮', phoneCode: 686, value: 'Kiribati',
      },
      {
        code: 'KW', map: '🇰🇼', phoneCode: 965, value: 'Kuwait',
      },
      {
        code: 'KG', map: '🇰🇬', phoneCode: 996, value: 'Kyrgyzstan',
      },
      {
        code: 'LA', map: '🇱🇦', phoneCode: 856, value: 'Laos',
      },
      {
        code: 'LV', map: '🇱🇻', phoneCode: 371, value: 'Latvia',
      },
      {
        code: 'LB', map: '🇱🇧', phoneCode: 961, value: 'Lebanon',
      },
      {
        code: 'LS', map: '🇱🇸', phoneCode: 266, value: 'Lesotho',
      },
      {
        code: 'LR', map: '🇱🇷', phoneCode: 231, value: 'Liberia',
      },
      {
        code: 'LY', map: '🇱🇾', phoneCode: 218, value: 'Libya',
      },
      {
        code: 'LI', map: '🇱🇮', phoneCode: 423, value: 'Liechtenstein',
      },
      {
        code: 'LT', map: '🇱🇹', phoneCode: 370, value: 'Lithuania',
      },
      {
        code: 'LU', map: '🇱🇺', phoneCode: 352, value: 'Luxembourg',
      },
      {
        code: 'MK', map: '🇲🇰', phoneCode: 389, value: 'Macedonia',
      },
      {
        code: 'MG', map: '🇲🇬', phoneCode: 261, value: 'Madagascar',
      },
      {
        code: 'MW', map: '🇲🇼', phoneCode: 265, value: 'Malawi',
      },
      {
        code: 'MY', map: '🇲🇾', phoneCode: 60, value: 'Malaysia',
      },
      {
        code: 'MV', map: '🇲🇻', phoneCode: 960, value: 'Maldives',
      },
      {
        code: 'ML', map: '🇲🇱', phoneCode: 223, value: 'Mali',
      },
      {
        code: 'MT', map: '🇲🇹', phoneCode: 356, value: 'Malta',
      },
      {
        code: 'MH', map: '🇲🇭', phoneCode: 692, value: 'Marshall Islands',
      },
      {
        code: 'MQ', map: '🇲🇶', phoneCode: 596, value: 'Martinique',
      },
      {
        code: 'MR', map: '🇲🇷', phoneCode: 222, value: 'Mauritania',
      },
      {
        code: 'MU', map: '🇲🇺', phoneCode: 230, value: 'Mauritius',
      },
      {
        code: 'YT', map: '🇾🇹', phoneCode: 269, value: 'Mayotte',
      },
      {
        code: 'MX', map: '🇲🇽', phoneCode: 52, value: 'Mexico',
      },
      {
        code: 'FM', map: '🇫🇲', phoneCode: 691, value: 'Micronesia',
      },
      {
        code: 'MD', map: '🇲🇩', phoneCode: 373, value: 'Moldova',
      },
      {
        code: 'MC', map: '🇲🇨', phoneCode: 377, value: 'Monaco',
      },
      {
        code: 'MN', map: '🇲🇳', phoneCode: 976, value: 'Mongolia',
      },
      {
        code: 'MS', map: '🇲🇸', phoneCode: 1664, value: 'Montserrat',
      },
      {
        code: 'MA', map: '🇲🇦', phoneCode: 212, value: 'Morocco',
      },
      {
        code: 'MZ', map: '🇲🇿', phoneCode: 258, value: 'Mozambique',
      },
      {
        code: 'NA', map: '🇳🇦', phoneCode: 264, value: 'Namibia',
      },
      {
        code: 'NR', map: '🇳🇷', phoneCode: 674, value: 'Nauru',
      },
      {
        code: 'NP', map: '🇳🇵', phoneCode: 977, value: 'Nepal',
      },
      {
        code: 'NC', map: '🇳🇨', phoneCode: 687, value: 'New Caledonia',
      },
      {
        code: 'NZ', map: '🇳🇿', phoneCode: 64, value: 'New Zealand',
      },
      {
        code: 'NI', map: '🇳🇮', phoneCode: 505, value: 'Nicaragua',
      },
      {
        code: 'NE', map: '🇳🇪', phoneCode: 227, value: 'Niger',
      },
      {
        code: 'NG', map: '🇳🇬', phoneCode: 234, value: 'Nigeria',
      },
      {
        code: 'NU', map: '🇳🇺', phoneCode: 683, value: 'Niue',
      },
      {
        code: 'NF', map: '🇳🇫', phoneCode: 672, value: 'Norfolk Island',
      },
      {
        code: 'MP', map: '🇲🇵', phoneCode: 1670, value: 'Northern Mariana Islands',
      },
      {
        code: 'NO', map: '🇳🇴', phoneCode: 47, value: 'Norway',
      },
      {
        code: 'OM', map: '🇴🇲', phoneCode: 968, value: 'Oman',
      },
     
      {
        code: 'PW', map: '🇵🇼', phoneCode: 680, value: 'Palau',
      },
      {
        code: 'PA', map: '🇵🇦', phoneCode: 507, value: 'Panama',
      },
      {
        code: 'PY', map: '🇵🇾', phoneCode: 595, value: 'Paraguay',
      },
      {
        code: 'PE', map: '🇵🇪', phoneCode: 51, value: 'Peru',
      },
      {
        code: 'PH', map: '🇵🇭', phoneCode: 63, value: 'Philippines',
      },
      {
        code: 'PL', map: '🇵🇱', phoneCode: 48, value: 'Poland',
      },
      {
        code: 'PT', map: '🇵🇹', phoneCode: 351, value: 'Portugal',
      },
      {
        code: 'PR', map: '🇵🇷', phoneCode: 1787, value: 'Puerto Rico',
      },
      {
        code: 'QA', map: '🇶🇦', phoneCode: 974, value: 'Qatar',
      },
      {
        code: 'RO', map: '🇷🇴', phoneCode: 40, value: 'Romania',
      },
      {
        code: 'RU', map: '🇷🇺', phoneCode: 70, value: 'Russia',
      },
      {
        code: 'RW', map: '🇷🇼', phoneCode: 250, value: 'Rwanda',
      },
      {
        code: 'WS', map: '🇼🇸', phoneCode: 684, value: 'Samoa',
      },
      {
        code: 'SM', map: '🇸🇲', phoneCode: 378, value: 'San Marino',
      },
      {
        code: 'SA', map: '🇸🇦', phoneCode: 966, value: 'Saudi Arabia',
      },
      {
        code: 'SN', map: '🇸🇳', phoneCode: 221, value: 'Senegal',
      },
      {
        code: 'RS', map: '🇷🇸', phoneCode: 381, value: 'Serbia',
      },
      {
        code: 'SC', map: '🇸🇨', phoneCode: 248, value: 'Seychelles',
      },
      {
        code: 'SL', map: '🇸🇱', phoneCode: 232, value: 'Sierra Leone',
      },
      {
        code: 'SG', map: '🇸🇬', phoneCode: 65, value: 'Singapore',
      },
      {
        code: 'SK', map: '🇸🇰', phoneCode: 421, value: 'Slovakia',
      },
      {
        code: 'SI', map: '🇸🇮', phoneCode: 386, value: 'Slovenia',
      },
      {
        code: 'SB', map: '🇸🇧', phoneCode: 677, value: 'Solomon Islands',
      },
      {
        code: 'SO', map: '🇸🇴', phoneCode: 252, value: 'Somalia',
      },
      {
        code: 'ZA', map: '🇿🇦', phoneCode: 27, value: 'South Africa',
      },
      {
        code: 'SS', map: '🇸🇸', phoneCode: 211, value: 'South Sudan',
      },
      {
        code: 'ES', map: '🇪🇸', phoneCode: 34, value: 'Spain',
      },
      {
        code: 'LK', map: '🇱🇰', phoneCode: 94, value: 'Sri Lanka',
      },
      {
        code: 'SD', map: '🇸🇩', phoneCode: 249, value: 'Sudan',
      },
      {
        code: 'SR', map: '🇸🇷', phoneCode: 597, value: 'Suriname',
      },
      {
        code: 'SZ', map: '🇸🇿', phoneCode: 268, value: 'Swaziland',
      },
      {
        code: 'SE', map: '🇸🇪', phoneCode: 46, value: 'Sweden',
      },
      {
        code: 'CH', map: '🇨🇭', phoneCode: 41, value: 'Switzerland',
      },
      {
        code: 'SY', map: '🇸🇾', phoneCode: 963, value: 'Syria',
      },
      {
        code: 'TW', map: '🇹🇼', phoneCode: 886, value: 'Taiwan',
      },
      {
        code: 'TJ', map: '🇹🇯', phoneCode: 992, value: 'Tajikistan',
      },
      {
        code: 'TZ', map: '🇹🇿', phoneCode: 255, value: 'Tanzania',
      },
      {
        code: 'TH', map: '🇹🇭', phoneCode: 66, value: 'Thailand',
      },
      {
        code: 'TG', map: '🇹🇬', phoneCode: 228, value: 'Togo',
      },
      {
        code: 'TK', map: '🇹🇰', phoneCode: 690, value: 'Tokelau',
      },
      {
        code: 'TO', map: '🇹🇴', phoneCode: 676, value: 'Tonga',
      },
      {
        code: 'TN', map: '🇹🇳', phoneCode: 216, value: 'Tunisia',
      },
      {
        code: 'TR', map: '🇹🇷', phoneCode: 90, value: 'Turkey',
      },
      {
        code: 'TM', map: '🇹🇲', phoneCode: 7370, value: 'Turkmenistan',
      },
      {
        code: 'TV', map: '🇹🇻', phoneCode: 688, value: 'Tuvalu',
      },
      {
        code: 'UG', map: '🇺🇬', phoneCode: 256, value: 'Uganda',
      },
      {
        code: 'UA', map: '🇺🇦', phoneCode: 380, value: 'Ukraine',
      },
      {
        code: 'AE', map: '🇦🇪', phoneCode: 971, value: 'United Arab Emirates',
      },
      {
        code: 'GB', map: '🇬🇧', phoneCode: 44, value: 'United Kingdom',
      },
      {
        code: 'US', map: '🇺🇸', phoneCode: 1, value: 'United States',
      },
      {
        code: 'UY', map: '🇺🇾', phoneCode: 598, value: 'Uruguay',
      },
      {
        code: 'UZ', map: '🇺🇿', phoneCode: 998, value: 'Uzbekistan',
      },
      {
        code: 'VU', map: '🇻🇺', phoneCode: 678, value: 'Vanuatu',
      },
      {
        code: 'VE', map: '🇻🇪', phoneCode: 58, value: 'Venezuela',
      },
      {
        code: 'VN', map: '🇻🇳', phoneCode: 84, value: 'Vietnam',
      },
      {
        code: 'EH', map: '🇪🇭', phoneCode: 212, value: 'Western Sahara',
      },
      {
        code: 'YE', map: '🇾🇪', phoneCode: 967, value: 'Yemen',
      },
      {
        code: 'ZM', map: '🇿🇲', phoneCode: 260, value: 'Zambia',
      },
      {
        code: 'ZW', map: '🇿🇼', phoneCode: 26, value: 'Zimbabwe',
      }])
    

    
    useEffect(() =>{        window.scrollTo(0, 0)

if(! JSON.parse(localStorage.getItem("User"))){

        props.match.history.push("/login")
      }
      else{
        localStorage.setItem("VenderWorkingThose" ,JSON.stringify(123)) 
        setUser1(JSON.parse(localStorage.getItem("User")))
        setNum(JSON.parse(localStorage.getItem("the")))
        setdata(JSON.parse(localStorage.getItem("EditData123")))
        if(JSON.parse(localStorage.getItem("EditDataForAllData1"))){
          setAllDataArr(JSON.parse(localStorage.getItem("EditDataForAllData1")))
        }
        setNu123(JSON.parse(localStorage.getItem("Edit123456")))
        if(JSON.parse(localStorage.getItem("EditData123")).photo){
          setNamea(JSON.parse(localStorage.getItem("EditData123")).Name)
          setImage(JSON.parse(localStorage.getItem("EditData123")).photo)
        }
        if(JSON.parse(localStorage.getItem("EditData123")).user === "user"){
          setemail12(JSON.parse(localStorage.getItem("EditData123")).link.split(".com/")[1])
            setdisUser1(false)
            setdisUser(true)
           
          
        }

        if(JSON.parse(localStorage.getItem("EditData123")).user === "user"){
          // setemail12(JSON.parse(localStorage.getItem("EditData12")).link)
          if(JSON.parse(localStorage.getItem("EditData123")).name === "snapchat"){
            setemail12(JSON.parse(localStorage.getItem("EditData123")).link.split("add/")[1])
          }
          else if(JSON.parse(localStorage.getItem("EditData123")).name === "tiktok"){
            setemail12(JSON.parse(localStorage.getItem("EditData123")).link.split("@")[1])
          }
          else{
            setemail12(JSON.parse(localStorage.getItem("EditData123")).link.split(".com/")[1])
          }

        }else{
          setemail(JSON.parse(localStorage.getItem("EditData123")).link)
          setdisUser1(true)
          setdisUser(true)
        }
        setemail(JSON.parse(localStorage.getItem("EditData123")).link)


        // setemail(JSON.parse(localStorage.getItem("EditData123")).link)
         if(JSON.parse(localStorage.getItem("EditData123")).Name1){
            Name1[0] =(JSON.parse(localStorage.getItem("EditData123")).Name1)
            email1[0] =(JSON.parse(localStorage.getItem("EditData123")).email1)
        }
         if(JSON.parse(localStorage.getItem("EditData123")).Name2){
            Name1[1] =(JSON.parse(localStorage.getItem("EditData123")).Name2)
            email1[1] =(JSON.parse(localStorage.getItem("EditData123")).email2)
        }
         if(JSON.parse(localStorage.getItem("EditData123")).Name3){
            Name1[2] =(JSON.parse(localStorage.getItem("EditData123")).Name3)
            email1[2] =(JSON.parse(localStorage.getItem("EditData123")).email3)
        }
         if(JSON.parse(localStorage.getItem("EditData123")).Name4){
            Name1[3] =(JSON.parse(localStorage.getItem("EditData123")).Name4)
            email1[3] =(JSON.parse(localStorage.getItem("EditData123")).email4)
        }
         if(JSON.parse(localStorage.getItem("EditData123")).Name5){
            Name1[4] =(JSON.parse(localStorage.getItem("EditData123")).Name5)
            email1[4] =(JSON.parse(localStorage.getItem("EditData123")).email5)
        }
         if(JSON.parse(localStorage.getItem("EditData123")).Number){
            setn ( JSON.parse(localStorage.getItem("EditData123")).Name)
            setn1  (JSON.parse(localStorage.getItem("EditData123")).Number)
        }
         if(JSON.parse(localStorage.getItem("EditData123")).BankName){
           setn (JSON.parse(localStorage.getItem("EditData123")).Name)
           setn1(JSON.parse(localStorage.getItem("EditData123")).BankName)
           setn2 ( JSON.parse(localStorage.getItem("EditData123")).AccountNumber)
        }
         if(JSON.parse(localStorage.getItem("EditData123")).name === "Contact"){
            setConname1 (JSON.parse(localStorage.getItem("EditData123")).name1 ?JSON.parse(localStorage.getItem("EditData123")).name1 : "")
            setConname (JSON.parse(localStorage.getItem("EditData123")).fullName)
            setConEmail(JSON.parse(localStorage.getItem("EditData123")).Email)
            setPhone ( JSON.parse(localStorage.getItem("EditData123")).Phone)
            setAddress ( JSON.parse(localStorage.getItem("EditData123")).City)
            // setGender ( JSON.parse(localStorage.getItem("EditData123")).Gender)
            setCity ( JSON.parse(localStorage.getItem("EditData123")).City)
            setCountry ( JSON.parse(localStorage.getItem("EditData123")).Country)
            setRole ( JSON.parse(localStorage.getItem("EditData123")).Role)
            setConImage ( JSON.parse(localStorage.getItem("EditData123")).photo)
            setConImage1 ( JSON.parse(localStorage.getItem("EditData123")).photo)
            setConImage12 ( JSON.parse(localStorage.getItem("EditData123")).photo1 !== null? JSON.parse(localStorage.getItem("EditData123")).photo1 : "")
        
             
            fetch("https://tapitdabit-backend.vercel.app/allUser/"+JSON.parse(localStorage.getItem("User"))._id,{
          method: "GET",
           headers :  {
           "Content-Type" : "application/json" , 
       } ,
      })
      .then(res5=>res5.json())
      .then(res6=>{
        let a = []
        let d =  []
        for (let i = 0; i < JSON.parse(localStorage.getItem("EditData123")).ContactBuss.length; i++) {
        for (let j = 0; j < res6.SocialBuss.length; j++) {
          if(JSON.parse(localStorage.getItem("EditData123")).ContactBuss[i].name === res6.SocialBuss[j].name && JSON.parse(localStorage.getItem("EditData123")).ContactBuss[i].link === res6.SocialBuss[j].link ){
              a.push(JSON.parse(localStorage.getItem("EditData123")).ContactBuss[i])
              
                for (let k = 0; k < 19; k++) {
                  if(k === j)
                  d[j] = 100
                
              }
              
            }
            }
          }
          setConRange1(d)
          console.log(d)

      })
        
        
          }
        if(JSON.parse(localStorage.getItem("EditData123")).user === "link"){
          setdisUser1(true)
          setdisUser(false)
        }
        if(JSON.parse(localStorage.getItem("EditData123")).user === "user"){
          setdisUser1(false)
          setdisUser(true)
        }


        fetch("https://tapitdabit-backend.vercel.app/allUser/"+JSON.parse(localStorage.getItem("User"))._id,{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res5=>res5.json())
        .then(res6=>{
            console.log(res6.AllBuss)
            for (let i = 0; i < res6.AllBuss.length; i++) {
                if(res6.AllBuss[i].sort){
                    text[i] = res6.AllBuss[i].sort
                }
                
                Ar[i]  = 0;
                // range1[i] = 0
            }
            setArr1(res6.AllBuss)
            setArr11(res6.SocialBuss)
            setArr22(res6.ContactBuss)
            



            setConArr1(res6.SocialBuss)
            for (let i = 0; i < res6.SocialBuss.length; i++) {
                ArCon[i]  = 0;
                // range1[i] = 0
            }
        })

      }

      return () => {
        document.getElementById("get1").classList.remove("is-active");
        document.getElementById("get2").style.display = "none";
      }
  
    },[])
    useEffect( () => () => localStorage.removeItem("VenderWorkingThose"), [] );







    const setDis123 = (e) =>{
      if(e === 1){
          setdisUser1(false)
          setdisUser(true)
      }
      else{
          setdisUser(false)
          setdisUser1(true)
    
      }
    }
      

    const setChanger8 = (e)=>{
      document.getElementById("myDivUmer12").style.display= "block"
    }

const  setChange55 = (e,i)=>{
    console.log(e , i ,Name1)
    let a = Name1
    a[i]  =  e
    setName1(a)
    console.log(e , i ,Name1)
    if(dispaly1){
        // setdispaly1(true)
        setdispaly1(false)

    }
  else{
  setdispaly1(true)
//   setdispaly1(false)
  }
}


const  setChange56 = (e,i)=>{
    // console.log(e , i ,email)
    let a = email1
    a[i]  =  e
    setemail1(a)
    console.log(e , i ,email1)
    if(dispaly1){
        // setdispaly1(true)
        setdispaly1(false)

    }
  else{
  setdispaly1(true)
//   setdispaly1(false)
  }
}


















const  setDeleteEK1 = ()=>{




  let A8 = []
  User1.Counter1 && User1.Counter1.map((res343,i)=>{
  if(res343.name === "Contact"){
    if(data.fullName !== res343.fullName || data.ContactBuss.length !== res343.ContactBuss.length || data.Email !== res343.Email || data.Phone !== res343.Phone ){
      console.log("1")
      A8.push( {
        ...res343 
      })
    }
    }
  //   else{
  //     console.log("3")
  //     A8.push( {
  //       ...res343 
  //     })          }
  // }
  // else{
    if(data.name !== res343.name ||  data.link !== res343.link){
      console.log("2")

    A8.push( {
      ...res343 
    })
  }
  // }

})


    const i = Nu123
    console.log(data,User1._id)
    const newData1 = Arr1.filter((data1,j)=> i !== j)
    const Arr11 = []
    const Arr22 = []
    newData1.map((res,p)=>{
      if(res.name === "Contact"){
        Arr22.push(res)
      }
      else{
        Arr11.push(res)

      }
    }) 
    const A = []
    const A1 = []
    User1.DispalyShow1.map((res23,g)=>{
      console.log(res23)
      if(res23.name === "Contact"){
        if(data.fullName === res23.fullName && data.ContactBuss.lenght === res23.ContactBuss.lenght && data.Email === res23.Email && data.Phone === res23.Phone ){
          console.log("1")
          A.push( {
            ...res23 
          })
        }
        else{
          console.log("3")
          A1.push(res23)
        }
      }
      else{
        if(data.name === res23.name &&  data.link === res23.link){
          console.log("2")

        A.push( {
          ...res23 
        })
      }
      else{
        console.log("3")
        A1.push(res23)
      }
      }
    })

    // let b= [...A,...User1.DispalyShow]

    //   let v = b.filter((a, i) => b.findIndex((s) =>{
    //   //  return a.name === s.name && a.link === s.link

    //    if(a.name === "Contact")
    //     return a.fullName === s.fullName && a.Contact.length === s.ContactBuss.length && a.Email === s.Email && a.Phone === s.Phone 
    //   else
    //     return a.name === s.name &&  a.link === s.link

    //   }
      
    //   ) === i)
      
    console.log(A1,A)
    // const newData2 = Arr11.filter(data1=>  data1.link !== data.link )
    // const newData2 =[]
    // Arr11.map(data1=> {
    //   if(  data1.link !== data.link && data1.name !== data.name && data1.Name !== data.Name) {
    //     newData2.push(data1)
    //   }
    // })
    // let AA = []
    // Arr22.map(data1=> {
    //    if(  data1.fullName !== data.fullName && data1.Phone !== data.Phone && data1.Email !== data.Email) {
    //        AA.push(data1)
    //    }
    // })
    // console.log(newData1,"1111111",newData2,"2222222",AA)

    fetch("https://tapitdabit-backend.vercel.app/deleteSocialBussiness",{
        method: "POST" , 
        headers: {
            "Content-Type" : "application/json" ,
        } , 
        body : JSON.stringify({
            id : User1._id,
            AllBuss : newData1,
            SocialBuss : Arr11,
            ContactBuss : Arr22,
            dis : A1,
            d : A8
        })
    })
    .then(res=>res.json())
    .then(res2=>{
    //     // const newData = Arr1.filter(data1=>  data1.name !== data.name && data1.link !== data.link && data1.photo !== data.photo : data1.name !== data.name && data1.fullName !== data.fullName && data1.Phone !== data.Phone && data1.Email !== data.Email)
        setArr1(newData1)
        setArr11(Arr11)
        setArr22(Arr22)
    //     // setAccountAdmin(newData)
    //     // props.history.push("/alluser")
    })
}











const setImage123 = (e)=>{
  setdispaly1(false)
  console.log(e)
  const data = new FormData()
  data.append("file", e)
  data.append("upload_preset", "commerce")
  data.append("cloud_name", "freedeveloperss123")
  fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
      method : "POST",
      body : data , 
  })
  .then(res=>res.json())
  .then((res2)=>{
    setImage(res2.url)
    document.getElementById("photoImage1").src = res2.url
    setdispaly1(true)
    setdispaly2(true)
      
  })
}








const SubmitDataMessage3 = () =>{
    setdispaly ( false)
    // console.log("wddde");
    let f
    
    // if(Num === 112){
    //   f = "Bank"
    // }
    // else{
      
    // }
    // let d = false
    //   if (n !== ""&&n1 !== ""&&n2 !== ""){
    //     d = true
    //   }
    // else{
    //   setdispaly ( true)
    //   swal("Feild are Missing")
    //   return
    // }


    let A8 = []
    User1.Counter1 && User1.Counter1.map((res343,i)=>{
    if(res343.name === "Contact"){
      if(data.fullName !== res343.fullName || data.ContactBuss.length !== res343.ContactBuss.length || data.Email !== res343.Email || data.Phone !== res343.Phone ){
        console.log("1")
        A8.push( {
          ...res343 
        })
      }
      }
    //   else{
    //     console.log("3")
    //     A8.push( {
    //       ...res343 
    //     })          }
    // }
    // else{
      if(data.name !== res343.name ||  data.link !== res343.link){
        console.log("2")
  
      A8.push( {
        ...res343 
      })
    }
    // }
  
  })

    const  i = Nu123
    console.log(data,User1._id)
    const newData1 = Arr1.filter((data1,j)=> i !== j)
    const Arr11 = []
    const Arr22 = []
    newData1.map((res,p)=>{
      if(res.name === "Contact"){
        Arr22.push(res)
      }
      else{
        Arr11.push(res)

      }
    }) 
    const A = []
    const A1 = []
    User1.DispalyShow.map((res23,g)=>{
      console.log(res23)
      if(res23.name === "Contact"){
        if(data.fullName === res23.fullName && data.ContactBuss.lenght === res23.ContactBuss.lenght && data.Email === res23.Email && data.Phone === res23.Phone ){
          console.log("1")
          A.push( {
            ...res23 
          })
        }
        else{
          console.log("3")
          A1.push(res23)
        }
      }
      else{
        if(data.name === res23.name &&  data.link === res23.link){
          console.log("2")

        A.push( {
          ...res23 
        })
      }
      else{
        console.log("3")
        A1.push(res23)
      }
      }
    })

    // let b= [...A,...User1.DispalyShow]

    //   let v = b.filter((a, i) => b.findIndex((s) =>{
    //   //  return a.name === s.name && a.link === s.link

    //    if(a.name === "Contact")
    //     return a.fullName === s.fullName && a.ContactBuss.length === s.ContactBussBuss.length && a.Email === s.Email && a.Phone === s.Phone 
    //   else
    //     return a.name === s.name &&  a.link === s.link

    //   }
      
    //   ) === i)
      
    console.log(A1,A)
    // const newData2 = Arr11.filter(data1=>  data1.link !== data.link )
    // const newData2 =[]
    // Arr11.map(data1=> {
    //   if(  data1.link !== data.link && data1.name !== data.name && data1.Name !== data.Name) {
    //     newData2.push(data1)
    //   }
    // })
    // let AA = []
    // Arr22.map(data1=> {
    //    if(  data1.fullName !== data.fullName && data1.Phone !== data.Phone && data1.Email !== data.Email) {
    //        AA.push(data1)
    //    }
    // })
    // console.log(newData1,"1111111",newData2,"2222222",AA)

    fetch("https://tapitdabit-backend.vercel.app/deleteSocialBussiness",{
        method: "POST" , 
        headers: {
            "Content-Type" : "application/json" ,
        } , 
        body : JSON.stringify({
            id : User1._id,
            AllBuss : newData1,
            SocialBuss : Arr11,
            ContactBuss : Arr22,
            dis : A1,
            d : A8
        })
    })
    .then(res=>res.json())
    .then(res2=>{

 fetch("https://tapitdabit-backend.vercel.app/1createBankBussiness",{
                                            method: "POST",
                                            headers :  {
                                                "Content-Type" : "application/json" , 
                                            } ,
                                            body : JSON.stringify({
                                              name  : data.name,
                                              Name : n,
                                              BankName : n1,
                                              Sorting  :JSON.parse(localStorage.getItem("EditData12")).sort,

                                              AccountNumber : n2,
                                              id : User1._id
                                                // MainLine2 :mainline ,
                                            })
                                        })
                                        .then(res11=>res11.json())
                                        .then((res12)=>{ 
                                          if(!res12.Error){
                                              setTimeout(()=>{
                                    swal.close()
                                           },1000)  
                                            swal("Successfully Upload your Links!", {
                                              buttons: false,
                                            });
                                              setTimeout(()=>{
      
                                                props.history.push("/user/bussiness/home1/"+User1._id)
                                              },1500)
                                              setdispaly ( true)
                                              this.setState({
                                                  email: "",
                                          })
  
                                            }
                                            else{
                                          swal(res12.Error);
                                          setdispaly ( true)
  
                                        }
                                    })
                                    .catch(err=>{
                                        setTimeout(()=>{
                                    swal.close()
                                           },1000)
                                      swal("Successfully Upload your Links!", {
                                        buttons: false,
                                      });
                                        setdispaly ( true)
              
                                        }) 


    //     // const newData = Arr1.filter(data1=>  data1.name !== data.name && data1.link !== data.link && data1.photo !== data.photo : data1.name !== data.name && data1.fullName !== data.fullName && data1.Phone !== data.Phone && data1.Email !== data.Email)
        setArr1(newData1)
        setArr11(Arr11)
        setArr22(Arr22)
    //     // setAccountAdmin(newData)
    //     // props.history.push("/alluser")
    })
                               
  
  }
  
  
const SubmitDataMessage2 = () =>{

    setdispaly ( false)
    // console.log("wddde");
    let f
    
    // if(Num === 110){
    //   f = "JazzCash"
    // }
    // else if(Num === 111){
    //   f = "EasyPaisa"
    // }
    // else{
      
    // }
    // let d = false
    // let c = false
    //   if (n !== ""&&n1 !== ""){
    //     d = true
    //     c = true
  
    //   }
    // else{
    //   setdispaly ( true)
      setTimeout(()=>{
                                    swal.close()
                                           },1000)//   
    swal("Feild are Missing")
    //   return



    // }
        
    
    let A8 = []
    User1.Counter1 && User1.Counter1.map((res343,i)=>{
    if(res343.name === "Contact"){
      if(data.fullName !== res343.fullName || data.ContactBuss.length !== res343.ContactBuss.length || data.Email !== res343.Email || data.Phone !== res343.Phone ){
        console.log("1")
        A8.push( {
          ...res343 
        })
      }
      }
    //   else{
    //     console.log("3")
    //     A8.push( {
    //       ...res343 
    //     })          }
    // }
    // else{
      if(data.name !== res343.name ||  data.link !== res343.link){
        console.log("2")
  
      A8.push( {
        ...res343 
      })
    }
    // }
  
  })




    
    const  i = Nu123
    console.log(data,User1._id)
    const newData1 = Arr1.filter((data1,j)=> i !== j)
    const Arr11 = []
    const Arr22 = []
    newData1.map((res,p)=>{
      if(res.name === "Contact"){
        Arr22.push(res)
      }
      else{
        Arr11.push(res)

      }
    }) 
    const A = []
    const A1 = []
    User1.DispalyShow.map((res23,g)=>{
      console.log(res23)
      if(res23.name === "Contact"){
        if(data.fullName === res23.fullName && data.ContactBuss.lenght === res23.ContactBuss.lenght && data.Email === res23.Email && data.Phone === res23.Phone ){
          console.log("1")
          A.push( {
            ...res23 
          })
        }
        else{
          console.log("3")
          A1.push(res23)
        }
      }
      else{
        if(data.name === res23.name &&  data.link === res23.link){
          console.log("2")

        A.push( {
          ...res23 
        })
      }
      else{
        console.log("3")
        A1.push(res23)
      }
      }
    })

    // let b= [...A,...User1.DispalyShow]

    //   let v = b.filter((a, i) => b.findIndex((s) =>{
    //   //  return a.name === s.name && a.link === s.link

    //    if(a.name === "Contact")
    //     return a.fullName === s.fullName && a.ContactBuss.length === s.ContactBussBuss.length && a.Email === s.Email && a.Phone === s.Phone 
    //   else
    //     return a.name === s.name &&  a.link === s.link

    //   }
      
    //   ) === i)
      
    console.log(A1,A)
    // const newData2 = Arr11.filter(data1=>  data1.link !== data.link )
    // const newData2 =[]
    // Arr11.map(data1=> {
    //   if(  data1.link !== data.link && data1.name !== data.name && data1.Name !== data.Name) {
    //     newData2.push(data1)
    //   }
    // })
    // let AA = []
    // Arr22.map(data1=> {
    //    if(  data1.fullName !== data.fullName && data1.Phone !== data.Phone && data1.Email !== data.Email) {
    //        AA.push(data1)
    //    }
    // })
    // console.log(newData1,"1111111",newData2,"2222222",AA)

    fetch("https://tapitdabit-backend.vercel.app/deleteSocialBussiness",{
        method: "POST" , 
        headers: {
            "Content-Type" : "application/json" ,
        } , 
        body : JSON.stringify({
            id : User1._id,
            AllBuss : newData1,
            SocialBuss : Arr11,
            ContactBuss : Arr22,
            dis : A1,
            d : A8
        })
    })
    .then(res=>res.json())
    .then(res2=>{



    fetch("https://tapitdabit-backend.vercel.app/1createJqazEasyBussiness",{
                                            method: "POST",
                                            headers :  {
                                                "Content-Type" : "application/json" , 
                                            } ,
                                            body : JSON.stringify({
                                              name  : data.name,
                                              Name : n,
                                              Number : n1,
                                              Sorting  :JSON.parse(localStorage.getItem("EditData12")).sort,

                                              id : User1._id
                                                // MainLine2 :mainline ,
                                            })
                                        })
                                        .then(res11=>res11.json())
                                        .then((res12)=>{ 
                                          if(!res12.Error){
                                              setTimeout(()=>{
                                    swal.close()
                                           },1000)  
                                            swal("Successfully Upload your Links!", {
                                              buttons: false,
                                            });
                                              setTimeout(()=>{
      
                                                props.history.push("/user/bussiness/home1/"+User1._id)
                                              },1500)
                                              setdispaly ( true)
                                          //     this.setState({
                                          //         n: "",
                                          //         n1: "",
                                          // })
  
                                            }
                                            else{
                                          swal(res12.Error);
                                          setdispaly ( true)
  
                                        }
                                    })
                                    .catch(err=>{
                                        setTimeout(()=>{
                                    swal.close()
                                           },1000)
                                      swal("Successfully Upload your Links!", {
                                        buttons: false,
                                      });
                                        setdispaly ( true)
              
                                        }) 


    //     // const newData = Arr1.filter(data1=>  data1.name !== data.name && data1.link !== data.link && data1.photo !== data.photo : data1.name !== data.name && data1.fullName !== data.fullName && data1.Phone !== data.Phone && data1.Email !== data.Email)
        setArr1(newData1)
        setArr11(Arr11)
        setArr22(Arr22)
    //     // setAccountAdmin(newData)
    //     // props.history.push("/alluser")
    })
    
  
  }
  
  
  

const SubmitDataMessage = (e) =>{
  e.preventDefault()

  setdispaly ( false)
  // console.log("wddde");
//   let f
//   if(Num === 1){
//     f = "instagram"
//   }
//   else if(Num === 2){
//     f = "snapchat"
//   }
//   else if(Num === 3){
//     f = "tiktok"
//   }
//   else if(Num === 4){
//     f = "facebook"
//   }
//   else if(Num === 5){
//     f = "linkedin"
//   }
//   else if(Num === 6){
//     f = "twitter"
//   }
//   else if(Num  === 7) {
//     f = "youtube"
//   }
//   else if(Num === 8){
//     f = "twitch"
//   }
//   else if(Num === 9){
//     f = "pinterest"
//   }
//   else if(Num === 10){
//     f = "cash app"
//   }
//   else if(Num === 11){
//     f = "paypal"
//   }
//   else if(Num === 12){
//     f = "Venmo"
//   }
//   else if(Num === 13){
//     f = "website"
//   }
//   else if(Num === 21){
//     f = "text"
//   }
//   else if(Num === 22){
//     f = "whatsapps"
//   }
//   else if(Num === 24){
//     f = "email"
//   }
//   else if(Num === 25){
//     f = "phone"
//   }
//   else if(Num === 33){
//     f = "Custom Link"
//   }
//   else{
    
//   }
//   if(email === ""){
//     swal("Link are Missing")
//     return
//   }
  

let A8 = []
User1.Counter1 && User1.Counter1.map((res343,i)=>{
if(res343.name === "Contact"){
  if(data.fullName !== res343.fullName || data.ContactBuss.length !== res343.ContactBuss.length || data.Email !== res343.Email || data.Phone !== res343.Phone ){
    console.log("1")
    A8.push( {
      ...res343 
    })
  }
  }
//   else{
//     console.log("3")
//     A8.push( {
//       ...res343 
//     })          }
// }
// else{
  if(data.name !== res343.name ||  data.link !== res343.link){
    console.log("2")

  A8.push( {
    ...res343 
  })
}
// }

})



const  i = Nu123
console.log(data,User1._id)
const newData1 = Arr1.filter((data1,j)=> i !== j)
const Arr11 = []
const Arr22 = []
newData1.map((res,p)=>{
  if(res.name === "Contact"){
    Arr22.push(res)
  }
  else{
    Arr11.push(res)

  }
}) 
const A = []
const A1 = []
User1.DispalyShow1.map((res23,g)=>{
  console.log(res23)
  if(res23.name === "Contact"){
    if(data.fullName === res23.fullName && data.ContactBuss.lenght === res23.ContactBuss.lenght && data.Email === res23.Email && data.Phone === res23.Phone ){
      console.log("1")
      A.push( {
        ...res23 
      })
    }
    else{
      console.log("3")
      A1.push(res23)
    }
  }
  else{
    if(data.name === res23.name &&  data.link === res23.link){
      console.log("2")

    A.push( {
      ...res23 
    })
  }
  else{
    console.log("3")
    A1.push(res23)
  }
  }
})

// let b= [...A,...User1.DispalyShow]

//   let v = b.filter((a, i) => b.findIndex((s) =>{
//   //  return a.name === s.name && a.link === s.link

//    if(a.name === "Contact")
//     return a.fullName === s.fullName && a.ContactBuss.length === s.ContactBussBuss.length && a.Email === s.Email && a.Phone === s.Phone 
//   else
//     return a.name === s.name &&  a.link === s.link

//   }
  
//   ) === i)
  
console.log(A1,A)
// const newData2 = Arr11.filter(data1=>  data1.link !== data.link )
// const newData2 =[]
// Arr11.map(data1=> {
//   if(  data1.link !== data.link && data1.name !== data.name && data1.Name !== data.Name) {
//     newData2.push(data1)
//   }
// })
// let AA = []
// Arr22.map(data1=> {
//    if(  data1.fullName !== data.fullName && data1.Phone !== data.Phone && data1.Email !== data.Email) {
//        AA.push(data1)
//    }
// })
// console.log(newData1,"1111111",newData2,"2222222",AA)

fetch("https://tapitdabit-backend.vercel.app/deleteSocialBussiness",{
    method: "POST" , 
    headers: {
        "Content-Type" : "application/json" ,
    } , 
    body : JSON.stringify({
        id : User1._id,
        AllBuss : newData1,
        SocialBuss : Arr11,
        ContactBuss : Arr22,
        dis : A1,
        d : A8
    })
})
.then(res=>res.json())
.then(res2=>{

  console.log(res2)

  let x ="";
  if(JSON.parse(localStorage.getItem("EditData123")).name === "instagram"){
    x = "https://www.instagram.com/"
}
else if(JSON.parse(localStorage.getItem("EditData123")).name === "snapchat"){
    x = "https://www.snapchat.com/add/"
  }
  else if(JSON.parse(localStorage.getItem("EditData123")).name === "tiktok"){
    x = "https://www.tiktok.com/@"
  }
  else if(JSON.parse(localStorage.getItem("EditData123")).name === "facebook"){
    x = "https://www.facebook.com/"
  }
  else if(JSON.parse(localStorage.getItem("EditData123")).name === "linkedin"){
    x = "https://www.linkedin.com/"
  }
  else if(JSON.parse(localStorage.getItem("EditData123")).name === "twitter"){
    x = "https://www.twitter.com/"
  }
  else if(JSON.parse(localStorage.getItem("EditData123")).name  === "youtube") {
    x = "https://www.youtube.com/"
  }
  else if(JSON.parse(localStorage.getItem("EditData123")).name === "twitch"){
    x = "https://www.twitch.com/"
  }
  else if(JSON.parse(localStorage.getItem("EditData123")).name === "pinterest"){
    x = "https://www.pinterest.com/"
  }
  else if(JSON.parse(localStorage.getItem("EditData123")).name === "cash app"){
    x = "https://www.cashapp.com/"
  }
  else if(JSON.parse(localStorage.getItem("EditData123")).name === "paypal"){
    x = "https://www.paypal.com/"
  }
  else if(JSON.parse(localStorage.getItem("EditData123")).name === "Venmo"){
    x = "https://www.Venmo.com/"
  }
  else{

  }
  let c
  let bg

  if(disUser && email12 !== ""){
    c = x + email12
    bg = "user"

  }
  else{
    c = email
    bg = "link"

  }
  if(JSON.parse(localStorage.getItem("EditData123")).name === "phone" || JSON.parse(localStorage.getItem("EditData123")).name === "text" || JSON.parse(localStorage.getItem("EditData123")).name === "whatsapps" ){
    if(UserPhoneCode !== null){
      c = "+"+UserPhoneCode+email
      console.log(c)
    }
      else {
        setdispaly(true)
        swal("select Country Code")
        setTimeout(()=>{
          swal.close()
                 },1000)
        return
      }

  }
 fetch("https://tapitdabit-backend.vercel.app/1createhumareLinkBussiness",{
                                          method: "POST",
                                          headers :  {
                                              "Content-Type" : "application/json" , 
                                          } ,
                                          body : JSON.stringify({
                                            name  :JSON.parse(localStorage.getItem("EditData123")).name,
                                            Name : c,
                                            link : c,
                                            Sorting  :JSON.parse(localStorage.getItem("EditData12")).sort,

                                            user : bg ,
                                            id : User1._id
                                              // MainLine2 :mainline ,
                                          })
                                      })
                                      .then(res11=>res11.json())
                                      .then((res12)=>{ 
                                        
                                          if(!res12.Error){
                                              
                                            swal("Successfully Upload your Links!", {
                                              buttons: false,
                                            });
                                            setTimeout(()=>{
                                              swal.close()
                                                     },1000)
                                              props.history.push("/user/bussiness/home1/"+User1._id)
                                              this.setState({
                                                  email: "",
                                          })
                                        }
                                        else{
                                        swal(res12.Error);
                                              
                                      }
                                  })
                                  .catch(err=>{
                                    
                                    swal("Successfully Upload your Links!", {
                                      buttons: false,
                                    });
                                    setTimeout(()=>{
                                      swal.close()
                                             },1000)
                                  }) 



//     // const newData = Arr1.filter(data1=>  data1.name !== data.name && data1.link !== data.link && data1.photo !== data.photo : data1.name !== data.name && data1.fullName !== data.fullName && data1.Phone !== data.Phone && data1.Email !== data.Email)
    setArr1(newData1)
    setArr11(Arr11)
    setArr22(Arr22)
//     // setAccountAdmin(newData)
//     // props.history.push("/alluser")
})

                             
}

  

const SubmitDataMessage56776 = () =>{
  // e.preventDefault()

  setdispaly ( false)
  // console.log("wddde");
//   let f
//   if(Num === 1){
//     f = "instagram"
//   }
//   else if(Num === 2){
//     f = "snapchat"
//   }
//   else if(Num === 3){
//     f = "tiktok"
//   }
//   else if(Num === 4){
//     f = "facebook"
//   }
//   else if(Num === 5){
//     f = "linkedin"
//   }
//   else if(Num === 6){
//     f = "twitter"
//   }
//   else if(Num  === 7) {
//     f = "youtube"
//   }
//   else if(Num === 8){
//     f = "twitch"
//   }
//   else if(Num === 9){
//     f = "pinterest"
//   }
//   else if(Num === 10){
//     f = "cash app"
//   }
//   else if(Num === 11){
//     f = "paypal"
//   }
//   else if(Num === 12){
//     f = "Venmo"
//   }
//   else if(Num === 13){
//     f = "website"
//   }
//   else if(Num === 21){
//     f = "text"
//   }
//   else if(Num === 22){
//     f = "whatsapps"
//   }
//   else if(Num === 24){
//     f = "email"
//   }
//   else if(Num === 25){
//     f = "phone"
//   }
//   else if(Num === 33){
//     f = "Custom Link"
//   }
//   else{
    
//   }
//   if(email === ""){
//     swal("Link are Missing")
//     return
//   }
  

let A8 = []
User1.Counter1 && User1.Counter1.map((res343,i)=>{
if(res343.name === "Contact"){
  if(data.fullName !== res343.fullName || data.ContactBuss.length !== res343.ContactBuss.length || data.Email !== res343.Email || data.Phone !== res343.Phone ){
    console.log("1")
    A8.push( {
      ...res343 
    })
  }
  }
//   else{
//     console.log("3")
//     A8.push( {
//       ...res343 
//     })          }
// }
// else{
  if(data.name !== res343.name ||  data.link !== res343.link){
    console.log("2")

  A8.push( {
    ...res343 
  })
}
// }

})



const  i = Nu123
console.log(data,User1._id)
const newData1 = Arr1.filter((data1,j)=> i !== j)
const Arr11 = []
const Arr22 = []
newData1.map((res,p)=>{
  if(res.name === "Contact"){
    Arr22.push(res)
  }
  else{
    Arr11.push(res)

  }
}) 
const A = []
const A1 = []
User1.DispalyShow1.map((res23,g)=>{
  console.log(res23)
  if(res23.name === "Contact"){
    if(data.fullName === res23.fullName && data.ContactBuss.lenght === res23.ContactBuss.lenght && data.Email === res23.Email && data.Phone === res23.Phone ){
      console.log("1")
      A.push( {
        ...res23 
      })
    }
    else{
      console.log("3")
      A1.push(res23)
    }
  }
  else{
    if(data.name === res23.name &&  data.link === res23.link){
      console.log("2")

    A.push( {
      ...res23 
    })
  }
  else{
    console.log("3")
    A1.push(res23)
  }
  }
})

// let b= [...A,...User1.DispalyShow]

//   let v = b.filter((a, i) => b.findIndex((s) =>{
//   //  return a.name === s.name && a.link === s.link

//    if(a.name === "Contact")
//     return a.fullName === s.fullName && a.ContactBuss.length === s.ContactBussBuss.length && a.Email === s.Email && a.Phone === s.Phone 
//   else
//     return a.name === s.name &&  a.link === s.link

//   }
  
//   ) === i)
  
console.log(A1,A)
// const newData2 = Arr11.filter(data1=>  data1.link !== data.link )
// const newData2 =[]
// Arr11.map(data1=> {
//   if(  data1.link !== data.link && data1.name !== data.name && data1.Name !== data.Name) {
//     newData2.push(data1)
//   }
// })
// let AA = []
// Arr22.map(data1=> {
//    if(  data1.fullName !== data.fullName && data1.Phone !== data.Phone && data1.Email !== data.Email) {
//        AA.push(data1)
//    }
// })
// console.log(newData1,"1111111",newData2,"2222222",AA)

fetch("https://tapitdabit-backend.vercel.app/deleteSocialBussiness",{
    method: "POST" , 
    headers: {
        "Content-Type" : "application/json" ,
    } , 
    body : JSON.stringify({
        id : User1._id,
        AllBuss : newData1,
        SocialBuss : Arr11,
        ContactBuss : Arr22,
        dis : A1,
        d : A8
    })
})
.then(res=>res.json())
.then(res2=>{

  console.log(res2)

  let x ="";
  if(JSON.parse(localStorage.getItem("EditData123")).name === "instagram"){
    x = "https://www.instagram.com/"
}
else if(JSON.parse(localStorage.getItem("EditData123")).name === "snapchat"){
    x = "https://www.snapchat.com/add/"
  }
  else if(JSON.parse(localStorage.getItem("EditData123")).name === "tiktok"){
    x = "https://www.tiktok.com/@"
  }
  else if(JSON.parse(localStorage.getItem("EditData123")).name === "facebook"){
    x = "https://www.facebook.com/"
  }
  else if(JSON.parse(localStorage.getItem("EditData123")).name === "linkedin"){
    x = "https://www.linkedin.com/"
  }
  else if(JSON.parse(localStorage.getItem("EditData123")).name === "twitter"){
    x = "https://www.twitter.com/"
  }
  else if(JSON.parse(localStorage.getItem("EditData123")).name  === "youtube") {
    x = "https://www.youtube.com/"
  }
  else if(JSON.parse(localStorage.getItem("EditData123")).name === "twitch"){
    x = "https://www.twitch.com/"
  }
  else if(JSON.parse(localStorage.getItem("EditData123")).name === "pinterest"){
    x = "https://www.pinterest.com/"
  }
  else if(JSON.parse(localStorage.getItem("EditData123")).name === "cash app"){
    x = "https://www.cashapp.com/"
  }
  else if(JSON.parse(localStorage.getItem("EditData123")).name === "paypal"){
    x = "https://www.paypal.com/"
  }
  else if(JSON.parse(localStorage.getItem("EditData123")).name === "Venmo"){
    x = "https://www.Venmo.com/"
  }
  else{

  }
  let c
  let bg

  if(disUser && email12 !== ""){
    c = x + email12
    bg = "user"

  }
  else{
    c = email
    bg = "link"

  }
  if(JSON.parse(localStorage.getItem("EditData123")).name === "phone" || JSON.parse(localStorage.getItem("EditData123")).name === "text" || JSON.parse(localStorage.getItem("EditData123")).name === "whatsapps" ){
    if(UserPhoneCode !== null){
      c = "+"+UserPhoneCode+email
      console.log(c)
    }
      else {
        setdispaly(true)
        swal("select Country Code")
        setTimeout(()=>{
          swal.close()
                 },1000)
        return
      }

  }
 fetch("https://tapitdabit-backend.vercel.app/1createhumareLinkBussiness",{
                                          method: "POST",
                                          headers :  {
                                              "Content-Type" : "application/json" , 
                                          } ,
                                          body : JSON.stringify({
                                            name  :JSON.parse(localStorage.getItem("EditData123")).name,
                                            Name : c,
                                            link : c,
                                            Sorting  :JSON.parse(localStorage.getItem("EditData12")).sort,

                                            user : bg ,
                                            id : User1._id
                                              // MainLine2 :mainline ,
                                          })
                                      })
                                      .then(res11=>res11.json())
                                      .then((res12)=>{ 
                                        
                                          if(!res12.Error){
                                              swal("Successfully Upload your Links!", {
                                                buttons: false,
                                              });
                                              setTimeout(()=>{
                                                swal.close()
                                                       },1000)
                                              props.history.push("/user/bussiness/home1/"+User1._id)
                                              this.setState({
                                                  email: "",
                                          })
                                        }
                                        else{
                                        swal(res12.Error);
                                              
                                      }
                                  })
                                  .catch(err=>{
                                    swal("Successfully Upload your Links!", {
                                      buttons: false,
                                    });
                                    setTimeout(()=>{
                                      swal.close()
                                             },1000)
                                  }) 



//     // const newData = Arr1.filter(data1=>  data1.name !== data.name && data1.link !== data.link && data1.photo !== data.photo : data1.name !== data.name && data1.fullName !== data.fullName && data1.Phone !== data.Phone && data1.Email !== data.Email)
    setArr1(newData1)
    setArr11(Arr11)
    setArr22(Arr22)
//     // setAccountAdmin(newData)
//     // props.history.push("/alluser")
})

                             
}




const SubmitDataMessage1 = (e) =>{
  e.preventDefault()

  setdispaly ( false)
  // console.log("wddde");
  let f
//   if(Num === 33){
//     f = "Custom Link"
//   }
  
//   else{
    
//   }
//   if(email === ""){
//     setdispaly ( true)

//     swal("Link are Missing")
//     return
//   }
//   if(Name === ""){
//     setdispaly ( true)

//     swal("Name are Missing")
//     return
//   }
//   if(Image === ""){
//     setdispaly ( true)

//     swal("Image are Missing")
//     return
//   }


let A8 = []
User1.Counter1 && User1.Counter1.map((res343,i)=>{
if(res343.name === "Contact"){
  if(data.fullName !== res343.fullName || data.ContactBuss.length !== res343.ContactBuss.length || data.Email !== res343.Email || data.Phone !== res343.Phone ){
    console.log("1")
    A8.push( {
      ...res343 
    })
  }
  }
//   else{
//     console.log("3")
//     A8.push( {
//       ...res343 
//     })          }
// }
// else{
  if(data.name !== res343.name ||  data.link !== res343.link){
    console.log("2")

  A8.push( {
    ...res343 
  })
}
// }

})

const  i = Nu123
console.log(data,User1._id)
const newData1 = Arr1.filter((data1,j)=> i !== j)
const Arr11 = []
const Arr22 = []
newData1.map((res,p)=>{
  if(res.name === "Contact"){
    Arr22.push(res)
  }
  else{
    Arr11.push(res)

  }
}) 
const A = []
const A1 = []
User1.DispalyShow.map((res23,g)=>{
  console.log(res23)
  if(res23.name === "Contact"){
    if(data.fullName === res23.fullName && data.ContactBuss.lenght === res23.ContactBuss.lenght && data.Email === res23.Email && data.Phone === res23.Phone ){
      console.log("1")
      A.push( {
        ...res23 
      })
    }
    else{
      console.log("3")
      A1.push(res23)
    }
  }
  else{
    if(data.name === res23.name &&  data.link === res23.link){
      console.log("2")

    A.push( {
      ...res23 
    })
  }
  else{
    console.log("3")
    A1.push(res23)
  }
  }
})

// let b= [...A,...User1.DispalyShow]

//   let v = b.filter((a, i) => b.findIndex((s) =>{
//   //  return a.name === s.name && a.link === s.link

//    if(a.name === "Contact")
//     return a.fullName === s.fullName && a.ContactBuss.length === s.ContactBussBuss.length && a.Email === s.Email && a.Phone === s.Phone 
//   else
//     return a.name === s.name &&  a.link === s.link

//   }
  
//   ) === i)
  
console.log(A1,A)
// const newData2 = Arr11.filter(data1=>  data1.link !== data.link )
// const newData2 =[]
// Arr11.map(data1=> {
//   if(  data1.link !== data.link && data1.name !== data.name && data1.Name !== data.Name) {
//     newData2.push(data1)
//   }
// })
// let AA = []
// Arr22.map(data1=> {
//    if(  data1.fullName !== data.fullName && data1.Phone !== data.Phone && data1.Email !== data.Email) {
//        AA.push(data1)
//    }
// })
// console.log(newData1,"1111111",newData2,"2222222",AA)

fetch("https://tapitdabit-backend.vercel.app/deleteSocialBussiness",{
    method: "POST" , 
    headers: {
        "Content-Type" : "application/json" ,
    } , 
    body : JSON.stringify({
        id : User1._id,
        AllBuss : newData1,
        SocialBuss : Arr11,
        ContactBuss : Arr22,
        dis : A1, 
        d : A8
    })
})
.then(res=>res.json())
.then(res2=>{
   const data = new FormData()
                              data.append("file", Image)
                              data.append("upload_preset", "commerce")
                              data.append("cloud_name", "freedeveloperss123")
                              fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
                                  method : "POST",
                                  body : data , 
                              })
                              .then(res=>res.json())
                              .then((res2)=>{
                                console.log(res2)
                              fetch("https://tapitdabit-backend.vercel.app/1createhumareLink1Bussiness",{
                                          method: "POST",
                                          headers :  {
                                              "Content-Type" : "application/json" , 
                                          } ,
                                          body : JSON.stringify({
                                            name  : data.name,
                                            photo : res2.url,
                                            Name ,
                                            Sorting  :JSON.parse(localStorage.getItem("EditData12")).sort,

                                            link : email,
                                            id : User1._id
                                              // MainLine2 :mainline ,
                                          })
                                      })
                                      .then(res11=>res11.json())
                                      .then((res12)=>{ 
                                          if(!res12.Error){
                                            setdispaly ( true)
                                              swal("Successfully Upload your Link!", {
                                            buttons: false,
                                          });
                                              setTimeout(()=>{
                                                swal.close()
                                                       },1000)
                                              setTimeout(()=>{

                                                props.history.push("/user/bussiness/home1/"+User1._id)
                                              },1500)
                                              this.setState({
                                                  email: "",
                                          })
                                        }
                                        else{
                                          setdispaly ( true)
                                        swal(res12.Error);
                                             
                                      }
                                  })
                                  .catch(err=>{
                                    setdispaly ( true)
                                    swal("Successfully Upload your Link!", {
                                            buttons: false,
                                          });
                                    setTimeout(()=>{
                                      swal.close()
                                             },1000)
                                  }) 
                                    })



//     // const newData = Arr1.filter(data1=>  data1.name !== data.name && data1.link !== data.link && data1.photo !== data.photo : data1.name !== data.name && data1.fullName !== data.fullName && data1.Phone !== data.Phone && data1.Email !== data.Email)
    setArr1(newData1)
    setArr11(Arr11)
    setArr22(Arr22)
//     // setAccountAdmin(newData)
//     // props.history.push("/alluser")
})

                           
}

const SubmitDataMessage1234 = (e) =>{
  e.preventDefault()

  setdispaly ( false)
  // console.log("wddde");
  let f
//   if(Num === 33){
//     f = "Custom Link"
//   }
  
//   else{
    
//   }
//   if(email === ""){
//     setdispaly ( true)

//     swal("Link are Missing")
//     return
//   }
//   if(Name === ""){
//     setdispaly ( true)

//     swal("Name are Missing")
//     return
//   }
//   if(Image === ""){
//     setdispaly ( true)

//     swal("Image are Missing")
//     return
//   }


let A8 = []
User1.Counter1 && User1.Counter1.map((res343,i)=>{
if(res343.name === "Contact"){
  if(data.fullName !== res343.fullName || data.ContactBuss.length !== res343.ContactBuss.length || data.Email !== res343.Email || data.Phone !== res343.Phone ){
    console.log("1")
    A8.push( {
      ...res343 
    })
  }
  }
//   else{
//     console.log("3")
//     A8.push( {
//       ...res343 
//     })          }
// }
// else{
  if(data.name !== res343.name ||  data.link !== res343.link){
    console.log("2")

  A8.push( {
    ...res343 
  })
}
// }

})

const  i = Nu123
console.log(data,User1._id)
const newData1 = Arr1.filter((data1,j)=> i !== j)
const Arr11 = []
const Arr22 = []
newData1.map((res,p)=>{
  if(res.name === "Contact"){
    Arr22.push(res)
  }
  else{
    Arr11.push(res)

  }
}) 
const A = []
const A1 = []
User1.DispalyShow1.map((res23,g)=>{
  console.log(res23)
  if(res23.name === "Contact"){
    if(data.fullName === res23.fullName && data.ContactBuss.lenght === res23.ContactBuss.lenght && data.Email === res23.Email && data.Phone === res23.Phone ){
      console.log("1")
      A.push( {
        ...res23 
      })
    }
    else{
      console.log("3")
      A1.push(res23)
    }
  }
  else{
    if(data.name === res23.name &&  data.link === res23.link){
      console.log("2")

    A.push( {
      ...res23 
    })
  }
  else{
    console.log("3")
    A1.push(res23)
  }
  }
})

// let b= [...A,...User1.DispalyShow]

//   let v = b.filter((a, i) => b.findIndex((s) =>{
//   //  return a.name === s.name && a.link === s.link

//    if(a.name === "Contact")
//     return a.fullName === s.fullName && a.ContactBuss.length === s.ContactBussBuss.length && a.Email === s.Email && a.Phone === s.Phone 
//   else
//     return a.name === s.name &&  a.link === s.link

//   }
  
//   ) === i)
  
console.log(A1,A)
// const newData2 = Arr11.filter(data1=>  data1.link !== data.link )
// const newData2 =[]
// Arr11.map(data1=> {
//   if(  data1.link !== data.link && data1.name !== data.name && data1.Name !== data.Name) {
//     newData2.push(data1)
//   }
// })
// let AA = []
// Arr22.map(data1=> {
//    if(  data1.fullName !== data.fullName && data1.Phone !== data.Phone && data1.Email !== data.Email) {
//        AA.push(data1)
//    }
// })
// console.log(newData1,"1111111",newData2,"2222222",AA)

fetch("https://tapitdabit-backend.vercel.app/deleteSocialBussiness",{
    method: "POST" , 
    headers: {
        "Content-Type" : "application/json" ,
    } , 
    body : JSON.stringify({
        id : User1._id,
        AllBuss : newData1,
        SocialBuss : Arr11,
        ContactBuss : Arr22,
        dis : A1,
        d : A8
    })
})
.then(res=>res.json())
.then(res2=>{
  let x ="";
  if(JSON.parse(localStorage.getItem("EditData123")).name === "instagram"){
    x = "https://www.instagram.com/"
}
else if(JSON.parse(localStorage.getItem("EditData123")).name === "snapchat"){
    x = "https://www.snapchat.com/add"
  }
  else if(JSON.parse(localStorage.getItem("EditData123")).name === "tiktok"){
    x = "https://www.tiktok.com/@"
  }
  else if(JSON.parse(localStorage.getItem("EditData123")).name === "facebook"){
    x = "https://www.facebook.com/"
  }
  else if(JSON.parse(localStorage.getItem("EditData123")).name === "linkedin"){
    x = "https://www.linkedin.com/"
  }
  else if(JSON.parse(localStorage.getItem("EditData123")).name === "twitter"){
    x = "https://www.twitter.com/"
  }
  else if(JSON.parse(localStorage.getItem("EditData123")).name === "youtube") {
    x = "https://www.youtube.com/"
  }
  else if(JSON.parse(localStorage.getItem("EditData123")).name === "twitch"){
    x = "https://www.twitch.com/"
  }
  else if(JSON.parse(localStorage.getItem("EditData123")).name === "pinterest"){
    x = "https://www.pinterest.com/"
  }
  else if(JSON.parse(localStorage.getItem("EditData123")).name === "cash app"){
    x = "https://www.cashapp.com/"
  }
  else if(JSON.parse(localStorage.getItem("EditData123")).name === "paypal"){
    x = "https://www.paypal.com/"
  }
  else if(JSON.parse(localStorage.getItem("EditData123")).name === "Venmo"){
    x = "https://www.Venmo.com/"
  }
  else{

  }
  let c
  let bg

  if(disUser && email12 !== ""){
    c = x + email12
    bg = "user"

  }
  else{
    c = email
    bg = "link"

  }

   const data = new FormData()
                              data.append("file", Image)
                              data.append("upload_preset", "commerce")
                              data.append("cloud_name", "freedeveloperss123")
                              fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
                                  method : "POST",
                                  body : data , 
                              })
                              .then(res=>res.json())
                              .then((res2)=>{
                                console.log(res2)
                              fetch("https://tapitdabit-backend.vercel.app/1createhumareLink1Bussiness",{
                                          method: "POST",
                                          headers :  {
                                              "Content-Type" : "application/json" , 
                                          } ,
                                          body : JSON.stringify({
                                            name  : JSON.parse(localStorage.getItem("EditData123")).name,
                                            photo : res2.url,
                                            Name : Namea,
                                            link : email,
                                            Sorting  :JSON.parse(localStorage.getItem("EditData12")).sort,

                                            user : bg,
                                            id : User1._id
                                              // MainLine2 :mainline ,
                                          })
                                      })
                                      .then(res11=>res11.json())
                                      .then((res12)=>{ 
                                          if(!res12.Error){
                                            setdispaly ( true)
                                              swal("Successfully Upload your Link!", {
                                                buttons: false,
                                              });
                                              setTimeout(()=>{
                                                swal.close()
                                                       },1000)
                                              setTimeout(()=>{

                                                props.history.push("/user/bussiness/home1/"+User1._id)
                                              },1500)
                                              this.setState({
                                                  email: "",
                                          })
                                        }
                                        else{
                                          setdispaly ( true)
                                        swal(res12.Error);
                                             
                                      }
                                  })
                                  .catch(err=>{
                                    setdispaly ( true)
                                    swal("Successfully Upload your Link!", {
                                      buttons: false,
                                    });
                                    setTimeout(()=>{
                                      swal.close()
                                             },1000)
                                  }) 
                                    })



//     // const newData = Arr1.filter(data1=>  data1.name !== data.name && data1.link !== data.link && data1.photo !== data.photo : data1.name !== data.name && data1.fullName !== data.fullName && data1.Phone !== data.Phone && data1.Email !== data.Email)
    setArr1(newData1)
    setArr11(Arr11)
    setArr22(Arr22)
//     // setAccountAdmin(newData)
//     // props.history.push("/alluser")
})

                           
}




const SubmitDataMessageLink = () =>{

    setdispaly ( false)
    // console.log("wddde");
    let f
    
    //  if(Num === 53){
    //   f = "Linktree"
    // }
    // else{
      
    // }



    let A8 = []
    User1.Counter1 && User1.Counter1.map((res343,i)=>{
    if(res343.name === "Contact"){
      if(data.fullName !== res343.fullName || data.ContactBuss.length !== res343.ContactBuss.length || data.Email !== res343.Email || data.Phone !== res343.Phone ){
        console.log("1")
        A8.push( {
          ...res343 
        })
      }
      }
    //   else{
    //     console.log("3")
    //     A8.push( {
    //       ...res343 
    //     })          }
    // }
    // else{
      if(data.name !== res343.name ||  data.link !== res343.link){
        console.log("2")
  
      A8.push( {
        ...res343 
      })
    }
    // }
  
  })

    const  i = Nu123
    console.log(data,User1._id)
    const newData1 = Arr1.filter((data1,j)=> i !== j)
    const Arr11 = []
    const Arr22 = []
    newData1.map((res,p)=>{
      if(res.name === "Contact"){
        Arr22.push(res)
      }
      else{
        Arr11.push(res)

      }
    }) 
    const A = []
    const A1 = []
    User1.DispalyShow1.map((res23,g)=>{
      console.log(res23)
      if(res23.name === "Contact"){
        if(data.fullName === res23.fullName && data.ContactBuss.lenght === res23.ContactBuss.lenght && data.Email === res23.Email && data.Phone === res23.Phone ){
          console.log("1")
          A.push( {
            ...res23 
          })
        }
        else{
          console.log("3")
          A1.push(res23)
        }
      }
      else{
        if(data.name === res23.name &&  data.link === res23.link){
          console.log("2")

        A.push( {
          ...res23 
        })
      }
      else{
        console.log("3")
        A1.push(res23)
      }
      }
    })

    // let b= [...A,...User1.DispalyShow]

    //   let v = b.filter((a, i) => b.findIndex((s) =>{
    //   //  return a.name === s.name && a.link === s.link

    //    if(a.name === "Contact")
    //     return a.fullName === s.fullName && a.ContactBuss.length === s.ContactBussBuss.length && a.Email === s.Email && a.Phone === s.Phone 
    //   else
    //     return a.name === s.name &&  a.link === s.link

    //   }
      
    //   ) === i)
      
    console.log(A1,A)
    // const newData2 = Arr11.filter(data1=>  data1.link !== data.link )
    // const newData2 =[]
    // Arr11.map(data1=> {
    //   if(  data1.link !== data.link && data1.name !== data.name && data1.Name !== data.Name) {
    //     newData2.push(data1)
    //   }
    // })
    // let AA = []
    // Arr22.map(data1=> {
    //    if(  data1.fullName !== data.fullName && data1.Phone !== data.Phone && data1.Email !== data.Email) {
    //        AA.push(data1)
    //    }
    // })
    // console.log(newData1,"1111111",newData2,"2222222",AA)

    fetch("https://tapitdabit-backend.vercel.app/deleteSocialBussiness",{
        method: "POST" , 
        headers: {
            "Content-Type" : "application/json" ,
        } , 
        body : JSON.stringify({
            id : User1._id,
            AllBuss : newData1,
            SocialBuss : Arr11,
            ContactBuss : Arr22,
            dis : A1,
            d  :A8
        })
    })
    .then(res=>res.json())
    .then(res2=>{


      let d = false
      let c = false
      for (let i = 0; i < Nu.length; i++) {
        console.log(Nu, Name1[i],email1[i] )
        if (Name1[i] !== "" && email1[i] !== ""){
        setdispaly ( true)
        swal("Feild are Missing")
        setTimeout(()=>{
          swal.close()
                 },1000)
        return
      }
      }
      // }

    // let d = false
    // let c = false
    // for (let i = 0; i < Name1.length; i++) {
    //   if (Name1[i] !== "")
    //   d = true
    //   if (email1[i] !== "")
    //   c = true
    // }
    // if(c && d){
  
    // }
    // else{
    //   setdispaly ( true)
    //   swal("Feild are Missing")
    //   return
    // }
                                fetch("https://tapitdabit-backend.vercel.app/1createlinktreeLinkBussiness",{
                                            method: "POST",
                                            headers :  {
                                                "Content-Type" : "application/json" , 
                                            } ,
                                            body : JSON.stringify({
                                              name  : data.name,
                                              Name1 : Name1[0],
                                              Name2 : Name1[1],
                                              Name3 : Name1[2],
                                              Sorting  :JSON.parse(localStorage.getItem("EditData12")).sort,

                                              Name4 : Name1[3],
                                              Name5 : Name1[4],
  
                                              email1 : email1[0],
                                              email2 : email1[1],
                                              email3 : email1[2],
                                              email4 : email1[3],
                                              email5 : email1[4],
                                              id : User1._id
                                                // MainLine2 :mainline ,
                                            })
                                        })
                                        .then(res11=>res11.json())
                                        .then((res12)=>{ 
                                            if(!res12.Error){
                                                swal("Successfully Upload your Links!", {
                                                  buttons: false,
                                                });
                                                setTimeout(()=>{
                                                  swal.close()
                                                         },1000)
                                                setTimeout(()=>{
  
                                                  props.history.push("/user/bussiness/home1/"+User1._id)
                                                },1500)
                                                setdispaly ( true)
  
                                                this.setState({
                                                    email: "",
                                            })
                                          }
                                          else{
                                          swal(res12.Error);
                                          setdispaly ( true)
   
                                        }
                                    })
                                    .catch(err=>{
                                      swal("Successfully Upload your Links!", {
                                        buttons: false,
                                      });
                                      setTimeout(()=>{
                                        swal.close()
                                               },1000)
                                      setdispaly ( true)
                   
                                        }) 

    //     // const newData = Arr1.filter(data1=>  data1.name !== data.name && data1.link !== data.link && data1.photo !== data.photo : data1.name !== data.name && data1.fullName !== data.fullName && data1.Phone !== data.Phone && data1.Email !== data.Email)
        setArr1(newData1)
        setArr11(Arr11)
        setArr22(Arr22)
    //     // setAccountAdmin(newData)
    //     // props.history.push("/alluser")
    })


  
  }
  


  const SubmitDataMessag44 = (e) =>{
    e.preventDefault()
    setDeleteEK1()
    setdispaly ( false)
    // console.log("wddde");
    let Ak = []
      for (let i = 0; i < Arr1.length; i++) {
          if (Conrange1[i] === 100)
          Ak.push(ConArr1[i])
          // const element = array[i];
          
      }
    console.log(Ak)
                                const data = new FormData()
                                data.append("file", ConImage)
                                data.append("upload_preset", "commerce")
                                data.append("cloud_name", "freedeveloperss123")
                                fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
                                    method : "POST",
                                    body : data , 
                                })
                                .then(res=>res.json())
                                .then((res2)=>{
  
                                fetch("https://tapitdabit-backend.vercel.app/createContactLinkBussinessNameChangejkkjkj",{
                                            method: "POST",
                                            headers :  {
                                                "Content-Type" : "application/json" , 
                                            } ,
                                            body : JSON.stringify({
                                              
                                                fullName :ConName ,
                                                Email : ConEmail ,
                                                Phone  , 
                                                Address ,
                                                Gender  , 
                                                Sorting  :JSON.parse(localStorage.getItem("EditData12")).sort,
                                                name1 :Conname1,
                                                City ,
                                                Country  , 
                                                Role  , 
                                                Image : res2.url ?  res2.url : ConImage1,
                                                photo1 :  ConImage12 ? ConImage12 : "",
                                                ContactBuss : Ak,
                                              id : User1._id
                                                // MainLine2 :mainline ,
                                            })
                                        })
                                        .then(res11=>res11.json())
                                        .then((res12)=>{ 
                                          if(!res12.Error){
                                    setdispaly ( true)         
                                            swal("Successfully Upload your Contact Card!", {
                                              buttons: false,
                                            });
                                            setTimeout(()=>{
                                              swal.close()
                                                     },1000)
                                            setTimeout(()=>{
    
                                              props.history.push("/user/bussiness/home1/"+User1._id)
                                            },1500)
                                          }
                                          else{                                             
                                            //     this.setState({
                                                  setdispaly ( true)
          
                                                swal("Plz try Again");
                                                setTimeout(()=>{
                                                  swal.close()
                                                         },1000)
                                            //         email: "",
                                            // })
                                        }
                                    })
                                    })
                                    .catch(err=>{
                                      setdispaly ( true)
  
                                        swal("There is An Error")  
                                        setTimeout(()=>{
                                          swal.close()
                                                 },1000)
                                                        
                                        }) 
  
  }
  
  

  
  const setRanger=(i)=>{
    if(as) setas(false)
    else setas(true)
    console.log(i)
    if(Conrange1[i] === 0){
        Conrange1[i] = 100 
    }
    else{
        Conrange1[i] = 0 
    }
    console.log(Conrange1)
  }
  
  const setRoter123 = ()=>{
    localStorage.setItem("request123",JSON.stringify(1) )
        props.history.push("/user/request-for-pro-item")
  
  }
  
  const setChanger1 = (e)=>{
    document.getElementById("myDiv1234").style.display= "none"
  }
  const setChanger2 = (e)=>{
    document.getElementById("myDivUmer").style.display= "none"
  }
  
















  
  const setChay = (data)=>{
    
      let A8 = []
      User1.Counter1 && User1.Counter1.map((res343,i)=>{
      if(res343.name === "Contact"){
        if(data.fullName === res343.fullName && data.ContactBuss.lenght === res343.ContactBuss.lenght && data.Email === res343.Email && data.Phone === res343.Phone ){
          console.log("1")
          A8.push( {
            ...res343 
          })
        }
        }
      //   else{
      //     console.log("3")
      //     A8.push( {
      //       ...res343 
      //     })          }
      // }
      // else{
        if(data.name === res343.name &&  data.link === res343.link  &&  data.sort === res343.sort){
          console.log("2")

        A8.push( {
          ...res343 
        })
      }
      // }
    
    })
    console.log(A8)


    const newData1 = AllDataArr.filter((data1,j)=> Nu123 !== j)
    const Arr11 = []
    const Arr22 = []
    newData1.map((res,p)=>{
      if(res.name === "Contact"){
        Arr22.push(res)
      }
      else{
        Arr11.push(res)

      }
    }) 
    const A = []
    const A1 = []
    User1.DispalyShow1.map((res23,g)=>{
      console.log(res23)
      if(res23.name === "Contact"){
        if(data.fullName === res23.fullName && data.ContactBuss.lenght === res23.ContactBuss.lenght && data.Email === res23.Email && data.Phone === res23.Phone ){
          console.log("1")
          A.push( {
            ...res23 
          })
        }
        else{
          console.log("3")
          A1.push(res23)
        }
      }
      else{
       
        if(data.name === res23.name &&  data.link === res23.link && res23.sort === data.sort){
 
             console.log("2")

        A.push( {
          ...res23 
        })
          }
         

      else{
        console.log("3")
        A1.push(res23)
      }
      }
    })
    console.log(A,A1) 
    fetch("https://tapitdabit-backend.vercel.app/deleteSocialBussiness",{
        method: "POST" , 
        headers: {
            "Content-Type" : "application/json" ,
        } , 
        body : JSON.stringify({
            id : User1._id,
            AllBuss : newData1,
            SocialBuss : Arr11,
            ContactBuss : Arr22,
            dis : A1,
            d : A8

        })
    })
    .then(res=>res.json())
    .then(res2=>{
    //     // const newData = Arr1.filter(data1=>  data1.name !== data.name && data1.link !== data.link && data1.photo !== data.photo : data1.name !== data.name && data1.fullName !== data.fullName && data1.Phone !== data.Phone && data1.Email !== data.Email)
        // setArr5(newData1)
        // setArr1(Arr11)
        // setArr2(Arr22)
        swal("Successfully Updated")
        setTimeout(()=>{
          swal.close()
                 },1000)

        // setdisDete(true)
        // props.history.push("/user/bussiness/home1/"+User1._id)
        window.location.assign("/user/bussiness/home1/"+User1.uid)


    //     // setAccountAdmin(newData)
    //     // props.history.push("/alluser")
    })
  }
  


        return (
            






      <div 
        // onScroll={onScrolll()}
        >
       
        {/* Slider */}

        <div id="myDivUmer12">
                {/* <img src={guides} alt=""  id="myDiv23456"/> */}
                <center>
                  <h1>
                    Analytics
                </h1>
                  </center>
                <center><p>
                Unlock all links with Pro to see insights of your account. <br />
Tap Buy now  to upgrade.
          </p></center>
                  <center>
                  <button class="btn btn-dark F23 f45" onClick={()=>setRoter123()}>Buy Now </button>
                  </center>
                <ClearIcon id="myDiv3456767"  style={{color : "black",fontSize : "30px",position : "absolute" , right: "10%",top : "10%" , zIndex: "2"}} onClick={()=>setChanger8()}/>
            </div> 
        <div id="myDivUmer">
                {/* <img src={guides} alt=""  id="myDiv23456"/> */}
                <center>
                  <h1>
                     Build Your Network
                </h1>
                  </center>
                <center><p>
Capture leads using Popl and favorite, group, sort and manage them all from here. Start building your connections now                  </p></center>
                  <center>
                  <button class="btn btn-dark F23 f45" onClick={()=>setRoter123()}>Buy Now </button>
                  </center>
                <ClearIcon id="myDiv34567"  style={{color : "black",fontSize : "30px",position : "absolute" , right: "10%",top : "10%" , zIndex: "2"}} onClick={()=>setChanger2()}/>
            </div> 






{data.name === "Contact"
? 
<>

<div style={{height : "2px"}}>

</div>
<DeleteForeverIcon style={{color : "black",position: "absolute",right: "30px",top: "101px", fontSize : "30px"}} onClick={()=>setChay(data)}/>                          

<section>
                    <div className="container mi2" style={{margin : "70px auto 0px auto" , width : "97%"}}>
                            <div className="row justify-content-center">

                                <center>
  
  <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12" style={{margin : "0px 0px"}}>
  <div class="px-1" style={{ borderRadius : "10px"}}>
   
    <div style={{display : "flex" , flexDirection : "row" ,padding : "14px 7px"}}>
      {/* <img src={s1} alt="" style={{borderRadius : "200px",width: "140px" , height : "140px"}}/> */}
        
        <div class="px-1"  style={{display : "flex" , justifyContent : "center"}}>
              {/* <textarea rows="8" cols="40" placeholder="Enter your Bio !" style={{padding : "10px 14px " , border :"2px solid white" , borderRadius : "10px", background  : " rgb(245, 246, 246)" }}></textarea> */}
             {ConImage12 ?
<img src={ConImage12} alt="" width="100%" height="150px" style={{borderRadius : "20px"}}/>
:
<img src={cd1} alt="" width="100%" height="200px" style={{width : "113px"}}/>

}
{/* <img src={ a111} alt="" width="100%" height="200px" /> */}
              {/* <p style={{color : "black"}}><b>{AdminData.user}</b></p> */}
        </div>
    </div>
     
  </div>
 

</div>
</center>










                               
                            </div>
                            </div>
                </section>







<form  onSubmit={(e)=>SubmitDataMessag44(e)}>

                <section>
                    <div className="container" style={{margin : "20px auto" , width : "97%"}}>
                            <div className="row justify-content-center">

                <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 ">
                                <div className="form-row">
                                          <div className="form-group col-md-6">
                                        <label for="inputPassword4">Name</label>
                                        <input type="text" required value={ConName} onChange={(e)=>setConname(e.target.value) }  className="form-control" id="inputPassword4" placeholder="Enter Full Name"/>
                                          </div>
                                          <div className="form-group col-md-6">
                                        <label for="inputPassword4">Email</label>
                                        <input type="email" required value={ConEmail} onChange={(e)=>setConEmail(e.target.value) }  className="form-control" id="inputPassword4" placeholder="Enter Email"/>
                                      </div>
                                </div>
                                <div className="form-row">
                                          <div className="form-group col-md-6">
                                        <label for="inputPassword4">Phone</label>
                                        <input type="text" required value={Phone} onChange={(e)=>setPhone(e.target.value) }  className="form-control" id="inputPassword4" placeholder="Enter Phone"/>
                                          </div>
                                          {/* <div className="form-group col-md-6">
                                        <label for="inputPassword4">Address</label>
                                        <input type="text" required value={Address} onChange={(e)=>setAddress(e.target.value) }  className="form-control" id="inputPassword4" placeholder="Enter Address"/>
                                      </div> */}
                                </div>
                                <div className="form-row">
                                          {/* <div className="form-group col-md-6">
                                        <label for="inputPassword4">Gender</label>
                                        <input type="text" required value={Gender} onChange={(e)=>setGender(e.target.value) }  className="form-control" id="inputPassword4" placeholder="Enter Gender"/>
                                          </div> */}
                                          <div className="form-group col-md-12">
                                        <label for="inputPassword4">Address</label>
                                        <input type="text" required value={City} onChange={(e)=>setCity(e.target.value) }  className="form-control" id="inputPassword4" placeholder="Enter Address"/>
                                      </div>
                                </div>
                                <div className="form-row">
                                          <div className="form-group col-md-6">
                                        <label for="inputPassword4">Country</label>
                                        <input type="text" required value={Country} onChange={(e)=>setCountry(e.target.value) }  className="form-control" id="inputPassword4" placeholder="Enter Country"/>
                                          </div>
                                          <div className="form-group col-md-6">
                                        <label for="inputPassword4">Role</label>
                                        <input type="text" value={Role} onChange={(e)=>setRole(e.target.role) }  className="form-control" id="inputPassword4" placeholder="Enter Role"/>
                                      </div>
                                </div>
                                
                                <div className="form-row">




                                {ConImage ? 
<>
<div style={{
  display: "flex",
  justifyontent: "flex-start",
  alignItems: "center",
  width: "94%"
}}>
<img src={ConImage1} alt="" style={{width : "60px", height : "60px" , borderRadius : "60px"}}/>
<div className="form-group col-md-12">
      <label for="inputAddress2">Edit Profile</label>
      <input type="file"  onChange={(e)=>setConImage(e.target.files[0])}  className="form-control" id="inputAddress2" placeholder="Upload Image"/>
  </div>
</div>
</>
:
<div className="form-group col-md-12">
      <label for="inputAddress2">Edit Profile</label>
      <input type="file"  onChange={(e)=>setConImage(e.target.files[0])}  className="form-control" id="inputAddress2" placeholder="Upload Image"/>
  </div>

}
















                                {/* <div className="form-group col-md-12">
                                        <label for="inputAddress2">Upload Profile</label>
                                        <input type="file"  onChange={(e)=>setConImage(e.target.files[0])}  className="form-control" id="inputAddress2" placeholder="Upload Image"/>
                                    </div> */}
                                </div>
                                
                        </div>

          </div>
          </div>
          </section>



















            {ConArr1.map((res1,i)=>{
              if(res1.name !== "JazzCash" && res1.name !== "EasyPaisa" && res1.name !== "Bank" && res1.name !== "text" && res1.name !== "whatsapps" &&res1.name !== "email" &&res1.name !== "phone"  &&res1.name !== "address" ){

                    return(
                        <section>
                               <center>{res1.photo === "" && res1.name === "facebook" ? 
                                                    <img src={a4} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "location" ? 
                                                    <img src={a23} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "instagram" ? 
                                                    <img src={a1} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "snapchat" ? 
                                                    <img src={a2} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "tiktok" ? 
                                                    <img src={a3} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "linkedin" ? 
                                                    <img src={a5} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "twitter" ? 
                                                    <img src={a6} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "youtube" ? 
                                                    <img src={a7} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "twitch" ? 
                                                    <img src={a8} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "pinterest" ? 
                                                    <img src={a9} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "cash app" ? 
                                                    <img src={z1} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "JazzCash" ? 
                                                    <img src={k1} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "EasyPaisa" ? 
                                                    <img src={k4} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "Bank" ? 
                                                    <img src={k5} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "paypal" ? 
                                                    <img src={z2} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "Venmo" ? 
                                                    <img src={z3} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "Linktree" ? 
                                                    <img src={c2} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "text" ? 
                                                    <img src={a21} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "website" ? 
                                                    <img src={c1} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "whatsapps" ? 
                                                    <img src={a22} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "email" ? 
                                                    <img src={a24} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "phone" ? 
                                                    <img src={a25} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo === "" && res1.name === "Address" ? 
                                                    <img src={a23} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                                {res1.photo !== "" && res1.name !== "Contact" ? 
                                                    <img src={res1.photo} alt="" className="arrival-Photo111 abc123 bu1"/>
                                                : ""
                                                }
                                           </center>
                                            <div className="container" style={{margin : "0px auto" , width : "97%",height : "117px"}}>
                                                    <div className="row justify-content-center">
                                
                                            {/* <div className="arrival11 col-xl-10 col-lg-10 col-md-4 col-sm-5">
                                                <div style={{minWidth: "95%",display: "flex",justifyContent: "center"}}  > */}
                                                {/* <img src={z1} alt="" className="arrival-Photo111"/> */}
                                                
                                                <div className="arrival11 col-xl-10 col-lg-10 col-md-4 col-sm-5">
                                                <div style={{minWidth: "95%",display: "flex",justifyContent: "center"}}  >
                                                <div>
                                                <h3>
                                                {res1.name}
                                                </h3>
                                                {res1.link ? <p style={{maxWidth : "250px", minWidth : "250px",   overflow: "hidden"}}> 
                                                {res1.link} 
                                                </p>
                                                :
                                                <p style={{maxWidth : "250px",minWidth : "250px",    overflow: "hidden"}}> 
                                                {res1.Number} 
                                                </p>
                                                }

                                                {res1.Name ? <>
                                                <p style={{maxWidth : "250px", minWidth : "250px",   overflow: "hidden"}}> 
                                                {res1.AccountNumber} 
                                                </p></>
                                                :
                                                ""
                                                }
                                                
                                                </div>
                                                {/* <div for={res1.name+"a"+res1.link} style={{width: "45px",position: "absolute",right: "-54%",height : "40px"}}></div> */}
                                                <div  onClick={()=>setRanger(i)} style={{position: "absolute",right: "-60px" ,minWidth: "29px",height: "28px",background: "transparent"}} className="ki1 uhuuihuih8"></div><input style={{width : "30px", marginTop : "2%",height:"5px"}} className="cx"  id={res1.name+"a"+res1.link} type="range" min="0" max="100" value={Conrange1[i]} onClick={()=>setRanger(i)} />
                                                </div>
                                            </div>
                                            
                                           
                            </div>
                               
                            </div>
                </section> 
                    )
                                              }
            })

            }
               







           <center>
          
            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-10" style={{margin : "20px 0px" }}>
                   
                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                     <br />
                    <br />
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        {/* <button className="button11" style={{width : "200px" , background : "rgb(211, 211, 211)" , color  : "black" , borderRadius :  "3px" , border : "3px solid black",fontSize : "18px"}}>Open</button> */}
                        {dispaly ? 
                            <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
                            
                            :
                            <span className="button11" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "16px"}}>Save</span>

                        }
                    </div>
                    <br/>
            </div>
           </center>
          
               </form>
</>

: ""

}
























{data.photo === "" && !data.Name && data.name === "instagram" ? 

<>

<br/>

      <DeleteForeverIcon style={{color : "black",position: "absolute",right: "30px",top: "101px", fontSize : "30px"}} onClick={()=>setChay(data)}/>                          




<section>
                    <div className="container mi2" style={{margin : "70px auto" , width : "96%"}}>
                            <div className="row justify-content-center">
                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111 arrival1111">
                                                <Link to={"/main-categories/"}><img src={in1}   alt="" className="arrival-Photo111 arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div>  <center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>Instagram</p>
</center>
                </section>

           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage(e)}>
                   

                                 

                <div style={{display : "flex" , justifyContent : "flexLeft"}}>

<span  onClick={()=>setDis123(1)} style={{borderRadius : "0px",padding: "4px 5px",height: "38px",marginTop: "8px", background : "#eee" ,width : "74px",marginRight : "0px", color  : "black"  , fontSize : "16px"}}>Username</span>
<div style={{height: "38px",marginTop : "8px",paddingTop : "4px",background:"#eee"}} onClick={()=>swal("How to get your Instagram username", "Open Instagram and go to your profile in the bottom right. The username that you see at the top above your profile picture is what you should type here. No need to include '@' ",{ button: "Got It"})}><span style={{borderRadius : "40px",padding: "0px 7px",height: "10px",marginTop: "8px" ,background : "#eee" ,width : "24px",border: "1px solid black",marginRight : "0px", color  : "black"  , fontSize : "11px"}}>i</span></div>

{disUser ? 
<>
<input className="input11" type="text"   value={email12} onChange={(e)=>setemail12(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>

</>
                : ""
                   }
                     
                 
        </div>


                     
                      {/* <>
                      <input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>
                     </> */}
                       
<br />

                {/* <div style={{display : "flex" , justifyContent : "flexLeft"}}>
                       <span onClick={()=>setDis123(2)} style={{borderRadius : "0px",padding: "4px 5px",height: "38px",marginTop: "8px"  , background : "#eee" ,marginRight  : "0px", width : "74px", color  : "black"  , fontSize : "16px"}}>Url</span>
                        <div style={{height: "38px",marginTop : "8px",paddingTop : "4px",background:"#eee"}}  onClick={()=>swal("", "",{ button: "Got It"})}><span onClick={()=>setDis123(2)} style={{borderRadius : "40px",padding: "0px 7px",height: "10px",marginTop: "8px" ,background : "#eee" ,width : "24px",border: "1px solid black",marginRight : "0px", color  : "black"  , fontSize : "11px"}}>i</span></div>

                       {disUser1 ? 
                     <><input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here " /><br/></>
                     : ""
                   }
                   </div> */}
                   {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                    
<br />

                     <br />
                    <br />
                    <div style={{display : "flex" , justifyContent : "center"}}>
                    <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}




{data.photo && data.photo.length !== 0 && data.name !== "Contact"? 

<>

<br/>
<DeleteForeverIcon style={{color : "black",position: "absolute",right: "30px",top: "101px", fontSize : "30px"}} onClick={()=>setChay(data)}/>                          




<center>
  <div class="image-upload mi2" style={{margin : "70px auto 0px auto"}}>
                                <label for="file-input">
                                    <img src={nu1}  style={{ width:"133px", fontSize : "30px",borderRadius : "15px"}}/>
                                </label>

                                <input id="file-input" type="file" style={{width :  "0px"}}  onChange={(e)=>setImage123(e.target.files[0])}/>
                                </div>   
</center>

              <section>
                    <div className="container mi2" style={{margin : "0px auto" , width : "97%"}}>
                            <div className="row justify-content-center">
                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111 arrival1111">
                                                <Link to={"/main-categories/"}><img src={data.photo}  id="photoImage1" alt="" className="arrival-Photo111 arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div>
                </section>

           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage1234(e)}>
                   






                <input className="input11" type="text"   value={Namea} onChange={(e)=>setNamea(e.target.value)}  name="name" placeholder="Enter Title Name" /><br/>



                              

                <div style={{display : "flex" , justifyContent : "flexLeft"}}>

<span  onClick={()=>setDis123(1)} style={{borderRadius : "0px",padding: "4px 5px",height: "38px",marginTop: "8px", background : "#eee" ,width : "74px",marginRight : "0px", color  : "black"  , fontSize : "16px"}}>Username</span>
                        <div style={{height: "38px",marginTop : "8px",paddingTop : "4px",background:"#eee"}}  onClick={()=>swal("", "",{ button: "Got It"})}><span onClick={()=>setDis123(1)} style={{borderRadius : "40px",padding: "0px 7px",height: "10px",marginTop: "8px" ,background : "#eee" ,width : "24px",border: "1px solid black",marginRight : "0px", color  : "black"  , fontSize : "11px"}}>i</span></div>

{disUser ? 
<>
<input className="input11" type="text"   value={email12} onChange={(e)=>setemail12(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>

</>
                : ""
                   }
                     
                 
        </div>


                     
                      {/* <>
                      <input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>
                     </> */}
                       
<br />
{/*  */}
                <div style={{display : "flex" , justifyContent : "flexLeft"}}>
                       <span onClick={()=>setDis123(2)} style={{borderRadius : "0px",padding: "4px 5px",height: "38px",marginTop: "8px"  , background : "#eee" ,marginRight  : "0px", width : "74px", color  : "black"  , fontSize : "16px"}}>Url</span>
                        <div style={{height: "38px",marginTop : "8px",paddingTop : "4px",background:"#eee"}}  onClick={()=>swal("", "",{ button: "Got It"})}><span onClick={()=>setDis123(2)} style={{borderRadius : "40px",padding: "0px 7px",height: "10px",marginTop: "8px" ,background : "#eee" ,width : "24px",border: "1px solid black",marginRight : "0px", color  : "black"  , fontSize : "11px"}}>i</span></div>

                   {disUser1 ? 
                     <><input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here " /><br/></>
                     : ""
                   }
                   </div>














{/* 
                <input className="input11" type="text"   value={Name} onChange={(e)=>setName(e.target.value)}  name="name" placeholder="Enter Link Here" /><br/>

                                 




                     
              
                     <input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here " /><br/>
                    
                    
<br /> */}

                     <br />
                    <br />
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}






{data.photo === "" && data.name === "snapchat" ? 
<>
<br/>

      <DeleteForeverIcon style={{color : "black",position: "absolute",right: "30px",top: "101px", fontSize : "30px"}} onClick={()=>setChay(data)}/>                          





              <section>
                    <div className="container mi2" style={{margin : "70px auto" , width : "96%"}}>
                            <div className="row justify-content-center">
                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={sc1} alt="" className="arrival-Photo111 arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div> <center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>snapchat</p>
</center>
                </section>

           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage(e)}>
                   
  <div style={{display : "flex" , justifyContent : "flexLeft"}}>

<span  onClick={()=>setDis123(1)} style={{borderRadius : "0px",padding: "4px 5px",height: "38px",marginTop: "8px", background : "#eee" ,width : "74px",marginRight : "0px", color  : "black"  , fontSize : "16px"}}>Username</span>
<div style={{height: "38px",marginTop : "8px",paddingTop : "4px",background:"#eee"}} onClick={()=>swal("How to get your Snapchat username.", "tap on your Profile icon at the top of the screen -- your username is shown under your display name.",{ button: "Got It"})}><span style={{borderRadius : "40px",padding: "0px 7px",height: "10px",marginTop: "8px" ,background : "#eee" ,width : "24px",border: "1px solid black",marginRight : "0px", color  : "black"  , fontSize : "11px"}}>i</span></div>

{disUser ? 
<>
<input className="input11" type="text"   value={email12} onChange={(e)=>setemail12(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>

</>
                : ""
                   }
                     
                 
        </div>


                     
                      {/* <>
                      <input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>
                     </> */}
                       
<br />

                {/* <div style={{display : "flex" , justifyContent : "flexLeft"}}>
                       <span onClick={()=>setDis123(2)} style={{borderRadius : "0px",padding: "4px 5px",height: "38px",marginTop: "8px"  , background : "#eee" ,marginRight  : "0px", width : "74px", color  : "black"  , fontSize : "16px"}}>Url</span>
                        <div style={{height: "38px",marginTop : "8px",paddingTop : "4px",background:"#eee"}}  onClick={()=>swal("", "",{ button: "Got It"})}><span onClick={()=>setDis123(2)} style={{borderRadius : "40px",padding: "0px 7px",height: "10px",marginTop: "8px" ,background : "#eee" ,width : "24px",border: "1px solid black",marginRight : "0px", color  : "black"  , fontSize : "11px"}}>i</span></div>

                       {disUser1 ? 
                     <><input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here " /><br/></>
                     : ""
                   }
                   </div>                    <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                     <br />
                    <br />
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}

{data.photo === "" && data.name === "tiktok" ? 

<>
<br/>

      <DeleteForeverIcon style={{color : "black",position: "absolute",right: "30px",top: "101px", fontSize : "30px"}} onClick={()=>setChay(data)}/>                          





              <section>
                    <div className="container mi2" style={{margin : "70px auto" , width : "96%"}}>
                            <div className="row justify-content-center">
                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={tk1} alt="" className="arrival-Photo111 arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div>           <center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>tiktok</p>
</center>
                </section>

           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage(e)}>
                   
  <div style={{display : "flex" , justifyContent : "flexLeft"}}>

<span  onClick={()=>setDis123(1)} style={{borderRadius : "0px",padding: "4px 5px",height: "38px",marginTop: "8px", background : "#eee" ,width : "74px",marginRight : "0px", color  : "black"  , fontSize : "16px"}}>Username</span>
<div style={{height: "38px",marginTop : "8px",paddingTop : "4px",background:"#eee"}} onClick={()=>swal("How to get your TikTok username", "Open TikTok and go to your profile in the bottom right. Tap the username right under your profile picture and paste that here. No need to include '@'",{ button: "Got It"})}><span style={{borderRadius : "40px",padding: "0px 7px",height: "10px",marginTop: "8px" ,background : "#eee" ,width : "24px",border: "1px solid black",marginRight : "0px", color  : "black"  , fontSize : "11px"}}>i</span></div>

{disUser ? 
<>
<input className="input11" type="text"   value={email12} onChange={(e)=>setemail12(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>

</>
                : ""
                   }
                     
                 
        </div>


                     
                      {/* <>
                      <input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>
                     </> */}
                       
<br />

                {/* <div style={{display : "flex" , justifyContent : "flexLeft"}}>
                       <span onClick={()=>setDis123(2)} style={{borderRadius : "0px",padding: "4px 5px",height: "38px",marginTop: "8px"  , background : "#eee" ,marginRight  : "0px", width : "74px", color  : "black"  , fontSize : "16px"}}>Url</span>
                        <div style={{height: "38px",marginTop : "8px",paddingTop : "4px",background:"#eee"}}  onClick={()=>swal("", "",{ button: "Got It"})}><span onClick={()=>setDis123(2)} style={{borderRadius : "40px",padding: "0px 7px",height: "10px",marginTop: "8px" ,background : "#eee" ,width : "24px",border: "1px solid black",marginRight : "0px", color  : "black"  , fontSize : "11px"}}>i</span></div>

                       {disUser1 ? 
                     <><input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here " /><br/></>
                     : ""
                   }
                   </div>                    <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                     <br />
                    <br />
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}

{data.photo === "" && data.name === "facebook" ? 


<>
<br/>

      <DeleteForeverIcon style={{color : "black",position: "absolute",right: "30px",top: "101px", fontSize : "30px"}} onClick={()=>setChay(data)}/>                          





              <section>
                    <div className="container mi2" style={{margin : "70px auto" , width : "96%"}}>
                            <div className="row justify-content-center">

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={fb1} alt="" className="arrival-Photo111 arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div>         <center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>facebook</p>
</center>
                </section>

           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage(e)}>
                   
  {/* <div style={{display : "flex" , justifyContent : "flexLeft"}}>

<span  onClick={()=>setDis123(1)} style={{borderRadius : "0px",padding: "4px 5px",height: "38px",marginTop: "8px", background : "#eee" ,width : "74px",marginRight : "0px", color  : "black"  , fontSize : "16px"}}>Username</span>
                        <div style={{height: "38px",marginTop : "8px",paddingTop : "4px",background:"#eee"}}  onClick={()=>swal("", "",{ button: "Got It"})}><span onClick={()=>setDis123(1)} style={{borderRadius : "40px",padding: "0px 7px",height: "10px",marginTop: "8px" ,background : "#eee" ,width : "24px",border: "1px solid black",marginRight : "0px", color  : "black"  , fontSize : "11px"}}>i</span></div>

{disUser ? 
<>
<input className="input11" type="text"   value={email12} onChange={(e)=>setemail12(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>

</>
                : ""
                   }
                     
                 
        </div> */}


                     
                      {/* <>
                      <input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>
                     </> */}
                       
<br />

                 <div style={{display : "flex" , justifyContent : "flexLeft"}}>
                       <span onClick={()=>setDis123(2)} style={{borderRadius : "0px",padding: "4px 5px",height: "38px",marginTop: "8px"  , background : "#eee" ,marginRight  : "0px", width : "74px", color  : "black"  , fontSize : "16px"}}>Url</span>
                       <div style={{height: "38px",marginTop : "8px",paddingTop : "4px",background:"#eee"}} onClick={()=>swal("How to get your Facebook profile link", "Open the Facebook app and tap on the hamburger icon in the bottom right. Tap your name at the top and once on your profile, tap the 3 dots button on the right. Then at the bottom tap 'Copy link' and paste here ",{ button: "Got It"})}><span style={{borderRadius : "40px",padding: "0px 7px",height: "10px",marginTop: "8px" ,background : "#eee" ,width : "24px",border: "1px solid black",marginRight : "0px", color  : "black"  , fontSize : "11px"}}>i</span></div>

                   {disUser1 ? 
                  //  {disUser1 ? 
                     <><input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here " /><br/></>
                     : ""
                   }
                   </div>
                   {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/>  */}
                     <br />
                    <br />
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}

{data.photo === "" && data.name === "linkedin" ? 

<>
<br/>

      <DeleteForeverIcon style={{color : "black",position: "absolute",right: "30px",top: "101px", fontSize : "30px"}} onClick={()=>setChay(data)}/>                          





              <section>
                    <div className="container mi2" style={{margin : "70px auto" , width : "96%"}}>
                            <div className="row justify-content-center">

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={ln1} alt="" className="arrival-Photo111 arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div><center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>linkedin</p>
</center>
                </section>

           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage(e)}>
                   
  {/* <div style={{display : "flex" , justifyContent : "flexLeft"}}>

<span  onClick={()=>setDis123(1)} style={{borderRadius : "0px",padding: "4px 5px",height: "38px",marginTop: "8px", background : "#eee" ,width : "74px",marginRight : "0px", color  : "black"  , fontSize : "16px"}}>Username</span>
                        <div style={{height: "38px",marginTop : "8px",paddingTop : "4px",background:"#eee"}}  onClick={()=>swal("", "",{ button: "Got It"})}><span onClick={()=>setDis123(1)} style={{borderRadius : "40px",padding: "0px 7px",height: "10px",marginTop: "8px" ,background : "#eee" ,width : "24px",border: "1px solid black",marginRight : "0px", color  : "black"  , fontSize : "11px"}}>i</span></div>

{disUser ? 
<>
<input className="input11" type="text"   value={email12} onChange={(e)=>setemail12(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>

</>
                : ""
                   }
                     
                 
        </div> */}


                     
                      {/* <>
                      <input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>
                     </> */}
                       
<br />

                <div style={{display : "flex" , justifyContent : "flexLeft"}}>
                       <span onClick={()=>setDis123(2)} style={{borderRadius : "0px",padding: "4px 5px",height: "38px",marginTop: "8px"  , background : "#eee" ,marginRight  : "0px", width : "74px", color  : "black"  , fontSize : "16px"}}>Url</span>
                       <div style={{height: "38px",marginTop : "8px",paddingTop : "4px",background:"#eee"}} onClick={()=>swal("How to get your LinkedIn profile link", "Open the LinkedIn app, tap on your profile icon in the top left. Then tap 'View Profile'. Once at your profile, tap the 3 dots button on the right. Then tap 'Share via...' and then 'Copy'. Paste the link here",{ button: "Got It"})}><span style={{borderRadius : "40px",padding: "0px 7px",height: "10px",marginTop: "8px" ,background : "#eee" ,width : "24px",border: "1px solid black",marginRight : "0px", color  : "black"  , fontSize : "11px"}}>i</span></div>

                   {disUser1 ? 
                  //  {disUser1 ? 
                     <><input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here " /><br/></>
                     : ""
                   }
                   </div>                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                     <br />
                    <br />
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}

{data.photo === "" && data.name === "twitter" ? 

<>
<br/>

      <DeleteForeverIcon style={{color : "black",position: "absolute",right: "30px",top: "101px", fontSize : "30px"}} onClick={()=>setChay(data)}/>                          





              <section>
                    <div className="container mi2" style={{margin : "70px auto" , width : "96%"}}>
                            <div className="row justify-content-center">

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={tw1} alt="" className="arrival-Photo111 arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div>  <center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>twitter</p>
</center>
                </section>

           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage(e)}>
                   
  <div style={{display : "flex" , justifyContent : "flexLeft"}}>

<span  onClick={()=>setDis123(1)} style={{borderRadius : "0px",padding: "4px 5px",height: "38px",marginTop: "8px", background : "#eee" ,width : "74px",marginRight : "0px", color  : "black"  , fontSize : "16px"}}>Username</span>
<div style={{height: "38px",marginTop : "8px",paddingTop : "4px",background:"#eee"}} onClick={()=>swal("How to get your Twitter username", "Open Twitter and go to your profile. Type the username you see in grey under your name that starts with '@' here. No need to include '@' ",{ button: "Got It"})}><span style={{borderRadius : "40px",padding: "0px 7px",height: "10px",marginTop: "8px" ,background : "#eee" ,width : "24px",border: "1px solid black",marginRight : "0px", color  : "black"  , fontSize : "11px"}}>i</span></div>

{disUser ? 
<>
<input className="input11" type="text"   value={email12} onChange={(e)=>setemail12(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>

</>
                : ""
                   }
                     
                 
        </div>


                     
                      {/* <>
                      <input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>
                     </> */}
                       
<br />

                {/* <div style={{display : "flex" , justifyContent : "flexLeft"}}>
                       <span onClick={()=>setDis123(2)} style={{borderRadius : "0px",padding: "4px 5px",height: "38px",marginTop: "8px"  , background : "#eee" ,marginRight  : "0px", width : "74px", color  : "black"  , fontSize : "16px"}}>Url</span>
                        <div style={{height: "38px",marginTop : "8px",paddingTop : "4px",background:"#eee"}}  onClick={()=>swal("", "",{ button: "Got It"})}><span onClick={()=>setDis123(2)} style={{borderRadius : "40px",padding: "0px 7px",height: "10px",marginTop: "8px" ,background : "#eee" ,width : "24px",border: "1px solid black",marginRight : "0px", color  : "black"  , fontSize : "11px"}}>i</span></div>

                       {disUser1 ? 
                     <><input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here " /><br/></>
                     : ""
                   }
                   </div>                    <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                     <br />
                    <br />
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}

{data.photo === "" && data.name === "youtube" ? 

<>
<br/>

      <DeleteForeverIcon style={{color : "black",position: "absolute",right: "30px",top: "101px", fontSize : "30px"}} onClick={()=>setChay(data)}/>                          





              <section>
                    <div className="container mi2" style={{margin : "70px auto" , width : "96%"}}>
                            <div className="row justify-content-center">

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={you1} alt="" className="arrival-Photo111 arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div>     <center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>youtube</p>
</center>
                </section>

           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage(e)}>
                   
  {/* <div style={{display : "flex" , justifyContent : "flexLeft"}}>

<span  onClick={()=>setDis123(1)} style={{borderRadius : "0px",padding: "4px 5px",height: "38px",marginTop: "8px", background : "#eee" ,width : "74px",marginRight : "0px", color  : "black"  , fontSize : "16px"}}>Username</span>
                        <div style={{height: "38px",marginTop : "8px",paddingTop : "4px",background:"#eee"}}  onClick={()=>swal("", "",{ button: "Got It"})}><span onClick={()=>setDis123(1)} style={{borderRadius : "40px",padding: "0px 7px",height: "10px",marginTop: "8px" ,background : "#eee" ,width : "24px",border: "1px solid black",marginRight : "0px", color  : "black"  , fontSize : "11px"}}>i</span></div>

{disUser ? 
<>
<input className="input11" type="text"   value={email12} onChange={(e)=>setemail12(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>

</>
                : ""
                   }
                     
                 
        </div> */}


                     
                      {/* <>
                      <input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>
                     </> */}
                       
<br />

                <div style={{display : "flex" , justifyContent : "flexLeft"}}>
                       <span onClick={()=>setDis123(2)} style={{borderRadius : "0px",padding: "4px 5px",height: "38px",marginTop: "8px"  , background : "#eee" ,marginRight  : "0px", width : "74px", color  : "black"  , fontSize : "16px"}}>Url</span>
                       <div style={{height: "38px",marginTop : "8px",paddingTop : "4px",background:"#eee"}} onClick={()=>swal("How to get your Youtube user/channel link", "Open the YouTube app and go to your channel. tap on 'Your channel' then tap the 3 dots in the top right. Tap 'Share' and then copy and paste link here. You can also copy and paste a video link if you'd like to share a video instead.",{ button: "Got It"})}><span style={{borderRadius : "40px",padding: "0px 7px",height: "10px",marginTop: "8px" ,background : "#eee" ,width : "24px",border: "1px solid black",marginRight : "0px", color  : "black"  , fontSize : "11px"}}>i</span></div>

                   {disUser1 ? 
                  //  {disUser1 ? 
                     <><input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here " /><br/></>
                     : ""
                   }
                   </div>                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                     <br />
                    <br />
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}

{data.photo === "" && data.name === "twitch" ? 

<>
<br/>

<DeleteForeverIcon style={{color : "black",position: "absolute",right: "30px",top: "101px", fontSize : "30px"}} onClick={()=>setChay(data)}/>                          

              <section>
                    <div className="container mi2" style={{margin : "70px auto" , width : "96%"}}>
                            <div className="row justify-content-center">

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={tt1} alt="" className="arrival-Photo111 arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div>  <center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>twitch</p>
</center>
                </section>

           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage(e)}>
                   
  <div style={{display : "flex" , justifyContent : "flexLeft"}}>

<span  onClick={()=>setDis123(1)} style={{borderRadius : "0px",padding: "4px 5px",height: "38px",marginTop: "8px", background : "#eee" ,width : "74px",marginRight : "0px", color  : "black"  , fontSize : "16px"}}>Username</span>
<div style={{height: "38px",marginTop : "8px",paddingTop : "4px",background:"#eee"}} onClick={()=>swal("How to get your twitch username", "Click your profile picture in the top right-hand corner and select Settings from the drop-down menu then Find your Pinterest Username.",{ button: "Got It"})}><span style={{borderRadius : "40px",padding: "0px 7px",height: "10px",marginTop: "8px" ,background : "#eee" ,width : "24px",border: "1px solid black",marginRight : "0px", color  : "black"  , fontSize : "11px"}}>i</span></div>

{disUser ? 
<>
<input className="input11" type="text"   value={email12} onChange={(e)=>setemail12(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>

</>
                : ""
                   }
                     
                 
        </div>


                     
                      {/* <>
                      <input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>
                     </> */}
                       
<br />

                {/* <div style={{display : "flex" , justifyContent : "flexLeft"}}>
                       <span onClick={()=>setDis123(2)} style={{borderRadius : "0px",padding: "4px 5px",height: "38px",marginTop: "8px"  , background : "#eee" ,marginRight  : "0px", width : "74px", color  : "black"  , fontSize : "16px"}}>Url</span>
                        <div style={{height: "38px",marginTop : "8px",paddingTop : "4px",background:"#eee"}}  onClick={()=>swal("", "",{ button: "Got It"})}><span onClick={()=>setDis123(2)} style={{borderRadius : "40px",padding: "0px 7px",height: "10px",marginTop: "8px" ,background : "#eee" ,width : "24px",border: "1px solid black",marginRight : "0px", color  : "black"  , fontSize : "11px"}}>i</span></div>

                       {disUser1 ? 
                     <><input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here " /><br/></>
                     : ""
                   }
                   </div>                    <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                     <br />
                    <br />
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}

{data.photo === "" && data.name === "pinterest" ? 

<>
<br/>

<DeleteForeverIcon style={{color : "black",position: "absolute",right: "30px",top: "101px", fontSize : "30px"}} onClick={()=>setChay(data)}/>                          

              <section>
                    <div className="container mi2" style={{margin : "70px auto" , width : "96%"}}>
                            <div className="row justify-content-center">

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={pi1} alt="" className="arrival-Photo111 arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div><center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>pinterest</p>
</center>
                </section>

           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage(e)}>
                   
  <div style={{display : "flex" , justifyContent : "flexLeft"}}>

<span  onClick={()=>setDis123(1)} style={{borderRadius : "0px",padding: "4px 5px",height: "38px",marginTop: "8px", background : "#eee" ,width : "74px",marginRight : "0px", color  : "black"  , fontSize : "16px"}}>Username</span>
<div style={{height: "38px",marginTop : "8px",paddingTop : "4px",background:"#eee"}} onClick={()=>swal("How do I find my Pinterest username?", "1.	Login to your Pinterest account. Go to http://www.pinterest.com and login to the account you'd like to connect to. 2.	Go to your Profile page. In the upper right-hand corner, click on your profile picture, then click on My profile. 3.	Find your Pinterest Username.",{ button: "Got It"})}><span style={{borderRadius : "40px",padding: "0px 7px",height: "10px",marginTop: "8px" ,background : "#eee" ,width : "24px",border: "1px solid black",marginRight : "0px", color  : "black"  , fontSize : "11px"}}>i</span></div>

{disUser ? 
<>
<input className="input11" type="text"   value={email12} onChange={(e)=>setemail12(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>

</>
                : ""
                   }
                     
                 
        </div>


                     
                      {/* <>
                      <input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>
                     </> */}
                       
<br />

                {/* <div style={{display : "flex" , justifyContent : "flexLeft"}}>
                       <span onClick={()=>setDis123(2)} style={{borderRadius : "0px",padding: "4px 5px",height: "38px",marginTop: "8px"  , background : "#eee" ,marginRight  : "0px", width : "74px", color  : "black"  , fontSize : "16px"}}>Url</span>
                        <div style={{height: "38px",marginTop : "8px",paddingTop : "4px",background:"#eee"}}  onClick={()=>swal("", "",{ button: "Got It"})}><span onClick={()=>setDis123(2)} style={{borderRadius : "40px",padding: "0px 7px",height: "10px",marginTop: "8px" ,background : "#eee" ,width : "24px",border: "1px solid black",marginRight : "0px", color  : "black"  , fontSize : "11px"}}>i</span></div>

                       {disUser1 ? 
                     <><input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here " /><br/></>
                     : ""
                   }
                   </div>                    <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                     <br />
                    <br />
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}
{/* 
{data.name === "Custom Link" ? 

<>
<br/>


              <section>
                    <div className="container mi2" style={{margin : "70px auto" , width : "96%"}}>
                            <div className="row justify-content-center">

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={c3} alt="" className="arrival-Photo111 arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div>
                </section>

           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage1(e)}>
                   
                    <input className="input11" type="text"   value={Name11} onChange={(e)=>setName11(e.target.value)}  name="name" placeholder="Enter Link Here" /><br/>
                    <input className="input11" type="file"    onChange={(e)=>setImage11(e.target.files[0])}  name="name" placeholder="Enter Link Here" /><br/>
                    <input className="input11" type="text"   value={email11} onChange={(e)=>setemail11(e.target.value)}  name="name" placeholder="Enter Link Here " /><br/>
                     <br />
                    <br />
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
} */}

{data.photo === "" && data.name === "cash app" ? 

<>
<br/>

      <DeleteForeverIcon style={{color : "black",position: "absolute",right: "30px",top: "101px", fontSize : "30px"}} onClick={()=>setChay(data)}/>                          





              <section>
                    <div className="container mi2" style={{margin : "70px auto" , width : "96%"}}>
                            <div className="row justify-content-center">

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={ca1} alt="" className="arrival-Photo111 arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div> <center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>cash app</p>
</center>
                </section>

           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage(e)}>
                   
                <div style={{display : "flex" , justifyContent : "flexLeft"}}>

<span  onClick={()=>setDis123(1)} style={{borderRadius : "0px",padding: "4px 5px",height: "38px",marginTop: "8px", background : "#eee" ,width : "74px",marginRight : "0px", color  : "black"  , fontSize : "16px"}}>Username</span>
<div style={{height: "38px",marginTop : "8px",paddingTop : "4px",background:"#eee"}} onClick={()=>swal("How to get your Cash App username", "Open Cash App and tap on the profile picture in the top right. Type the username you see in grey under your name here. No need to include the '$'",{ button: "Got It"})}><span style={{borderRadius : "40px",padding: "0px 7px",height: "10px",marginTop: "8px" ,background : "#eee" ,width : "24px",border: "1px solid black",marginRight : "0px", color  : "black"  , fontSize : "11px"}}>i</span></div>

{disUser ? 
<>
<input className="input11" type="text"   value={email12} onChange={(e)=>setemail12(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>

</>
                : ""
                   }
                     
                 
        </div>


                     
                      {/* <>
                      <input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>
                     </> */}
                       
<br />

                {/* <div style={{display : "flex" , justifyContent : "flexLeft"}}>
                       <span onClick={()=>setDis123(2)} style={{borderRadius : "0px",padding: "4px 5px",height: "38px",marginTop: "8px"  , background : "#eee" ,marginRight  : "0px", width : "74px", color  : "black"  , fontSize : "16px"}}>Url</span>
                        <div style={{height: "38px",marginTop : "8px",paddingTop : "4px",background:"#eee"}}  onClick={()=>swal("", "",{ button: "Got It"})}><span onClick={()=>setDis123(2)} style={{borderRadius : "40px",padding: "0px 7px",height: "10px",marginTop: "8px" ,background : "#eee" ,width : "24px",border: "1px solid black",marginRight : "0px", color  : "black"  , fontSize : "11px"}}>i</span></div>

                       {disUser1 ? 
                     <><input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here " /><br/></>
                     : ""
                   }
                   </div>                    <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                     <br />
                    <br />
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}

{data.name === "website" ? 

<>
<br/>

      <DeleteForeverIcon style={{color : "black",position: "absolute",right: "30px",top: "101px", fontSize : "30px"}} onClick={()=>setChay(data)}/>                          





              <section>
                    <div className="container mi2" style={{margin : "70px auto" , width : "96%"}}>
                            <div className="row justify-content-center">

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={we1} alt="" className="arrival-Photo111 arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div><center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>website</p>
</center>
                </section>

           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage(e)}>
                   
                    <input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Website link e.g (https://www.web.com)" /><br/>
                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                     <br />
                    <br />
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}

{data.name === "Address" ? 

<>
<br/>

      <DeleteForeverIcon style={{color : "black",position: "absolute",right: "30px",top: "101px", fontSize : "30px"}} onClick={()=>setChay(data)}/>                          





              <section>
                    <div className="container mi2" style={{margin : "70px auto" , width : "96%"}}>
                            <div className="row justify-content-center">

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={lo1} alt="" className="arrival-Photo111 arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div>
                            <center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>Address</p>
</center>
                </section>

           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage(e)}>
                   
                    <input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here " /><br/>
                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                     <br />
                    <br />
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}

{data.photo === "" && data.name === "paypal" ? 

<>
<br/>

      <DeleteForeverIcon style={{color : "black",position: "absolute",right: "30px",top: "101px", fontSize : "30px"}} onClick={()=>setChay(data)}/>                          





              <section>
                    <div className="container mi2" style={{margin : "70px auto" , width : "96%"}}>
                            <div className="row justify-content-center">

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
[                                                <Link to={"/main-categories/"}><img src={pp1} alt="" className="arrival-Photo111 arrival-P"/></Link>
]                                            </div>
                                            
                                     
                               
                            </div>
                            </div><center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>paypal</p>
</center>
                </section>

           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage(e)}>
                   
  <div style={{display : "flex" , justifyContent : "flexLeft"}}>

<span  onClick={()=>setDis123(1)} style={{borderRadius : "0px",padding: "4px 5px",height: "38px",marginTop: "8px", background : "#eee" ,width : "74px",marginRight : "0px", color  : "black"  , fontSize : "16px"}}>Username</span>
<div style={{height: "38px",marginTop : "8px",paddingTop : "4px",background:"#eee"}} onClick={()=>swal("How to get your paypal.me link", "Open PayPal and copy and paste your PayPal.me link here",{ button: "Got It"})}><span style={{borderRadius : "40px",padding: "0px 7px",height: "10px",marginTop: "8px" ,background : "#eee" ,width : "24px",border: "1px solid black",marginRight : "0px", color  : "black"  , fontSize : "11px"}}>i</span></div>

{disUser ? 
<>
<input className="input11" type="text"   value={email12} onChange={(e)=>setemail12(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>

</>
                : ""
                   }
                     
                 
        </div>


                     
                      {/* <>
                      <input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>
                     </> */}
                       
<br />

                {/* <div style={{display : "flex" , justifyContent : "flexLeft"}}>
                       <span onClick={()=>setDis123(2)} style={{borderRadius : "0px",padding: "4px 5px",height: "38px",marginTop: "8px"  , background : "#eee" ,marginRight  : "0px", width : "74px", color  : "black"  , fontSize : "16px"}}>Url</span>
                        <div style={{height: "38px",marginTop : "8px",paddingTop : "4px",background:"#eee"}}  onClick={()=>swal("", "",{ button: "Got It"})}><span onClick={()=>setDis123(2)} style={{borderRadius : "40px",padding: "0px 7px",height: "10px",marginTop: "8px" ,background : "#eee" ,width : "24px",border: "1px solid black",marginRight : "0px", color  : "black"  , fontSize : "11px"}}>i</span></div>

                       {disUser1 ? 
                     <><input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here " /><br/></>
                     : ""
                   }
                   </div>                    <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                     <br />
                    <br />
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}

{data.photo === "" && data.name === "Venmo" ? 

<>
<br/>

      <DeleteForeverIcon style={{color : "black",position: "absolute",right: "30px",top: "101px", fontSize : "30px"}} onClick={()=>setChay(data)}/>                          





              <section>
                    <div className="container mi2" style={{margin : "70px auto" , width : "96%"}}>
                            <div className="row justify-content-center">

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={ve1} alt="" className="arrival-Photo111 arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div><center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>Venmo</p>
</center>
                </section>

           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage(e)}>
                   
  <div style={{display : "flex" , justifyContent : "flexLeft"}}>

<span  onClick={()=>setDis123(1)} style={{borderRadius : "0px",padding: "4px 5px",height: "38px",marginTop: "8px", background : "#eee" ,width : "74px",marginRight : "0px", color  : "black"  , fontSize : "16px"}}>Username</span>
<div style={{height: "38px",marginTop : "8px",paddingTop : "4px",background:"#eee"}} onClick={()=>swal("How to get your Venmo username", "Open Venmo and tap the tab in the bottom right to go to your profile. You will see your venmo username starting with a '@' under your name. Type that in here. No need to include the '@'",{ button: "Got It"})}><span style={{borderRadius : "40px",padding: "0px 7px",height: "10px",marginTop: "8px" ,background : "#eee" ,width : "24px",border: "1px solid black",marginRight : "0px", color  : "black"  , fontSize : "11px"}}>i</span></div>

{disUser ? 
<>
<input className="input11" type="text"   value={email12} onChange={(e)=>setemail12(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>

</>
                : ""
                   }
                     
                 
        </div>


                     
                      {/* <>
                      <input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>
                     </> */}
                       
<br />

                {/* <div style={{display : "flex" , justifyContent : "flexLeft"}}>
                       <span onClick={()=>setDis123(2)} style={{borderRadius : "0px",padding: "4px 5px",height: "38px",marginTop: "8px"  , background : "#eee" ,marginRight  : "0px", width : "74px", color  : "black"  , fontSize : "16px"}}>Url</span>
                        <div style={{height: "38px",marginTop : "8px",paddingTop : "4px",background:"#eee"}}  onClick={()=>swal("", "",{ button: "Got It"})}><span onClick={()=>setDis123(2)} style={{borderRadius : "40px",padding: "0px 7px",height: "10px",marginTop: "8px" ,background : "#eee" ,width : "24px",border: "1px solid black",marginRight : "0px", color  : "black"  , fontSize : "11px"}}>i</span></div>

                       {disUser1 ? 
                     <><input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here " /><br/></>
                     : ""
                   }
                   </div>                    <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                     <br />
                    <br />
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}
{data.name === "text" ? 

<>
<br/>

      <DeleteForeverIcon style={{color : "black",position: "absolute",right: "30px",top: "101px", fontSize : "30px"}} onClick={()=>setChay(data)}/>                          





              <section>
                    <div className="container mi2" style={{margin : "70px auto" , width : "96%"}}>
                            <div className="row justify-content-center">

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={mess1} alt="" className="arrival-Photo111 arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div><center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>Text</p>
</center>
                </section>

           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage(e)}>
                {/* <div style={{display : "flex" , justifyContent : "flex-start"}}>
                    <select name="" id=""  value={UserPhoneCode} onChange={(e)=>setUserPhoneCode(e.target.value)}  style={{width : "117px" , fontSize :"12px" , height: "37px" , marginTop : "8px"}}>
                      
                      {
                        PhoneCode.map((res,i)=>{
                          return(
                            <option value={res.phoneCode} >{res.value}  +{res.phoneCode}</option>
                            <option value={"+" + res.phoneCode} onChange={()=>setUserPhoneCode(res)}>{res.value}  +{res.phoneCode}</option>
                          )
                        })
                      }
                    </select> */}
                    <input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Number Here" /><br/>
                    {/* </div> */}
                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                     <br />
                    <br />
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}
{data.name === "whatsapps" ? 

<>
<br/>

      <DeleteForeverIcon style={{color : "black",position: "absolute",right: "30px",top: "101px", fontSize : "30px"}} onClick={()=>setChay(data)}/>                          





              <section>
                    <div className="container mi2" style={{margin : "70px auto" , width : "96%"}}>
                            <div className="row justify-content-center">

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={wa1} alt="" className="arrival-Photo111 arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div>  <center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>Whatsapp</p>
</center>
                </section>

           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage(e)}>
                {/* <div style={{display : "flex" , justifyContent : "flex-start"}}>
                    <select name="" id=""  value={UserPhoneCode} onChange={(e)=>setUserPhoneCode(e.target.value)}  style={{width : "117px" , fontSize :"12px" , height: "37px" , marginTop : "8px"}}>
                      
                      {
                        PhoneCode.map((res,i)=>{
                          return(
                            <option value={res.phoneCode} >{res.value}  +{res.phoneCode}</option>
                            <option value={"+" + res.phoneCode} onChange={()=>setUserPhoneCode(res)}>{res.value}  +{res.phoneCode}</option>
                          )
                        })
                      }
                    </select> */}
                    <input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Number Here" /><br/>
                    {/* </div> */}
                   
                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                     <br />
                    <br />
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}
{data.name === "email" ? 

<>
<br/>

      <DeleteForeverIcon style={{color : "black",position: "absolute",right: "30px",top: "101px", fontSize : "30px"}} onClick={()=>setChay(data)}/>                          





              <section>
                    <div className="container mi2" style={{margin : "70px auto" , width : "96%"}}>
                            <div className="row justify-content-center">

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={mail1} alt="" className="arrival-Photo111 arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div><center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>Email</p>
</center>
                </section>

           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage(e)}>
                   
                    <input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Email Here" /><br/>
                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                     <br />
                    <br />
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}
{data.name === "phone" ? 

<>
<br/>

      <DeleteForeverIcon style={{color : "black",position: "absolute",right: "30px",top: "101px", fontSize : "30px"}} onClick={()=>setChay(data)}/>                          





              <section>
                    <div className="container mi2" style={{margin : "70px auto" , width : "96%"}}>
                            <div className="row justify-content-center">

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={call1} alt="" className="arrival-Photo111 arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div><center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>Number</p>
</center>
                </section>

           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage(e)}>
                {/* <div style={{display : "flex" , justifyContent : "flex-start"}}>
                    <select name="" id=""  value={UserPhoneCode} onChange={(e)=>setUserPhoneCode(e.target.value)}  style={{width : "117px" , fontSize :"12px" , height: "37px" , marginTop : "8px"}}>
                      
                      {
                        PhoneCode.map((res,i)=>{
                          return(
                            <option value={res.phoneCode} >{res.value}  +{res.phoneCode}</option>
                            <option value={"+" + res.phoneCode} onChange={()=>setUserPhoneCode(res)}>{res.value}  +{res.phoneCode}</option>
                          )
                        })
                      }
                    </select> */}
                    <input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Number Here" /><br/>
                    {/* </div> */}
                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                     <br />
                    <br />
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}

         
              





{data.name === "address" ? 

<>
<br/>

      <DeleteForeverIcon style={{color : "black",position: "absolute",right: "30px",top: "101px", fontSize : "30px"}} onClick={()=>setChay(data)}/>                          





              <section>
                    <div className="container mi2" style={{margin : "70px auto" , width : "96%"}}>
                            <div className="row justify-content-center">
                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={lo1} alt="" className="arrival-Photo111 arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div><center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>Address</p>
</center>
                </section>

           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage(e)}>
                   
                    <input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here " /><br/>
                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                     <br />
                    <br />
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}


{data.name === "Embed video" ? 

<>

<br/>

      <DeleteForeverIcon style={{color : "black",position: "absolute",right: "30px",top: "101px", fontSize : "30px"}} onClick={()=>setChay(data)}/>                          




<section>
                    <div className="container mi2" style={{margin : "70px auto 20px auto" , width : "97%"}}>
                            <div className="row justify-content-center">

                                <center>
  
  <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12" style={{margin : "40px 0px"}}>
  <div class="px-1" style={{background : "rgb(245, 246, 246)", borderRadius : "10px"}}>
   
    <div style={{display : "flex" , flexDirection : "row" ,padding : "14px 7px"}}>
      {/* <img src={s1} alt="" style={{borderRadius : "200px",width: "140px" , height : "140px"}}/> */}
        
        <div class="px-1"  style={{display : "flex" , justifyContent : "center"}}>
              {/* <textarea rows="8" cols="40" placeholder="Enter your Bio !" style={{padding : "10px 14px " , border :"2px solid white" , borderRadius : "10px", background  : " rgb(245, 246, 246)" }}></textarea> */}
              <img src={vi1} alt="" width="100%" height="200px"/>
              {/* <p style={{color : "black"}}><b>{AdminData.user}</b></p> */}
        </div>
    </div>
     
  </div>
 

</div>
</center>










                               
                            </div>
                            </div>           <center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>Embed video</p>
</center>
                </section>


              

           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage(e)}>
                   
                    <input className="input11" required type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Embed Video Link Here" /><br/>
                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                     <br />
                    <br />
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        {/* <button className="button11" style={{width : "200px" , background : "rgb(211, 211, 211)" , color  : "black" , borderRadius :  "3px" , border : "3px solid black",fontSize : "18px"}}>Open</button> */}
                    {dispaly ? 
                        <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
                        :
                        <span className="button11" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "16px"}}>Save</span>

                    }
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}


          
        













{data.name === "Linktree" ? 

<>
<br/>

      <DeleteForeverIcon style={{color : "black",position: "absolute",right: "30px",top: "101px", fontSize : "30px"}} onClick={()=>setChay(data)}/>                          





              <section>
                    <div className="container mi2" style={{margin : "70px auto" , width : "96%"}}>
                            <div className="row justify-content-center">
                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={lt1} alt="" className="arrival-Photo111 arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div> <center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>Link tree</p>
</center>
                </section>

           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "40px 0px" }}>
                   {Nu === 0 || Nu > 0?
                       <>
                       <p> 1  </p>
                    <input className="input11" type="text"   value={Name1[0]} onChange={(e)=>setChange55(e.target.value,0)} name="name" placeholder="Enter Link Here" /><br/>
                    <input className="input11" type="text"   value={email1[0]} onChange={(e)=>setChange56(e.target.value,0)}  name="name" placeholder="Enter Link Here " /><br/>
                    </>
                     : ""}
                   {Nu === 1 || Nu > 1 ?
                       <>
                       <p> 2 </p>
                    <input className="input11" type="text"   value={Name1[1]} onChange={(e)=>setChange55(e.target.value,1)} name="name" placeholder="Enter Link Here" /><br/>
                    <input className="input11" type="text"   value={email1[1]} onChange={(e)=>setChange56(e.target.value,1)}  name="name" placeholder="Enter Link Here " /><br/>
                    </>
                     : ""}
                   {Nu === 2 || Nu > 2   ?
                       <>
                       <p>3 </p>
                    <input className="input11" type="text"   value={Name1[2]} onChange={(e)=>setChange55(e.target.value,2)} name="name" placeholder="Enter Link Here" /><br/>
                    <input className="input11" type="text"   value={email1[2]} onChange={(e)=>setChange56(e.target.value,2)}  name="name" placeholder="Enter Link Here " /><br/>
                    </>
                     : ""}
                   {Nu === 3 || Nu > 3  ?
                       <>
                       <p>4  </p>
                    <input className="input11" type="text"   value={Name1[3]} onChange={(e)=>setChange55(e.target.value,3)} name="name" placeholder="Enter Link Here" /><br/>
                    <input className="input11" type="text"   value={email1[3]} onChange={(e)=>setChange56(e.target.value,3)}  name="name" placeholder="Enter Link Here " /><br/>
                    </>
                     : ""}
                   {Nu === 4 || Nu > 4  ?
                       <>
                       <p>5  </p>
                    <input className="input11" type="text"   value={Name1[4]} onChange={(e)=>setChange55(e.target.value,4)} name="name" placeholder="Enter Link Here" /><br/>
                    <input className="input11" type="text"   value={email1[4]} onChange={(e)=>setChange56(e.target.value,4)}  name="name" placeholder="Enter Link Here " /><br/>
                    </>
                     : ""}
                    <div style={{display : "flex" , justifyContent : "space-between"}}>
                    <button onClick={()=>setNu(Nu+1)} className="button11" style={{borderRadius : "50px",width : "190px", border : "1px solid white",  background : "rgb(0, 0, 0)" ,  color  : "white", fontSize : "11px",height: "45px"}}  >Add More</button>
                        {dispaly ?
                            <button className="button11" onClick={()=> SubmitDataMessageLink()} style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}} >Save</button>
                            :
                            <button className="button11"  style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
                        }   
                    </div>
                    <br/>
                {/* </form> */}
            </div>
           </center>
           </>
           :""
}

{data.name === "JazzCash" ? 

<>
<br/>

      <DeleteForeverIcon style={{color : "black",position: "absolute",right: "30px",top: "101px", fontSize : "30px"}} onClick={()=>setChay(data)}/>                          





              <section>
                    <div className="container mi2" style={{margin : "70px auto" , width : "96%"}}>
                            <div className="row justify-content-center">
                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={jc1} alt="" className="arrival-Photo111 arrival-P"  style={{objectFit : "contain",padding: "0px 20px 0px 28px"}}/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div><center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>JazzCash</p>
</center>
                </section>

           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "40px 0px" }}>
            <p style={{textAlign: "left"}}>Account Title</p>

                    <input className="input11" type="text"   value={n} onChange={(e)=>setn(e.target.value)} name="name" placeholder="Enter  Account Title" /><br/>
                    <p style={{textAlign: "left"}}>Account Number</p>

                    <input className="input11" type="text"   value={n1} onChange={(e)=>setn1(e.target.value)}  name="name" placeholder="Enter Account Number" /><br/>
                   
                     <br />
                    <br />
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        {dispaly ?
                            <button className="button11" onClick={()=> SubmitDataMessage2()} style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "16px"}}>Save</button>
                            :
                            <span className="button11"  style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "16px"}}>Save</span>
                            }                    </div>
                    <br/>
                {/* </form> */}
            </div>
           </center>
           </>
           :""
}


{data.name === "EasyPaisa" ? 

<>
<br/>

      <DeleteForeverIcon style={{color : "black",position: "absolute",right: "30px",top: "101px", fontSize : "30px"}} onClick={()=>setChay(data)}/>                          





              <section>
                    <div className="container mi2" style={{margin : "70px auto" , width : "96%"}}>
                            <div className="row justify-content-center">
                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={ep1} alt="" className="arrival-Photo111 arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div><center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>EasyPaisa</p>
</center>
                </section>

           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "40px 0px" }}>
            <p style={{textAlign: "left"}}>Account Title</p>

                    <input className="input11" type="text"   value={n} onChange={(e)=>setn(e.target.value)} name="name" placeholder="Enter  Account Title" /><br/>
                    <p style={{textAlign: "left"}}>Account Number</p>

                    <input className="input11" type="text"   value={n1} onChange={(e)=>setn1(e.target.value)}  name="name" placeholder="Enter Account Number" /><br/>
                   
                     <br />
                    <br />
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        {dispaly ?
                            <button className="button11" onClick={()=> SubmitDataMessage2()} style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "16px"}}>Save</button>
                            :
                            <span className="button11" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "16px"}}>Save</span>
                            }                    </div>
                    <br/>
                {/* </form> */}
            </div>
           </center>
           </>
           :""
}

{data.name === "Bank" ? 

<>
<br/>

      <DeleteForeverIcon style={{color : "black",position: "absolute",right: "30px",top: "101px", fontSize : "30px"}} onClick={()=>setChay(data)}/>                          





              <section>
                    <div className="container mi2" style={{margin : "70px auto" , width : "96%"}}>
                            <div className="row justify-content-center">
                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={bk1} alt="" className="arrival-Photo111 arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div><center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>Bank</p>
</center>
                </section>

           <center>
          
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10" style={{margin : "40px 0px" }}>
            <p style={{textAlign: "left"}}>Account Title</p>

                    <input className="input11" type="text"   value={n} onChange={(e)=>setn(e.target.value)} name="name" placeholder="Enter  Account Title" /><br/>
                    <p style={{textAlign: "left"}}>Bank Name</p>

                    <input className="input11" type="text"   value={n1} onChange={(e)=>setn1(e.target.value)}  name="name" placeholder="Enter Bank Name" /><br/>
                    <p style={{textAlign: "left"}}>Account Number</p>
 
                    <input className="input11" type="text"   value={n2} onChange={(e)=>setn2(e.target.value)}  name="name" placeholder="Enter Account Number" /><br/>
                   
                     <br />
                    <br />
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        {dispaly ?
                            <button className="button11" onClick={()=> SubmitDataMessage3()} style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "16px"}}>Save</button>
                            :
                            <span className="button11" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "16px"}}>Save</span>

                            }                    </div>
                    <br/>
                {/* </form> */}
            </div>
           </center>
           </>
           :""
}

















          
        


        {/* Back to top */}
        <div className="btn-back-to-top" id="myBtn">
          <span className="symbol-btn-back-to-top">
            <i className="zmdi zmdi-chevron-up" />
          </span>
        </div>
        {/* Modal1 */}
        <div className="wrap-modal1 js-modal1 p-t-60 p-b-20">
          <div className="overlay-modal1 js-hide-modal1" />
          <div className="container mi2">
            <div className="bg0 p-t-60 p-b-30 p-lr-15-lg how-pos3-parent">
              <button className="how-pos3 hov3 trans-04 js-hide-modal1">
                <img src="images/icons/icon-close.png" alt="CLOSE" />
              </button>
              <div className="row">
                <div className="col-md-6 col-lg-7 p-b-30">
                  <div className="p-l-25 p-r-30 p-lr-0-lg">
                    <div className="wrap-slick3 flex-sb flex-w">
                      <div className="wrap-slick3-dots" />
                      <div className="wrap-slick3-arrows flex-sb-m flex-w" />
                      <div className="slick3 gallery-lb">
                        <div className="item-slick3" data-thumb="images/product-detail-01.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-01.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-01.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-02.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-02.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-02.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-03.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-03.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-03.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5 p-b-30">
                  <div className="p-r-50 p-t-5 p-lr-0-lg">
                    <h4 className="mtext-105 cl2 js-name-detail p-b-14">
                      Lightweight Jacket
                    </h4>
                    <span className="mtext-106 cl2">
                      $58.79
                    </span>
                    <p className="stext-102 cl3 p-t-23">
                      Nulla eget sem vitae eros pharetra viverra. Nam vitae luctus ligula. Mauris consequat ornare feugiat.
                    </p>
                    {/*  */}
                    <div className="p-t-33">
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Size
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Size S</option>
                              <option>Size M</option>
                              <option>Size L</option>
                              <option>Size XL</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Color
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Red</option>
                              <option>Blue</option>
                              <option>White</option>
                              <option>Grey</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-204 flex-w flex-m respon6-next">
                          <div className="wrap-num-product flex-w m-r-20 m-tb-10">
                            <div className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-minus" />
                            </div>
                            <input className="mtext-104 cl3 txt-center num-product" type="number" name="num-product" defaultValue={1} />
                            <div className="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-plus" />
                            </div>
                          </div>
                          <button className="flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04 js-addcart-detail">
                            Add to cart
                          </button>
                        </div>
                      </div>	
                    </div>
                    {/*  */}
                    <div className="flex-w flex-m p-l-100 p-t-40 respon7">
                      <div className="flex-m bor9 p-r-10 m-r-11">
                        <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 js-addwish-detail tooltip100" data-tooltip="Add to Wishlist">
                          <i className="zmdi zmdi-favorite" />
                        </a>
                      </div>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Facebook">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Twitter">
                        <i className="fa fa-twitter" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Google Plus">
                        <i className="fa fa-google-plus" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
 






        )
    
}

export default Home;