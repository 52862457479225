import React, { useState ,useEffect} from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
// import p22 from "./../assets/images/p2.png"
// import FacebookIcon from '@material-ui/icons/Facebook';
// import InstagramIcon from '@material-ui/icons/Instagram';
// import LanguageIcon from '@material-ui/icons/Language';
// import YouTubeIcon from '@material-ui/icons/YouTube';
// import TwitterIcon from '@material-ui/icons/Twitter';
// import fr from "./../assets/images/fr.JPG"
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const Contact = (props)=>  {
    const [name, setname] = useState("") 
    const [lname, setlname] = useState("") 
    const [email, setemail] = useState("") 
    const [subject, setsubject] = useState("") 
    const [Message, setMessage] = useState("") 
    const [User1, setUser1] = useState({}) 
    const [User2, setUser2] = useState({}) 
    const [Dis8, setDis8] = useState(true) 
    const [Dis9, setDis9] = useState(false) 
    const [Rad, setRad] = useState("JazzCash") 
    const [Amount, setAmount] = useState("") 
    const [Mobile, setMobile] = useState("") 
    const [BankName, setBankName] = useState("") 
    const [Title, setTitle] = useState("") 
    const [AccountNumber, setAccountNumber] = useState("") 
    const [Dis12, setDis12] = useState(false) 
    const [Dis1, setDis1] = useState(true) 
    const [Dis11, setDis11] = useState(true) 
    const [Earn, setEarn] = useState(0) 


    useEffect(() =>{
        if(! JSON.parse(localStorage.getItem("Ambassador User"))){
                props.history.push("/ambassador-login")
              }
              else{
                // setUser1(JSON.parse(localStorage.getItem("User")))
          
            //     this.setState({
            //         email: "",
            // })
            // window.location.reload(true)
            window.scrollTo(0, 0)
            document.getElementById("Close-the-header").style.display = "none"

            setUser1(JSON.parse(localStorage.getItem("Ambassador User")))
            fetch("https://tapitdabit-backend.vercel.app/allUser111/"+JSON.parse(localStorage.getItem("Ambassador User"))._id,{
                method: "GET",
                headers :  {
                    "Content-Type" : "application/json" , 
 } ,
})
.then(res5=>res5.json())
.then(res6=>{
    localStorage.setItem("Ambassador User" ,JSON.stringify(res6)) 
    setUser1(res6)
})
            fetch("https://tapitdabit-backend.vercel.app/AllUserCheckoutData",{
                method: "GET",
                headers :  {
                "Content-Type" : "application/json" , 
            } ,
            })
            .then(res7=>res7.json())
            .then(res8=>{
                console.log(res8)
                let h = []
                for (let i = 0; i < res8.length; i++) {
                    console.log(res8[i],res8[i].AmbaCoup , JSON.parse(localStorage.getItem("Ambassador User")).name+""+JSON.parse(localStorage.getItem("Ambassador User")).Num)
                    if(res8[i].AmbaCoup === JSON.parse(localStorage.getItem("Ambassador User")).name+""+JSON.parse(localStorage.getItem("Ambassador User")).Num){
                        h.push(res8[i])
                    }
                    
                }
                setUser2(h)
            })
            fetch("https://tapitdabit-backend.vercel.app/AllUserCheckoutData",{
                method: "GET",
                headers :  {
                "Content-Type" : "application/json" , 
            } ,
            })
            .then(res7=>res7.json())
            .then(res8=>{
                console.log(res8)
                let h = []
                for (let i = 0; i < res8.length; i++) {
                    console.log(res8[i],res8[i].AmbaCoup , JSON.parse(localStorage.getItem("Ambassador User")).name+""+JSON.parse(localStorage.getItem("Ambassador User")).Num)
                    if(res8[i].AmbaCoup === JSON.parse(localStorage.getItem("Ambassador User")).name+""+JSON.parse(localStorage.getItem("Ambassador User")).Num){
                        h.push(res8[i])
                    }
                    
                }
                // setUser2(h)
                let to = 0
                let to1 = 0
                for (let i = 0; i < h.length; i++) {
                    if(h[i].AfterDiscount){
                        to = to + h[i].AfterDiscount
                        if( h[i].discount.AmbaPercen){
                            to1 =  to1 + ( ((h[i].AfterDiscount * h[i].discount.AmbaPercen) / 100))
                        }
                    }

                }
                
                setEarn(to1)
                
            })
    }

    },[])

    const Submitdata1 = ()=>{
        // e.preventDefault()
        setDis1(false)

        console.log(  Rad   ,
            Mobile  ,
            Amount  ,
            JSON.parse(localStorage.getItem("Ambassador User"))._id  )
            if(Rad.length === 0){
                swal("Enter The Type !"  )
                   return
                 }
              if(Mobile.length === 0){
                  setDis1(true)
                  swal("Enter The Mobile !"  )
                  return
                }
                
                if(Amount.length === 0){
                    setDis1(true)
                    swal("Enter The Amount !"  )
                    return
                }
                if(Earn >= Amount){
                   fetch("https://tapitdabit-backend.vercel.app/create-mobile-account",{
                    method: 'POST' , 
            headers :  {
                "Content-Type" : "application/json" , 
            } , 
            body : JSON.stringify({
                type : Rad  ,
              Mobile  ,
              Amount : parseInt(Amount) ,
              id :  JSON.parse(localStorage.getItem("Ambassador User"))._id ,
            })
          })
          .then((res)=>res.json())
          .then((res2)  =>{
              console.log(res2)
              if (res2){
                  swal("SucessFully Send Your Request"  )

                    setMobile("")
                    setAmount("")
                    // props.history.push("/login")          
                    setDis1(true)

                }
                else if (res2.Error){
                    swal(res2.Error )
                    setDis1(true)
                    
                }
              else{
                  swal("Plz Try Again !"  )
                  setDis1(true)
                  
                }
                // console.log(res2)
            })
            .catch((err)=>console.log(err))
            
        }
        else{
            swal("Amount is greater than Earning"  )
            setDis1(true)
            return

                }
               
    }
    const Submitdata2 = ()=>{
        // e.preventDefault()
        setDis11(false)
        if(BankName.length === 0){
            setDis1(true)
            swal("Enter The Bank Name !"  )
               return
             }
          if(AccountNumber.length === 0){
              setDis1(true)
            swal("Enter The Account Number !"  )
               return
             }
             
          if(AccountNumber.length === 0){
              setDis1(true)
            swal("Enter The Account Number !"  )
               return
             }
             
             if(Amount.length === 0){
                 setDis1(true)
                 swal("Enter The Amount !"  )
                 return
                }
                if(Earn >= Amount){

                fetch("https://tapitdabit-backend.vercel.app/create-bank-account",{
                    method: 'POST' , 
            headers :  {
                "Content-Type" : "application/json" , 
            } , 
            body : JSON.stringify({
                type : "Bank"  ,
                BankName  ,
                Title  ,
                AccountNumber  ,
                Amount : parseInt(Amount) ,
                id :  JSON.parse(localStorage.getItem("Ambassador User"))._id ,
            })
          })
          .then((res)=>res.json())
          .then((res2)  =>{
              console.log(res2)
              if (res2){
                swal("SucessFully Send Your Request"  )
                setBankName("")
                setTitle("")
                setAccountNumber("")
                setAmount("")
                setDis11(true)
                // props.history.push("/login")
            }
            else if (res2.Error){
                swal(res2.Error )
                setDis11(true)
            }
            else{
                swal("Plz Try Again !"  )
                setDis11(true)
              }
            // console.log(res2)
          })
          .catch((err)=>console.log(err))
        }
        else{
            swal("Amount is greater than Earning"  )
            setDis11(true)
            return

                }
    }

    const setProfile1 = ()=>{
        localStorage.removeItem("Ambassador User")
        window.location.href = "https://www.tapitdabit.com/ambassador-login"
    }
    const setDis81 = ()=>{
        setDis8(true)
        setDis9(false)
    }
    const setDis91 = ()=>{
        setDis9(true)
        setDis8(false)

    }


        return (
            <div>
                {/* <div className="bg-light py-3 theporper">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-0"><Link to="/ambassador-dashboard">Home</Link> <span className="mx-2 mb-0">/</span> <strong className="text-black">Contact</strong></div>
                        </div>
                    </div>
                </div> */}
                <div className="site-section">
                    <div className="container-fuild" style={{margin :  "auto" , width  : '90%'}}>
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="h3 mb-3 text-black">Withdrawl</h2>
                            </div>

                            <div className="col-md-12">

                                <div>

                                    <div className="border" style={{padding : "10px 10px"}}>
                            {/* <div style={{borderBottom :  "1px solid black"}} className="col-md-12 mb-2"><Link to="/ambassador-dashboard">Home</Link> <span className="mx-2 mb-0" style={{paddingLeft : "30px", paddingBottom : "10px"}}>|</span><Link to="/ambassador-dashboard-Analytics">Analytics</Link> <span className="mx-2 mb-0" style={{paddingLeft : "30px", paddingBottom : "10px"}}>|</span> <Link to="/ambassador-dashboard-Payments">Payments</Link> <span className="mx-2 mb-0" style={{paddingLeft : "30px", paddingBottom : "10px"}}>|</span>  <Link to="/ambassador-dashboard-Settings">Settings</Link><span className="mx-2 mb-0" style={{paddingLeft : "30px", paddingBottom : "10px"}}>|</span>  <Link onClick={()=>setProfile1()}>Logout</Link></div> */}

                                       
                            <div  style={{border :  "1px solid black"}} className="col-md-12 uiuiiuuhi">
                            {/* <div className="col-md-12 mb-0"> <span className="mx-2 mb-0">/</span> <strong className="text-black">Contact</strong></div> */}
                    <li onClick={()=> setDis12(! Dis12)}>Withdrawl  <span> <KeyboardArrowDownIcon style={{position : "absolute" , right : "10px"}} /></span> </li>
                    </div>
                     {Dis12 ?

                   <ul   style={{border :  "1px solid black", padding : "10px 0px 0px 0px"}}>
                    <li  style={{ padding : "5px 14px",borderBottom :"1px solid black",margin : "0px",color:  "black"}}><Link to="/ambassador-dashboard" style={{color : "black", fontWeight : "bold"}}>Home</Link></li>
                    <li  style={{ padding : "5px 14px",borderBottom :"1px solid black",margin : "0px"}}><Link to="/ambassador-dashboard-Analytics" style={{color : "black", fontWeight : "bold"}}>Analytics</Link></li>
                    <li  style={{ padding : "5px 14px",borderBottom :"1px solid black",margin : "0px"}}> <Link to="/ambassador-dashboard-Payments" style={{color : "black", fontWeight : "bold"}}>Payments</Link></li>
                    <li  style={{ padding : "5px 14px",borderBottom :"1px solid black",margin : "0px"}}> <Link to="/ambassador-dashboard-Settings" style={{color : "black", fontWeight : "bold"}}>Settings</Link></li>
                    <li  style={{ padding : "5px 14px",borderBottom :"1px solid black",margin : "0px"}}> <Link onClick={()=>setProfile1()} style={{color : "black", fontWeight : "bold"}}>Logout</Link></li>
                   </ul>
                     : ""
                     }  
                            <div style={{borderBottom :  "1px solid black"}} className="col-md-12 mb- theporper"><Link to="/ambassador-dashboard">Home</Link> <span className="mx-2 mb-0" style={{paddingLeft : "30px", paddingBottom : "10px"}}>|</span><Link to="/ambassador-dashboard-Analytics">Analytics</Link> <span className="mx-2 mb-0" style={{paddingLeft : "30px", paddingBottom : "10px"}}>|</span> <Link to="/ambassador-dashboard-Payments">Payments</Link> <span className="mx-2 mb-0" style={{paddingLeft : "30px", paddingBottom : "10px"}}>|</span>  <Link to="/ambassador-dashboard-Settings">Settings</Link><span className="mx-2 mb-0" style={{paddingLeft : "30px", paddingBottom : "10px"}}>|</span>  <Link onClick={()=>setProfile1()}>Logout</Link></div>
                         <br />


            <div style={{margin : "20px 0px"}}>
                    {/* <div style={{display : 'flex' , flexDirection  :"row"}}> */}
                    <div className="form-group row">
                        <div className="col-md-12">
                            <h3 style={{color : "black" , fontWeight : "300"}}>Select Payment Method</h3>

                        </div>
                    </div>
                    <div className="form-group row">
                        
                        <div class="col-xl-6 col-lg-6 col-md-4 col-sm-6 ikuiui" style={{background  : Dis8 && !Dis9 ? "black" : "rgb(218, 218, 218)",padding :  "20px 10px"}} onClick={()=>setDis81()}>
                   <center>
                    <span  style={{color  : Dis8 && !Dis9 ? "white" : "black",}}>Mobile Money</span>
                    </center> 
                  </div>
                        <div class="col-xl-6 col-lg-6 col-md-4 col-sm-6 ikuiui" style={{background  : Dis9 && !Dis8 ? "black" : "rgb(218, 218, 218)",padding :  "20px 10px"}}  onClick={()=>setDis91()}>
                            <center>

                    <span  style={{color  : !Dis8 && Dis9 ? "white" : "black",}}>Bank</span>
                            </center>
                  </div>
                     
                      


                    </div>
            </div>




                        {Dis8 ?
            <div style={{margin : "20px 0px"}}>
                    {/* <div style={{display : 'flex' , flexDirection  :"row"}}> */}
                   
                    <div className="form-group row">
                            <div class="col-xl-6 col-lg-6 col-md-4 col-sm-6 ikuiui">
                                <center>
                                        <input type="radio" name="Sele" checked value="JazzCash" onChange={(e)=>setRad("JazzCash")} id="" /> <span style={{fontWeight : "bold" }}>JazzCash</span> 
                                </center> 
                            </div>
                               {/* <center>
                                        <input type="radio" name="Sele" value="JazzCash" onChange={(e)=>setRad(e.target.value)} id="" /> <span style={{fontWeight : "bold" }}>JazzCash</span> 
                                </center>  */}
                            
                            <div class="col-xl-6 col-lg-6 col-md-4 col-sm-6 ikuiui">
                                <center>
                                    <input type="radio" name="Sele" id=""  value="Easy Paisa" onChange={(e)=>setRad("Easy Paisa")} /> <span style={{fontWeight : "bold" }}>Easy Paisa</span> 
                                </center>
                            </div>



            </div>
            </div>
                        : ""
                                }

                        {Dis8 ?
            <div style={{margin : "20px 0px"}}>
                   <div className="form-group row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-6">
                            <label style={{fontWeight : "bold",color : "black",marginLeft : "30px"}} htmlFor="c_email" className="text-black">Mobile Number<span className="text-danger">*</span></label>
                            {/* <input type="file" required  onChange={(e)=>setPhoto(e.target.files[0])}  className="form-control" placeholder="" /> */}
                            <input style={{padding :  "5px 4px",margin : "0px 30px" , borderBottom:  "1px solid black"}} type="text" value={Mobile} onChange={(e)=>setMobile(e.target.value)} placeholder="Enter Mobile Number" /> 

                        </div>
                    </div>
                   <div className="form-group row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-6">
                            <label style={{fontWeight : "bold",color : "black",marginLeft : "30px"}} htmlFor="c_email" className="text-black">Amount<span className="text-danger">*</span></label>
                            {/* <input type="file" required  onChange={(e)=>setPhoto(e.target.files[0])}  className="form-control" placeholder="" /> */}
                            <input style={{padding :  "5px 4px",margin : "0px 30px" , borderBottom:  "1px solid black"}} type="text"   value={Amount} onChange={(e)=>setAmount(e.target.value)} placeholder="Enter Amount"/> 

                        </div>
                    </div>
                {/* <div className="form-group row"> */}
                        {/* <div class="col-xl-12 col-lg-12 col-md-12 col-sm-6">
                            <center>
                                    <span style={{fontWeight : "bold",color : "black",marginLeft : "-100px"}}></span>
                                    <input style={{padding :  "5px 4px",margin : "0px 20px" , borderBottom:  "1px solid black"}} type="text" value={Mobile} onChange={(e)=>setMobile(e.target.value)} placeholder="Enter Mobile Number" /> 
                            </center> 
                        </div> */}
                        {/* <br /> */}
                        {/* <div class="col-xl-12 col-lg-12 col-md-12 col-sm-6" style={{marginTop :  "20px"}}>
                            <center>
                            <span style={{fontWeight : "bold",color : "black",marginLeft : "-158px"}}>Amount</span>
                                <input style={{padding :  "5px 4px",margin : "0px 20px" , borderBottom:  "1px solid black"}} type="text"   value={Amount} onChange={(e)=>setAmount(e.target.value)} placeholder="Enter Amount"/> 
                            </center>
                        </div>
                </div> */}
            </div>
                        : ""
                                }
            {Dis8 ?
                    <>
                        <center>
                            <h5>Amount will be remitted to you after <b> 1-2 (working days)</b> of receiving your request</h5>
                            {/* <img src={fr} alt="" style={{width : "300px", height : "300px", marginTop : "10px"  ,objectFit :"cover"}}/> */}
                        </center>
                        <br />
                        <center>
                            {Dis1 ? 
                            <button className="button11" style={{background  :  "black" , fontWeight : "bold"  ,color  :"white" , fontSize : "20px" ,borderRadius :  "0px" , padding  :"13px 59px"}} onClick={()=>Submitdata1()} onDoubleClick={()=>setname("")}>
                            ✓
                            </button>
 : 
                            <button className="button11" style={{background  :  "black" , fontWeight : "bold"  ,color  :"white" , fontSize : "20px" ,borderRadius :  "0px" , padding  :"13px 59px"}}>
                            ✓
                            </button>
                            }
                        </center>
                    </>
                        : ""
                                }














                     
                  

                        {Dis9 ?
            <div style={{margin : "20px 0px"}}>
                   

                   <div className="form-group row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-6">
                            <label style={{fontWeight : "bold",color : "black",marginLeft : "20px"}} htmlFor="c_email" className="text-black">Bank Name<span className="text-danger">*</span></label>
                            {/* <input type="file" required  onChange={(e)=>setPhoto(e.target.files[0])}  className="form-control" placeholder="" /> */}
                            <input style={{padding :  "5px 4px",margin : "0px 20px" , borderBottom:  "1px solid black"}} type="text"   value={BankName} onChange={(e)=>setBankName(e.target.value)} placeholder="Bank Name"/> 

                        </div>
                    </div>
                   <div className="form-group row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-6">
                            <label style={{fontWeight : "bold",color : "black",marginLeft : "20px"}} htmlFor="c_email" className="text-black">Title<span className="text-danger">*</span></label>
                            {/* <input type="file" required  onChange={(e)=>setPhoto(e.target.files[0])}  className="form-control" placeholder="" /> */}
                            <input style={{padding :  "5px 4px",margin : "0px 20px" , borderBottom:  "1px solid black"}} type="text"   value={Title} onChange={(e)=>setTitle(e.target.value)} placeholder="Enter Title"/> 

                        </div>
                    </div>
                   <div className="form-group row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-6">
                            <label style={{fontWeight : "bold",color : "black",marginLeft : "20px"}} htmlFor="c_email" className="text-black">Account Number<span className="text-danger">*</span></label>
                            {/* <input type="file" required  onChange={(e)=>setPhoto(e.target.files[0])}  className="form-control" placeholder="" /> */}
                            <input style={{padding :  "5px 4px",margin : "0px 20px" , borderBottom:  "1px solid black"}} type="text" value={AccountNumber} onChange={(e)=>setAccountNumber(e.target.value)} placeholder="Enter Account Number" /> 

                        </div>
                    </div>
                   <div className="form-group row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-6">
                            <label style={{fontWeight : "bold",color : "black",marginLeft : "20px"}} htmlFor="c_email" className="text-black">Amount<span className="text-danger">*</span></label>
                            {/* <input type="file" required  onChange={(e)=>setPhoto(e.target.files[0])}  className="form-control" placeholder="" /> */}
                            <input style={{padding :  "5px 4px",margin : "0px 20px" , borderBottom:  "1px solid black"}} type="text"   value={Amount} onChange={(e)=>setAmount(e.target.value)} placeholder="Enter Amount"/> 

                        </div>
                    </div>






                {/* <div className="form-group row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-6">
                            <center>
                            <span style={{fontWeight : "bold",color : "black",marginLeft : "-154px"}}>Bank Name</span>
                                <input style={{padding :  "5px 4px",margin : "0px 20px" , borderBottom:  "1px solid black"}} type="text"   value={BankName} onChange={(e)=>setBankName(e.target.value)} placeholder="Bank Name"/> 
                            </center>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-6" style={{marginTop :  "20px"}}>
                            <center>
                            <span style={{fontWeight : "bold",color : "black",marginLeft : "-197px"}}>Title</span>
                                <input style={{padding :  "5px 4px",margin : "0px 20px" , borderBottom:  "1px solid black"}} type="text"   value={Title} onChange={(e)=>setTitle(e.target.value)} placeholder="Enter Title"/> 
                            </center>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-6" style={{marginTop :  "20px"}}>
                            <center>
                            <span style={{fontWeight : "bold",color : "black",marginLeft : "-110px"}}>Account Number </span>
                                    <input style={{padding :  "5px 4px",margin : "0px 20px" , borderBottom:  "1px solid black"}} type="text" value={AccountNumber} onChange={(e)=>setAccountNumber(e.target.value)} placeholder="Enter Account Number" /> 
                            </center> 
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-6" style={{marginTop :  "20px"}}>
                            <center>
                            <span style={{fontWeight : "bold",color : "black",marginLeft : "-170px"}}>Amount</span>
                                <input style={{padding :  "5px 4px",margin : "0px 20px" , borderBottom:  "1px solid black"}} type="text"   value={Amount} onChange={(e)=>setAmount(e.target.value)} placeholder="Enter Amount"/> 
                            </center>
                        </div>
                </div> */}
            </div>
                        : ""
                                }
            {Dis9 ?
                    <>
                        <center>
                        <h5>Amount will be remitted to you after <b> 1-2 (working days)</b> of receiving your request</h5>

                            {/* <img src={fr} alt="" style={{width : "300px", height : "300px", marginTop : "10px"  ,objectFit :"cover"}}/> */}
                        </center>
                        <br />
                        <center>
                            {/* <button className="button11" style={{background  :  "black" , fontWeight : "bold"  ,color  :"white" , fontSize : "20px"  ,borderRadius :  "0px" , padding  :"13px 59px"}} onClick={()=>Submitdata2()}>
                            ✓
                            </button> */}
                             {Dis11 ? 
                            <button className="button11" style={{background  :  "black" , fontWeight : "bold"  ,color  :"white" , fontSize : "20px" ,borderRadius :  "0px" , padding  :"13px 59px"}} onClick={()=>Submitdata2()} onDoubleClick={()=>setname("")}>
                            ✓
                            </button>
 : 
                            <button className="button11" style={{background  :  "black" , fontWeight : "bold"  ,color  :"white" , fontSize : "20px" ,borderRadius :  "0px" , padding  :"13px 59px"}}>
                            ✓
                            </button>
                            }
                        </center>
                    </>
                        : ""
                                }












                                    </div>
                                </div>
                            </div>
                          



                        </div>
                    </div>
                </div>
            </div>
        )
    
}


export default Contact;