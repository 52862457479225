import React, { useState , useEffect } from 'react';
// import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import whatapps from '../assets/images/what.png';

const Contact = (props)=>  {
    const [name, setname] = useState("") 
    const [name1, setname1] = useState("") 
    const [name2, setname2] = useState("") 
    const [name3, setname3] = useState("") 
    const [name4, setname4] = useState("") 
    const [name5, setname5] = useState("") 
    const [lname, setlname] = useState("") 
    const [email, setemail] = useState("") 
    const [subject, setsubject] = useState("") 
    const [Message, setMessage] = useState("") 
    const [CategoriesName , setCategoriesname] = useState({})
    const [Arr, setArr] = useState(0) 
    
    useEffect(() =>{

        window.scrollTo(0, 0)
        console.log( props.match.params);
        let Categoriesname = props.match.params ;
        setCategoriesname(Categoriesname)
        var data =  JSON.parse(localStorage.getItem("Cart")) 
        if(data){
let a = []
        data.map((res4,y)=>{
            console.log(res4.Product_Catagories ,"Custom Products")
            if(res4.Product_Catagories === "Custom Products"){
                setArr(Arr + res4.Pieces)
                a.push(res4.Pieces)
                // console.log(Arr , res4.Pieces )
            }
            a.push(res4) 
        })
        
        // console.log(Arr , res4.Pieces )
        // setArr(a.length)
            console.log(a.length,data)
        }
        else{
            props.history.push("/")
        }
        
        },[])

    const Submitdata1 = (e)=>{
        e.preventDefault()
    if(Arr  === 1){
        if(name === ""){
            swal("Plz Upload the Photo")
             return
            }
    }
    if(Arr  === 2){
        if(name1 === "") {
            swal("Plz Upload the Photo")
             return
            }
    }
    if(Arr  === 3){
        if(name2 === "")  {
            swal("Plz Upload the Photo")
             return
            }

    }
    if(Arr  === 4){
        if(name3 === "")  {
            swal("Plz Upload the Photo")
             return
            }

    }
    if(Arr  === 5){
        if(name4 === "")  {
            swal("Plz Upload the Photo")
             return
            }

    }
    if(Arr  === 6){
        if(name5 === "")  {
            swal("Plz Upload the Photo")
             return
            }

    }


        const data = new FormData()
        data.append("file", name)
        data.append("upload_preset", "commerce")
        data.append("cloud_name", "freedeveloperss123")
        fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
            method : "POST",
            body : data , 
        })
        .then(res1=>res1.json())
        .then((res1)=>{
        
          
          const data1 = new FormData()
          data1.append("file", name1)
          data1.append("upload_preset", "commerce")
          data1.append("cloud_name", "freedeveloperss123")
          fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
              method : "POST",
              body : data1 , 
          })
          .then(res11=>res11.json())
          .then((res2)=>{

        const data2 = new FormData()
        data2.append("file", name2)
        data2.append("upload_preset", "commerce")
        data2.append("cloud_name", "freedeveloperss123")
        fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
            method : "POST",
            body : data2 , 
        })
        .then(res1=>res1.json())
        .then((res3)=>{
          
          
          const data3 = new FormData()
          data3.append("file", name3)
          data3.append("upload_preset", "commerce")
          data3.append("cloud_name", "freedeveloperss123")
          fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
              method : "POST",
              body : data3 , 
          })
          .then(res11=>res11.json())
          .then((res4)=>{

        const data4 = new FormData()
        data4.append("file", name4)
        data4.append("upload_preset", "commerce")
        data4.append("cloud_name", "freedeveloperss123")
        fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
            method : "POST",
            body : data4 , 
        })
        .then(res1=>res1.json())
        .then((res5)=>{
          
          
          const data5 = new FormData()
          data5.append("file", name5)
          data5.append("upload_preset", "commerce")
          data5.append("cloud_name", "freedeveloperss123")
          fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
              method : "POST",
              body : data5 , 
          })
          .then(res11=>res11.json())
          .then((res6)=>{
            //   let da
            
              if(Arr === 1){
                   var da = {
                      photo : res1.url,

                  }

              }
              if(Arr === 2){
                   var da = {
                      photo : res1.url,
                      photo : res2.url,

                  }

              }
              if(Arr === 3){
                   var da = {
                    photo : res1.url,
                    photo : res2.url,
                    photo : res3.url,


                  }

              }
              if(Arr === 4){
                   var da = {
                    photo : res1.url,
                    photo : res2.url,
                    photo : res3.url,
                    photo : res4.url,


                  }

              }
              if(Arr === 5){
                   var da = {
                    photo : res1.url,
                    photo : res2.url,
                    photo : res3.url,
                    photo : res4.url,
                    photo : res5.url,

                  }

              }
              if(Arr === 6){
                   var da = {
                    photo : res1.url,
                    photo : res2.url,
                    photo : res3.url,
                    photo : res4.url,
                    photo : res5.url,
                    photo : res6.url,

                  }

              }
              console.log(da)
            // const da = {
            //     photo1 : res1.url,
            //     photo2 : res2.url,
            //     photo3 : res3.url,
            //     photo4 : res4.url,
            //     photo5 : res5.url,
            //     photo6 : res6.url,
            // }
            localStorage.setItem("Custom Img",JSON.stringify(da))
            swal("Update!", "Your Item Add in Cart!", "success");
            props.history.push("/cart")
        })
          })
          })
          })
          })
          })





        // fetch("https://tapitdabit-backend.vercel.app/toenterprise1uchIn",{
        //     method: 'POST' , 
        //     headers :  {
        //       "Content-Type" : "application/json" , 
        //     } , 
        //     body : JSON.stringify({
        //       name  ,
        //       lname  ,
        //       email  ,
        //       subject  ,
        //       Message  ,
        //     })
        //   })
        //   .then((res)=>res.json())
        //   .then((res2)  =>{
        //       console.log(res2)
        //       if (res2){
        //         swal("SucessFully Send "  )
        //         setname ("") 
        //         setlname("") 
        //             setemail("") 
        //             setsubject("") 
        //             setMessage("") 
        //         // localStorage.setItem("User" , JSON.stringify("Yes") )
        //             // props.history.push("/login")
        //       }
        //       else if (res2.Error){
        //         swal(res2.Error )
        //       }
        //       else{
        //         swal("Plz Try Again !"  )
        //       }
        //     // console.log(res2)
        //   })
        //   .catch((err)=>console.log(err))
        
    }



        return (
            <div>
                {/* <div className="bg-light py-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-0"><Link to="/">Home</Link> <span className="mx-2 mb-0">/</span> <strong className="text-black">Contact</strong></div>
                        </div>
                    </div>
                </div> */}
                              <a href="https://wa.me/923302757197" target="_blank"><img src={whatapps} alt="" style={{position : "fixed" ,zIndex : "999999", top : "80%" , right : "20px",width:"70px",height : "70px"}}/></a>

                <div className="site-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="h3 mb-3 text-black">Custom Photo Upload</h2>
                            </div>
                            <div className="col-md-12">

                                <form onSubmit={(e)=>Submitdata1(e)}>

                                    <div className="p-3 p-lg-5 border">
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label htmlFor="c_fname" className="text-black">Add Logo<span className="text-danger">*</span></label>
                                                <input type="file" onChange={(e)=>setname(e.target.files[0])}   className="form-control" />
                                            </div>
                                            {Arr > 1 ? 
                                            <div className="col-md-6">
                                                <label htmlFor="c_fname" className="text-black">Add Logo<span className="text-danger">*</span></label>
                                                <input type="file"  onChange={(e)=>setname1(e.target.files[0])}   className="form-control" />
                                            </div>

                                            : ""

                                            }
                                        </div>
                                        <div className="form-group row">
                                        {Arr > 2 ? 
                                            <div className="col-md-6">
                                                <label htmlFor="c_fname" className="text-black">Add Logo<span className="text-danger">*</span></label>
                                                <input type="file"  onChange={(e)=>setname2(e.target.files[0])}   className="form-control" />
                                            </div>
                                                :""
                                            }
                                            {Arr > 3 ? 
                                            <div className="col-md-6">
                                                <label htmlFor="c_fname" className="text-black">Add Logo<span className="text-danger">*</span></label>
                                                <input type="file"  onChange={(e)=>setname3(e.target.files[0])}   className="form-control" />
                                            </div>
                                              :""
                                            }
                                        </div>
                                        <div className="form-group row">
                                            {Arr > 4 ? 

                                            <div className="col-md-6">
                                                <label htmlFor="c_fname" className="text-black">Add Logo<span className="text-danger">*</span></label>
                                                <input type="file"  onChange={(e)=>setname4(e.target.files[0])}   className="form-control" />
                                            </div>
                                                :""
                                            }
                                            {Arr > 5 ? 

                                            <div className="col-md-6">
                                                <label htmlFor="c_fname" className="text-black">Add Logo<span className="text-danger">*</span></label>
                                                <input type="file"  onChange={(e)=>setname5(e.target.files[0])}   className="form-control" />
                                            </div>
                                            :""
                                                                                    }             
                                                                                    
                                            </div>
                                        <div className="form-group row">
                                            {Arr > 6 ? 

                                            <div className="col-md-6">
                                                <label htmlFor="c_fname" className="text-black">Add Logo<span className="text-danger">*</span></label>
                                                <input type="file"  onChange={(e)=>setname4(e.target.files[0])}   className="form-control" />
                                            </div>
                                                :""
                                            }
                                              
                                                                                    
                                            </div>


                                        <div className="form-group row">
                                            <div className="col-lg-4">
                                                <input type="submit" className="btn btn-darken" value="Upload" style={{background : "rgb(255, 122, 89)"}}/>
                                            </div>
                                        </div>
                                
                                    </div>
                                </form>
                            </div>
                            {/* <div className="col-md-5 ml-auto">
                                <div className="p-4 border mb-3">
                                    <span className="d-block text-primary h6 text-uppercase">New York</span>
                                    <p className="mb-0">203 Fake St. Mountain View, San Francisco, California, USA</p>
                                </div>
                                <div className="p-4 border mb-3">
                                    <span className="d-block text-primary h6 text-uppercase">London</span>
                                    <p className="mb-0">203 Fake St. Mountain View, San Francisco, California, USA</p>
                                </div>
                                <div className="p-4 border mb-3">
                                    <span className="d-block text-primary h6 text-uppercase">Canada</span>
                                    <p className="mb-0">203 Fake St. Mountain View, San Francisco, California, USA</p>
                                </div>

                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    
}


export default Contact;