import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"
import swal from 'sweetalert';
import $ from 'jquery';
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ReactCrop from 'react-image-crop';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import x1 from "./../assets/images/x1.jpg"
import ClearIcon from '@material-ui/icons/Clear';
import 'react-image-crop/dist/ReactCrop.css';

import "react-multi-carousel/lib/styles.css";
import "react-multi-carousel/lib/styles.css";
import "./../assets/css/main.css"
import "./../assets/css/util.css"
import "./../assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css"
import Loader from "react-loader-spinner";


import as1 from "./../assets/images/as1.jpg"
// import as2 from "./../assets/images/as2.jpg"

// import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"

import "./../assets/vendor/css-hamburgers/hamburgers.min.css"

import "./../assets/fonts/iconic/css/material-design-iconic-font.min.css"
import "./../assets/vendor/bootstrap/css/bootstrap.min.css"
import "./../assets/vendor/select2/select2.min.css"
import "./../assets/vendor/slick/slick.css"
import "./../assets/vendor/perfect-scrollbar/perfect-scrollbar.css"



const Home = ( props ) => {
    const [myRef,setRef] = useState( React.createRef())
    const [scrollTop1,setscrollTop] = useState(0)
    const [email , setemail] = useState("")
    const [pass , setpass] = useState("")
    const [dispaly ,  setdispaly ] = useState(true)
    const [User1 ,  setUser1 ] = useState({})
    const [Name ,  setName ] = useState("")
    const [gender ,  setgender ] = useState("")
    const [date ,  setdate ] = useState("")
    const [area ,  setarea ] = useState("")
    // const [Image ,  setImage ] = useState("")
    // const [Image1 ,  setImage1 ] = useState("")
    const [dispaly1 ,  setdispaly1 ] = useState(true)
    const [dispaly2 ,  setdispaly2 ] = useState(true)

    const [Image1235 ,  setImage ] = useState("")
    const [image ,  setImage1 ] = useState("")
    const [Result ,  setResult ] = useState(null)
    const [crop, setCrop] = useState({ aspect: 0 ,
      height: 90 ,
      unit: "px",
      width: 90,
      x: 54.015625,
      y: 28.34375});

    useEffect(() =>{
      if(! JSON.parse(localStorage.getItem("User"))){
        props.match.history.push("/login")
      }else{
        localStorage.setItem("VenderWorkingThose" ,JSON.stringify(123)) 
        setUser1(JSON.parse(localStorage.getItem("User")))
        setName(JSON.parse(localStorage.getItem("User")).NameBuss)
        setgender(JSON.parse(localStorage.getItem("User")).bioBuss)
        setImage(JSON.parse(localStorage.getItem("User")).ProfilePhotoBuss)
        setImage1(JSON.parse(localStorage.getItem("User")).ProfilePhotoBuss)
        setResult(JSON.parse(localStorage.getItem("User")).ProfilePhotoBuss)

      }
  // localStorage.setItem("User" , JSON.stringify(res2) )
  return () => {
    document.getElementById("get1").classList.remove("is-active");
    document.getElementById("get2").style.display = "none";
  }
    },[])

    useEffect( () => () => localStorage.removeItem("VenderWorkingThose"), [] );

    const setChange = () =>{
        if(document.getElementById("get2").style.display === "none"){
            document.getElementById("get1").classList.add("is-active");
            document.getElementById("get2").style.display = "block";
        }
        else{
            document.getElementById("get1").classList.remove("is-active");
            document.getElementById("get2").style.display = "none";
        }
    }

    const setChange1 = (e) =>{
      if(e === 1 ){
        // document.getElementById("signUp").classList.add("right-panel-active");
        // document.getElementById("signIn").classList.remove("right-panel-active");
        document.getElementById("container1").classList.add("right-panel-active")
      }
      else{
        document.getElementById("container1").classList.remove("right-panel-active")
      }
    }



    const onImageLoaded = image => {
      this.setState({ crop: { width: image.width, height: image.height } });
      return false; // Return false when setting crop state in here.
    }
    
    
    
const setChangeData = () =>{
  

  console.log(Name,gender,area,date)
  if(Name.length > 20){
    swal("Name Lenght Not Greater then 20");
    return
  }
  if(gender.length > 60){
    swal("Bio Lenght Not Greater then 74");
    return
  }
  // const data = new FormData()
  //                             data.append("file", Image)
  //                             data.append("upload_preset", "commerce")
  //                             data.append("cloud_name", "freedeveloperss123")
  //                             fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
  //                                 method : "POST",
  //                                 body : data , 
  //                             })
  //                             .then(res=>res.json())
  //                             .then((res2)=>{
    fetch("https://tapitdabit-backend.vercel.app/ProfleChangeBussiness",{
      method: 'POST' , 
      headers :  {
        "Content-Type" : "application/json" , 
      } , 
      body : JSON.stringify({
        id : User1._id ,
        Name  ,
        image : Result ,
        area  :gender ,
        // area  ,
      })
    })
    .then((res)=>res.json())
    .then((res2)  =>{
        console.log(res2)
        // if (res2 !== null && !res2.Error  ){
        //   if(!res2.isSettings){
          swal("Successfully Save your Profile!");
          setTimeout(()=>{
    
            props.history.push("/user/bussiness/home1/"+User1._id)
          },1500)
          // localStorage.setItem("User" , JSON.stringify(res2) )
          //     props.history.push("/settings-profile-vender")
          
        
      // console.log(res2)
    })
    .catch((err)=>console.log(err))
                              // })

}



const setImage1234 = (e)=>{
  setdispaly2(false)
  console.log(e)
  const data = new FormData()
  data.append("file", e)
  data.append("upload_preset", "commerce")
  data.append("cloud_name", "freedeveloperss123")
  fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
      method : "POST",
      body : data , 
  })
  .then(res=>res.json())
  .then((res2)=>{
    setImage1(res2.url)
    document.getElementById("photoImage2").style.background =`url(${res2.url})`
    document.getElementById("photoImage2").style.backgroundRepeat ="noRepeat"
    document.getElementById("photoImage2").style.backgroundSize ="cover"
    // backgroundRepeat:"no-repeat",backgroundSize : "cover" ,
    setdispaly2(true)
    setChanger3()
  })
}




const setRouting1 = (e)=>{
  if(e === 1){
    if(JSON.parse(localStorage.getItem("User")).DisPersonal)
    props.history.push("/user/home1/"+User1._id)
    else 
    props.history.push("/user/bussiness/home1/"+User1._id)
  }
  else if(e === 4){
    if(JSON.parse(localStorage.getItem("User")).DisPersonal)
    props.history.push("/user/editProfile/sort-delete/"+User1.uid)
    else
    props.history.push("/user/bussiness/editProfile/sort-delete/"+User1.uid)
  }
  else if(e === 3){
    // props.history.push("/user/analytics/"+User1._id)   

    const d =  JSON.parse(localStorage.getItem("User")).ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
    console.log(d)
    
      
    if (d.length > 0){
      let a = new Date()
      let f= a.getDate() +" "+(a.getUTCMonth()+1)+ " "+a.getUTCFullYear()
      console.log(d[0].End[0]+""+d[0].End[1] >= a.getDate(), d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ,d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear())
      if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] > a.getUTCFullYear()     ){
      // if(d[0].End && d[0].End >= f){
    props.history.push("/user/analytics/"+User1._id)   

      }
      else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear()){
        if( d[0].End[3]+""+d[0].End[4] >a.getUTCMonth()+1 ){
      props.history.push("/user/analytics/"+User1._id)   

        }
        else{
          if(d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1){
            if( d[0].End[0]+""+d[0].End[1] >=a.getDate()){
          props.history.push("/user/analytics/"+User1._id)   

            }
            else{
              document.getElementById("myDivUmer12").style.display= "block"

              // localStorage.setItem("request123",JSON.stringify(1) )
              // props.history.push("/user/request-for-pro-item")
              // swal("Sorry First You Buy")
  
            }
          }
          else{
            document.getElementById("myDivUmer12").style.display= "block"

            // localStorage.setItem("request123",JSON.stringify(1) )
            //   props.history.push("/user/request-for-pro-item")
            //   swal("Sorry First You Buy")
  
            }
          }
        }
      else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear() && d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ){
          if( d[0].End[0]+""+d[0].End[1] >=a.getDate()){
        props.history.push("/user/analytics/"+User1._id)   

        }
        else{
          document.getElementById("myDivUmer12").style.display= "block"

          // localStorage.setItem("request123",JSON.stringify(1) )
          // props.history.push("/user/request-for-pro-item")
          // swal("Sorry First You Buy")
  
        }
      }
      else{
        document.getElementById("myDivUmer12").style.display= "block"

        // localStorage.setItem("request123",JSON.stringify(1) )
        //   props.history.push("/user/request-for-pro-item")
        //   swal("Sorry First You Buy")
  
      }
    }
    else{
      document.getElementById("myDivUmer12").style.display= "block"

      // localStorage.setItem("request123",JSON.stringify(1) )
      //   props.history.push("/user/request-for-pro-item")
      //   swal("Sorry First You Buy")
  }
  }
  else if(e === 2){


    const d =  JSON.parse(localStorage.getItem("User")).ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
    console.log(d)
    
      
    if (d.length > 0){
      let a = new Date()
      let f= a.getDate() +" "+(a.getUTCMonth()+1)+ " "+a.getUTCFullYear()
      console.log(d[0].End[0]+""+d[0].End[1] >= a.getDate(), d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ,d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear())
      if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] > a.getUTCFullYear()     ){
      // if(d[0].End && d[0].End >= f){
        props.history.push("/user/connect/myprofile/"+User1.uid)

      }
      else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear()){
        if( d[0].End[3]+""+d[0].End[4] >a.getUTCMonth()+1 ){
          props.history.push("/user/connect/myprofile/"+User1.uid)

        }
        else{
          if(d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1){
            if( d[0].End[0]+""+d[0].End[1]>=a.getDate()){
              props.history.push("/user/connect/myprofile/"+User1.uid)

            }
            else{
              document.getElementById("myDivUmer").style.display= "block"

              // localStorage.setItem("request123",JSON.stringify(1) )
              // props.history.push("/user/request-for-pro-item")
              // swal("Sorry First You Buy")
  
            }
          }
          else{
            document.getElementById("myDivUmer").style.display= "block"

            // localStorage.setItem("request123",JSON.stringify(1) )
            //   props.history.push("/user/request-for-pro-item")
            //   swal("Sorry First You Buy")
  
            }
          }
        }
      else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear() && d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ){
          if( d[0].End[0]+""+d[0].End[1]>=a.getDate()){
            props.history.push("/user/connect/myprofile/"+User1.uid)

        }
        else{
          document.getElementById("myDivUmer").style.display= "block"

          // localStorage.setItem("request123",JSON.stringify(1) )
          // props.history.push("/user/request-for-pro-item")
          // swal("Sorry First You Buy")
  
        }
      }
      else{
        document.getElementById("myDivUmer").style.display= "block"

        // localStorage.setItem("request123",JSON.stringify(1) )
        //   props.history.push("/user/request-for-pro-item")
        //   swal("Sorry First You Buy")
  
      }
    }
    else{
      document.getElementById("myDivUmer").style.display= "block"

      // localStorage.setItem("request123",JSON.stringify(1) )
      //   props.history.push("/user/request-for-pro-item")
      //   swal("Sorry First You Buy")
  }
  }
  else{

  }
}
const setChanger8 = (e)=>{
  document.getElementById("myDivUmer12").style.display= "block"
}
const setImage123 = (e)=>{
  console.log(URL.createObjectURL(e))
  setImage(URL.createObjectURL(e))
    setChangeImager1(URL.createObjectURL(e))
    setChanger3()
}
// const setImage123 = (e)=>{
//   console.log(e)
//   const data = new FormData()
//   data.append("file", e)
//   data.append("upload_preset", "commerce")
//   data.append("cloud_name", "freedeveloperss123")
//   fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
//       method : "POST",
//       body : data , 
//   })
//   .then(res=>res.json())
//   .then((res2)=>{
//     setImage(res2.url)
//     document.getElementById("photoImage2").src = res2.url
//   })
// }
const setRoter123 = ()=>{
  localStorage.setItem("request123",JSON.stringify(1) )
      props.history.push("/user/request-for-pro-item")

}

const setChanger1 = (e)=>{
  document.getElementById("myDiv1234").style.display= "none"
}
const setChanger2 = (e)=>{
  document.getElementById("myDivUmer").style.display= "none"
}
const setChanger3 = (e)=>{
  document.getElementById("myDivUmer5").style.display= "none"
}
const setChangeImager = ()=>{
  setTimeout(()=>{
    document.getElementById("myDivUmer10").style.display= "block"
  },1500)
}
const setChangeImager55 = ()=>{
  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext("2d");

  // // New lines to be added
  // const pixelRatio = window.devicePixelRatio;
  // canvas.width = crop.width * pixelRatio;
  // canvas.height = crop.height * pixelRatio;
  // ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  // ctx.ImageSmoothingQu ality = "high";
  
  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
    )
    const base = canvas.toDataURL("image/jpeg")
    console.log(base)
    setResult(base)
    document.getElementById("photoImage1").src = base
    document.getElementById("myDivUmer10").style.display= "none"
}
const setChangeImager189898989 = (e)=>{
  document.getElementById("myDivUmer10").style.display= "none"
}
  const setChangeImager1 = (e)=>{
  // document.getElementById("myDivUmer10").style.display= "none"
  //   const data = new FormData()
//   data.append("file", e)
//   data.append("upload_preset", "commerce")
//   data.append("cloud_name", "freedeveloperss123")
//   fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
//       method : "POST",
//       body : data , 
//   })
//   .then(res=>res.json())
//   .then((res2)=>{
//     setImage(res2.url)
//     document.getElementById("photoImage1").src = res2.url
//     setdispaly1(true)
//     setChanger3()
//     setChangeImager1(res2.url)
//   })
  // document.getElementById("photoImage10").src = e

}

const  getCroppedImg = ()=> {
  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext("2d");

  // // New lines to be added
  // const pixelRatio = window.devicePixelRatio;
  // canvas.width = crop.width * pixelRatio;
  // canvas.height = crop.height * pixelRatio;
  // ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  // ctx.ImageSmoothingQu ality = "high";

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  )
const base = canvas.toDataURL("image/jpeg")
console.log(base)
setResult(base)
document.getElementById("photoImage1").src = base
  // const data = new FormData()
  // data.append("file", base)
  // data.append("upload_preset", "commerce")
  // data.append("cloud_name", "freedeveloperss123")
  // fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
  //     method : "POST",
  //     body : base , 
  // })
  // .then(res=>res.json())
  // .then((res2)=>{
  //   setImage(res2.url)
  //   setdispaly1(true)
  //   setChangeImager55()
  //   // setChangeImager1(res2.url)
  // })



//   canvas.toBlob(blob => {
//       console.log(blob)
//     // setResult(blob)
// });
// return new Promise((resolve, reject) => {
//   canvas.toBlob(
//     (blob) => {
//       blob.name = fileName;
//       resolve(blob);
//     },
//     "image/jpeg",
//     1
//   );
// });


}
const setCrop1 = (e) =>{
  console.log(e)
  setCrop({
    ...e 
    // , width : 260 , height : 260
  })
}
  return (
            






      <div 
        // onScroll={onScrolll()}
        >
     
        {/* Slider */}

        <div id="myDivUmer12">
                {/* <img src={guides} alt=""  id="myDiv23456"/> */}
                <center>
                  <h1>
                    Analytics
                </h1>
                  </center>
                <center><p>
                Unlock all links with Pro to see insights of your account. <br />
Tap Buy now  to upgrade.
          </p></center>
                  <center>
                  <button class="btn btn-dark F23 f45" onClick={()=>setRoter123()}>Buy Now </button>
                  </center>
                <ClearIcon id="myDiv3456767"  style={{color : "black",fontSize : "30px",position : "absolute" , right: "10%",top : "10%" , zIndex: "2"}} onClick={()=>setChanger8()}/>
            </div> 
        <div id="myDivUmer5">
                <center>
                <center>
   <div class="image-upload"style={{background :  "rgb(244, 244, 244)",borderRadius : "14px",width :  "239px" , marginBottom : "20px" , padding :  "40px 0px"}}>
   <img src={as1}  width="50px" height="40px" alt=""  style={{position  :"relative"}}/>

      <label for="file-input1"   style={{padding  :  "20px 0px 0px 0px",width : "190px" ,  color  : "black"  , fontSize : "16px" , position : "absolute" , top : "29%"}}>
          {/* <img src={r4} style={{ width:"35px", fontSize : "30px",padding  :  "20px 0px",borderRadius : "15px",height : "27px",marginTop : "-6px"}}/><span style={{fontWeight : "bold" , fontSize : "22px" }}> Profile Image</span> */}
                Edit Profile Picture
      </label>

      <input id="file-input1" type="file" style={{width :  "0px"}}  onChange={(e)=>setImage123(e.target.files[0])}  accept="Image/*" />
    </div>        
</center>
                  {/* <center>
                    <h4>
                   or
                    </h4>
                  </center> */}
                  {/* <div class="image-upload" style={{background :  "rgb(244, 244, 244)",borderRadius : "14px",width :  "239px" , marginBottom : "20px"}}>
                  <img src={as2} for="file-input2" width="50px" height="40px" alt="" /> */}

      {/* <label for="file-input2" style={{padding  :  "5px 0px",width : "190px" , background : "rgb(244, 244, 244)" , color  : "black"  , fontSize : "16px"}}>
                 Edit Cover Picture
      </label>

      <input id="file-input2" type="file" style={{width :  "0px"}}  onChange={(e)=>setImage1234(e.target.files[0])}/> */}
    {/* </div>         */}
</center>
                  {/* <button class="btn btn-dark F23 f45" onClick={()=>setRoter8()}>Edit Cover Photo</button>
                  </center> */}
                <ClearIcon id="myDiv34567"  style={{color : "black",fontSize : "30px",position : "absolute" , right: "10%",top : "10%" , zIndex: "2"}} onClick={()=>setChanger3()}/>
            </div> 
            <div id="myDivUmer10">  
                {/* <img src={guides} alt=""  id="myDiv23456"/> */}
                <center >
                  <div className="tyug1" >
                  <center style={{display : "flex" , justifyContent : "space-between"}}>
                      {/* <button class="btn btn-dark F23 f45"  onClick={()=>setChangeImager55()}>Set Profile Image</button> */}
                      <ClearIcon id="myDiv34567"  style={{fontWeight : "bold",color : "black",fontSize : "30px"}} onClick={()=>setChangeImager189898989()}/>
                      <span style={{fontWeight : "bold",color : "black",fontSize : "30px", marginTop : "-10px"}}  onClick={()=>setChangeImager55()}>✓</span>
                    </center>
                <ReactCrop src={Image1235} onImageLoaded={setImage1}  circularCrop={true} className="rtoidf" alt="" width="300px" height="300px" id="photoImage10" crop={crop} onChange={newCrop => setCrop1(newCrop)}  />
                {/* <ReactCrop src={Image1235} onImageLoaded={setImage1} className="rtoidf" alt="" width="300px" height="300px" id="photoImage10" crop={crop} onChange={newCrop => setCrop(newCrop)} /> */}
                  </div>
               
                  {/* <button class="btn btn-dark F23 f45" onClick={()=>getCroppedImg()}>Crop </button> */}
                  </center>
                <center>
                {/* <div className="tyug" >

                  {Result ? 
                  <img src={Result} alt=""   style={{marginTop  : "40px" ,height: "120px",width: "120px" , border  : "2px solid black" , borderRadius : "50%"}}/>
                    : ""
                  }
                  </div> */}
                  {/* // <img src={Result} alt=""  style={{marginTop  : "40px" , borderRadius :  "50%",height: "120px",width: "120px" , border  : "2px solid black"}}/>

                  // <img src={Result} alt=""  style={{marginTop  : "40px" , borderRadius :  "50%",height: "120px",width: "120px" , border  : "2px solid black"}}/> */}
                {/* <ReactCrop src={Image} onImageLoaded={setImage1} alt="" width="100%" height="100%" id="photoImage10" crop={crop} onChange={newCrop => setCrop(newCrop)} /> */}
                  </center>
                  <br />
                  {/* <center>
                    <button class="btn btn-dark F23 f45"  onClick={()=>setChangeImager55()}>Set Profile Image</button>
                  </center> */}
                {/* <ClearIcon id="myDiv34567 fgjkdgj"  style={{color : "black",fontSize : "30px",position : "absolute" , right: "10%",top : "10%" , zIndex: "2"}} onClick={()=>setChangeImager55()}/> */}
            </div>






      
        <div id="myDivUmer">
                {/* <img src={guides} alt=""  id="myDiv23456"/> */}
                <center>
                  <h1>
                     Build Your Network
                </h1>
                  </center>
                <center><p>
Capture leads using Popl and favorite, group, sort and manage them all from here. Start building your connections now                  </p></center>
                  <center>
                  <button class="btn btn-dark F23 f45" onClick={()=>setRoter123()}>Buy Now </button>
                  </center>
                <ClearIcon id="myDiv34567"  style={{color : "black",fontSize : "30px",position : "absolute" , right: "10%",top : "10%" , zIndex: "2"}} onClick={()=>setChanger2()}/>
            </div> 













{/* <br />
<br /> */}
      
                                            <div class="carousel-inner">
                                            {/* {
                                                SliderPhoto.length > 0 && SliderPhoto.map((res,i)=>{
                                                    console.log(res)
                                                        return ( */}
                                                             <>
                                                             <div class="carousel-item active efefefelk"  style={{background :    "white" ,height:"200px" ,display : "flex" , justifyContent : "center",alignItems :  "center", width : "100%"}} id="photoImage2">
                                                                {/* <img  src={User1.ProfilePhotoBuss ? User1.ProfilePhotoBuss : x1}  id="photoImage1" width= "45%" height= "300px" alt="" style={{marginTop  : "40px" , borderRadius :  "50%",height: "175px",width: "173px"}}/> */}
                                                                
                                                                <LazyLoadImage 
                                                                 src={User1.ProfilePhotoBuss ? User1.ProfilePhotoBuss : x1}
                                                                  id="photoImage1"
                                                                  // width= "45%" height= "300px"
                                                                   alt=""
                                                                   style={{maxHeight: "155px",minWidth : "130px",maxWidth: "183px" , borderRadius : "50%" , objectFit : "cover"}}

                                                                    //  style={{maxHeight: "185px",minWidth : "130px",maxWidth: "183px" , borderRadius : "50%"}}
                                                                     />
                                                                {/* <div class="carousel-caption">
                                                                    <p class="animated fadeInRight">We Are Professional</p>
                                                                    <h1 class="animated fadeInLeft">For Your Building Trust</h1>
                                                                    <button>Shop Now</button>
                                                                </div> */}
                                                            </div>
                                                       
                                                                
                                                                
                                                            </>
                                                        {/* )
                                                })
                                            } */}
                                                
                                            </div>
                                           <br/> 
                                       

                                           
                                           <center>
   <div class="image-upload">
                                <label for="file-input2" style={{border : "2px solid grey" , borderRadius : "20px" ,padding : "0px 6px"}} onClick={()=>setChangeImager()}>
                                <i  for="file-input2" className="zmdi zmdi-edit"  style={{ padding: "6px 5px 5px 3px",fontSize :"30px" , color :"black"}}/> <span style={{fontWeight : "bold" , fontSize : "22px", position : "relative" , top :  "-4px"  }}> Profile Image</span>
                                </label>
                                <input id="file-input2" type="file" style={{width :  "0px"}}  onChange={(e)=>setImage123(e.target.files[0])}/>

                                {/* <input id="file-input" type="file" style={{width :  "0px"}}  onChange={(e)=>setImage123(e.target.files[0])}/> */}
                                </div>        
</center>

 {/* <center>
                  <div style={{padding :  "20px 0px",display : "flex" , justifyContent : "center"}} >
                     <input type="text"  style={{ borderRadius : "10px" , padding : "4px 10px" , border:"1px solid black"}} value={Name} onChange={(e)=>setName(e.target.value)}/> <i className="zmdi zmdi-edit"  style={{ padding: "6px 5px 5px 28px",fontSize :"40px" , color :"black"}}/> 

                  </div>
</center>  */}


                                       
{/* <br />
<br /> */}
{!dispaly1
?
<center style={{position : "absolute" }} className="load-123">
  <Loader
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={3000} //3 secs
      />
</center>
:""
}
                                            {/* <button style={{position:"absolute",heught : "100px",right:"0px"}} className="yh1">
                                                                    <input type="file" className="zmdi zmdi-camera" visbility="hidden"  onChange={(e)=>setImage123(e.target.files[0])} style={{ width:"25px", fontSize : "30px"}} />
                                                                </button> */}





{/*           
 <center>
                  <div style={{padding :  "20px 0px",display : "flex" , justifyContent : "center"}} >
                     <input type="text"  style={{ borderRadius : "10px" , padding : "4px 10px" , border:"1px solid black"}} value={Name} onChange={(e)=>setName(e.target.value)}/> <i className="zmdi zmdi-edit"  style={{ padding: "6px 5px 5px 28px",fontSize :"40px" , color :"black"}}/> 

                  </div>
</center>  */}



<div class="fg1" style={{width : "30%",display : "flex" , justifyContent : "center",margin : "auto"}}>
<span style={{padding: "6px 30px",height: "37px",marginTop: "8px" , background : "rgb(238, 238, 238)" , color  : "black"  , fontSize : "16px"}}>Name</span>
                      <><input className="input11" type="text"   value={Name} onChange={(e)=>setName(e.target.value)}  name="name" placeholder="Enter Profile Name" /><br/></>
                   
                    </div>


                    <div class="fg1" style={{width : "30%",display : "flex" , justifyContent : "center",margin : "auto"}}>
                        <span style={{padding: "6px 38px",height: "37px",marginTop: "8px" , background : "rgb(238, 238, 238)" , color  : "black"  , fontSize : "16px"}}>Bio</span>
                      <><input className="input11" type="text"   value={gender} onChange={(e)=>setgender(e.target.value)}  name="name" placeholder="Enter Profile Bio" /><br/></>
                   
                    </div>
 


          <center>
              <div class="col-xl-6 col-lg-8 col-md-8 col-sm-10" style={{margin : "20px 0px" , display : "flex" , justifyContent :  "center "}}>
                  <div class="px-1" style={{background : "rgb(245, 246, 246)", borderRadius : "10px"}}>
                    
                    <button type="button" class="btn btn-dark" style={{padding : "10px 15px" ,border : "2px solid black",borderRadius : "10px" ,width : "230px",background : "BEC1V9" , fontSize :"20px"}} onClick={()=>setChangeData()}>Save Profile  </button>
                  </div>
                 
               
              </div>
              
          </center>

          <center>

<div className="p-b-4 trans-05" style={{ background: "rgb(140, 146, 160)",width   :"200px",padding: "15px 10px",borderRadius: "20px"}}>
<div className="stext-101 cl0 trans-09">
<Link to={"/user/new-password-recovery"} style={{color : "white"}}>Change Password </Link>
</div>
</div>
</center>

<br />
       



{/* 

          <div className="cc1">
              <div className="container1" id="container1"  >
                <div className="form-container sign-up-container">
                  <form className="form11" >
                    <h1 className="h22">Create Account</h1>
                    <div className="social-container">
                     
                    </div>
                    <input className="input11" type="text" name="name" placeholder="Name" />
                    <input className="input11" type="email" name="email" placeholder="Email" />
                    <input className="input11" type="password" name="password" placeholder="Password" />
                    <button className="button11" type="submit">SignUp</button>
                  </form>
                </div>
                <div className="form-container sign-in-container">
                  <form  className="form11"   onSubmit={(e)=>postData(e)}>
                    <h1 className="h22">Sign In</h1>
                    <div className="social-container">
                     
                    </div>
                    <input className="input11" type="email"   value={email} onChange={(e)=>setemail(e.target.value)}  name="email" placeholder="Email" />
                    {dispaly ?
                    <>
                    <input className="input11" type="password"  value={pass} onChange={(e)=>setpass(e.target.value)}  name="password" placeholder="Password" />
                    <VisibilityOffIcon  onClick={()=>setPassShow(false)} style={{position : "absolute" , right : "63px", top : "252px"}}/>
                      </>
                      :
                    <>
                    <input className="input11" type="text"  value={pass} onChange={(e)=>setpass(e.target.value)}  name="password" placeholder="Password" />
                    <VisibilityIcon onClick={()=>setPassShow(true)} style={{position : "absolute" , right : "63px", top : "252px"}}/>
                      </>
                      
                    }
                    <a href="#" className="aa">Forgot Your Password</a>
                    <button className="button11" type="submit">Sign In</button>
                  </form>
                </div>
                <div className="overlay-container">
                  <div className="overlay">
                    <div className="overlay-panel overlay-left">
                      <h1 className="h22">Welcome Back!</h1>
                      <p className="ppp1">To keep connected with us please login with your personal info</p>
                      <button  className="ghost button11" id="signIn" onClick={()=>setChange1(11)}>Sign In</button>
                    </div>
                    <div className="overlay-panel overlay-right">
                      <h1 className="h22">Hello, Friend!</h1>
                      <p className="ppp1">Enter your details and start journey with us</p>
                      <button  className="ghost button11" id="signUp" onClick={()=>setChange1(1)}>Sign Up</button>
                    </div>
                  </div>
                </div>
              </div>
          </div>
         */}

        {/* Back to top */}
        <div className="btn-back-to-top" id="myBtn">
          <span className="symbol-btn-back-to-top">
            <i className="zmdi zmdi-chevron-up" />
          </span>
        </div>
        {/* Modal1 */}
        <div className="wrap-modal1 js-modal1 p-t-60 p-b-20">
          <div className="overlay-modal1 js-hide-modal1" />
          <div className="container">
            <div className="bg0 p-t-60 p-b-30 p-lr-15-lg how-pos3-parent">
              <button className="how-pos3 hov3 trans-04 js-hide-modal1">
                <img src="images/icons/icon-close.png" alt="CLOSE" />
              </button>
              <div className="row">
                <div className="col-md-6 col-lg-7 p-b-30">
                  <div className="p-l-25 p-r-30 p-lr-0-lg">
                    <div className="wrap-slick3 flex-sb flex-w">
                      <div className="wrap-slick3-dots" />
                      <div className="wrap-slick3-arrows flex-sb-m flex-w" />
                      <div className="slick3 gallery-lb">
                        <div className="item-slick3" data-thumb="images/product-detail-01.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-01.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-01.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-02.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-02.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-02.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-03.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-03.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-03.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5 p-b-30">
                  <div className="p-r-50 p-t-5 p-lr-0-lg">
                    <h4 className="mtext-105 cl2 js-name-detail p-b-14">
                      Lightweight Jacket
                    </h4>
                    <span className="mtext-106 cl2">
                      $58.79
                    </span>
                    <p className="stext-102 cl3 p-t-23">
                      Nulla eget sem vitae eros pharetra viverra. Nam vitae luctus ligula. Mauris consequat ornare feugiat.
                    </p>
                    {/*  */}
                    <div className="p-t-33">
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Size
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Size S</option>
                              <option>Size M</option>
                              <option>Size L</option>
                              <option>Size XL</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Color
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Red</option>
                              <option>Blue</option>
                              <option>White</option>
                              <option>Grey</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-204 flex-w flex-m respon6-next">
                          <div className="wrap-num-product flex-w m-r-20 m-tb-10">
                            <div className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-minus" />
                            </div>
                            <input className="mtext-104 cl3 txt-center num-product" type="number" name="num-product" defaultValue={1} />
                            <div className="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-plus" />
                            </div>
                          </div>
                          <button className="flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04 js-addcart-detail">
                            Add to cart
                          </button>
                        </div>
                      </div>	
                    </div>
                    {/*  */}
                    <div className="flex-w flex-m p-l-100 p-t-40 respon7">
                      <div className="flex-m bor9 p-r-10 m-r-11">
                        <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 js-addwish-detail tooltip100" data-tooltip="Add to Wishlist">
                          <i className="zmdi zmdi-favorite" />
                        </a>
                      </div>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Facebook">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Twitter">
                        <i className="fa fa-twitter" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Google Plus">
                        <i className="fa fa-google-plus" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
 






        )
    
}

export default Home;