import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import swal from 'sweetalert';

import categ from '../assets/images/cate.jpeg';

import women from '../assets/images/women.jpg';
import u1 from '../assets/images/u1.jfif';
import whatapps from '../assets/images/what.png';

import "react-multi-carousel/lib/styles.css";
import "react-multi-carousel/lib/styles.css";
import "./../assets/css/main.css"
import "./../assets/css/util.css"

import "./../assets/vendor/css-hamburgers/hamburgers.min.css"

import "./../assets/fonts/iconic/css/material-design-iconic-font.min.css"
import "./../assets/vendor/bootstrap/css/bootstrap.min.css"
import "./../assets/vendor/select2/select2.min.css"
import "./../assets/vendor/slick/slick.css"
import "./../assets/vendor/perfect-scrollbar/perfect-scrollbar.css"

// import Logo from "./../assets/images/icons/logo-01.png"

// import b1 from "./../assets/images/banner-01.jpg"
// import b2 from "./../assets/images/banner-02.jpg"
// import b3 from "./../assets/images/banner-03.jpg"


// import p1 from "./../assets/images/product-01.jpg"
// import p2 from "./../assets/images/product-02.jpg"
// import p3 from "./../assets/images/product-03.jpg"
// import p4 from "./../assets/images/product-04.jpg"
// import p5 from "./../assets/images/product-05.jpg"

// import p6 from "./../assets/images/product-06.jpg"

// import h9 from "./../assets/images/VIDEO-2021-10-08-00-04-57.mp4"

// import h1 from "./../assets/images/icons/icon-heart-01.png"
// import h2 from "./../assets/images/icons/icon-heart-02.png"


// // import  "./../assets/vendor/perfect-scrollbar/perfect-scrollbar.min.js"

// // import "./../../style1.css"
// // import "./../../style.css"


// import s1 from "./../assets/images/slide-01.jpg"
// import s2 from "./../assets/images/slide-02.jpg"
// import s3 from "./../assets/images/slide-03.jpg"
import { RESIZE } from 'video-react/lib/actions/video';


const SubCategoriesProduct = (props)=> {
    const [CategoriesUrl , setCategoriesUrl] = useState("")
    const [Product , setProduct] = useState([])
    const [Product1 , setProduct1] = useState([])
    const [Product2 , setProduct2] = useState([])
    const [Categories , setCategories] = useState([])
    const [fal , setFal] = useState(false)
    const [fal1 , setFal1] = useState(false)
    const [CategoriesName , setCategoriesname] = useState({})
    const [subCate , setsubCate] = useState([])
    const [allproduct , setallproduct] = useState([])
    const [Subproduct , setSubproduct] = useState([])
    const [SortedData , setSortedData] = useState([])
    const [SortedName , setSortedName] = useState([])
    // const [productDetail , setproductDetail] = useState([])
    const [DataPart2 , setDataPart2] = useState([])
    const [Main , setMain] = useState([])

    // categoriesName subcategories

    useEffect(() =>{

        window.scrollTo(0, 0)
        // console.log( props.match.params);
        // let Categoriesname = props.match.params ;
        // setCategoriesname(Categoriesname)

        // const Inter = setInterval(()=>{
            //  if (!JSON.parse(localStorage.getItem("CateProduct")) ){
            //  var data1 = JSON.parse(localStorage.getItem("Cate")) 

        // var Category =  props.match.url.split("/")
        // setCategoriesUrl(data1)
        // var Category1 =  CategoriesUrl.split("-").join(" ")


        fetch("https://tapitdabit-backend.vercel.app/MainCatogories",{
          method: "GET",
           headers :  {
           "Content-Type" : "application/json" , 
       } ,
      })
      .then(res2=>res2.json())
      .then(res3=>{
          console.log(res3);
          setMain(res3)
      })


        
                    fetch("https://tapitdabit-backend.vercel.app/TagsSort1111",{
                    method: "GET",
                        headers :  {
                        "Content-Type" : "application/json" , 
                    } ,
                })
                .then(res9=>res9.json())
                .then(res8=>{
                    // const dta = res8.filter((res7,i)=>{
                    //     // if(res7.MainCategories ===Categoriesname.categoriesName  ) {
                    //         console.log(res7);
                    //         return res7
                    //     }
                    // })
                    console.log(res8);
                    setSortedData(res8[0])
                })

                    fetch("https://tapitdabit-backend.vercel.app/AllCategories",{
                    method: "GET",
                        headers :  {
                        "Content-Type" : "application/json" , 
                    } ,
                })
                .then(res4=>res4.json())
                .then(res5=>{
                    // let data = res5.filter((res6,i)=>{
                    //     if (res6.MainCategories === Categoriesname.categoriesName) return res6
                    // })
                    setsubCate(res5)
                    // console.log(res5,data);
                })



                fetch("https://tapitdabit-backend.vercel.app/AllProduct",{
                    method: "GET",
                    headers :  {
                    "Content-Type" : "application/json" , 
                } ,
                })
                .then(res7=>res7.json())
                .then(res8=>{
                    setallproduct(res8)
                    console.log(res8)
                    const NonActive = res8.filter((res9,i)=>{
                        // console.log(res9.status , res9.Product_Catagories , Categoriesname.categoriesName );
                        if(res9.status  ){
                            return res9 
                        }
                    })

                    setSubproduct(NonActive)
                })

        

      






        // fetch("/CategoriesShop",{
        //                         method: "GET",
        //                         headers :  {
        //                             "Content-Type" : "application/json" , 
        //                         }
        //                     })
        //                     .then(res=>res.json())
        //                     .then(res1=>{
        //                         const Pro = res1.filter((res2,i)=>{
        //                             // console.log(res2.Product_Catagories=== Category1,res2.Product_Catagories , " yyy  ", Category1);
        //                             return res2.Product_Catagories === data1
        //                         })
        //                         setProduct(Pro)
        //                         console.log(Pro);
        //                     })
        // // console.log(Catego/ry1);
        //     fetch("/AllCategories",{
        //         method: "GET",
        //             headers :  {
        //             "Content-Type" : "application/json" , 
        //         } ,
        //     })
        //     .then(res4=>res4.json())
        //     .then(res5=>{
        //         setsubCate(res5)
        //         console.log(res5);
        //     })

        // }
        // else{
        //     var data1 = JSON.parse(localStorage.getItem("Cate")) 

        //     setCategoriesUrl(data1)
            
        //     const Pro = JSON.parse(localStorage.getItem("CateProduct"))
        //                             setProduct(Pro)
        //                             console.log(Pro);
        //         fetch("/AllCategories",{
        //             method: "GET",
        //                 headers :  {
        //                 "Content-Type" : "application/json" , 
        //             } ,
        //         })
        //         .then(res4=>res4.json())
        //         .then(res5=>{
        //             setCategories(res5)
        //             // console.log(res1);
        //         })
        // }
        var data1 =  JSON.parse(localStorage.getItem("Cart")) 
        if (data1) setDataPart2(data1)
        
         const Inter = setInterval(()=>{
           
          fetch("https://tapitdabit-backend.vercel.app/AllProduct",{
            method: "GET",
            headers :  {
            "Content-Type" : "application/json" , 
        } ,
        })
        .then(res7=>res7.json())
        .then(res8=>{
           const NonActive = res8.filter((res9,i)=>{
            //    console.log(props.history.location.pathname.split("/")[2],res9.Product_Catagories ,allproduct)
                 if(res9.status  ){
                     return res9 
                 }
             })
             setSubproduct(NonActive)
             })
 
         },1000)
 
     return () => clearInterval(Inter); 

},[])




    const addtocartproduct = (productDetail) =>{
   
      swal("Update!", "Your Item Add in Cart!", "success");

      // document.getElementById("myDi").style.visibility = "visible"
      // setTimeout(()=>{
      // document.getElementById("myDi").style.visibility = "hidden"
    
      // },1000)
      if (productDetail[0].Product_Price_Discounted === null){
      const data =  {...productDetail[0] ,
          Percentage : 0,
          DeliveryStatus : "Pending",
          Pieces : 1 ,
          Total_Product_Price  : productDetail[0].Product_Price * 1 }
      var data1 = JSON.parse(localStorage.getItem("Cart")) 
      if (data1){
      var data3 = data1.map((item) => {
          if(item._id === data._id ){
              console.log("double");
      ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
      }
      else{
      console.log("double not match");
      return item
      }
    
      })
      var data5 =  JSON.parse(localStorage.getItem("double")) 
      console.log(DataPart2.length, data3.length,data5);
      var data10 =  JSON.parse(localStorage.getItem("Cart")) 
    
      if(data10.length=== data3.length && data5){
      console.log("double remove");
      localStorage.removeItem("double")
      localStorage.setItem("Cart" , JSON.stringify(data3) )
    
      }
      else{
      console.log("Differet");
      var data2 = [...data1 , data]
    
      localStorage.setItem("Cart" , JSON.stringify(data2) )
      }
      }
      else{
      console.log("1");
      localStorage.setItem("Cart" , JSON.stringify([data]) )
    
      }
    
      }
      else if (productDetail[0].Product_Price_Discounted){
      const data =  {...productDetail[0] ,
          Percentage : 0,
          DeliveryStatus : "Pending",
          Pieces : 1 ,
          Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
      var data1 = JSON.parse(localStorage.getItem("Cart")) 
      if (data1){
      var data3 = data1.map((item) => {
          if(item._id === data._id ){
              console.log("double");
      ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
      }
      else{
      console.log("double not match");
      return item
      }
    
      })
      var data5 =  JSON.parse(localStorage.getItem("double")) 
      console.log(DataPart2.length, data3.length,data5);
      var data10 =  JSON.parse(localStorage.getItem("Cart")) 
    
      if(data10.length=== data3.length && data5){
      console.log("double remove");
      localStorage.removeItem("double")
      localStorage.setItem("Cart" , JSON.stringify(data3) )
    
      }
      else{
      console.log("Differet");
      var data2 = [...data1 , data]
    
      localStorage.setItem("Cart" , JSON.stringify(data2) )
      }
      }
      else{
      console.log("1");
      localStorage.setItem("Cart" , JSON.stringify([data]) )
    
      }
    
      }
      else if (productDetail[0].Size_Discounted[0]){
          
              const data =  {...productDetail[0] ,
                  Percentage : 0,
                  DeliveryStatus : "Pending",
                  Pieces : 1 ,
                  Size : productDetail[0].Size[0],
                  Product_Price_Discounted : productDetail[0].Size_Discounted[0],
                  Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
      var data1 = JSON.parse(localStorage.getItem("Cart")) 
      if (data1){
      var data3 = data1.map((item) => {
          if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
              console.log("double");
      ;                   localStorage.setItem("double",JSON.stringify(true))
          return {...item,
              Pieces : 1 + item.Pieces,
              Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
      }
      else{
          console.log("double not match");
          return item
      }
    
      })
      var data5 =  JSON.parse(localStorage.getItem("double")) 
      console.log(DataPart2.length, data3.length,data5);
      var data10 =  JSON.parse(localStorage.getItem("Cart")) 
    
      if(data10.length=== data3.length && data5){
      console.log("double remove");
      localStorage.removeItem("double")
      localStorage.setItem("Cart" , JSON.stringify(data3) )
    
      }
      else{
      console.log("Differet");
      var data2 = [...data1 , data]
    
      localStorage.setItem("Cart" , JSON.stringify(data2) )
      }
      }
      else{
      console.log("1");
      localStorage.setItem("Cart" , JSON.stringify([data]) )
    
      }
    
      }
      else{
    
    
    
    
              const data =  {...productDetail[0] ,
                  Percentage : 0,
                  DeliveryStatus : "Pending",
                  Pieces : 1 ,
                  Size : productDetail[0].Size[0],
                  Product_Price_Discounted : productDetail[0].Price[0],
                  Total_Product_Price  : productDetail[0].Price[0] * 1 }
      var data1 = JSON.parse(localStorage.getItem("Cart")) 
      if (data1){
      var data3 = data1.map((item) => {
          if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
              console.log("double");
      ;                   localStorage.setItem("double",JSON.stringify(true))
          return {...item,
              Pieces : 1 + item.Pieces,
              Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
      }
      else{
          console.log("double not match");
          return item
      }
    
      })
      var data5 =  JSON.parse(localStorage.getItem("double")) 
      console.log(DataPart2.length, data3.length,data5);
      var data10 =  JSON.parse(localStorage.getItem("Cart")) 
    
      if(data10.length=== data3.length && data5){
      console.log("double remove");
      localStorage.removeItem("double")
      localStorage.setItem("Cart" , JSON.stringify(data3) )
    
      }
      else{
      console.log("Differet");
      var data2 = [...data1 , data]
    
      localStorage.setItem("Cart" , JSON.stringify(data2) )
      }
      }
      else{
      console.log("1");
      localStorage.setItem("Cart" , JSON.stringify([data]) )
    
      }
    
    
      }
    
    
          
    
    }
    
    
    
    
    
    
        
        
    
        const addtocartproduct12 = (productDetail) =>{
          swal("Update!", "Your Item Add in Cart!", "success");

          if (productDetail[0].Product_Price_Discounted === null){
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : 1 ,
                Total_Product_Price  : productDetail[0].Product_Price * 1 }
            var data1 = JSON.parse(localStorage.getItem("Cart")) 
            if (data1){
            var data3 = data1.map((item) => {
                if(item._id === data._id ){
                    console.log("double");
            ;                   localStorage.setItem("double",JSON.stringify(true))
            return {...item,
                Pieces : 1 + item.Pieces,
                Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
            }
            else{
            console.log("double not match");
            return item
            }
        
            })
            var data5 =  JSON.parse(localStorage.getItem("double")) 
            console.log(DataPart2.length, data3.length,data5);
            var data10 =  JSON.parse(localStorage.getItem("Cart")) 
        
            if(data10.length=== data3.length && data5){
            console.log("double remove");
            localStorage.removeItem("double")
            localStorage.setItem("Cart" , JSON.stringify(data3) )
        
            }
            else{
            console.log("Differet");
            var data2 = [...data1 , data]
        
            localStorage.setItem("Cart" , JSON.stringify(data2) )
            }
            }
            else{
            console.log("1");
            localStorage.setItem("Cart" , JSON.stringify([data]) )
        
            }
        
            }
            else if (productDetail[0].Product_Price_Discounted){
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : 1 ,
                Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
            var data1 = JSON.parse(localStorage.getItem("Cart")) 
            if (data1){
            var data3 = data1.map((item) => {
                if(item._id === data._id ){
                    console.log("double");
            ;                   localStorage.setItem("double",JSON.stringify(true))
            return {...item,
                Pieces : 1 + item.Pieces,
                Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
            }
            else{
            console.log("double not match");
            return item
            }
        
            })
            var data5 =  JSON.parse(localStorage.getItem("double")) 
            console.log(DataPart2.length, data3.length,data5);
            var data10 =  JSON.parse(localStorage.getItem("Cart")) 
        
            if(data10.length=== data3.length && data5){
            console.log("double remove");
            localStorage.removeItem("double")
            localStorage.setItem("Cart" , JSON.stringify(data3) )
        
            }
            else{
            console.log("Differet");
            var data2 = [...data1 , data]
        
            localStorage.setItem("Cart" , JSON.stringify(data2) )
            }
            }
            else{
            console.log("1");
            localStorage.setItem("Cart" , JSON.stringify([data]) )
        
            }
        
            }
        
        
          props.history.push("/custom-product/photo-upload")
        }
    
    
        return (
            <div>
                            <a href="https://wa.me/923302757197" target="_blank"><img src={whatapps} alt="" style={{position : "fixed" ,zIndex : "999999", top : "80%" , right : "20px",width:"70px",height : "70px"}}/></a>

                     {Subproduct.length === 0?
                        <Loader
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={10000} //3 secs
        className="loader-1"
        // style={{position : "fixed",    left: "35%",top:"20%",zIndex: "9999"}}
      /> 
      :""
}

        <div>
        {/* Product */}
        {/* <br /><br /><br /> */}
        <div className="bg0 m-t-60 p-b-140 mi2"  style={{paddingTop : "30px"}}>
          <div className="container">
            <div className="flex-w flex-sb-m p-b-52">
              <div className="flex-w flex-l-m filter-tope-group m-tb-10">
                <button className="stext-106 cl6 hov1 bor3 trans-04 m-r-32 m-tb-5 how-active1" data-filter="*">
                Categories
                </button>
                {Main.map((res,i)=>{
                  return(
                        <button className="stext-106 cl6 hov1 bor3 trans-04 m-r-32 m-tb-5" data-filter=".women">
                          <Link to={"/categories/"+res.MainCategories+"/product"}>{res.MainCategories}</Link>
                        </button>

                  )
                })
                  
                }
                
              </div>
              <div className="flex-w flex-c-m m-tb-10">
                {/* <div className="flex-c-m stext-106 cl6 size-104 bor4 pointer hov-btn3 trans-04 m-r-8 m-tb-4" id="ss1" onClick={()=>SetDate()}>
                  <i className="icon-filter cl2 m-r-6 fs-15 trans-04 zmdi zmdi-filter-list" />
                  <i className="icon-close-filter cl2 m-r-6 fs-15 trans-04 zmdi zmdi-close dis-none" />
                  Filter
                </div> */}
                {/* <div className="flex-c-m stext-106 cl6 size-105 bor4 pointer hov-btn3 trans-04 m-tb-4 js-show-search" id="ss3" onClick={()=>SetDate1()}>
                  <i className="icon-search cl2 m-r-6 fs-15 trans-04 zmdi zmdi-search" />
                  <i className="icon-close-search cl2 m-r-6 fs-15 trans-04 zmdi zmdi-close dis-none" />
                  Search
                </div> */}
              </div>
              {/* <div className="flex-w flex-c-m m-tb-10">
                <div className="" id="ss1" onClick={()=>SetDate()}>
                  Filter
                </div>
                <div className="flex-c-m stext-106 cl6 size-105 bor4 pointer hov-btn3 trans-04 m-tb-4 js-show-search" id="ss3" onClick={()=>SetDate1()}>
                  <i className="icon-search cl2 m-r-6 fs-15 trans-04 zmdi zmdi-search" />
            <i className="icon-close-search cl2 m-r-6 fs-15 trans-04 zmdi zmdi-close dis-none" /> 
                  Search
                </div>
              </div> */}
              {/* Search product */}
              <div className="dis-none panel-search w-full p-t-10 p-b-15" id="ss4">
                <div className="bor8 dis-flex p-l-15">
                  <button className="size-113 flex-c-m fs-16 cl2 hov-cl1 trans-04">
                    <i className="zmdi zmdi-search" />
                  </button>
                  <input className="mtext-107 cl2 size-114 plh2 p-r-15" type="text" name="search-product" placeholder="Search" />
                </div>	
              </div>
              {/* Filter */}
              <div className="dis-none panel-filter w-full p-t-10" id="ss5" id="ss2">
                <div className="wrap-filter flex-w bg6 w-full p-lr-40 p-t-27 p-lr-15-sm">
                  <div className="filter-col1 p-r-15 p-b-27">
                    <div className="mtext-102 cl2 p-b-15">
                      Sort By
                    </div>
                    <ul>
                      <li className="p-b-6">
                        <a href="#" className="filter-link stext-106 trans-04">
                          Default
                        </a>
                      </li>
                      <li className="p-b-6">
                        <a href="#" className="filter-link stext-106 trans-04">
                          Popularity
                        </a>
                      </li>
                      <li className="p-b-6">
                        <a href="#" className="filter-link stext-106 trans-04">
                          Average rating
                        </a>
                      </li>
                      <li className="p-b-6">
                        <a href="#" className="filter-link stext-106 trans-04 filter-link-active">
                          Newness
                        </a>
                      </li>
                      <li className="p-b-6">
                        <a href="#" className="filter-link stext-106 trans-04">
                          Price: Low to High
                        </a>
                      </li>
                      <li className="p-b-6">
                        <a href="#" className="filter-link stext-106 trans-04">
                          Price: High to Low
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="filter-col2 p-r-15 p-b-27">
                    <div className="mtext-102 cl2 p-b-15">
                      Price
                    </div>
                    <ul>
                      <li className="p-b-6">
                        <a href="#" className="filter-link stext-106 trans-04 filter-link-active">
                          All
                        </a>
                      </li>
                      <li className="p-b-6">
                        <a href="#" className="filter-link stext-106 trans-04">
                          $0.00 - $50.00
                        </a>
                      </li>
                      <li className="p-b-6">
                        <a href="#" className="filter-link stext-106 trans-04">
                          $50.00 - $100.00
                        </a>
                      </li>
                      <li className="p-b-6">
                        <a href="#" className="filter-link stext-106 trans-04">
                          $100.00 - $150.00
                        </a>
                      </li>
                      <li className="p-b-6">
                        <a href="#" className="filter-link stext-106 trans-04">
                          $150.00 - $200.00
                        </a>
                      </li>
                      <li className="p-b-6">
                        <a href="#" className="filter-link stext-106 trans-04">
                          $200.00+
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="filter-col3 p-r-15 p-b-27">
                    <div className="mtext-102 cl2 p-b-15">
                      Color
                    </div>
                    <ul>
                      <li className="p-b-6">
                        <span className="fs-15 lh-12 m-r-6" style={{color: '#222'}}>
                          <i className="zmdi zmdi-circle" />
                        </span>
                        <a href="#" className="filter-link stext-106 trans-04">
                          Black
                        </a>
                      </li>
                      <li className="p-b-6">
                        <span className="fs-15 lh-12 m-r-6" style={{color: '#4272d7'}}>
                          <i className="zmdi zmdi-circle" />
                        </span>
                        <a href="#" className="filter-link stext-106 trans-04 filter-link-active">
                          Blue
                        </a>
                      </li>
                      <li className="p-b-6">
                        <span className="fs-15 lh-12 m-r-6" style={{color: '#b3b3b3'}}>
                          <i className="zmdi zmdi-circle" />
                        </span>
                        <a href="#" className="filter-link stext-106 trans-04">
                          Grey
                        </a>
                      </li>
                      <li className="p-b-6">
                        <span className="fs-15 lh-12 m-r-6" style={{color: '#00ad5f'}}>
                          <i className="zmdi zmdi-circle" />
                        </span>
                        <a href="#" className="filter-link stext-106 trans-04">
                          Green
                        </a>
                      </li>
                      <li className="p-b-6">
                        <span className="fs-15 lh-12 m-r-6" style={{color: '#fa4251'}}>
                          <i className="zmdi zmdi-circle" />
                        </span>
                        <a href="#" className="filter-link stext-106 trans-04">
                          Red
                        </a>
                      </li>
                      <li className="p-b-6">
                        <span className="fs-15 lh-12 m-r-6" style={{color: '#aaa'}}>
                          <i className="zmdi zmdi-circle-o" />
                        </span>
                        <a href="#" className="filter-link stext-106 trans-04">
                          White
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="filter-col4 p-b-27">
                    <div className="mtext-102 cl2 p-b-15">
                      Tags
                    </div>
                    <div className="flex-w p-t-4 m-r--5">
                      <a href="#" className="flex-c-m stext-107 cl6 size-301 bor7 p-lr-15 hov-tag1 trans-04 m-r-5 m-b-5">
                        Fashion
                      </a>
                      <a href="#" className="flex-c-m stext-107 cl6 size-301 bor7 p-lr-15 hov-tag1 trans-04 m-r-5 m-b-5">
                        Lifestyle
                      </a>
                      <a href="#" className="flex-c-m stext-107 cl6 size-301 bor7 p-lr-15 hov-tag1 trans-04 m-r-5 m-b-5">
                        Denim
                      </a>
                      <a href="#" className="flex-c-m stext-107 cl6 size-301 bor7 p-lr-15 hov-tag1 trans-04 m-r-5 m-b-5">
                        Streetstyle
                      </a>
                      <a href="#" className="flex-c-m stext-107 cl6 size-301 bor7 p-lr-15 hov-tag1 trans-04 m-r-5 m-b-5">
                        Crafts
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row isotope-grid">

                {Subproduct.map((res,i)=>{
                     if(res.Price[0]){
                      return (
                        <div className="col-sm-6 col-md-4 col-lg-4 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                        <div className="block2">
                          <div className="block2-pic hov-img0">
                          <Link  to={"/main-categories/"+res.Product_Catagories+"/product/"+res._id+"/"+res.Product_Name} ><img src={res.Product_Image_Upload} alt="IMG-PRODUCT"  style={{height : "300px", objectFit  :"cover",height: "fitContent"}}/></Link>
                            <Link to={"/main-categories/"+res.Product_Catagories+"/product/"+res._id+"/"+res.Product_Name} className="block2-btn flex-c-m stext-103 cl2 size-102 bg0 bor2 hov-btn1 p-lr-15 trans-04 js-show-modal1">
                              Quick View
                            </Link>
                          </div>
                          <div className="block2-txt flex-w flex-t p-t-14" style={{padding :  "10px 13px"}}>
                            <div className="block2-txt-child1 flex-col-l ">
                              <Link to={"/main-categories/"+res.Product_Catagories+"/"+"product/"+res._id+"/"+res.Product_Name} className="stext-104 cl4 hov-cl1 trans-04 js-name-b2 p-b-6">
                              <b>
                            <h1 style={{color: "black", fontSize:" 16px",fontWeight: "bold"}}>
                             {
                              res.Product_Name
                            } 
                            </h1>
                            
                              </b>                      </Link>
        
        
        
                              <span className="stext-105 cl3">
                                  <i>
                                     {res.Size_Discounted[0] ? 
                                                                <div style={{display : "flex",justifyContent : 'center',fontSize : "19px"}}>
                                                                    <p  style={{color  : "black"  }}>Rs : {res.Size_Discounted[0]}</p>
                                                                    <div style={{width : "8px"}}></div>
                                                                    <p style={{color  : "grey"  }}> <b><s>Rs : {res.Price[0]}</s></b>  </p>
                                                                    <div style={{width : "8px"}}></div>
                                                                    <p style={{color  : "black"  }}> <b>Size : {res.Size[0]}</b>  </p>
                                                                </div>
                                                                : 
                                                                <div style={{display : "flex",justifyContent : 'center',fontSize : "19px"}}>
                                                                <p style={{color  : "black"  ,fontSize : "21px"}}>Rs : {res.Price[0]}</p>
                                                                  <div style={{width : "8px"}}></div>
                                                                  <p style={{color  : "black"  }}> <b>Size : {res.Size[0]}</b>  </p>
                                                              </div>
        
                                                                }    
                                  </i>
                              
        
                                                                                      </span>
                            </div>
                           <div className="block2-txt-child2 flex-r p-t-3">
                              <a href="#" className="btn-addwish-b2 dis-block pos-relative js-addwish-b2">
                              <i className="zmdi zmdi-shopping-cart dis-block trans-04"  style={{fontSize : "24px",color:"black" ,background :"rgb(238, 238, 238)",padding: "10px 13px 10px 12px",borderRadius: "39px"}}  onClick={() =>addtocartproduct([res])  }/>
        
                                {/* <img className="icon-heart1 dis-block trans-04" src={h1} alt="ICON" />
                                <img className="icon-heart2 dis-block trans-04 ab-t-l" src={h2} alt="ICON" /> */}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                        )
                      }else{
                        if(res.Product_Name === "Executive Black Metal Hybrid Card "                        ){
                          return (
                          <div className="col-sm-6 col-md-4 col-lg-4 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                          <div className="block2">
                            <div className="block2-pic hov-img0">
                            <Link  to={"/main-category/"+res.Product_Catagories+"/product/"+res._id+"/"+res.Product_Name} ><img src={res.Product_Image_Upload} alt="IMG-PRODUCT"  style={{height : "300px", objectFit  :"cover",height: "fitContent"}}/></Link>
                              <Link to={"/main-category/"+res.Product_Catagories+"/product/"+res._id+"/"+res.Product_Name} className="block2-btn flex-c-m stext-103 cl2 size-102 bg0 bor2 hov-btn1 p-lr-15 trans-04 js-show-modal1">
                                Quick View
                              </Link>
                            </div>
                            <div className="block2-txt flex-w flex-t p-t-14" style={{padding :  "10px 13px"}}>
                              <div className="block2-txt-child1 flex-col-l ">
                                <Link to={"/main-category/"+res.Product_Catagories+"/"+"product/"+res._id+"/"+res.Product_Name} className="stext-104 cl4 hov-cl1 trans-04 js-name-b2 p-b-6">
                                <b>
                            <h1 style={{color: "black", fontSize:" 16px",fontWeight: "bold"}}>
                             {
                              res.Product_Name
                            } 
                            </h1>
                            
                              </b>                      </Link>
          
          
          
                                <span className="stext-105 cl3">
                                    <i>
                                       {res.Product_Price_Discounted ? 
                                                                  <div style={{display : "flex",justifyContent : 'center',fontSize : "21px"}}>
                                                                      <p  style={{color  : "black"  }}>Rs : {res.Product_Price_Discounted}</p>
                                                                      <div style={{width : "10px"}}></div>
                                                                      <p style={{color  : "grey"  }}> <b><s>Rs : {res.Product_Price}</s></b>  </p>
                                                                  </div>
                                                                  : 
                                                                  <p style={{color  : "black"  ,fontSize : "21px"}}>Rs : {res.Product_Price}</p>
          
                                                                  }    
                                    </i>
                                
          
                                                                                        </span>
                              </div>
                             <div className="block2-txt-child2 flex-r p-t-3">
                                <a href="#" className="btn-addwish-b2 dis-block pos-relative js-addwish-b2">
                                <i className="zmdi zmdi-shopping-cart dis-block trans-04"  style={{fontSize : "24px",color:"black" ,background :"rgb(238, 238, 238)",padding: "10px 13px 10px 12px",borderRadius: "39px"}}  onClick={() =>addtocartproduct([res]) }/>
          
                                  {/* <img className="icon-heart1 dis-block trans-04" src={h1} alt="ICON" />
                                  <img className="icon-heart2 dis-block trans-04 ab-t-l" src={h2} alt="ICON" /> */}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div> 
                          )
                        }
                        else{
                          return (
                            <div className="col-sm-6 col-md-4 col-lg-4 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                            <div className="block2">
                              <div className="block2-pic hov-img0">
                              <Link  to={"/categories/"+res.Product_Catagories+"/product/"+res._id+"/"+res.Product_Name} ><img src={res.Product_Image_Upload} alt="IMG-PRODUCT"  style={{height : "300px", objectFit  :"cover",height: "fitContent"}}/></Link>
                                <Link to={"/categories/"+res.Product_Catagories+"/product/"+res._id+"/"+res.Product_Name} className="block2-btn flex-c-m stext-103 cl2 size-102 bg0 bor2 hov-btn1 p-lr-15 trans-04 js-show-modal1">
                                  Quick View
                                </Link>
                              </div>
                              <div className="block2-txt flex-w flex-t p-t-14" style={{padding :  "10px 13px"}}>
                                <div className="block2-txt-child1 flex-col-l ">
                                  <Link to={"/categories/"+res.Product_Catagories+"/"+"product/"+res._id+"/"+res.Product_Name} className="stext-104 cl4 hov-cl1 trans-04 js-name-b2 p-b-6">
                                  <b>
                            <h1 style={{color: "black", fontSize:" 16px",fontWeight: "bold"}}>
                             {
                              res.Product_Name
                            } 
                            </h1>
                            
                              
                                      </b>                       </Link>
            
            
            
                                  <span className="stext-105 cl3">
                                      <i>
                                         {res.Product_Price_Discounted ? 
                                                                    <div style={{display : "flex",justifyContent : 'center',fontSize : "21px"}}>
                                                                        <p  style={{color  : "black"  }}>Rs : {res.Product_Price_Discounted}</p>
                                                                        <div style={{width : "10px"}}></div>
                                                                        <p style={{color  : "grey"  }}> <b><s>Rs : {res.Product_Price}</s></b>  </p>
                                                                    </div>
                                                                    : 
                                                                    <p style={{color  : "black"  ,fontSize : "21px"}}>Rs : {res.Product_Price}</p>
            
                                                                    }    
                                      </i>
                                  
            
                                                                                          </span>
                                </div>
                               <div className="block2-txt-child2 flex-r p-t-3">
                                  <a href="#" className="btn-addwish-b2 dis-block pos-relative js-addwish-b2">
                                  <i className="zmdi zmdi-shopping-cart dis-block trans-04"  style={{fontSize : "24px",color:"black" ,background :"rgb(238, 238, 238)",padding: "10px 13px 10px 12px",borderRadius: "39px"}}  onClick={() =>addtocartproduct([res]) }/>
            
                                    {/* <img className="icon-heart1 dis-block trans-04" src={h1} alt="ICON" />
                                    <img className="icon-heart2 dis-block trans-04 ab-t-l" src={h2} alt="ICON" /> */}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div> 
                            )
                        }
                        
                        
                    }
                })

                }
             
            </div>
            {/* Load more */}
              {/* <div className="flex-c-m flex-w w-full p-t-0" style={{marginBottom : "40px"}}>
                <a href="#" className="flex-c-m stext-101 cl5 size-103 bg2 bor1 hov-btn1 p-lr-15 trans-04">
                  Load More
                </a>
              </div> */}
          </div>
        </div>
        
        {/* Back to top */}
        <div className="btn-back-to-top" id="myBtn">
          <span className="symbol-btn-back-to-top">
            <i className="zmdi zmdi-chevron-up" />
          </span>
        </div>
        {/* Modal1 */}
        <div className="wrap-modal1 js-modal1 p-t-60 p-b-20">
          <div className="overlay-modal1 js-hide-modal1" />
          <div className="container">
            <div className="bg0 p-t-60 p-b-30 p-lr-15-lg how-pos3-parent">
              <button className="how-pos3 hov3 trans-04 js-hide-modal1">
                <img src="images/icons/icon-close.png" alt="CLOSE" />
              </button>
              <div className="row">
                <div className="col-md-6 col-lg-7 p-b-30">
                  <div className="p-l-25 p-r-30 p-lr-0-lg">
                    <div className="wrap-slick3 flex-sb flex-w">
                      <div className="wrap-slick3-dots" />
                      <div className="wrap-slick3-arrows flex-sb-m flex-w" />
                      <div className="slick3 gallery-lb">
                        <div className="item-slick3" data-thumb="images/product-detail-01.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-01.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-01.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-02.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-02.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-02.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-03.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-03.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-03.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5 p-b-30">
                  <div className="p-r-50 p-t-5 p-lr-0-lg">
                    <h4 className="mtext-105 cl2 js-name-detail p-b-14">
                      Lightweight Jacket
                    </h4>
                    <span className="mtext-106 cl2">
                      $58.79
                    </span>
                    <p className="stext-102 cl3 p-t-23">
                      Nulla eget sem vitae eros pharetra viverra. Nam vitae luctus ligula. Mauris consequat ornare feugiat.
                    </p>
                    {/*  */}
                    <div className="p-t-33">
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Size
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Size S</option>
                              <option>Size M</option>
                              <option>Size L</option>
                              <option>Size XL</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Color
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Red</option>
                              <option>Blue</option>
                              <option>White</option>
                              <option>Grey</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-204 flex-w flex-m respon6-next">
                          <div className="wrap-num-product flex-w m-r-20 m-tb-10">
                            <div className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-minus" />
                            </div>
                            <input className="mtext-104 cl3 txt-center num-product" type="number" name="num-product" defaultValue={1} />
                            <div className="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-plus" />
                            </div>
                          </div>
                          <button className="flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04 js-addcart-detail">
                            Add to cart
                          </button>
                        </div>
                      </div>	
                    </div>
                    {/*  */}
                    <div className="flex-w flex-m p-l-100 p-t-40 respon7">
                      <div className="flex-m bor9 p-r-10 m-r-11">
                        <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 js-addwish-detail tooltip100" data-tooltip="Add to Wishlist">
                          <i className="zmdi zmdi-favorite" />
                        </a>
                      </div>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Facebook">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Twitter">
                        <i className="fa fa-twitter" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Google Plus">
                        <i className="fa fa-google-plus" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>




        )
    }



export default SubCategoriesProduct;


