import React, { useState ,useEffect} from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
// import p22 from "./../assets/images/p2.png"
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LanguageIcon from '@material-ui/icons/Language';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// import TikTokIcon from '@material-ui/icons/TikTok';
const Contact = (props)=>  {
    const [name, setname] = useState("") 
    const [lname, setlname] = useState("") 
    const [email, setemail] = useState("") 
    const [subject, setsubject] = useState("") 
    const [Message, setMessage] = useState("") 
    const [User1, setUser1] = useState({}) 
    const [User2, setUser2] = useState({}) 
    const [Sale, setSale] = useState(0) 
    const [Earn, setEarn] = useState(0) 
    const [Dis12, setDis12] = useState(false) 


    useEffect(() =>{
        if(! JSON.parse(localStorage.getItem("Ambassador User"))){
                props.history.push("/ambassador-login")
              }
              else{
                // setUser1(JSON.parse(localStorage.getItem("User")))
          
            //     this.setState({
            //         email: "",
            // })
            // window.location.reload(true)
            window.scrollTo(0, 0)
            document.getElementById("Close-the-header").style.display = "none"
            setUser1(JSON.parse(localStorage.getItem("Ambassador User")))
            fetch("https://tapitdabit-backend.vercel.app/allUser111/"+JSON.parse(localStorage.getItem("Ambassador User"))._id,{
                method: "GET",
                headers :  {
                    "Content-Type" : "application/json" , 
 } ,
})
.then(res5=>res5.json())
.then(res6=>{
    localStorage.setItem("Ambassador User" ,JSON.stringify(res6)) 
    setUser1(res6)
})
            fetch("https://tapitdabit-backend.vercel.app/AllUserCheckoutData",{
                method: "GET",
                headers :  {
                "Content-Type" : "application/json" , 
            } ,
            })
            .then(res7=>res7.json())
            .then(res8=>{
                console.log(res8)
                let h = []
                for (let i = 0; i < res8.length; i++) {
                    console.log(res8[i],res8[i].AmbaCoup , JSON.parse(localStorage.getItem("Ambassador User")).name+""+JSON.parse(localStorage.getItem("Ambassador User")).Num)
                    if(res8[i].AmbaCoup === JSON.parse(localStorage.getItem("Ambassador User")).name+""+JSON.parse(localStorage.getItem("Ambassador User")).Num){
                        h.push(res8[i])
                    }
                    
                }
                setUser2(h)
                let to = 0
                let to1 = 0
                for (let i = 0; i < h.length; i++) {
                    if(h[i].AfterDiscount){
                        to = to + h[i].AfterDiscount
                        if( h[i].discount.AmbaPercen){
                            to1 =  to1 + ( ((h[i].AfterDiscount * h[i].discount.AmbaPercen) / 100))
                        }
                    }

                }
                
                setSale(to)
                setEarn(to1)
                
            })
    }

    },[])

    const Submitdata = (e)=>{
        e.preventDefault()

        fetch("/touchIn",{
            method: 'POST' , 
            headers :  {
              "Content-Type" : "application/json" , 
            } , 
            body : JSON.stringify({
              name  ,
              lname  ,
              email  ,
              subject  ,
              Message  ,
            })
          })
          .then((res)=>res.json())
          .then((res2)  =>{
              console.log(res2)
              if (res2){
                swal("SucessFully Send "  )
                setname ("") 
                setlname("") 
                    setemail("") 
                    setsubject("") 
                    setMessage("") 
                // localStorage.setItem("User" , JSON.stringify("Yes") )
                    // props.history.push("/login")
              }
              else if (res2.Error){
                swal(res2.Error )
              }
              else{
                swal("Plz Try Again !"  )
              }
            // console.log(res2)
          })
          .catch((err)=>console.log(err))
        
    }

    const setProfile1 = ()=>{
        localStorage.removeItem("Ambassador User")
        // localStorage.removeItem("User")
        window.location.href = "https://www.tapitdabit.com/ambassador-login"
        
    }
    const eie = (a,b)=>{
        navigator.clipboard.writeText(a+""+b)
        swal("SucessFully Copied Your Coupon Code")
    }
    const eie1 = (a,b,c)=>{
        navigator.clipboard.writeText(c+""+a+""+b)
        swal("SucessFully Copied Your Referral Link")
    }


        return (
            <div>
                {/* <div className="bg-light py-3 theporper">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-0"><Link to="/ambassador-dashboard">Home</Link> <span className="mx-2 mb-0">/</span> <strong className="text-black">Contact</strong></div>
                        </div>
                    </div>
                </div> */}
                
                <div className="site-section">
                    <div className="container-fuild" style={{margin :  "auto" , width  : '90%'}}>
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="h3 mb-3 text-black">Dashboard</h2>
                            </div>

                            <div className="col-md-12">

                                <form onSubmit={(e)=>Submitdata(e)}>

                                    <div className="border" style={{padding : "10px 10px"}}>
                            <div  style={{border :  "1px solid black"}} className="col-md-12 uiuiiuuhi">
                            {/* <div className="col-md-12 mb-0"> <span className="mx-2 mb-0">/</span> <strong className="text-black">Contact</strong></div> */}
                    <li onClick={()=> setDis12(! Dis12)}>Home  <span> <KeyboardArrowDownIcon style={{position : "absolute" , right : "10px"}} /></span> </li>
                    </div>
                     {Dis12 ?

                   <ul   style={{border :  "1px solid black", padding : "10px 0px 0px 0px"}}>
                    <li  style={{ padding : "5px 14px",borderBottom :"1px solid black",margin : "0px"}}><Link to="/ambassador-dashboard" style={{color : "black", fontWeight : "bold"}}>Home</Link></li>
                    <li  style={{ padding : "5px 14px",borderBottom :"1px solid black",margin : "0px"}}><Link to="/ambassador-dashboard-Analytics" style={{color : "black", fontWeight : "bold"}}>Analytics</Link></li>
                    <li  style={{ padding : "5px 14px",borderBottom :"1px solid black",margin : "0px"}}> <Link to="/ambassador-dashboard-Payments" style={{color : "black", fontWeight : "bold"}}>Payments</Link></li>
                    <li  style={{ padding : "5px 14px",borderBottom :"1px solid black",margin : "0px"}}> <Link to="/ambassador-dashboard-Settings" style={{color : "black", fontWeight : "bold"}}>Settings</Link></li>
                    <li  style={{ padding : "5px 14px",borderBottom :"1px solid black",margin : "0px"}}> <Link onClick={()=>setProfile1()} style={{color : "black", fontWeight : "bold"}}>Logout</Link></li>
                   </ul>
                     : ""
                     }  
                            <div style={{borderBottom :  "1px solid black"}} className="col-md-12 mb- theporper"><Link to="/ambassador-dashboard">Home</Link> <span className="mx-2 mb-0" style={{paddingLeft : "30px", paddingBottom : "10px"}}>|</span><Link to="/ambassador-dashboard-Analytics">Analytics</Link> <span className="mx-2 mb-0" style={{paddingLeft : "30px", paddingBottom : "10px"}}>|</span> <Link to="/ambassador-dashboard-Payments">Payments</Link> <span className="mx-2 mb-0" style={{paddingLeft : "30px", paddingBottom : "10px"}}>|</span>  <Link to="/ambassador-dashboard-Settings">Settings</Link><span className="mx-2 mb-0" style={{paddingLeft : "30px", paddingBottom : "10px"}}>|</span>  <Link onClick={()=>setProfile1()}>Logout</Link></div>
                          
                          
<br />
                                       
            <div style={{margin : "20px 0px"}}>
                    <div style={{display : 'flex' , flexDirection  :"row"}}>
                        <div>
                            <img src={User1.Photo} alt="" style={{width : "100px" , height :  "100px" , borderRadius : "100px",marginRight : "20px",objectFit  :"cover"}}/>
                        </div>
                        <div>
                            <b style={{fontSize: "17px"}}>{User1.name}</b>
                            <p style={{margin : "0px",overflow: "hidden", maxWidth: "211px",fontSize: "13px"}} className="dfefee">{User1.email}</p>
                            <p>
                              {User1.Youtube !== ""  ? <a href={User1.Youtube}><YouTubeIcon/></a> : "" }
                              {User1.instagram !== ""  ? <a href={User1.instagram}> <InstagramIcon/></a>   : "" }
                                 {User1.Twitter  !== ""  ? <a href={User1.Twitter}> <TwitterIcon/></a>   : "" }
                            {/* <a href={User1.TikTok}> <TikTokIcon/></a> */}
                             </p>
                        </div>
                        {/* <div style={{position :  "relative"}}>
                            Hide
                        </div> */}
                    </div>
            </div>
            <div style={{margin : "20px 0px"}}>
                    {/* <div style={{display : 'flex' , flexDirection  :"row"}}> */}
                    <div className="form-group row">
                        <div className="col-md-6">
                            <h2 style={{color : "black" , fontWeight : "300"}}>Referral Link  <span style={{padding :  "4px 8px" , background :  "rgb(55, 58, 60)", fontSize : "16px", marginLeft  : "30px" , fontWeight : "bold", color :  "white"}}>{User1.percen} %</span></h2>
                            <p style={{marginTop : "20px"}}>Refer your friends using the link below and earn commissions on purchases made by them
                            <div style={{display : "flex" , flexDirection  : "column"}}>
                            <p style={{margin : "0px", fontSize : "12px" , color  : "black"}}> Referral Link </p>
                            <p> <a href={`https://www.tapitdabit.com?${User1.name}${User1.Num}`}>https://www.tapitdabit.com?{User1.name}{User1.Num}</a> </p>
                            <b style={{fontSize: "23px", color : "blue"}} onClick={()=>eie1(User1.name,User1.Num,"https://www.tapitdabit.com?")}> Copy</b>

                            </div>

</p>
                        </div>
                        <div className="col-md-6">
                            <h2 style={{color : "black" , fontWeight : "300"}}>Coupon Code  <span style={{padding :  "4px 8px" , background :  "rgb(55, 58, 60)", fontSize : "16px", marginLeft  : "30px" , fontWeight : "bold", color :  "white"}} className="eeeerr">20 % OFF</span> </h2>
                            <p style={{marginTop : "20px"}}>Share your coupon code with others. For every purchase someone makes using your coupon code, you get the credit

</p>
                            <div style={{display : "flex" , flexDirection  : "column"}}>
                            <p style={{margin : "0px", fontSize : "12px" , color  : "black"}}> Coupon Code </p>
                            <b style={{fontSize: "23px"}}> {User1.name}{User1.Num} </b>
                            <b style={{fontSize: "23px", color : "blue"}} onClick={()=>eie(User1.name,User1.Num)}> Copy</b>
                            {/* <p> <a href={`https://www.tapitdabit.com?${User1.name}${User1.Num}`}>https://www.tapitdabit.com?{User1.name}{User1.Num}</a> </p> */}
                            
                            </div>
                        </div>
                        
                    </div>
            </div>




            <div style={{margin : "20px 0px"}}>
                    {/* <div style={{display : 'flex' , flexDirection  :"row"}}> */}
                    <div className="form-group row">
                        <div className="col-md-6">
                            <h2 style={{color : "black" , fontWeight : "300"}}>Commission Structure</h2>
                            <p style={{marginTop : "5px"}}>A {User1.percen}% commission is given on sale of every product (except those listed below)



</p>
                        </div>
                        
                        
                    </div>
            </div>



            <div style={{margin : "20px 0px"}}>
                    {/* <div style={{display : 'flex' , flexDirection  :"row"}}> */}
                    <div className="form-group row">
                        <div className="col-md-12">
                            <h2 style={{color : "black" , fontWeight : "300"}}>Summary</h2>

                        </div>
                    </div>
                    <div className="form-group row">
                        
                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <div class="card twoCards">
                      <div class="card-body">
                        <div  style={{display :  "flex" , justifyContent : "space-between" , alignItems : "center"}}>
                        <p class="card-title changeTitle" style={{fontSize : "20px"  , color   : "black"}}>{User1.count} </p>
                        </div>
                          <div style={{display :  "flex" , justifyContent : "flex-start" , alignItems : "center"}}>
                              <p>Referrals </p>
                          </div>
                      </div>
                    </div>
                  </div>
                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <div class="card twoCards">
                      <div class="card-body">
                        <div  style={{display :  "flex" , justifyContent : "space-between" , alignItems : "center"}}>
                        <p class="card-title changeTitle" style={{fontSize : "20px"  , color   : "black"}}>{User2.length} </p>
                        </div>
                          <div style={{display :  "flex" , justifyContent : "flex-start" , alignItems : "center"}}>
                              <p>Orders </p>
                          </div>
                      </div>
                    </div>
                  </div>
                        {/* <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <div class="card twoCards">
                      <div class="card-body">
                        <div  style={{display :  "flex" , justifyContent : "space-between" , alignItems : "center"}}>
                        <p class="card-title changeTitle" style={{fontSize : "20px"  , color   : "black"}}>0 </p>
                        </div>
                          <div style={{display :  "flex" , justifyContent : "flex-start" , alignItems : "center"}}>
                              <p>Conversions </p>
                          </div>
                      </div>
                    </div>
                  </div> */}
                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <div class="card twoCards">
                      <div class="card-body">
                        <div  style={{display :  "flex" , justifyContent : "space-between" , alignItems : "center"}}>
                        <p class="card-title changeTitle" style={{fontSize : "20px"  , color   : "black"}}>Rs : {Sale} </p>
                        </div>
                          <div style={{display :  "flex" , justifyContent : "flex-start" , alignItems : "center"}}>
                              <p>Sales </p>
                          </div>
                      </div>
                    </div>
                  </div>
                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <div class="card twoCards">
                      <div class="card-body">
                        <div  style={{display :  "flex" , justifyContent : "space-between" , alignItems : "center"}}>
                        <p class="card-title changeTitle" style={{fontSize : "20px"  , color   : "black"}}>Rs : {Earn} </p>
                        </div>
                          <div style={{display :  "flex" , justifyContent : "flex-start" , alignItems : "center"}}>
                              <p>Earnings </p>
                          </div>
                      </div>
                    </div>
                  </div>
                        



                    </div>
            </div>







                                    </div>
                                </form>
                            </div>
                            {/* <div className="col-md-5 ml-auto">
                                <div className="p-4 border mb-3">
                                    <span className="d-block text-primary h6 text-uppercase">New York</span>
                                    <p className="mb-0">203 Fake St. Mountain View, San Francisco, California, USA</p>
                                </div>
                                <div className="p-4 border mb-3">
                                    <span className="d-block text-primary h6 text-uppercase">London</span>
                                    <p className="mb-0">203 Fake St. Mountain View, San Francisco, California, USA</p>
                                </div>
                                <div className="p-4 border mb-3">
                                    <span className="d-block text-primary h6 text-uppercase">Canada</span>
                                    <p className="mb-0">203 Fake St. Mountain View, San Francisco, California, USA</p>
                                </div>

                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    
}


export default Contact;