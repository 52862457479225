import React, { useState,useEffect } from 'react';
import { Link ,useHistory} from 'react-router-dom';
import swal from 'sweetalert';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import AssessmentIcon from '@material-ui/icons/Assessment';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SortIcon from '@material-ui/icons/Sort';

const Contact = (props)=>  {

  const [User1 ,  setUser1 ] = useState({})  
    let history = useHistory();

  useEffect(() =>{
    window.scrollTo(0, 0)

      setUser1(JSON.parse(localStorage.getItem("User")))
      
      const inter1 = setInterval(()=>{
        setUser1(JSON.parse(localStorage.getItem("User")))
      },1000) 
      return () => {
        clearInterval(inter1)
      }
  },[])
 


    const setRouting1 = (e)=>{
        if(e === 1){
          if(JSON.parse(localStorage.getItem("User")).DisPersonal){
            
                     history.push("/user/home1/"+User1._id)
        
                  
          
          }




          else {

            const d = JSON.parse(localStorage.getItem("User")).ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
            console.log(d)
            if (d.length > 0){
              let a = new Date()
              let f= a.getDate() +" "+(a.getUTCMonth()+1)+ " "+a.getUTCFullYear()
              console.log(d , d[0].End[0]+""+d[0].End[1] , a.getDate(), d[0].End[3]+""+d[0].End[4] ,a.getUTCMonth()+1 ,d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] , a.getUTCFullYear())
              if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] > a.getUTCFullYear()     ){
              // if(d[0].End && d[0].End >= f){
               
               history.push("/user/bussiness/home1/"+User1._id)
             
              }
              else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear()){
                if( d[0].End[3]+""+d[0].End[4] >a.getUTCMonth()+1 ){
                 history.push("/user/bussiness/home1/"+User1._id)
        
                }
                else{
                  if(d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1){
                    if( d[0].End[0]+""+d[0].End[1]>=a.getDate()){
                     history.push("/user/bussiness/home1/"+User1._id)
        
                    }
                    else{
                      
            // document.getElementById("myDiv1234").style.display= "block"
                history.push("/user/pro-feather")
        
                      localStorage.setItem("request123",JSON.stringify(1) )
                      
                      // setTimeout(()=>{
                        //  history.push("/user/request-for-pro-item")
                        //   swal("Sorry First You Buy")
                        // },1000)
                        
                      }
                    }
                    else{
                      // document.getElementById("myDiv1234").style.display= "block"
                          history.push("/user/pro-feather")
        
                      localStorage.setItem("request123",JSON.stringify(1) )
                      // localStorage.setItem("request123",JSON.stringify(1) )
                      // setTimeout(()=>{
                        //  history.push("/user/request-for-pro-item")
                    //   swal("Sorry First You Buy")
                    // },1000)
                    
                  }
                  }
                }
              else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear() && d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ){
                  if( d[0].End[0]+""+d[0].End[1]>=a.getDate()){
                   history.push("/user/bussiness/home1/"+User1._id)
                }
                else{
                  // document.getElementById("myDiv1234").style.display= "block"
                      history.push("/user/pro-feather")
        
                  localStorage.setItem("request123",JSON.stringify(1) )
                  //   localStorage.setItem("request123",JSON.stringify(1) )
                  // setTimeout(()=>{
                    //  history.push("/user/request-for-pro-item")
                    //   swal("Sorry First You Buy")
                    // },1000)
                    
                  }
                }
                else{
                  // document.getElementById("myDiv1234").style.display= "block"
                      history.push("/user/pro-feather")
        
                  localStorage.setItem("request123",JSON.stringify(1) )
                // localStorage.setItem("request123",JSON.stringify(1) )
                // setTimeout(()=>{
                //  history.push("/user/request-for-pro-item")
                //   swal("Sorry First You Buy")
                // },1000)
          
              }
            }
            else{
              // document.getElementById("myDiv1234").style.display= "block"
                  history.push("/user/pro-feather")
        
              localStorage.setItem("request123",JSON.stringify(1) )
              // setTimeout(()=>{
              //  history.push("/user/request-for-pro-item")
              //   swal("Sorry First You Buy")
              // },1000)
          }
          
          }







          // history.push("/user/bussiness/home1/"+User1._id)

        }
        else if(e === 4){
          // if(JSON.parse(localStorage.getItem("User")).DisPersonal)
          // history.push("/user/editProfile/sort-delete/"+User1.uid)
          // else 
          // history.push("/user/bussiness/editProfile/sort-delete/"+User1._id)







          const d = JSON.parse(localStorage.getItem("User")).ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
          console.log(d)
          if (d.length > 0){
            let a = new Date()
            let f= a.getDate() +" "+(a.getUTCMonth()+1)+ " "+a.getUTCFullYear()
            console.log(d , d[0].End[0]+""+d[0].End[1] , a.getDate(), d[0].End[3]+""+d[0].End[4] ,a.getUTCMonth()+1 ,d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] , a.getUTCFullYear())
            if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] > a.getUTCFullYear()     ){
            // if(d[0].End && d[0].End >= f){
             history.push("/user/analytics/"+User1._id)
           
            }
            else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear()){
              if( d[0].End[3]+""+d[0].End[4] >a.getUTCMonth()+1 ){
               history.push("/user/analytics/"+User1._id)
      
              }
              else{
                if(d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1){
                  if( d[0].End[0]+""+d[0].End[1]>=a.getDate()){
                   history.push("/user/analytics/"+User1._id)
      
                  }
                  else{
                    
          // document.getElementById("myDivUmer12").style.display= "block"
              history.push("/user/pro-feather")
      
                    localStorage.setItem("request123",JSON.stringify(1) )
                    
                    // setTimeout(()=>{
                      //  history.push("/user/request-for-pro-item")
                      //   swal("Sorry First You Buy")
                      // },1000)
                      
                    }
                  }
                  else{
                    // document.getElementById("myDivUmer12").style.display= "block"
                        history.push("/user/pro-feather")
      
                    localStorage.setItem("request123",JSON.stringify(1) )
                    // localStorage.setItem("request123",JSON.stringify(1) )
                    // setTimeout(()=>{
                      //  history.push("/user/request-for-pro-item")
                  //   swal("Sorry First You Buy")
                  // },1000)
                  
                }
                }
              }
            else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear() && d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ){
                if( d[0].End[0]+""+d[0].End[1]>=a.getDate()){
                 history.push("/user/analytics/"+User1._id)
              }
              else{
                // document.getElementById("myDivUmer12").style.display= "block"
                    history.push("/user/pro-feather")
      
                localStorage.setItem("request123",JSON.stringify(1) )
                //   localStorage.setItem("request123",JSON.stringify(1) )
                // setTimeout(()=>{
                  //  history.push("/user/request-for-pro-item")
                  //   swal("Sorry First You Buy")
                  // },1000)
                  
                }
              }
              else{
                // document.getElementById("myDivUmer12").style.display= "block"
                    history.push("/user/pro-feather")
      
                localStorage.setItem("request123",JSON.stringify(1) )
              // localStorage.setItem("request123",JSON.stringify(1) )
              // setTimeout(()=>{
              //  history.push("/user/request-for-pro-item")
              //   swal("Sorry First You Buy")
              // },1000)
        
            }
         
        
        
        }
        else{
          // document.getElementById("myDivUmer12").style.display= "block"
              history.push("/user/pro-feather")
      
          localStorage.setItem("request123",JSON.stringify(1) )
        }










        }
        else if(e === 3){
          if(JSON.parse(localStorage.getItem("User")).DisPersonal){

 
           
               history.push("/user/share-qr-1")
             
            

          }
          // history.push("/user/share-qr-1")
          else {





            const d = JSON.parse(localStorage.getItem("User")).ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
            console.log(d)
            if (d.length > 0){
              let a = new Date()
              let f= a.getDate() +" "+(a.getUTCMonth()+1)+ " "+a.getUTCFullYear()
              console.log(d , d[0].End[0]+""+d[0].End[1] , a.getDate(), d[0].End[3]+""+d[0].End[4] ,a.getUTCMonth()+1 ,d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] , a.getUTCFullYear())
              if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] > a.getUTCFullYear()     ){
              // if(d[0].End && d[0].End >= f){
               history.push("/user/share-qr-2")
             
              }
              else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear()){
                if( d[0].End[3]+""+d[0].End[4] >a.getUTCMonth()+1 ){
                  history.push("/user/share-qr-2")
        
                }
                else{
                  if(d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1){
                    if( d[0].End[0]+""+d[0].End[1]>=a.getDate()){
                      history.push("/user/share-qr-2")
        
                    }
                    else{
                      
            // document.getElementById("myDivUmer").style.display= "block"
                history.push("/user/pro-feather")
        
                      localStorage.setItem("request123",JSON.stringify(1) )
                      
                      // setTimeout(()=>{
                        //  history.push("/user/request-for-pro-item")
                        //   swal("Sorry First You Buy")
                        // },1000)
                        
                      }
                    }
                    else{
                      // document.getElementById("myDivUmer").style.display= "block"
                          history.push("/user/pro-feather")
        
                      localStorage.setItem("request123",JSON.stringify(1) )
                      // localStorage.setItem("request123",JSON.stringify(1) )
                      // setTimeout(()=>{
                        //  history.push("/user/request-for-pro-item")
                    //   swal("Sorry First You Buy")
                    // },1000)
                    
                  }
                  }
                }
              else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear() && d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ){
                  if( d[0].End[0]+""+d[0].End[1]>=a.getDate()){
                    history.push("/user/share-qr-2")
                  }
                else{
                  // document.getElementById("myDivUmer").style.display= "block"
                      history.push("/user/pro-feather")
        
                  localStorage.setItem("request123",JSON.stringify(1) )
                  //   localStorage.setItem("request123",JSON.stringify(1) )
                  // setTimeout(()=>{
                    //  history.push("/user/request-for-pro-item")
                    //   swal("Sorry First You Buy")
                    // },1000)
                    
                  }
                }
                else{
                  // document.getElementById("myDivUmer").style.display= "block"
                      history.push("/user/pro-feather")
        
                  localStorage.setItem("request123",JSON.stringify(1) )
                // localStorage.setItem("request123",JSON.stringify(1) )
                // setTimeout(()=>{
                //  history.push("/user/request-for-pro-item")
                //   swal("Sorry First You Buy")
                // },1000)
          
              }
           
          
          
          }
          else{
            // document.getElementById("myDivUmer").style.display= "block"
                history.push("/user/pro-feather")
        
            localStorage.setItem("request123",JSON.stringify(1) )
          }









          }
          // history.push("/user/share-qr-2")


















        }
        else if(e === 2){
         
    const d = JSON.parse(localStorage.getItem("User")).ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
    console.log(d)
    if (d.length > 0){
      let a = new Date()
      let f= a.getDate() +" "+(a.getUTCMonth()+1)+ " "+a.getUTCFullYear()
      console.log(d , d[0].End[0]+""+d[0].End[1] , a.getDate(), d[0].End[3]+""+d[0].End[4] ,a.getUTCMonth()+1 ,d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] , a.getUTCFullYear())
      if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] > a.getUTCFullYear()     ){
      // if(d[0].End && d[0].End >= f){
       history.push("/user/connect/myprofile/"+User1._id)
     
      }
      else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear()){
        if( d[0].End[3]+""+d[0].End[4] >a.getUTCMonth()+1 ){
         history.push("/user/connect/myprofile/"+User1._id)

        }
        else{
          if(d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1){
            if( d[0].End[0]+""+d[0].End[1]>=a.getDate()){
             history.push("/user/connect/myprofile/"+User1._id)

            }
            else{
              
    // document.getElementById("myDivUmer").style.display= "block"
        history.push("/user/pro-feather")

              localStorage.setItem("request123",JSON.stringify(1) )
              
              // setTimeout(()=>{
                //  history.push("/user/request-for-pro-item")
                //   swal("Sorry First You Buy")
                // },1000)
                
              }
            }
            else{
              // document.getElementById("myDivUmer").style.display= "block"
                  history.push("/user/pro-feather")

              localStorage.setItem("request123",JSON.stringify(1) )
              // localStorage.setItem("request123",JSON.stringify(1) )
              // setTimeout(()=>{
                //  history.push("/user/request-for-pro-item")
            //   swal("Sorry First You Buy")
            // },1000)
            
          }
          }
        }
      else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear() && d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ){
          if( d[0].End[0]+""+d[0].End[1]>=a.getDate()){
           history.push("/user/connect/myprofile/"+User1._id)
        }
        else{
          // document.getElementById("myDivUmer").style.display= "block"
              history.push("/user/pro-feather")

          localStorage.setItem("request123",JSON.stringify(1) )
          //   localStorage.setItem("request123",JSON.stringify(1) )
          // setTimeout(()=>{
            //  history.push("/user/request-for-pro-item")
            //   swal("Sorry First You Buy")
            // },1000)
            
          }
        }
        else{
          // document.getElementById("myDivUmer").style.display= "block"
              history.push("/user/pro-feather")

          localStorage.setItem("request123",JSON.stringify(1) )
        // localStorage.setItem("request123",JSON.stringify(1) )
        // setTimeout(()=>{
        //  history.push("/user/request-for-pro-item")
        //   swal("Sorry First You Buy")
        // },1000)
  
      }
   
  
  
  }
  else{
    // document.getElementById("myDivUmer").style.display= "block"
        history.push("/user/pro-feather")

    localStorage.setItem("request123",JSON.stringify(1) )
  }
        }
        else{
      
        }
      }
        return (
           

          window.location.href.split("/")[3] !== "user" ||  (JSON.parse(localStorage.getItem("UserProp"))) ||   User1.uid === null ? 
"":
        <center>
       <div className="container-fuild cvb1" style={{background : "white",width: "100%" , bottom : "0px",right : "0px",height : "62px" , position : "fixed" ,zIndex : "99999",padding : "6px 14px"}}>
                <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3" style={{width : "25%" , padding : "5px 0px"}} onClick={()=>setRouting1(1)}>
                <DashboardIcon/>  <br /> Dashboard        

                {/* <i className="zmdi zmdi-nature-people" /> */}
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3" style={{width : "25%" , padding : "5px 0px"}} onClick={()=>setRouting1(2)}>
                  <PeopleAltIcon/>  <br /> Connection   
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3" style={{width : "25%" , padding : "5px 0px"}} onClick={()=>setRouting1(3)}>
                <AssessmentIcon/>  <br /> Share        
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3" style={{width : "25%" , padding : "5px 0px"}} onClick={()=>setRouting1(4)}>
                <SortIcon/>  <br />Analytics        
                </div>
                </div>
              </div>
      </center>


        )
    
}


export default Contact;