import React from 'react';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
// import Shop from './Pages/Shop';
import Contact from './Pages/Contact';
// import Detail from './Pages/Detail';
// import Card from './Pages/Card';
import Checkout from './Pages/Checkout';
import Thankyou from './Pages/Thankyou';
import Header from './Components/Header';
import Footer from './Components/Footer';

// import demo from './Pages/demo';
// import CategoriesShop from './Pages/CategoriesShop';
// import SearchData from './Pages/SearchData';

import Signup from './Pages/Admin/Signup';

import AllShop from './Pages/AllShop';
import TermAmba from './Pages/TermAmba';


// import whatapps from './assets/images/what.png';






// import SubCategories from './Pages/Categories';
import SubCategoriesProduct from './Pages/SubCategoriesProduct';
import SubCategoriesProductDetails from './Pages/SubCategoriesProductDetails';












import "./bootstrap.min.css"
import "./style1.css"


// import teacherform from './Pages/teacherform';
// import studentform from './Pages/studentform';
import Shipping from './Pages/Shipping';
import Privacy from './Pages/Privacy';
import tapkropro from './Pages/tapkro Pro';
import enterprise from './Pages/enterprise';







import Login from './Pages/login';
import userHome from './Pages/userHome';
import userMainHome from './Pages/userMainHome';
import linkmerecreate from './Pages/linkmerecreate';
import linkpartycreate from './Pages/linkpartycreate';
import ContactCard from './Pages/ContactCard';
import OtherCheckProfile from './Pages/OtherCheckProfile';
import Crt from './Pages/Crt';
import embedvideo from './Pages/embedvideo';
import sortanddeletelink from './Pages/sortanddeletelink';
import otherconnectedpeople from './Pages/otherconnectedpeople';
import myprofileconnectedrequest from './Pages/myprofileconnectedrequest';
import customlinkparty from './Pages/customlinkparty';


import userMainHomeBussiness from './Pages/userMainHomeBussiness';
import userHomeBussiness from './Pages/userHomeBussiness';
import linkmerecreateBussiness from './Pages/linkmerecreateBussiness';
import linkpartycreateBussiness from './Pages/linkpartycreateBussiness';
import ContactCardBussiness from './Pages/ContactCardBussiness';
import customlinkpartyBussiness from './Pages/customlinkpartyBussiness';
import embedvideoBussiness from './Pages/embedvideoBussiness';
import sortanddeletelinkBussiness from './Pages/sortanddeletelinkBussiness';
import userAnalytics from './Pages/userAnalytics';
import myprofileconnectedrequestSignlePerson from './Pages/myprofileconnectedrequestSignlePerson';
import requestforProItem from './Pages/requestforProItem';
import editUserHome from './Pages/editUserHome';
import editUserHomeBussiness from './Pages/editUserHomeBussiness';
import EditDataLink from './Pages/EditDataLink';
import EditDataLinkBussiness from './Pages/EditDataLinkBussiness';
import AddOtherLink from './Pages/AddOtherLink';
import AddOtherLinkContact from './Pages/AddOtherLinkContact';
import AddOtherLinkContactBussiness from './Pages/AddOtherLinkContactBussiness';
import help from './Pages/help';
import pro from './Pages/pro';
import AddOtherLinkBussiness from './Pages/AddOtherLinkBussiness';
import photoUploadCustom from './Pages/photoUploadCustom';
import newpasswordInUser from './Pages/newpasswordInUser';
import forgetPass from './Pages/forgetPass';
import ActivateAccount from './Pages/ActivateAccount';
import AmbassadorLogin from './Pages/AmbassadorLogin';
import AmbassadorSignup from './Pages/AmbassadorSignup';
import AmbassadorDashboard from './Pages/AmbassadorDashboard';
import ambassadorforgetPass from './Pages/ambassadorforgetPass';
import AmbassadorSetting from './Pages/AmbassadorSetting';
import AmbassadorAnalytics from './Pages/AmbassadorAnalytics';
import AmbassadorPayments from './Pages/AmbassadorPayments';
import AmbassadorWithdraw from './Pages/AmbassadorWithdraw';
import h from './Pages/h';
import Setup from './Pages/Setup';
import Shareqr from './Pages/Shareqr';
import ShareqrBuss from './Pages/ShareqrBuss';
import Mobileheader from './Pages/Mobileheader';
import Tutorial from './Pages/Tutorial';
import SizeSubProductDetails from './Pages/SizeSubProductDetails';
import nameSubProductDetails from './Pages/nameSubProductDetails';
import Error from './Pages/Error';


function App() {
  // const whatappsClick = ()=>{
  //   console.log("efef");
  // }
  return (
    <div className="App">
      <Router>

        <Header />
        <Mobileheader/>
        {/* <div className="desktop-nav" id="desktop-nav1">
            <ul className="desktop-nav-drop">
                       <li>
                            <Link to="/shop"  className="mobile-nav-border">Shoes</Link>
                        </li>
                       <li> 
                            <Link  className="mobile-nav-border">Shoes</Link>    
                        </li>
                       <li>
                            <Link  className="mobile-nav-border">Shoes</Link>
                        </li>
           </ul>
       </div> */}
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/h' component={h} />
          <Route exact path='/user/pro-feather' component={pro} />
          <Route exact path='/signup-user' component={Signup} />
          {/* <Route exact path='/abc' component={demo} /> */}
          <Route exact path='/custom-product/photo-upload' component={photoUploadCustom} />
          <Route exact path='/all-shop' component={AllShop} />
          <Route exact path='/about' component={About} />
          {/* <Route exact path='/shop' component={Shop} /> */}
          <Route exact path='/contact' component={Contact} />
          <Route exact path='/help' component={help} />
          <Route exact path='/tutorial' component={Tutorial} />
          <Route exact path='/tapkropro' component={tapkropro} />
          <Route exact path='/enterprise' component={enterprise} />
          <Route exact path='/account-activate' component={ActivateAccount} />






          <Route exact path='/login' component={Login} />
          <Route exact path='/all-TermAmba' component={TermAmba} />
          <Route exact path='/howtosetup' component={Setup} />
          <Route exact path='/ambassador-forget-Pass' component={ambassadorforgetPass} />
          <Route exact path='/ambassador-login' component={AmbassadorLogin} />
          <Route exact path='/ambassador-dashboard' component={AmbassadorDashboard} />
          <Route exact path='/ambassador-dashboard-Analytics' component={AmbassadorAnalytics} />
          <Route exact path='/ambassador-dashboard-Payments' component={AmbassadorPayments} />
          <Route exact path='/ambassador-dashboard-withdraw' component={AmbassadorWithdraw} />
          <Route exact path='/ambassador-dashboard-Settings' component={AmbassadorSetting} />
          <Route exact path='/ambassador-sign-up' component={AmbassadorSignup} />

          <Route exact path='/user/request-for-pro-item' component={requestforProItem} />
          <Route exact path='/user/share-qr-1' component={Shareqr} />
          <Route exact path='/user/share-qr-2' component={ShareqrBuss} />
          <Route exact path='/user/home/:userId' component={userHome} />
          <Route exact path='/user/home1/:userId' component={userMainHome} />
          <Route exact path='/user/new-password-recovery' component={newpasswordInUser} />
          
          
          <Route exact path='/user/profile-edit/:userId' component={editUserHome} />
          <Route exact path='/user/edit-link/:userId' component={EditDataLink} />
          <Route exact path='/user/bussiness/edit-link/:userId' component={EditDataLinkBussiness} />
          <Route exact path='/user/bussiness/profile-edit/:userId' component={editUserHomeBussiness} />
         
          <Route exact path='/user/bussiness/home/:userId' component={userHomeBussiness} />
          <Route exact path='/user/bussiness/home1/:userId' component={userMainHomeBussiness} />
          <Route exact path='/user/bussiness/editProfile/:userId' component={linkmerecreateBussiness} />
          <Route exact path='/user/bussiness/editProfile123/:userId' component={linkpartycreateBussiness} /> 
           <Route exact path='/user/bussiness/editProfile/contactcard/:userId' component={ContactCardBussiness} /> 
           <Route exact path='/user/bussiness/editProfile/linktree/:userId' component={customlinkpartyBussiness} /> 
           <Route exact path='/user/bussiness/editProfile/emdedvideo/:userId' component={embedvideoBussiness} />
          <Route exact path='/user/bussiness/editProfile/sort-delete/:userId' component={sortanddeletelinkBussiness} />
          <Route exact path='/user/bussiness/editProfile/addotherlink/contact/:userId' component={AddOtherLinkContactBussiness} />
          <Route exact path='/user/bussiness/editProfile/addotherlink/:userId' component={AddOtherLinkBussiness} />

          {/* Website link e.g (https://www.web.com) */}


          <Route exact path='/user/analytics/:userId' component={userAnalytics} />

          <Route exact path='/user/editProfile/:userId' component={linkmerecreate} />
          <Route exact path='/user/editProfile/addotherlink/:userId' component={AddOtherLink} />
          <Route exact path='/user/editProfile/addotherlink/contact/:userId' component={AddOtherLinkContact} />
          <Route exact path='/user/editProfile123/:userId' component={linkpartycreate} />
          <Route exact path='/user/editProfile/linktree/:userId' component={customlinkparty} />
          <Route exact path='/user/editProfile/sort-delete/:userId' component={sortanddeletelink} />
          <Route exact path='/user/editProfile/contactcard/:userId' component={ContactCard} />
          <Route exact path='/user/editProfile/emdedvideo/:userId' component={embedvideo} />








          <Route exact path='/user/:otheruserId' component={OtherCheckProfile} />
          <Route exact path='/user/connected/:otheruserId' component={otherconnectedpeople} />
          <Route exact path='/user/connect/myprofile/:otheruserId' component={myprofileconnectedrequest} />
          <Route exact path='/user/connect/myprofile/single/:otheruserId' component={myprofileconnectedrequestSignlePerson} />













          <Route exact path='/privacy-policy' component={Privacy} />
          <Route exact path='/term-policy' component={Shipping} />
          
          {/* <Route exact path='/student-tutor-forum' component={studentform} />
          <Route exact path='/teacher-medium-forum' component={teacherform} /> */}
        

          <Route exact path='/categories/:categoriesName/product' component={SubCategoriesProduct} />
          <Route exact path='/categories/:categoriesName/product/:productid/:productname' component={SubCategoriesProductDetails} />
          <Route exact path='/main-categories/:categoriesName/product/:productid/:productname' component={SizeSubProductDetails} />
          <Route exact path='/main-category/:categoriesName/product/:productid/:productname' component={nameSubProductDetails} />



   
          <Route exact path='/cart' component={Crt} />
          <Route exact path='/checkout' component={Checkout} />
          <Route exact path='/thankyou' component={Thankyou} />
          <Route exact path='/forget-Pass' component={forgetPass} />



          <Route path='*' component={Error} />


        </Switch>

        <Footer />

      </Router>
    </div>
  );
}

export default App;
