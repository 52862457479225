import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"

import "react-multi-carousel/lib/styles.css";
import "react-multi-carousel/lib/styles.css";
import "./../assets/css/main.css"
import "./../assets/css/util.css"
import "./../assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css"


// import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"

import "./../assets/vendor/css-hamburgers/hamburgers.min.css"

import "./../assets/fonts/iconic/css/material-design-iconic-font.min.css"
import "./../assets/vendor/bootstrap/css/bootstrap.min.css"
import "./../assets/vendor/select2/select2.min.css"
import "./../assets/vendor/slick/slick.css"
import "./../assets/vendor/perfect-scrollbar/perfect-scrollbar.css"

import x1 from "./../assets/images/x1.jpg"

import me1 from "./../assets/images/me1.jpg"
import me2 from "./../assets/images/me2.jpg"





const Home = ( props ) => {
    const [myRef,setRef] = useState( React.createRef())
    const [scrollTop1,setscrollTop] = useState(0)
    const [email , setemail] = useState("")
    const [pass , setpass] = useState("")
    const [dispaly ,  setdispaly ] = useState(true)
    const [User1 ,  setUser1 ] = useState({})
    const [Arr1 ,  setArr1 ] = useState([])
    const [Arr2 ,  setArr2 ] = useState([])
    const [Arr5 ,  setArr5 ] = useState([])
    const [directOffDis ,  setdirectOffDis ] = useState(false)
    const [directOff ,  setdirectOff ] = useState(false)
    const [All1, setAll1] = useState([])
    const [ConnectedUser, setConnectedUser] = useState([])
    const [range1,setRange1] = useState([0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0])
    const [as ,  setas ] = useState(true)
    const [Y ,  setY ] = useState(false)

    useEffect(() =>{        window.scrollTo(0, 0)

if(! JSON.parse(localStorage.getItem("User"))){
        props.match.history.push("/login")
      }
      else{
        // setUser1(JSON.parse(localStorage.getItem("User")))
  
    //     this.setState({
    //         email: "",
    // })


    
      setY(true)























    localStorage.setItem("VenderWorkingThose" ,JSON.stringify(123)) 


// let A1 = JSON.parse(localStorage.getItem("User"))
  fetch("https://tapitdabit-backend.vercel.app/allUser/"+JSON.parse(localStorage.getItem("User"))._id,{
    method: "GET",
     headers :  {
     "Content-Type" : "application/json" , 
 } ,
})
.then(res5=>res5.json())
.then(res6=>{

  // localStorage.removeItem("User")
  const data = {
    ...res6 , All : [...res6.Social,...res6.Contact]
  }
  localStorage.setItem("User" ,JSON.stringify(data)) 

      setUser1(res6)
      const aa = [...res6.Social,...res6.Contact]
      aa.sort((a, b) => a.sort > b.sort && 1 || -1)
      setArr5(aa)
      setAll1(aa)
      setArr1(res6.Social)
      setArr2(res6.Contact)
     


//   fetch("https://tapitdabit-backend.vercel.app/ab",{
//     method: "POST",
//     headers :  {
//         "Content-Type" : "application/json" , 
//     } ,
//     body : JSON.stringify({
//         data :[...res6.Social,...res6.Contact],
//       id : res6._id
//         // MainLine2 :mainline ,
//     })
//   })
//   .then((res3)=>res3.json())
//   .then((res44)=>{
//       if(res44.Error){
          
//           // swal(res12.Error);
//         }
//         else{
            
//             console.log(res6)
            
            
            
            
            
//         }
//   })
})





fetch("https://tapitdabit-backend.vercel.app/ConnectedOther",{
  method: "GET",
  headers :  {
      "Content-Type" : "application/json" , 
  } 
})
.then((res13)=>res13.json())
.then((res444)=>{
  let f = JSON.parse(localStorage.getItem("User")).DisPersonal
  let y 
  if(f){
    y = "per"
  }
  else y  = "buss"
    console.log(res444,JSON.parse(localStorage.getItem("User"))._id)
    const Fil = res444.filter((res)=>res.id === JSON.parse(localStorage.getItem("User"))._id && res.use === y)
    setConnectedUser(Fil.reverse())

    console.log(Fil)
})
    // console.log(scrollTop1)





   
      }









    return () => {
      document.getElementById("get1").classList.remove("is-active");
      document.getElementById("get2").style.display = "none";
    }


    },[])



const setClass = ( data ) =>{
    props.history.push("/user/connect/myprofile/single/"+data._id)
}
        return (
     
<>
   
        
       

{Y ? 
   <div >
        <div style={{height : "2px"}}>

</div>
      


<h2 className='change-resspon-link' style={{margin :"92px 5% 22px 20%" ,color  :"black"}}>Leads


   </h2>


   
<center>
  
<section className="bg0">
          <div className="container">
            <div className="p-b-10">
            
              <h1  style={{color : "black",fontWeight : "bold"}}>

              Connections

              </h1>
             
            </div>
            
           
            <div className="row isotope-grid">
       
            {ConnectedUser.map((res1,i)=>{
    if(User1.DisPersonal === res1.dis){
          return(
                <div className="col-sm-6 col-md-6 col-lg-6 p-b-35 isotope-item women" style={{padding :  "4px 10px"}}  onClick={()=>setClass(res1)}>
                <div className="block2" style={{display : "flex", padding  : "0px 0px"}}>
                {/* <div className="block2" style={{display : "flex", borderBottom : "1px solid black" ,padding  : "10px 0px"}}> */}
                  <div className="block2-pic hov-img0" style={{padding  : "0px 20px"}}>
                    <Link >
                    {/* <img src={tapni1} alt="IMG-PRODUCT"  style={{width:"67px",height : "64px"}}/> */}
                    {res1.photo === null  ? 
                        <img src={x1} alt="" style={{width : "74px",minHeight : "68px",maxHeight: "74px" , borderRadius : "50%",objectFit : "cover"}}/>
                        : 
                        <img src={res1.photo} alt="" style={{width : "74px",minHeight : "68px",maxHeight: "74px" , borderRadius : "50%",objectFit : "cover"}}/>
                    }
                    </Link>
                  </div>
                  <div className='text-resp-connection' style={{paddingTop  : "8px"}}>
                  {/* <div className='text-resp-connection' style={{paddingTop  : "30px"}}> */}
                    <h6 style={{color : "black",fontWeight : 'bold',overflow: "hidden",width: "199px"}}> {res1.fullName}</h6>
                    <p style={{color : "black",marginBottom : "0px",marginTop: "-9px"}}>  <img src={me2} alt=""  width="30px" height="30px"/>{res1.Date}  </p>
                    <p style={{color : "black",marginBottom : "0px",marginTop: "-7px"}}> {res1.Role ? <><img src={me1} alt="" width="30px" height="30px"/> {res1.Role}</> : ""}  </p>
                  </div>
                </div>
              </div>
          )
    }
  })
}
            
             
            </div>

          </div>
        </section>



</center>



















{/* 
<center>  <section>

  {ConnectedUser.map((res1,i)=>{
    if(User1.DisPersonal === res1.dis){
          return(
                      
                               <center style={{padding: "20px"}}>
                               <div style={{display : "flex" , justifyContent : "center"}} onClick={()=>setClass(res1)} className="mn1">
                                     {res1.photo !== ""  ? 
                                                    <img src={res1.photo} alt="" className="arrival-Photo111 abc123" style={{margin: "-1px 0px 0px -25px" , borderRadius :  "70px", maxHeight : "70px"}}/>
                                                    : 
                                                <img src={x1} alt="" className="arrival-Photo111 abc123"/>
                                                }
                                                
                                            <div className="container" style={{margin : "25px 0px 0px 0px" , width : "10%"}}>
                                                    <div className="row">
                                
                                        
                                                <div className="col-xl-10 col-lg-10 col-md-4 col-sm-5" style={{margin : "0px 10px"}}>
                                                <div style={{minWidth: "95%",display: "flex",justifyContent: "center",margin : "0px 20px"}}  >
                                                <div>
                                                <h5 style={{color  :"black",width : "100px", overflow: "hidden"}}>
                                                {res1.fullName}
                                                </h5>
                                                <p style={{maxWidth : "250px",    overflow: "hidden" , margin : "0px",width: "123px",marginLeft : "27px"}}> 
                                          
                                                 <img src={me2} alt=""  width="30px" height="30px"/>{res1.Date} 
                                                </p>
                                                <p style={{maxWidth : "250px",    overflow: "hidden"}}> 
                                               <img src={me1} alt="" width="30px" height="30px"/> {res1.Role} 
                                                </p>
                                                </div>
                                                </div>
                                            </div>
                                            
                                           
                            </div>
                               
                            </div>
                               </div>
                                                </center>
                                 
            
                    )
    }
                
            })

            }
                  </section> 
</center>
           
 */}
























































        {/* Back to top */}
        <div className="btn-back-to-top" id="myBtn">
          <span className="symbol-btn-back-to-top">
            <i className="zmdi zmdi-chevron-up" />
          </span>
        </div>
        {/* Modal1 */}
        <div className="wrap-modal1 js-modal1 p-t-60 p-b-20">
          <div className="overlay-modal1 js-hide-modal1" />
          <div className="container">
            <div className="bg0 p-t-60 p-b-30 p-lr-15-lg how-pos3-parent">
              <button className="how-pos3 hov3 trans-04 js-hide-modal1">
                <img src="images/icons/icon-close.png" alt="CLOSE" />
              </button>
              <div className="row">
                <div className="col-md-6 col-lg-7 p-b-30">
                  <div className="p-l-25 p-r-30 p-lr-0-lg">
                    <div className="wrap-slick3 flex-sb flex-w">
                      <div className="wrap-slick3-dots" />
                      <div className="wrap-slick3-arrows flex-sb-m flex-w" />
                      <div className="slick3 gallery-lb">
                        <div className="item-slick3" data-thumb="images/product-detail-01.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-01.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-01.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-02.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-02.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-02.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-03.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-03.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-03.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5 p-b-30">
                  <div className="p-r-50 p-t-5 p-lr-0-lg">
                    <h4 className="mtext-105 cl2 js-name-detail p-b-14">
                      Lightweight Jacket
                    </h4>
                    <span className="mtext-106 cl2">
                      $58.79
                    </span>
                    <p className="stext-102 cl3 p-t-23">
                      Nulla eget sem vitae eros pharetra viverra. Nam vitae luctus ligula. Mauris consequat ornare feugiat.
                    </p>
                    {/*  */}
                    <div className="p-t-33">
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Size
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Size S</option>
                              <option>Size M</option>
                              <option>Size L</option>
                              <option>Size XL</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Color
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Red</option>
                              <option>Blue</option>
                              <option>White</option>
                              <option>Grey</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-204 flex-w flex-m respon6-next">
                          <div className="wrap-num-product flex-w m-r-20 m-tb-10">
                            <div className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-minus" />
                            </div>
                            <input className="mtext-104 cl3 txt-center num-product" type="number" name="num-product" defaultValue={1} />
                            <div className="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-plus" />
                            </div>
                          </div>
                          <button className="flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04 js-addcart-detail">
                            Add to cart
                          </button>
                        </div>
                      </div>	
                    </div>
                    {/*  */}
                    <div className="flex-w flex-m p-l-100 p-t-40 respon7">
                      <div className="flex-m bor9 p-r-10 m-r-11">
                        <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 js-addwish-detail tooltip100" data-tooltip="Add to Wishlist">
                          <i className="zmdi zmdi-favorite" />
                        </a>
                      </div>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Facebook">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Twitter">
                        <i className="fa fa-twitter" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Google Plus">
                        <i className="fa fa-google-plus" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
 
:
""

}




</>



        )
    
}

export default Home;