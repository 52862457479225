



import React , {useState , useEffect} from 'react';
import {Link} from "react-router-dom"

const Header = ( props ) => {
  




        return (
            <>
            <div className='mi2' style={{ paddingTop: "29px",marginTop : "70px", paddingBottom  :"28px" }}>

            <h1 style={{  textAlign: "center",margin: "30px 15px",fontWeight : "bold",fontSize :"42px"}}>404 Error Page</h1>
            <p class="zoom-area"><b>I</b>  think you just went to a page non-existing page. </p>
            <p class="zoom-area"><b>Click</b>   anywhere on the screen if you want to search for something.</p>
            <section class="error-container">
              <span class="four"><span class="screen-reader-text">4</span></span>
              <span class="zero"><span class="screen-reader-text">0</span></span>
              <span class="four"><span class="screen-reader-text">4</span></span>
            </section>
            <div class="link-container">
              <a onClick={()=>props.history.push("/")} class="more-link">Go Back</a>
            </div>
            <br />
            </div>
            </>
        )
    
}

export default Header;