import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"
import swal from 'sweetalert';
import $ from 'jquery';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import ClearIcon from '@material-ui/icons/Clear';
import a23 from "./../assets/images/a23.png"

import "react-multi-carousel/lib/styles.css";
import "react-multi-carousel/lib/styles.css";
import "./../assets/css/main.css"
import "./../assets/css/util.css"
import "./../assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css"

import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import AssessmentIcon from '@material-ui/icons/Assessment';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SortIcon from '@material-ui/icons/Sort';



// import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"

import "./../assets/vendor/css-hamburgers/hamburgers.min.css"

import "./../assets/fonts/iconic/css/material-design-iconic-font.min.css"
import "./../assets/vendor/bootstrap/css/bootstrap.min.css"
import "./../assets/vendor/select2/select2.min.css"
import "./../assets/vendor/slick/slick.css"
import "./../assets/vendor/perfect-scrollbar/perfect-scrollbar.css"

// import Logo from "./../assets/images/icons/logo-01.png"

// import b1 from "./../assets/images/banner-01.jpg"
// import b2 from "./../assets/images/banner-02.jpg"
// import b3 from "./../assets/images/banner-03.jpg"


import a1 from "./../assets/images/a1.png"
import a2 from "./../assets/images/a2.png"
import a3 from "./../assets/images/a3.png"
import a4 from "./../assets/images/a4.png"
import a5 from "./../assets/images/a5.png"
import a6 from "./../assets/images/a6.png"
import a7 from "./../assets/images/a7.png"
import a8 from "./../assets/images/a8.png"

import a9 from "./../assets/images/a9.png"

// import a11 from "./../assets/images/a11.png"
import a21 from "./../assets/images/a21.png"
import a22 from "./../assets/images/a22.png"
// import a23 from "./../assets/images/a23.png"
import a24 from "./../assets/images/a24.png"
import a25 from "./../assets/images/a25.png"
// import a27 from "./../assets/images/a27.png"


import z1 from "./../assets/images/z1.png"
import z2 from "./../assets/images/z2.png"
import z3 from "./../assets/images/z3.png"
// import z4 from "./../assets/images/z4.png"


import c1 from "./../assets/images/c1.png"
// import c2 from "./../assets/images/c2.png"
import c3 from "./../assets/images/c3.png"
// import c4 from "./../assets/images/c4.png"






// import p2 from "./../assets/images/product-02.jpg"
// import p3 from "./../assets/images/product-03.jpg"
// import p4 from "./../assets/images/product-04.jpg"
// import p5 from "./../assets/images/product-05.jpg"
// import p6 from "./../assets/images/product-06.jpg"



// import h1 from "./../assets/images/icons/icon-heart-01.png"
// import h2 from "./../assets/images/icons/icon-heart-02.png"

import nu1 from "./../assets/images/nu1.jpeg"



// import s1 from "./../assets/images/slide-01.jpg"
// import s2 from "./../assets/images/slide-02.jpg"
// import s3 from "./../assets/images/slide-03.jpg"
import Loader from "react-loader-spinner";







import ln1 from "./../assets/images/svg/social/f linkedin.svg"
import tw1 from "./../assets/images/svg/social/f twitter.svg"
import in1 from "./../assets/images/svg/social/f instagram.svg"
import pi1 from "./../assets/images/svg/social/f pinterest.svg"
import tt1 from "./../assets/images/svg/social/twitch (1).svg"
import tk1 from "./../assets/images/svg/social/tiktok.svg"
import you1 from "./../assets/images/svg/social/youtube (2).svg"
import sc1 from "./../assets/images/svg/social/snapchat.svg"
import fb1 from "./../assets/images/svg/social/f facebook.svg"


import bk1 from "./../assets/images/svg/Payments/bank (9).svg"
import ca1 from "./../assets/images/svg/Payments/cashapp.svg"
import ep1 from "./../assets/images/svg/Payments/easypaisa.png"
import jc1 from "./../assets/images/svg/Payments/SELECT JAZZ CASH LOGO/jazzcash.png"
import ve1 from "./../assets/images/svg/Payments/venmo.svg"
import pp1 from "./../assets/images/svg/Payments/paypal (1).svg"



import cu1 from "./../assets/images/svg/MORE/custom link.svg"
import lt1 from "./../assets/images/svg/MORE/linktree-2.svg"
import vi1 from "./../assets/images/svg/MORE/video.svg"
import we1 from "./../assets/images/svg/MORE/WEBSITE.svg"
// import ve1 from "./../assets/images/svg/MORE/custom link.svg"
// import ve1 from "./../assets/images/svg/MORE/custom link.svg"


import call1 from "./../assets/images/svg/cotact info/call.svg"
import cd1 from "./../assets/images/svg/cotact info/ccard.svg"
import lo1 from "./../assets/images/svg/cotact info/location.svg"
import mail1 from "./../assets/images/svg/cotact info/mail.svg"
import wa1 from "./../assets/images/svg/cotact info/whatapps.svg"
import mess1 from "./../assets/images/svg/cotact info/message (2).svg"




const Home = ( props ) => {
    const [myRef,setRef] = useState( React.createRef())
    const [scrollTop1,setscrollTop] = useState(0)
    const [email , setemail] = useState("")
    const [email1 , setemail1] = useState("")
    const [Name , setName] = useState("")
    const [Image , setImage] = useState("")
    const [pass , setpass] = useState("")
    const [dispaly ,  setdispaly ] = useState(true)
    const [disUser ,  setdisUser ] = useState(true)
    const [disUser1 ,  setdisUser1 ] = useState(false)
    const [User1 ,  setUser1 ] = useState({})
    const [Num ,  setNum ] = useState(0)
    const [FUrl ,  setFUrl ] = useState("")
    const [dispaly1 ,  setdispaly1 ] = useState(true)
    const [dispaly2 ,  setdispaly2 ] = useState(false)
    const [dispalyBut ,  setdispalyBut ] = useState(true)

    const [UserPhoneCode ,  setUserPhoneCode ] = useState("92")
    const [PhoneCode ,  setPhoneCode ] = useState([
      {
        code: 'PK', map: '🇵🇰', phoneCode: 92, value: 'Pakistan',
      },
      {
        code: 'AF', map: '🇦🇫', phoneCode: 93, value: 'Afghanistan',
      },
      {
        code: 'AL', map: '🇦🇱', phoneCode: 355, value: 'Albania',
      },
      {
        code: 'DZ', map: '🇩🇿', phoneCode: 213, value: 'Algeria',
      },
      {
        code: 'AS', map: '🇦🇸', phoneCode: 1684, value: 'American Samoa',
      },
      {
        code: 'AD', map: '🇦🇩', phoneCode: 376, value: 'Andorra',
      },
      {
        code: 'AO', map: '🇦🇴', phoneCode: 244, value: 'Angola',
      },
      {
        code: 'AI', map: '🇦🇮', phoneCode: 1264, value: 'Anguilla',
      },
      {
        code: 'AQ', map: '🇦🇶', phoneCode: 0, value: 'Antarctica',
      },
      {
        code: 'AR', map: '🇦🇷', phoneCode: 54, value: 'Argentina',
      },
      {
        code: 'AM', map: '🇦🇲', phoneCode: 374, value: 'Armenia',
      },
      {
        code: 'AW', map: '🇦🇼', phoneCode: 297, value: 'Aruba',
      },
      {
        code: 'AU', map: '🇦🇺', phoneCode: 61, value: 'Australia',
      },
      {
        code: 'AT', map: '🇦🇹', phoneCode: 43, value: 'Austria',
      },
      {
        code: 'AZ', map: '🇦🇿', phoneCode: 994, value: 'Azerbaijan',
      },
      {
        code: 'BH', map: '🇧🇭', phoneCode: 973, value: 'Bahrain',
      },
      {
        code: 'BD', map: '🇧🇩', phoneCode: 880, value: 'Bangladesh',
      },
      {
        code: 'BB', map: '🇧🇧', phoneCode: 1246, value: 'Barbados',
      },
      {
        code: 'BY', map: '🇧🇾', phoneCode: 375, value: 'Belarus',
      },
      {
        code: 'BE', map: '🇧🇪', phoneCode: 32, value: 'Belgium',
      },
      {
        code: 'BZ', map: '🇧🇿', phoneCode: 501, value: 'Belize',
      },
      {
        code: 'BJ', map: '🇧🇯', phoneCode: 229, value: 'Benin',
      },
      {
        code: 'BM', map: '🇧🇲', phoneCode: 1441, value: 'Bermuda',
      },
      {
        code: 'BT', map: '🇧🇹', phoneCode: 975, value: 'Bhutan',
      },
      {
        code: 'BO', map: '🇧🇴', phoneCode: 591, value: 'Bolivia',
      },
      {
        code: 'BW', map: '🇧🇼', phoneCode: 267, value: 'Botswana',
      },
      {
        code: 'BV', map: '🇧🇻', phoneCode: 0, value: 'Bouvet Island',
      },
      {
        code: 'BR', map: '🇧🇷', phoneCode: 55, value: 'Brazil',
      },
      {
        code: 'IO', map: '🇮🇴', phoneCode: 246, value: 'British Indian Ocean Territory',
      },
      {
        code: 'BN', map: '🇧🇳', phoneCode: 673, value: 'Brunei',
      },
      {
        code: 'BG', map: '🇧🇬', phoneCode: 359, value: 'Bulgaria',
      },
      {
        code: 'BF', map: '🇧🇫', phoneCode: 226, value: 'Burkina Faso',
      },
      {
        code: 'BI', map: '🇧🇮', phoneCode: 257, value: 'Burundi',
      },
      {
        code: 'KH', map: '🇰🇭', phoneCode: 855, value: 'Cambodia',
      },
      {
        code: 'CM', map: '🇨🇲', phoneCode: 237, value: 'Cameroon',
      },
      {
        code: 'CA', map: '🇨🇦', phoneCode: 1, value: 'Canada',
      },
      {
        code: 'CV', map: '🇨🇻', phoneCode: 238, value: 'Cape Verde',
      },
      {
        code: 'KY', map: '🇰🇾', phoneCode: 1345, value: 'Cayman Islands',
      },
      {
        code: 'CF', map: '🇨🇫', phoneCode: 236, value: 'Central African Republic',
      },
      {
        code: 'TD', map: '🇹🇩', phoneCode: 235, value: 'Chad',
      },
      {
        code: 'CL', map: '🇨🇱', phoneCode: 56, value: 'Chile',
      },
      {
        code: 'CN', map: '🇨🇳', phoneCode: 86, value: 'China',
      },
      {
        code: 'CX', map: '🇨🇽', phoneCode: 61, value: 'Christmas Island',
      },
      {
        code: 'CC', map: '🇨🇨', phoneCode: 672, value: 'Cocos (Keeling) Islands',
      },
      {
        code: 'CO', map: '🇨🇴', phoneCode: 57, value: 'Colombia',
      },
      {
        code: 'KM', map: '🇰🇲', phoneCode: 269, value: 'Comoros',
      },
      {
        code: 'CK', map: '🇨🇰', phoneCode: 682, value: 'Cook Islands',
      },
      {
        code: 'CR', map: '🇨🇷', phoneCode: 506, value: 'Costa Rica',
      },
      {
        code: 'CU', map: '🇨🇺', phoneCode: 53, value: 'Cuba',
      },
      {
        code: 'CY', map: '🇨🇾', phoneCode: 357, value: 'Cyprus',
      },
      {
        code: 'DK', map: '🇩🇰', phoneCode: 45, value: 'Denmark',
      },
      {
        code: 'DJ', map: '🇩🇯', phoneCode: 253, value: 'Djibouti',
      },
      {
        code: 'DM', map: '🇩🇲', phoneCode: 1767, value: 'Dominica',
      },
      {
        code: 'DO', map: '🇩🇴', phoneCode: 1809, value: 'Dominican Republic',
      },
      {
        code: 'EC', map: '🇪🇨', phoneCode: 593, value: 'Ecuador',
      },
      {
        code: 'EG', map: '🇪🇬', phoneCode: 20, value: 'Egypt',
      },
      {
        code: 'SV', map: '🇸🇻', phoneCode: 503, value: 'El Salvador',
      },
      {
        code: 'GQ', map: '🇬🇶', phoneCode: 240, value: 'Equatorial Guinea',
      },
      {
        code: 'ER', map: '🇪🇷', phoneCode: 291, value: 'Eritrea',
      },
      {
        code: 'EE', map: '🇪🇪', phoneCode: 372, value: 'Estonia',
      },
      {
        code: 'ET', map: '🇪🇹', phoneCode: 251, value: 'Ethiopia',
      },
      {
        code: 'FK', map: '🇫🇰', phoneCode: 500, value: 'Falkland Islands',
      },
      {
        code: 'FO', map: '🇫🇴', phoneCode: 298, value: 'Faroe Islands',
      },
      {
        code: 'FI', map: '🇫🇮', phoneCode: 358, value: 'Finland',
      },
      {
        code: 'FR', map: '🇫🇷', phoneCode: 33, value: 'France',
      },
      {
        code: 'GF', map: '🇬🇫', phoneCode: 594, value: 'French Guiana',
      },
      {
        code: 'PF', map: '🇵🇫', phoneCode: 689, value: 'French Polynesia',
      },
      {
        code: 'TF', map: '🇹🇫', phoneCode: 0, value: 'French Southern Territories',
      },
      {
        code: 'GA', map: '🇬🇦', phoneCode: 241, value: 'Gabon',
      },
      {
        code: 'GE', map: '🇬🇪', phoneCode: 995, value: 'Georgia',
      },
      {
        code: 'DE', map: '🇩🇪', phoneCode: 49, value: 'Germany',
      },
      {
        code: 'GH', map: '🇬🇭', phoneCode: 233, value: 'Ghana',
      },
      {
        code: 'GI', map: '🇬🇮', phoneCode: 350, value: 'Gibraltar',
      },
      {
        code: 'GR', map: '🇬🇷', phoneCode: 30, value: 'Greece',
      },
      {
        code: 'GL', map: '🇬🇱', phoneCode: 299, value: 'Greenland',
      },
      {
        code: 'GD', map: '🇬🇩', phoneCode: 1473, value: 'Grenada',
      },
      {
        code: 'GP', map: '🇬🇵', phoneCode: 590, value: 'Guadeloupe',
      },
      {
        code: 'GU', map: '🇬🇺', phoneCode: 1671, value: 'Guam',
      },
      {
        code: 'GT', map: '🇬🇹', phoneCode: 502, value: 'Guatemala',
      },
      {
        code: 'GN', map: '🇬🇳', phoneCode: 224, value: 'Guinea',
      },
      {
        code: 'GW', map: '🇬🇼', phoneCode: 245, value: 'Guinea-Bissau',
      },
      {
        code: 'GY', map: '🇬🇾', phoneCode: 592, value: 'Guyana',
      },
      {
        code: 'HT', map: '🇭🇹', phoneCode: 509, value: 'Haiti',
      },
      {
        code: 'HN', map: '🇭🇳', phoneCode: 504, value: 'Honduras',
      },
      {
        code: 'HU', map: '🇭🇺', phoneCode: 36, value: 'Hungary',
      },
      {
        code: 'IS', map: '🇮🇸', phoneCode: 354, value: 'Iceland',
      },
      {
        code: 'IN', map: '🇮🇳', phoneCode: 91, value: 'India',
      },
      {
        code: 'ID', map: '🇮🇩', phoneCode: 62, value: 'Indonesia',
      },
      {
        code: 'IR', map: '🇮🇷', phoneCode: 98, value: 'Iran',
      },
      {
        code: 'IQ', map: '🇮🇶', phoneCode: 964, value: 'Iraq',
      },
      {
        code: 'IE', map: '🇮🇪', phoneCode: 353, value: 'Ireland',
      },
      {
        code: 'IL', map: '🇮🇱', phoneCode: 972, value: 'Israel',
      },
      {
        code: 'IT', map: '🇮🇹', phoneCode: 39, value: 'Italy',
      },
      {
        code: 'JM', map: '🇯🇲', phoneCode: 1876, value: 'Jamaica',
      },
      {
        code: 'JP', map: '🇯🇵', phoneCode: 81, value: 'Japan',
      },
      {
        code: 'JO', map: '🇯🇴', phoneCode: 962, value: 'Jordan',
      },
      {
        code: 'KZ', map: '🇰🇿', phoneCode: 7, value: 'Kazakhstan',
      },
      {
        code: 'KE', map: '🇰🇪', phoneCode: 254, value: 'Kenya',
      },
      {
        code: 'KI', map: '🇰🇮', phoneCode: 686, value: 'Kiribati',
      },
      {
        code: 'KW', map: '🇰🇼', phoneCode: 965, value: 'Kuwait',
      },
      {
        code: 'KG', map: '🇰🇬', phoneCode: 996, value: 'Kyrgyzstan',
      },
      {
        code: 'LA', map: '🇱🇦', phoneCode: 856, value: 'Laos',
      },
      {
        code: 'LV', map: '🇱🇻', phoneCode: 371, value: 'Latvia',
      },
      {
        code: 'LB', map: '🇱🇧', phoneCode: 961, value: 'Lebanon',
      },
      {
        code: 'LS', map: '🇱🇸', phoneCode: 266, value: 'Lesotho',
      },
      {
        code: 'LR', map: '🇱🇷', phoneCode: 231, value: 'Liberia',
      },
      {
        code: 'LY', map: '🇱🇾', phoneCode: 218, value: 'Libya',
      },
      {
        code: 'LI', map: '🇱🇮', phoneCode: 423, value: 'Liechtenstein',
      },
      {
        code: 'LT', map: '🇱🇹', phoneCode: 370, value: 'Lithuania',
      },
      {
        code: 'LU', map: '🇱🇺', phoneCode: 352, value: 'Luxembourg',
      },
      {
        code: 'MK', map: '🇲🇰', phoneCode: 389, value: 'Macedonia',
      },
      {
        code: 'MG', map: '🇲🇬', phoneCode: 261, value: 'Madagascar',
      },
      {
        code: 'MW', map: '🇲🇼', phoneCode: 265, value: 'Malawi',
      },
      {
        code: 'MY', map: '🇲🇾', phoneCode: 60, value: 'Malaysia',
      },
      {
        code: 'MV', map: '🇲🇻', phoneCode: 960, value: 'Maldives',
      },
      {
        code: 'ML', map: '🇲🇱', phoneCode: 223, value: 'Mali',
      },
      {
        code: 'MT', map: '🇲🇹', phoneCode: 356, value: 'Malta',
      },
      {
        code: 'MH', map: '🇲🇭', phoneCode: 692, value: 'Marshall Islands',
      },
      {
        code: 'MQ', map: '🇲🇶', phoneCode: 596, value: 'Martinique',
      },
      {
        code: 'MR', map: '🇲🇷', phoneCode: 222, value: 'Mauritania',
      },
      {
        code: 'MU', map: '🇲🇺', phoneCode: 230, value: 'Mauritius',
      },
      {
        code: 'YT', map: '🇾🇹', phoneCode: 269, value: 'Mayotte',
      },
      {
        code: 'MX', map: '🇲🇽', phoneCode: 52, value: 'Mexico',
      },
      {
        code: 'FM', map: '🇫🇲', phoneCode: 691, value: 'Micronesia',
      },
      {
        code: 'MD', map: '🇲🇩', phoneCode: 373, value: 'Moldova',
      },
      {
        code: 'MC', map: '🇲🇨', phoneCode: 377, value: 'Monaco',
      },
      {
        code: 'MN', map: '🇲🇳', phoneCode: 976, value: 'Mongolia',
      },
      {
        code: 'MS', map: '🇲🇸', phoneCode: 1664, value: 'Montserrat',
      },
      {
        code: 'MA', map: '🇲🇦', phoneCode: 212, value: 'Morocco',
      },
      {
        code: 'MZ', map: '🇲🇿', phoneCode: 258, value: 'Mozambique',
      },
      {
        code: 'NA', map: '🇳🇦', phoneCode: 264, value: 'Namibia',
      },
      {
        code: 'NR', map: '🇳🇷', phoneCode: 674, value: 'Nauru',
      },
      {
        code: 'NP', map: '🇳🇵', phoneCode: 977, value: 'Nepal',
      },
      {
        code: 'NC', map: '🇳🇨', phoneCode: 687, value: 'New Caledonia',
      },
      {
        code: 'NZ', map: '🇳🇿', phoneCode: 64, value: 'New Zealand',
      },
      {
        code: 'NI', map: '🇳🇮', phoneCode: 505, value: 'Nicaragua',
      },
      {
        code: 'NE', map: '🇳🇪', phoneCode: 227, value: 'Niger',
      },
      {
        code: 'NG', map: '🇳🇬', phoneCode: 234, value: 'Nigeria',
      },
      {
        code: 'NU', map: '🇳🇺', phoneCode: 683, value: 'Niue',
      },
      {
        code: 'NF', map: '🇳🇫', phoneCode: 672, value: 'Norfolk Island',
      },
      {
        code: 'MP', map: '🇲🇵', phoneCode: 1670, value: 'Northern Mariana Islands',
      },
      {
        code: 'NO', map: '🇳🇴', phoneCode: 47, value: 'Norway',
      },
      {
        code: 'OM', map: '🇴🇲', phoneCode: 968, value: 'Oman',
      },
    
      {
        code: 'PW', map: '🇵🇼', phoneCode: 680, value: 'Palau',
      },
      {
        code: 'PA', map: '🇵🇦', phoneCode: 507, value: 'Panama',
      },
      {
        code: 'PY', map: '🇵🇾', phoneCode: 595, value: 'Paraguay',
      },
      {
        code: 'PE', map: '🇵🇪', phoneCode: 51, value: 'Peru',
      },
      {
        code: 'PH', map: '🇵🇭', phoneCode: 63, value: 'Philippines',
      },
      {
        code: 'PL', map: '🇵🇱', phoneCode: 48, value: 'Poland',
      },
      {
        code: 'PT', map: '🇵🇹', phoneCode: 351, value: 'Portugal',
      },
      {
        code: 'PR', map: '🇵🇷', phoneCode: 1787, value: 'Puerto Rico',
      },
      {
        code: 'QA', map: '🇶🇦', phoneCode: 974, value: 'Qatar',
      },
      {
        code: 'RO', map: '🇷🇴', phoneCode: 40, value: 'Romania',
      },
      {
        code: 'RU', map: '🇷🇺', phoneCode: 70, value: 'Russia',
      },
      {
        code: 'RW', map: '🇷🇼', phoneCode: 250, value: 'Rwanda',
      },
      {
        code: 'WS', map: '🇼🇸', phoneCode: 684, value: 'Samoa',
      },
      {
        code: 'SM', map: '🇸🇲', phoneCode: 378, value: 'San Marino',
      },
      {
        code: 'SA', map: '🇸🇦', phoneCode: 966, value: 'Saudi Arabia',
      },
      {
        code: 'SN', map: '🇸🇳', phoneCode: 221, value: 'Senegal',
      },
      {
        code: 'RS', map: '🇷🇸', phoneCode: 381, value: 'Serbia',
      },
      {
        code: 'SC', map: '🇸🇨', phoneCode: 248, value: 'Seychelles',
      },
      {
        code: 'SL', map: '🇸🇱', phoneCode: 232, value: 'Sierra Leone',
      },
      {
        code: 'SG', map: '🇸🇬', phoneCode: 65, value: 'Singapore',
      },
      {
        code: 'SK', map: '🇸🇰', phoneCode: 421, value: 'Slovakia',
      },
      {
        code: 'SI', map: '🇸🇮', phoneCode: 386, value: 'Slovenia',
      },
      {
        code: 'SB', map: '🇸🇧', phoneCode: 677, value: 'Solomon Islands',
      },
      {
        code: 'SO', map: '🇸🇴', phoneCode: 252, value: 'Somalia',
      },
      {
        code: 'ZA', map: '🇿🇦', phoneCode: 27, value: 'South Africa',
      },
      {
        code: 'SS', map: '🇸🇸', phoneCode: 211, value: 'South Sudan',
      },
      {
        code: 'ES', map: '🇪🇸', phoneCode: 34, value: 'Spain',
      },
      {
        code: 'LK', map: '🇱🇰', phoneCode: 94, value: 'Sri Lanka',
      },
      {
        code: 'SD', map: '🇸🇩', phoneCode: 249, value: 'Sudan',
      },
      {
        code: 'SR', map: '🇸🇷', phoneCode: 597, value: 'Suriname',
      },
      {
        code: 'SZ', map: '🇸🇿', phoneCode: 268, value: 'Swaziland',
      },
      {
        code: 'SE', map: '🇸🇪', phoneCode: 46, value: 'Sweden',
      },
      {
        code: 'CH', map: '🇨🇭', phoneCode: 41, value: 'Switzerland',
      },
      {
        code: 'SY', map: '🇸🇾', phoneCode: 963, value: 'Syria',
      },
      {
        code: 'TW', map: '🇹🇼', phoneCode: 886, value: 'Taiwan',
      },
      {
        code: 'TJ', map: '🇹🇯', phoneCode: 992, value: 'Tajikistan',
      },
      {
        code: 'TZ', map: '🇹🇿', phoneCode: 255, value: 'Tanzania',
      },
      {
        code: 'TH', map: '🇹🇭', phoneCode: 66, value: 'Thailand',
      },
      {
        code: 'TG', map: '🇹🇬', phoneCode: 228, value: 'Togo',
      },
      {
        code: 'TK', map: '🇹🇰', phoneCode: 690, value: 'Tokelau',
      },
      {
        code: 'TO', map: '🇹🇴', phoneCode: 676, value: 'Tonga',
      },
      {
        code: 'TN', map: '🇹🇳', phoneCode: 216, value: 'Tunisia',
      },
      {
        code: 'TR', map: '🇹🇷', phoneCode: 90, value: 'Turkey',
      },
      {
        code: 'TM', map: '🇹🇲', phoneCode: 7370, value: 'Turkmenistan',
      },
      {
        code: 'TV', map: '🇹🇻', phoneCode: 688, value: 'Tuvalu',
      },
      {
        code: 'UG', map: '🇺🇬', phoneCode: 256, value: 'Uganda',
      },
      {
        code: 'UA', map: '🇺🇦', phoneCode: 380, value: 'Ukraine',
      },
      {
        code: 'AE', map: '🇦🇪', phoneCode: 971, value: 'United Arab Emirates',
      },
      {
        code: 'GB', map: '🇬🇧', phoneCode: 44, value: 'United Kingdom',
      },
      {
        code: 'US', map: '🇺🇸', phoneCode: 1, value: 'United States',
      },
      {
        code: 'UY', map: '🇺🇾', phoneCode: 598, value: 'Uruguay',
      },
      {
        code: 'UZ', map: '🇺🇿', phoneCode: 998, value: 'Uzbekistan',
      },
      {
        code: 'VU', map: '🇻🇺', phoneCode: 678, value: 'Vanuatu',
      },
      {
        code: 'VE', map: '🇻🇪', phoneCode: 58, value: 'Venezuela',
      },
      {
        code: 'VN', map: '🇻🇳', phoneCode: 84, value: 'Vietnam',
      },
      {
        code: 'EH', map: '🇪🇭', phoneCode: 212, value: 'Western Sahara',
      },
      {
        code: 'YE', map: '🇾🇪', phoneCode: 967, value: 'Yemen',
      },
      {
        code: 'ZM', map: '🇿🇲', phoneCode: 260, value: 'Zambia',
      },
      {
        code: 'ZW', map: '🇿🇼', phoneCode: 26, value: 'Zimbabwe',
      }])
    

    useEffect(() =>{        window.scrollTo(0, 0)

if(! JSON.parse(localStorage.getItem("User"))){

        props.match.history.push("/login")
      }
      else{
        
        localStorage.setItem("VenderWorkingThose" ,JSON.stringify(123)) 
        setUser1(JSON.parse(localStorage.getItem("User")))
        setNum(JSON.parse(localStorage.getItem("the")))

        let Num1 = JSON.parse(localStorage.getItem("the"))
  if(Num1 === 1){
    // setemail1("https://wwww.instagram.com/")
    setImage("https://res.cloudinary.com/freedeveloperss123/image/upload/v1656789703/f_instagram_mtrtw3.svg")
    // setImage("https://res.cloudinary.com/freedeveloperss123/image/upload/v1637006299/a1_b0fs4u.png")
}
else if(Num1 === 2){
    // setemail1("https://wwww.snapchat.com/")
    setImage("https://res.cloudinary.com/freedeveloperss123/image/upload/v1656789708/snapchat_nttuow.svg")
    // setImage("https://res.cloudinary.com/freedeveloperss123/image/upload/v1637006298/a2_wvv3wp.png")
  }
  else if(Num1 === 3){
    setImage("https://res.cloudinary.com/freedeveloperss123/image/upload/v1656789708/tiktok_mzgu59.svg")
    // setImage("https://res.cloudinary.com/freedeveloperss123/image/upload/v1637006298/a3_ovvxlp.png")
    // setemail1("https://wwww.tiktok.com/")
  }
  else if(Num1 === 4){
    setImage("https://res.cloudinary.com/freedeveloperss123/image/upload/v1656789696/f_facebook_a2qqie.svg")
    // setImage("https://res.cloudinary.com/freedeveloperss123/image/upload/v1637006298/a4_ffszmd.png")
    // setemail1("https://wwww.facebook.com/")
  }
  else if(Num1 === 5){
    setImage("https://res.cloudinary.com/freedeveloperss123/image/upload/v1656789707/f_linkedin_exzm60.svg")
    // setImage("https://res.cloudinary.com/freedeveloperss123/image/upload/v1637006298/a5_h5z3gv.png")
    // setemail1("https://wwww.linkedin.com/")
  }
  else if(Num1 === 6){
    setImage("https://res.cloudinary.com/freedeveloperss123/image/upload/v1656789708/f_twitter_z1tadf.svg")
    // setImage("https://res.cloudinary.com/freedeveloperss123/image/upload/v1637006298/a6_sznkks.png")
    // setemail1("https://wwww.twitter.com/")
  }
  else if(Num1  === 7) {
    setImage("https://res.cloudinary.com/freedeveloperss123/image/upload/v1656789709/youtube_2_qoffko.svg")
    // setImage("https://res.cloudinary.com/freedeveloperss123/image/upload/v1637006299/a7_sn28dl.png")
    // setemail1("https://wwww.youtube.com/")
  }
  else if(Num1 === 8){
    setImage("https://res.cloudinary.com/freedeveloperss123/image/upload/v1656789709/twitch_1_u9bz7p.svg")
    // setImage("https://res.cloudinary.com/freedeveloperss123/image/upload/v1637006299/a8_c12iof.png")
    // setemail1("https://wwww.twitch.com/")
  }
  else if(Num1 === 9){
    setImage("https://res.cloudinary.com/freedeveloperss123/image/upload/v1656789707/f_pinterest_cafcgg.svg")
    // setImage("https://res.cloudinary.com/freedeveloperss123/image/upload/v1637006299/a9_wxopbn.png")
    // setemail1("https://wwww.pinterest.com/")
  }
  else if(Num1 === 10){
    setImage("https://res.cloudinary.com/freedeveloperss123/image/upload/v1656789713/cashapp_wrqnu8.svg")
    // setImage("https://res.cloudinary.com/freedeveloperss123/image/upload/v1637006299/z1_vzupbq.png")
    // setemail1("https://wwww.cashapp.com/")
  }
  else if(Num1 === 11){
    setImage("https://res.cloudinary.com/freedeveloperss123/image/upload/v1656789713/paypal_1_kcbxac.svg")
    // setImage("https://res.cloudinary.com/freedeveloperss123/image/upload/v1637006299/z2_erux28.png")
    // setemail1("https://wwww.paypal.com/")
  }
  else if(Num1 === 12){
    setImage("https://res.cloudinary.com/freedeveloperss123/image/upload/v1656789713/venmo_e2bbs2.svg")
    // setImage("https://res.cloudinary.com/freedeveloperss123/image/upload/v1637006299/z3_uqrg2d.png")
    // setemail1("https://wwww.Venmo.com/")
  }



  else{
    
  }
      }

  
    },[])
    useEffect( () => () => localStorage.removeItem("VenderWorkingThose"), [] );


    const setChange = () =>{
        if(document.getElementById("get2").style.display === "none"){
            document.getElementById("get1").classList.add("is-active");
            document.getElementById("get2").style.display = "block";
        }
        else{
            document.getElementById("get1").classList.remove("is-active");
            document.getElementById("get2").style.display = "none";
        }
    }

    const setChange1 = (e) =>{
      if(e === 1 ){
        // document.getElementById("signUp").classList.add("right-panel-active");
        // document.getElementById("signIn").classList.remove("right-panel-active");
        document.getElementById("container1").classList.add("right-panel-active")
      }
      else{
        document.getElementById("container1").classList.remove("right-panel-active")
      }
    }


    const setRouting1 = (e)=>{
      if(e === 1){
        if(JSON.parse(localStorage.getItem("User")).DisPersonal)
        props.history.push("/user/home1/"+User1._id)
        else 
        props.history.push("/user/bussiness/home1/"+User1._id)
      }
      else if(e === 4){
        if(JSON.parse(localStorage.getItem("User")).DisPersonal)
        props.history.push("/user/editProfile/sort-delete/"+User1.uid)
        else
        props.history.push("/user/bussiness/editProfile/sort-delete/"+User1.uid)
      }
      else if(e === 3){
        // props.history.push("/user/analytics/"+User1._id)   
    
        const d =  JSON.parse(localStorage.getItem("User")).ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
        console.log(d)
        
          
        if (d.length > 0){
          let a = new Date()
          let f= a.getDate() +" "+(a.getUTCMonth()+1)+ " "+a.getUTCFullYear()
          console.log(d[0].End[0]+""+d[0].End[1] >= a.getDate(), d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ,d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear())
          if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] > a.getUTCFullYear()     ){
          // if(d[0].End && d[0].End >= f){
        props.history.push("/user/analytics/"+User1._id)   
    
          }
          else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear()){
            if( d[0].End[3]+""+d[0].End[4] >a.getUTCMonth()+1 ){
          props.history.push("/user/analytics/"+User1._id)   
    
            }
            else{
              if(d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1){
                if( d[0].End[0]+""+d[0].End[1] >=a.getDate()){
              props.history.push("/user/analytics/"+User1._id)   
    
                }
                else{
                  document.getElementById("myDivUmer12").style.display= "block"
    
                  // localStorage.setItem("request123",JSON.stringify(1) )
                  // props.history.push("/user/request-for-pro-item")
                  // swal("Sorry First You Buy")
      
                }
              }
              else{
                document.getElementById("myDivUmer12").style.display= "block"
    
                // localStorage.setItem("request123",JSON.stringify(1) )
                //   props.history.push("/user/request-for-pro-item")
                //   swal("Sorry First You Buy")
      
                }
              }
            }
          else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear() && d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ){
              if( d[0].End[0]+""+d[0].End[1] >=a.getDate()){
            props.history.push("/user/analytics/"+User1._id)   
    
            }
            else{
              document.getElementById("myDivUmer12").style.display= "block"
    
              // localStorage.setItem("request123",JSON.stringify(1) )
              // props.history.push("/user/request-for-pro-item")
              // swal("Sorry First You Buy")
      
            }
          }
          else{
            document.getElementById("myDivUmer12").style.display= "block"
    
            // localStorage.setItem("request123",JSON.stringify(1) )
            //   props.history.push("/user/request-for-pro-item")
            //   swal("Sorry First You Buy")
      
          }
        }
        else{
          document.getElementById("myDivUmer12").style.display= "block"
    
          // localStorage.setItem("request123",JSON.stringify(1) )
          //   props.history.push("/user/request-for-pro-item")
          //   swal("Sorry First You Buy")
      }
      }
      else if(e === 2){


        const d =  JSON.parse(localStorage.getItem("User")).ProFeather.filter((res,i)=> res.Name === "Pro Feathers")
        console.log(d)
        
          
        if (d.length > 0){
          let a = new Date()
          let f= a.getDate() +" "+(a.getUTCMonth()+1)+ " "+a.getUTCFullYear()
          console.log(d[0].End[0]+""+d[0].End[1] >= a.getDate(), d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ,d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear())
          if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] > a.getUTCFullYear()     ){
          // if(d[0].End && d[0].End >= f){
           
          }
          else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear()){
            if( d[0].End[3]+""+d[0].End[4] >a.getUTCMonth()+1 ){
              props.history.push("/user/connect/myprofile/"+User1.uid)
    
            }
            else{
              if(d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1){
                if( d[0].End[0]+""+d[0].End[1]>=a.getDate()){
                  props.history.push("/user/connect/myprofile/"+User1.uid)
    
                }
                else{
                  document.getElementById("myDivUmer").style.display= "block"
    
                  // localStorage.setItem("request123",JSON.stringify(1) )
                  // props.history.push("/user/request-for-pro-item")
                  // swal("Sorry First You Buy")
      
                }
              }
              else{
                document.getElementById("myDivUmer").style.display= "block"
    
                // localStorage.setItem("request123",JSON.stringify(1) )
                //   props.history.push("/user/request-for-pro-item")
                //   swal("Sorry First You Buy")
      
                }
              }
            }
          else if(d[0].End[6]+""+d[0].End[7]+""+d[0].End[8]+""+d[0].End[9] >= a.getUTCFullYear() && d[0].End[3]+""+d[0].End[4] >=a.getUTCMonth()+1 ){
              if( d[0].End[0]+""+d[0].End[1]>=a.getDate()){
                props.history.push("/user/connect/myprofile/"+User1.uid)
    
            }
            else{
              document.getElementById("myDivUmer").style.display= "block"
    
              // localStorage.setItem("request123",JSON.stringify(1) )
              // props.history.push("/user/request-for-pro-item")
              // swal("Sorry First You Buy")
      
            }
          }
          else{
            document.getElementById("myDivUmer").style.display= "block"
    
            // localStorage.setItem("request123",JSON.stringify(1) )
            //   props.history.push("/user/request-for-pro-item")
            //   swal("Sorry First You Buy")
      
          }
        }
        else{
          document.getElementById("myDivUmer").style.display= "block"
    
          // localStorage.setItem("request123",JSON.stringify(1) )
          //   props.history.push("/user/request-for-pro-item")
          //   swal("Sorry First You Buy")
      }
      }
      else{
    
      }
    }
        

    const setChanger8 = (e)=>{
      document.getElementById("myDivUmer12").style.display= "block"
    }
const SubmitDataMessage = (e) =>{
  e.preventDefault()
  setdispalyBut(false)

  // console.log("wddde");
  let x;
  if(Num === 1){
    x = "https://wwww.instagram.com/"
}
else if(Num === 2){
    x = "https://wwww.snapchat.com/add/"
  }
  else if(Num === 3){
    x = "https://wwww.tiktok.com/@"
  }
  else if(Num === 4){
    x = "https://wwww.facebook.com/"
  }
  else if(Num === 5){
    x = "https://wwww.linkedin.com/"
  }
  else if(Num === 6){
    x = "https://wwww.twitter.com/"
  }
  else if(Num  === 7) {
    x = "https://wwww.youtube.com/"
  }
  else if(Num === 8){
    x = "https://wwww.twitch.com/"
  }
  else if(Num === 9){
    x = "https://wwww.pinterest.com/"
  }
  else if(Num === 10){
    x = "https://wwww.cashapp.com/"
  }
  else if(Num === 11){
    x = "https://wwww.paypal.com/"
  }
  else if(Num === 12){
    x = "https://wwww.Venmo.com/"
  }

  else{
    
  }
  let f
  if(Num === 1){
    f = "instagram"
  }
  else if(Num === 2){
    f = "snapchat"
  }
  else if(Num === 3){
    f = "tiktok"
  }
  else if(Num === 4){
    f = "facebook"
  }
  else if(Num === 5){
    f = "linkedin"
  }
  else if(Num === 6){
    f = "twitter"
  }
  else if(Num  === 7) {
    f = "youtube"
  }
  else if(Num === 8){
    f = "twitch"
  }
  else if(Num === 9){
    f = "pinterest"
  }
  else if(Num === 10){
    f = "cash app"
  }
  else if(Num === 11){
    f = "paypal"
  }
  else if(Num === 12){
    f = "Venmo"
  }
  else if(Num === 13){
    f = "website"
  }
  else if(Num === 99){
    f = "Address"
  }
  else if(Num === 21){
    f = "text"
  }
  else if(Num === 22){
    f = "whatsapps"
  }
  else if(Num === 24){
    f = "email"
  }
  else if(Num === 25){
    f = "phone"
  }
  else if(Num === 33){
    f = "Custom Link"
  }
  else{
    
  }
  if(email === "" && email1 === ""){
    swal("Link are Missing")
    setdispalyBut(true)

          setTimeout(()=>{
          swal.close()
                 },1000)
    return
  }
  
  

    setdispaly ( false)
    let c
    if(disUser){
      c = x +""+ email1
    }
    else{
      c = email
    }
      console.log(Name ,email1 , x , f );

    // fetch("https://tapitdabit-backend.vercel.app/createhumareLink",{
    //                                       method: "POST",
    //                                       headers :  {
    //                                           "Content-Type" : "application/json" , 
    //                                       } ,
    //                                       body : JSON.stringify({
    //                                         name  : f,
    //                                         Name : c,
    //                                         link : c,
    //                                         id : User1._id
    //                                           // MainLine2 :mainline ,
    //                                       })
    //                                   })
    //                                   .then(res11=>res11.json())
    //                                   .then((res12)=>{ 
                                        
    //                                       if(!res12.Error){
    //                                           swal("Successfully Upload your Links!");
    //                                           props.history.push("/user/home/"+User1._id)
    //                                           this.setState({
    //                                               email: "",
    //                                       })
    //                                     }
    //                                     else{
    //                                     swal(res12.Error);
                                              
    //                                   }
    //                               })
    //                               .catch(err=>{
    //                                 swal("Successfully Upload your Links!");
    //                               }) 


                                

}




const SubmitDataMessage1 = (e) =>{
    e.preventDefault()
    console.log(Image,Name,email)
  setdispaly ( false)
  setdispalyBut(false)

  // console.log("wddde");
  let x;
  if(Num === 1){
    x = "https://www.instagram.com/"
}
else if(Num === 2){
    x = "https://www.snapchat.com/add/"
  }
  else if(Num === 3){
    x = "https://www.tiktok.com/@"
  }
  else if(Num === 4){
    x = "https://www.facebook.com/"
  }
  else if(Num === 5){
    x = "https://www.linkedin.com/"
  }
  else if(Num === 6){
    x = "https://www.twitter.com/"
  }
  else if(Num  === 7) {
    x = "https://www.youtube.com/"
  }
  else if(Num === 8){
    x = "https://www.twitch.com/"
  }
  else if(Num === 9){
    x = "https://www.pinterest.com/"
  }
  else if(Num === 10){
    x = "https://www.cashapp.com/"
  }
  else if(Num === 11){
    x = "https://www.paypal.com/"
  }
  else if(Num === 12){
    x = "https://www.Venmo.com/"
  }
  else{

  }
  let f
if(Num === 1){
    f = "instagram"
  }
  else if(Num === 2){
    f = "snapchat"
  }
  else if(Num === 3){
    f = "tiktok"
  }
  else if(Num === 4){
    f = "facebook"
  }
  else if(Num === 5){
    f = "linkedin"
  }
  else if(Num === 6){
    f = "twitter"
  }
  else if(Num  === 7) {
    f = "youtube"
  }
  else if(Num === 8){
    f = "twitch"
  }
  else if(Num === 9){
    f = "pinterest"
  }
  else if(Num === 10){
    f = "cash app"
  }
  else if(Num === 11){
    f = "paypal"
  }
  else if(Num === 12){
    f = "Venmo"
  }
  else if(Num === 13){
    f = "website"
  }
  else if(Num === 99){
    f = "Address"
  }
  else if(Num === 21){
    f = "text"
  }
  else if(Num === 22){
    f = "whatsapps"
  }
  else if(Num === 24){
    f = "email"
  }
  else if(Num === 25){
    f = "phone"
  }
  else if(Num === 33){
    f = "Custom Link"
  }
  else{
    
  }
  if(email === "" && email1 === ""){
    setdispaly ( true)

    swal("Link are Missing")
    setdispalyBut(true)

          setTimeout(()=>{
          swal.close()
                 },1000)
    return
  }
  if(Name === ""){
    setdispaly ( true)

    swal("Name are Missing")
    setdispalyBut(true)

    setTimeout(()=>{
      swal.close()
             },1000)
    return
  }
 

  setdispaly ( false)
  let c
  let bg

 if(disUser && email1 !== ""){
    if(Num === 13){
      c = x + email1 + ".com"
      bg = "user"
    }
    else{
      c = x + email1
      bg = "user"
    }
  }
  else{
    c = email
    bg = "link"
  }

  if(disUser){
    c = x + email1
    bg = "user"

  }
  else{
    c = email
    bg = "link"

  }
 
  // if( f === "youtube" || f === "twitter" ){
  if(f === "facebook" || f === "linkedin" || f === "youtube" || f === "twitter" ){
    c = email
    bg = "link"
  }
  if(f === "website"  ){
    console.log(email)
    if(email[0]+""+email[1]+""+email[2]+""+email[3]+""+email[4]+""+email[5]+""+email[6]+""+email[7]!== "https://"){
      console.log("the1")
      c = "https://"+email
    }
  }
  if(f === "phone" || f === "text" || f === "whatsapps" ){
    if(UserPhoneCode !== null){
      c = "+"+UserPhoneCode+email
      console.log(c)
    }
      else {
        setdispaly(true)
        swal("select Country Code")
        setdispalyBut(true)

        setTimeout(()=>{
          swal.close()
                 },1000)
        return
      }

  }
  // console.log(Name ,email,c , x , f );
  console.log(Name ,email,"def",c ,"ii", x ,"uiuoui", f );

                              fetch("https://tapitdabit-backend.vercel.app/createhumareLink1",{
                                          method: "POST",
                                          headers :  {
                                              "Content-Type" : "application/json" , 
                                          } ,
                                          body : JSON.stringify({
                                            name  : f,
                                            photo : Image,
                                            Name ,
                                            link : c,
                                            user : bg,

                                            id : User1._id
                                              // MainLine2 :mainline ,
                                          })
                                      })
                                      .then(res11=>res11.json())
                                      .then((res12)=>{ 
                                          if(!res12.Error){
                                            setdispaly ( true)
                                            setdispalyBut(true)

                                              swal("Successfully Upload your Product!", {
                                              buttons: false,
                                            });
                                                    setTimeout(()=>{
          swal.close()
                 },1000)
                                              setTimeout(()=>{

                                                props.history.push("/user/home/"+User1._id)
                                              },1500)
                                              this.setState({
                                                  email: "",
                                          })
                                        }
                                        else{
                                          setdispaly ( true)
                                          setdispalyBut(true)

                                        swal(res12.Error);
                                             
                                      }
                                  })
                                  .catch(err=>{
                                    setdispaly ( true)
                                    setdispalyBut(true)

                                    swal("Successfully Upload your Product!", {
                                              buttons: false,
                                            });
                                          setTimeout(()=>{
          swal.close()
                 },1000)
                                  }) 
                                  
}



const setImage123 = (e)=>{
    setdispaly1(false)
    console.log(e)
    const data = new FormData()
    data.append("file", e)
    data.append("upload_preset", "commerce")
    data.append("cloud_name", "freedeveloperss123")
    fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
        method : "POST",
        body : data , 
    })
    .then(res=>res.json())
    .then((res2)=>{
      setImage(res2.url)
      document.getElementById("photoImage1").src = res2.url
      setdispaly1(true)
      setdispaly2(true)
        
    })
  }



  const setDis123 = (e) =>{
    if(e === 1){
        setdisUser1(false)
        setdisUser(true)
    }
    else{
        setdisUser(false)
        setdisUser1(true)

    }
  }
  const setRoter123 = ()=>{
    localStorage.setItem("request123",JSON.stringify(1) )
        props.history.push("/user/request-for-pro-item")
  
  }
  
  const setChanger1 = (e)=>{
    document.getElementById("myDiv1234").style.display= "none"
  }
  const setChanger2 = (e)=>{
    document.getElementById("myDivUmer").style.display= "none"
  }
  
        return (
            






      <div 
        // onScroll={onScrolll()}
        >
       
        {/* Slider */}
        <div id="myDivUmer12">
                {/* <img src={guides} alt=""  id="myDiv23456"/> */}
                <center>
                  <h1>
                    Analytics
                </h1>
                  </center>
                <center><p>
                Unlock all links with Pro to see insights of your account. <br />
Tap Buy now  to upgrade.
          </p></center>
                  <center>
                  <button class="btn btn-dark F23 f45" onClick={()=>setRoter123()}>Buy Now </button>
                  </center>
                <ClearIcon id="myDiv3456767"  style={{color : "black",fontSize : "30px",position : "absolute" , right: "10%",top : "10%" , zIndex: "2"}} onClick={()=>setChanger8()}/>
            </div> 
        <div id="myDivUmer">
                {/* <img src={guides} alt=""  id="myDiv23456"/> */}
                <center>
                  <h1>
                     Build Your Network
                </h1>
                  </center>
                <center><p>
Capture leads using Popl and favorite, group, sort and manage them all from here. Start building your connections now                  </p></center>
                  <center>
                  <button class="btn btn-dark F23 f45" onClick={()=>setRoter123()}>Buy Now </button>
                  </center>
                <ClearIcon id="myDiv34567"  style={{color : "black",fontSize : "30px",position : "absolute" , right: "10%",top : "10%" , zIndex: "2"}} onClick={()=>setChanger2()}/>
            </div> 



      


        {!dispaly1
?
<center style={{position : "absolute" }} className="load-123">
  <Loader
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={3000} //3 secs
      />
</center>
:""
}


{Num === 1 ? 

<>

<br/>



<center>
  <div class="image-upload mi2" style={{margin : "70px auto 0px auto"}}>
                                <label for="file-input">
                                    <img src={nu1} style={{ width:"133px", fontSize : "30px",borderRadius : "15px"}}/>
                                </label>

                                <input id="file-input" type="file" style={{width :  "0px"}}  onChange={(e)=>setImage123(e.target.files[0])}/>
                                </div>   
</center>
<br/>


              <section>
                    <div className="container" style={{margin : "0px auto" , width : "97%"}}>
                            <div className="row justify-content-center">
                               

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={in1} id="photoImage1" alt="" className="arrival-Photo111 arrival-P"/></Link>
                                            </div>
                                            
                                {/* <button style={{position:"absolute",heught : "100px",right:"43%"}} className="yh2">
                                    <input type="file"  visbility="hidden" onChange={(e)=>setImage123(e.target.files[0])} style={{ width:"125px", fontSize : "30px"}} accept={nu1} /> 
                                    <img src={nu1} alt="Mountain"/>
                                     <span>Customize Icon</span> 
                                </button> */}
                                {/* <button style={{position:"absolute",heught : "100px",right:"43%"}} className="yh2">
                                    <input type="file"  visbility="hidden" onChange={(e)=>setImage123(e.target.files[0])} style={{ width:"125px", fontSize : "30px"}} accept={nu1} /> 

                                </button> */}

                                                                {/* <div class="image-upload" style={{ width:"1px",position:"absolute",heught : "100px",right:"43%"}} className="yh2">
                                <label for="file-input">
                                    <img src={nu1} style={{ width:"133px", fontSize : "30px",borderRadius : "15px"}}/>
                                </label>

                                <input id="file-input" type="file" style={{width :  "0px"}}  onChange={(e)=>setImage123(e.target.files[0])}/>
                                </div>                         */}
                               
                            </div>
                            </div>        <center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>Instagram</p>
</center>
                </section>

           <center>
           <div class="col-xl-5 col-lg-6 col-md-8 col-sm-12" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage1(e)}>
                   
                <input className="input11" type="text"   value={Name} onChange={(e)=>setName(e.target.value)}  name="name" placeholder="Enter Title" /><br/>


                 {/* <div style={{display : "flex" , justifyContent : "center"}}>
                        <span className="button11" onClick={()=>setDis123(1)} style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" ,width :  "90px", color  : "black"  ,marginRight : "3px", fontSize : "16px"}}>Username</span>
                    </div> */}
                    
                    {/* <br /> */}

                  {/* <div style={{display : "flex" , justifyContent : "center"}}> */}




                  {/* <div className="container-fuild">
                            <div className="row justify-content-center">
                               
                  <div class="col-xl-5 col-lg-6 col-md-8 col-sm-2">
 <span  onClick={()=>setDis123(1)} style={{borderRadius : "50px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" ,width :  "90px", color  : "black"  ,marginRight : "3px", fontSize : "16px"}}>Username</span>
 </div> 
 {disUser ? 

                  <div class="col-xl-5 col-lg-6 col-md-8 col-sm-10">
 <input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>
                    </div>

                 : ""
                    }
                      
                      </div>
                      </div> */}
                      

  <div style={{display : "flex" , justifyContent : "flexLeft"}}>

 <span  onClick={()=>setDis123(1)} style={{borderRadius : "10px",padding: "4px 0px",height: "38px",marginTop: "8px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" ,width : "94px",marginRight : "3px", color  : "black"  , fontSize : "16px"}}>Username</span>
 {disUser ? 
<>
 <input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>

</>
                 : ""
                    }
                      
                  
         </div>


                      
                       {/* <>
                       <input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>
                      </> */}
                        
<br />

                 {/* <div style={{display : "flex" , justifyContent : "flexLeft"}}>
                        <span onClick={()=>setDis123(2)} style={{borderRadius : "10px",padding: "4px 0px",height: "38px",marginTop: "8px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" ,width : "74px",marginRight : "3px", color  : "black"  , fontSize : "16px"}}>Url</span>
                    {disUser1 ? 
                      <><input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here" /><br/></>
                      : ""
                    }
                    </div> */}
                    
                   


<br />








                    {/* <input className="input11" type="file"    onChange={(e)=>setImage(e.target.files[0])}  name="name" placeholder="Enter Link Here" /><br/> */}
                    {/* <input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here" /><br/> */}
                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        {/* <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px", border : "1px solid white",  background : "rgb(0, 0, 0)" ,  color  : "white", fontSize : "12px"}}>Save</button> */}
                        {dispalyBut ? 

<button className="button11" type="submit" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
:
<span className="button11" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</span>


}
                    </div>
                    <br/>
                </form>
            </div>
          
            {/* <div class="col-xl-5 col-lg-6 col-md-8 col-sm-12" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage(e)}>
                  
             
                    { disUser || disUser1 ? 
                    ""
                      : <div style={{display : "flex" , justifyContent : "space-between"}}>
                        <button className="button11" onClick={()=>setdisUser(true)} style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" ,width :  "90px", color  : "black"  ,marginRight : "3px", fontSize : "16px"}}>Username</button>
                        <button className="button11" onClick={()=>setdisUser1(true)}  style={{borderRadius : "50px",width : "190px", border : "1px solid white",  background : "rgb(0, 0, 0)" ,  color  : "white", fontSize : "12px"}}>Link</button>
                    </div>
                    }
                    {disUser ? 
                      <><input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/></>
                      : ""
                    }
                    {disUser1 ? 
                      <><input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here" /><br/></>
                      : ""
                    }
                    
                    
                    <br />
                    <div style={{display : "flex" , justifyContent : "space-between"}}>
                        <button className="button11" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "16px"}}>Another Link</button>
                        <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px", border : "1px solid white",  background : "rgb(0, 0, 0)" ,  color  : "white", fontSize : "12px"}}>Save</button>
                    </div>
                    <br/>
                </form>
            </div> */}
           </center>
           </>
           :""
}

{Num === 2 ? 

<>
<br/>




<center>
  <div class="image-upload mi2" style={{margin : "70px auto 0px auto"}}>
                                <label for="file-input">
                                    <img src={nu1} style={{ width:"133px", fontSize : "30px",borderRadius : "15px"}}/>
                                </label>

                                <input id="file-input" type="file" style={{width :  "0px"}}  onChange={(e)=>setImage123(e.target.files[0])}/>
                                </div>   
</center>



              <section>
                    <div className="container" style={{margin : "0px auto" , width : "97%"}}>
                            <div className="row justify-content-center">
                                {/* <div className="col-12 text-center">
                                    <h2 style={{color : "black"}} > <b>SnapChat </b> </h2> */}
                                    
                                {/* </div>  */}

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={sc1} alt=""  id="photoImage1" className="arrival-Photo111  arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div>       <center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>SnapChat</p>
</center>
                </section>

           <center>
          
           <div class="col-xl-5 col-lg-6 col-md-8 col-sm-12" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage1(e)}>
                   
                <input className="input11" type="text"   value={Name} onChange={(e)=>setName(e.target.value)}  name="name" placeholder="Enter Title" /><br/>


                          

  <div style={{display : "flex" , justifyContent : "flexLeft"}}>

  <span  onClick={()=>setDis123(1)} style={{borderRadius : "10px",padding: "4px 0px",height: "38px",marginTop: "8px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" ,width : "94px",marginRight : "3px", color  : "black"  , fontSize : "16px"}}>Username</span>
{disUser ? 
<>
<input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>

</>
                : ""
                   }
                     
                 
        </div>


                     
                      {/* <>
                      <input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>
                     </> */}
                       
<br />

                {/* <div style={{display : "flex" , justifyContent : "flexLeft"}}>
                <span onClick={()=>setDis123(2)} style={{borderRadius : "10px",padding: "4px 0px",height: "38px",marginTop: "8px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" ,width : "74px",marginRight : "3px", color  : "black"  , fontSize : "16px"}}>Url</span>
                   {disUser1 ? 
                     <><input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here" /><br/></>
                     : ""
                   }
                   </div> */}


{/* <br /> */}








                    {/* <input className="input11" type="file"    onChange={(e)=>setImage(e.target.files[0])}  name="name" placeholder="Enter Link Here" /><br/> */}
                    {/* <input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here" /><br/> */}
                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        {/* <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px", border : "1px solid white",  background : "rgb(0, 0, 0)" ,  color  : "white", fontSize : "12px"}}>Save</button> */}
                        {dispalyBut ? 

<button className="button11" type="submit" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
:
<span className="button11" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</span>


}
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}

{Num === 3 ? 

<>
<br/>




<center>
  <div class="image-upload mi2" style={{margin : "70px auto 0px auto"}}>
                                <label for="file-input">
                                    <img src={nu1} style={{ width:"133px", fontSize : "30px",borderRadius : "15px"}}/>
                                </label>

                                <input id="file-input" type="file" style={{width :  "0px"}}  onChange={(e)=>setImage123(e.target.files[0])}/>
                                </div>   
</center>



              <section>
                    <div className="container" style={{margin : "0px auto" , width : "97%"}}>
                            <div className="row justify-content-center">
                                {/* <div className="col-12 text-center">
                                    <h2 style={{color : "black"}} > <b>TikTok </b> </h2> */}
                                    
                                {/* </div>  */}

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={tk1} alt=""  id="photoImage1" className="arrival-Photo111  arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div>            <center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>TikTok</p>
</center>
                </section>

           <center>
          
            <div class="col-xl-5 col-lg-6 col-md-8 col-sm-12" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage1(e)}>
                   
                <input className="input11" type="text"   value={Name} onChange={(e)=>setName(e.target.value)}  name="name" placeholder="Enter Title" /><br/>


                          

  <div style={{display : "flex" , justifyContent : "flexLeft"}}>

 <span  onClick={()=>setDis123(1)} style={{borderRadius : "10px",padding: "4px 0px",height: "38px",marginTop: "8px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" ,width : "94px",marginRight : "3px", color  : "black"  , fontSize : "16px"}}>Username</span>
 {disUser ? 
<>
 <input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>

</>
                 : ""
                    }
                      
                  
         </div>


                      
                       {/* <>
                       <input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>
                      </> */}
                        
<br />

                 {/* <div style={{display : "flex" , justifyContent : "flexLeft"}}>
                        <span onClick={()=>setDis123(2)} style={{borderRadius : "10px",padding: "4px 0px",height: "38px",marginTop: "8px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" ,width : "74px",marginRight : "3px", color  : "black"  , fontSize : "16px"}}>Url</span>
                    {disUser1 ? 
                      <><input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here" /><br/></>
                      : ""
                    }
                    </div> */}


{/* <br /> */}








                    {/* <input className="input11" type="file"    onChange={(e)=>setImage(e.target.files[0])}  name="name" placeholder="Enter Link Here" /><br/> */}
                    {/* <input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here" /><br/> */}
                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        {/* <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px", border : "1px solid white",  background : "rgb(0, 0, 0)" ,  color  : "white", fontSize : "12px"}}>Save</button> */}
                        {dispalyBut ? 

<button className="button11" type="submit" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
:
<span className="button11" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</span>


}
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}

{Num === 4 ? 

<>
<br/>




<center>
  <div class="image-upload mi2" style={{margin : "70px auto 0px auto"}}>
                                <label for="file-input">
                                    <img src={nu1} style={{ width:"133px", fontSize : "30px",borderRadius : "15px"}}/>
                                </label>

                                <input id="file-input" type="file" style={{width :  "0px"}}  onChange={(e)=>setImage123(e.target.files[0])}/>
                                </div>   
</center>



              <section>
                    <div className="container" style={{margin : "0px auto" , width : "97%"}}>
                            <div className="row justify-content-center">
                                {/* <div className="col-12 text-center">
                                    <h2 style={{color : "black"}} > <b>Facebook */}
                                     {/* </b> </h2> */}
                                    {/*  */}
                                {/* </div>  */}

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={fb1} alt=""  id="photoImage1" className="arrival-Photo111  arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div>     <center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>Facebook</p>
</center>
                </section>

           <center>
          
            <div class="col-xl-5 col-lg-6 col-md-8 col-sm-12" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage1(e)}>
                   
                <input className="input11" type="text"   value={Name} onChange={(e)=>setName(e.target.value)}  name="name" placeholder="Enter Title" /><br/>


                          

  {/* <div style={{display : "flex" , justifyContent : "flexLeft"}}>

 <span  onClick={()=>setDis123(1)} style={{borderRadius : "10px",padding: "4px 0px",height: "38px",marginTop: "8px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" ,width : "94px",marginRight : "3px", color  : "black"  , fontSize : "16px"}}>Username</span>
 {disUser ? 
<>
 <input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>

</>
                 : ""
                    }
                      
                  
         </div> */}


                      
                       {/* <>
                       <input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>
                      </> */}
                        
<br />

                 <div style={{display : "flex" , justifyContent : "flexLeft"}}>
                        <span onClick={()=>setDis123(2)} style={{borderRadius : "10px",padding: "4px 0px",height: "38px",marginTop: "8px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" ,width : "74px",marginRight : "3px", color  : "black"  , fontSize : "16px"}}>Url</span>
                    {disUser ? 
                    // {disUser1 ? 
                      <><input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here" /><br/></>
                      : ""
                    }
                    </div>


{/* <br /> */}








                    {/* <input className="input11" type="file"    onChange={(e)=>setImage(e.target.files[0])}  name="name" placeholder="Enter Link Here" /><br/> */}
                    {/* <input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here" /><br/> */}
                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        {/* <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px", border : "1px solid white",  background : "rgb(0, 0, 0)" ,  color  : "white", fontSize : "12px"}}>Save</button> */}
                        {dispalyBut ? 

<button className="button11" type="submit" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
:
<span className="button11" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</span>


}
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}

{Num === 5 ? 

<>
<br/>




<center>
  <div class="image-upload mi2" style={{margin : "70px auto 0px auto"}}>
                                <label for="file-input">
                                    <img src={nu1} style={{ width:"133px", fontSize : "30px",borderRadius : "15px"}}/>
                                </label>

                                <input id="file-input" type="file" style={{width :  "0px"}}  onChange={(e)=>setImage123(e.target.files[0])}/>
                                </div>   
</center>



              <section>
                    <div className="container" style={{margin : "0px auto" , width : "97%"}}>
                            <div className="row justify-content-center">
                                {/* <div className="col-12 text-center">
                                    <h2 style={{color : "black"}} > <b>Linkedin */}
                                     {/* </b> </h2> */}
                                    {/*  */}
                                {/* </div>  */}

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={ln1} alt=""  id="photoImage1" className="arrival-Photo111  arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div><center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>Linkedin</p>
</center>
                </section>

           <center>
          
            <div class="col-xl-5 col-lg-6 col-md-8 col-sm-12" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage1(e)}>
                   
                <input className="input11" type="text"   value={Name} onChange={(e)=>setName(e.target.value)}  name="name" placeholder="Enter Title" /><br/>


                          

  {/* <div style={{display : "flex" , justifyContent : "flexLeft"}}>

 <span  onClick={()=>setDis123(1)} style={{borderRadius : "10px",padding: "4px 0px",height: "38px",marginTop: "8px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" ,width : "94px",marginRight : "3px", color  : "black"  , fontSize : "16px"}}>Username</span>
 {disUser ? 
<>
 <input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>

</>
                 : ""
                    }
                      
                  
         </div> */}


                      
                       {/* <>
                       <input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>
                      </> */}
                        
<br />

                 <div style={{display : "flex" , justifyContent : "flexLeft"}}>
                        <span onClick={()=>setDis123(2)} style={{borderRadius : "10px",padding: "4px 0px",height: "38px",marginTop: "8px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" ,width : "74px",marginRight : "3px", color  : "black"  , fontSize : "16px"}}>Url</span>
                    {disUser ? 
                    // {disUser1 ? 
                      <><input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here" /><br/></>
                      : ""
                    }
                    </div>


{/* <br /> */}








                    {/* <input className="input11" type="file"    onChange={(e)=>setImage(e.target.files[0])}  name="name" placeholder="Enter Link Here" /><br/> */}
                    {/* <input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here" /><br/> */}
                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        {/* <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px", border : "1px solid white",  background : "rgb(0, 0, 0)" ,  color  : "white", fontSize : "12px"}}>Save</button> */}
                        {dispalyBut ? 

<button className="button11" type="submit" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
:
<span className="button11" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</span>


}
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}

{Num === 6 ? 

<>
<br/>




<center>
  <div class="image-upload mi2" style={{margin : "70px auto 0px auto"}}>
                                <label for="file-input">
                                    <img src={nu1} style={{ width:"133px", fontSize : "30px",borderRadius : "15px"}}/>
                                </label>

                                <input id="file-input" type="file" style={{width :  "0px"}}  onChange={(e)=>setImage123(e.target.files[0])}/>
                                </div>   
</center>



              <section>
                    <div className="container" style={{margin : "0px auto" , width : "97%"}}>
                            <div className="row justify-content-center">
                                {/* <div className="col-12 text-center">
                                    <h2 style={{color : "black"}} > <b>Twitter */}
                                     {/* </b> </h2> */}
                                    {/*  */}
                                {/* </div>  */}

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={tw1} alt=""  id="photoImage1" className="arrival-Photo111  arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div>         <center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>Twitter</p>
</center>
                </section>

           <center>
          
            <div class="col-xl-5 col-lg-6 col-md-8 col-sm-12" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage1(e)}>
                   
                <input className="input11" type="text"   value={Name} onChange={(e)=>setName(e.target.value)}  name="name" placeholder="Enter Title" /><br/>


                          

  <div style={{display : "flex" , justifyContent : "flexLeft"}}>

 <span  onClick={()=>setDis123(1)} style={{borderRadius : "10px",padding: "4px 0px",height: "38px",marginTop: "8px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" ,width : "94px",marginRight : "3px", color  : "black"  , fontSize : "16px"}}>Username</span>
 {disUser ? 
<>
 <input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>

</>
                 : ""
                    }
                      
                  
         </div>


                      
                       {/* <>
                       <input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>
                      </> */}
                        
<br />

                 {/* <div style={{display : "flex" , justifyContent : "flexLeft"}}>
                        <span onClick={()=>setDis123(2)} style={{borderRadius : "10px",padding: "4px 0px",height: "38px",marginTop: "8px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" ,width : "74px",marginRight : "3px", color  : "black"  , fontSize : "16px"}}>Url</span>
                    {disUser1 ? 
                      <><input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here" /><br/></>
                      : ""
                    }
                    </div> */}


{/* <br /> */}








                    {/* <input className="input11" type="file"    onChange={(e)=>setImage(e.target.files[0])}  name="name" placeholder="Enter Link Here" /><br/> */}
                    {/* <input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here" /><br/> */}
                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        {/* <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px", border : "1px solid white",  background : "rgb(0, 0, 0)" ,  color  : "white", fontSize : "12px"}}>Save</button> */}
                        {dispalyBut ? 

<button className="button11" type="submit" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
:
<span className="button11" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</span>


}
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}

{Num === 7 ? 

<>
<br/>




<center>
  <div class="image-upload mi2" style={{margin : "70px auto 0px auto"}}>
                                <label for="file-input">
                                    <img src={nu1} style={{ width:"133px", fontSize : "30px",borderRadius : "15px"}}/>
                                </label>

                                <input id="file-input" type="file" style={{width :  "0px"}}  onChange={(e)=>setImage123(e.target.files[0])}/>
                                </div>   
</center>



              <section>
                    <div className="container" style={{margin : "0px auto" , width : "97%"}}>
                            <div className="row justify-content-center">
                                {/* <div className="col-12 text-center">
                                    <h2 style={{color : "black"}} > <b>Youtube */}
                                     {/* </b> </h2> */}
                                    {/*  */}
                                {/* </div>  */}

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={you1} alt=""  id="photoImage1" className="arrival-Photo111  arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div> <center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>Youtube</p>
</center>
                </section>

           <center>
          
            <div class="col-xl-5 col-lg-6 col-md-8 col-sm-12" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage1(e)}>
                   
                <input className="input11" type="text"   value={Name} onChange={(e)=>setName(e.target.value)}  name="name" placeholder="Enter Title" /><br/>


                          

  {/* <div style={{display : "flex" , justifyContent : "flexLeft"}}>

 <span  onClick={()=>setDis123(1)} style={{borderRadius : "10px",padding: "4px 0px",height: "38px",marginTop: "8px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" ,width : "94px",marginRight : "3px", color  : "black"  , fontSize : "16px"}}>Username</span>
 {disUser ? 
<>
 <input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>

</>
                 : ""
                    }
                      
                  
         </div> */}


                      
                       {/* <>
                       <input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>
                      </> */}
                        
<br />

                 <div style={{display : "flex" , justifyContent : "flexLeft"}}>
                        <span onClick={()=>setDis123(2)} style={{borderRadius : "10px",padding: "4px 0px",height: "38px",marginTop: "8px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" ,width : "74px",marginRight : "3px", color  : "black"  , fontSize : "16px"}}>Url</span>
                    {disUser ? 
                    // {disUser1 ? 
                      <><input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here" /><br/></>
                      : ""
                    }
                    </div>


{/* <br /> */}








                    {/* <input className="input11" type="file"    onChange={(e)=>setImage(e.target.files[0])}  name="name" placeholder="Enter Link Here" /><br/> */}
                    {/* <input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here" /><br/> */}
                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        {/* <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px", border : "1px solid white",  background : "rgb(0, 0, 0)" ,  color  : "white", fontSize : "12px"}}>Save</button> */}
                        {dispalyBut ? 

<button className="button11" type="submit" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
:
<span className="button11" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</span>


}
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}

{Num === 8 ? 

<>
<br/>




<center>
  <div class="image-upload mi2" style={{margin : "70px auto 0px auto"}}>
                                <label for="file-input">
                                    <img src={nu1} style={{ width:"133px", fontSize : "30px",borderRadius : "15px"}}/>
                                </label>

                                <input id="file-input" type="file" style={{width :  "0px"}}  onChange={(e)=>setImage123(e.target.files[0])}/>
                                </div>   
</center>



              <section>
                    <div className="container" style={{margin : "0px auto" , width : "97%"}}>
                            <div className="row justify-content-center">
                                {/* <div className="col-12 text-center">
                                    <h2 style={{color : "black"}} > <b>SnapChat </b> </h2> */}
                                    
                                {/* </div>  */}

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={tt1} alt=""  id="photoImage1" className="arrival-Photo111  arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div>
                            <center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>Twitch</p>
</center>
                </section>

           <center>
          
           <div class="col-xl-5 col-lg-6 col-md-8 col-sm-12" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage1(e)}>
                   
                <input className="input11" type="text"   value={Name} onChange={(e)=>setName(e.target.value)}  name="name" placeholder="Enter Title" /><br/>


                          

  <div style={{display : "flex" , justifyContent : "flexLeft"}}>

  <span  onClick={()=>setDis123(1)} style={{borderRadius : "10px",padding: "4px 0px",height: "38px",marginTop: "8px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" ,width : "94px",marginRight : "3px", color  : "black"  , fontSize : "16px"}}>Username</span>
{disUser ? 
<>
<input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>

</>
                : ""
                   }
                     
                 
        </div>


                     
                      {/* <>
                      <input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>
                     </> */}
                       
<br />

                {/* <div style={{display : "flex" , justifyContent : "flexLeft"}}>
                <span onClick={()=>setDis123(2)} style={{borderRadius : "10px",padding: "4px 0px",height: "38px",marginTop: "8px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" ,width : "74px",marginRight : "3px", color  : "black"  , fontSize : "16px"}}>Url</span>
                   {disUser1 ? 
                     <><input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here" /><br/></>
                     : ""
                   }
                   </div> */}


{/* <br /> */}








                    {/* <input className="input11" type="file"    onChange={(e)=>setImage(e.target.files[0])}  name="name" placeholder="Enter Link Here" /><br/> */}
                    {/* <input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here" /><br/> */}
                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        {/* <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px", border : "1px solid white",  background : "rgb(0, 0, 0)" ,  color  : "white", fontSize : "12px"}}>Save</button> */}
                        {dispalyBut ? 

<button className="button11" type="submit" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
:
<span className="button11" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</span>


}
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}

{Num === 9 ? 

<>
<br/>




<center>
  <div class="image-upload mi2" style={{margin : "70px auto 0px auto"}}>
                                <label for="file-input">
                                    <img src={nu1} style={{ width:"133px", fontSize : "30px",borderRadius : "15px"}}/>
                                </label>

                                <input id="file-input" type="file" style={{width :  "0px"}}  onChange={(e)=>setImage123(e.target.files[0])}/>
                                </div>   
</center>



              <section>
                    <div className="container" style={{margin : "0px auto" , width : "97%"}}>
                            <div className="row justify-content-center">
                                {/* <div className="col-12 text-center">
                                    <h2 style={{color : "black"}} > <b>SnapChat </b> </h2> */}
                                    
                                {/* </div>  */}

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={pi1} alt=""  id="photoImage1" className="arrival-Photo111  arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div>  <center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>Pinterest</p>
</center>
                </section>

           <center>
          
           <div class="col-xl-5 col-lg-6 col-md-8 col-sm-12" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage1(e)}>
                   
                <input className="input11" type="text"   value={Name} onChange={(e)=>setName(e.target.value)}  name="name" placeholder="Enter Title" /><br/>


                          

  <div style={{display : "flex" , justifyContent : "flexLeft"}}>

  <span  onClick={()=>setDis123(1)} style={{borderRadius : "10px",padding: "4px 0px",height: "38px",marginTop: "8px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" ,width : "94px",marginRight : "3px", color  : "black"  , fontSize : "16px"}}>Username</span>
{disUser ? 
<>
<input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>

</>
                : ""
                   }
                     
                 
        </div>


                     
                      {/* <>
                      <input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>
                     </> */}
                       
<br />

                {/* <div style={{display : "flex" , justifyContent : "flexLeft"}}>
                <span onClick={()=>setDis123(2)} style={{borderRadius : "10px",padding: "4px 0px",height: "38px",marginTop: "8px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" ,width : "74px",marginRight : "3px", color  : "black"  , fontSize : "16px"}}>Url</span>
                   {disUser1 ? 
                     <><input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here" /><br/></>
                     : ""
                   }
                   </div> */}


{/* <br /> */}








                    {/* <input className="input11" type="file"    onChange={(e)=>setImage(e.target.files[0])}  name="name" placeholder="Enter Link Here" /><br/> */}
                    {/* <input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here" /><br/> */}
                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        {/* <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px", border : "1px solid white",  background : "rgb(0, 0, 0)" ,  color  : "white", fontSize : "12px"}}>Save</button> */}
                        {dispalyBut ? 

<button className="button11" type="submit" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
:
<span className="button11" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</span>


}
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}

{Num === 33 ? 

<>

<center>
  <div class="image-upload mi2" style={{margin : "70px auto 0px auto"}}>
                                <label for="file-input">
                                    <img src={nu1} style={{ width:"133px", fontSize : "30px",borderRadius : "15px"}}/>
                                </label>

                                <input id="file-input" type="file" style={{width :  "0px"}}  onChange={(e)=>setImage123(e.target.files[0])}/>
                                </div>   
</center>
<br/>


              <section>
                    <div className="container" style={{margin : "0px auto" , width : "97%"}}>
                            <div className="row justify-content-center">
                                {/* <div className="col-12 text-center">
                                    <h2 style={{color : "black"}} > <b>Custom Link */}
                                     {/* </b> </h2> */}
                                    {/*  */}
                                {/* </div>  */}

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={cu1} alt=""  id="photoImage1" className="arrival-Photo111  arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div>     <center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>Custom Link</p>
</center>
                </section>

           <center>
          
            <div class="col-xl-5 col-lg-6 col-md-8 col-sm-12" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage1(e)}>
                   
                    <input className="input11" type="text"   value={Name} onChange={(e)=>setName(e.target.value)}  name="name" placeholder="Enter Title" /><br/>
                    <input className="input11" type="file"    onChange={(e)=>setImage(e.target.files[0])}  name="name" placeholder="Enter Link Here" /><br/>
                    <input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here" /><br/>
                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                    <div style={{display : "flex" , justifyContent : "space-between"}}>
                        <button className="button11" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "16px"}}>Another Link</button>
                        <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px", border : "1px solid white",  background : "rgb(0, 0, 0)" ,  color  : "white", fontSize : "12px"}}>Save</button>
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}

{Num === 10 ? 

<>
<br/>




<center>
  <div class="image-upload mi2" style={{margin : "70px auto 0px auto"}}>
                                <label for="file-input">
                                    <img src={nu1} style={{ width:"133px", fontSize : "30px",borderRadius : "15px"}}/>
                                </label>

                                <input id="file-input" type="file" style={{width :  "0px"}}  onChange={(e)=>setImage123(e.target.files[0])}/>
                                </div>   
</center>



              <section>
                    <div className="container" style={{margin : "0px auto" , width : "97%"}}>
                            <div className="row justify-content-center">
                                {/* <div className="col-12 text-center">
                                    <h2 style={{color : "black"}} > <b>Cash App */}
                                     {/* </b> </h2> */}
                                    {/*  */}
                                {/* </div>  */}

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={ca1} alt=""  id="photoImage1" className="arrival-Photo111  arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div><center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>Cash App</p>
</center>
                </section>

           <center>
          
           <div class="col-xl-5 col-lg-6 col-md-8 col-sm-12" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage1(e)}>
                   
                <input className="input11" type="text"   value={Name} onChange={(e)=>setName(e.target.value)}  name="name" placeholder="Enter Title" /><br/>


                          

  <div style={{display : "flex" , justifyContent : "flexLeft"}}>

 <span  onClick={()=>setDis123(1)} style={{borderRadius : "10px",padding: "4px 0px",height: "38px",marginTop: "8px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" ,width : "94px",marginRight : "3px", color  : "black"  , fontSize : "16px"}}>Username</span>
 {disUser ? 
<>
 <input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>

</>
                 : ""
                    }
                      
                  
         </div>


                      
                       {/* <>
                       <input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>
                      </> */}
                        
<br />

                 {/* <div style={{display : "flex" , justifyContent : "flexLeft"}}>
                        <span onClick={()=>setDis123(2)} style={{borderRadius : "10px",padding: "4px 0px",height: "38px",marginTop: "8px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" ,width : "74px",marginRight : "3px", color  : "black"  , fontSize : "16px"}}>Url</span>
                    {disUser1 ? 
                      <><input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here" /><br/></>
                      : ""
                    }
                    </div> */}


{/* <br /> */}








                    {/* <input className="input11" type="file"    onChange={(e)=>setImage(e.target.files[0])}  name="name" placeholder="Enter Link Here" /><br/> */}
                    {/* <input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here" /><br/> */}
                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        {/* <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px", border : "1px solid white",  background : "rgb(0, 0, 0)" ,  color  : "white", fontSize : "12px"}}>Save</button> */}
                        {dispalyBut ? 

<button className="button11" type="submit" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
:
<span className="button11" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</span>


}
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}

{Num === 13 ? 

<>
<br/>




<center>
  <div class="image-upload mi2" style={{margin : "70px auto 0px auto"}}>
                                <label for="file-input">
                                    <img src={nu1} style={{ width:"133px", fontSize : "30px",borderRadius : "15px"}}/>
                                </label>

                                <input id="file-input" type="file" style={{width :  "0px"}}  onChange={(e)=>setImage123(e.target.files[0])}/>
                                </div>   
</center>



              <section>
                    <div className="container" style={{margin : "0px auto" , width : "97%"}}>
                            <div className="row justify-content-center">
                                {/* <div className="col-12 text-center">
                                    <h2 style={{color : "black"}} > <b>Website */}
                                     {/* </b> </h2> */}
                                    {/*  */}
                                {/* </div>  */}

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={we1} alt=""  id="photoImage1" className="arrival-Photo111  arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div> <center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>Website</p>
</center>
                </section>

           <center>
          
            <div class="col-xl-5 col-lg-6 col-md-8 col-sm-12" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage(e)}>
                   
                    <input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Website link e.g (https://www.web.com)" /><br/>
                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                    <div style={{display : "flex" , justifyContent : "space-between"}}>
                        <button className="button11" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "16px"}}>Another Link</button>
                        <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px", border : "1px solid white",  background : "rgb(0, 0, 0)" ,  color  : "white", fontSize : "12px"}}>Save</button>
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}

{Num === 99 ? 

<>
<br/>




<center>
  <div class="image-upload mi2" style={{margin : "70px auto 0px auto"}}>
                                <label for="file-input">
                                    <img src={nu1} style={{ width:"133px", fontSize : "30px",borderRadius : "15px"}}/>
                                </label>

                                <input id="file-input" type="file" style={{width :  "0px"}}  onChange={(e)=>setImage123(e.target.files[0])}/>
                                </div>   
</center>



              <section>
                    <div className="container" style={{margin : "0px auto" , width : "97%"}}>
                            <div className="row justify-content-center">
                                {/* <div className="col-12 text-center">
                                    <h2 style={{color : "black"}} > <b>Website */}
                                     {/* </b> </h2> */}
                                    {/*  */}
                                {/* </div>  */}

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={lo1} alt=""  id="photoImage1" className="arrival-Photo111  arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div> <center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>Address</p>
</center>     
                </section>

           <center>
          
            <div class="col-xl-5 col-lg-6 col-md-8 col-sm-12" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage(e)}>
                   
                    <input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here" /><br/>
                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                    <div style={{display : "flex" , justifyContent : "space-between"}}>
                        <button className="button11" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "16px"}}>Another Link</button>
                        <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px", border : "1px solid white",  background : "rgb(0, 0, 0)" ,  color  : "white", fontSize : "12px"}}>Save</button>
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}

{Num === 11 ? 

<>
<br/>




<center>
  <div class="image-upload mi2" style={{margin : "70px auto 0px auto"}}>
                                <label for="file-input">
                                    <img src={nu1} style={{ width:"133px", fontSize : "30px",borderRadius : "15px"}}/>
                                </label>

                                <input id="file-input" type="file" style={{width :  "0px"}}  onChange={(e)=>setImage123(e.target.files[0])}/>
                                </div>   
</center>



              <section>
                    <div className="container" style={{margin : "0px auto" , width : "97%"}}>
                            <div className="row justify-content-center">
                                {/* <div className="col-12 text-center">
                                    <h2 style={{color : "black"}} > <b>Paypal */}
                                     {/* </b> </h2> */}
                                    {/*  */}
                                {/* </div>  */}

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={pp1} alt=""  id="photoImage1" className="arrival-Photo111  arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div>  <center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>PayPal</p>
</center>     
                </section>

           <center>
          
           <div class="col-xl-5 col-lg-6 col-md-8 col-sm-12" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage1(e)}>
                   
                <input className="input11" type="text"   value={Name} onChange={(e)=>setName(e.target.value)}  name="name" placeholder="Enter Title" /><br/>


                          

  <div style={{display : "flex" , justifyContent : "flexLeft"}}>

 <span  onClick={()=>setDis123(1)} style={{borderRadius : "10px",padding: "4px 0px",height: "38px",marginTop: "8px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" ,width : "94px",marginRight : "3px", color  : "black"  , fontSize : "16px"}}>Username</span>
 {disUser ? 
<>
 <input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>

</>
                 : ""
                    }
                      
                  
         </div>


                      
                       {/* <>
                       <input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>
                      </> */}
                        
<br />

                 {/* <div style={{display : "flex" , justifyContent : "flexLeft"}}>
                        <span onClick={()=>setDis123(2)} style={{borderRadius : "10px",padding: "4px 0px",height: "38px",marginTop: "8px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" ,width : "74px",marginRight : "3px", color  : "black"  , fontSize : "16px"}}>Url</span>
                    {disUser1 ? 
                      <><input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here" /><br/></>
                      : ""
                    }
                    </div> */}


{/* <br /> */}








                    {/* <input className="input11" type="file"    onChange={(e)=>setImage(e.target.files[0])}  name="name" placeholder="Enter Link Here" /><br/> */}
                    {/* <input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here" /><br/> */}
                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        {/* <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px", border : "1px solid white",  background : "rgb(0, 0, 0)" ,  color  : "white", fontSize : "12px"}}>Save</button> */}
                        {dispalyBut ? 

<button className="button11" type="submit" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
:
<span className="button11" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</span>


}
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}

{Num === 12 ? 

<>
<br/>




<center>
  <div class="image-upload mi2" style={{margin : "70px auto 0px auto"}}>
                                <label for="file-input">
                                    <img src={nu1} style={{ width:"133px", fontSize : "30px",borderRadius : "15px"}}/>
                                </label>

                                <input id="file-input" type="file" style={{width :  "0px"}}  onChange={(e)=>setImage123(e.target.files[0])}/>
                                </div>   
</center>



              <section>
                    <div className="container" style={{margin : "0px auto" , width : "97%"}}>
                            <div className="row justify-content-center">
                                {/* <div className="col-12 text-center">
                                    <h2 style={{color : "black"}} > <b>Venmo */}
                                     {/* </b> </h2> */}
                                    {/*  */}
                                {/* </div>  */}

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={ve1} alt=""  id="photoImage1" className="arrival-Photo111  arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div>  <center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>Venmo</p>
</center>    
                </section>

           <center>
          
           <div class="col-xl-5 col-lg-6 col-md-8 col-sm-12" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage1(e)}>
                   
                <input className="input11" type="text"   value={Name} onChange={(e)=>setName(e.target.value)}  name="name" placeholder="Enter Title" /><br/>


                          

  <div style={{display : "flex" , justifyContent : "flexLeft"}}>

 <span  onClick={()=>setDis123(1)} style={{borderRadius : "10px",padding: "4px 0px",height: "38px",marginTop: "8px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" ,width : "94px",marginRight : "3px", color  : "black"  , fontSize : "16px"}}>Username</span>
 {disUser ? 
<>
 <input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>

</>
                 : ""
                    }
                      
                  
         </div>


                      
                       {/* <>
                       <input className="input11" type="text"   value={email1} onChange={(e)=>setemail1(e.target.value)}  name="name" placeholder="Enter Username Here" /><br/>
                      </> */}
                        
<br />

                 <div style={{display : "flex" , justifyContent : "flexLeft"}}>
                        <span onClick={()=>setDis123(2)} style={{borderRadius : "10px",padding: "10px 0px",height: "54px",marginTop: "6px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" ,width : "74px", color  : "black"  ,marginRight : "3px", fontSize : "16px"}}>Url</span>
                    {disUser1 ? 
                      <><input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here" /><br/></>
                      : ""
                    }
                    </div>


{/* <br /> */}








                    {/* <input className="input11" type="file"    onChange={(e)=>setImage(e.target.files[0])}  name="name" placeholder="Enter Link Here" /><br/> */}
                    {/* <input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Link Here" /><br/> */}
                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                    <div style={{display : "flex" , justifyContent : "center"}}>
                        {/* <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px", border : "1px solid white",  background : "rgb(0, 0, 0)" ,  color  : "white", fontSize : "12px"}}>Save</button> */}
                        {dispalyBut ? 

<button className="button11" type="submit" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</button>
:
<span className="button11" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "12px",height: "45px"}}>Save</span>


}
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}
{Num === 21 ? 

<>
<br/>




<center>
  <div class="image-upload mi2" style={{margin : "70px auto 0px auto"}}>
                                <label for="file-input">
                                    <img src={nu1} style={{ width:"133px", fontSize : "30px",borderRadius : "15px"}}/>
                                </label>

                                <input id="file-input" type="file" style={{width :  "0px"}}  onChange={(e)=>setImage123(e.target.files[0])}/>
                                </div>   
</center>



              <section>
                    <div className="container" style={{margin : "0px auto" , width : "97%"}}>
                            <div className="row justify-content-center">
                                {/* <div className="col-12 text-center">
                                    <h2 style={{color : "black"}} > <b>Text */}
                                     {/* </b> </h2> */}
                                    {/*  */}
                                {/* </div>  */}

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={mess1} alt=""  id="photoImage1" className="arrival-Photo111  arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div><center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>Text</p>
</center>  
                </section>

           <center>
          
            <div class="col-xl-5 col-lg-6 col-md-8 col-sm-12" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage(e)}>
                <div style={{display : "flex" , justifyContent : "flex-start"}}>
                    <select name="" id=""  value={UserPhoneCode} onChange={(e)=>setUserPhoneCode(e.target.value)}  style={{width : "117px" , fontSize :"12px" , height: "37px" , marginTop : "8px"}}>
                      
                      {
                        PhoneCode.map((res,i)=>{
                          return(
                            <option value={res.phoneCode} >{res.value}  +{res.phoneCode}</option>
                            // <option value={"+" + res.phoneCode} onChange={()=>setUserPhoneCode(res)}>{res.value}  +{res.phoneCode}</option>
                          )
                        })
                      }
                    </select>
                    <input className="input11" type="number"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Number Here" /><br/>
                    </div>
                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                    <div style={{display : "flex" , justifyContent : "space-between"}}>
                        <button className="button11" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "16px"}}>Another Link</button>
                        <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px", border : "1px solid white",  background : "rgb(0, 0, 0)" ,  color  : "white", fontSize : "12px"}}>Save</button>
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}
{Num === 22 ? 

<>
<br/>




<center>
  <div class="image-upload mi2" style={{margin : "70px auto 0px auto"}}>
                                <label for="file-input">
                                    <img src={nu1} style={{ width:"133px", fontSize : "30px",borderRadius : "15px"}}/>
                                </label>

                                <input id="file-input" type="file" style={{width :  "0px"}}  onChange={(e)=>setImage123(e.target.files[0])}/>
                                </div>   
</center>



              <section>
                    <div className="container" style={{margin : "0px auto" , width : "97%"}}>
                            <div className="row justify-content-center">
                                {/* <div className="col-12 text-center">
                                    <h2 style={{color : "black"}} > <b>Whatsapps */}
                                     {/* </b> </h2> */}
                                    {/*  */}
                                {/* </div>  */}

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={wa1} alt=""  id="photoImage1" className="arrival-Photo111  arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div> <center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>Whatsapp</p>
</center> 
                </section>

           <center>
          
            <div class="col-xl-5 col-lg-6 col-md-8 col-sm-12" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage(e)}>
                <div style={{display : "flex" , justifyContent : "flex-start"}}>
                    <select name="" id=""  value={UserPhoneCode} onChange={(e)=>setUserPhoneCode(e.target.value)}  style={{width : "117px" , fontSize :"12px" , height: "37px" , marginTop : "8px"}}>
                      
                      {
                        PhoneCode.map((res,i)=>{
                          return(
                            <option value={res.phoneCode} >{res.value}  +{res.phoneCode}</option>
                            // <option value={"+" + res.phoneCode} onChange={()=>setUserPhoneCode(res)}>{res.value}  +{res.phoneCode}</option>
                          )
                        })
                      }
                    </select>
                    <input className="input11" type="number"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Number Here" /><br/>
                    </div>
                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                    <div style={{display : "flex" , justifyContent : "space-between"}}>
                        <button className="button11" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "16px"}}>Another Link</button>
                        <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px", border : "1px solid white",  background : "rgb(0, 0, 0)" ,  color  : "white", fontSize : "12px"}}>Save</button>
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}
{Num === 24 ? 

<>
<br/>




<center>
  <div class="image-upload mi2" style={{margin : "70px auto 0px auto"}}>
                                <label for="file-input">
                                    <img src={nu1} style={{ width:"133px", fontSize : "30px",borderRadius : "15px"}}/>
                                </label>

                                <input id="file-input" type="file" style={{width :  "0px"}}  onChange={(e)=>setImage123(e.target.files[0])}/>
                                </div>   
</center>



              <section>
                    <div className="container" style={{margin : "0px auto" , width : "97%"}}>
                            <div className="row justify-content-center">
                                {/* <div className="col-12 text-center">
                                    <h2 style={{color : "black"}} > <b>Email */}
                                     {/* </b> </h2> */}
                                    {/*  */}
                                {/* </div>  */}

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={mail1} alt=""  id="photoImage1" className="arrival-Photo111  arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div><center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>Email</p>
</center> 
                </section>

           <center>
          
            <div class="col-xl-5 col-lg-6 col-md-8 col-sm-12" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage(e)}>
                   
                    <input className="input11" type="text"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Email Here" /><br/>
                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                    <div style={{display : "flex" , justifyContent : "space-between"}}>
                        <button className="button11" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "16px"}}>Another Link</button>
                        <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px", border : "1px solid white",  background : "rgb(0, 0, 0)" ,  color  : "white", fontSize : "12px"}}>Save</button>
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}
{Num === 25 ? 

<>
<br/>




<center>
  <div class="image-upload mi2" style={{margin : "70px auto 0px auto"}}>
                                <label for="file-input">
                                    <img src={nu1} style={{ width:"133px", fontSize : "30px",borderRadius : "15px"}}/>
                                </label>

                                <input id="file-input" type="file" style={{width :  "0px"}}  onChange={(e)=>setImage123(e.target.files[0])}/>
                                </div>   
</center>



              <section>
                    <div className="container" style={{margin : "0px auto" , width : "97%"}}>
                            <div className="row justify-content-center">
                                {/* <div className="col-12 text-center">
                                    <h2 style={{color : "black"}} > <b>Phone */}
                                     {/* </b> </h2> */}
                                    {/*  */}
                                {/* </div>  */}

                                            <div className="arrival11 col-xl-3 col-lg-3 col-md-4 col-sm-5 arrival1111">
                                                <Link to={"/main-categories/"}><img src={call1} alt=""  id="photoImage1" className="arrival-Photo111  arrival-P"/></Link>
                                            </div>
                                            
                                     
                               
                            </div>
                            </div>        <center>

<p style={{fontWeight : "bold"  , color : "black", marginLeft : "-17px"}}>Number</p>
</center>
                </section>

           <center>
          
            <div class="col-xl-5 col-lg-6 col-md-8 col-sm-12" style={{margin : "40px 0px" }}>
                <form  onSubmit={(e)=>SubmitDataMessage(e)}>
                <div style={{display : "flex" , justifyContent : "flex-start"}}>
                    <select name="" id=""  value={UserPhoneCode} onChange={(e)=>setUserPhoneCode(e.target.value)}  style={{width : "117px" , fontSize :"12px" , height: "37px" , marginTop : "8px"}}>
                      
                      {
                        PhoneCode.map((res,i)=>{
                          return(
                            <option value={res.phoneCode} >{res.value}  +{res.phoneCode}</option>
                            // <option value={"+" + res.phoneCode} onChange={()=>setUserPhoneCode(res)}>{res.value}  +{res.phoneCode}</option>
                          )
                        })
                      }
                    </select>
                    <input className="input11" type="number"   value={email} onChange={(e)=>setemail(e.target.value)}  name="name" placeholder="Enter Number Here" /><br/>
                    </div>
                    <br />

                    {/* <input className="input11" type="email" name="email" placeholder="Email" /><br/> */}
                    <div style={{display : "flex" , justifyContent : "space-between"}}>
                        <button className="button11" style={{borderRadius : "50px",width : "190px" ,border : "1px solid rgb(211, 211, 211)", background : "rgb(211, 211, 211)" , color  : "black"  , fontSize : "16px"}}>Another Link</button>
                        <button className="button11" type="submit" style={{borderRadius : "50px",width : "190px", border : "1px solid white",  background : "rgb(0, 0, 0)" ,  color  : "white", fontSize : "12px"}}>Save</button>
                    </div>
                    <br/>
                </form>
            </div>
           </center>
           </>
           :""
}

         
              




          
        


        {/* Back to top */}
        <div className="btn-back-to-top" id="myBtn">
          <span className="symbol-btn-back-to-top">
            <i className="zmdi zmdi-chevron-up" />
          </span>
        </div>
        {/* Modal1 */}
        <div className="wrap-modal1 js-modal1 p-t-60 p-b-20">
          <div className="overlay-modal1 js-hide-modal1" />
          <div className="container mi2">
            <div className="bg0 p-t-60 p-b-30 p-lr-15-lg how-pos3-parent">
              <button className="how-pos3 hov3 trans-04 js-hide-modal1">
                <img src="images/icons/icon-close.png" alt="CLOSE" />
              </button>
              <div className="row">
                <div className="col-md-6 col-lg-7 p-b-30">
                  <div className="p-l-25 p-r-30 p-lr-0-lg">
                    <div className="wrap-slick3 flex-sb flex-w">
                      <div className="wrap-slick3-dots" />
                      <div className="wrap-slick3-arrows flex-sb-m flex-w" />
                      <div className="slick3 gallery-lb">
                        <div className="item-slick3" data-thumb="images/product-detail-01.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-01.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-01.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-02.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-02.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-02.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                        <div className="item-slick3" data-thumb="images/product-detail-03.jpg">
                          <div className="wrap-pic-w pos-relative">
                            <img src="images/product-detail-03.jpg" alt="IMG-PRODUCT" />
                            <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href="images/product-detail-03.jpg">
                              <i className="fa fa-expand" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5 p-b-30">
                  <div className="p-r-50 p-t-5 p-lr-0-lg">
                    <h4 className="mtext-105 cl2 js-name-detail p-b-14">
                      Lightweight Jacket
                    </h4>
                    <span className="mtext-106 cl2">
                      $58.79
                    </span>
                    <p className="stext-102 cl3 p-t-23">
                      Nulla eget sem vitae eros pharetra viverra. Nam vitae luctus ligula. Mauris consequat ornare feugiat.
                    </p>
                    {/*  */}
                    <div className="p-t-33">
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Size
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Size S</option>
                              <option>Size M</option>
                              <option>Size L</option>
                              <option>Size XL</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-203 flex-c-m respon6">
                          Color
                        </div>
                        <div className="size-204 respon6-next">
                          <div className="rs1-select2 bor8 bg0">
                            <select className="js-select2" name="time">
                              <option>Choose an option</option>
                              <option>Red</option>
                              <option>Blue</option>
                              <option>White</option>
                              <option>Grey</option>
                            </select>
                            <div className="dropDownSelect2" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-w flex-r-m p-b-10">
                        <div className="size-204 flex-w flex-m respon6-next">
                          <div className="wrap-num-product flex-w m-r-20 m-tb-10">
                            <div className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-minus" />
                            </div>
                            <input className="mtext-104 cl3 txt-center num-product" type="number" name="num-product" defaultValue={1} />
                            <div className="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m">
                              <i className="fs-16 zmdi zmdi-plus" />
                            </div>
                          </div>
                          <button className="flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04 js-addcart-detail">
                            Add to cart
                          </button>
                        </div>
                      </div>	
                    </div>
                    {/*  */}
                    <div className="flex-w flex-m p-l-100 p-t-40 respon7">
                      <div className="flex-m bor9 p-r-10 m-r-11">
                        <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 js-addwish-detail tooltip100" data-tooltip="Add to Wishlist">
                          <i className="zmdi zmdi-favorite" />
                        </a>
                      </div>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Facebook">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Twitter">
                        <i className="fa fa-twitter" />
                      </a>
                      <a href="#" className="fs-14 cl3 hov-cl1 trans-04 lh-10 p-lr-5 p-tb-2 m-r-8 tooltip100" data-tooltip="Google Plus">
                        <i className="fa fa-google-plus" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
 






        )
    
}

export default Home;